import React, { useCallback, useEffect, useRef, useState } from "react";
import FreemiumFeature from "../../../components/Fremium/FreemiumFeature";
import { getFeatureControlByPlan } from "../../../middlewares/FeatureControl";
import { useAuth } from "../../../hooks/auth";
import TasksManagerFilterBar from "./TasksManagerFilterBar";
import debounce from "lodash.debounce";
import { Card } from "../../../interfaces/Card";
import api from "../../../services/api";
import { AvatarAdd, CardDialogContainer, CellDueAdd, CellStep, CellTag, CellTagAdd, Container, ContainerFields, ContainerList, RegisterData } from "./style";
import List from "../../../components/List";
import ListItem from "../../../components/List/ListItem";
import ListColumn from "../../../components/List/ListColumn";
import ListText from "../../../components/List/ListText";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { FaExternalLinkAlt, FaMailBulk } from "react-icons/fa";
import { BsCalendarPlus, BsTag } from "react-icons/bs";
import { CardFlowTag } from "../../../interfaces/CardFlowTag";
import { TaskTag } from "../../../dialogs/Card/styles";
import AvatarCange from "../../../components/AvatarCange";
import { FiUserPlus } from "react-icons/fi";
import { User } from "../../../interfaces/User";
import { CellDue } from "../../../components/TableCange/style";
import { Link } from "react-router-dom";
import { useToast } from "../../../hooks/toast";
import { Flow } from "../../../interfaces/Flow";
import { useMediaQuery } from "react-responsive";
import { KanbanConfigProps } from "../../../components/Kanban";
import getKanbanConfig from "../../../utils/KanbanConfig/getKanbanConfig";
import { getDaysDiff } from "../../../utils/getDaysDiff";
import { formatDuration } from "../../../utils/formatDuration";

interface TasksManagerProps { }

export interface UpdateControlProps {
    random: number;
    card?: Card;
}

const TasksManager: React.FC<TasksManagerProps> = () => {

    const { user } = useAuth();
    const { addToast } = useToast();

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    const [data, setData] = useState<Card[]>([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const pageSize = 25;
    const parentRef = useRef<HTMLDivElement | null>(null);

    const [selectedFlows, setSelectedFlows] = useState<number[]>([]);
    const [flowsBase, setFlowsBase] = useState<Flow[]>([]);

    const [users, setUsers] = useState<User[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);

    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();

    const [openCard, setOpenCard] = useState(false);
    const [currentCard, setCurrentCard] = useState<Card>();
    const [flowSelected, setFlowSelected] = useState<Flow>();
    const [kanbanConfig, setKanbanConfig] = useState<KanbanConfigProps>();
    const [updateControl, setUpdateControl] = useState<UpdateControlProps>({ random: 0 });

    const getApiCard = useCallback(async (id_card: number, keepCardOpen?: boolean, id_flow?: number) => {

        if (id_card !== undefined && id_flow !== undefined) {

            await api.get(`/card/v2`, {

                params: {
                    id_card: id_card,
                    flow_id: id_flow,
                    isTestModel: false,
                    isIgnoringDelArchStatus: true,
                    isWithPreAnswer: true,
                    isWithTimeTracking: true
                }
            }).then(response => {
                if (response.data !== null) {

                    const newCard: Card = response.data;

                    setUpdateControl({
                        random: (Math.random() * 100) * -1,
                        card: newCard
                    });

                    if (keepCardOpen) {
                        setCurrentCard(newCard);
                        setOpenCard(true);
                    }

                }
            })

        }

    }, []);

    const cardClose = useCallback(async (updateData: boolean, id_card?: number, keepCardOpen?: boolean, id_flow?: number) => {
        setOpenCard(false);

        setCurrentCard(undefined);

        if (updateData && id_card !== undefined) {

            getApiCard(id_card, keepCardOpen, id_flow);

        } else {
            if (updateData && id_card === undefined) {
                setUpdateControl({
                    random: (Math.random() * 100) * -1
                });
            }
        }

    }, [getApiCard]);

    const getApiMyFlows = useCallback(async () => {

        await api.get(`/flow/my-flows`, {
            params: {

            }
        }).then(response => {
            if (response.data !== null) {

                const respFlow: Flow[] = response.data;

                setFlowsBase(respFlow);

            }
        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Não foi possível buscar os seus fluxos!',
                description: 'Ocorreu ao tentar carregar os dados dos fluxos!',
            });
        });

    }, [addToast]);

    const getApiUsers = useCallback(async () => {

        setLoading(true);

        await api.get(`/user/by-company`, {
        }).then(response => {
            if (response.data !== null) {

                const respUser: User[] = response.data;

                setUsers(respUser);

            }
        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Não foi possível buscar todos os usuários!',
                description: 'Ocorreu ao tentar carregar os dados dos usuários!',
            });
        });

    }, [addToast]);

    // Função para buscar dados
    const fetchData = async (pageToLoad: number, resetHasMore?: boolean) => {
        setLoading(true);
        setPage(pageToLoad);

        try {

            let newStartDate;
            if (startDate !== undefined && startDate !== null) {
                newStartDate = startDate.toISOString().split('T')[0];
            }

            let newEndDate;
            if (endDate !== undefined && endDate !== null) {
                newEndDate = endDate.toISOString().split('T')[0];
            }

            if (resetHasMore) {
                setHasMore(true);
            }

            const response = await api.post(`/card/p/all`, {
                page: pageToLoad,
                size: pageSize,
                id_flows: selectedFlows,

                id_users: selectedUsers,
                dt_due_start: newStartDate,
                dt_due_end: newEndDate,
            });

            if (response.data && response.data.length > 0) {
                const cardsApi: Card[] = response.data;

                if (pageToLoad === 1) {
                    setData(cardsApi);
                } else {
                    setData((prevData) => [...prevData, ...cardsApi]);
                }
            } else {

                if (pageToLoad === 1) {
                    setData([]);
                } else {
                    setHasMore(false);
                }

            }
        } catch (error) {
            console.error("Error loading data:", error);
        } finally {
            setLoading(false);
        }
    };

    // Debounce para o evento de scroll
    const handleScroll = debounce(() => {
        if (parentRef.current && hasMore && !loading) {
            const { scrollTop, scrollHeight, clientHeight } = parentRef.current;

            // Se o usuário está próximo do fim da lista, carrega mais dados
            if (scrollTop + clientHeight >= scrollHeight - 300) {
                fetchData(page + 1);
            }
        }
    }, 200);

    const getApiFlow = useCallback(async (id_flow: number) => {

        await api.get(`/flow`, {
            params: {
                id_flow: id_flow,
                withSteps: true,
                withCards: false
            }
        }).then(response => {
            if (response.data !== null) {

                const respFlow: Flow = response.data;

                if (respFlow !== undefined) {

                    setFlowSelected(respFlow);
                    setKanbanConfig(getKanbanConfig(respFlow));

                }
            }

        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Erro ao carregar o Fluxo',
                description: 'Ocorreu ao tentar carregar o Fluxo!',
            });
        });

    }, [addToast]);

    const cardOpen = useCallback(async (card: Card) => {
        setCurrentCard(card);
        await getApiFlow(card.flow_id);
        setOpenCard(true);
    }, [getApiFlow]);

    useEffect(() => {

        //Just one time
        fetchData(1, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFlows, selectedUsers, startDate, endDate]);

    useEffect(() => {

        //Just one time
        getApiMyFlows();

        getApiUsers();

    }, [getApiMyFlows, getApiUsers]);

    useEffect(() => {

        if (updateControl?.card !== undefined && updateControl.card.id_card > 0) {

            const newCard = updateControl.card;

            setData((prevCards: Card[]) => {

                const updatedCards = [...prevCards];

                const index = updatedCards.findIndex((card) => card.id_card === newCard.id_card);

                let action: 'remove' | 'update' | 'add' = 'update';

                if (index !== -1) {
                    if (newCard.archived === "S") {
                        action = 'remove';
                    } else if (newCard.deleted === "S") {
                        action = 'remove';
                    } else {
                        action = 'update';
                    }
                } else {
                    if (newCard.archived !== "S" && newCard.deleted !== "S") {
                        action = 'add';
                    }
                }

                if (action === 'update') {
                    updatedCards[index] = newCard;
                } else if (action === 'add') {
                    updatedCards.push(newCard);
                } else if (action === 'remove') {
                    updatedCards.splice(index, 1);
                }

                return updatedCards;
            });

        } else {
            fetchData(1, true);

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateControl]);

    return (
        <Container>
            <FreemiumFeature
                title={"Painel de Insights"}
                description={
                    "O Painel de Insights do Cange oferece uma visão completa e detalhada sobre o tempo dedicado a cada tarefa e processo. Ele é projetado para quem deseja maximizar a produtividade, proporcionando um acompanhamento preciso do andamento de atividades. Com ele, você pode monitorar o tempo investido, identificar áreas que precisam de ajustes e melhorar a eficiência geral da sua equipe."
                }
                isPaidFeature={!getFeatureControlByPlan(7, user.company)}
                buttonText={"Fazer Upgrade"}
                planRequired={"Professional"}
                maxWidth={500}
                style={{ height: "calc(100% - 50px)" }}
            >
                <TasksManagerFilterBar
                    flowsBase={flowsBase}
                    usersBase={users}
                    startDate={startDate}
                    endDate={endDate}
                    selectedFlows={selectedFlows}
                    selectedUsers={selectedUsers}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setSelectedFlows={setSelectedFlows}
                    setSelectedUsers={setSelectedUsers}
                    forceUpdate={() => { }}
                />

                <ContainerFields item xs={12} md={12} lg={12} sm={12}>

                    <ContainerList
                        ref={parentRef}
                        onScroll={handleScroll}
                        style={{ height: "100%", overflowY: "auto" }}
                    >
                        <List style={{ minWidth: '1600px' }}>
                            <ListItem>
                                <ListColumn flex flexGrow={4} style={{ padding: '10px' }}>
                                    <ListText header>
                                        Título
                                    </ListText>
                                </ListColumn>
                                <ListColumn style={{ minWidth: '120px' }}>
                                    <ListText header>
                                        Responsável
                                    </ListText>
                                </ListColumn>
                                <ListColumn flex flexGrow={2}>
                                    <ListText header>
                                        Vencimento
                                    </ListText>
                                </ListColumn>
                                <ListColumn flex flexGrow={2}>
                                    <ListText header>
                                        Etiquetas
                                    </ListText>
                                </ListColumn>
                                <ListColumn flex flexGrow={3}>
                                    <ListText header>
                                        Etapa
                                    </ListText>
                                </ListColumn>
                                <ListColumn flex flexGrow={3}>
                                    <ListText header>
                                        Fluxo
                                    </ListText>
                                </ListColumn>
                                <ListColumn flex style={{ minWidth: '120px' }}>
                                    <ListText header>
                                        Idade
                                    </ListText>
                                </ListColumn>
                                <ListColumn flex style={{ minWidth: '120px' }}>
                                    <ListText header>
                                        Tracking
                                    </ListText>
                                </ListColumn>
                            </ListItem>
                            {data.map((rowData, index) => (
                                <ListItem key={index} onClick={() => cardOpen(rowData)}>
                                    <ListColumn flex flexGrow={4} aglinItems="flex-start">
                                        <ListText fontSize="15px" fontWeight="500">
                                            {rowData.title}
                                        </ListText>
                                    </ListColumn>
                                    <ListColumn style={{ minWidth: '120px' }}>
                                        {rowData.user !== undefined ?
                                            <AvatarCange user={rowData.user as User} size="35" /> :
                                            <AvatarAdd>
                                                <FiUserPlus />
                                            </AvatarAdd>
                                        }
                                    </ListColumn>
                                    <ListColumn flex flexGrow={2}>
                                        <ListText>
                                            {rowData.dt_due === undefined || rowData.dt_due === null ?
                                                <CellDueAdd><BsCalendarPlus /></CellDueAdd> :
                                                rowData.complete !== undefined && rowData.complete === "S" ?
                                                    <CellDue color={rowData.dt_due_color}><s>{rowData.dt_due_string}</s></CellDue> :
                                                    <CellDue color={rowData.dt_due_color}>{rowData.dt_due_string}</CellDue>
                                            }
                                        </ListText>
                                    </ListColumn>
                                    <ListColumn flex flexGrow={2}>
                                        <ListText>
                                            {rowData.card_flow_tags === undefined || rowData.card_flow_tags.length <= 0 ?
                                                <CellTagAdd>
                                                    <BsTag />
                                                </CellTagAdd> :
                                                <div>
                                                    <CellTag>
                                                        {rowData.card_flow_tags?.map((tag: CardFlowTag) => {
                                                            return (
                                                                <TaskTag key={tag.id_card_flow_tag} color={tag.flow_tag.color}>{tag.flow_tag.description}</TaskTag>
                                                            );
                                                        })}
                                                    </CellTag>
                                                </div>
                                            }
                                        </ListText>
                                    </ListColumn>
                                    <ListColumn flex flexGrow={3}>
                                        <CellStep color={rowData.flow_step?.color}>
                                            {rowData.flow_step?.icon !== undefined ?
                                                <IconPickerItem
                                                    icon={rowData.flow_step?.icon as IconList}
                                                    color={"white"}
                                                /> :
                                                <FaMailBulk />
                                            }
                                            {rowData.flow_step?.name}
                                        </CellStep>
                                    </ListColumn>
                                    <ListColumn aglinItems="flex-start" flex flexGrow={3}>
                                        <Link key={index} to={"/flow/" + rowData.flow?.hash + "/card/" + rowData.id_card} style={{ textDecoration: 'none' }} target="_blank">
                                            <RegisterData color={rowData.flow?.color}>
                                                {rowData.flow?.name}
                                                <FaExternalLinkAlt style={{ marginLeft: '10px', fontSize: '11px' }} />
                                            </RegisterData>
                                        </Link>
                                    </ListColumn>
                                    <ListColumn flex style={{ minWidth: '120px' }}>
                                        <ListText>
                                            {rowData.dt_created !== undefined && (
                                                <ListText fontSize="12px" color="#888">
                                                    {getDaysDiff(new Date(rowData.dt_created))}
                                                </ListText>
                                            )}
                                        </ListText>
                                    </ListColumn>
                                    <ListColumn flex style={{ minWidth: '120px' }}>
                                        <ListText>
                                            {rowData.total_tracking_time !== undefined && (
                                                <ListText fontSize="12px" color="#888">
                                                    {formatDuration(rowData.total_tracking_time)}
                                                </ListText>
                                            )}
                                        </ListText>
                                    </ListColumn>
                                </ListItem>
                            ))}
                            {loading && (
                                <ListItem>
                                    <ListColumn flex flexGrow={15}>
                                        Carregando...
                                    </ListColumn>
                                </ListItem>
                            )}
                        </List>
                    </ContainerList>
                </ContainerFields>
            </FreemiumFeature>

            {openCard && currentCard !== undefined && flowSelected !== undefined && (
                <CardDialogContainer
                    isMobile={isMobile}
                    open={openCard}
                    flow={flowSelected}
                    kanban_config={kanbanConfig}
                    selectedValue={currentCard}
                    onClose={cardClose}
                    typeUser={flowSelected.typeUserAccess !== undefined ? flowSelected.typeUserAccess : ""}
                    showFlowDetail={true}
                />
            )}
        </Container>
    );
};

export default TasksManager;