import styled, { css } from "styled-components";

interface LogoContainerProps {
    color: string;
    backgroundColor: string;
}

export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    margin-bottom: 5px;

    :hover {
        background-color: #f4f4f4;
        border-radius: 10px;

        .add-btn-item-formula {
            display: flex;
        }
    }
`;

export const LogoContainer = styled.div<LogoContainerProps>`
    background-color: #fde8ec;
    color: #f23b5c;
    height: 25px;
    width: 25px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    ${(props) => props.color && css`
        color: ${props.color};
    `}

    ${(props) => props.backgroundColor && css`
        background-color: ${props.backgroundColor};
    `}
`;

export const NameContainer = styled.div`
    border: none;
    background: transparent;
    font-size: 13px;
    //text-transform: uppercase;
    letter-spacing: 0.2px;
    font-weight: 400;
    color: black;
    margin-right: 10px;
`;

export const TypeContainer = styled.div`
    color: gray;
    font-style: italic;
    font-size: 12px;
    margin-right: 10px;
`;


export const AddButton = styled.button`
    display: none;
    background-color: #f23b5c;
    padding: 3px 7px;
    font-size: 10px;
    border-radius: 5px;
    color: white;
    font-weight: 400;
    letter-spacing: 0.5px;
    border: none;
`;

