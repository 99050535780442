import Grid from '@material-ui/core/Grid';
import styled, { css } from "styled-components";

interface BoxIconProps {
    color?: string;
}

export const ContentList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
`;

export const ContainerFields = styled(Grid)`
    background-color: white;
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
`;

export const ContainerList = styled.div`
    margin: 10px;
    margin-top: 15px;
    margin-left: 5px;
    margin-right: 5px;
    overflow: auto;
`;

export const BoxIcon = styled.div<BoxIconProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #f23b5d;
    color: white;
    border-radius: 5px;
    font-size: 13px;
    margin-right: 10px;

    div {
        display: flex;
        font-size: 13px !important;
    }

    ${props => props.color && css`
        background-color: ${props.color};
    `}
`;

export const AutomaticData = styled.span`
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 3px;
    font-size: 13px;
    border: 1px solid rgb(238, 238, 238);
    color: rgba(51, 51, 51, 0.9);
    background-color: rgb(248, 248, 248);
`;