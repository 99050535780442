import { TimeSheetFieldProps } from ".";
import { Card } from "../../../interfaces/Card";

export default function getFieldsFromCard(cards: Card[]): TimeSheetFieldProps[] {

    let newFields: TimeSheetFieldProps[] = [];

    if (cards !== undefined && cards.length > 0) {

        for (let index = 0; index < cards.length; index++) {
            const card = cards[index];

            if (card.form_answers !== undefined && card.form_answers.length > 0) {
                for (let idxB = 0; idxB < card.form_answers.length; idxB++) {
                    const formAnswer = card.form_answers[idxB];

                    if (formAnswer.form_answer_fields !== undefined && formAnswer.form_answer_fields.length > 0) {
                        for (let idxC = 0; idxC < formAnswer.form_answer_fields.length; idxC++) {
                            const formAnswerField = formAnswer.form_answer_fields[idxC];

                            if (formAnswerField.field.type === "COMBO_BOX_REGISTER_FIELD") {
                                const findField = newFields.filter(field => field.register_id === formAnswerField.field.register_id);

                                if (findField.length === 0) {

                                    newFields.push({
                                        id_field: formAnswerField.field_id,
                                        name: formAnswerField.field.name,
                                        title: formAnswerField.field.title,
                                        type: formAnswerField.field.type,
                                        index: formAnswerField.field.index,
                                        flow_id: formAnswerField.field.flow_id,
                                        register_id: formAnswerField.field.register_id,
                                        origin: "Cadastros Compartilhados",
                                        typeOrigin: "FormAnswer",
                                        indexOrigin: 1
                                    });

                                } else {

                                    newFields = newFields.map(field => {
                                        if (field.register_id === formAnswerField.field.register_id) {

                                            field.fieldsGroup = field.fieldsGroup === undefined ? [] : field.fieldsGroup;

                                            const findField = field.fieldsGroup.filter(f => f.id_field === formAnswerField.field.id_field);

                                            if (findField.length === 0) {
                                                field.fieldsGroup.push({
                                                    id_field: formAnswerField.field_id,
                                                    name: formAnswerField.field.name,
                                                    title: formAnswerField.field.title,
                                                    type: formAnswerField.field.type,
                                                    index: formAnswerField.field.index,
                                                    flow_id: formAnswerField.field.flow_id,
                                                    register_id: formAnswerField.field.register_id,
                                                    origin: "Cadastros Compartilhados",
                                                    typeOrigin: "FormAnswer",
                                                    indexOrigin: 1
                                                });
                                            }

                                            return {
                                                ...field,
                                                fieldsGroup: field.fieldsGroup
                                            }
                                        }
                                        return field;
                                    });

                                }

                            } else {
                                const findField = newFields.filter(field => field.id_field === formAnswerField.field_id);

                                if (findField.length === 0) {

                                    newFields.push({
                                        id_field: formAnswerField.field_id,
                                        name: formAnswerField.field.name,
                                        title: formAnswerField.field.title,
                                        type: formAnswerField.field.type,
                                        index: formAnswerField.field.index,
                                        flow_id: formAnswerField.field.flow_id,
                                        register_id: formAnswerField.field.register_id,
                                        origin: card.flow !== undefined ? card.flow?.name : "Fluxo",
                                        typeOrigin: "FormAnswer",
                                        indexOrigin: 3
                                    });

                                }
                            }
                        }
                    }
                }
            }
        }

    }

    return newFields;
}