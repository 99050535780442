import Grid from '@material-ui/core/Grid';
import styled from "styled-components";

export const ContentList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
`;

export const BreakArea = styled.div`
    margin-bottom: 30px !important;
`;

export const ContainerFields = styled(Grid)`
    background-color: white;
    min-width: 600px;
    border-radius: 10px 10px 10px 10px;
    padding-right: 4%;
    padding-left: 4%;
    padding-top: 1%;
    padding-bottom: 2%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: auto;

    form {
        padding: 25px 10px 0px 10px;
    }
`;

export const AvatarContainer = styled.div`
    margin-top: 15px;
    width: 100px;

    :hover {
        .avatar-user {
            display: none !important;
        }

        .avatar-edit {
            display: flex;
        }
    }
`;

export const AvatarEdit = styled.div`
    justify-content: center;
    align-items: center;
    display: none;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px dashed gray;
    flex-direction: column;
    text-align: center;
    cursor: pointer;

    svg {
        font-size: 20px;
        color: gray;
    }

    span {
        font-size: 11px;
        color: gray;
        margin-top: 5px;
    }
`;

export const LogoField = styled.div`
    padding: 15px 15px;
    padding-bottom: 0px;
`;

export const LogoFieldTitle = styled.div`

    margin-bottom: 15px;
    
    h3 {
        font-size: 16px;
        margin-bottom: 5px;
        font-weight: 500;
        color: black;
    }

    span {
        font-size: 15px;
        font-weight: 500;
        color: gray;
        margin-bottom: 5px;        
    }
`;

export const LogoFieldContent = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const LogoFieldImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;

    img {
        width: 100%;
        border-radius: 5px;
    }
`;

export const LogoFieldButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
`;

export const LogoFieldBtnUpload = styled.button`
    border: none;
    background-color: #4681b8;
    color: white;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 5px;        
`;

export const LogoFieldBtnRemove = styled.button`
    border: none;
    background-color: #f23b5c;
    color: white;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 10px;
`;

export const DividerMenu = styled.div`
    width: 100%;
    border-bottom: 1px solid #f4f4f4;
    margin: 20px 0;
`;