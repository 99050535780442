import styled, { css } from "styled-components";

interface ProgressBarTaskLineInsideProps {
    width?: number;
    colorBar?: string;
}

interface CLDescriptionProps {
    checked?: boolean;
}

export const ChecklistBody = styled.div`
    padding-right: 5px;
    padding-left: 5px;
`;

export const ProgressBarTask = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ProgressBarTaskLine = styled.div`
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ededed;
    margin-right: 10px;
`;

export const ProgressBarTaskLineInside = styled.div<ProgressBarTaskLineInsideProps>`
    height: 10px;
    min-height: 10px;
    width: 100%;
    background: rgb(255,140,47);
    background: linear-gradient(90deg, rgba(255,140,47,1) 25%, rgba(251,50,92,1) 50%, rgba(147,55,237,1) 75%);

    width: 0%;
    border-radius: 10px;

    ${(props) => props.width && css`
        width: ${props.width + "%"};
    `}

    ${(props) => props.colorBar && css`
        background: ${props.colorBar};
    `}
`;

export const ProgressBarTaskPercent = styled.div`
    font-weight: 500;
    margin-right: 10px;
`;

export const CLItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: grab;

    :hover {
        .btn-delete-cl-item {
            display: flex;
        }
    }

    .input-description-cl-item {       

        :focus {
            .btn-delete-cl-item  {
                display: flex;
            }
        }
    }
`;

export const CLCheckbox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    cursor: pointer;
`;

export const CLDescriptionContainer = styled.div`
  flex: 1;
  input {
    border: none;
    outline: none;   
  }
`;


export const CLDeleteButton = styled.button`
    display: none;
    border: none;
    background: transparent;
    color: #9e37ed;
    margin-left: 5px;
    transition: transform 0.3s ease;

    :hover {
        transform: scale(1.2);
    }
`;

export const CLDescription = styled.input<CLDescriptionProps>`
    color: black;
    margin-left: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;    
    background-color: white;
    width: 100%;
    padding: 8px;
    padding-left: 6px;
    border: none;

    :hover {
        border: 1px solid #f6ebfd;
        border-radius: 5px;
        padding: 7px;
        padding-left: 5px;
        width: calc(100% - 10px);
    }

    :focus {
        border: 1px solid #9e37ed;
        border-radius: 5px;
        padding: 7px;
        padding-left: 5px;
        width: calc(100% - 10px);
    }

    ${props => props.checked && css`
        text-decoration: line-through;
        color: #808080;
    `}
`;

export const ButtonAddCheckListItem = styled.button`
    width: 100%;
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1.5px;
    color: #9e37ed;
    padding: 5px 7px;
    border-radius: 5px;

    svg {
        margin-right: 5px;
    }

    :hover {
        background-color: #f6edfd;
    }
`;