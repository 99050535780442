import {
    Animation,
    BoxUserCenter,
    BoxUserItem,
    BoxUserLeft,
    BoxUserMail,
    BoxUserName,
    BoxUserRemove,
    BoxUserRigth,
    BoxUserType,
    BtnBoxUserRemove,
    BtnResendMail,
    ContainerItems,
    ContainerLoader,
    ContainerTotal,
    ContainerUsers,
    Content,
    ContentBody,
    DialogTop,
    DialogTopCenter,
    DialogTopLeft,
    DialogTopRight,
    HelpContainer,
    HelpIcon,
    HelpItem,
    HelpItems,
    HelpText,
    IconHeader
} from "./styles";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { AiOutlineClose } from "react-icons/ai";
import Avatar from 'react-avatar';
import ComboBoxInviteUser from '../../components/ComboBoxInviteUser';
import { Dialog } from '@material-ui/core';
import { FiUnlock } from 'react-icons/fi';
import { Flow } from "../../interfaces/Flow";
import { FlowUser } from '../../interfaces/FlowUser';
import { HiOutlineLightBulb } from "react-icons/hi";
import { InvitedUser } from '../../interfaces/InvitedUser';
import { IoIosWarning, IoMdPersonAdd } from "react-icons/io";
import { Link } from "react-router-dom";
import { Register } from '../../interfaces/Register';
import { RegisterUser } from '../../interfaces/RegisterUser';
import { User } from '../../interfaces/User';
import api from '../../services/api';
import lottie from 'lottie-web';
import getAccessControl from '../../middlewares/AccessControl';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import ConfirmDialog from "../ConfirmDialog";
import { Workspace } from "../../interfaces/Workspace";
import { WorkspaceUser } from "../../interfaces/WorkspaceUser";
import AvatarCange from "../../components/AvatarCange";
import InviteTeam from "../InviteTeam";
import Button from "../../components/Button";
import DependencyError, { DependencyErrorApi } from "../DependencyError";

export interface InviteUserProps {
    open: boolean;
    flow?: Flow | undefined;
    register?: Register | undefined;
    workspace?: Workspace | undefined;
    maxWidth?: "lg" | "md" | "sm" | "xl" | "xs";
    fullScreen?: boolean;
    title?: string;
    onClose: () => void;
    typeUser: string;
    isFromAdminPanel?: boolean;
}

interface DeleteUserFlowProps {
    open: boolean;
    userFlow?: FlowUser;
}

const rolesDescription = {
    admin: 'Possui total controle. Cria, edita/move e exclui cartões. Além disso, pode editar qualquer configuração do fluxo',
    member: 'Tem permissões para criar, editar/mover e excluir qualquer cartão no fluxo',
    singleMember: 'Autorizado a criar e editar/mover apenas os cartões atribuídos a si mesmo',
    restrictedView: 'Permite visualizar, criar e editar/mover apenas os cartões atribuídos a si mesmo',
    requester: 'Permite criar cartões, comentar e visualizar as suas demandas solicitadas',
    viewer: 'Pode somente visualizar todas as informações do fluxo'
}

const InviteUser: React.FC<InviteUserProps> = ({ onClose, open, flow, register, workspace, typeUser, maxWidth, fullScreen, title, isFromAdminPanel }) => {

    const nameLocal = flow !== undefined ? "fluxo" : register !== undefined ? "cadastro" : workspace !== undefined ? 'workspace' : "ambiente";

    const { user } = useAuth();
    const { addToast } = useToast();

    const lottieContainer = useRef<HTMLDivElement>(null);

    const [flowUsers, setFlowUsers] = useState<FlowUser[]>();
    const [registerUsers, setRegisterUsers] = useState<RegisterUser[]>();
    const [workspaceUsers, setWorkspaceUsers] = useState<WorkspaceUser[]>();
    const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>();
    const [users, setUsers] = useState<User[]>();
    const [publicPlace, setPublicPlace] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>();
    const [loadingDelete, setLoadingDelete] = useState<boolean>();
    const [loadingDeleteFlow, setLoadingDeleteFlow] = useState<boolean>();
    const [loadingInviting, setLoadingInviting] = useState<boolean>();
    const [totalMembers, setTotalMembers] = useState<number>();
    const [openDialogDelUser, setOpenDialogDelUser] = useState(false);
    const [openDialogDelUserFlow, setOpenDialogDelUserFlow] = useState<DeleteUserFlowProps>({ open: false, userFlow: undefined });
    const [selectDelUser, setSelectDelUser] = useState<User>();
    const [openInviteTeam, setOpenInviteTeam] = useState<boolean>(false);

    const [openDependencies, setOpenDependencies] = useState(false);
    const [dependencies, setDependencies] = useState<DependencyErrorApi[]>([]);

    const updateData = useCallback(async () => {

        setLoading(true);

        //If is from Flow
        if (flow?.id_flow !== undefined) {

            await api.get(`/invite-user`, {
                params: {
                    flow_id: flow?.id_flow
                }
            }).then(response => {
                if (response.data !== null) {
                    const respStep: InvitedUser[] = response.data;

                    setInvitedUsers(respStep);

                }
            });

            await api.get(`/flow-user`, {
                params: {
                    id_flow: flow?.id_flow
                }
            }).then(response => {
                if (response.data !== null) {
                    const respStep: FlowUser[] = response.data;

                    setFlowUsers(respStep);
                    setLoading(false);
                }
            });

            if (flow.isPrivate !== "0") {
                setPublicPlace(false);
            } else {
                setPublicPlace(true);
            }

            //Set empty array
            setUsers([]);
            setRegisterUsers([]);
            setWorkspaceUsers([]);

        } else if (register?.id_register !== undefined) { // If is from a register

            await api.get(`/invite-user`, {
                params: {
                    register_id: register?.id_register
                }
            }).then(response => {
                if (response.data !== null) {
                    const respStep: InvitedUser[] = response.data;

                    setInvitedUsers(respStep);

                }
            });

            await api.get(`/register-user`, {
                params: {
                    id_register: register?.id_register
                }
            }).then(response => {
                if (response.data !== null) {
                    const respStep: RegisterUser[] = response.data;

                    setRegisterUsers(respStep);
                    setLoading(false);
                }
            });


            if (register.isPrivate !== "0") {
                setPublicPlace(false);
            } else {
                setPublicPlace(true);
            }

            //Set empty array
            setUsers([]);
            setFlowUsers([]);
            setWorkspaceUsers([]);

        } else if (workspace?.id_workspace !== undefined) { // If is from a workspace

            await api.get(`/invite-user`, {
                params: {
                    workspace_id: workspace?.id_workspace
                }
            }).then(response => {
                if (response.data !== null) {
                    const respStep: InvitedUser[] = response.data;

                    setInvitedUsers(respStep);

                }
            });

            await api.get(`/workspace/users`, {
                params: {
                    id_workspace: workspace?.id_workspace,
                    isFromAdminPanel: isFromAdminPanel
                }
            }).then(response => {
                if (response.data !== null) {
                    const respStep: WorkspaceUser[] = response.data;

                    setWorkspaceUsers(respStep);
                    setLoading(false);

                }
            });

            if (workspace.isPrivate !== "0") {
                setPublicPlace(false);
            } else {
                setPublicPlace(true);
            }

            //Set empty array
            setUsers([]);
            setFlowUsers([]);
            setRegisterUsers([]);

        } else { //If come from other part of system            

            await api.get(`/invite-user`, {
            }).then(response => {
                if (response.data !== null) {
                    const respStep: InvitedUser[] = response.data;

                    setInvitedUsers(respStep);

                }
            });

            await api.get(`/user/by-company`, {
            }).then(response => {
                if (response.data !== null) {
                    const usersApi: User[] = response.data;

                    setUsers(usersApi);
                    setLoading(false);

                }
            });

            //Set public flow
            setPublicPlace(false);
            //Set empty array
            setFlowUsers([]);
            setRegisterUsers([]);

        }

    }, [flow, register, workspace, isFromAdminPanel]);

    const dialogDelUserSubmmit = useCallback(async () => {

        if (selectDelUser !== undefined) {
            setLoadingDelete(true);
            api
                .post('/user/inactive', {
                    id_user: selectDelUser.id_user
                })
                .then(response => {

                    updateData();

                    setOpenDialogDelUser(false);
                    setSelectDelUser(undefined);

                    addToast({
                        type: 'success',
                        title: 'Usuário removido com sucesso!',
                        description: 'O usuário selecionado foi removido do seu ambiente com sucesso',
                    });

                    setLoadingDelete(false);

                }).catch(error => {
                    setLoadingDelete(false);
                    console.log(error.response.data);
                    addToast({
                        type: 'error',
                        title: 'Erro ao remover Usuário',
                        description: 'Ocorreu um erro ao remover o usuário!',
                    });
                });
        }

    }, [addToast, updateData, selectDelUser]);


    const handleSelectUserFlow = useCallback(async (flowUser: FlowUser) => {

    }, []);

    const handleSelectUserRegister = useCallback(async (registerUser: RegisterUser) => {

    }, []);

    const handleSelectUserWorkspace = useCallback(async (workspaceUser: WorkspaceUser) => {

    }, []);

    const handleSelectUser = useCallback(async (user: User) => {

    }, []);

    const handleRemoveInvitedUser = useCallback(async (email: string) => {

        await api
            .delete('/invite-user?email=' + email)
            .then(response => {

                updateData();

            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao deletar o convidado',
                    description: 'Ocorreu um erro ao deletar o usuário da base de dados!',
                });
            });

    }, [addToast, updateData]);

    const handleRemoveUserFlow = useCallback(async (flowUser?: FlowUser) => {

        if (flowUser !== undefined) {

            const validFlowUsers = flowUsers?.filter((flowUser) => flowUser.type === "A");

            if ((validFlowUsers !== undefined && validFlowUsers?.length > 1) ||
                (flowUser.type === "M") || (flowUser.type === "V") || (flowUser.type === "S") || (flowUser.type === "R") || (flowUser.type === "I")) {

                setLoadingDeleteFlow(true);
                await api
                    .delete('/flow-user?flow_id=' + flow?.id_flow + '&user_id=' + flowUser.user.id_user)
                    .then(response => {
                        setLoadingDeleteFlow(false);
                        updateData();

                    }).catch(error => {
                        setLoadingDeleteFlow(false);
                        addToast({
                            type: 'error',
                            title: 'Erro ao inserir Usuário',
                            description: 'Ocorreu um erro ao inserir o usuário na base de dados!',
                        });
                    });

            } else {
                addToast({
                    type: 'error',
                    title: 'Erro ao remover o usuário',
                    description: 'É necessário ter ao menos um Administrador no fluxo!',
                });
            }

        }

    }, [addToast, flow, updateData, flowUsers]);

    const handleRemoveUserRegister = useCallback(async (registerUser: RegisterUser) => {

        const validRegisterUsers = registerUsers?.filter((registerUser) => registerUser.type === "A");

        if ((validRegisterUsers !== undefined && validRegisterUsers?.length > 1) ||
            (registerUser.type === "M") || (registerUser.type === "V")) {

            await api
                .delete('/register-user?register_id=' + register?.id_register + '&user_id=' + registerUser.user.id_user)
                .then(response => {

                    updateData();

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao inserir Usuário',
                        description: 'Ocorreu um erro ao inserir o usuário na base de dados!',
                    });
                });

        } else {
            addToast({
                type: 'error',
                title: 'Erro ao remover o usuário',
                description: 'É necessário ter ao menos um Administrador no cadastro!',
            });
        }

    }, [addToast, register, updateData, registerUsers]);

    const handleRemoveUserWorkspace = useCallback(async (workspaceUser: WorkspaceUser) => {

        const validWorkspaceUsers = workspaceUsers?.filter((workspaceUser) => workspaceUser.type === "A");

        if ((validWorkspaceUsers !== undefined && validWorkspaceUsers?.length > 1) ||
            (workspaceUser.type === "M")) {

            await api
                .delete('/workspace/user?workspace_id=' + workspace?.id_workspace + '&id_workspace_user=' + workspaceUser.id_workspace_user)
                .then(response => {

                    updateData();

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao inserir Usuário',
                        description: 'Ocorreu um erro ao inserir o usuário na base de dados!',
                    });
                });

        } else {
            addToast({
                type: 'error',
                title: 'Erro ao remover o usuário',
                description: 'É necessário ter ao menos um Administrador no fluxo!',
            });
        }

    }, [addToast, workspace, updateData, workspaceUsers]);

    const handleInviteNewUser = useCallback(async (email: string) => {

        setLoadingInviting(true);

        await api
            .post('/invite-user', {
                email,
                name: email.substr(0, email.indexOf("@")),
                flow_id: flow?.id_flow,
                register_id: register?.id_register,
                workspace_id: workspace?.id_workspace
            })
            .then(response => {

                updateData();

                addToast({
                    type: 'success',
                    title: 'E-mail enviado com sucesso!',
                    description: 'Em instantes o e-mail estará chegando na caixa do seu convidado ;)',
                });
                setLoadingInviting(false);
            }).catch(error => {
                setLoadingInviting(false);
                addToast({
                    type: 'error',
                    title: 'Erro ao convidar Usuário',
                    description: 'Ocorreu um erro ao convidar o usuário!',
                });
            });

    }, [addToast, flow, register, workspace, updateData]);

    const handleSelectNewUser = useCallback(async (user: User) => {

        const validFlowUsers = flowUsers?.filter((flowUser) => flowUser.user_id === user.id_user);
        const validRegisterUsers = registerUsers?.filter((registerUser) => registerUser.user_id === user.id_user);
        const validWorkspaceUsers = workspaceUsers?.filter((workspaceUser) => workspaceUser.user_id === user.id_user);

        if (flow !== undefined) {

            if (validFlowUsers?.length === 0) {
                setLoadingInviting(true);
                await api
                    .post('/flow-user', {
                        flow_id: flow?.id_flow,
                        user_id: user.id_user,
                        type: user.type !== "A" ? user.type : "M"
                    })
                    .then(response => {
                        setLoadingInviting(false);
                        updateData();
                    }).catch(error => {
                        setLoadingInviting(false);
                        addToast({
                            type: 'error',
                            title: 'Erro ao inserir Usuário',
                            description: 'Ocorreu um erro ao inserir o usuário na base de dados!',
                        });
                    });
            }

        } else if (register !== undefined) {

            if (validRegisterUsers?.length === 0) {
                setLoadingInviting(true);
                await api
                    .post('/register-user', {
                        register_id: register?.id_register,
                        user_id: user.id_user,
                        type: "M"
                    })
                    .then(response => {
                        setLoadingInviting(false);
                        updateData();

                    }).catch(error => {
                        setLoadingInviting(false);
                        addToast({
                            type: 'error',
                            title: 'Erro ao inserir Usuário',
                            description: 'Ocorreu um erro ao inserir o usuário na base de dados!',
                        });
                    });
            }

        } else if (workspace !== undefined) {

            if (validWorkspaceUsers?.length === 0) {
                setLoadingInviting(true);
                await api
                    .post('/workspace/user', {
                        workspace_id: workspace?.id_workspace,
                        user_id: user.id_user,
                        type: "M",
                        isFromAdminPanel: isFromAdminPanel
                    })
                    .then(response => {
                        setLoadingInviting(false);
                        updateData();

                    }).catch(error => {
                        setLoadingInviting(false);
                        addToast({
                            type: 'error',
                            title: 'Erro ao inserir Usuário',
                            description: 'Ocorreu um erro ao inserir o usuário na base de dados!',
                        });
                    });
            }

        }

    }, [addToast, flow, register, updateData, flowUsers, registerUsers, workspace, workspaceUsers, isFromAdminPanel]);

    const handleEditTypeUserFlow = useCallback(async (e: React.FormEvent<HTMLSelectElement>, flowUser: FlowUser) => {

        const validFlowUsersAdmin = flowUsers?.filter((flowUser) => flowUser.type === "A");

        //If the unique admin user is trying edit 
        if (validFlowUsersAdmin !== undefined &&
            validFlowUsersAdmin?.length === 1 &&
            validFlowUsersAdmin[0].user.id_user === flowUser.user.id_user) {
            addToast({
                type: 'error',
                title: 'Erro ao editar o usuário',
                description: 'É necessário ter ao menos um Administrador no ambiente!',
            });
            return;
        }

        //Vaid if the user is S (Requester)    
        if (flowUser.user.type === "S" && e.currentTarget.value !== "S") {
            addToast({
                type: 'error',
                title: 'Este usuário é um usuário solicitante no ambiente',
                description: 'Não é possível alterar o tipo de um usuário que é solicitante no ambiente para outro tipo!'
            });
            return;
        }

        //Vaid if the user is I (Individual Member)
        if (flowUser.user.type === "I" && e.currentTarget.value !== "I") {
            addToast({
                type: 'error',
                title: 'Este usuário é um usuário membro individual no ambiente',
                description: 'Não é possível alterar o tipo de um usuário que é Membro Individual no ambiente para outro tipo!'
            });
            return;
        }

        if ((validFlowUsersAdmin !== undefined && validFlowUsersAdmin?.length >= 1) || e.currentTarget.value === "A") {

            await api
                .post('/flow-user', {
                    flow_id: flow?.id_flow,
                    user_id: flowUser.user.id_user,
                    type: e.currentTarget.value
                })
                .then(response => {

                    updateData();

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao inserir Usuário',
                        description: 'Ocorreu um erro ao inserir o usuário na base de dados!',
                    });
                });

        } else {
            addToast({
                type: 'error',
                title: 'Erro ao editar o usuário',
                description: 'É necessário ter ao menos um Administrador no fluxo!',
            });
        }

    }, [addToast, flow, updateData, flowUsers]);

    const handleEditTypeUserRegister = useCallback(async (e: React.FormEvent<HTMLSelectElement>, registerUser: RegisterUser) => {

        const validRegisterUsersAdmin = registerUsers?.filter((registerUser) => registerUser.type === "A");

        //If the unique admin user is trying edit 
        if (validRegisterUsersAdmin !== undefined &&
            validRegisterUsersAdmin?.length === 1 &&
            validRegisterUsersAdmin[0].user.id_user === registerUser.user.id_user) {
            addToast({
                type: 'error',
                title: 'Erro ao editar o usuário',
                description: 'É necessário ter ao menos um Administrador no ambiente!',
            });
            return;
        }

        if ((validRegisterUsersAdmin !== undefined && validRegisterUsersAdmin?.length >= 1) || e.currentTarget.value === "A") {

            await api
                .post('/register-user', {
                    register_id: register?.id_register,
                    user_id: registerUser.user.id_user,
                    type: e.currentTarget.value
                })
                .then(response => {

                    updateData();

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao inserir Usuário',
                        description: 'Ocorreu um erro ao inserir o usuário na base de dados!',
                    });
                });

        } else {
            addToast({
                type: 'error',
                title: 'Erro ao editar o usuário',
                description: 'É necessário ter ao menos um Administrador no cadastro!',
            });
        }

    }, [addToast, register, updateData, registerUsers]);

    const handleEditTypeUserWorkspace = useCallback(async (e: React.FormEvent<HTMLSelectElement>, workspaceUser: WorkspaceUser) => {

        const validWorkspaceUsersAdmin = workspaceUsers?.filter((workspaceUser) => workspaceUser.type === "A");

        //If the unique admin user is trying edit 
        if (validWorkspaceUsersAdmin !== undefined &&
            validWorkspaceUsersAdmin?.length === 1 &&
            validWorkspaceUsersAdmin[0].user.id_user === workspaceUser.user.id_user) {
            addToast({
                type: 'error',
                title: 'Erro ao editar o usuário',
                description: 'É necessário ter ao menos um Administrador no ambiente!',
            });
            return;
        }

        if ((validWorkspaceUsersAdmin !== undefined && validWorkspaceUsersAdmin?.length >= 1) || e.currentTarget.value === "A") {

            await api
                .post('/workspace/user', {
                    id_workspace_user: workspaceUser.id_workspace_user,
                    workspace_id: workspace?.id_workspace,
                    user_id: workspaceUser.user.id_user,
                    type: e.currentTarget.value,
                    isFromAdminPanel: isFromAdminPanel
                })
                .then(response => {

                    updateData();

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao inserir Usuário',
                        description: 'Ocorreu um erro ao inserir o usuário na base de dados!',
                    });
                });

        } else {
            addToast({
                type: 'error',
                title: 'Erro ao editar o usuário',
                description: 'É necessário ter ao menos um Administrador no cadastro!',
            });
        }

    }, [addToast, workspace, updateData, workspaceUsers, isFromAdminPanel]);


    const handleEditTypeUser = useCallback(async (e: React.FormEvent<HTMLSelectElement>, user: User) => {

        const validUsersAdmin = users?.filter((user) => user.type === "A");

        //If the unique admin user is trying edit 
        if (validUsersAdmin !== undefined &&
            validUsersAdmin?.length === 1 &&
            validUsersAdmin[0].id_user === user.id_user) {
            addToast({
                type: 'error',
                title: 'Erro ao editar o usuário',
                description: 'É necessário ter ao menos um Administrador no ambiente!',
            });
            return;
        }

        if ((validUsersAdmin !== undefined && validUsersAdmin?.length >= 1) || e.currentTarget.value === "A") {

            await api
                .post('/user', {
                    id_user: user.id_user,
                    type: e.currentTarget.value
                })
                .then(response => {

                    updateData();

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao editar Usuário',
                        description: 'Ocorreu um erro ao editar o usuário na base de dados!',
                    });

                    if (error.response.data.complement !== undefined) {
                        setDependencies(error.response.data.complement);
                        setOpenDependencies(true);
                    }
                });

        } else {
            addToast({
                type: 'error',
                title: 'Erro ao editar o usuário',
                description: 'É necessário ter ao menos um Administrador no ambiente!',
            });
        }

    }, [addToast, updateData, users]);

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    useEffect(() => {

        let newTotMembers = 0;

        if (registerUsers !== undefined) {
            newTotMembers += registerUsers.length;
        }

        if (invitedUsers !== undefined) {
            newTotMembers += invitedUsers.length
        }

        if (users !== undefined) {
            newTotMembers += users.length
        }

        if (flowUsers !== undefined) {
            newTotMembers += flowUsers.length
        }

        if (workspaceUsers !== undefined) {
            newTotMembers += workspaceUsers.length
        }

        setTotalMembers(newTotMembers);

    }, [registerUsers, invitedUsers, users, flowUsers, workspaceUsers]);

    useEffect(() => {

        updateData();

    }, [updateData]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../assets/lottie/loader-circle-white.json')
            })
        }
    }, [loadingInviting, loading]);

    return (
        <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={maxWidth !== undefined ? maxWidth : "md"}
            open={open}
            onClose={handleClose}
            key={'invite-user'}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={"gray"}>
                        <div>
                            <IoMdPersonAdd />
                        </div>
                    </IconHeader>
                    <h1>
                        {title !== undefined ? title : "Convidar Membros"}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose></AiOutlineClose></button>
                </DialogTopRight>
            </DialogTop>

            <Content>
                <ContentBody container>

                    {!loading && (
                        <ContainerTotal>
                            <h3>
                                {totalMembers}
                                {flow !== undefined ? <>{" membros neste fluxo"}</> :
                                    register !== undefined ? <>{" membros neste cadastro"}</> :
                                        workspace !== undefined ? <>{" membros neste workspace"}</> :
                                            <>{" membros nesta empresa"}</>}
                            </h3>
                            <span>
                                Gerencie membros e funções
                            </span>
                        </ContainerTotal>
                    )}

                    <HelpContainer>
                        <HelpItems>
                            <HelpItem>
                                <HelpIcon>
                                    <HiOutlineLightBulb />
                                </HelpIcon>
                                <HelpText>
                                    {flow !== undefined ? <>Convide mais membros para o fluxo&nbsp;<b>{flow.name}</b></> :
                                        register !== undefined ? <>{"Convide mais membros para o cadastro "}  <b>{register.name}</b></> :
                                            <>Convide mais membros para o&nbsp;<b>Cange</b></>}{"! Chame novos usuários para colaborar com você e fazer coisas incríveis :)"}
                                </HelpText>
                            </HelpItem>
                        </HelpItems>
                    </HelpContainer>

                    {publicPlace ? (
                        <HelpContainer color="#f23b5c1e">
                            <HelpItems>
                                <HelpItem>
                                    <HelpIcon color="#f23b5c">
                                        <FiUnlock />
                                    </HelpIcon>
                                    <HelpText>
                                        {flow !== undefined ? "O seu fluxo " + flow.name :
                                            register !== undefined ? "O seu cadastro " + register.name :
                                                ""}

                                        <b>{" não é privado"}</b>{"! Ele está disponível para todos os usuários da sua companhia. Para alterar as configurações de privacidade "}

                                        {flow !== undefined ?
                                            <Link to={'/flow/1/' + flow?.hash + "/edit"} style={{ textDecoration: 'none' }}>
                                                clique aqui
                                            </Link> :
                                            register !== undefined ?
                                                <Link to={'/register/1/' + register?.hash + "/edit"} style={{ textDecoration: 'none' }}>
                                                    clique aqui
                                                </Link> :
                                                <></>}

                                        {" :)"}
                                    </HelpText>
                                </HelpItem>
                            </HelpItems>
                        </HelpContainer>
                    ) : null}

                    <>

                        <InviteTeam
                            key={'invite-team'}
                            open={openInviteTeam}
                            onClose={() => {
                                setOpenInviteTeam(false);
                                updateData();
                            }}
                        />

                        <DependencyError
                            key={'dependency-error'}
                            title={"Erro ao editar o perfil do usuário"}
                            description={"Este usuário possuí permissões em outros objetos que impedem a alteração do seu perfil. Para alterar o perfil do usuário, é necessário remover as permissões em outros objetos."}
                            icon={<IoIosWarning color="#f23b5c" />}
                            open={openDependencies}
                            onClose={() => setOpenDependencies(false)}
                            dependencies={dependencies}

                        />

                        {nameLocal === "ambiente" && getAccessControl(2, typeUser) && (
                            <Button
                                onClick={() => setOpenInviteTeam(true)}
                                style={{ width: '160px', height: '34px', fontSize: '15px', position: 'absolute', right: '45px' }}
                            >
                                Convidar pessoas
                            </Button>
                        )}

                        <ContainerItems>
                            {/* Combo box para busca e adição */}
                            {getAccessControl(4, typeUser) ?
                                <ComboBoxInviteUser
                                    handleSelectUser={handleSelectNewUser}
                                    handleInviteUser={handleInviteNewUser}
                                    removeUsersEnvironment={users}
                                    removeUsersFlow={flowUsers}
                                    removeUsersRegister={registerUsers}
                                    removeUsersWorkspace={workspaceUsers}
                                    nameLocal={
                                        flow !== undefined ? "fluxo" :
                                            register !== undefined ? "cadastro" :
                                                workspace !== undefined ? "workspace" :
                                                    "ambiente"
                                    }
                                />
                                : <></>
                            }

                            {/* Lista de usuários */}
                            <ContainerUsers>
                                {loadingInviting || loading ?
                                    <ContainerLoader>
                                        <Animation className="lottieContainer" ref={lottieContainer} />
                                        {!loading ? <label>Convidando...</label> : <label>Carregando...</label>}
                                    </ContainerLoader> :
                                    <></>
                                }
                                {!loading && invitedUsers?.map((flowInvitedUser) => {
                                    return (
                                        <BoxUserItem key={flowInvitedUser.id_invited_user}>
                                            <BoxUserLeft>
                                                <Avatar color={'red'} size="30" name={flowInvitedUser.name} round="20px" />
                                            </BoxUserLeft>
                                            <BoxUserCenter>
                                                <BoxUserName>{flowInvitedUser.name}*</BoxUserName>
                                                <BoxUserMail>{flowInvitedUser.email}</BoxUserMail>
                                            </BoxUserCenter>
                                            <BoxUserRigth>
                                                <BtnResendMail onClick={() => handleInviteNewUser(flowInvitedUser.email)}>Reenviar Convite</BtnResendMail>
                                            </BoxUserRigth>
                                            <BoxUserRigth>
                                                <BoxUserRemove>
                                                    <BtnBoxUserRemove onClick={() => handleRemoveInvitedUser(flowInvitedUser.email)}>
                                                        <AiOutlineClose />
                                                    </BtnBoxUserRemove>
                                                </BoxUserRemove>
                                            </BoxUserRigth>
                                        </BoxUserItem>
                                    );
                                })}
                                {!loading && flowUsers?.map((flowUser) => {
                                    return (
                                        <BoxUserItem key={flowUser.id_flow_user} onClick={() => handleSelectUserFlow(flowUser)}>
                                            <BoxUserLeft>
                                                <AvatarCange user={flowUser.user} size="30" />
                                            </BoxUserLeft>
                                            <BoxUserCenter>
                                                <BoxUserName>
                                                    {flowUser.user.name}
                                                    {flowUser.user.email === user.email ? " (Você)" : ""}
                                                </BoxUserName>
                                                <BoxUserMail>{flowUser.user.email}</BoxUserMail>
                                            </BoxUserCenter>
                                            <BoxUserRigth>
                                                {getAccessControl(2, typeUser) ?
                                                    <BoxUserType name="select" value={flowUser.type} onChange={e => handleEditTypeUserFlow(e, flowUser)}>
                                                        <option value="A" title={rolesDescription.admin}>Administrador</option>
                                                        <option value="M" title={rolesDescription.member}>Membro</option>
                                                        <option value="I" title={rolesDescription.singleMember}>Membro individual</option>
                                                        <option value="S" title={rolesDescription.requester}>Solicitante</option>
                                                        <option value="R" title={rolesDescription.restrictedView}>Visão restrita</option>
                                                        <option value="V" title={rolesDescription.viewer}>Visualizador</option>
                                                    </BoxUserType> :
                                                    <BoxUserType name="select" value={flowUser.type} disabled={true}>
                                                        <option value="A" title={rolesDescription.admin}>Administrador</option>
                                                        <option value="M" title={rolesDescription.member}>Membro</option>
                                                        <option value="I" title={rolesDescription.singleMember}>Membro individual</option>
                                                        <option value="S" title={rolesDescription.requester}>Solicitante</option>
                                                        <option value="R" title={rolesDescription.restrictedView}>Visão restrita</option>
                                                        <option value="V" title={rolesDescription.viewer}>Visualizador</option>
                                                    </BoxUserType>
                                                }
                                            </BoxUserRigth>
                                            <BoxUserRigth>
                                                {getAccessControl(3, typeUser) ?
                                                    <BoxUserRemove>
                                                        <BtnBoxUserRemove onClick={() => setOpenDialogDelUserFlow({ open: true, userFlow: flowUser })}>
                                                            <AiOutlineClose />
                                                        </BtnBoxUserRemove>
                                                    </BoxUserRemove> : <></>
                                                }
                                            </BoxUserRigth>
                                        </BoxUserItem>
                                    );
                                })}
                                {!loading && registerUsers?.map((registerUser) => {
                                    return (
                                        <BoxUserItem key={registerUser.id_register_user} onClick={() => handleSelectUserRegister(registerUser)}>
                                            <BoxUserLeft>
                                                <AvatarCange user={registerUser.user} size="30" />
                                            </BoxUserLeft>
                                            <BoxUserCenter>
                                                <BoxUserName>
                                                    {registerUser.user.name}
                                                    {registerUser.user.email === user.email ? " (Você)" : ""}
                                                </BoxUserName>
                                                <BoxUserMail>{registerUser.user.email}</BoxUserMail>
                                            </BoxUserCenter>
                                            <BoxUserRigth>
                                                {getAccessControl(2, typeUser) ?
                                                    <BoxUserType name="select" value={registerUser.type} onChange={e => handleEditTypeUserRegister(e, registerUser)}>
                                                        <option value="A">Administrador</option>
                                                        <option value="M">Membro</option>
                                                        <option value="V">Visualizador</option>
                                                    </BoxUserType> :
                                                    <BoxUserType name="select" value={registerUser.type} disabled={true}>
                                                        <option value="A">Administrador</option>
                                                        <option value="M">Membro</option>
                                                        <option value="V">Visualizador</option>
                                                    </BoxUserType>
                                                }
                                            </BoxUserRigth>
                                            <BoxUserRigth>
                                                {getAccessControl(3, typeUser) ?
                                                    <BoxUserRemove>
                                                        <BtnBoxUserRemove onClick={() => handleRemoveUserRegister(registerUser)}>
                                                            <AiOutlineClose />
                                                        </BtnBoxUserRemove>
                                                    </BoxUserRemove> : <></>
                                                }
                                            </BoxUserRigth>
                                        </BoxUserItem>
                                    );
                                })}
                                {!loading && workspaceUsers?.map((workspaceUser) => {
                                    return (
                                        <BoxUserItem key={workspaceUser.id_workspace_user} onClick={() => handleSelectUserWorkspace(workspaceUser)}>
                                            <BoxUserLeft>
                                                <AvatarCange user={workspaceUser.user} size="30" />
                                            </BoxUserLeft>
                                            <BoxUserCenter>
                                                <BoxUserName>
                                                    {workspaceUser.user.name}
                                                    {workspaceUser.user.email === user.email ? " (Você)" : ""}
                                                </BoxUserName>
                                                <BoxUserMail>{workspaceUser.user.email}</BoxUserMail>
                                            </BoxUserCenter>
                                            <BoxUserRigth>
                                                {getAccessControl(2, typeUser) ?
                                                    <BoxUserType name="select" value={workspaceUser.type} onChange={e => handleEditTypeUserWorkspace(e, workspaceUser)}>
                                                        <option value="A">Administrador</option>
                                                        <option value="M">Membro</option>
                                                    </BoxUserType> :
                                                    <BoxUserType name="select" value={workspaceUser.type} disabled={true}>
                                                        <option value="A">Administrador</option>
                                                        <option value="M">Membro</option>
                                                    </BoxUserType>
                                                }
                                            </BoxUserRigth>
                                            <BoxUserRigth>
                                                {getAccessControl(3, typeUser) ?
                                                    <BoxUserRemove>
                                                        <BtnBoxUserRemove onClick={() => handleRemoveUserWorkspace(workspaceUser)}>
                                                            <AiOutlineClose />
                                                        </BtnBoxUserRemove>
                                                    </BoxUserRemove> : <></>
                                                }
                                            </BoxUserRigth>
                                        </BoxUserItem>
                                    );
                                })}
                                {!loading && users?.map((usr) => {
                                    return (
                                        <>
                                            <BoxUserItem key={usr.id_user} onClick={() => handleSelectUser(usr)}>
                                                <BoxUserLeft>
                                                    <AvatarCange user={usr} size="30" />
                                                </BoxUserLeft>
                                                <BoxUserCenter>
                                                    <BoxUserName>
                                                        {usr.name}
                                                        {usr.email === user.email ? " (Você)" : ""}
                                                    </BoxUserName>
                                                    <BoxUserMail>{usr.email}</BoxUserMail>
                                                </BoxUserCenter>
                                                <BoxUserRigth>
                                                    {getAccessControl(2, typeUser) ?
                                                        <BoxUserType name="select" value={usr.type} onChange={e => handleEditTypeUser(e, usr)}>
                                                            <option value="A">Administrador</option>
                                                            <option value="M">Membro</option>
                                                            <option value="I">Membro Individual</option>
                                                            <option value="S">Solicitante</option>
                                                            <option value="V">Visualizador</option>
                                                        </BoxUserType> :
                                                        <BoxUserType name="select" value={usr.type} disabled={true}>
                                                            <option value="A">Administrador</option>
                                                            <option value="M">Membro</option>
                                                            <option value="I">Membro Individual</option>
                                                            <option value="S">Solicitante</option>
                                                            <option value="V">Visualizador</option>
                                                        </BoxUserType>
                                                    }
                                                </BoxUserRigth>
                                                <BoxUserRigth>
                                                    {getAccessControl(47, typeUser) && usr.email !== user.email ?
                                                        <BoxUserRemove>
                                                            <BtnBoxUserRemove onClick={() => {
                                                                setOpenDialogDelUser(true);
                                                                setSelectDelUser(usr)
                                                            }}>
                                                                <AiOutlineClose />
                                                            </BtnBoxUserRemove>
                                                        </BoxUserRemove> :
                                                        <></>
                                                    }
                                                </BoxUserRigth>

                                            </BoxUserItem>
                                        </>
                                    );
                                })}
                            </ContainerUsers>
                            <ConfirmDialog
                                key="delete-users-environment"
                                open={openDialogDelUser}
                                onSubmmit={dialogDelUserSubmmit}
                                onClose={() => setOpenDialogDelUser(false)}
                                isLoading={loadingDelete}
                                type={1}
                                message={"Você tem certeza que deseja remover a conta do(a) " + selectDelUser?.name + " e todas suas permissões no Cange?"}
                            />

                            <ConfirmDialog
                                key="delete-users-flow"
                                open={openDialogDelUserFlow.open}
                                onSubmmit={() => handleRemoveUserFlow(openDialogDelUserFlow.userFlow)}
                                onClose={() => setOpenDialogDelUserFlow({ open: false, userFlow: undefined })}
                                isLoading={loadingDeleteFlow}
                                type={1}
                                message={"Você tem certeza que deseja remover a conta do(a) " + openDialogDelUserFlow.userFlow?.user.name + " do fluxo e limpar o responsável dos cartões atribuídos a ele(a)?"}
                            />
                        </ContainerItems>
                    </>
                </ContentBody>
            </Content>
            {/*
            <DialogFooter>
                <DialogFooterCenter>
                    <BtnInsertNewAnswer icon={BiSave} onClick={() => handleSave()}>
                        Salvar
                    </BtnInsertNewAnswer>
                </DialogFooterCenter>
            </DialogFooter>
            */}
        </Dialog >
    );
};

export default InviteUser;

