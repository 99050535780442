import { Container, Title } from "./styles";
import React, { InputHTMLAttributes, useCallback, useEffect, useRef, useState } from "react";

import ErrorDescription from "../../../../ErrorDescription";
import { IconBaseProps } from "react-icons";
import { useField } from "@unform/core";

interface InputNumberProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon?: React.ComponentType<IconBaseProps>;
    description?: string;
    max_length?: number;
}

const InputNumber: React.FC<InputNumberProps> = ({ name, description, icon: Icon, disabled, max_length, ...rest }) => {

    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const { fieldName, defaultValue, error, registerField } = useField(name);

    const maskNumber = (value: string) => {
        return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    };

    const [fieldValue, setFieldValue] = React.useState(defaultValue !== undefined ? maskNumber(defaultValue) : undefined);

    const handleInputFocused = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: () => {
                if (fieldValue !== undefined) {
                    let value: string = fieldValue.trim();

                    return value === "" ? undefined : Number(value.replace(/[^0-9.-]+/g, ""));
                } else {
                    return undefined;
                }
            },
            setValue: (ref, value) => {
                if (value !== null && value !== undefined) {
                    setFieldValue(maskNumber(String(value)));
                } else {
                    setFieldValue(maskNumber(""));
                }
            },
            clearValue: () => {
                setFieldValue(maskNumber(""));
            }
        });

    }, [fieldName, registerField, fieldValue]);

    return (
        <>
            {description && <Title>{description}</Title>}
            <Container isErrored={!!error} isFocused={isFocused} isDisabled={!!disabled} >
                {Icon && <Icon size="20" />}
                <input
                    onFocus={handleInputFocused}
                    onBlur={handleInputBlur}
                    onChange={(e) => setFieldValue(maskNumber(e.target.value))}
                    ref={inputRef}
                    disabled={disabled}
                    maxLength={max_length}
                    value={fieldValue}
                    {...rest}
                />

            </Container>

            {error && (<ErrorDescription title={error} />)}
        </>
    );
}

export default InputNumber;