import React from "react";
import { Container } from "./style";

const CangeIcon: React.FC = () => {

    return (
        <Container>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="1em" width="1em" viewBox="0 0 229.29 183.43"><defs></defs><g id="GRID"><path d="M213.24,44.68v24.1H199.48V52.73h-39V39h47A5.71,5.71,0,0,1,213.24,44.68Z" /><path d="M45.86,97.57h0A5.86,5.86,0,0,0,40,91.71H5.85A5.86,5.86,0,0,0,0,97.57s0,0,0,.05v79.95a5.85,5.85,0,0,0,5.85,5.86H40a5.86,5.86,0,0,0,5.86-5.86v-80Z" /><path d="M229.29,97.57h0a5.87,5.87,0,0,0-5.86-5.86H189.28a5.86,5.86,0,0,0-5.85,5.86v80a5.86,5.86,0,0,0,5.86,5.86h34.14a5.86,5.86,0,0,0,5.86-5.86v-80Z" /><path d="M137.57,5.86h0A5.85,5.85,0,0,0,131.72,0H97.57a5.86,5.86,0,0,0-5.86,5.86.43.43,0,0,1,0,0v80a5.84,5.84,0,0,0,5.86,5.85h34.14a5.85,5.85,0,0,0,5.85-5.85v-80Z" /><path d="M16.05,44.68v24.1H29.81V52.73h39V39h-47A5.71,5.71,0,0,0,16.05,44.68Z" /></g></svg>
        </Container>
    );

}

export default CangeIcon;