import styled, { css } from 'styled-components';

interface ContainerProps {
    isActived: boolean;
}

export const Container = styled.div<ContainerProps>`
    position: absolute;
    right: calc(50% - 400px);
    bottom: 0;
    right: 0;
    //padding: 30px;
    overflow: hidden;
    //Novos itens
    z-index: 9999999;

    ${props => props.isActived && css`
        padding: 30px;
    `}


`;

