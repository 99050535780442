import {
    Animation,
    Container,
    ContainerLoader,
    FlowContainer,
    FlowContainerEmpty,
    FlowData,
    FlowDataEmpty,
    FlowIconContainer,
    FlowIconContainerEmpty
} from "./style";
import { IconList, IconPickerItem } from 'react-fa-icon-picker'
import React, { useCallback, useEffect, useRef, useState } from "react";

import { FaPlus } from "react-icons/fa";
import { Flow } from "../../../interfaces/Flow";
import api from '../../../services/api';
import getAccessControl from "../../../middlewares/AccessControl";
import lottie from 'lottie-web';
import { Link, useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';

interface MyFlowsProps {
    typeUser: string;
    columns?: number;
}

function compare(a: Flow, b: Flow) {
    if (a.isStared !== undefined && b.isStared !== undefined) {
        if (a.isStared > b.isStared) {
            return -1;
        }
        if (a.isStared < b.isStared) {
            return 1;
        }
    }
    return 0;

}

const MyFlows: React.FC<MyFlowsProps> = ({ typeUser, columns }) => {

    const [flows, setFlows] = useState<Flow[]>();
    const [loading, setLoading] = useState<boolean>(true);
    const lottieContainer = useRef<HTMLDivElement>(null);
    const { addToast } = useToast();
    const history = useHistory();

    const handleAddNewFlow = useCallback(async () => {

        history.push('/template-store');

    }, [history]);

    useEffect(() => {

        setLoading(true);

        api.get(`/flow/my-flows`, {
        }).then(response => {

            const flowApi: Flow[] = response.data;

            setFlows(flowApi);

            setLoading(false);

        }).catch(error => {
            setLoading(false);
            addToast({
                type: 'error',
                title: 'Erro ao buscar os seus Fluxos',
                description: 'Ocorreu um erro ao carregar os seus Fluxos!',
            });
        });

    }, [addToast]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../assets/lottie/loader.json')
            })
        }
    }, []);

    return (

        loading ?
            <ContainerLoader>
                < Animation className="lottieContainer" ref={lottieContainer} />
            </ContainerLoader > :

            <Container columns={columns !== undefined ? String(columns) : undefined}>
                {getAccessControl(10, typeUser) ?
                    <FlowContainerEmpty onClick={() => handleAddNewFlow()}>
                        <FlowIconContainerEmpty>
                            <FaPlus />
                        </FlowIconContainerEmpty>
                        <FlowDataEmpty>
                            <h2>Criar um novo fluxo</h2>
                        </FlowDataEmpty>
                    </FlowContainerEmpty> : (<></>)
                }

                {flows?.sort(compare).map((flow) => {
                    return (
                        <Link key={flow.id_flow} to={'/flow/' + flow.hash} style={{ textDecoration: 'none' }}>
                            <FlowContainer key={flow.id_flow} color={flow.color ? flow.color : "#795548"}>
                                <FlowIconContainer color={flow.color ? flow.color : "#795548"}>
                                    <IconPickerItem
                                        icon={flow.icon ? flow.icon as IconList : "FaWrench"}
                                        color={flow.color ? flow.color : "#795548"}
                                    />
                                </FlowIconContainer>
                                <FlowData>
                                    <h2>{flow.name ? flow.name : "Rascunho"}</h2>
                                    {getAccessControl(55, typeUser) ?
                                        <span>
                                            {flow?.total_cards !== undefined && flow?.total_cards > 0 ?
                                                <>{flow?.total_cards} {flow?.total_cards > 1 ? "Cards" : "Card"}</> :
                                                <></>
                                            }
                                            {flow?.isStared === "S" ?
                                                <> · Favorito</> : <></>
                                            }
                                        </span> : <></>
                                    }
                                </FlowData>
                            </FlowContainer>
                        </Link>
                    )
                })}
            </Container>
    );

}

export default MyFlows;