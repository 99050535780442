import React from "react";

import { Container } from "./styles";

interface ToolTipProps {
  title: string;
  className?: string;
  color?: string;
  children?: React.ReactNode;
}

const ToolTip: React.FC<ToolTipProps> = ({
  title,
  className,
  color,
  children
}) => {
  return (
    <Container className={className} color={color}>
      {children}
      <span>{title}</span>
    </Container>
  );
};

export default ToolTip;
