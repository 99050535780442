import {
    BoxComment,
    BoxDescription,
    BoxDescriptionOptions,
    BoxDetail,
    BtnAction,
    Container,
    Description,
    FixedSpan,
    User
} from "./style";
import { BsPinAngleFill, BsPinFill } from 'react-icons/bs';
import React, { useState } from "react";

import { CardComment } from '../../../../interfaces/CardComment';
import { FaTrash } from 'react-icons/fa';
import { IconBaseProps } from "react-icons/lib";
import { MdMessage } from "react-icons/md";
import getAccessControl from '../../../../middlewares/AccessControl';
import { useAuth } from '../../../../hooks/auth';
import AvatarCange from "../../../AvatarCange";

interface HistoryItemProps {
    id: number;
    cardComment: CardComment;
    typeUser: string;
    onDeleteComment?: (id_card_comment?: number) => void;
    onFixComment?: (cardComment?: CardComment) => void;
}

interface TypesProps {
    id: number;
    name: string;
    description: string;
    icon: React.ComponentType<IconBaseProps>;
    color: string;
}

const types: TypesProps[] = [
    {
        id: 0,
        name: "comment",
        description: "comentou",
        icon: MdMessage,
        color: "blue"
    }
]

const HistoryItem: React.FC<HistoryItemProps> = ({ cardComment, id, typeUser, onDeleteComment, onFixComment }) => {

    const [type] = useState<TypesProps>(types[id]);
    const { user } = useAuth();

    return (
        <Container>
            <User>
                <AvatarCange
                    user={cardComment.user}
                    size="30"
                />
            </User>
            <BoxDetail>
                <BoxDescription>
                    <Description>
                        <b>{cardComment.user?.name}</b> {type.description}
                        {cardComment.fixed !== undefined && cardComment.fixed !== null && cardComment.fixed === "1" ?
                            <FixedSpan>
                                <BsPinFill />
                                Fixado
                            </FixedSpan> :
                            <></>
                        }
                    </Description>
                    <span>{cardComment?.dt_created_string}</span>

                    {getAccessControl(45, typeUser) && onFixComment !== undefined && onDeleteComment !== undefined ?
                        <BoxDescriptionOptions className='comment-actions'>
                            <BtnAction type='button' title='Fixar no topo' onClick={() => onFixComment(cardComment)}>
                                {cardComment.fixed !== undefined && cardComment.fixed !== null && cardComment.fixed === "1" ?
                                    <BsPinFill /> :
                                    <BsPinAngleFill />}
                            </BtnAction>
                            {cardComment.user_id === user.id_user ?
                                <BtnAction type='button' title='Excluir comentário' onClick={() => onDeleteComment(cardComment.id_card_comment)}>
                                    <FaTrash />
                                </BtnAction> : <></>
                            }
                        </BoxDescriptionOptions> : <></>
                    }
                </BoxDescription>
                <BoxComment dangerouslySetInnerHTML={{ __html: cardComment?.description !== undefined ? cardComment?.description : "" }} />
            </BoxDetail>
        </Container>
    );

}

export default HistoryItem;