interface DynamicDateProps {
    type: string;
    isPeriod: boolean;
    startDate: Date;
    endDate?: Date;
}

export default function getDynamicDate(type: string): DynamicDateProps {

    const date = new Date();

    date.setHours(0, 0, 0, 0);

    if (type === "-1") { //Hoje
        return {
            type: "-1",
            isPeriod: false,
            startDate: date
        }
    } else if (type === "-2") { //Ontem
        date.setDate(date.getDate() - 1);
        return {
            type: "-2",
            isPeriod: false,
            startDate: date
        }
    } else if (type === "-3") { //Amanhã
        date.setDate(date.getDate() + 1);
        return {
            type: "-3",
            isPeriod: false,
            startDate: date
        }
    } else if (type === "-5") { //Próximos 7 dias without today

        const lastDay = new Date();
        lastDay.setDate(lastDay.getDate() + 7);
        lastDay.setHours(23, 59, 59, 999);

        const firstDay = new Date(date);
        firstDay.setDate(date.getDate() + 1);
        firstDay.setHours(0, 0, 0, 0);

        return {
            type: "-5",
            isPeriod: true,
            startDate: firstDay,
            endDate: lastDay
        }
    } else if (type === "-6") { //Últimos 7 dias without today

        const lastDay = new Date();
        lastDay.setDate(lastDay.getDate() - 1);
        lastDay.setHours(23, 59, 59, 999);

        const firstDay = new Date(date);
        firstDay.setDate(date.getDate() - 7);
        firstDay.setHours(0, 0, 0, 0);

        return {
            type: "-6",
            isPeriod: true,
            startDate: firstDay,
            endDate: lastDay
        }
    } else if (type === "-7") { //Esta Semana        
        const firstDay = new Date(date);
        firstDay.setDate(date.getDate() - date.getDay());

        const lastDay = new Date(date);
        lastDay.setDate(date.getDate() + (6 - date.getDay()));

        return {
            type: "-7",
            isPeriod: true,
            startDate: firstDay,
            endDate: lastDay
        }
    } else if (type === "-8") { //Semana passada
        const firstDay = new Date(date);
        firstDay.setDate(date.getDate() - date.getDay() - 7);

        const lastDay = new Date(date);
        lastDay.setDate(date.getDate() - date.getDay() - 1);

        return {
            type: "-8",
            isPeriod: true,
            startDate: firstDay,
            endDate: lastDay
        }
    } else if (type === "-9") { //Próxima semana
        const firstDay = new Date(date);
        firstDay.setDate(date.getDate() + (7 - date.getDay()));

        const lastDay = new Date(date);
        lastDay.setDate(date.getDate() + (6 - date.getDay()) + 7);

        return {
            type: "-9",
            isPeriod: true,
            startDate: firstDay,
            endDate: lastDay
        }
    } else if (type === "-10") { //Este mês
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        return {
            type: "-10",
            isPeriod: true,
            startDate: firstDay,
            endDate: lastDay
        }
    } else if (type === "-11") { //Mês passado
        const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

        return {
            type: "-11",
            isPeriod: true,
            startDate: firstDay,
            endDate: lastDay
        }
    } else if (type === "-12") { //Próximo mês
        const firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 2, 0);

        return {
            type: "-12",
            isPeriod: true,
            startDate: firstDay,
            endDate: lastDay
        }
    } else if (type === "-13") { //Este trimestre
        const firstDay = new Date(date.getFullYear(), Math.floor(date.getMonth() / 3) * 3, 1);
        const lastDay = new Date(date.getFullYear(), Math.floor(date.getMonth() / 3) * 3 + 3, 0);

        return {
            type: "-13",
            isPeriod: true,
            startDate: firstDay,
            endDate: lastDay
        }
    } else if (type === "-14") { //Trimestre passado
        const firstDay = new Date(date.getFullYear(), Math.floor(date.getMonth() / 3) * 3 - 3, 1);
        const lastDay = new Date(date.getFullYear(), Math.floor(date.getMonth() / 3) * 3, 0);

        return {
            type: "-14",
            isPeriod: true,
            startDate: firstDay,
            endDate: lastDay
        }
    } else if (type === "-15") { //Próximo trimestre
        const firstDay = new Date(date.getFullYear(), Math.floor(date.getMonth() / 3) * 3 + 3, 1);
        const lastDay = new Date(date.getFullYear(), Math.floor(date.getMonth() / 3) * 3 + 6, 0);

        return {
            type: "-15",
            isPeriod: true,
            startDate: firstDay,
            endDate: lastDay
        }
    } else if (type === "-16") { //Este ano
        const firstDay = new Date(date.getFullYear(), 0, 1);
        const lastDay = new Date(date.getFullYear(), 11, 31);

        return {
            type: "-16",
            isPeriod: true,
            startDate: firstDay,
            endDate: lastDay
        }
    } else if (type === "-17") { //Ano passado
        const firstDay = new Date(date.getFullYear() - 1, 0, 1);
        const lastDay = new Date(date.getFullYear() - 1, 11, 31);

        return {
            type: "-17",
            isPeriod: true,
            startDate: firstDay,
            endDate: lastDay
        }
    } else if (type === "-18") { //Próximo ano
        const firstDay = new Date(date.getFullYear() + 1, 0, 1);
        const lastDay = new Date(date.getFullYear() + 1, 11, 31);

        return {
            type: "-18",
            isPeriod: true,
            startDate: firstDay,
            endDate: lastDay
        }
    }

    return {
        type: "-1",
        isPeriod: false,
        startDate: date
    }

}