import styled from 'styled-components';

import background from '../../../assets/background_gradient.png';

export const Container = styled.div`
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;    
    padding: 35px;
    padding-left: 100px;
    padding-right: 100px;
    justify-content: center;
    background-color: #f6f8f9;

    flex: 1;
    background: url(${background}) no-repeat center;
    background-size: cover;

    @media(max-width: 1200px) {
        padding: 0px;
    }

    @media(max-height: 800px) {
        padding: 0px;
    }

    @media(max-width: 750px) {
        background: none;
        background-color: white;
    }
`;