import React, { useState } from "react";

import {
    Content,
    ContentBody, 
    ContainerAnimation,
    ConfettiArea,    
    ButtonsArea,
    StartButton,
    MainDialog
} from "./style";

import { useMediaQuery } from "react-responsive";

interface EndTrialProps {
    open: boolean;
    onClose: () => void;
}

const EndTrial: React.FC<EndTrialProps> = ({ onClose, open }) => {

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [activeExit] = useState<boolean>(false);

    return (
        <MainDialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            fullScreen={isMobile}
            style={{ zIndex: '99999' }}
            isVisible={activeExit}
        >
            <Content>
                <ContentBody container>

                    <ContainerAnimation>
                        <ConfettiArea style={{ position: 'relative' }}>                            
                            <h1>O seu período de teste com o <b style={{ color: "#f23b5d" }}>Trial</b> terminou 😢</h1>
                            <h3>Entendemos que o período de teste de 15 dias passa rapidamente, porém, agora é hora de escolher o plano ideal para sua empresa.</h3>                            
                            <h3>Clique no botão abaixo e escolha um plano para não perder nenhuma funcionalidade do <b style={{ color: "#f23b5d" }}>Cange</b></h3>                            
                        </ConfettiArea>
                        <ButtonsArea>
                            <StartButton onClick={() => {
                                onClose();
                            }}>
                                Escolher meu plano
                            </StartButton>
                        </ButtonsArea>
                    </ContainerAnimation>

                </ContentBody>
            </Content>

        </MainDialog >
    );

}

export default EndTrial;