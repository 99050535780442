import React, { useCallback, useEffect, useState } from "react";

import {
    ButtonAction,
    Container,
    ContainerAction,
    LeftContainer,
    RightContainer
} from "./style";
import { Card } from "../../interfaces/Card";
import { FormAnswer } from "../../interfaces/FormAnswer";
import { FaRegCalendarAlt, FaTag, FaUserAlt } from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { ClickAwayListener, styled, Tooltip, TooltipProps } from "@material-ui/core";
import QuickActionBox from "../QuickActionBox";

interface BulkActionsBarProps {
    flow_id: number;
    cardsSelected?: Card[];
    formAnswersSelect?: FormAnswer[];
    onClose: () => Promise<void>;
    onForceUpdate?: (id_card_items?: number[], isUpdateAll?: boolean) => void;
}

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    "& .MuiTooltip-tooltip": {
        fontSize: "12px",
        padding: "5px 10px",
    },
    "& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom": {
        backgroundColor: "#1F2D32",
        color: 'white',
        boxShadow: theme.shadows[1],
    },
}));

const BulkActionsBar: React.FC<BulkActionsBarProps> = ({ flow_id, cardsSelected, formAnswersSelect, onClose, onForceUpdate }) => {

    const [type] = useState<'flow' | 'register'>(cardsSelected !== undefined && cardsSelected.length > 0 ? 'flow' : 'register');
    const [totalSelected, setTotalSelected] = useState<number>(0);
    const [idCardItems, setIdCardItems] = useState<number[]>([]);

    const [openUser, setOpenUser] = useState<boolean>(false);
    const [openDueDate, setOpenDueDate] = useState<boolean>(false);
    const [openTag, setOpenTag] = useState<boolean>(false);
    const [openOptions, setOpenOptions] = useState<boolean>(false);

    const handleClickAway = () => {
        setOpenDueDate(false);
        setOpenTag(false);
        setOpenOptions(false);
        setOpenUser(false);
    };

    const handleClose = useCallback(() => {
        setOpenDueDate(false);
        setOpenTag(false);
        setOpenOptions(false);
        setOpenUser(false);

        onClose();
    }, [onClose]);

    const handleSetOpen = useCallback(async (type: string) => {

        if (type === 'user') {
            const action = !openUser;
            setOpenUser(action);

            if (action) {
                setOpenDueDate(false);
                setOpenTag(false);
                setOpenOptions(false);
            }

        } else if (type === 'due_date') {
            const action = !openDueDate;
            setOpenDueDate(action);

            if (action) {
                setOpenUser(false);
                setOpenTag(false);
                setOpenOptions(false);
            }
        } else if (type === 'tag') {
            const action = !openTag;
            setOpenTag(action);

            if (action) {
                setOpenUser(false);
                setOpenDueDate(false);
                setOpenOptions(false);
            }
        } else if (type === 'options') {
            const action = !openOptions;
            setOpenOptions(action);

            if (action) {
                setOpenUser(false);
                setOpenDueDate(false);
                setOpenTag(false);
            }
        }

    }, [openUser, openDueDate, openTag, openOptions]);

    useEffect(() => {
        if (cardsSelected !== undefined) {
            setTotalSelected(cardsSelected.length);
        } else if (formAnswersSelect !== undefined) {
            setTotalSelected(formAnswersSelect.length);
        }
    }, [cardsSelected, formAnswersSelect]);

    useEffect(() => {
        if (cardsSelected !== undefined) {
            setIdCardItems(cardsSelected.map(card => card.id_card));
        } else if (formAnswersSelect !== undefined) {
            setIdCardItems(formAnswersSelect.map(formAnswer => formAnswer.id_form_answer));
        }
    }, [cardsSelected, formAnswersSelect]);

    return (
        totalSelected > 0 ? (
            <ClickAwayListener onClickAway={handleClickAway}>
                <Container>
                    <LeftContainer>
                        <span>
                            {totalSelected} {type === 'flow' ? 'cartões' : 'registros'} selecionados
                        </span>
                    </LeftContainer>
                    <RightContainer>

                        <ContainerAction>
                            <DarkTooltip title={
                                <React.Fragment>
                                    <div>Editar o responsável dos cartões selecionados</div>
                                </React.Fragment>
                            }>
                                <ButtonAction onClick={() => handleSetOpen('user')}>
                                    <FaUserAlt />
                                </ButtonAction>
                            </DarkTooltip>
                            {openUser && (
                                <QuickActionBox
                                    key={"quick-action-box-user"}
                                    type="user"
                                    flow_id={flow_id}                                
                                    totalSelected={totalSelected}
                                    id_card_items={idCardItems}
                                    onForceUpdate={onForceUpdate}
                                    onClose={handleClose}
                                />
                            )}
                        </ContainerAction>

                        <ContainerAction>
                            <DarkTooltip title={
                                <React.Fragment>
                                    <div>Editar o vencimento dos cartões selecionados</div>
                                </React.Fragment>
                            }>
                                <ButtonAction onClick={() => handleSetOpen('due_date')}>
                                    <FaRegCalendarAlt />
                                </ButtonAction>
                            </DarkTooltip>
                            {openDueDate && (
                                <QuickActionBox
                                    key={"quick-action-box-due-date"}
                                    type="due_date"
                                    flow_id={flow_id}
                                    totalSelected={totalSelected}
                                    id_card_items={idCardItems}
                                    onForceUpdate={onForceUpdate}
                                    onClose={handleClose}
                                />
                            )}
                        </ContainerAction>

                        <ContainerAction>
                            <DarkTooltip title={
                                <React.Fragment>
                                    <div>Editar a etiqueta dos cartões selecionados</div>
                                </React.Fragment>
                            }>
                                <ButtonAction onClick={() => handleSetOpen('tag')}>
                                    <FaTag />
                                </ButtonAction>
                            </DarkTooltip>
                            {openTag && (
                                <QuickActionBox
                                    key={"quick-action-box-tag"}
                                    type="tag"
                                    flow_id={flow_id}
                                    totalSelected={totalSelected}
                                    id_card_items={idCardItems}
                                    onForceUpdate={onForceUpdate}
                                    onClose={handleClose}
                                />
                            )}
                        </ContainerAction>

                        <ContainerAction>
                            <DarkTooltip title={
                                <React.Fragment>
                                    <div>Outras ações</div>
                                </React.Fragment>
                            }>
                                <ButtonAction onClick={() => handleSetOpen('options')}>
                                    <HiDotsHorizontal />
                                </ButtonAction>
                            </DarkTooltip>
                            {openOptions && (
                                <QuickActionBox
                                    key={"quick-action-box-options"}
                                    type="options"
                                    flow_id={flow_id}
                                    totalSelected={totalSelected}
                                    id_card_items={idCardItems}
                                    onForceUpdate={onForceUpdate}
                                    onClose={handleClose}
                                />
                            )}
                        </ContainerAction>

                        <ContainerAction>
                            <DarkTooltip title={
                                <React.Fragment>
                                    <div>Fechar barra de ações</div>
                                </React.Fragment>
                            }>
                                <ButtonAction onClick={() => onClose()}>
                                    <MdClose />
                                </ButtonAction>
                            </DarkTooltip>
                        </ContainerAction>

                    </RightContainer>
                </Container>
            </ClickAwayListener>
        ) :
            (
                <></>
            )
    );

}

export default BulkActionsBar;