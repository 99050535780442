import 'react-quill/dist/quill.snow.css';

import {
    DialogContainer,
    DialogTopRight,
    HeaderEmail,
    HeaderEmailSubject
} from "./style";
import React, { useCallback } from "react";

import { AiOutlineClose } from "react-icons/ai";
import { Email as EmailInterface } from "../../interfaces/Email";
import { Flow } from '../../interfaces/Flow';
import EmailContainer from './Email';

interface EmailDialogProps {
    open: boolean;
    typeUser: string;
    id_card?: number;
    flow?: Flow;
    flowAddress?: string;
    onClose: () => void;
    email?: EmailInterface;
}

const EmailDialog: React.FC<EmailDialogProps> = ({ open, onClose, email, flow, flowAddress, typeUser, id_card }) => {

    const handleClose = useCallback(async () => {

        onClose();

    }, [onClose]);

    return (
        <DialogContainer
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
        >
            <HeaderEmail>
                <HeaderEmailSubject>
                    {email?.subject !== undefined ? email.subject : "Nova Mensagem"}
                </HeaderEmailSubject>

                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose /></button>
                </DialogTopRight>
            </HeaderEmail>

            <EmailContainer
                typeUser={typeUser}
                id_card={id_card}
                flow={flow}
                flowAddress={flowAddress}
                onClose={onClose}
                email={email}
            />

        </DialogContainer>
    );

}

export default EmailDialog;