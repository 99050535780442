import React, { useCallback, useEffect, useState } from "react";

import {
    AutomationConfigItemImage,
    BlockAutomation,
    BlockAutomationAction,
    BlockAutomationBody,
    BlockAutomationEmpty,
    BlockAutomationEmptyLeft,
    BlockAutomationEmptyRight,
    BlockAutomationHeader,
    BlockAutomationLeft,
    BlockAutomationRight,
    BlockAutomationStatusConfig,
    BlockAutomationStatusConfigFixed,
    BlockConditionalAction,
    BlockConditionalActionAddItem,
    BlockConditionalActionBody,
    BlockConditionalActionDescription,
    BlockConditionalActionDivider,
    BlockConditionalActionHeader,
    BlockConditionalActionItem,
    BlockConnection,
    ButtonClose,
    ButtonSave,
    Container,
    ContainerAutomation,
    ContainerBlockAutomation,
    ContainerConfig,
    ContainerConfigLeft,
    ContainerConfigRight
} from "./style";
import AppBarHeader from "../../../components/AppBarHeader";
import { IoFlash, IoTrash } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
import DialogAction from "../../DialogAction";
import NewAutomationConfigBar from "./NewAutomationConfigBar";
import SelectMenu from "./SelectMenu";
import { FaPlus } from "react-icons/fa";
import Automation from "../../../interfaces/Automation";
import { Flow } from "../../../interfaces/Flow";
import Trigger from "../../../interfaces/Trigger";
import Action from "../../../interfaces/Action";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import ConfigMenu from "./ConfigMenu";
import { Condition } from "../../../interfaces/Condition";
import { FiAlertTriangle, FiCheck } from "react-icons/fi";
import AutomationAction from "../../../interfaces/AutomationAction";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import RunHistoryMenu from "./RunHistoryMenu";
import IntegrationAuth from "../../../interfaces/IntegrationAuth";

interface NewAutomationProps {
    flow: Flow;
    selectedAutomation?: Automation;
    daysLog: number;
    onClose: () => void;
}

const NewAutomation: React.FC<NewAutomationProps> = ({ flow, selectedAutomation, daysLog, onClose }) => {

    const { addToast } = useToast();

    const [loading, setLoading] = useState<boolean>(false);

    const [automation, setAutomation] = useState<Automation>();
    const [nameAutomation, setNameAutomation] = useState<string>('Nova Automação');
    const [activeAutomation, setActiveAutomation] = useState<boolean>(true);
    const [descriptionAutomation] = useState<string>('');
    const [triggerAutomation, setTriggerAutomation] = useState<Trigger>();
    const [actionsAutomation, setActionsAutomation] = useState<Action[]>([]);
    const [deletedActionsAutomation, setDeletedActionsAutomation] = useState<Action[]>([]);
    const [conditionsAutomation, setConditionsAutomation] = useState<Condition[]>();
    const [conditionReference, setConditionReferenca] = useState<Condition>();
    const [isActionConditional, setIsActionConditional] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<Trigger | Action | Condition>();
    const [filterSchema, setFilterSchema] = useState<string | undefined>();
    const [filterStateMessage, setFilterStateMessage] = useState<string>();

    const [allowSave, setAllowSave] = useState<boolean>(false);
    const [openCloseConfirm, setOpenCloseConfirm] = useState(false);

    const [openConfig, setOpenConfig] = useState<boolean>(false);
    const [typeConfig, setTypeConfig] = useState<'trigger' | 'action' | 'condition' | undefined>('trigger');

    const handleOpenLogHistory = useCallback(() => {

        setOpenConfig(true);
        setTypeConfig(undefined);
        setSelectedItem(undefined);

    }, []);

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    const handleSave = useCallback(async () => {

        setLoading(true);

        //Update the automation object to save
        let newAutomation = automation;

        if (newAutomation !== undefined) {

            newAutomation.active = activeAutomation ? 'S' : 'N';
            newAutomation.conditional = isActionConditional ? 'S' : 'N';
            newAutomation.name = nameAutomation;
            newAutomation.description = descriptionAutomation;

            //Update trigger object
            if (triggerAutomation !== undefined) {
                newAutomation.trigger_id = triggerAutomation.id_trigger;
                newAutomation.trigger_config_fields = triggerAutomation.config_fields_values;

                if (triggerAutomation.flow_target_id !== undefined && triggerAutomation.flow_target_id !== null) {
                    newAutomation.flow_target_id = triggerAutomation.flow_target_id;
                } else {
                    newAutomation.flow_target_id = undefined;
                }
            }

            let newAutomationActions: AutomationAction[] = [];

            //Update actions object
            if (actionsAutomation !== undefined && actionsAutomation.length > 0) {

                actionsAutomation.map((action, index) => {

                    let newAutoAction: AutomationAction = {
                        id_automation_action: action.automation_action?.id_automation_action,
                        automation_id: automation?.id_automation,
                        hash: action.hash,
                        index: index + 1,
                        action_id: action.id_action,
                        description: action.config_description !== undefined && action.config_description !== null ? action.config_description : undefined,
                        action_config_fields: action.config_fields_values,
                        integration_auth_company_id: action.integration_auth_company_id !== undefined && action.integration_auth_company_id !== null ? action.integration_auth_company_id : undefined
                    }

                    newAutomationActions.push(newAutoAction);

                    return action;
                });

            }

            if (deletedActionsAutomation !== undefined && deletedActionsAutomation.length > 0) {

                //Add the deleted actions
                deletedActionsAutomation.map((action, index) => {

                    if (action.automation_action !== undefined && action.automation_action.id_automation_action !== undefined) {
                        let newAutoAction: AutomationAction = {
                            id_automation_action: action.automation_action?.id_automation_action,
                            automation_id: automation?.id_automation,
                            hash: action.hash,
                            index: index + 1,
                            action_id: action.id_action,
                            description: action.config_description !== undefined && action.config_description !== null ? action.config_description : undefined,
                            action_config_fields: action.config_fields_values,
                            deleted: "S",
                            integration_auth_company_id: action.integration_auth_company_id !== undefined && action.integration_auth_company_id !== null ? action.integration_auth_company_id : undefined
                        }

                        newAutomationActions.push(newAutoAction);
                    }

                    return action;
                });

            }

            //Add the conditional actions
            if (conditionsAutomation !== undefined && conditionsAutomation.length > 0) {

                conditionsAutomation.map((condition, index) => {

                    if (condition.actions !== undefined && condition.actions.length > 0) {

                        condition.actions.map((action, indexAction) => {

                            let newAutoAction: AutomationAction = {
                                id_automation_action: action.automation_action?.id_automation_action,
                                automation_id: automation?.id_automation,
                                hash: action.hash,
                                index: indexAction + 1,
                                indexCondition: index + 1,
                                action_id: action.id_action,
                                description: action.config_description !== undefined && action.config_description !== null ? action.config_description : undefined,
                                descriptionCondition: condition.config_description !== undefined && condition.config_description !== null ? condition.config_description : undefined,
                                action_config_fields: action.config_fields_values,
                                action_config_conditions: condition.config_fields_values !== undefined && condition.config_fields_values !== null ? condition.config_fields_values : undefined,
                                integration_auth_company_id: action.integration_auth_company_id !== undefined && action.integration_auth_company_id !== null ? action.integration_auth_company_id : undefined
                            }

                            newAutomationActions.push(newAutoAction);

                            return action;
                        });

                    }

                    return condition;
                });

            }

            if (newAutomationActions.length > 0) {
                newAutomation.actions = newAutomationActions;
            }

            await api
                .post('/automation', newAutomation)
                .then(response => {

                    const respAutomation = response.data as Automation;

                    if (respAutomation !== undefined) {

                        setAutomation(respAutomation);

                        onClose();

                        addToast({
                            type: 'success',
                            title: 'Wow! Automação criada com sucesso!',
                            description: 'Você publicou sua nova automação com sucesso!',
                        });

                    }

                    setLoading(false);
                }).catch(error => {
                    setLoading(false);
                    console.log(error);
                    addToast({
                        type: 'error',
                        title: 'Erro ao inserir um novo item [1]',
                        description: 'Ocorreu um erro ao inserir o registro!',
                    });
                });

        }

    }, [actionsAutomation, activeAutomation, automation, descriptionAutomation, nameAutomation, triggerAutomation, deletedActionsAutomation, addToast, onClose, conditionsAutomation, isActionConditional]);

    const handleRemoveAction = useCallback((action: Action) => {

        setDeletedActionsAutomation([...deletedActionsAutomation, action]);

        let newActions = actionsAutomation?.filter((item) => item.hash !== action.hash);

        setActionsAutomation(newActions);
        setSelectedItem(undefined);
        setOpenConfig(false);
        setFilterSchema(undefined);
        setFilterStateMessage(undefined);

    }, [actionsAutomation, deletedActionsAutomation]);

    const handleRemoveActionFromCondition = useCallback((condition: Condition, action: Action) => {

        setDeletedActionsAutomation([...deletedActionsAutomation, action]);

        let newCondition = condition;

        if (newCondition !== undefined) {

            newCondition.actions = newCondition.actions?.filter((item) => item.hash !== action.hash);

            setConditionsAutomation(
                conditionsAutomation?.map((condition) => {

                    if (condition.hash === newCondition.hash) {
                        condition = newCondition
                    }

                    return condition;
                })
            )
        }

        setSelectedItem(undefined);
        setConditionReferenca(undefined);
        setOpenConfig(false);
        setFilterSchema(undefined);
        setFilterStateMessage(undefined);

    }, [conditionsAutomation, deletedActionsAutomation]);

    const handleRemoveCondition = useCallback((condition: Condition) => {

        if (condition.actions !== undefined && condition.actions.length > 0) {

            let newDeletedActions = deletedActionsAutomation;

            condition.actions.map((action) => {

                newDeletedActions.push(action);

                return action;
            });

            setDeletedActionsAutomation(newDeletedActions);
        }

        let newConditions = conditionsAutomation?.filter((item) => item.hash !== condition.hash);

        setConditionsAutomation(newConditions);
        setSelectedItem(undefined);
        setConditionReferenca(undefined);
        setOpenConfig(false);
        setFilterSchema(undefined);
        setFilterStateMessage(undefined);

        if (newConditions === undefined || newConditions.length === 0) {
            setIsActionConditional(false);
        }


    }, [conditionsAutomation, deletedActionsAutomation]);

    const handleAddAdvancedLogic = useCallback((id_logic: number) => {

        var hashLib = require('object-hash');
        const dateNowTime = new Date().getTime();
        const randomNumber = Math.floor(Math.random() * 1000);

        if (id_logic === -1 && automation !== undefined) {

            const hash = hashLib(dateNowTime + flow.id_flow + randomNumber + automation.hash + conditionsAutomation?.length);

            const newCondition: Condition = {
                hash: hash,
                name: 'Lógica Condicional',
                description: 'Execute diferentes ações de acordo com condições específicas',
                index: conditionsAutomation !== undefined && conditionsAutomation.length >= 1 ? conditionsAutomation.length + 1 : 1,
                icon: 'FaProjectDiagram',
                color: '#a1a0a0',
                status_config: 'pending'
            };

            setConditionsAutomation([newCondition]);
            setTypeConfig('condition');
            setSelectedItem(newCondition)
            setIsActionConditional(true);

        }

    }, [conditionsAutomation, flow.id_flow, automation]);

    const handleAddCondition = useCallback(() => {

        var hashLib = require('object-hash');
        const dateNowTime = new Date().getTime();
        const randomNumber = Math.floor(Math.random() * 1000);

        if (automation !== undefined) {
            const hash = hashLib(dateNowTime + flow.id_flow + randomNumber + automation.hash + conditionsAutomation?.length);

            const newCondition: Condition = {
                name: 'Lógica Condicional',
                hash: hash,
                description: 'Execute diferentes ações de acordo com condições específicas',
                index: conditionsAutomation !== undefined && conditionsAutomation.length >= 1 ? conditionsAutomation.length + 1 : 1,
                icon: 'FaProjectDiagram',
                color: '#a1a0a0',
                status_config: 'pending'
            };

            let newConditions = conditionsAutomation;

            if (newCondition !== undefined) {
                newConditions?.push(newCondition);
            } else {
                newConditions = [newCondition];
            }

            setFilterSchema(undefined);
            setFilterStateMessage(undefined);
            setConditionsAutomation(newConditions);
            setTypeConfig('condition');
            setSelectedItem(newCondition);
            setOpenConfig(true);
            setIsActionConditional(true);
        }

    }, [conditionsAutomation, flow.id_flow, automation]);

    const handleSelectItem = useCallback((type: 'trigger' | 'action', item: Trigger | Action) => {

        var hashLib = require('object-hash');
        const dateNowTime = new Date().getTime();
        const randomNumber = Math.floor(Math.random() * 1000);

        if (type === 'trigger') {

            setTriggerAutomation(item as Trigger);
            setSelectedItem(item);

        } else if (type === 'action' && automation !== undefined) {

            let newItem = item as Action;

            const hashAction = hashLib(dateNowTime + flow.id_flow + randomNumber + automation.hash);
            newItem.hash = hashAction;

            if (!isActionConditional && (conditionsAutomation === undefined || conditionsAutomation.length === 0)) {

                //Update the index
                if (actionsAutomation !== undefined && actionsAutomation.length > 0) {
                    newItem.index = actionsAutomation.length + 1;
                } else {
                    newItem.index = 1;
                }

                setActionsAutomation([...actionsAutomation, newItem as Action]);
                setSelectedItem(item);
            } else if (conditionReference !== undefined) {
                let newCondition = conditionReference as Condition;

                if (newCondition !== undefined) {

                    if (newCondition.actions === undefined) {

                        newItem.index = 1;

                        newCondition.actions = [item as Action];
                    } else {

                        //Update the index
                        if (newCondition.actions !== undefined && newCondition.actions.length > 0) {
                            newItem.index = newCondition.actions.length + 1;
                        } else {
                            newItem.index = 1;
                        }

                        newCondition.actions = [...newCondition.actions, newItem as Action];
                    }

                    setConditionsAutomation(
                        conditionsAutomation?.map((condition) => {

                            if (condition.hash === newCondition.hash) {
                                condition = newCondition
                            }

                            return condition;
                        })
                    )
                    setSelectedItem(item);
                }
            }

        } else {

        }

    }, [actionsAutomation, conditionsAutomation, isActionConditional, conditionReference, flow.id_flow, automation]);

    const handleSaveIntegration = useCallback((type: 'trigger' | 'action' | 'condition', selectedItem: Trigger | Action | Condition, integration_auth_id: number, id_integration_auth_company: number) => {

        if (type === 'action') {

            let newAction = selectedItem as Action;

            if (newAction !== undefined) {
                newAction.integration_auth_company_id = id_integration_auth_company;
            }

            api.get(`/integration/auth-company/by-company`, {
                params: {
                    id_integration_auth: integration_auth_id
                }
            }).then(response => {
                if (response.data !== null) {

                    let retIntegrations: IntegrationAuth = response.data;

                    if (retIntegrations !== undefined) {

                        //Run into to find the integration and update
                        if (newAction !== undefined && newAction.integration !== undefined) {
                            if (newAction.integration.integrations_auth !== undefined) {

                                for (let index = 0; index < newAction.integration.integrations_auth.length; index++) {
                                    const ia = newAction.integration.integrations_auth[index];

                                    if (ia.id_integration_auth === integration_auth_id) {
                                        newAction.integration.integrations_auth[index] = retIntegrations;
                                        break;
                                    }

                                }
                                setSelectedItem(newAction);
                            }
                        }
                    }
                }
            }).catch(error => {
                setSelectedItem(newAction);
                addToast({
                    type: 'error',
                    title: 'Não foi possível carregar as integrações disponíveis',
                    description: 'Ocorreu um erro ao carregar as integrações disponíveis!',
                });
            });

        }

    }, [addToast]);

    const handleSaveMenuConfig = useCallback((type: 'trigger' | 'action' | 'condition', selectedItem: Trigger | Action | Condition, data?: object[]) => {

        if (type === 'trigger') {

            let newTrigger = selectedItem as Trigger;

            if (newTrigger !== undefined && (data !== undefined || newTrigger.config_fields === null)) {
                newTrigger.status_config = 'completed';
                newTrigger.config_fields_values = JSON.stringify(data);

                if (newTrigger.id_trigger === 16) { //Todos os cartões conectados forem movidos - It is need execute in a target flow

                    const newData = data as unknown as Record<string, any>;

                    const arrFlowId = Object.entries(newData).filter(function (key) { return key.includes('trigger_flow_target_id') });

                    if (arrFlowId !== undefined && arrFlowId.length > 0) {

                        //Get the value of the selected flow
                        const flowId = arrFlowId[0][1] as number[];

                        if (flowId !== undefined && flowId !== null && flowId.length > 0) {
                            newTrigger.flow_target_id = Number(flowId);
                        }

                    }

                }

                setTriggerAutomation(newTrigger);

                //Rule for title 
                if (nameAutomation === "Nova Automação") {
                    setNameAutomation("Quando '" + newTrigger.name + "' faça...");
                }
            } else {
                newTrigger.status_config = 'pending';
                setTriggerAutomation(newTrigger);
            }

        } else if (type === 'action') {

            let newAction = selectedItem as Action;

            if (newAction !== undefined && data !== undefined) {

                if (newAction.integration !== undefined && newAction.integration_auth_company_id === undefined) {
                    newAction.status_config = 'pending';
                } else {
                    newAction.status_config = 'completed';
                }

                newAction.config_fields_values = JSON.stringify(data);
                setSelectedItem(newAction);
            } else {
                newAction.status_config = 'pending';
                setSelectedItem(newAction);
            }

        } else if (type === 'condition') {

            let newCondition = selectedItem as Condition;

            if (newCondition !== undefined && data !== undefined) {
                newCondition.status_config = 'completed';
                newCondition.config_fields_values = JSON.stringify(data);

                if (data !== undefined && data !== null && data.length > 0) {
                    let newData = data as unknown as { name: string, value: string | undefined }[];
                    let nameObj = newData.find(obj => obj.name === 'name');

                    if (nameObj !== undefined && nameObj.value !== undefined && nameObj.value !== null && nameObj.value !== '') {
                        newCondition.name = nameObj.value as string;
                    }
                }

                setSelectedItem(newCondition);
            } else {

                if (selectedItem.index > 1 && conditionsAutomation !== undefined && conditionsAutomation.length > 1) {
                    newCondition.status_config = 'completed';
                } else {
                    newCondition.status_config = 'pending';
                }

                newCondition.name = "Lógica Condicional";

                setSelectedItem(newCondition);
            }

        }


    }, [nameAutomation, conditionsAutomation]);

    const handleSaveDescription = useCallback((type: 'trigger' | 'action' | 'condition', selectedItem: Trigger | Action | Condition, description: string) => {

        if (type === 'action') {

            let newAction = selectedItem as Action;

            if (newAction !== undefined) {
                newAction.config_description = description;
                setSelectedItem(newAction);
            }

        } else if (type === 'condition') {

            let newCondition = selectedItem as Condition;

            if (newCondition !== undefined) {
                newCondition.config_description = description;
                setSelectedItem(newCondition);
            }

        }

    }, []);

    const handleCloseMenuConfig = useCallback(() => {

        setOpenConfig(false);
        setSelectedItem(undefined);
        setFilterSchema(undefined);
        setFilterStateMessage(undefined);

    }, []);

    useEffect(() => {

        var hashLib = require('object-hash');
        const dateNowTime = new Date().getTime();
        const randomNumber = Math.floor(Math.random() * 1000);

        if (selectedAutomation === undefined) {

            const hashAutomation = hashLib(dateNowTime + flow.id_flow + randomNumber);

            //Create a new automation
            setAutomation({
                flow_id: flow.id_flow,
                company_id: flow.company_id,
                hash: hashAutomation,
                name: 'Nova Automação',
                active: 'S',
            });

            //Open the right menu
            setTypeConfig('trigger');
            setOpenConfig(true);
            setSelectedItem(undefined);

        } else if (selectedAutomation !== undefined) {

            //Build the objects
            const isConditional = selectedAutomation.conditional !== undefined && selectedAutomation.conditional !== null && selectedAutomation.conditional === 'S' ? true : false;

            setAutomation(selectedAutomation);

            setNameAutomation(selectedAutomation.name);
            setActiveAutomation(selectedAutomation.active === 'S' ? true : false);
            setIsActionConditional(isConditional);

            //Set the trigger
            if (selectedAutomation.trigger !== undefined) {

                let newTrigger = selectedAutomation.trigger;
                newTrigger.status_config = 'completed';
                newTrigger.config_fields_values = selectedAutomation.trigger_config_fields !== undefined ? selectedAutomation.trigger_config_fields : undefined;

                setTriggerAutomation(selectedAutomation.trigger);
            }

            //Set the actions
            if (!isConditional && selectedAutomation.actions !== undefined && selectedAutomation.actions.length > 0) {

                let newActions: Action[] = [];

                selectedAutomation.actions.map((automationAction) => {

                    if (automationAction.action !== undefined) {

                        let newAction: Action = automationAction.action;

                        newAction.status_config = 'completed';
                        newAction.config_fields_values = automationAction.action_config_fields;
                        newAction.config_description = automationAction.description;
                        newAction.automation_action = automationAction;
                        newAction.hash = automationAction.hash;
                        newAction.index = automationAction.index;
                        newAction.integration_auth_company_id = automationAction.integration_auth_company_id;

                        newActions.push(newAction);

                    }

                    return automationAction;
                });

                setActionsAutomation(newActions);

            }

            //Set the conditions
            if (isConditional && selectedAutomation.actions !== undefined && selectedAutomation.actions.length > 0) {

                let newConditions: Condition[] = [];

                selectedAutomation.actions.map((automationAction) => {

                    if (automationAction.action !== undefined) {

                        let newAction: Action = automationAction.action;

                        newAction.status_config = 'completed';
                        newAction.config_fields_values = automationAction.action_config_fields;
                        newAction.config_description = automationAction.description;
                        newAction.automation_action = automationAction;
                        newAction.hash = automationAction.hash;
                        newAction.index = automationAction.index;
                        newAction.integration_auth_company_id = automationAction.integration_auth_company_id;

                        if (automationAction.indexCondition !== undefined && automationAction.indexCondition !== null) {

                            //Try to find first the condition in the newConditions with the same index 
                            let condition = newConditions.find((condition) => condition.index === automationAction.indexCondition);

                            //Valid if the condition already exists, if not create a new one
                            if (condition !== undefined) {

                                if (condition.actions !== undefined) {
                                    condition.actions.push(newAction);
                                } else {
                                    condition.actions = [newAction];
                                }

                            } else {

                                let newCondition: Condition = {
                                    name: 'Lógica Condicional',
                                    hash: automationAction.hash,
                                    description: 'Execute diferentes ações de acordo com condições específicas',
                                    index: automationAction.indexCondition !== undefined ? automationAction.indexCondition : 1,
                                    icon: 'FaProjectDiagram',
                                    color: '#a1a0a0',
                                    status_config: 'completed',
                                    config_fields_values: automationAction.action_config_conditions,
                                    config_description: automationAction.descriptionCondition,
                                    actions: [newAction]
                                };

                                if (automationAction.action_config_conditions !== undefined && automationAction.action_config_conditions !== null && automationAction.action_config_conditions !== '') {
                                    const newData = JSON.parse(automationAction.action_config_conditions) as unknown as { name: string, value: string | undefined }[];

                                    let nameObj = newData.find(obj => obj.name === 'name');

                                    if (nameObj !== undefined && nameObj.value !== undefined && nameObj.value !== null && nameObj.value !== '') {
                                        newCondition.name = nameObj.value as string;
                                    }

                                }

                                newConditions.push(newCondition);

                            }

                        }

                    }

                    return automationAction;
                });

                setConditionsAutomation(newConditions);

            }

        }

    }, [flow, selectedAutomation]);

    useEffect(() => {

        //Valid the trigger
        const triggerReady = triggerAutomation !== undefined && triggerAutomation.status_config === 'completed';

        //Valid the actions
        const actionsReady = actionsAutomation !== undefined && actionsAutomation.length > 0 && actionsAutomation.filter((action) => action.status_config === 'completed').length === actionsAutomation.length;

        //Valid the conditions
        const conditionsReady = conditionsAutomation !== undefined && conditionsAutomation.length > 0 && conditionsAutomation.filter((condition) => condition.status_config === 'completed').length === conditionsAutomation.length;

        //Valid the actions in the conditions
        let actionsReadyInConditions = true;
        if (conditionsAutomation !== undefined && conditionsAutomation.length > 0) {
            conditionsAutomation.map((condition) => {
                if (condition.actions !== undefined && condition.actions.length > 0) {
                    if (condition.actions.filter((action) => action.status_config === 'completed').length !== condition.actions.length) {
                        actionsReadyInConditions = false;
                    }
                } else {
                    actionsReadyInConditions = false;
                }
                return condition;
            });
        } else {
            actionsReadyInConditions = false;
        }

        if ((triggerReady && actionsReady) || (triggerReady && actionsReadyInConditions && conditionsReady)) {
            setAllowSave(true);
        } else {
            setAllowSave(false);
        }

    }, [actionsAutomation, triggerAutomation, openConfig, conditionsAutomation]);

    return (
        <Container key={flow.id_flow}>

            {/* Header Page */}
            <AppBarHeader
                title={nameAutomation}
                icon={IoFlash}
                iconDynamicColor="#f23b5d"
                titleDynamic={nameAutomation}
                setTitleDynamic={setNameAutomation}
            >

                <ButtonSave
                    className="new-item-btn"
                    type="button"
                    color="#f23b5c"
                    margin="0px 15px 0px 0px"
                    onClick={() => handleSave()}
                    disabled={!allowSave}
                    isLoading={loading}
                >
                    Publicar
                </ButtonSave>

                <ButtonClose type="button" onClick={allowSave && automation?.id_automation === undefined ? () => setOpenCloseConfirm(true) : onClose}>
                    <AiOutlineClose />
                </ButtonClose>

            </AppBarHeader>

            <NewAutomationConfigBar
                activeAutomation={activeAutomation}
                setActiveAutomation={setActiveAutomation}
                id_automation={automation?.id_automation}
                flow_id={flow.id_flow}
                last_run={automation?.last_run}
                handleOpenLogHistory={handleOpenLogHistory}
                onClose={onClose}
            />

            <DialogAction
                open={openCloseConfirm}
                message={"Você tem certeza que deseja descartar esta automação?"}
                actions_items={[
                    "Perda das alterações realizadas na automação"
                ]}
                disclaimer={"Lembrando que após a confirmação não será mais possível recuperar os dados referentes a Automação!"}
                onSubmmit={handleClose}
                onClose={() => setOpenCloseConfirm(false)}
                type={1}
            />

            <ContainerConfig>
                <ContainerConfigLeft isSplited={openConfig}>
                    <ContainerAutomation onClick={() => {
                        setOpenConfig(false);
                        setSelectedItem(undefined);
                        setConditionReferenca(undefined);
                        setFilterSchema(undefined);
                        setFilterStateMessage(undefined);
                    }}>

                        {/* TRIGGER */}
                        {triggerAutomation === undefined ?
                            <BlockAutomationEmpty key={'trigger'} onClick={(e) => {
                                e.stopPropagation();
                                setTypeConfig('trigger');
                                setOpenConfig(true);
                                setSelectedItem(undefined);
                            }}>
                                <BlockAutomationEmptyLeft>
                                    <FaPlus />
                                </BlockAutomationEmptyLeft>
                                <BlockAutomationEmptyRight>
                                    <span>Gatilho</span>
                                    <h3>Quando isso acontecer...</h3>
                                </BlockAutomationEmptyRight>
                            </BlockAutomationEmpty> :
                            <BlockAutomation
                                color={triggerAutomation.color}
                                isSelected={selectedItem !== undefined && selectedItem === triggerAutomation}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setTypeConfig('trigger');
                                    setSelectedItem(triggerAutomation);
                                    setOpenConfig(true);
                                }}
                            >
                                {triggerAutomation.status_config === undefined || triggerAutomation.status_config === 'pending' ?
                                    <BlockAutomationStatusConfig>
                                        <FiAlertTriangle />
                                    </BlockAutomationStatusConfig> :
                                    <BlockAutomationStatusConfig style={{ backgroundColor: 'white' }}>
                                        <FiCheck style={{ color: 'green', marginTop: '3px' }} />
                                    </BlockAutomationStatusConfig>
                                }
                                <BlockAutomationHeader>
                                    <BlockAutomationLeft color={triggerAutomation.color}>
                                        <IconPickerItem
                                            icon={triggerAutomation.icon as IconList}
                                            color={"white"}
                                        />
                                    </BlockAutomationLeft>
                                    <BlockAutomationRight>
                                        <span>Gatilho</span>
                                        <h3>{triggerAutomation.name}</h3>
                                    </BlockAutomationRight>
                                </BlockAutomationHeader>
                                <BlockAutomationAction onClick={(e) => {
                                    e.stopPropagation()
                                    setSelectedItem(undefined);
                                    setTriggerAutomation(undefined);
                                    setOpenConfig(false);
                                    setFilterSchema(undefined);
                                    setFilterStateMessage(undefined);
                                }}>
                                    <IoTrash />
                                </BlockAutomationAction>
                                <BlockAutomationBody>
                                </BlockAutomationBody>
                            </BlockAutomation>
                        }

                        {triggerAutomation !== undefined && (
                            <BlockConnection />
                        )}

                        {/* ACTIONS */}
                        {triggerAutomation !== undefined && (actionsAutomation === undefined || actionsAutomation.length === 0) && isActionConditional === false ?
                            <BlockAutomationEmpty key={'action'} onClick={(e) => {
                                e.stopPropagation();
                                setTypeConfig('action');
                                setOpenConfig(true);
                                setSelectedItem(undefined);
                            }}>
                                <BlockAutomationEmptyLeft>
                                    <FaPlus />
                                </BlockAutomationEmptyLeft>
                                <BlockAutomationEmptyRight>
                                    <span>Ação</span>
                                    <h3>Faça isso...</h3>
                                </BlockAutomationEmptyRight>
                            </BlockAutomationEmpty> :
                            isActionConditional !== false && conditionsAutomation !== undefined && conditionsAutomation.length > 0 ?
                                <>
                                    {/* CONDITIONAL ACTION */}
                                    {conditionsAutomation.map((condition, index) => {
                                        return (
                                            <BlockConditionalAction
                                                key={condition.hash}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (openConfig && (typeConfig === 'condition' || typeConfig === 'action')) {
                                                        setTypeConfig(undefined);
                                                        setSelectedItem(undefined);
                                                        setOpenConfig(false);
                                                        setFilterSchema(undefined);
                                                        setFilterStateMessage(undefined);
                                                    } else {
                                                        setTypeConfig('condition');
                                                        setSelectedItem(condition);
                                                        setOpenConfig(true);
                                                    }
                                                }}
                                                color="#bab6b6"
                                                isSelected={selectedItem !== undefined && selectedItem === condition}
                                                style={index === 0 ? { borderRadius: '10px 10px 0px 0px', boxShadow: 'none', borderBottom: 'none' } : { borderRadius: '0px', boxShadow: 'none', borderBottom: 'none' }}
                                            >
                                                {condition.status_config === undefined || condition.status_config === 'pending' ?
                                                    <BlockAutomationStatusConfig>
                                                        <FiAlertTriangle />
                                                    </BlockAutomationStatusConfig> :
                                                    <BlockAutomationStatusConfig style={{ backgroundColor: 'white' }}>
                                                        <FiCheck style={{ color: 'green' }} />
                                                    </BlockAutomationStatusConfig>
                                                }
                                                <BlockConditionalActionItem>
                                                    <BlockConditionalActionHeader>
                                                        {condition !== undefined && condition.name !== undefined && condition.name !== "Lógica Condicional" && condition.config_fields_values !== undefined ? <h3>{condition.name}</h3> : index >= 1 ? <h3>Se não...</h3> : <h3>Se...</h3>}
                                                    </BlockConditionalActionHeader>
                                                    <BlockConditionalActionDescription>
                                                        {condition.config_description !== undefined && condition.config_description !== null && condition.config_description !== '' ?
                                                            <p>{condition.config_description}</p> :
                                                            <label>Configure as condições específicas para a execução das ações abaixo</label>
                                                        }
                                                    </BlockConditionalActionDescription>
                                                    <BlockAutomationAction onClick={(e) => {
                                                        e.stopPropagation()
                                                        handleRemoveCondition(condition);
                                                    }}>
                                                        <IoTrash />
                                                    </BlockAutomationAction>

                                                    <BlockConditionalActionBody>

                                                        {condition.actions !== undefined && condition.actions.length > 0 ?
                                                            <>
                                                                {condition.actions.map((action) => (
                                                                    <ContainerBlockAutomation
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            if (openConfig && (typeConfig === 'condition' || typeConfig === 'action')) {
                                                                                setTypeConfig(undefined);
                                                                                setSelectedItem(undefined);
                                                                                setOpenConfig(false);
                                                                                setFilterSchema(undefined);
                                                                                setFilterStateMessage(undefined);
                                                                            } else {
                                                                                setTypeConfig('action');
                                                                                setSelectedItem(action);
                                                                                setOpenConfig(true);
                                                                            }
                                                                        }}
                                                                    >
                                                                        {action.status_config === undefined || action.status_config === 'pending' ?
                                                                            <BlockAutomationStatusConfigFixed>
                                                                                <FiAlertTriangle />
                                                                            </BlockAutomationStatusConfigFixed> :
                                                                            <BlockAutomationStatusConfigFixed style={{ backgroundColor: 'white' }}>
                                                                                <FiCheck style={{ color: 'green', marginTop: '3px' }} />
                                                                            </BlockAutomationStatusConfigFixed>
                                                                        }
                                                                        <BlockAutomation
                                                                            key={action.hash}
                                                                            color={action.color}
                                                                            isSelected={selectedItem !== undefined && selectedItem === action}
                                                                            style={{ width: '100%', zIndex: '5', marginBottom: '10px' }}
                                                                        >
                                                                            <BlockAutomationHeader>
                                                                                <BlockAutomationLeft color={action.color}>
                                                                                    <IconPickerItem
                                                                                        icon={action.icon as IconList}
                                                                                        color={"white"}
                                                                                    />
                                                                                </BlockAutomationLeft>
                                                                                <BlockAutomationRight>
                                                                                    {!(action.config_description !== undefined && action.config_description !== null && action.config_description !== '') && (
                                                                                        <span>Ação</span>
                                                                                    )}
                                                                                    <h3>{action.name}</h3>
                                                                                    {action.config_description !== undefined && action.config_description !== null && action.config_description !== '' && (
                                                                                        <span style={{ marginTop: '5px', marginBottom: '5px' }}>{action.config_description}</span>
                                                                                    )}
                                                                                </BlockAutomationRight>
                                                                            </BlockAutomationHeader>
                                                                            <BlockAutomationAction onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                handleRemoveActionFromCondition(condition, action);
                                                                            }}>
                                                                                <IoTrash />
                                                                            </BlockAutomationAction>
                                                                            <BlockAutomationBody>
                                                                            </BlockAutomationBody>
                                                                        </BlockAutomation>
                                                                    </ContainerBlockAutomation>
                                                                ))}
                                                                {condition.actions !== undefined && condition.actions.length > 0 && condition.actions.length <= 2 && (
                                                                    <BlockAutomationEmpty
                                                                        key={'action'}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setTypeConfig('action');
                                                                            setOpenConfig(true);
                                                                            setSelectedItem(undefined);
                                                                            setConditionReferenca(condition);
                                                                        }}
                                                                        style={{ width: '100%', zIndex: '5', minHeight: '40px' }}
                                                                    >
                                                                        <BlockAutomationEmptyLeft style={{ marginRight: '10px' }}>
                                                                            <FaPlus style={{ fontSize: '13px' }} />
                                                                        </BlockAutomationEmptyLeft>
                                                                        <BlockAutomationEmptyRight>
                                                                            <h3 style={{ fontSize: '13px', marginTop: '0px' }}>Faça isso...</h3>
                                                                        </BlockAutomationEmptyRight>
                                                                    </BlockAutomationEmpty>
                                                                )}
                                                            </> :
                                                            <BlockAutomationEmpty
                                                                key={'action'}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setTypeConfig('action');
                                                                    setOpenConfig(true);
                                                                    setSelectedItem(undefined);
                                                                    setConditionReferenca(condition);
                                                                }}
                                                                style={{ width: '100%', zIndex: '5' }}
                                                            >
                                                                <BlockAutomationEmptyLeft>
                                                                    <FaPlus />
                                                                </BlockAutomationEmptyLeft>
                                                                <BlockAutomationEmptyRight>
                                                                    <span>Ação</span>
                                                                    <h3>Faça isso...</h3>
                                                                </BlockAutomationEmptyRight>
                                                            </BlockAutomationEmpty>
                                                        }

                                                    </BlockConditionalActionBody>
                                                </BlockConditionalActionItem>

                                                <BlockConditionalActionDivider />

                                            </BlockConditionalAction>
                                        )
                                    })}

                                    <BlockConditionalActionAddItem
                                        key={'condition-add'}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (openConfig && (typeConfig === 'condition' || typeConfig === 'action')) {
                                                setTypeConfig(undefined);
                                                setSelectedItem(undefined);
                                                setOpenConfig(false);
                                                setFilterSchema(undefined);
                                                setFilterStateMessage(undefined);
                                            } else {
                                                handleAddCondition();
                                            }
                                        }}
                                    >
                                        <FaPlus />
                                        <label>Adicionar nova condição</label>
                                    </BlockConditionalActionAddItem>

                                </> :
                                actionsAutomation !== undefined && actionsAutomation.length > 0 ?
                                    <>
                                        {/* ACTIONS */}
                                        {actionsAutomation.map((action, index) => (
                                            <>
                                                <BlockAutomation
                                                    key={action.hash}
                                                    color={action.color}
                                                    isSelected={selectedItem !== undefined && selectedItem === action}
                                                    style={{ width: '100%', zIndex: '5' }}
                                                >
                                                    {action.status_config === undefined || action.status_config === 'pending' ?
                                                        <BlockAutomationStatusConfig>
                                                            <FiAlertTriangle />
                                                        </BlockAutomationStatusConfig> :
                                                        <BlockAutomationStatusConfig style={{ backgroundColor: 'white' }}>
                                                            <FiCheck style={{ color: 'green', marginTop: '3px' }} />
                                                        </BlockAutomationStatusConfig>
                                                    }
                                                    <BlockAutomationHeader onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (openConfig && typeConfig === 'action') {
                                                            setTypeConfig(undefined);
                                                            setSelectedItem(undefined);
                                                            setOpenConfig(false);
                                                            setFilterSchema(undefined);
                                                            setFilterStateMessage(undefined);
                                                        } else {
                                                            setTypeConfig('action');
                                                            setSelectedItem(action);
                                                            setOpenConfig(true);
                                                        }
                                                    }
                                                    }>
                                                        <BlockAutomationLeft color={action.color} isIntegration={action.integration !== undefined && action.integration !== null}>
                                                            {action.integration !== undefined && action.integration !== null ?
                                                                <AutomationConfigItemImage size={50}>
                                                                    <img src={action.integration.url_logo} alt={action.integration.name} />
                                                                </AutomationConfigItemImage> :
                                                                <IconPickerItem
                                                                    icon={action.icon as IconList}
                                                                    color={"white"}
                                                                />
                                                            }
                                                        </BlockAutomationLeft>
                                                        <BlockAutomationRight>
                                                            {!(action.config_description !== undefined && action.config_description !== null && action.config_description !== '') && (
                                                                <span>Ação</span>
                                                            )}
                                                            <h3>{action.name}</h3>
                                                            {action.config_description !== undefined && action.config_description !== null && action.config_description !== '' && (
                                                                <span style={{ marginTop: '5px', marginBottom: '5px' }}>{action.config_description}</span>
                                                            )}
                                                        </BlockAutomationRight>
                                                    </BlockAutomationHeader>
                                                    <BlockAutomationAction onClick={(e) => {
                                                        e.stopPropagation()
                                                        handleRemoveAction(action);
                                                    }}>
                                                        <IoTrash />
                                                    </BlockAutomationAction>
                                                    <BlockAutomationBody>
                                                    </BlockAutomationBody>
                                                </BlockAutomation>

                                                {actionsAutomation !== undefined && actionsAutomation.length > 0 && index < actionsAutomation.length - 1 && (
                                                    <BlockConnection style={{ height: '25px', minHeight: '25px' }} />
                                                )}
                                            </>
                                        ))}

                                        {actionsAutomation !== undefined && actionsAutomation.length > 0 && actionsAutomation.length <= 2 && (
                                            <>
                                                <BlockConnection style={{ height: '25px', minHeight: '25px' }} />
                                                <BlockAutomationEmpty
                                                    key={'action'}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setTypeConfig('action');
                                                        setOpenConfig(true);
                                                        setSelectedItem(undefined);
                                                        setConditionReferenca(undefined);
                                                    }}
                                                    style={{ width: '100%', zIndex: '5', minHeight: '40px' }}
                                                >
                                                    <BlockAutomationEmptyLeft style={{ marginRight: '10px' }}>
                                                        <FaPlus style={{ fontSize: '13px' }} />
                                                    </BlockAutomationEmptyLeft>
                                                    <BlockAutomationEmptyRight>
                                                        <h3 style={{ fontSize: '13px', marginTop: '0px' }}>Faça isso...</h3>
                                                    </BlockAutomationEmptyRight>
                                                </BlockAutomationEmpty>
                                            </>
                                        )}

                                    </> : triggerAutomation !== undefined ?
                                        <BlockAutomationEmpty
                                            key={'action'}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setTypeConfig('action');
                                                setOpenConfig(true);
                                                setSelectedItem(undefined);
                                                setConditionReferenca(undefined);
                                            }}
                                            style={{ width: '100%', zIndex: '5' }}
                                        >
                                            <BlockAutomationEmptyLeft>
                                                <FaPlus />
                                            </BlockAutomationEmptyLeft>
                                            <BlockAutomationEmptyRight>
                                                <span>Ação</span>
                                                <h3>Faça isso...</h3>
                                            </BlockAutomationEmptyRight>
                                        </BlockAutomationEmpty> : <></>
                        }

                    </ContainerAutomation>
                </ContainerConfigLeft>
                <ContainerConfigRight isSplited={openConfig}>

                    {selectedItem === undefined && typeConfig === undefined && automation !== undefined && automation.id_automation !== undefined ?
                        <RunHistoryMenu
                            openConfig={openConfig}
                            flow={flow}
                            automation_id={automation.id_automation}
                            days={daysLog}
                            handleCloseMenuConfig={handleCloseMenuConfig}
                        /> : selectedItem === undefined && typeConfig !== undefined ?
                            <SelectMenu
                                openConfig={openConfig}
                                type={typeConfig}
                                isEnableAdvancedLogic={actionsAutomation === undefined || actionsAutomation.length === 0}
                                isActionConditional={isActionConditional}
                                parentItem={triggerAutomation}
                                handleAddAdvancedLogic={handleAddAdvancedLogic}
                                handleCloseMenuConfig={handleCloseMenuConfig}
                                handleSelectItem={handleSelectItem}
                            /> : selectedItem !== undefined && typeConfig !== undefined ?
                                <ConfigMenu
                                    openConfig={openConfig}
                                    type={typeConfig}
                                    flow={flow}
                                    selectedItem={selectedItem}
                                    filterSchema={filterSchema}
                                    filterStateMessage={filterStateMessage}
                                    setFilterSchema={setFilterSchema}
                                    setFilterStateMessage={setFilterStateMessage}
                                    handleSaveMenuConfig={handleSaveMenuConfig}
                                    handleSaveIntegration={handleSaveIntegration}
                                    handleCloseMenuConfig={handleCloseMenuConfig}
                                    handleSaveDescription={handleSaveDescription}
                                /> :
                                <></>
                    }

                </ContainerConfigRight>
            </ContainerConfig>

        </Container >
    );

}

export default NewAutomation;
