import styled, { css } from "styled-components";

interface LoaderAttachmentProps {
    progress: number;
}

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    padding-bottom: 5px;
    overflow: auto;
`;

export const AttachmentContainer = styled.div`
    cursor: pointer;
`;

export const LoaderAttachment = styled.div<LoaderAttachmentProps>`
    max-width: 210px;
    min-width: 150px;
    height: 5px;

    div {
        height: 100%;
        width: 30%;
        background: linear-gradient(90deg, rgb(255, 140, 47) 25%, rgb(251, 50, 92) 50%, rgb(147, 55, 237) 75%);
        border-radius: 20px;

        ${props => props.progress && css`
            width: ${props.progress + "%"};
        `}
    }

`;

export const Attachment = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 220px;
    min-width: 150px;
    max-height: 50px;
    border: 1px solid #f6f8fb;
    padding: 5px;
    padding-right: 15px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 10px;
    font-weight: 400;
    cursor: pointer;
    
    svg {
        width: 30px;
        min-width: 25px;
        min-height: 25px;
        color: gray;
        margin-right: 10px;
        margin-left: 5px;
    }

    :hover {
        border: 1px solid gray;
        
        .drop-options {
            svg {
                color: gray;
                display: block;
            }
        }
    }
    
`;

export const AttachmentNew = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-height: 50px;
    min-height: 50px;
    min-width: 50px;
    border: 1px dashed #eef2f8;
    border-radius: 5px;
    margin-right: 10px;
    padding: 5px;
    overflow: hidden;
    cursor: pointer;
    
    svg {
        font-size: 20px;
        color: gray;
        margin-right: 10px;
        margin-left: 10px;
    }

    :hover {

        border: 1px dashed #f23b5c;
        color: #f23b5c;

        svg {
            color: #f23b5c;
        }

    }
`;

export const AttachmentDetail = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 3px;
    margin-bottom: 3px;
    cursor: pointer;
    user-select: none;
`;

export const AttachDescription = styled.div`
    font-size: 13px;
    user-select: none;
    cursor: pointer;
`;

export const AttachDropOptions = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
        font-size: 15px;
        min-height: 15px;
        min-width: 15px;
        margin-right: 0px;
        margin-left: 5px;
        color: transparent;
        width: 15px;
        display: none;
    }
`;

export const AttachOptions = styled.div`
    font-size: 12px;
    color: gray;
    margin-top: 3px;

    a {
        border: none;
        background: transparent;
        font-size: 12px;
        color: gray;
        margin-left: 5px;
        text-decoration: auto;

        :hover {
            color: #4582b8;
        }
    }
`;

export const DownloadBtn = styled.button`
    font-size: 12px;
    color: gray;
    border: none;
    background: transparent;
    margin-left: 3px;

    :hover {
        color: #4582b8;
        text-decoration: underline;
    }
`;
