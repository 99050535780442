import {
    AutomaticData,
    ContainerDynamicTextView,
    ContainerEditField,
    ContainerEditFieldButtons,
    ContainerEditFieldTitle,
    ContainerRecord,
    EditField,
    FieldBodyItem,
    FieldBodyItemBody,
    FieldBodyItemHead,
    NoRegister,
    TextContainer
} from "./style";
import { FaHashtag, FaPen, FaRegCalendarAlt, FaUser } from "react-icons/fa";
import { FieldProps, getFieldObject, getItemComponentIcon } from "../Forms/Fields/FieldBuilder";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { maskFormulaCurrency, maskFormulaNumber, maskFormulaPercent } from "../Forms/Fields/Components/Formula";

import Attachments from "../Colaboration/Attachments";
import Button from "../../components/Button";
import { Form } from "../../interfaces/Form";
import { FormAnswer } from "../../interfaces/FormAnswer";
import { FormAnswerField } from "../../interfaces/FormAnswerField";
import { FormAnswerFieldApi } from "../../interfaces/FormAnswerFieldApi";
import FormBuilder from "../Forms/FormBuilder";
import { FormHandles } from "@unform/core";
import ItemRegister from "../../components/ItemRegister";
import api from '../../services/api';
import formAnswerToComboItemFromFlow from "../../utils/formAnswerToComboItemFromFlow";
import formAnswerToComboItemFromRegister from "../../utils/formAnswerToComboItemFromRegister";
import getAccessControl from "../../middlewares/AccessControl";
import parseFormula from "../../utils/Formula/parseFormula";
import { useToast } from '../../hooks/toast';
import updateFormulaFromFormAnswer from "../../utils/Formula/updateFormulaFromFormAnswer";
import CheckListView from "../Forms/Fields/Components/CheckListField/CheckListView";
import LinkPreview from "../LinkPreview";
import DynamicContentPreview from "../DynamicContentPreview";
import { Card } from "../../interfaces/Card";

interface FormAnswerRecordProps {
    selectedValue: FormAnswer;
    form: Form;
    flow_id?: number;
    card_current_id?: number;
    card_current?: Card;
    register_id?: number;
    typeUser: string;
    showDetailAnswer?: boolean;
    hideContainer?: boolean;
    notUpdateObject?: boolean;
    forceUpdate?: () => void;
}

interface objectInit {
    [x: string]: string | object
}

const FormAnswerRecord: React.FC<FormAnswerRecordProps> = ({ selectedValue, form, forceUpdate, typeUser, showDetailAnswer = true, hideContainer = false, register_id, flow_id, card_current, card_current_id, notUpdateObject = false }) => {

    const { addToast } = useToast();
    const formRef = useRef<FormHandles>(null);
    const [fields, setFields] = useState<FieldProps[]>();
    const [formAnswer, setFormAnswer] = useState<FormAnswer>(selectedValue);
    const [formAnswerSelected, setFormAnswerSelected] = useState<FormAnswerField>();
    const [editing, setEditing] = useState<number | undefined>(undefined);
    const [fieldSelected, setFieldSelected] = useState<FieldProps>();
    const [initValue, setInitValue] = useState({});
    const [loadingEdit, setLoadingEdit] = useState<boolean>(false);

    const handleEdit = useCallback(async (field?: FieldProps, formAnswer?: FormAnswerField) => {

        if (field !== undefined && fields !== undefined) {
            setFields(
                fields.map((item: FieldProps) => {
                    if (item.id_field === field.id_field) {
                        item.currentEdit = !item.currentEdit;
                        if (item.currentEdit) {
                            setEditing(item.id_field);
                            setFormAnswerSelected(formAnswer);
                            setFieldSelected(field);
                        } else {
                            setEditing(undefined);
                            setFormAnswerSelected(undefined);
                            setFieldSelected(undefined);
                        }
                    }
                    return item;
                })
            );
        }

    }, [fields]);

    const getApiRecord = useCallback(async (ignoreNotUpdate: boolean) => {

        if (!notUpdateObject || ignoreNotUpdate) {

            await api
                .get('/form/answer?id_form_answer=' + formAnswer.id_form_answer)
                .then(response => {

                    let retApi: FormAnswer[] = response.data;

                    if (retApi !== undefined && retApi.length > 0) {
                        setFormAnswer(retApi[0])
                    }

                });

        }


    }, [formAnswer.id_form_answer, notUpdateObject]);

    const handleEditRecord = useCallback(async () => {

        if (formRef.current !== null) {
            formRef.current.submitForm();
        }

    }, []);

    const handleSubmit = useCallback(async (data: object[]) => {

        const dataNormalized = data as unknown as FormAnswerFieldApi[];

        let apiObject = undefined;
        if (formAnswerSelected !== undefined) { //If is Editting
            apiObject = {
                id_form_answer_field: formAnswerSelected?.id_form_answer_field,
                form_answer_id: formAnswerSelected?.form_answer_id,
                field_id: formAnswerSelected?.field_id,
                value: dataNormalized,
                flow_id: flow_id,
                card_id: card_current_id
            }
        } else if (fieldSelected !== undefined && formAnswer !== undefined) { //If is inserting (no record)
            apiObject = {
                form_answer_id: formAnswer?.id_form_answer,
                field_id: fieldSelected?.id_field,
                value: dataNormalized,
                flow_id: flow_id,
                card_id: card_current_id
            }
        }

        if (apiObject !== undefined) {

            setLoadingEdit(true);
            await api
                .put('/form/answer-field', apiObject)
                .then(response => {

                    handleEdit(fieldSelected);

                    getApiRecord(true);

                    addToast({
                        type: 'success',
                        title: 'Alterado com sucesso o(a)',
                        description: 'Congrats! Você alterou o registro :)',
                    });

                    if (forceUpdate !== undefined) {
                        forceUpdate();
                    }

                    setLoadingEdit(false);
                }).catch(error => {
                    setLoadingEdit(false);
                    console.log(error);
                    addToast({
                        type: 'error',
                        title: 'Erro ao alterar o novo item [1]',
                        description: 'Ocorreu um erro alterar o registro!',
                    });
                });

        } else {
            addToast({
                type: 'error',
                title: 'Erro ao alterar o novo item [3]',
                description: 'Ocorreu um erro ao alterar o registro!',
            });
        }

    }, [addToast, formAnswerSelected, fieldSelected, handleEdit, formAnswer, getApiRecord, forceUpdate, card_current_id, flow_id]);

    useEffect(() => {
        getApiRecord(false);
    }, [getApiRecord])

    useEffect(() => {

        //Set Form Answer
        let formAnswerBase = formAnswer;
        if (formAnswer !== undefined) {
            if (formAnswer.id_form_answer !== selectedValue.id_form_answer) {
                formAnswerBase = selectedValue;
            }
        }

        //Close all currentEdit
        const newFields = form.fields.map((item: FieldProps) => {
            item.currentEdit = false;
            return item;
        })

        formAnswerBase = updateFormulaFromFormAnswer(formAnswer, newFields);

        setFormAnswer(formAnswerBase);
        setFields(newFields);

        let objTemp: objectInit = {

        };

        for (let index = 0; index < formAnswer.form_answer_fields.length; index++) {

            let element = formAnswer.form_answer_fields[index];

            //Valid if is inserted;
            if (objTemp[element.field.name] !== undefined) { //Already was inserted

                const arrNew = [];

                //Put the existing data
                if (typeof objTemp[element.field.name] === "object") {
                    arrNew.push(...objTemp[element.field.name] as String[]);
                } else if (typeof objTemp[element.field.name] === "string") {
                    arrNew.push(objTemp[element.field.name]);
                }

                arrNew.push(element.value);

                objTemp[element.field.name] = arrNew;

            } else { //New field
                if (index === 0) {
                    objTemp = { [element.field.name]: element.value };
                } else if (objTemp !== undefined) {
                    objTemp[element.field.name] = element.value;
                }
            }

        }

        if (objTemp !== undefined) {
            setInitValue(objTemp);
        }

    }, [formAnswer, form, selectedValue]);

    useEffect(() => {

        //Valid if fields exists
        if (fields !== undefined) {

            //Filter the fields that are formula fields
            const formulaFields = fields.filter((f) => f.type === "FORMULA_FIELD");

            //If has formula fields
            if (formulaFields.length > 0) {

                const newObjData: objectInit = {};
                let newFormAnswer = formAnswer;
                let foundAnswer = false;
                let maskedValue = "";
                let maxFormAnswer = 0;

                //Nomalize the data from form_answer_fields to object[]
                for (let index = 0; index < formAnswer.form_answer_fields.length; index++) {
                    const formField = formAnswer.form_answer_fields[index];

                    if (formField.field.type !== "FORMULA_FIELD") { //Remove the form field                        
                        newObjData[formField.field.name] = formField.value;
                    }

                }

                //Run all formula fields
                for (let index = 0; index < formulaFields.length; index++) {
                    const fField = formulaFields[index];

                    //Calcule formula value
                    const calcValue = parseFormula(fField, fields, newObjData as unknown as object[]);

                    //Mask the value
                    if (fField.variation !== undefined) {
                        if (fField.variation === "1") { //Number
                            maskedValue = maskFormulaNumber(calcValue);
                        } else if (fField.variation === "2") { //Currency
                            maskedValue = maskFormulaCurrency(calcValue);
                        } else if (fField.variation === "3") { //Percent
                            maskedValue = maskFormulaPercent(calcValue);
                        } else {
                            maskedValue = maskFormulaNumber(calcValue);
                        }

                    }

                    //Run to update the value in the forms that exists
                    for (let index = 0; index < newFormAnswer.form_answer_fields.length; index++) {

                        if (fField.name === newFormAnswer.form_answer_fields[index].field.name) {
                            foundAnswer = true;
                            //Set the value updated in the formula
                            newFormAnswer.form_answer_fields[index].valueString = maskedValue;
                        }

                        if (maxFormAnswer < newFormAnswer.form_answer_fields[index].id_form_answer_field) {
                            maxFormAnswer = newFormAnswer.form_answer_fields[index].id_form_answer_field;
                        }

                    }

                    if (!foundAnswer && fField.id_field !== undefined) {
                        const newAnswer: FormAnswerField = {
                            id_form_answer_field: maxFormAnswer + 1,
                            form_answer_id: newFormAnswer.id_form_answer,
                            field_id: fField.id_field,
                            index: 0,
                            value: calcValue,
                            valueString: maskedValue,
                            field: fField
                        }

                        maxFormAnswer = maxFormAnswer + 1;
                        newFormAnswer.form_answer_fields.push(newAnswer);
                    }

                    foundAnswer = false;

                }

                setFormAnswer(newFormAnswer);

            }
        }

    }, [formAnswer, fields]);

    return (
        <ContainerRecord hideContainer={hideContainer}>
            {fields !== undefined ? fields.filter((field) => getFieldObject(field.type).onShowInAnswerRecord).map((field) => {

                const Icon = getItemComponentIcon(field.type);
                const value: FormAnswerField[] = formAnswer.form_answer_fields.filter((answer: FormAnswerField) => answer.field_id === field.id_field);

                return (
                    <FieldBodyItem key={field.id_field}>
                        <FieldBodyItemHead>
                            <Icon />
                            <h3>{field.title}{field.required ? "*" : ""}</h3>
                            {(editing === undefined || editing === field.id_field) &&
                                getAccessControl(28, typeUser) &&
                                field.type !== "FORMULA_FIELD" && field.type !== "ID_FIELD" && field.type !== "DYNAMIC_TEXT_FIELD" && field.updated !== "N" ?
                                <EditField className="edit-field" onClick={() => handleEdit(field, value[0])} >
                                    <FaPen />
                                    <span>Editar</span>
                                </EditField> :
                                <></>
                            }
                        </FieldBodyItemHead>
                        <FieldBodyItemBody>
                            {!field.currentEdit ?
                                (value === undefined || value.length <= 0) && field.type !== "DYNAMIC_TEXT_FIELD" ?
                                    <NoRegister>Sem registro</NoRegister> :

                                    field.type === "DYNAMIC_TEXT_FIELD" ?
                                        <DynamicContentPreview formula={field.formula} card_current={card_current} /> :

                                        field.type === "CHECK_LIST_FIELD" ?
                                            <CheckListView key={formAnswer.id_form_answer} value={value} /> :

                                            field.type === "COMBO_BOX_REGISTER_FIELD" ?
                                                <>
                                                    {value.map((val) => {
                                                        return (
                                                            field.register !== undefined && val.valueRegister !== undefined && (
                                                                <ItemRegister key={val.valueRegister.id_form_answer} type={'Register'} item={formAnswerToComboItemFromRegister(val.valueRegister, field.register)} />
                                                            )
                                                        )
                                                    })}
                                                </> :

                                                value.map((val) => {
                                                    return (
                                                        field.type === "INPUT_ATTACH_FIELD" && val.valueAttachment !== undefined ?
                                                            <Attachments key={val.valueAttachment.id_attachment} uploadedFiles={[val.valueAttachment]} maxLength={"1"} typeUser={typeUser} variation={field.variation} card_current_id={card_current_id} flow_id={flow_id} /> :
                                                            field.type === "ID_FIELD" ?
                                                                <AutomaticData key={val.valueString}>{val.valueString}</AutomaticData> :
                                                                field.type === "LINK_FIELD" ?
                                                                    <LinkPreview link={val.valueString} /> :
                                                                        field.type === "INPUT_RICH_TEXT_FIELD" ?
                                                                            <ContainerDynamicTextView>
                                                                                <div style={{ fontWeight: 'normal' }} dangerouslySetInnerHTML={{ __html: val.value }} />
                                                                            </ContainerDynamicTextView> :
                                                                            field.type === "COMBO_BOX_REGISTER_FIELD" && field.register !== undefined && val.valueRegister !== undefined ?
                                                                                <ItemRegister key={val.valueRegister.id_form_answer} type={'Register'} item={formAnswerToComboItemFromRegister(val.valueRegister, field.register)} /> :
                                                                                field.type === "COMBO_BOX_FLOW_FIELD" && field.flow !== undefined && val.valueCardFlow !== undefined ?
                                                                                    <ItemRegister key={val.valueCardFlow.id_card} type={'Flow'} item={formAnswerToComboItemFromFlow(val.valueCardFlow, field.flow)} /> :
                                                                                    <TextContainer className="text-container" key={val.id_form_answer_field + val.index}>{val.valueString}</TextContainer>
                                                    )
                                                }) :
                                <ContainerEditField>
                                    <ContainerEditFieldTitle>Editando</ContainerEditFieldTitle>
                                    <FormBuilder
                                        id="formEdit"
                                        formRef={formRef}
                                        fields={[field]}
                                        handleSubmit={handleSubmit}
                                        initialValue={initValue}
                                        hideContainer={true}
                                        register_id={register_id}
                                        card_current_id={card_current_id}
                                        flow_id={flow_id}
                                    />
                                    <ContainerEditFieldButtons>
                                        <Button color="#f23c5d" onClick={() => handleEditRecord()} isLoading={loadingEdit}>Salvar</Button>
                                        <Button color="#f4f5f7" color_text="black" onClick={() => handleEdit(field)}>Cancelar</Button>
                                    </ContainerEditFieldButtons>
                                </ContainerEditField>
                            }
                        </FieldBodyItemBody>
                    </FieldBodyItem>
                );
            }) : <></>}
            {showDetailAnswer ?
                <>
                    <FieldBodyItem key={-3}>
                        <FieldBodyItemHead>
                            <FaHashtag />
                            <h3>Identificador</h3>
                        </FieldBodyItemHead>
                        <FieldBodyItemBody>
                            <AutomaticData>{formAnswer.id_form_answer}</AutomaticData>
                        </FieldBodyItemBody>
                    </FieldBodyItem>
                    <FieldBodyItem key={-1}>
                        <FieldBodyItemHead>
                            <FaRegCalendarAlt />
                            <h3>Criado em</h3>
                        </FieldBodyItemHead>
                        <FieldBodyItemBody>
                            <AutomaticData>{formAnswer.dt_created_string}</AutomaticData>
                        </FieldBodyItemBody>
                    </FieldBodyItem>
                    <FieldBodyItem key={-2}>
                        <FieldBodyItemHead>
                            <FaUser />
                            <h3>Criado por</h3>
                        </FieldBodyItemHead>
                        <FieldBodyItemBody>
                            <AutomaticData>{formAnswer.user_created}</AutomaticData>
                        </FieldBodyItemBody>
                    </FieldBodyItem>
                </> :
                <></>
            }
        </ContainerRecord>
    );

}

export default FormAnswerRecord;