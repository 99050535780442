import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    Container,
    ContainerChecklist,
    ChecklistTitleContainer,
    ChecklistTitle,
    ChecklistTotal,
    DividerGroup,
    ChecklistBody,
    CleanBox,
    CleanBoxText,
    ContainerButton,
    ChecklistButton,
    CLItem,
    CLCheckbox,
    CLDescription,
    CLDescriptionContainer,
    ProgressBarTask,
    ProgressBarTaskLine,
    ProgressBarTaskLineInside,
    ProgressBarTaskPercent,
    ButtonAddCheckListItem,
    CLDeleteButton,
    GroupLoading,
    CheckListDeleteButton
} from "./style";

import { TbCheckbox, TbTrash } from "react-icons/tb";
import { FaPlus, FaPlusSquare } from "react-icons/fa";
import { CheckList } from "../../../interfaces/CheckList";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";
import { CheckListItem } from "../../../interfaces/CheckListItem";
import api from "../../../services/api";
import { useToast } from '../../../hooks/toast';
import ContentLoader from "react-content-loader";
import ConfirmDialog from "../../ConfirmDialog";
import getAccessControl from "../../../middlewares/AccessControl";

interface CheckListTabProps {
    card_id?: number;
    flow_id?: number;
    typeUser: string;
}

const LoaderContainer = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={'100%'}
        viewBox="0 0 500 210"
        backgroundColor="#ffffff"
        foregroundColor="#f3f3f3"
    >
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="200" />
    </ContentLoader>
)

const CheckListTab: React.FC<CheckListTabProps> = ({ card_id, flow_id, typeUser }) => {

    const [checklists, setChecklists] = useState<CheckList[]>([]);
    const [lastCheckListItemAdded, setLastCheckListItemAdded] = useState<CheckListItem>();
    const lastCheckListItemAddedRef = useRef<HTMLInputElement | null>();
    const [loading, setLoading] = useState<boolean>(false);
    const { addToast } = useToast();
    const [dialogDeleteState, setDialogDeleteState] = useState(false);
    const [checkListSelected, setCheckListSelected] = useState<CheckList | undefined>(undefined);

    const deleteCheckListApi = useCallback(async (checkList: CheckList) => {

        if (card_id !== undefined && flow_id !== undefined) {

            api.delete('/checklist', {
                params: {
                    id_check_list: checkList.id_check_list,
                    flow_id: flow_id,
                    card_id: card_id,
                }
            }).then((response) => {

                const resultApi: boolean = response.data as boolean;

                if (resultApi) {
                    const updatedCheckLists = checklists.filter((cl) => cl.hash !== checkList.hash);
                    setChecklists(updatedCheckLists);
                    setCheckListSelected(undefined);
                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro ao deletar o checklist na base de dados',
                        description: 'Ocorreu um erro ao tentar deletar o checklist na base de dados!',
                    });
                }

            }).catch((error) => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Erro ao deletar o checklist na base de dados',
                    description: 'Ocorreu um erro ao tentar deletar o checklist na base de dados!',
                });
            });

        }

    }, [addToast, card_id, checklists, flow_id]);

    const deleteCheckListItemApi = useCallback(async (checkListItem: CheckListItem, checkList: CheckList) => {

        if (card_id !== undefined && flow_id !== undefined) {

            api.delete('/checklist/item', {
                params: {
                    id_check_list_item: checkListItem.id_check_list_item,
                    check_list_id: checkList.id_check_list,
                    flow_id: flow_id,
                    card_id: card_id,
                }
            }).then((response) => {

                const resultApi: boolean = response.data as boolean;

                if (resultApi) {

                    const updatedCheckLists = checklists.map((cl) => {
                        if (checkList.hash === cl.hash) {
                            const updatedItems = cl.checklist_items.filter((item) => item.hash !== checkListItem.hash);
                            cl.checklist_items = updatedItems;
                        }
                        return cl;
                    });

                    setChecklists(updatedCheckLists);

                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro ao deletar o checklist na base de dados',
                        description: 'Ocorreu um erro ao tentar deletar o checklist na base de dados!',
                    });
                }

            }).catch((error) => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Erro ao deletar o checklist na base de dados',
                    description: 'Ocorreu um erro ao tentar deletar o checklist na base de dados!',
                });
            });

        }

    }, [addToast, card_id, checklists, flow_id]);

    const postCheckListItemApi = useCallback(async (checklistItem: CheckListItem, checkList: CheckList, updateFocus: boolean) => {

        if (card_id !== undefined && flow_id !== undefined) {

            api.post('/checklist/item', {
                id_check_list_item: checklistItem.id_check_list_item,
                check_list_id: checkList.id_check_list,
                flow_id: flow_id,
                card_id: card_id,
                hash: checklistItem.hash,
                description: checklistItem.description,
                index: checklistItem.index,
                checked: checklistItem.checked,
            }).then((response) => {

                const checkListItemResponse = response.data as CheckListItem;

                const updatedCheckLists = checklists.map((cl) => {
                    if (checkList.hash === cl.hash) {
                        let foundItem = false;

                        const updatedItems = cl.checklist_items.map((item) => {
                            if (item.hash === checkListItemResponse.hash) {
                                foundItem = true;
                                return checkListItemResponse;
                            }
                            return item;
                        });

                        if (!foundItem) {
                            updatedItems.push(checkListItemResponse);
                        }

                        cl.checklist_items = updatedItems;
                    }
                    return cl;
                });

                setChecklists(updatedCheckLists);

                if (updateFocus) {
                    setLastCheckListItemAdded(checkListItemResponse);
                }

            }).catch((error) => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Erro ao salvar o item do checklist na base de dados',
                    description: 'Ocorreu um erro ao tentar salvar o item do checklist na base de dados!',
                });
            });

        }

    }, [addToast, card_id, checklists, flow_id]);

    const postCheckListApi = useCallback(async (checklist: CheckList, updateFocus: boolean) => {

        if (card_id !== undefined && flow_id !== undefined) {

            api.post('/checklist', {
                id_check_list: checklist.id_check_list,
                hash: checklist.hash,
                name: checklist.name,
                index: checklist.index,
                card_id: card_id,
                flow_id: flow_id,
                check_list_item: checklist.checklist_items.map((item) => {
                    return {
                        id_check_list_item: item.id_check_list_item,
                        hash: item.hash,
                        description: item.description,
                        checked: item.checked,
                        index: item.index,
                    }
                })
            }).then((response) => {

                const checkListResponse = response.data as CheckList;

                //Valid if the checklist already exists and update it or insert]
                let foundCheckList = false;

                const updatedCheckLists = checklists.map((cl) => {
                    if (cl.hash === checkListResponse.hash) {
                        foundCheckList = true;
                        return checkListResponse;
                    }
                    return cl;
                });

                if (!foundCheckList) {
                    setChecklists([...checklists, checkListResponse]);
                } else {
                    setChecklists(updatedCheckLists);
                }

                if (updateFocus) {
                    setLastCheckListItemAdded(checkListResponse.checklist_items[0]);
                }

            }).catch((error) => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Erro ao salvar o checklist na base de dados',
                    description: 'Ocorreu um erro ao tentar salvar o checklist na base de dados!',
                });
            });

        }


    }, [card_id, flow_id, addToast, checklists]);

    const getCheckListApi = useCallback(async () => {

        if (card_id !== undefined && flow_id !== undefined) {

            setLoading(true);

            api.get('/checklist/by-card', {
                params: {
                    card_id: card_id,
                    flow_id: flow_id,
                }
            }).then((response) => {

                const checkListsResponse = response.data as CheckList[];

                setChecklists(checkListsResponse);
                setLoading(false);
            }).catch((error) => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar os checklists na base de dados',
                    description: 'Ocorreu um erro ao tentar buscar os checklists na base de dados!',
                });
            });

        }

    }, [card_id, flow_id, addToast]);

    const handleDeleteCheckList = useCallback(async () => {

        if (checkListSelected !== undefined) {
            await deleteCheckListApi(checkListSelected);
        }

    }, [deleteCheckListApi, checkListSelected]);

    const handleAddNewCheckList = useCallback(async () => {

        if (card_id !== undefined) {

            //Create the checkbox hash
            const dateNowTime = new Date().getTime();
            var hashLib = require('object-hash');

            const hashCheckList = await hashLib(dateNowTime + card_id + checklists.length + 1);
            const hashCheckListItem = await hashLib(dateNowTime + hashCheckList + '1');

            const newCheckList: CheckList = {
                name: "Checklist",
                hash: hashCheckList,
                index: checklists.length + 1,
                checklist_items: [
                    {
                        description: "",
                        hash: hashCheckListItem,
                        index: 1,
                        checked: "N",
                    }
                ]
            }

            //Call the post api
            await postCheckListApi(newCheckList, true);

        }

    }, [checklists, card_id, postCheckListApi]);

    const handleCheckListChange = useCallback(async (checkListId: string, newName: string) => {
        const updatedCheckLists = checklists.map((checkList) => {
            if (checkList.hash === checkListId) {
                checkList.name = newName;
            }
            return checkList;
        });

        setChecklists(updatedCheckLists);
    }, [checklists]);

    const handleItemChange = useCallback(async (checkListId: string, itemId: string, newName?: string, checked?: string) => {
        const updatedCheckLists = checklists.map((checkList) => {
            if (checkList.hash === checkListId) {
                const updatedItems = checkList.checklist_items.map((item) => {
                    if (item.hash === itemId) {
                        if (newName !== undefined) {
                            item.description = newName;
                        } else {
                            item.checked = checked !== undefined ? checked : "N";
                            postCheckListItemApi(item, checkList, false)
                        }
                    }
                    return item;
                });

                return {
                    ...checkList,
                    checklist_items: updatedItems,
                };
            }
            return checkList;
        });

        setChecklists(updatedCheckLists);
    }, [checklists, postCheckListItemApi]);

    const handleAddItem = useCallback(async (checkListId: string) => {

        //Create the checkbox hash
        const dateNowTime = new Date().getTime();
        var hashLib = require('object-hash');

        const updatedCheckLists = checklists.map((checkList) => {
            if (checkList.hash === checkListId) {

                //Valid if the last item is empty or not
                if (checkList.checklist_items.length > 0) {
                    let lastItem = checkList.checklist_items[checkList.checklist_items.length - 1];
                    if (lastItem.description.trim() === '') {

                        const element = document.getElementById(lastItem.hash);
                        if (element) {
                            element.focus();
                        }

                        return checkList;
                    }
                }

                const hashCheckListItem = hashLib(dateNowTime + checkListId + checkList.checklist_items.length + 1);

                const newItem: CheckListItem = {
                    hash: hashCheckListItem,
                    description: '',
                    checked: "N",
                    index: checkList.checklist_items.length + 1,
                };

                postCheckListItemApi(newItem, checkList, true);

                return {
                    ...checkList,
                    checklist_items: [...checkList.checklist_items, newItem],
                };
            }
            return checkList;
        });

        setChecklists(updatedCheckLists);

    }, [checklists, postCheckListItemApi]);

    const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>, checkListId: string, itemId?: string, checked?: string) => {

        if (event.key === "Enter" && (event.ctrlKey || event.metaKey) && itemId !== undefined) {
            handleItemChange(checkListId, itemId, undefined, checked);
        } else if (event.key === "Enter") {
            handleAddItem(checkListId);
        }
    };

    useEffect(() => {

        if (lastCheckListItemAddedRef.current !== null && lastCheckListItemAddedRef.current !== undefined) {
            lastCheckListItemAddedRef.current.focus();
        }

    }, [lastCheckListItemAdded, lastCheckListItemAdded?.dt_last_update]);

    useEffect(() => {

        getCheckListApi()

    }, [card_id, flow_id, getCheckListApi]);

    return (
        <Container>

            {loading ?
                <GroupLoading>
                    <LoaderContainer />
                    <LoaderContainer />
                </GroupLoading> :
                <>

                    {getAccessControl(62, typeUser) ?
                        <ContainerButton>
                            <ChecklistButton onClick={() => handleAddNewCheckList()}>
                                <FaPlusSquare />
                                Adicionar checklist
                            </ChecklistButton>
                        </ContainerButton> :
                        <> </>
                    }

                    {checklists === undefined || checklists.length <= 0 ?
                        <ContainerChecklist>
                            <ChecklistTitleContainer>
                                <ChecklistTitle value={"Checklist"} disabled />
                            </ChecklistTitleContainer>
                            <DividerGroup />
                            <ChecklistBody>

                                <CleanBox>
                                    <TbCheckbox />
                                    <CleanBoxText>
                                        Adapte, monitore e aumente o controle sobre as tarefas associadas a este cartão criando checklists de subtarefas e itens personalizados
                                    </CleanBoxText>
                                </CleanBox>

                            </ChecklistBody>
                        </ContainerChecklist> :
                        <></>
                    }

                    {checklists && checklists.length > 0 ? checklists.map((checklist, index) => {

                        //Calculate the percentage of the checklist
                        let percentage = 0;
                        if (checklist.checklist_items !== undefined && checklist.checklist_items.length > 0) {
                            const totalItemsChecked = checklist.checklist_items.filter((item) => item.checked === 'S').length;
                            percentage = totalItemsChecked / checklist.checklist_items.length;
                        }

                        return (
                            <ContainerChecklist key={index}>
                                <ChecklistTitleContainer>
                                    <ChecklistTitle
                                        value={checklist.name}
                                        maxLength={75}
                                        disabled={!getAccessControl(63, typeUser)}
                                        onChange={(e) => handleCheckListChange(checklist.hash, e.currentTarget.value)}
                                        onBlur={() => postCheckListApi(checklist, false)}
                                    />
                                    {checklist !== undefined && checklist.checklist_items !== undefined ?
                                        <ChecklistTotal className="cl-total">
                                            {checklist.checklist_items.filter((item) => item.checked).length} /
                                            {checklist.checklist_items.length}
                                        </ChecklistTotal> :
                                        <></>
                                    }
                                    {getAccessControl(63, typeUser) ?
                                        <CheckListDeleteButton className="cl-btn-delete" onClick={() => {
                                            setDialogDeleteState(true);
                                            setCheckListSelected(checklist)
                                        }}>
                                            <TbTrash />
                                        </CheckListDeleteButton> :
                                        <></>
                                    }
                                </ChecklistTitleContainer>
                                <DividerGroup style={{ marginBottom: '15px' }} />
                                <ChecklistBody>

                                    <ProgressBarTask style={{ marginBottom: '10px' }}>
                                        <ProgressBarTaskLine>
                                            <ProgressBarTaskLineInside width={percentage !== undefined ? percentage * 100 : 0} />
                                        </ProgressBarTaskLine>
                                        <ProgressBarTaskPercent>{String(Math.round((Number(percentage) * 100)))}%</ProgressBarTaskPercent>
                                    </ProgressBarTask>

                                    {checklist.checklist_items && checklist.checklist_items.length > 0 ? checklist.checklist_items.sort((a, b) => a.index - b.index).map((item, index) => {
                                        return (
                                            <CLItem
                                                key={index}
                                            >

                                                <CLCheckbox
                                                    onClick={() => {
                                                        if (getAccessControl(63, typeUser)) {
                                                            handleItemChange(checklist.hash, item.hash, undefined, item.checked === undefined ? "S" : item.checked === "S" ? "N" : "S")
                                                        }
                                                    }}
                                                >
                                                    {item.checked && item.checked === "S" ?
                                                        <BsCheckCircleFill color="#61bd4f" size={'20px'} /> :
                                                        <BsCircle color="#9e37ed" size={'20px'} />
                                                    }
                                                </CLCheckbox>

                                                <CLDescriptionContainer>
                                                    <CLDescription
                                                        id={item.hash}
                                                        className="input-description-cl-item"
                                                        ref={(ref) => {
                                                            //Set the ref inside of this object to use it later
                                                            if (item.hash === lastCheckListItemAdded?.hash) {
                                                                lastCheckListItemAddedRef.current = ref;
                                                            }
                                                        }}
                                                        key={index}
                                                        placeholder="Digite aqui uma descrição para o novo item..."
                                                        type="text"
                                                        disabled={!getAccessControl(63, typeUser)}
                                                        value={item.description}
                                                        checked={item.checked === undefined ? false : item.checked === "S" ? true : false}
                                                        onChange={(e) => handleItemChange(checklist.hash, item.hash, e.currentTarget.value)}
                                                        onKeyUp={(e) => handleEnterPress(e, checklist.hash, item.hash, item.checked === undefined ? "S" : item.checked === "S" ? "N" : "S")}
                                                        onBlur={() => postCheckListItemApi(item, checklist, false)}
                                                    />
                                                </CLDescriptionContainer>

                                                {getAccessControl(63, typeUser) ?
                                                    <CLDeleteButton className="btn-delete-cl-item" onClick={() => deleteCheckListItemApi(item, checklist)}>
                                                        <AiFillCloseCircle />
                                                    </CLDeleteButton> :
                                                    <></>
                                                }
                                            </CLItem>
                                        )
                                    }) :
                                        <></>
                                    }

                                    {getAccessControl(63, typeUser) ?
                                        <ButtonAddCheckListItem onClick={() => handleAddItem(checklist.hash)}>
                                            <FaPlus />
                                            Adicionar item
                                        </ButtonAddCheckListItem> :
                                        <></>
                                    }

                                </ChecklistBody>
                            </ContainerChecklist>
                        )

                    }) :
                        <></>
                    }
                    <ConfirmDialog
                        open={dialogDeleteState}
                        onSubmmit={() => handleDeleteCheckList()}
                        onClose={() => setDialogDeleteState(false)}
                        type={1}
                        message="Você tem certeza que deseja excluir este CheckList?"
                    />
                </>
            }

        </Container>
    );

}

export default CheckListTab;