import styled from "styled-components";
import Viewer from 'react-viewer';

export const Container = styled.div`
    z-index: 9999999999999999999999999999;
`;

export const ImageViewer = styled(Viewer)`

    .react-viewer-mask {
        z-index: 99999999999999999999999999;
    }

    .react-viewer-close {
        z-index: 9999999999999999999999999999;
    }

    .react-viewer-canvas {
        z-index: 99999999999;
        width: calc(100% - 25px);
    }

    .react-viewer-footer {
        z-index: 99999999999999999999999999;
    }

`;