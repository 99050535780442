import React from "react";
import { IconBaseProps } from "react-icons";
import { IconList } from 'react-fa-icon-picker'

import {
    ButtonsHeader,
    Container,
    LeftHeader,
    NavigationBar,
    NavigationBarItem,
    NavigationBarItemIcon,
    NavigationBarPages,
    NavigationBarTitle,
    RightHeader,
    NavigationBarTitleBadge
} from "./styles";
import { NavigationBarItemType } from "../AppBarHeader";

interface AppBarHeaderPocketProps {
    icon?: React.ComponentType<IconBaseProps>;
    title?: string;
    iconDynamic?: IconList;
    iconDynamicColor?: string;
    iconQuick?: React.ComponentType<IconBaseProps>;
    navBarItems?: NavigationBarItemType[];
    children?: React.ReactNode;
    typeUser: string;
    onClickQuick?: () => Promise<void>;
}

const AppBarHeaderPocket: React.FC<AppBarHeaderPocketProps> = ({ icon: Icon, title, children, iconDynamic, iconDynamicColor, iconQuick: IconQuick, onClickQuick, navBarItems, typeUser }) => {

    return (
        <Container>
            <LeftHeader>
                <NavigationBar>
                    <NavigationBarPages>
                        {navBarItems?.filter((item) => item.hideItem === undefined || item.hideItem === false).map((item, idx) => {

                            // Acess Control
                            if (item.typeUserAllowed !== undefined) {

                                if (typeUser === undefined) {
                                    return <div key={idx}></div>;
                                }

                                if (item.typeUserAllowed.indexOf(typeUser) === -1) {
                                    return <div key={idx}></div>;
                                }
                            }

                            return (
                                <NavigationBarItem key={idx} active={item.active} onClick={() => item.onClick()}>
                                    <NavigationBarItemIcon sizeIcon={item.iconSize}>
                                        <item.icon />
                                    </NavigationBarItemIcon>
                                    <NavigationBarTitle>
                                        {item.description}
                                        {item.badgeItems !== undefined && item.badgeItems > 0 ?
                                            <NavigationBarTitleBadge>
                                                {item.badgeItems}
                                            </NavigationBarTitleBadge> :
                                            <></>
                                        }
                                    </NavigationBarTitle>
                                </NavigationBarItem>
                            )
                        })}
                    </NavigationBarPages>
                </NavigationBar>
            </LeftHeader>
            <RightHeader>
                <ButtonsHeader>
                    {children}
                </ButtonsHeader>
            </RightHeader>
        </Container>
    );
};

export default AppBarHeaderPocket;