import {
    Container,
    Content,
    ContentLabel
} from "./style";
import React, { useEffect, useState } from "react";

import { DataProps } from "../..";
import getChartFormatValue from "../../getChartFormatValue";

interface NumberChartProps {
    dataElement?: DataProps[];
    color?: string;
    show_time?: string;
    axis_y_field_id?: number;
    axis_y_field_type?: string;
    axis_y_field_variation?: string;
}

const NumberChart: React.FC<NumberChartProps> = ({ dataElement, color, axis_y_field_id, show_time, axis_y_field_type, axis_y_field_variation }) => {

    const [value, setValue] = useState<number>(0);
    const [totalLabel, setTotalLabel] = useState<string>("");

    useEffect(() => {

        if (dataElement !== undefined && dataElement.length >= 1) {
            setValue(dataElement[0].value);

            if (show_time !== undefined && axis_y_field_id === -2) {
                if (show_time === "day") {
                    setTotalLabel(" Dias");
                } else if (show_time === "hour") {
                    setTotalLabel(" Horas");
                } else if (show_time === "min") {
                    setTotalLabel(" Minutos");
                } else if (show_time === "sec") {
                    setTotalLabel(" Segundos");
                }
            } else {
                setTotalLabel("");
            }
        }

    }, [dataElement, axis_y_field_id, show_time])

    return (
        <Container>
            <Content color={color}>
                {getChartFormatValue(value, axis_y_field_type, 2, axis_y_field_variation)}<ContentLabel>{totalLabel}</ContentLabel>
            </Content>
        </Container>
    );

}

export default NumberChart;