import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
`;

export const ContainerLoader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 200px;
`;

export const Animation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 15px;
    }
    
`;

export const ContainerHeader = styled.div`
   
`;

export const ContainerBody = styled.div`
    padding: 15px;
    padding-top: 0;
`;

export const ContainerEmpty = styled.div`    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 5%;
`;

export const ContainerInfo = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    color: black;
    margin-bottom: 5px;
    font-size: 20px;
  }

  p {
    font-size: 15px;
    color: gray;
    letter-spacing: 0.5px;
  }

  p.tip {
    background: rgba(69, 129, 183, 0.118);
    border-radius: 10px;
    padding: 7px;
    color: rgb(69, 129, 183);
    font-weight: 400;
    margin-top: 30px;
  }
`;

export const ContainerIlustration = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;

    img {
        width: 35%;
    }
`;