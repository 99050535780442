import styled, { css } from "styled-components";
import { Box } from "@material-ui/core";

interface ContainerProps {
    isErrored: boolean;
}

interface SearchInputProps {
    open: boolean;
}

export const Container = styled.div<ContainerProps>`
  padding: 0px 0px 0px 0px;
  width: calc(100%);

  ${(props) => props.isErrored && css`
      div {
        border-color: red;
      }
  `}
`;

export const BoxSearchContainer = styled.div`
    padding: 10px 10px 10px 10px;
    padding-bottom: 0px;
`;

export const SearchInput = styled.input<SearchInputProps>`
    width: 100%;
    height: 40px;
    padding: 5px 15px 5px 15px;
    border-radius: 15px;
    border: 1px solid #f4f5f7;
    background-color: #f4f5f7;
    font-size: 14px;

    ${(props) => props.open && css`
        border-radius: 15px 15px 0px 0px;
    `}
`;

export const BoxContainer = styled(Box)`
    position: absolute;
    top: auto;
    right: auto;
    left: auto;
    z-index: 1;
    min-width: 250px;
    //max-width: 462px;
    max-width: calc(100% - 140px);
    width: 100%;
    padding: 5px;
    margin-left: 10px;
    background-color: white;
    box-shadow: 0 7px 35px 3px rgb(0 0 0 / 20%);
    border-radius: 0pc 0px 15px 15px;
    margin-right: 25px;
    //min-height: 70px;
`;

export const ContainerNewUser = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    border: 1px dashed transparent;

    :hover {
        background-color: #f4f5f7;
        border-radius: 15px;
        border: 1px dashed #e8f2ff;
    }
`;

export const LeftNewUser = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    color: black;

    svg {
        height: 20px;
        width: 20px;
    }
`;

export const RightNewUser = styled.div`
    font-weight: 400;
    font-size: 15px;
    color: black;
`;

export const DisclaimerLicense = styled.div`
    font-size: 12px;
    color: #f23b5c;
    margin-top: 2px;
`;

export const BadgeInvalidMail = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 15px;
    padding: 5px 10px 5px 10px;
    background-color: #f23b5c;
    color: white;
    margin-left: 5px;
    font-size: 10px;
    font-weight: 500;
`;


export const BoxUserItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px 5px 10px;
    border: 1px dashed transparent;
    margin-top: 5px;

    :hover {
        background-color: #f4f5f7;
        border-radius: 15px;
        border: 1px dashed #e8f2ff;
    }
`;

export const BoxUserLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BoxUserRigth = styled.div`
    margin-left: 15px;
    width: 100%;
`;

export const BoxUserName = styled.div`
    font-size: 14px;
    color: black;
    font-weight: 400;
    overflow: hidden;
`;

export const BoxUserMail = styled.div`
    font-size: 11px;
    color: gray;
    overflow: hidden;
`;