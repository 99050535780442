import styled, { css } from "styled-components";

interface FlowIconContainerProps {
    color: string;
}

interface FlowContainerProps {
    color: string;
}

interface ContainerProps {
    columns?: string;
}

export const PreContainer = styled.div`
    display: flex;
`;

export const Container = styled.div<ContainerProps>`
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    max-height: 230px;
    overflow: auto;

    @media(max-width: 800px){
        display: block;
    }

    ${props => props.columns && css`
        grid-template-columns: repeat(${props.columns}, 1fr);
    `}
`;

export const FlowContainer = styled.button<FlowContainerProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    border-color: transparent;
    width: 100%;
    background-color: transparent;

    :hover {
        border-radius: 15px;

        ${props => props.color && css`
            background-color: ${props.color + "1e"};
        `}
    }
`;

export const FlowIconContainer = styled.div<FlowIconContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    min-width: 50px;
    min-height: 50px;
    background-color: #9d37ed1e;
    border-radius: 15px;
    margin-right: 15px;

    ${props => props.color && css`
        background-color: ${props.color + "1e"};
    `}

    div {
        display: flex;

        svg {
            width: 20px;
            height: 20px;
        }
    }
`;

export const FlowData = styled.div`
    display: block;
    justify-content: center;
    align-items: center;
    text-align: start;

    span {
        color: grey;
        font-size: 12px;
    }

    h2 {
        color: #000000d9;
        font-size: 16px;
        text-align: center;
        height: auto;
        text-align: start;
    }
`;

export const FlowContainerEmpty = styled.button`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    border-color: transparent;
    background-color: transparent;

    :hover {
        div {
            background-color: #f23c5d;
            border-color: white;

            h2 {
                color: #f23c5d;
            }
            
            svg {
                color: white;
            }
        }
    }
`;

export const FlowIconContainerEmpty = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    min-width: 50px;
    min-height: 50px;
    border: 1px dashed #4681b8;
    border-radius: 15px;
    margin-right: 15px;

    svg {
        color: #4681b8;
        width: 20px;
        height: 20px;
    }

`;

export const FlowDataEmpty = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: transparent !important;

    h2 {
        color: #4681b8;
        font-size: 16px;
        text-align: center;
        height: auto;
        text-align: start;
    }
`;

export const ContainerLoader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(305px - 65px);
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 30% !important;
        max-width: 150px;
    }
    
`;