import React, { useCallback, useRef } from "react";
import { FormHandles } from "@unform/core";

import Button from "../../../components/Button";
import FormBuilder from "../../../components/Forms/FormBuilder";
import api from '../../../services/api';
import {
    ContainerButton,
    ContainerFields,
    ContentList
} from "./style";
import { FieldProps, Fields } from "../../../components/Forms/Fields/FieldBuilder";
import { useToast } from '../../../hooks/toast';

interface PasswordProps {

}

interface ResetPasswordProps {
    password: string;
    password_confirmation: string;
}

const fieldsForm: FieldProps[] = [
    {
        name: "password",
        type: Fields.PASSWORD_FIELD,
        required: true,
        index: 1,
        title: "Nova Senha",
        description: "Digite uma nova senha com mais de 8 caracteres",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nova Senha é obrigatório!"]
            },
            {
                type: "min",
                params: [8, "O campo Nova Senha deve conter mais de 8 caracteres!"]
            }
        ]
    },
    {
        name: "password_confirmation",
        type: Fields.PASSWORD_FIELD,
        required: true,
        index: 2,
        title: "Confirme a Senha",
        description: "Digite novamente a sua nova senha",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nova Senha é obrigatório!"]
            },
            {
                type: "min",
                params: [8, "O campo Nova Senha deve conter mais de 8 caracteres!"]
            }
        ]
    }
]

const Password: React.FC<PasswordProps> = () => {

    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();

    const handleSubmitForm = useCallback(async (data: object[]) => {
        //Valida if is igual


        if (formRef.current !== null) {

            const dataNormalized = formRef.current.getData() as unknown as ResetPasswordProps;

            if (dataNormalized.password === dataNormalized.password_confirmation) {

                await api
                    .post('/user/reset-password', {
                        password: dataNormalized.password
                    })
                    .then(response => {

                        addToast({
                            type: 'success',
                            title: 'Congrats! Senha alterada com sucesso :]',
                            description: 'Sua senha foi alterada com sucesso!',
                        });

                        formRef.current?.reset();

                    }).catch(error => {
                        addToast({
                            type: 'error',
                            title: 'Erro ao salvar a senha :[',
                            description: 'Ocorreu um erro ao salvar a senha na base de dados!',
                        });
                    });

            } else {
                addToast({
                    type: 'error',
                    title: 'As senhas não conferem :[',
                    description: 'Favor validar as senhas inseridas, as duas não conferem!',
                });
            }

        }


    }, [addToast]);

    const handleSubmitPage = useCallback(async () => {
        if (formRef.current !== undefined) {
            formRef.current?.submitForm();
        }
    }, []);

    return (
        <ContentList>

            {/* Forms */}
            <ContainerFields item xs={12} md={6} style={{ display: "block" }}>

                <FormBuilder
                    id="form"
                    formRef={formRef}
                    fields={fieldsForm}
                    handleSubmit={handleSubmitForm}
                    hideContainer={true}
                />

                <ContainerButton>
                    <Button color="#f23c5d" onClick={() => handleSubmitPage()}>Salvar</Button>
                </ContainerButton>

            </ContainerFields>

        </ContentList>
    );

}

export default Password;