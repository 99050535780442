import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import styled from "styled-components";
import { Dialog } from '@material-ui/core';

export const MainDialog = styled(Dialog)`

`;

export const Content = styled(DialogContent)`
    background-color: white;  
    width: 100%;
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: center;
    height: auto;
    width: 100%;
    flex-direction: column;
`;

export const DialogTop = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #eef2f8;
    margin-top: 15px;
    width: 100%;
    height: 30px;
`;

export const DialogTopRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    top: 0px;

    button {
        width: 30px;
        border: none;
        background-color: transparent;
    }
`;

export const ContainerLogo = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;    
    margin-bottom: 25px;
    width: 100%;
    position: relative;

    img {
        width: 20%;
        max-width: 230px;
        min-width: 100px;

    }
`;

export const ContainerHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
`;

export const HeaderTitle = styled.h2`
    text-align: center;
    font-size: 27px;
    font-weight: 500;
    margin-bottom: 15px;
`;

export const HeaderInformation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        font-size: 16px;
        color: #000000ba;
        margin-right: 10px;

        u {
            font-weight: 500;
        }
    }

`;

export const HeaderDicountInformation = styled.div`
    color: #027947;
    background-color: #ebfdf2;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 10px;
    font-weight: 500;
`;

export const ContainerPricing = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const BottomContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
`;

export const BottomDescription = styled.p`
    font-size: 16px;
    color: #7a7a7a;
    margin-right: 25px;
`;

export const ButtonPlanFree = styled.button`
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: gray;
`;  

export const BottomButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: white;
    font-size: 13px;
    border: none;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    background-color: #818181;
    width: 220px;
    padding: 8px 10px;

    svg {
        margin-right: 10px;
        font-size: 15px;
    }
`;