import React from "react";

import {
    Container
} from "./style";

interface ListButtonProps {
    title: string;
    Icon?: React.ElementType;
    onClick?: () => void;
    color?: string;
    width?: number;    
}

const ListButton: React.FC<ListButtonProps> = ({ title, Icon, onClick, width, color }) => {

    return (
        <Container 
            width={width}
            color={color}
            onClick={onClick}
        >
            {Icon && <Icon />}
            {title}
        </Container>
    );

}

export default ListButton;