import {
    Container,
    ContainerTemplate
} from "./style";
import React, { useCallback, useEffect, useState } from "react";

import { Template } from "../../../interfaces/Template";
import TemplateCard from "../../Template/TemplateCard";
import api from '../../../services/api';
import { useHistory } from 'react-router-dom';

interface defaultProps {

}

const MyTemplate: React.FC<defaultProps> = () => {

    const [templates, setTemplates] = useState<Template[]>();
    const history = useHistory();

    const handleSelectTemplate = useCallback(async (template: Template, onDetailOpen: boolean) => {

        history.push('/template-store/' + template.id_template);

    }, [history]);

    useEffect(() => {

        api
            .get(`/template/all?template_group_id=0&maxLength=5`)
            .then(response => {

                const apiTemplate: Template[] = response.data;

                setTemplates(apiTemplate);

            }).catch(error => {
                console.log(error);
            });

    }, []);

    return (
        <Container>
            {templates?.map((template) => {
                return (
                    <ContainerTemplate key={template.id_template}>
                        <TemplateCard template={template} handleSelectTemplate={() => handleSelectTemplate(template, true)} />
                    </ContainerTemplate>
                )
            })}
        </Container>
    );

}

export default MyTemplate;