import styled, { css } from "styled-components";

interface BlockProps {
    color?: string;
}

interface BlockEndProps {
    color?: string;
}

export const Container = styled.div`
    height: 25px;
    width: 100%;
    margin-top: 10px;
    display: flex;
    overflow: auto;

    ::-webkit-scrollbar {
        display: none;
    }

`;

export const BlockInit = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 100%;
    background-color: #f23b5c;
    border-radius: 5px 0px 0px 5px;
    margin-right: 2px;

    svg {
        font-size: 12px;
        color: white;
        margin-left: 1px;
    }
`;

export const BlocksContainer = styled.div`
    width: calc(100% - 50px);    
    height: 100%;
    display: flex;
`;

export const Block = styled.a<BlockProps>`
    display: flex;
    justify-content: flex-start;
    justify-content: center;
    margin-right: 2px;
    background-color: #893be9;
    width: 100%;
    height: 100%;

    ${props => props.color && css`
        background-color: ${props.color + "cc"} !important;

        :hover {
            background-color: ${props.color} !important;
        }
    `}
`;

export const BlockEnd = styled.div<BlockEndProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 100%;
    background-color: #ff8c2e;
    border-radius: 0px 5px 5px 0px;

    svg {
        font-size: 12px;
        color: white;
        margin-right: 1px;
    }

    ${props => props.color && css`
        background-color: ${props.color}
    `}
`;

export const BlockIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        display: flex;
        svg {
            font-size: 12px;
        }
    }

`;

export const BlockName = styled.span`
    //max-width: 100px;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-left: 5px;
    color: white;
    //text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.5px;
`;

export const TooltipBackground = styled.div`
    display: block;
    border-radius: 5px 5px 5px 5px;
    padding: 5px;
    background-color: #ffa563;
`;

export const TooltipTitle = styled.div`
    font-size: 11px;
    text-align: center;
`;

export const TooltipDuration = styled.div`
   font-size: 12px;
   margin-top: 5px;
   text-align: center;
`;

export const TraceStepContainer = styled.div`
   padding: 10px;
`;

export const TraceStepTitle = styled.div`
`;

export const TraceStepUser = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-bottom: 5px;
    margin-top: -20px;
    font-size: 20px;

    .sb-avatar__text {
        div {
            font-size: 13px !important;
        }
    }
`;

export const TraceStepLine = styled.div`
    height: 3px;
    width: 150px;
    background-color: #d2d6d8;
`;

export const TraceStepDateTime = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
`;

export const TraceStepDuration = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    margin-right: 20px;
    margin-left: 20px;
    padding: 5px 0px;
    background-color: #893be9;
    border-radius: 20px;
    color: white;
    font-size: 12px;
`;

export const TraceStepSLA = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
`;


export const TraceStepDateTimeEntry = styled.div`
    margin-right: 130px;
`;

export const TraceStepDateTimeExit = styled.div`
 
`;

export const TraceStepDateTimeDate = styled.div`
    text-align: center;
`;

export const TraceStepDateTimeTime = styled.div`
    text-align: center;
`;


