import React from "react";

import emptyField from '../../../assets/empty-state/empty-field.png';
import {
    Container,
    ContainerImage,
    ContainerSubTitle,
    ContainerTitle
} from "./style";

interface emptyStateProps {

}

const EmptyField: React.FC<emptyStateProps> = () => {

    return (
        <Container>
            <ContainerImage>
                <img src={emptyField} alt="Sem Tarefas" />
            </ContainerImage>
            <ContainerTitle>
                <h1>Este formulário não possui campos</h1>
            </ContainerTitle>
            <ContainerSubTitle>
                <span>Clique aqui para adicionar novos campos</span>
            </ContainerSubTitle>
        </Container>
    );

}

export default EmptyField;