import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    Content,
    ContentBody,
    DialogContainer,
    DialogTop,
    DialogTopLeft,
    DialogTopCenter,
    DialogTopRight,
    DialogFooter,
    DialogFooterCenter,
    BtnInsertNewAnswer,
    ContainerForm
} from "./style";

import { AiOutlineClose } from "react-icons/ai";
import { FieldProps, Fields } from "../../components/Forms/Fields/FieldBuilder";
import { FormHandles } from "@unform/core";
import FormBuilder from "../../components/Forms/FormBuilder";
import api from "../../services/api";
import { TEMPLATE_BASE_ID } from "../../config/app";
import { useAuth } from "../../hooks/auth";
import { Flow } from "../../interfaces/Flow";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/toast";

const fieldsFlowForm: FieldProps[] = [
    {
        name: "name_flow",
        type: Fields.CUSTOM_SELECT_FIELD,
        required: true,
        variation: "1",
        index: 0,
        title: "Nome do fluxo",
        description: "Digite o nome do fluxo",
        placeholder: "Digite aqui...",
        help_text: "Neste campo é ideal que você escolha um nome claro para o seu fluxo, pois este nome que será exibido dentro do sistema para identifica-lo",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome do Fluxo é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    }
]

interface flowProps {
    name_flow: string;
}


interface NewFlowProps {
    open: boolean;
    workspaceId?: number;
    onClose: () => void;
}

const NewFlow: React.FC<NewFlowProps> = ({ onClose, open, workspaceId }) => {

    const { user } = useAuth();
    const { addToast } = useToast();

    const history = useHistory();
    const formRefFlow = useRef<FormHandles>(null);

    const [loadingInsert, setLoadingInsert] = useState<boolean>(false);

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    const handleSubmitForm = useCallback(async (data: object[]) => {

        let flowHash = "";

        if (data !== undefined) {

            const dataNormalized = data as unknown as flowProps;

            if (dataNormalized !== undefined && dataNormalized.name_flow !== "") {

                setLoadingInsert(true);

                await api.post('/template/use-count', {
                    id_template: TEMPLATE_BASE_ID
                })

                await api
                    .post('/template/use-template', {
                        id_template: TEMPLATE_BASE_ID,
                        id_user: user.id_user,
                        company_id: user.company_id,
                        name_flow: dataNormalized.name_flow,
                        isTestMode: 'false',
                        workspace_id: workspaceId !== undefined ? workspaceId : undefined
                    })
                    .then(response => {

                        const flowApi: Flow = response.data;

                        flowHash = flowApi.hash;

                    }).catch(error => {
                        console.log(error);
                        history.push('/dashboard');
                        addToast({
                            type: 'error',
                            title: 'Erro ao criar template!',
                            description: 'Ocorreu um erro ao tentar criar o template na base de dados!',
                        });
                    });

                if (flowHash !== "") {
                    history.push('/flow/' + flowHash);
                } else {
                    history.push('/my-flows');
                }

            }

        }

    }, [workspaceId, addToast, history, user.company_id, user.id_user]);

    const handleSubmitPage = useCallback(async () => {
        if (formRefFlow.current !== undefined) {
            formRefFlow.current?.submitForm();
        }
    }, []);

    //Keyboard Shortcuts
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
                handleSubmitPage();
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleSubmitPage]);

    return (
        <DialogContainer
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
        >
            <DialogTop>
                <DialogTopLeft>
                    <h1>
                        {"Criar um novo fluxo"}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>
            <Content>
                <ContentBody container>

                    <ContainerForm>
                        <FormBuilder
                            id="formFirstStep"
                            formRef={formRefFlow}
                            fields={fieldsFlowForm}
                            handleSubmit={handleSubmitForm}
                            hideContainer={true}
                        />
                    </ContainerForm>

                </ContentBody>
            </Content>
            <DialogFooter>
                <DialogFooterCenter>
                    <BtnInsertNewAnswer color={"#9337ed"} style={{ width: '130px', height: '36px' }} onClick={() => handleSubmitPage()} isLoading={loadingInsert}>
                        Criar
                    </BtnInsertNewAnswer>
                </DialogFooterCenter>
            </DialogFooter>
        </DialogContainer >
    );

}

export default NewFlow;