import { Email } from "../../../interfaces/Email";

export default function getBodyEmailToReply(lastEmail: Email, action: 'reply' | 'reply-all' | 'foreward'): string {

    let newBody = "";

    if (action === "reply" || action === 'reply-all') {

        newBody = "</br></br> <div style={{ color: 'black'}}>Em " + lastEmail.date_string_completed + ", " + lastEmail.from_name + " <" + lastEmail.from + "> escreveu: </br></br></div>" + lastEmail.emailPostmark?.HtmlBody;

    } else if (action === 'foreward') {

        newBody = "</br></br>";
        newBody += "---------- Forwarded message ---------";
        newBody += "</br>";
        newBody += "De: " + lastEmail.from_name + " <" + lastEmail.from + ">";
        newBody += "</br>";
        newBody += "Date: " + lastEmail.date_string_completed;
        newBody += "</br>";
        newBody += "Subject: " + lastEmail.subject;
        newBody += "</br>";
        newBody += "To: Matheus Wentzel <" + lastEmail.to + ">";
        newBody += "</br></br>";
        newBody += lastEmail.emailPostmark?.HtmlBody;

    }

    return newBody;

}