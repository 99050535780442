export const colorsElement = [
    "#f23b5c",  // Red
    "#4680B8",  // Blue
    "#9e37ed",  // Purple
    "#ff8c2f",  // Orange
    "#61bd4f",  // Green
    "#00aaff",  // Cyan
    "#ff007f",  // Magenta
    "#6a5acd",  // Slate Blue
    "#ffd700",  // Gold
    "#228B22",  // Forest Green
    "#D2691E",  // Chocolate
    "#8B0000",  // Dark Red
    "#20B2AA",  // Light Sea Green
    "#FF4500",  // Orange Red
    "#1E90FF",  // Dodger Blue
    "#32CD32",  // Lime Green
    "#9400D3"   // Dark Violet
];