import React from "react";
import { IconList, IconPickerItem } from "react-fa-icon-picker";

import {
    Container,
    StepContainerBottom,
    StepContainerTop
} from "./style";
import { Step } from "../../interfaces/Step";

interface StepItemProps {
    selectedIndex: number;
    step: Step;
    index: number;
    selectStepHandle: (stepId: number) => Promise<void>;
    moveStep: (dragIndex: number, hoverIndex: number) => void;
}

const StepItem: React.FC<StepItemProps> = ({ selectedIndex, step, index, selectStepHandle, moveStep }) => {

    return (
        <Container
            selectedIndex={selectedIndex}
            index={step.index}
            key={step.index}
            color={step.color}
            onClick={() => selectStepHandle(index)}
        >
            {/**/}
            <StepContainerTop>
                <IconPickerItem
                    icon={step.icon !== undefined ? step.icon as IconList : 'FaCodeBranch'}
                    color={step.color !== undefined ? step.color : "#f23b5c"}
                />
            </StepContainerTop>
            <StepContainerBottom>
                <h2>
                    {step.name}
                </h2>
            </StepContainerBottom>
        </Container>
    );

}

export default StepItem;