import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
`;

export const ContainerConfigRightHeader = styled.div`
    padding: 15px;
    width: 100%;
    border-bottom: 1px solid #f4f4f4;    
    position: relative;
`;

export const ContainerConfigRightHeaderTitle = styled.div`

    span {
        color: gray;
        font-size: 12px;
    }
`;

export const ContainerConfigRightHeaderSearch = styled.div`
`;

export const ContainerCloseButton = styled.div`
    position: absolute;
    right: 10px;
    top: 20px;
`;

export const ButtonClose = styled.button`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    width: 45px !important;
    height: 35px !important;
    margin-right: 15px !important;

    svg {
        font-size: 16px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
    
`;

export const ContainerConfigRightBody = styled.div`
    height: calc(100% - 72px);
`;