import styled, {css, keyframes} from "styled-components";

interface TemplateImageProps {
    url_image?: string;
    color: string;
}

interface TemplateIconContainerProps {
    color: string;
}

interface TemplateTagProps {
    color: string;
}

interface BtnTemplateProps {
    color: string;
}

export const Template = styled.div`
    grid-column: span 1 / auto;
    
    button {
        border: none;
        background: var(--light-base);
        box-shadow: inset 0 0 0 1px var(--gray-400);
        border-radius: var(--radius-medium);
        height: 100%;
        width: 100%;
        max-width: 400px;
        margin: auto;
        display: flex;
        flex-direction: column;
        -webkit-box-align: stretch;
        align-items: stretch;
        transition: box-shadow var(--productive) var(--ease-out);
        border-radius: 15px;
        border: 1px solid white;
        position: relative;
        box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
    }
`;

const shakeElement = keyframes`
    from {
        opacity: 1;
        transform: translateX(-15px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
    from {
        opacity: 1;
        transform: translateX(15px);
    } 
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const BtnTemplate = styled.button<BtnTemplateProps>`
    border: none;
    background: var(--light-base);
    box-shadow: inset 0 0 0 1px var(--gray-400);
    border-radius: var(--radius-medium);
    height: 100%;
    width: 100%;
    max-width: 400px;
    min-height: 275px;
    margin: auto;
    display: flex;
    flex-direction: column;
    -webkit-box-align: stretch;
    align-items: stretch;
    transition: box-shadow var(--productive) var(--ease-out);
    border-radius: 15px;
    border: 1px solid white;
    position: relative;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);

    :hover {
        ${(props) => props.color && css`
            background-color: ${props.color+"0d"};
            border: 1px solid ${props.color+"0d"};
        `}

        //animation: ${shakeElement} 1s;

    }
`;

export const TemplateImage = styled.div<TemplateImageProps>`
    margin-bottom: 20px;
    height: 100px;
    border-radius: 13px 13px 0px 0px;
    border-bottom: 5px solid #f44336;

    ${(props) => props.url_image && css`
        background: url(${props.url_image}) no-repeat center;
    `}

    ${(props) => props.color && css`
        border-bottom: 5px solid ${props.color};
    `}
    
`;

export const TemplateIconContainer = styled.div<TemplateIconContainerProps>`
    padding: 2px;
    background-color: #cddc39;
    border: 3px solid white;
    color: white;
    width: 60px;
    height: 50px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 70px;
    left: 15px;

    ${(props) => props.color && css`
        background-color: ${props.color};
    `}


    div {
        display: flex;
        
        svg {
            width: 25px;
            height: 25px;
        }
    }
`;

export const TemplateLabel = styled.div`
    text-align: start;
    margin-left: 15px;
    margin-right: 15px;
`;

export const TemplateName = styled.div`
    color: black;
    text-align: left;
    margin-top: 8px;
    font-weight: 500;
    font-size: 18px;
    margin-block-end: unset;
    margin-bottom: 10px;
`;

export const TemplateDescription = styled.div`
    margin-top: 5px;
    margin-bottom: 5px;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    color: #979797;
    max-height: 65px;
    overflow: hidden;
`;

export const TemplateTags = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    overflow-x: auto;
    bottom: 0;
    position: absolute;
`;

export const TemplateTag = styled.div<TemplateTagProps>`
    padding: 5px 10px 5px 10px;
    background-color: #673ab7;
    border-radius: 5px;
    color: white;
    font-size: 11px;
    font-weight: 500;
    margin-right: 10px;

    ${(props) => props.color && css`
        background-color: ${props.color};
    `}

`;
