import styled, { keyframes } from "styled-components";

import Button from "../../components/Button";

const pulse = keyframes`
    0% {
      transform: scale(1); 
    }
    50% {
      transform: scale(1.15); 
    }
    100% {
      transform: scale(1); 
    }
`;

export const ButtonUserAdd = styled(Button)`
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 35px;
    margin-bottom: 10px;
    margin-right: 25px !important;
    font-size: 14px;
    font-weight: 400;
    color: #4681b8;
    background-color: white;
    border: 1px dashed #4681b8;
    overflow: hidden;
    width: 120px !important;

    div {
        margin-top: 2px !important;

        svg {
            width: 18px;
            height: 18px;
            color: #4681b8;
        }
    }

    :hover {

        border: 1px dashed #f23b5c;
        background-color: white;
        color: #f23b5c;

        svg {
            color: #f23b5c;
        }

    }

    @media(max-width: 800px){
        display: none !important;
    }
`;


export const ContainerBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    width: 100%;
    //box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);

    @media(max-width: 800px){
        display: block;
    }
`;

export const ContainerBodyWelcome = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    width: 100%;
`;

export const ContainerContent = styled.div`
    width: 100%;
    border-radius: 10px;
    padding: 20px; 
    background-color: white;
    display: block;

    @media(max-width: 800px){
        margin-bottom: 20px;
        max-height: unset !important;
    }
`;

export const ContainerWelcome = styled.div`
    width: 100%;
    border-radius: 10px;
    padding: 20px; 
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 0px;
`;

export const ContainerHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;

    div {
        margin-bottom: 0px;
    }

    @media(max-width: 800px) {
        display: block;
    }
`;

export const ContainerHeaderRight = styled.div`
    width: calc(100% - 115px);
    display: flex;
    justify-content: end;
    align-items: center;  
    
    @media(max-width: 800px){
        width: 100% !important;
        text-align: center;
        margin: 0px;
        padding: 0px;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
`;

export const BtnGoToPage = styled.button`
    border: none;
    background: transparent;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    color: #f23b5c;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 15px;

    :hover {
        background-color: #f23b5c;
        color: white; 
    }
`;

export const ContainerFocus = styled.div`
    background-color: white;
    width: 130px;
    height: 125px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    justify-items: center;
    display: grid;
    padding: 10px;
    padding-right: 5px;
    padding-left: 5px;
    border: 1px solid transparent;  
    margin-right: 15px;

    svg {
        font-size: 50px;
        color: #ff8c2e;
        animation: ${pulse} 2s infinite;
    }

    :hover {
        border: 1px solid #ff8c2e;
    }

    @media(max-width: 800px){
        display: none !important;
    }
`;

export const ContainerFocusDisplay = styled.div`
    color: black;
    margin-top: 5px;
    border: none;
    background: transparent;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 3px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        font-size: 20px;
        margin-left: 3px;
        color: #ff8c2e;
        animation: none;
    }
`;

export const ContainerFocusBadge = styled.div`
`;