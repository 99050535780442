import styled from "styled-components";

export const Content = styled.div`
    margin-top: 15px;
`;

export const DisclaimerPreview = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px dashed #eef2f8;
    border-radius: 5px;
    padding: 10px;

    h1 {
        margin-left: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #4d4d4d;
    }

    svg {
        height: 15px;
        width: 15px;
        color: #4d4d4d;
    }

    :hover {

        border: 1px dashed #f23b5c;

        h1 {
            color: #f23b5c;
        }


        svg {
            color: #f23b5c;
        }

    }
`;