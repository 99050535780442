import { Box, ClickAwayListener } from "@material-ui/core";
import {
    AggregateItemLeft,
    AggregationContainer,
    AggregationItem,
    AggregationName,
    AggregationNameText,
    AggregationNameType,
    AggregationValue,
    BoxAddNew,
    BoxContainer,
    BoxItemsContainer,
    BoxSearchContainer,
    ButtonAddLeft,
    ButtonAddRegister,
    ButtonAddRight,
    ButtonBack,
    ButtonNew,
    Container,
    FormItemsContainer,
    FormItemsCounter,
    FormItemsForm,
    FormItemsHeader,
    ItemRegisterContainer,
    ItemRegisterDetail,
    ItemRegisterDetailBody,
    ItemRegisterDetailContainer,
    ItemRegisterDetailHead,
    ItemRegisterHead,
    ItemRegisterIcon,
    ItemRegisterTitle,
    NoResults,
    SearchInput
} from "./style";
import { FaExternalLinkAlt, FaPlus, FaTable } from "react-icons/fa";
import { IconList, IconPickerItem } from 'react-fa-icon-picker'
import ItemRegister, { ComboItem } from "../../../../ItemRegister";
import React, { useCallback, useEffect, useRef, useState } from "react";

import ContentLoader from "react-content-loader";
import ErrorDescription from "../../../../ErrorDescription";
import { FormAnswer } from "../../../../../interfaces/FormAnswer";
import { FormAnswerApi } from "../../../../../interfaces/FormAnswerApi";
import { FormAnswerFieldApi } from "../../../../../interfaces/FormAnswerFieldApi";
import FormBuilder from "../../../FormBuilder";
import { FormHandles } from "@unform/core";
import { IoMdListBox } from "react-icons/io";
import { Link } from "react-router-dom";
import { Register } from "../../../../../interfaces/Register";
import api from '../../../../../services/api';
import formAnswerToComboItemFromRegister from "../../../../../utils/formAnswerToComboItemFromRegister";
import { getItemComponentIcon, getMaskField } from "../../FieldBuilder";
import { Props as reacSelectProps } from "react-select";
import { useField } from "@unform/core";
import { useRouteMatch } from 'react-router-dom';
import { useToast } from '../../../../../hooks/toast';
import { FormAnswerField } from "../../../../../interfaces/FormAnswerField";
import formAnswerToObjectFormInit from "../../../../../utils/formAnswerToObjectFormInit";
import getAutoCompleteRule from "../../../../../utils/getAutoCompleteRule";
import { useAuth } from "../../../../../hooks/auth";
import getFormAnswerAggregations from "../../../../../middlewares/getFormAnswerAggregations";

interface RouteParams {
    hash: string;
    origin?: string;
    id?: string;
}

interface ComboOption {
    value: string;
    label: string;
}

interface objectInit {
    [x: string]: string | object
}

interface AggregateItemsProps {
    columnName: string;
    typeField: string;
    typeAggregate: string;
    variation?: string;
    value: number;
}

interface ComboBoxRegisterProps extends reacSelectProps {
    name: string;
    variation?: string;
    placeholder?: string;
    register_id?: number;
    options?: ComboOption[];
    max_length?: number;
    previewField?: boolean;
    flow_base_id?: number;
    register_base_id?: number;
    card_current_id?: number;
    isPublicForm?: boolean;
    public_hash?: string;
    filter_schema?: string;
    user_creator_id?: number;
    onForceBlur?: () => void;
}

const LoaderContainer = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={65}
        viewBox="0 0 500 65"
        backgroundColor="#ffffff"
        foregroundColor="#f3f3f3"
    >
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="50" />
    </ContentLoader>
)

const ComboBoxRegister: React.FC<ComboBoxRegisterProps> = ({ name, register_id, placeholder, options, variation, max_length, previewField, onForceBlur, flow_base_id, register_base_id, card_current_id, isPublicForm, public_hash, filter_schema, user_creator_id, ...rest }) => {

    //Variation
    //1 - Busca registros existentes no cadastro
    //2 - Criar novo registro no cadastro
    //3 - Buscar ou criar novo registro no cadastro

    //Max Lenght
    //1 - Único Item
    //2 - Múltiplos Itens

    //Possibilities (Mode)
    //1 - Busca registros + Único Item
    //2 - Busca registros + Múltiplos Itens
    //3 - Criar novo registro + Único Item
    //4 - Criar novo registro + Múltiplos Itens
    //5 - Busca ou criar novo registro + Único Item
    //6 - Busca ou criar novo registro + Múltiplos Itens

    //Function to define the possibilitie and set in a constant to work with
    function defineMode(variation: string | undefined, max_length: number | undefined) {

        let possibilitie: number = 1;

        //if max_length is undefined, set as 1
        if (max_length === undefined || max_length === null || max_length === 0) {
            max_length = 1;
        }

        if (variation === "1" && max_length === 1) {
            possibilitie = 1; //1 - Busca registros + Único Item
        } else if (variation === "1" && max_length === 2) {
            possibilitie = 2; //2 - Busca registros + Múltiplos Itens
        } else if (variation === "2" && max_length === 1) {
            possibilitie = 3; //3 - Criar novo registro + Único Item
        } else if (variation === "2" && max_length === 2) {
            possibilitie = 4; //4 - Criar novo registro + Múltiplos Itens
        } else if (variation === "3" && max_length === 1) {
            possibilitie = 5; //5 - Busca ou criar novo registro + Único Item
        } else if (variation === "3" && max_length === 2) {
            possibilitie = 6; //6 - Busca ou criar novo registro + Múltiplos Itens
        }

        return possibilitie;

    };

    //Function to define the place holder
    function definePlaceHolder(mode: number, placeholder: string | undefined) {

        let newPlaceHolder = placeholder;
        if (placeholder !== undefined && placeholder !== "" && placeholder !== null) {
            return newPlaceHolder;
        } else if (mode === 1 || mode === 2) {
            return "Selecione um registro...";
        } else if (mode === 3 || mode === 4) {
            return "Crie um novo registro...";
        } else if (mode === 5 || mode === 6) {
            return "Selecione ou crie um novo registro...";
        }

    }

    //Define the mode
    const mode: number = defineMode(variation, max_length);

    placeholder = definePlaceHolder(mode, placeholder);
    max_length = max_length === undefined ? 1 : max_length;
    variation = variation !== "" && variation !== undefined && variation !== null ? variation : "1";

    const { fieldName, error, registerField, defaultValue } = useField(name);
    const { params } = useRouteMatch<RouteParams>();
    const { addToast } = useToast();
    const { user } = useAuth();

    const selectRef = useRef(null);
    const formRef = useRef<FormHandles>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const [searchValue, setSearchValue] = useState<string>();
    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingForm, setLoadingForm] = useState<boolean>(true);
    const [loadingLargeSearch, setLoadingLargeSearch] = useState<boolean>(false);
    const [loadingAdding, setLoadingAdding] = useState<boolean>(false);
    const [items, setItems] = useState<ComboItem[]>();
    const [register, setRegister] = useState<Register>();
    const [isLargeData, setIsLargeData] = useState<boolean>(false);
    const [itemSelected, setItemSelected] = useState<ComboItem[]>([]);
    const [itemsFiltered, setItemsFiltered] = useState<ComboItem[]>();
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [firstUpdate, setFirstUpdate] = useState<boolean>(false);
    const [initValue, setInitValue] = useState({});

    const [aggregateItems, setAggregateItems] = useState<AggregateItemsProps[]>([]);
    const [formAnswers, setFormAnswers] = useState<FormAnswer[]>([]);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const handleSelect = useCallback(async (item: ComboItem) => {

        const haveInSelected = itemSelected.filter((itemSel) => itemSel.id_register === item.id_register);

        //Valid to not insert a duplicate item
        if (haveInSelected.length <= 0) {
            if (defaultValue === undefined) {
                setItemSelected(oldItems => [...oldItems, item]);
                setOpen(false);
                setFormAnswers(oldItems => [...oldItems, item.formAnswer as FormAnswer]);

            } else if (defaultValue !== undefined && register_id !== undefined) { //If is a record reference
                setItemSelected(oldItems => [...oldItems, item]);
                setOpen(false);
                setFormAnswers(oldItems => [...oldItems, item.formAnswer as FormAnswer]);

            } else {
                addToast({
                    type: 'error',
                    title: 'Não é possível remover o cadastro atual!',
                    description: 'Para fazer esta alteração será necessário remover o campo e incluir um novo! Todos os dados poderão ser perdidos!',
                });
            }
        } else {
            addToast({
                type: 'error',
                title: "O Registro '" + item.title + "' já está na seleção!",
                description: 'Você deve escolher um registro que ainda não está na sua seleção!',
            });
        }

    }, [addToast, defaultValue, register_id, itemSelected]);

    const handleRemove = useCallback(async (itemRemove: ComboItem) => {

        let oldItems: ComboItem[] = [];

        itemSelected.map((item) => {
            if (item.id_register !== itemRemove.id_register) {
                oldItems.push(item);
            }
            return item;
        });

        //Remove the form answer
        let oldFormAnswers: FormAnswer[] = [];

        formAnswers.map((item) => {
            if (item.id_form_answer !== itemRemove.id_register) {
                oldFormAnswers.push(item);
            }
            return item;
        });

        setFormAnswers(oldFormAnswers);

        if (defaultValue === undefined) {
            setItemSelected(oldItems);
        } else if (defaultValue !== undefined && register_id !== undefined) { //If is a record reference
            setItemSelected(oldItems);
        } else { //If is a register reference
            addToast({
                type: 'error',
                title: 'Não é possível remover o cadastro atual!',
                description: 'Para fazer esta alteração será necessário remover o campo e incluir um novo! Todos os dados poderão ser perdidos!',
            });
        }

    }, [addToast, defaultValue, register_id, itemSelected, formAnswers]);

    const getAggregations = useCallback(async (formAnswers: FormAnswer[]) => {

        if (register !== undefined && register.fields_aggregate !== undefined && register.fields_aggregate.length >= 0) {
            const newAggregation = getFormAnswerAggregations(register.fields_aggregate, formAnswers);
            setAggregateItems(newAggregation);
        }

    }, [register]);

    const handleSubmit = useCallback(async (data: object[]) => {

        //Variables
        const dataNormalized = data as unknown as FormAnswerFieldApi[];
        let objApi: FormAnswerApi | undefined = undefined;

        if (register !== undefined && register.form_id !== undefined) { //If is a Register

            objApi = {
                id_form: register?.form_id,
                origin: isPublicForm ? "/PublicForm" : "/ComboBoxRegister",
                values: dataNormalized,
                register_id: register?.id_register,
                deleted: "W",
                flow_parent_id: flow_base_id,
                register_parent_id: register_base_id,
                public_hash: isPublicForm && public_hash ? public_hash : undefined
            }

        } else {
            addToast({
                type: 'error',
                title: 'Erro ao inserir um novo item [2]',
                description: 'Ocorreu um erro ao inserir o registro!',
            });
        }

        const endpoint = isPublicForm ? `/form-public/new-answer` : '/form/new-answer';
        const endpointGet = isPublicForm ? `/form-public/register/answer` : `/register/answer`;

        if (register !== undefined && objApi !== undefined) {

            //setOpenForm(false);
            setLoadingAdding(true);

            await api
                .post(endpoint, objApi)
                .then(response => {

                    let retAnswer: FormAnswer = response.data;

                    //Renew the object and set as selected
                    api.get(endpointGet, {
                        params: {
                            id_register: register.id_register,
                            id_form_answer: retAnswer.id_form_answer,
                            withWaitingAnswers: true,
                            flow_parent_id: flow_base_id,
                            register_parent_id: register_base_id,
                            public_hash: isPublicForm && public_hash ? public_hash : undefined
                        }
                    }).then(response => {

                        retAnswer = response.data;

                        const newComboItem: ComboItem = formAnswerToComboItemFromRegister(retAnswer, register);

                        setItemSelected(oldItems => [...oldItems, newComboItem]);
                        setFormAnswers(oldItems => [...oldItems, retAnswer]);

                        if (variation === "2") {
                            setOpenForm(false);
                        } else if (variation === "3") {
                            setOpenForm(false);
                            setOpen(false);
                        }

                        if (onForceBlur !== undefined) {
                            onForceBlur();
                        }

                        setLoadingAdding(false);
                    }).catch(error => {
                        setLoadingAdding(false);
                        console.log(error);
                        addToast({
                            type: 'error',
                            title: 'Erro ao inserir um novo item [4]',
                            description: 'Ocorreu um erro ao inserir o registro!',
                        });
                    });
                }).catch(error => {
                    setLoadingAdding(false);
                    console.log(error);
                    addToast({
                        type: 'error',
                        title: 'Erro ao inserir um novo item [1]',
                        description: 'Ocorreu um erro ao inserir o registro!',
                    });
                });

        } else {
            addToast({
                type: 'error',
                title: 'Erro ao inserir um novo item [3]',
                description: 'Ocorreu um erro ao inserir o registro!',
            });
        }

    }, [addToast, register, variation, onForceBlur, flow_base_id, register_base_id, isPublicForm, public_hash]);

    const getApiAnswer = useCallback(async (registerObj: Register) => {

        let arrItems: string[] = [];

        if (typeof defaultValue === "string") {
            arrItems.push(defaultValue);
        } else if (typeof defaultValue === "object") {
            arrItems = defaultValue as unknown as string[];
        }

        const endpoint = isPublicForm ? `/form-public/register/answer` : `/register/answer`;

        if (registerObj !== undefined) {

            let newItemSelect: ComboItem[] = [];
            let newFormAnswer: FormAnswer[] = [];

            for (let index = 0; index < arrItems.length; index++) {
                const faId = arrItems[index];

                await api.get(endpoint, {
                    params: {
                        id_register: registerObj.id_register,
                        id_form_answer: faId,
                        withWaitingAnswers: true,
                        flow_parent_id: flow_base_id,
                        register_parent_id: register_base_id,
                        public_hash: isPublicForm && public_hash ? public_hash : undefined
                    }
                }).then(response => {

                    const retAnswer = response.data;

                    const newComboItem: ComboItem = formAnswerToComboItemFromRegister(retAnswer, registerObj);

                    newItemSelect.push(newComboItem);
                    newFormAnswer.push(retAnswer);

                }).catch(error => {
                    console.log(error);
                    addToast({
                        type: 'error',
                        title: 'Erro ao carregar o registro [4]',
                        description: 'Ocorreu um erro ao carregar o registro!',
                    });
                });

            }

            setItemSelected(newItemSelect);
            setFormAnswers(newFormAnswer);
            setLoading(false);
        }

    }, [addToast, defaultValue, flow_base_id, register_base_id, isPublicForm, public_hash]);

    const getApiRegister = useCallback(async (registerId: number, withAnswers: boolean, likeSearch?: string) => {

        if (likeSearch === undefined) {
            setLoadingForm(true);
            setLoading(true);
        } else {
            setLoadingLargeSearch(true);
        }

        const endpoint = isPublicForm ? `/form-public/register` : `/register`;

        try {
            const responseRegister = await api.get(endpoint, {
                params: {
                    id_register: registerId,
                    withAnswers: withAnswers,
                    withAutoComplete: mode === 3 || mode === 4 || mode === 5 || mode === 6 ? true : false,
                    flow_parent_id: flow_base_id,
                    register_parent_id: register_base_id,
                    public_hash: isPublicForm && public_hash ? public_hash : undefined,
                    isReadyForLargeData: true,
                    likeSearch: likeSearch,
                    filterSchema: filter_schema
                }
            });

            if (responseRegister !== undefined && responseRegister.data !== null) {
                const registersApi: Register = responseRegister.data;

                let itemsNew: ComboItem[] = [];
                let formAnswerFields: FormAnswerField[] = [];

                //Set the items in the combo
                if (registersApi.form_answers !== undefined) {

                    for (let index = 0; index < registersApi.form_answers.length; index++) {

                        const newComboItem: ComboItem = formAnswerToComboItemFromRegister(registersApi.form_answers[index], registersApi);

                        itemsNew.push(newComboItem);

                    }

                    setItems(itemsNew);
                    setItemsFiltered(itemsNew);

                } else {
                    setItems([]);
                    setItemsFiltered([]);
                }

                //Auto Complete - Rule    
                if (!isPublicForm) {
                    if (registersApi !== undefined && registersApi.form !== undefined && registersApi.form.fields !== undefined && registersApi.form.fields !== null) {

                        let userCardCreator: number = user_creator_id !== undefined ? user_creator_id : user.id_user;

                        formAnswerFields = await getAutoCompleteRule('combo', registersApi.form.fields, user.id_user, userCardCreator, card_current_id);

                        const newObjData: objectInit = formAnswerToObjectFormInit(formAnswerFields);
                        setInitValue(newObjData);

                    }
                }

                if (defaultValue !== undefined) {
                    getApiAnswer(registersApi);
                } else {
                    setLoading(false);
                }

                //Check if is large data
                if (registersApi.isLargeData !== undefined && registersApi.isLargeData !== null && registersApi.isLargeData === "S") {
                    setIsLargeData(true);
                }

                setRegister(registersApi);

            }

        } catch (error) {
            console.log(error);
            addToast({
                type: 'error',
                title: 'Erro ao carregar os registros [3]',
                description: 'Ocorreu um erro ao carregar os registros!',
            });
        }

        setLoadingForm(false);
        setLoadingLargeSearch(false);

    }, [getApiAnswer, defaultValue, addToast, register_base_id, flow_base_id, card_current_id, mode, user, isPublicForm, public_hash, filter_schema, user_creator_id]);

    const getApiRegisterSelect = useCallback(async () => {
        setLoading(true);
        api.get(`/register/my-registers`)
            .then(response => {
                if (response.data !== null) {

                    const registersApi: Register[] = response.data;
                    let itemsNew: ComboItem[] = [];

                    for (let index = 0; index < registersApi.length; index++) {

                        const title: string = registersApi[index].name === undefined || registersApi[index].name === null ? "Rascunho" : registersApi[index].name + "";

                        const itemNew: ComboItem = {
                            id_register: registersApi[index].id_register,
                            title: title,
                            hash: registersApi[index].hash,
                            icon: registersApi[index].icon === null ? "FaWrench" : registersApi[index].icon,
                            color: registersApi[index].color === null ? "#795548" : registersApi[index].color,
                            origin: "Meus cadastros",
                            details: [
                                {
                                    title: "Total de Registros",
                                    Icon: getItemComponentIcon("TEXT_SHORT_FIELD"),
                                    value: registersApi[index].total_registers !== undefined ? registersApi[index].total_registers + "" : "0"
                                }
                            ]
                        }

                        itemsNew.push(itemNew);

                    }

                    //Remove the actually register
                    if (params.hash !== undefined) {
                        itemsNew = itemsNew.filter((r) => r.hash !== params.hash);
                    }

                    //If have already a selected value
                    if (defaultValue !== undefined) {
                        const selectValFil = itemsNew.filter((item) => item.id_register === defaultValue);
                        if (selectValFil !== undefined && selectValFil.length > 0) {
                            setItemSelected(selectValFil);
                        }
                    }

                    setItems(itemsNew);
                    setItemsFiltered(itemsNew);

                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }, [defaultValue, params.hash]);

    const handleFilter = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {

        e.persist();

        const SEARCH_DELAY = 1000; // 1 second delay

        const searchText = e.currentTarget.value.toLowerCase();

        if (items !== undefined && !isLargeData) {

            let itemsNew = items;

            //Create logic to dynamic content
            const itemsTitle = itemsNew.filter((item) => {

                //Filter by title
                if (item.title.toLowerCase().includes(searchText)) {
                    return true;
                }

                //Filter by details
                if (item.details !== undefined && item.details.length > 0) {
                    for (let index = 0; index < item.details.length; index++) {
                        const detail = item.details[index];

                        if (detail.value.toLowerCase().includes(searchText)) {
                            return true;
                        }

                    }
                }

                return false;

            });

            setItemsFiltered(itemsTitle);
            setSearchValue(searchText);

        } else {

            if (register_id !== undefined && Number(register_id) > 0) {
                clearTimeout(searchTimeout as NodeJS.Timeout);
                setSearchTimeout(
                    setTimeout(() => {
                        getApiRegister(register_id, true, searchText);
                    }, SEARCH_DELAY)
                );

            }

        }

    }, [items, register_id, isLargeData, searchTimeout, getApiRegister]);

    const handleAddNewItem = useCallback(async () => {

        if (formRef.current !== null) {
            formRef.current.submitForm();
            //formRef.current.reset();
        }

    }, []);

    useEffect(() => {

        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }

        if (!open) {
            setItemsFiltered(items);
        }

    }, [open, items]);

    useEffect(() => {

        if (!firstUpdate) {

            //Creating a new field (New Field)
            if (register_id === undefined) {
                getApiRegisterSelect();
            }

            //Get the register items
            if (register_id !== undefined && Number(register_id) > 0) {

                if (defaultValue !== undefined) {
                    if (mode === 1 || mode === 2 || mode === 5 || mode === 6) { //There is the possibility to select a register
                        getApiRegister(register_id, true);
                    } else {
                        getApiRegister(register_id, false);
                    }
                } else if (mode === 3 || mode === 4) {
                    getApiRegister(register_id, false);
                } else {
                    getApiRegister(register_id, true);
                }

            }

            setFirstUpdate(true);
        }

    }, [getApiRegisterSelect, getApiRegister, mode, register_id, defaultValue, firstUpdate]);

    useEffect(() => {

        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref: any) => {
                if (loading && firstUpdate) {
                    return defaultValue;
                } else {
                    return itemSelected.map(({ id_register }) => id_register);
                }
            },
            clearValue: ref => {
                setItemSelected([]);
            },
        });

    }, [fieldName, registerField, itemSelected, defaultValue, loading, firstUpdate]);

    useEffect(() => {

        if (formAnswers !== undefined) {
            getAggregations(formAnswers);
        }

    }, [formAnswers, getAggregations]);

    return (
        <>
            <Container isErrored={!!error}>

                {!firstUpdate || loading ?
                    <>
                        <LoaderContainer />
                    </> :

                    <>

                        {/* If has a register selected, show the register */}
                        {!loading && itemSelected !== undefined ?
                            itemSelected.map((item) => (
                                <ItemRegister key={item.id_register} type={'Register'} item={item} handleRemove={() => handleRemove(item)} />
                            )) :
                            <></>
                        }

                        {/* Aggregation */}
                        {aggregateItems !== undefined && aggregateItems.length > 0 && itemSelected !== undefined && itemSelected.length > 0 && max_length === 2 ?
                            <AggregationContainer>
                                {aggregateItems.map((item) => (
                                    <AggregationItem key={item.columnName + item.typeField} color={register !== undefined && register.color !== undefined ? register.color : "#9e37ed"}>
                                        <AggregateItemLeft>
                                            <AggregationName>
                                                <AggregationNameText>{item.columnName}</AggregationNameText>
                                                <AggregationNameType>
                                                    {"(" + item.typeAggregate + ")"}
                                                </AggregationNameType>
                                            </AggregationName>
                                            <AggregationValue>
                                                {item.value !== undefined ? getMaskField(item.typeField, item.value, item.variation) : "0"}
                                            </AggregationValue>
                                        </AggregateItemLeft>
                                    </AggregationItem>
                                ))}
                            </AggregationContainer> : <></>
                        }

                        {/* If can choose a new register, show the button */}
                        {(((mode === 1 || mode === 5) && itemSelected.length < 1) || (mode === 2 || mode === 6)) && !openForm && !loading ?
                            <Box>

                                {defaultValue !== undefined && loading ?
                                    <>
                                        <LoaderContainer />
                                    </> :
                                    <ButtonAddRegister type="button" onClick={handleClick}>
                                        <ButtonAddLeft>
                                            <FaPlus />
                                        </ButtonAddLeft>
                                        <ButtonAddRight>
                                            {placeholder}
                                        </ButtonAddRight>
                                    </ButtonAddRegister>
                                }

                                {open ? (
                                    <ClickAwayListener onClickAway={handleClickAway}>
                                        <BoxContainer>

                                            <BoxSearchContainer>
                                                <SearchInput
                                                    placeholder="Buscar Registros"
                                                    value={searchValue}
                                                    onChange={handleFilter}
                                                    ref={searchInputRef}
                                                >

                                                </SearchInput>
                                                {mode === 5 || mode === 6 ?
                                                    <BoxAddNew onClick={() => {
                                                        setOpenForm(true)
                                                    }}>+ Adicionar novo</BoxAddNew> :
                                                    <></>
                                                }
                                            </BoxSearchContainer>

                                            <BoxItemsContainer>

                                                {loading ?
                                                    <>
                                                        <LoaderContainer />
                                                        <LoaderContainer />
                                                        <LoaderContainer />
                                                    </> :
                                                    <></>
                                                }

                                                {!loading && !loadingLargeSearch && itemsFiltered !== undefined && itemsFiltered?.length > 0 ?
                                                    itemsFiltered?.map((item) => {
                                                        return (
                                                            <ItemRegisterContainer key={item.id_register + item.title} color={item?.color} onClick={() => handleSelect(item)}>
                                                                <ItemRegisterHead
                                                                    key={item.id_register}
                                                                    color={item?.color}
                                                                    selected={false}
                                                                >
                                                                    <ItemRegisterIcon color={item.color}>
                                                                        {item !== undefined && item.icon !== undefined ?
                                                                            <IconPickerItem
                                                                                icon={item.icon as IconList}
                                                                                color={item.color}
                                                                            /> :
                                                                            <IoMdListBox />
                                                                        }
                                                                    </ItemRegisterIcon>
                                                                    <ItemRegisterTitle>
                                                                        <h1>{item.title}</h1>
                                                                        <span>{item.origin}</span>
                                                                    </ItemRegisterTitle>
                                                                    <Link to={"/register/" + item.hash} style={{ position: 'absolute', right: '10px', textDecoration: 'none' }} target="_blank">
                                                                        <FaExternalLinkAlt style={{ marginLeft: '10px', fontSize: '11px', color: item.color }} />
                                                                    </Link>
                                                                </ItemRegisterHead>
                                                                {item.details !== undefined && item.details?.length > 0 ?
                                                                    <ItemRegisterDetailContainer>
                                                                        {item.details?.map((detail) => {
                                                                            return (
                                                                                <ItemRegisterDetail key={detail.title}>
                                                                                    <ItemRegisterDetailHead>
                                                                                        {detail !== undefined && detail.Icon !== undefined ?
                                                                                            <detail.Icon /> :
                                                                                            <IoMdListBox />
                                                                                        }
                                                                                        <h3>{detail.title}</h3>
                                                                                    </ItemRegisterDetailHead>
                                                                                    <ItemRegisterDetailBody>{detail.value}</ItemRegisterDetailBody>
                                                                                </ItemRegisterDetail>
                                                                            )
                                                                        })}
                                                                    </ItemRegisterDetailContainer> :
                                                                    <></>}
                                                            </ItemRegisterContainer>
                                                        )
                                                    }) :
                                                    !loading && !isLargeData ?
                                                        <>
                                                            <NoResults>Sem Registros</NoResults>
                                                        </> : loadingLargeSearch ?
                                                            <>
                                                                <LoaderContainer />
                                                                <LoaderContainer />
                                                                <LoaderContainer />
                                                            </> :
                                                            <>
                                                                <NoResults>Pesquise para buscar os registros</NoResults>
                                                            </>
                                                }

                                                {register_id === undefined ?
                                                    <Link to={"/register-list/"} style={{ textDecoration: 'none' }} target="_blank">
                                                        <ItemRegisterContainer color={"#f23b5c"}>
                                                            <ItemRegisterHead
                                                                color={"#f23b5c"}
                                                                selected={false}
                                                            >
                                                                <ItemRegisterIcon color={"#f23b5c"}>
                                                                    <FaTable />
                                                                </ItemRegisterIcon>
                                                                <ItemRegisterTitle>
                                                                    <h1>Crie um novo cadastro agora!</h1>
                                                                    <span>Clique aqui</span>
                                                                </ItemRegisterTitle>
                                                                <FaExternalLinkAlt style={{ marginLeft: '10px', fontSize: '11px', color: "#f23b5c" }} />
                                                            </ItemRegisterHead>
                                                        </ItemRegisterContainer>
                                                    </Link> :
                                                    <></>
                                                }

                                            </BoxItemsContainer>

                                        </BoxContainer>
                                    </ClickAwayListener>
                                ) : null}

                            </Box> :
                            <></>
                        }

                        {/* If can add a new register, show the form */}
                        {((mode === 3 && itemSelected.length < 1) ||
                            (mode === 4 && (openForm || itemSelected.length < 1)) ||
                            (mode === 5 && openForm && itemSelected.length < 1) ||
                            (mode === 6 && openForm)) &&
                            !loading ?

                            <>
                                {defaultValue !== undefined && loadingForm ?
                                    <LoaderContainer /> :
                                    <>
                                        {!openForm ?
                                            <ButtonAddRegister type="button" onClick={() => setOpenForm(true)}>
                                                <ButtonAddLeft>
                                                    <FaPlus />
                                                </ButtonAddLeft>
                                                <ButtonAddRight>
                                                    {placeholder}
                                                </ButtonAddRight>
                                            </ButtonAddRegister> :
                                            <>
                                                <FormItemsContainer color={register?.color}>
                                                    <FormItemsHeader color={register?.color}>

                                                        <ItemRegisterIcon color={register?.color}>
                                                            {register !== undefined && register.icon !== undefined ?
                                                                <IconPickerItem
                                                                    icon={register.icon as IconList}
                                                                    color={register.color}
                                                                /> :
                                                                <IoMdListBox />
                                                            }
                                                        </ItemRegisterIcon>
                                                        <ItemRegisterTitle style={{ width: '100%' }}>
                                                            <h1>{register?.name}</h1>
                                                            <span>Adicionando novo registro</span>
                                                        </ItemRegisterTitle>

                                                        {max_length === 2 ?
                                                            <FormItemsCounter color={register?.color}>
                                                                Item {itemSelected.length + 1}
                                                            </FormItemsCounter> :
                                                            <></>}
                                                    </FormItemsHeader>
                                                    <FormItemsForm>
                                                        {!loadingForm ?
                                                            register !== undefined && register.form !== undefined ?
                                                                <FormBuilder
                                                                    id="registerComboRegister"
                                                                    hideContainer={true}
                                                                    formRef={formRef}
                                                                    fields={register.form?.fields}
                                                                    register_id={register.id_register}
                                                                    handleSubmit={handleSubmit}
                                                                    flow_parent_id={flow_base_id}
                                                                    initialValue={initValue}
                                                                    typeUser={register?.typeUserAccess}
                                                                    isPublicForm={isPublicForm}
                                                                    public_hash={public_hash}
                                                                    activeHiddenFields={true}
                                                                /> :
                                                                <></> :
                                                            <>
                                                                <LoaderContainer />
                                                                <LoaderContainer />
                                                                <LoaderContainer />
                                                            </>
                                                        }
                                                    </FormItemsForm>
                                                </FormItemsContainer>
                                                <div style={{ height: '56px' }}>
                                                    {max_length === 2 ?
                                                        <ButtonNew type="button" onClick={handleAddNewItem} color={register?.color} isLoading={loadingAdding}>Adicionar item</ButtonNew> :
                                                        <ButtonNew type="button" onClick={handleAddNewItem} color={register?.color} isLoading={loadingAdding}>Criar Registro</ButtonNew>
                                                    }
                                                    {openForm ?
                                                        <ButtonBack type="button" onClick={() => setOpenForm(false)} isLoading={loadingAdding}>Fechar</ButtonBack> :
                                                        <></>
                                                    }
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </> :
                            max_length === 2 && (mode === 3 || mode === 4) ?
                                <div style={{ height: '25px' }}>
                                    <ButtonNew type="button" onClick={() => setOpenForm(true)} style={{ marginTop: "0px" }} isLoading={loadingAdding} color={register?.color}>Adicionar novo item</ButtonNew>
                                </div> :
                                <></>
                        }
                    </>
                }
            </Container>
            {error && (<ErrorDescription title={error} />)}
        </>
    );

}

export default ComboBoxRegister;