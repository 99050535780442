import React from "react";

import {
    OverlayContainer,
    OverlayContent,
    OverlayIcon
} from "./style";
import { FaCrown } from "react-icons/fa";

interface OverlayFreemiumProps {
    title: string,
    description: string,
    buttonText: string,
    planRequired: string,
    maxWidth?: number,
    onUpgrade: () => void
}

const OverlayFreemium: React.FC<OverlayFreemiumProps> = ({ title, description, buttonText, planRequired, maxWidth, onUpgrade }) => {

    return (
        <OverlayContainer>
            <OverlayContent maxWidth={maxWidth}>
                <OverlayIcon>
                    <FaCrown />
                </OverlayIcon>
                <h3>{title}</h3>
                <p>{description} {" Faça o upgrade para o plano "} <b>{planRequired}</b> {" e tenha acesso a esta funcionalidade."}</p>
                <button onClick={onUpgrade}>{buttonText}</button>
            </OverlayContent>
        </OverlayContainer>
    );

}

export default OverlayFreemium;