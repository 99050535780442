import React, { InputHTMLAttributes, useCallback, useEffect, useRef, useState } from "react";
import { TbMathFunction, TbPercentage } from "react-icons/tb";

import { Container } from "./style";
import ErrorDescription from "../../../../ErrorDescription";
import { FaMoneyBill } from "react-icons/fa";
import { TiSortNumerically } from "react-icons/ti";
import { useField } from "@unform/core";

interface FormulaProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    formula?: string;
    description?: string;
    variation?: string;
}

export const maskFormulaCurrency = (value: string) => {
    return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

export const maskFormulaNumber = (value: string) => {
    return (Number(value)).toLocaleString();
};

export const maskFormulaPercent = (value: string) => {
    return String(Math.round((Number(value) * 100) * 100) / 100) + "%"
};

const Formula: React.FC<FormulaProps> = ({ name, formula, description, variation, ...rest }) => {

    const { fieldName, defaultValue, error, registerField } = useField(name);
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [valueFormula, setValueFormula] = useState<string>(defaultValue !== undefined ? defaultValue : "");

    const handleInputFocused = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
    }, []);

    const setMask = useCallback((value: string) => {

        //If there is a variation
        if (variation !== undefined) {
            if (variation === "1") { //Number
                setValueFormula(maskFormulaNumber(value));
            } else if (variation === "2") { //Currency
                setValueFormula(maskFormulaCurrency(value));
            } else if (variation === "3") { //Percent
                setValueFormula(maskFormulaPercent(value));
            } else {
                setValueFormula(maskFormulaNumber(value));
            }

        }

    }, [variation]);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {

                let value: string = ref.current.value;

                if (variation !== undefined && variation === "1") {
                    return value === "" ? undefined : Number(value);
                } else if (variation !== undefined && variation === "2") {
                    value = value.replaceAll(".", "").replace(",", ".");
                    value = value.replace("R$ ", "");

                    return value === "" ? undefined : Number(value.replace(/[^0-9.-]+/g, ""));
                } else if (variation !== undefined && variation === "3") {
                    value = value.replaceAll("% ", "");
                    return value === "" ? undefined : (Number(value) / 100);
                }

                return value === "" ? undefined : Number(value);

            },
            setValue: (ref, value) => {
                ref.current.value = value;
                if (value !== undefined) {

                    //If there is a variation
                    if (variation !== undefined) {
                        if (variation === "1") { //Number
                            ref.current.value = maskFormulaNumber(String(value));
                            setValueFormula(maskFormulaNumber(String(value)));
                        } else if (variation === "2") { //Currency
                            ref.current.value = maskFormulaCurrency(String(value));
                            setValueFormula(maskFormulaCurrency(String(value)));
                        } else if (variation === "3") { //Percent
                            ref.current.value = maskFormulaPercent(String(value));
                            setValueFormula(maskFormulaPercent(String(value)));
                        } else {
                            ref.current.value = maskFormulaNumber(String(value));
                            setValueFormula(maskFormulaNumber(String(value)));
                        }

                    }

                }
            },
            clearValue: ref => {
                ref.current.value = ''
                setValueFormula("");
            },
        })
    }, [fieldName, registerField, variation]);

    return (
        <>
            <Container isErrored={!!error} isFocused={isFocused} isDisabled={true} >
                {variation !== undefined && variation === "1" ?
                    <TiSortNumerically size="20" /> :
                    variation !== undefined && variation === "2" ?
                        <FaMoneyBill size="20" /> :
                        variation !== undefined && variation === "3" ?
                            <TbPercentage size="20" /> :
                            <TbMathFunction size="20" />
                }
                <input
                    onChange={(e) => setMask(e.target.value)}
                    onFocus={handleInputFocused}
                    onBlur={handleInputBlur}
                    disabled={true}
                    ref={inputRef}
                    value={valueFormula}
                    {...rest}
                />

            </Container>

            {error && (<ErrorDescription title={error} />)}
        </>
    );

}

export default Formula;