import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    Animation,
    ChartContainer,
    ChartItem,
    ChartItemBody,
    ChartItemTitle,
    Container,
    ContainerLeft,
    ContainerLoader,
    ContainerRight,
    HelpContainer,
    HelpDescription,
    HelpIcon,
    HelpItem,
    HelpItems,
    HelpText,
    LogArea,
    LogBody,
    LogContainer,
    LogGroup,
    LogGroupItem,
    LogGroupLine,
    LogGroupTitle,
    LogGroupTitleText,
    LogHeader,
    LogHeaderCardDeleted,
    LogHeaderIcon,
    LogHeaderInfo,
    LogHeaderOrigin,
    LogHeaderTitle,
    LogHistoryAvatar,
    LogHistoryChildren,
    LogHistoryDate,
    LogHistoryDescription,
    LogHistoryExpanded,
    LogHistoryItem,
    LogHistoryUserName,
    LogItems
} from "./style";
import api from "../../../services/api";
import { LogHistory } from "../../../interfaces/LogHistory";
import { formatDateToDateRead } from "../../../utils/formatDateToDateRead";
import { Card } from "../../../interfaces/Card";
import AvatarCange from "../../../components/AvatarCange";
import { useAuth } from "../../../hooks/auth";
import LineChart from "./ActivityTabCharts/LineChart";
import RankingChart from "./ActivityTabCharts/RankingChart";
import { FiHelpCircle } from "react-icons/fi";
import Dialog from "../../../dialogs/Dialog";
import ActivityFilterBar from "./ActivityFilterBar";
import lottie from "lottie-web";
import { IoIosWarning } from "react-icons/io";
import EmptyActivities from "../../../components/EmptyState/EmptyActivities";

interface ActivityTabProps {
    flow_id: number;
    isTestModel: boolean;
    onClickCard: (card: Card) => Promise<void>;
}

const ActivityTab: React.FC<ActivityTabProps> = ({ flow_id, isTestModel, onClickCard }) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [activities, setActivities] = useState<LogHistory[]>([]);
    const [activitiesFiltered, setActivitiesFiltered] = useState<LogHistory[]>([]);
    const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
    const [allExpanded, setAllExpanded] = useState<boolean>(false);
    const [groupByType] = useState<number>(0); // 0 - Date Group
    const lottieContainer = useRef<HTMLDivElement>(null);

    const [days, setDays] = useState<number>(7);

    const { user } = useAuth();

    const [open, setOpen] = React.useState(false);
    const [dialogProps, setDialogProps] = React.useState({ title: '', value: '' });
    const handleDialog = (title: string, value: string) => {
        setDialogProps({
            title: title,
            value: value
        });
        setOpen(!open);
    };

    const getApiActivities = useCallback(async (id_flow: number) => {

        setLoading(true);

        api.get(`/activity/by-flow`, {
            params: {
                flow_id: id_flow,
                days: days
            }
        }).then(response => {
            if (response.data !== null) {

                let respActivities: LogHistory[] = response.data;

                if (respActivities !== undefined) {

                    //Format date
                    respActivities = respActivities.map((activity: LogHistory) => {

                        return {
                            ...activity,
                            date_group: formatDateToDateRead(new Date(activity.dt_action))
                        }

                    });

                    setActivities(respActivities);
                    setActivitiesFiltered(respActivities);
                }

            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            console.log(error);
        });

    }, [days]);

    const toggleGroup = (id: string) => {
        if (expandedGroups.includes(id)) {
            setExpandedGroups(expandedGroups.filter((group) => group !== id));
        } else {
            setExpandedGroups([...expandedGroups, id]);
        }
    };

    const groupedLogs: { [groupName: string]: LogHistory[] } = activitiesFiltered.reduce(
        (acc, item) => {

            if (groupByType === 0) { //Date Group
                if (item.date_group !== undefined) {
                    if (acc[item.date_group]) {
                        acc[item.date_group].push(item);
                    } else {
                        acc[item.date_group] = [item];
                    }
                }
            }

            return acc;
        },
        {} as { [groupName: string]: LogHistory[] }
    );

    useEffect(() => {

        getApiActivities(flow_id);

    }, [flow_id, getApiActivities]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../assets/lottie/loader.json')
            })
        }
    }, []);

    return (
        <>
            <ActivityFilterBar
                days={days}
                logItems={activities}
                setLogItemsFiltered={setActivitiesFiltered}
                setAllExpanded={setAllExpanded}
                setDays={setDays}
            />
            <Container>

                {!loading && activitiesFiltered.length === 0 ?
                    <EmptyActivities />
                    :
                    <>
                        <ContainerLeft
                            item
                            xs={12}
                            sm={12}
                            md={8}
                            lg={8}
                            style={{ marginBottom: '10px', maxHeight: '85vh', overflowY: 'auto', marginRight: '10px' }}
                        >

                            {isTestModel && (
                                <HelpContainer>
                                    <HelpItems>
                                        <HelpItem>
                                            <HelpIcon>
                                                <IoIosWarning />
                                            </HelpIcon>
                                            <HelpDescription>
                                                No modo teste não está disponível a visualização de atividades de cartões que foram inseridos neste modo
                                            </HelpDescription>
                                        </HelpItem>
                                    </HelpItems>
                                </HelpContainer>
                            )}

                            {loading ?
                                <ContainerLoader>
                                    <Animation className="lottieContainer" ref={lottieContainer} />
                                </ContainerLoader > :
                                <>
                                    {Object.entries(groupedLogs).map(([groupName, itemsLog]) => {

                                        return (
                                            <LogGroup key={groupName}>
                                                <LogGroupItem>
                                                    <LogGroupLine />
                                                    <LogGroupTitle>

                                                        <LogGroupTitleText>
                                                            {groupName}
                                                        </LogGroupTitleText>

                                                    </LogGroupTitle>

                                                </LogGroupItem>

                                                <LogItems>
                                                    {itemsLog.map((log) => {

                                                        let isExpanded = expandedGroups.includes(String(log.id_log_history));

                                                        if (allExpanded) {
                                                            isExpanded = true;
                                                        }

                                                        return (
                                                            <LogArea
                                                                key={log.id_log_history}
                                                            >
                                                                <LogContainer key={log.id_log_history} className="log-container">
                                                                    <LogHeader onClick={() => log.card !== undefined && log.card?.deleted !== "S" ? onClickCard(log.card) : {}}>
                                                                        <LogHeaderInfo>
                                                                            <LogHeaderIcon color={log.card?.flow_step?.color}>

                                                                            </LogHeaderIcon>
                                                                            <LogHeaderOrigin>
                                                                                {log.card?.flow_step?.name}
                                                                            </LogHeaderOrigin>
                                                                        </LogHeaderInfo>
                                                                        <LogHeaderTitle>
                                                                            {log.card?.title}
                                                                            {log.card?.deleted === "S" && (
                                                                                <LogHeaderCardDeleted>Excluído</LogHeaderCardDeleted>
                                                                            )}
                                                                        </LogHeaderTitle>
                                                                    </LogHeader>
                                                                    <LogBody>

                                                                        <LogHistoryItem>
                                                                            <LogHistoryAvatar>
                                                                                <AvatarCange user={log.user} size={"20"} />
                                                                            </LogHistoryAvatar>
                                                                            <LogHistoryUserName>
                                                                                {log.user.name} {log.user_id === user.id_user ? '(você)' : ''}:
                                                                            </LogHistoryUserName>
                                                                            <LogHistoryDescription>
                                                                                {log.description} <b>·</b> <LogHistoryDate>{formatDateToDateRead(new Date(log.dt_action), true)}</LogHistoryDate>
                                                                            </LogHistoryDescription>
                                                                        </LogHistoryItem>

                                                                        {log.log_children !== undefined && log.log_children.length > 0 ?
                                                                            !isExpanded ?
                                                                                <LogHistoryChildren onClick={() => toggleGroup(String(log.id_log_history))}>
                                                                                    Mostrar mais {log.log_children?.length} {log.log_children?.length === 1 ? 'atualização' : 'atualizações'}
                                                                                </LogHistoryChildren> :
                                                                                <LogHistoryExpanded>
                                                                                    {log.log_children.map((logChild, index) => {

                                                                                        //Check if the last log is the same user
                                                                                        let showAvatarUser = true;

                                                                                        if (index === 0) {
                                                                                            if (logChild.user_id === log.user_id) {
                                                                                                showAvatarUser = false;
                                                                                            }
                                                                                        } else {
                                                                                            if (log.log_children !== undefined) {
                                                                                                if (logChild.user_id === log.log_children[index - 1].user_id) {
                                                                                                    showAvatarUser = false;
                                                                                                }
                                                                                            }
                                                                                        }

                                                                                        return (
                                                                                            <>
                                                                                                {showAvatarUser ? <div style={{ marginTop: '5px' }}></div> : <></>}
                                                                                                <LogHistoryItem key={logChild.id_log_history}>
                                                                                                    {showAvatarUser ?
                                                                                                        <LogHistoryAvatar>
                                                                                                            <AvatarCange user={logChild.user} size={"20"} />
                                                                                                        </LogHistoryAvatar> :
                                                                                                        <></>
                                                                                                    }
                                                                                                    <LogHistoryUserName>
                                                                                                        {logChild.user.name} {logChild.user_id === user.id_user ? '(você)' : ''}:
                                                                                                    </LogHistoryUserName>
                                                                                                    <LogHistoryDescription>
                                                                                                        {logChild.description} <b>·</b> <LogHistoryDate>{formatDateToDateRead(new Date(logChild.dt_action), true)}</LogHistoryDate>
                                                                                                    </LogHistoryDescription>
                                                                                                </LogHistoryItem>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </LogHistoryExpanded> :
                                                                            <></>
                                                                        }
                                                                    </LogBody>
                                                                </LogContainer>
                                                            </LogArea>
                                                        )
                                                    })}
                                                </LogItems>
                                            </LogGroup>
                                        )
                                    })}
                                </>}
                        </ContainerLeft>
                        <ContainerRight
                            item
                            xs={1}
                            sm={1}
                            md={4}
                            lg={4}
                            style={{ marginBottom: '10px', maxHeight: '85vh', overflowY: 'auto' }}
                        >
                            <ChartContainer>

                                <Dialog
                                    title={dialogProps.title}
                                    value={dialogProps.value}
                                    status={open}
                                    handleDialog={() => setOpen(!open)}
                                />

                                <ChartItem>
                                    <ChartItemTitle>
                                        Ranking de atualizações

                                        <HelpText type="button" onClick={() => handleDialog(
                                            'Ranking de Atualizações',
                                            'Este espaço celebra a colaboração, atribuindo 1 ponto a cada log e atualização feitos por você. Descubra quem está na liderança, incentive a participação e faça parte dessa competição amigável. Colabore, acumule pontos e destaque-se no fluxo.'
                                        )}>
                                            <FiHelpCircle />
                                        </HelpText>
                                    </ChartItemTitle>
                                    <ChartItemBody>
                                        {!loading && activities.length === 0 ?
                                            <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                Quando houver dados, aqui você verá o ranking da sua equipe, mostrando quem está a frente com o número de atualizações
                                            </div> :
                                            <RankingChart flow_id={flow_id} days={days} />
                                        }
                                    </ChartItemBody>
                                </ChartItem>
                                <ChartItem>
                                    <ChartItemTitle>
                                        Total de atualizações (por Dia)

                                        <HelpText type="button" onClick={() => handleDialog(
                                            'Total de atualizações (por Dia)',
                                            'Este gráfico exibe o número diário de atualizações em seu fluxo, oferecendo insights visuais sobre a dinâmica das operações. Acompanhe os picos de atividade e identifique padrões. Uma ferramenta valiosa para entender o fluxo do seu processo. Explore e otimize sua eficiência!'
                                        )}>
                                            <FiHelpCircle />
                                        </HelpText>
                                    </ChartItemTitle>
                                    <ChartItemBody>
                                        {!loading && activities.length === 0 ?
                                            <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                                Quando houver dados, aqui você verá o total de atualizações por dia no seu fluxo
                                            </div> :
                                            <LineChart flow_id={flow_id} days={days} />
                                        }
                                    </ChartItemBody>
                                </ChartItem>

                            </ChartContainer>
                        </ContainerRight>
                    </>
                }
            </Container >
        </>
    );

}

export default ActivityTab;