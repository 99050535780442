import { FormAnswerField } from "../interfaces/FormAnswerField";

interface objectInit {
    [x: string]: string | object
}

export default function formAnswerToObjectFormInit(formAnswerFields: FormAnswerField[], autoCompleteFormAnswers?: FormAnswerField[]): objectInit {

    let newObjData: objectInit = {};
    let insertedFirst: boolean = false;

    let fieldKeysInserted: string[] = [];

    //Nomalize the data from form_answer_fields to object[]
    for (let index = 0; index < formAnswerFields.length; index++) {

        //Valid if already inserted
        if (fieldKeysInserted.includes(formAnswerFields[index].form_answer_id + "-" + formAnswerFields[index].field_id + "-" + formAnswerFields[index].index)) {
            continue;
        }

        fieldKeysInserted.push(formAnswerFields[index].form_answer_id + "-" + formAnswerFields[index].field_id + "-" + formAnswerFields[index].index);

        let element = formAnswerFields[index];

        //Valid if is inserted;
        if (newObjData[element.field.name] !== undefined) { //Already was inserted in the object

            const arrNew = [];

            //Put the existing data
            if (typeof newObjData[element.field.name] === "object") {
                arrNew.push(...newObjData[element.field.name] as String[]);
            } else if (typeof newObjData[element.field.name] === "string") {
                arrNew.push(newObjData[element.field.name]);
            }

            arrNew.push(element.value);

            newObjData[element.field.name] = arrNew;

        } else { //New field
            if (index === 0) {
                insertedFirst = true;
                newObjData = { [element.field.name]: element.value };
            } else if (newObjData !== undefined) {
                newObjData[element.field.name] = element.value;
            }
        }

    }

    if (autoCompleteFormAnswers !== undefined) {

        //Just get the diference, remove the answers already inserted    
        let newAutoCompleteFormAnswers = autoCompleteFormAnswers?.filter(ac => !formAnswerFields.some(fa => fa.field_id === ac.field_id));

        for (let index = 0; index < newAutoCompleteFormAnswers.length; index++) {

            let element = newAutoCompleteFormAnswers[index];

            //Valid if is inserted;
            if (newObjData[element.field.name] !== undefined) { //Already was inserted in the object

                const arrNew = [];

                //Put the existing data
                if (typeof newObjData[element.field.name] === "object") {
                    arrNew.push(...newObjData[element.field.name] as String[]);
                } else if (typeof newObjData[element.field.name] === "string") {
                    arrNew.push(newObjData[element.field.name]);
                }

                arrNew.push(element.value);

                newObjData[element.field.name] = arrNew;

            } else { //New field
                if (index === 0 && !insertedFirst) {
                    newObjData = { [element.field.name]: element.value };
                } else if (newObjData !== undefined) {
                    newObjData[element.field.name] = element.value;
                }
            }

        }
    }

    return newObjData;

}