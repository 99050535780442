import {
    AddButton,
    Container,
    LogoContainer,
    NameContainer,
    TypeContainer
} from "./style";

import { ItemFormula } from "..";
import React from "react";
import { getFieldObject } from "../../../FieldBuilder";

interface ItemFormulaBoxProps {
    item: ItemFormula
    onClick(nameLabel: string): void;
}

const ItemFormulaBox: React.FC<ItemFormulaBoxProps> = ({ item, onClick }) => {

    const fieldObject = getFieldObject(item.typeField);

    return (
        <Container key={item.hash} onClick={() => onClick(item.nameLabel)}>

            <LogoContainer color={fieldObject.color} backgroundColor={fieldObject.colorBackground}>
                <fieldObject.icon />
            </LogoContainer>

            <NameContainer>
                {item.nameLabel}
            </NameContainer>

            <TypeContainer>                
                {item.type === 0 ? "Coluna" : item.type === 1 ? "Fórmula" : "Não definido"}
            </TypeContainer>

            <AddButton type="button" className="add-btn-item-formula" onClick={() => onClick(item.nameLabel)}>
                Adicionar
            </AddButton>

        </Container>
    );

}

export default ItemFormulaBox;