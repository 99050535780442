import React, { useCallback, useEffect, useRef, useState } from "react";
import api from "../../../../../services/api";
import AvatarCange from "../../../../../components/AvatarCange";
import { Animation, ContainerLoader, ContainerRank, RankAvatar, RankItem, RankName, RankPoints, RankPosition, RankingChildren } from "./style";
import { User } from "../../../../../interfaces/User";
import lottie from "lottie-web";

interface RankingChartProps {
    flow_id: number;
    days: number;
}

interface RankingDataProps {
    user_id_user: number;
    user_name: string;
    user_color: string;
    user_company_id: number;
    user_email: string;
    user_avatar_id: number;
    user_avatar_url: string;
    total_activities: number;
}

const RankingChart: React.FC<RankingChartProps> = ({ flow_id, days }) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<RankingDataProps[]>();
    const [usersToShow] = useState<number>(2);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const lottieContainer = useRef<HTMLDivElement>(null);

    const getApiData = useCallback(async (id_flow: number) => {

        setLoading(true);

        api.get(`/activity/ranking/by-flow`, {
            params: {
                flow_id: id_flow,
                days: days
            }
        }).then(response => {
            if (response.data !== null) {

                const newData = response.data as RankingDataProps[];

                setData(newData);

            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            console.log(error);
        });

    }, [days]);

    useEffect(() => {

        getApiData(flow_id);

    }, [flow_id, getApiData]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../../../assets/lottie/loader.json')
            })
        }
    }, []);

    return (
        <>
            {loading ?
                <ContainerLoader>
                    <Animation className="lottieContainer" ref={lottieContainer} />
                </ContainerLoader > :
                <ContainerRank>
                    {data?.slice(0, usersToShow).map((d, index) => {

                        const isFirst = index === 0;

                        return (
                            <RankItem key={d.user_id_user} isFirst={isFirst}>
                                <RankPosition>#{index + 1}</RankPosition>
                                <RankAvatar>
                                    <AvatarCange
                                        user={{
                                            id_user: d.user_id_user,
                                            name: d.user_name,
                                            color: d.user_color,
                                            company_id: d.user_company_id,
                                            email: d.user_email,
                                            avatar_id: d.user_avatar_id,
                                            avatar_url: d.user_avatar_url

                                        } as User}
                                        size={"35"}
                                    />
                                </RankAvatar>
                                <RankName>{d.user_name}</RankName>
                                <RankPoints>{d.total_activities}</RankPoints>
                            </RankItem>
                        )
                    })}
                    {data !== undefined && data.length > usersToShow && !collapsed ?
                        <RankingChildren onClick={() => setCollapsed(true)}>
                            Mostrar mais {data?.length - usersToShow} {data.length - usersToShow > 1 ? "usuários" : "usuário"}
                        </RankingChildren> : data !== undefined && data.length > usersToShow && collapsed ?
                            data?.slice(usersToShow, data.length).map((d, index) => {
                                return (
                                    <RankItem key={d.user_id_user} isFirst={false}>
                                        <RankPosition>#{index + usersToShow + 1}</RankPosition>
                                        <RankAvatar>
                                            <AvatarCange
                                                user={{
                                                    id_user: d.user_id_user,
                                                    name: d.user_name,
                                                    color: d.user_color,
                                                    company_id: d.user_company_id,
                                                    email: d.user_email,
                                                    avatar_id: d.user_avatar_id,
                                                    avatar_url: d.user_avatar_url

                                                } as User}
                                                size={"35"}
                                            />
                                        </RankAvatar>
                                        <RankName>{d.user_name}</RankName>
                                        <RankPoints>{d.total_activities}</RankPoints>
                                    </RankItem>
                                )
                            }) :
                            <></>
                    }
                </ContainerRank>
            }
        </>
    );

}

export default RankingChart;