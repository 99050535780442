import styled, { css } from "styled-components";

import { Box } from "@material-ui/core";
import { TextField } from "unform-material-ui";

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
  isDisabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  background-color: transparent;
  border: 0px;
  padding: 16px;
  padding-top: 11px;
  width: 100%;
  border-bottom: 1px solid gray;
  color: black;
  display: flex;
  align-items: center;

  & + div {
    margin-top: 30px;
  }
  svg {
    margin-right: 16px;
    margin-bottom: -5px;
    color: gray;
  }

  ${(props) =>
    props.isErrored &&
    css`
      color: red;
      border-bottom-color: red;
      /*
      svg {
        color: red;
      }
      */
    `}
  ${(props) =>
    props.isFocused &&
    css`
      color: #f23b5c;
      border-bottom-color: #f23b5c;
      svg {
        color: #f23b5c;
      }
    `}
  ${(props) =>
    props.isDisabled &&
    css`
      background-color: rgba(188, 188, 188, 0.1);
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    `}  
`;

export const TextInput = styled(TextField)`
  height: 100%;
  width: 100%;
  margin-left: 5px;

  p {
    display: none;
  }

  div {
    padding-bottom: 0px;

    &::before {
      flex: 1;
      background: transparent;
      border-bottom: none !important;
    }

    &::after {
      flex: 1;
      background: transparent;
      border-bottom: none !important;
    }

    textarea {
      flex: 1;
      background: transparent;
      border: 0;

      &::placeholder {
        opacity: 1;
        color: gray;
      }
    }
  }
`;

export const BoxContainer = styled(Box)`
    position: relative;
    //position: absolute;
    z-index: 1;
    top: auto;
    //right: auto;
    left: auto;
    min-width: 250px;
    //max-width: 350px;
    width: auto;
    border-radius: 5px;
    padding: 5px;
    margin-top: 15px;
    padding-bottom: 10px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 0px !important;

    @media(max-width: 800px){
        margin-right: 0px;
    }
`;

export const BoxItemsContainer = styled.div`
    max-height: 180px;
    overflow-y: auto;
`;

export const ShortCutBarContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
`;

export const ShortCutItem = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fde8ec;
  color: #f23b5c;
  padding: 3px 7px;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.7px;
  text-align: center;
  min-width: 30px;
  min-height: 20px;
  border: none;
`;

export const ShortCutDesc = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  padding: 3px 7px;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 1px;
  text-align: center;
  min-width: 30px;
  min-height: 20px;
  font-style: italic;
  font-weight: 100;
`;

export const StatusIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: 16px;
    margin-right: 0px;
  }
`;