import React from 'react';
import { FiEye } from "react-icons/fi";

import { Content, DisclaimerPreview } from "./style";

interface ContentPreviewProps {
    children?: React.ReactNode;
}

const ContentPreview: React.FC<ContentPreviewProps> = ({ children }) => {

    return (
        <Content>
            <DisclaimerPreview>
                <FiEye />
                <h1>Este formulário é uma pré-visualização</h1>
            </DisclaimerPreview>
            { children }
        </Content>
    );
};

export default ContentPreview;