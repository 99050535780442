import { Grid } from "@material-ui/core";
import styled, { css, keyframes } from "styled-components";
import Button from "../../components/Button";

interface FlowItemProps {
    color?: string;
}

interface FlowStarProps {
    isStarred?: string;
}

interface KpiContainerProps {
    color?: string;
}

interface KpiIconContainerProps {
    color?: string;
    activeMotion?: boolean;
}

const pulse = keyframes`
    0% {
      transform: scale(1); 
    }
    50% {
      transform: scale(1.1); 
    }
    100% {
      transform: scale(1); 
    }
`;

export const Container = styled.div`

`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: left;
    
    width: 100%;
    height: 100%;    

    @media(max-width: 960px){
        .container-right {
            display: none !important;
        }
    }
`;

export const ContainerLeft = styled(Grid)`

`;

export const ContainerRight = styled(Grid)`

`;

export const ContainerBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 24px;
    width: 100%;
`;

export const ContainerContent = styled.div`
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 20px; 
    margin-bottom: 15px;
    position: relative;
`;

export const FlowContainer = styled.div`
    //display: flex;
    align-items: center;
    justify-items: center;

    height: auto;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    margin-top: 15px;
    padding-left: 5px;

    overflow-x: auto;

    display: grid;
    --min-col-width: var(--grid-min-column-width,160px);
    --max-col-width: var(--grid-max-column-width,1fr);
    grid-template-columns: repeat(auto-fill,minmax(var(--min-col-width),var(--max-col-width)));
    grid-gap: var(--gap);
    
`;

export const UserContainer = styled.div`
    //display: flex;
    align-items: center;

    height: auto;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    margin-top: 15px;

    overflow-x: auto;

    display: grid;
    --min-col-width: var(--grid-min-column-width,60px);
    --max-col-width: var(--grid-max-column-width,1fr);
    grid-template-columns: repeat(auto-fill,minmax(var(--min-col-width),var(--max-col-width)));
    grid-gap: var(--gap);
    
`;

export const ButtonUserAdd = styled(Button)`
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    height: 35px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #4681b8;
    background-color: white;
    border: 1px dashed #4681b8;
    overflow: hidden;
    width: 120px !important;

    div {
        margin-top: 2px !important;

        svg {
            width: 18px;
            height: 18px;
            color: #4681b8;
        }
    }

    :hover {

        border: 1px dashed #f23b5c;
        background-color: white;
        color: #f23b5c;

        svg {
            color: #f23b5c;
        }

    }

    @media(max-width: 800px){
        display: none;
    }

`;

export const ButtonConfig = styled(Button)`
    width: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        margin-right: 0px !important; 
        margin-top: 3px;
    }

    @media(max-width: 800px){
        display: none;
    }
`;

export const AvatarContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`;

export const AddNewUserAvatar = styled.div`
    height: 45px;
    width: 45px;
    border: 1px dashed #4681b8;
    border-radius: 20px;
    line-height: initial;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4681b8;
    font-size: 15px;
    margin: 5px;

    :hover {
        color: #f23c5d;
        border-color: #f23c5d;

        svg {
            color: #f23c5d;
        }
    }
`;

export const FlowNewItem = styled.button`
    display: inline-flex;
    align-items: left;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-column: span 1 / auto;

    margin: 15px;
    margin-left: 0px;
    height: 190px;
    width: 150px;
    min-width: 145px;
    background-color: white;
    border: 1px dashed #f23b5c;
    border-radius: 10px;
    padding: 15px;

    &:hover {
        background: ${'#f23b5c1a'};
        transform: translateY(-10px);
        
        h2 {
            color: ${"black"};
        }
    } 

    h2 {
        font-size: 14px;
        color: #4d4d4d;
    }
`;

export const FlowNewItemContainer = styled.div`
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;

    width: 70px;
    height: 60px;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 20%;

    background-color: #f23b5c;
    border-radius: 10px;

    svg {
        color: white;
        height: 40px;
        width: 40px;
        padding: 5px;
        margin-top: 5px;
    }

`;

export const FlowNewContainerBottom = styled.div`
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    white-space: normal;
    overflow-wrap: anywhere;

    text-align: center;

`;

export const DescriptionEmpty = styled.div`
    border: 1px dashed #80808059;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    padding: 10px;
    text-align: center;

    svg {
        margin-right: 5px;
        font-size: 20px;
    }

    :hover {
        border: 1px dashed #f23b5c;
        color: #f23b5c;
    }
`;

export const EditDescriptionContainer = styled.div`
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 13px;
    color: #f23b5c;
    cursor: pointer;
    transition: transform 0.3s ease; 

    :hover {
        transform: scale(1.1);
        font-weight: 500;
    }
`;

export const ContainerDescriptionHtml = styled.div`

    color: #1e1f21;

    ol { 
        margin-left: 25px;
    }

    ul {
        margin-left: 25px;
    }
`;

export const FlowItem = styled.div<FlowItemProps>`
    position: relative;
    display: inline-flex;
    align-items: left;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    grid-column: span 1 / auto;
    cursor: pointer;

    margin: 15px;
    margin-left: 0px;
    height: 190px;
    width: 150px;
    min-width: 145px;
    background-color: #f23b5c1a;
    border-radius: 10px;
    border: 1px dashed transparent;
    padding: 15px;

    &:hover {
        background: ${'#f23b5c'};
        
        h2 {
            color: ${"white"};
        }

        span {
            color: ${"white"};
        }

        svg {
            color: white;
        }

        .flow-star {
            display: flex;
        }

        .flow-config {
            display: flex;
        }
    } 

    h2 {
        font-size: 14px;
        color: black;
    }

    ${props => props.color && css`
        background-color: ${props.color+"1a"};
        
        &:hover {
            background: ${props.color};
        }
    `}
`;


export const FlowHeader = styled.div`
    display: flex;
    width: 100%;
    min-height: 15px;
`;

export const FlowHeaderLeft = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

`;

export const FlowHeaderCenter = styled.div`
    
    display: none;
    justify-content: flex-start;
    align-items: center;

    svg {
        font-size: 17px;
    }

`;

export const FlowHeaderRigth = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const FlowStar = styled.button<FlowStarProps>`
    display: none;
    border: none;
    background-color: transparent;
    padding: 5px;

    svg {
        color: white;
        font-size: 17px;
    }

    ${props => props.isStarred === "S" && css`
        display: flex;

        svg {
            color: #fe8c2f !important;   
        }
    `}

    :hover {
        svg {
            color: #fe8c2f !important;   
        }
    }
`;

export const FlowConfig = styled.button`
    display: none;
    border: none;
    background-color: transparent;
    padding: 5px;

    svg {
        color: white;
        font-size: 17px;
    }

    :hover {
        svg {
            color: gray;   
        }
    }
`;

export const FlowItemContainer = styled.div<FlowItemProps>`
    display: inline-flex;
    -webkit-box-align: center;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;

    width: 70px;
    height: 70px;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 10%;

    border-radius: 10px;

    svg {
        color: #f23b5c;
        height: 40px;
        width: 40px;
    }

    ${props => props.color && css`
        svg {
            color: ${props.color};
            
        }
    `}

`;

export const FlowContainerBottom = styled.div`
    height: 100%;
    width: 100%;

    display: contents;
    justify-content: center;
    align-items: center;

    white-space: normal;
    overflow-wrap: anywhere;

    text-align: center;

    span {
        margin-top: 10px;
        color: grey;
        font-size: 12px;
    }

`;

export const FlowRemoveContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 50px;
    padding: 5px;
    right: -24px;
    top: -19px;
    position: absolute;
    margin-right: 5px;
`;

export const FlowRemoveBox = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 50px;
    padding: 5px;

    svg {
        height: 15px;
        width: 15px;
        color: white;
    }

    background-color: #bcbcbc80;

    :hover {
        background-color: gray;
        
        svg {
            color: white;
        }
    }
`;

export const KpiItemsContainer = styled.div`
    overflow-x: auto;
    display: flex;
    align-items: center;
    height: auto;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    margin-top: 15px;
    padding-left: 5px;
`;

export const KpiContainer = styled.div<KpiContainerProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    background-color: #0000ff1e;
    border-radius: 5px;
    min-width: 200px;
    margin-right: 10px;
    margin-bottom: 15px;

    ${props => props.color && css`
        background-color: ${props.color};
    `}
`;

export const KpiIconContainer = styled.div<KpiIconContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    margin-right: 10px;
    background-color: blue;
    border-radius: 5px;
    color: white;
    font-size: 18px;

    ${props => props.color && css`
        background-color: ${props.color};
    `}

    ${props => props.activeMotion && css`
        svg {
            animation: ${pulse} 1s infinite;
        }
    `}
`;

export const KpiInformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const KpiValue = styled.div`
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 2px;
    color: #4d4d4d;
`;

export const KpiDescription = styled.div`
    font-size: 11px;
    color: gray;
`;
