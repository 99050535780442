import {
    Address,
    AddressValue,
    Container,
    ContainerAdrress,
    ContainerEmails,
    CopyBtn,
    DividerGroup,
    EmailBody,
    EmailTitle,
    EmailTitleContainer,
    EmailTotal,
    ContainerButton,
    EmailButton,
    TagOrigin,
    TitleAddress,
    CleanBox,
    CleanBoxText
} from "./style";
import React, { useCallback, useEffect, useState } from "react";
import { TbBrandMailgun, TbCheck, TbCopy } from "react-icons/tb";

import { Email } from "../../../interfaces/Email";
import EmailDialog from "../../EmailDialog";
import { EmailPostmark } from "../../../interfaces/EmailPostmark";
import { FaPaperPlane } from "react-icons/fa";
import { Flow } from "../../../interfaces/Flow";
import api from "../../../services/api";
import { BsMailbox2 } from "react-icons/bs";
import { INBOUND_DOMAIN } from "../../../config/app";
import EmailList from "../../../components/EmailBox/EmailList";
import getAccessControl from "../../../middlewares/AccessControl";

interface MailTabProps {
    typeUser: string;
    flow?: Flow;
    card_id?: number
}

const MailTab: React.FC<MailTabProps> = ({ card_id, flow, typeUser }) => {

    const [copied, setCopied] = useState<boolean>(false);
    const [emails, setEmails] = useState<Email[]>();
    const [flowAddress, setFlowAddress] = useState<string>();
    const [emailSelected, setEmailSelected] = useState<Email>();

    const [openMail, setOpenMail] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(flow?.hash + "+" + card_id + "@" + INBOUND_DOMAIN);

        setCopied(true);
    };

    const getApiEmails = useCallback(async () => {

        if (flow !== undefined && card_id !== undefined && flow.id_flow !== undefined) {

            await api.get(`/email/by-card`, {
                params: {
                    card_id: card_id,
                    flow_id: flow.id_flow,
                }
            }).then(response => {
                if (response.data !== null) {

                    let apiRetEmail: Email[] = response.data;

                    apiRetEmail = apiRetEmail.map((e) => {

                        if (e.json_recived !== undefined) {
                            const emailParsed: EmailPostmark = JSON.parse(e.json_recived);
                            e.emailPostmark = emailParsed;
                        }

                        return e;
                    })

                    setEmails(apiRetEmail);

                }
            }).catch(error => {
            });

        }

    }, [card_id, flow]);

    const openEmailDialog = useCallback(async (email: Email) => {
        if (email.isRead !== "S" && flow !== undefined) {
            await api.post(`/email/preferences`, {
                id_email: email.id_email,
                flow_id: flow?.id_flow,
                isRead: "S"
            })
        }

        setOpenMail(true);
        setEmailSelected(email);
    }, [flow]);

    const closeEmailDialog = useCallback(async () => {

        setOpenMail(false);
        getApiEmails();

    }, [getApiEmails]);

    const markReadStatus = useCallback(async (email: Email, status: string) => {

        if (flow !== undefined) {
            await api.post(`/email/preferences`, {
                id_email: email.id_email,
                flow_id: flow?.id_flow,
                isRead: status
            })

            getApiEmails();
        }

    }, [flow, getApiEmails]);

    const markStarredtatus = useCallback(async (email: Email, status: string) => {

        if (flow !== undefined) {
            await api.post(`/email/preferences`, {
                id_email: email.id_email,
                flow_id: flow?.id_flow,
                isStarred: status
            })

            getApiEmails();
        }

    }, [flow, getApiEmails]);

    const moveToTrash = useCallback(async (email: Email, status: string) => {

        if (flow !== undefined) {
            await api.post(`/email/preferences`, {
                id_email: email.id_email,
                flow_id: flow?.id_flow,
                isDeleted: status
            })

            getApiEmails();
        }

    }, [flow, getApiEmails]);

    useEffect(() => {

        getApiEmails();

    }, [getApiEmails]);

    useEffect(() => {

        if (flow !== undefined) {
            setFlowAddress(flow?.hash + "+" + card_id + "@" + INBOUND_DOMAIN);
        }

    }, [flow, card_id]);

    return (
        <Container>

            <ContainerAdrress>
                <TitleAddress>
                    <TbBrandMailgun />
                    <h3>Endereço de e-mail do cartão</h3>
                </TitleAddress>
                <AddressValue>
                    <Address>{flow?.hash + "+" + card_id + "@" + INBOUND_DOMAIN}</Address>
                    <CopyBtn onClick={handleCopyClick}>
                        {copied ?
                            <>
                                <TbCheck />
                                Copiado
                            </> :
                            <>
                                <TbCopy />
                                Copiar
                            </>
                        }
                    </CopyBtn>
                </AddressValue>
            </ContainerAdrress>

            {getAccessControl(58, typeUser) ?
            <ContainerButton>
                <EmailButton onClick={() => {
                    setOpenMail(true);
                    setEmailSelected(undefined);
                }}>
                    <FaPaperPlane />
                    Nova mensagem
                </EmailButton>
            </ContainerButton>
            : <></>}

            <ContainerEmails>
                <EmailTitleContainer>
                    <EmailTitle>
                        Caixa de entrada
                        <TagOrigin>Cartão</TagOrigin>
                    </EmailTitle>
                    <EmailTotal>{emails !== undefined ? (
                        <>{emails.filter((e) => e.isRead !== "S").length + "/" + emails?.length}</>
                    ) : 0}</EmailTotal>
                </EmailTitleContainer>
                <DividerGroup />
                <EmailBody>

                    <EmailList
                        emails={emails}
                        typeUser={typeUser}
                        markReadStatus={markReadStatus}
                        markStarredtatus={markStarredtatus}
                        moveToTrash={moveToTrash}
                        openEmailDialog={openEmailDialog}
                    />                    

                    {emails?.length === 0 && (
                        <CleanBox>
                            <BsMailbox2 />
                            <CleanBoxText>
                                Você ainda não possui mensagens na sua caixa de entrada, envie agora mesmo para o endereço de e-mail do cartão
                            </CleanBoxText>
                        </CleanBox>
                    )}

                    <EmailDialog
                        open={openMail}
                        typeUser={typeUser}
                        id_card={card_id}
                        flow={flow}
                        flowAddress={flowAddress}
                        onClose={() => closeEmailDialog()}
                        email={emailSelected}
                    />

                </EmailBody>
            </ContainerEmails>

        </Container>
    );

}

export default MailTab;