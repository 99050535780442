import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import React, { useCallback } from "react";
import { MdAttachFile } from "react-icons/md";

import {
    Container,
    DropContainer,
    UploadMessage
} from "./style";
import { MAX_SIZE_ATTACH, allowedMimes } from "../../utils/getIconAttachment";
import { useToast } from '../../hooks/toast';

interface UploadButtonProps {
    placeholder?: string;
    onUploadFile: (files: File[]) => void;
}

const UploadButton: React.FC<UploadButtonProps> = ({ onUploadFile, placeholder }) => {

    const { addToast } = useToast();

    const onUploadReject = useCallback((fileRejections: FileRejection[], event: DropEvent) => {

        if (fileRejections !== undefined && fileRejections.length > 0) {
            for (let index = 0; index < fileRejections.length; index++) {
                const rejection = fileRejections[index];

                for (let idxB = 0; idxB < rejection.errors.length; idxB++) {
                    const error = rejection.errors[idxB];

                    addToast({
                        type: 'error',
                        title: 'Erro ao carregar o arquivo',
                        description: error.message + " (" + error.code + ")",
                    });

                }
            }
        }
    }, [addToast]);

    return (
        <Container>
            <Dropzone accept={allowedMimes} onDropAccepted={onUploadFile} maxSize={MAX_SIZE_ATTACH} onDropRejected={onUploadReject}>
                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                    <DropContainer
                        {...getRootProps()}
                        isDragActive={isDragActive}
                        isDragReject={isDragReject}
                    >
                        <input {...getInputProps()} />
                        {
                            !isDragActive ?
                                <UploadMessage type="default">
                                    <MdAttachFile />
                                    {!!placeholder ?
                                        placeholder :
                                        "Clique aqui ou arraste arquivos para anexar"
                                    }
                                </UploadMessage> :
                                isDragReject ?
                                    <UploadMessage type="error">
                                        Arquivo não suportado :(
                                    </UploadMessage> :
                                    <UploadMessage type="sucess">
                                        Solte os arquivos aqui :)
                                    </UploadMessage>
                        }
                    </DropContainer>
                )}
            </Dropzone>
        </Container>
    );

}

export default UploadButton;