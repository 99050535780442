import {
    ButtonNewFormField,
    Container,
    ContainerButtonNewFormField,
    ContainerFieldBox,
    FieldBox
} from "./style";
import {
    FieldProps,
    getItemComponentColor,
    getItemComponentColorBackground,
    getItemComponentDescription,
    getItemComponentIcon
} from "../Forms/Fields/FieldBuilder";
import React from "react";

import NewField from "../../dialogs/NewField";
import { Flow } from "../../interfaces/Flow";
import { Register } from "../../interfaces/Register";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

interface defaultProps {
    fields: FieldProps[];
    open: boolean;
    selectedValue: FieldProps;
    stateDialog: string;
    origin: 'flow' | 'register';
    flow?: Flow;
    register?: Register;
    isFormInit?: boolean;
    handleMoveField: (type: string, index: number) => Promise<void>;
    handleMoveFieldDragAndDrop?: (nameField: string, newIndex: number) => void;
    handleEditField: (index: number) => Promise<void>;
    handleCopyField?: (index: number) => Promise<void>;
    dialogNewFieldSubmit: (data: FieldProps) => Promise<void>;
    dialogNewFieldOpen: () => void;
    dialogNewFieldClose: (value: FieldProps) => void;
    dialogNewFieldDelete?: (name: string, id_field?: number) => Promise<void>;
}
//
const Fields: React.FC<defaultProps> = ({ fields, open, selectedValue, stateDialog, handleMoveField, handleCopyField, handleMoveFieldDragAndDrop, handleEditField, dialogNewFieldSubmit, dialogNewFieldOpen, dialogNewFieldClose, dialogNewFieldDelete, origin, flow, register, isFormInit }) => {

    function sortfields(a: FieldProps, b: FieldProps) {
        if (a.index !== undefined && b.index !== undefined) {
            if (a.index < b.index) {
                return -1;
            }
            if (a.index > b.index) {
                return 1;
            }
        }
        return 0;

    }

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        if (handleMoveFieldDragAndDrop) {
            handleMoveFieldDragAndDrop(result.draggableId, result.destination.index);
        }
    };

    return (
        <Container>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="fieldDropList" isDropDisabled={handleMoveFieldDragAndDrop === undefined}>
                    {(provided) => (
                        <Container {...provided.droppableProps} ref={provided.innerRef}>
                            <div>
                                {fields.sort(sortfields).map((field, index) => {
                                    return (
                                        <Draggable draggableId={field.name} index={index} key={field.name}>
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <ContainerFieldBox key={field.name}>
                                                        <div></div>
                                                        <FieldBox
                                                            title={field.title}
                                                            field_type={getItemComponentDescription(field.type)}
                                                            index={index}
                                                            icon={getItemComponentIcon(field.type)}
                                                            color={getItemComponentColor(field.type)}
                                                            color_background={getItemComponentColorBackground(field.type)}
                                                            moveField={handleMoveField}
                                                            editField={handleEditField}
                                                            copyField={handleCopyField}
                                                        />
                                                    </ContainerFieldBox>
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                            </div>
                            {provided.placeholder}
                        </Container>
                    )}
                </Droppable>
            </DragDropContext>

            <ContainerButtonNewFormField>
                <ButtonNewFormField color="#2390eb" height="36px" margin="0px 30px 0px 0px" onClick={dialogNewFieldOpen}>
                    Criar novo campo
                </ButtonNewFormField>
                {open && (
                    <NewField
                        index={fields.length}
                        origin={origin}
                        selectedValue={selectedValue}
                        open={open}
                        onSubmmit={dialogNewFieldSubmit}
                        onClose={dialogNewFieldClose}
                        onDelete={dialogNewFieldDelete}
                        type={stateDialog}
                        formFields={fields}
                        flow={flow}
                        register={register}
                        isFormInit={isFormInit}
                    />
                )}
            </ContainerButtonNewFormField>
        </Container>
    );

};

export default Fields;