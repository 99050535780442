import React, { useCallback, useEffect } from "react";

import {
    AddNewConditionalButton,
    ConditionalsItem,
    ConditionalsItemIconName,
    ConditionalsItemIconTrigger,
    ConditionalsItemLeft,
    ConditionalsItemRight,
    Container,
    ContainerConditionals,
    ContainerConditionalsHeader,
    ContainerConditionalsTip,
    DropDownMenuContainer
} from "./style";
import { Conditional } from "../../../interfaces/Conditional";
import { RiFlowChart, RiLightbulbFlashFill } from "react-icons/ri";
import { HelpContainer, HelpIcon, HelpItem, HelpItems, HelpText } from "../style";
import { BsThreeDots } from "react-icons/bs";
import DropDownList from "../../../components/DropDownList";
import DropDownItem from "../../../components/DropDownList/DropDownItem";
import { FaPen } from "react-icons/fa";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import api from "../../../services/api";
import { Step } from "../../../interfaces/Step";

interface ConditionalStepProps {
    flow_id?: number;
    flowStep: Step;
    flowStepParent: Step;
    conditionals: Conditional[];
    handleAddNewConditional: () => void;
    handleOpenConditional: (conditional: Conditional) => void;
}

const ConditionalStep: React.FC<ConditionalStepProps> = ({ conditionals, flow_id, flowStep, flowStepParent, handleAddNewConditional, handleOpenConditional }) => {

    const [conditionalsList, setConditionalsList] = React.useState<Conditional[]>(conditionals);

    const getListStyle = (isDraggingOver: boolean) => ({
        opacity: isDraggingOver ? 0.8 : 1,
        marginRight: '25px',
        width: '100%'
    });

    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        userSelect: "none",
        opacity: isDragging ? 0.9 : 1,
        ...draggableStyle
    });

    const handleMoveCondition = useCallback(async (id_conditional: number, prev_conditional_id?: number, next_conditional_id?: number) => {

        let newIndex = id_conditional;

        if (prev_conditional_id !== undefined && next_conditional_id !== undefined) {
            newIndex = (prev_conditional_id + next_conditional_id) / 2;
        } else if (next_conditional_id !== undefined) {
            newIndex = next_conditional_id - 0.5;
        } else if (prev_conditional_id !== undefined) {
            newIndex = prev_conditional_id + 0.5;
        }

        setConditionalsList((conditional) =>
            conditionalsList.map((conditional) => {
                if (conditional.id_conditional === id_conditional) {
                    conditional.index = newIndex;
                }
                return conditional;
            }));

        //sort by index, if index is null or undefined put in the end of the list
        let conditionalsSorted: number[] = conditionalsList !== undefined ? conditionalsList.sort((a, b) => {

            if (a.index === undefined || b.index === undefined) {
                return 0;
            }

            return a.index - b.index

        }).map((conditional) => conditional.id_conditional !== undefined ? conditional.id_conditional : 0) : [];

        //move conditional
        if (flowStep.id_step !== undefined && flowStepParent.id_step !== undefined && flow_id !== undefined && conditionalsSorted.length > 0) {
            api.post(`/conditional/step/move`, {
                flow_step_id: flowStep.id_step,
                flow_step_parent_id: flowStepParent.id_step,
                flow_id: flow_id,
                id_conditional_list: conditionalsSorted
            });
        }

    }, [conditionalsList, flowStep.id_step, flowStepParent.id_step, flow_id]);

    const reorder = (list: any[], startIndex: number, endIndex: number) => {

        const result = Array.from(list);

        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        handleMoveCondition(
            result[endIndex].id_conditional,
            result[endIndex - 1] !== undefined && result[endIndex - 1].index !== undefined ? result[endIndex - 1].index : result[endIndex - 1]?.id_conditional,
            result[endIndex + 1] !== undefined && result[endIndex + 1].index !== undefined ? result[endIndex + 1].index : result[endIndex + 1]?.id_conditional
        );

        return result;

    };

    const onDragEnd = (result: DropResult) => {

        if (!result.destination) {
            return;
        }

        const updatedItems = reorder(
            conditionalsList,
            result.source.index,
            result.destination.index
        );

        setConditionalsList(updatedItems);
    };

    useEffect(() => {

        let newConditionals: Conditional[] = conditionals;

        //sort by index, if index is null or undefined put in the end of the list
        newConditionals = newConditionals.sort((a, b) => {

            if (a.index === undefined || b.index === undefined) {
                return 0;
            }

            return a.index - b.index

        });

        setConditionalsList(newConditionals);

    }, [conditionals]);

    return (
        <Container>

            <ContainerConditionalsTip>
                <HelpContainer style={{ marginBottom: '0px' }}>
                    <HelpItems>
                        <HelpItem>
                            <HelpIcon>
                                <RiLightbulbFlashFill />
                            </HelpIcon>
                            <HelpText>
                                <b>{"Da etapa " + flowStepParent.name + " para " + flowStep.name + ": "}</b>
                                {"As condicionais a seguir definirão cenários dinâmicos para a movimentação do cartão. A ordem das regras influenciará sua execução e acionamento: a verificação começará pela primeira regra e o primeiro cenário positivo será executado"}
                            </HelpText>
                        </HelpItem>
                    </HelpItems>
                </HelpContainer>
            </ContainerConditionalsTip>

            <ContainerConditionalsHeader>
                <AddNewConditionalButton onClick={() => handleAddNewConditional()}>
                    Adicionar condicional
                </AddNewConditionalButton>
            </ContainerConditionalsHeader>

            <ContainerConditionals>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {conditionalsList !== undefined && conditionalsList.sort((a, b) => {
                                    if (a.index === undefined || b.index === undefined) {
                                        return 0;
                                    }

                                    return a.index - b.index
                                }).map((conditional, index) => (
                                    <Draggable key={conditional.id_conditional + ""} draggableId={conditional.id_conditional + ""} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <ConditionalsItem key={index} color={"#f23b5d"}>
                                                    <ConditionalsItemLeft onClick={() => handleOpenConditional(conditional)}>
                                                        <ConditionalsItemIconTrigger color={"#f23b5d"}>
                                                            <RiFlowChart />
                                                        </ConditionalsItemIconTrigger>
                                                        <ConditionalsItemIconName>
                                                            <h3>{conditional.name}</h3>
                                                            <span>Ação: {conditional.action === '1' ? 'Exibir campo' : conditional.action === '2' ? 'Esconder campo' : "Sem ação"}</span>
                                                        </ConditionalsItemIconName>
                                                    </ConditionalsItemLeft>
                                                    <ConditionalsItemRight>
                                                        <DropDownMenuContainer>
                                                            <DropDownList color={"#4d4d4d"} icon={BsThreeDots}>
                                                                <DropDownItem title="Editar" icon={FaPen} color={"#f23b5c"} onClick={() => handleOpenConditional(conditional)} />
                                                            </DropDownList>
                                                        </DropDownMenuContainer>
                                                    </ConditionalsItemRight>
                                                </ConditionalsItem>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </ContainerConditionals>

        </Container>
    );

}

export default ConditionalStep;