import styled from 'styled-components';

export const SubMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  height: 40px;
`;

export const LinkItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666666;
    font-weight: 400;
    padding: 10px;
    margin-right: 10px;
    font-size: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-bottom: 2px solid transparent;

    span {
        background-color: #2e2e2e;
        color: #ffffff;
        font-size: 11px;
        padding: 3px 5px;
        border-radius: 5px;
        margin-left: 5px;
    }

    &.active {
        color: #000000d6;
        border-bottom: 2px solid #000000d6;
        font-weight: 500;
    }

    :hover {
        background-color: #6666661a;
        color: #000000d6;
        border-radius: 5px;      
        border-bottom: 2px solid transparent;  
    }
`;