import {
    AvatarContainer,
    AvatarEdit,
    ContainerFields,
    ContentList
} from "./style";
import { FieldProps, Fields } from "../../../components/Forms/Fields/FieldBuilder";
import React, { useCallback, useEffect, useRef, useState } from "react";

import FormBuilder from "../../../components/Forms/FormBuilder";
import { FormHandles } from "@unform/core";
import { User } from "../../../interfaces/User";
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import Avatar from "react-avatar";
import { FaPlus } from "react-icons/fa";
import UploadImage from "../../../dialogs/UploadImage";

const fieldsForm: FieldProps[] = [
    {
        name: "name",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 1,
        title: "Nome Completo",
        description: "Digite o seu nome completo",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome do Cadastro é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ],
        max_length: 45
    },
    {
        name: "phone",
        type: Fields.PHONE_FIELD,
        index: 2,
        title: "Telefone",
        description: "Digite o seu telefone para contato"
    },
    {
        name: "role_name",
        type: Fields.TEXT_SHORT_FIELD,
        index: 3,
        title: "Função",
        description: "Digite a sua função na empresa",
        max_length: 45
    },
    {
        name: "department",
        type: Fields.TEXT_SHORT_FIELD,
        index: 4,
        title: "Departamento/Setor",
        description: "Digite o departamento ou setor que você trabalha",
        max_length: 45
    },
    {
        name: "localization",
        type: Fields.TEXT_SHORT_FIELD,
        index: 5,
        title: "Localização",
        description: "Digite o seu local de trabalho",
        max_length: 60
    },
    {
        name: "about_me",
        type: Fields.TEXT_SHORT_FIELD,
        index: 6,
        title: "Sobre mim",
        description: "Digite o que você gostaria de compartilhar sobre você"
    },
    {
        name: "color",
        type: Fields.COLOR_PICKER_FIELD,
        index: 7,
        title: "Cor do perfil",
        description: "Escolha uma cor para o seu perfil"
    }
]

const Profile: React.FC = () => {

    const formRef = useRef<FormHandles>(null);
    const { user, renewUser } = useAuth();
    const { addToast } = useToast();

    const [openUploadImage, setOpenUploadImage] = useState(false);

    const handleSavePage = useCallback(async () => {

        if (formRef.current !== null) {

            const dataNormalized = formRef.current.getData() as unknown as User;

            api
                .post('/user', {
                    id_user: user.id_user,
                    name: dataNormalized.name,
                    phone: dataNormalized.phone,
                    role_name: dataNormalized.role_name,
                    department: dataNormalized.department,
                    about_me: dataNormalized.about_me,
                    localization: dataNormalized.localization,
                    color: dataNormalized.color
                })
                .then(response => {

                    renewUser();

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar o perfil do usuário',
                        description: 'Ocorreu um erro ao salvar o perfil do usuário!',
                    });
                });

        }

    }, [addToast, user.id_user, renewUser]);

    const handleSubmitForm = useCallback(async (data: object[]) => {

        await handleSavePage();

    }, [handleSavePage]);

    const handleSubmitPage = useCallback(async () => {
        if (formRef.current !== undefined) {
            formRef.current?.submitForm();
        }
    }, []);

    useEffect(() => {

        if (formRef.current !== null && user !== null) {
            formRef.current.setData(user);
        }

    }, [user]);

    return (
        <ContentList>

            {/* Forms */}
            <ContainerFields item xs={12} md={6}>

                <AvatarContainer onClick={() => setOpenUploadImage(true)}>
                    <AvatarEdit className="avatar-edit" >
                        <FaPlus />
                        <span>Adicionar Foto</span>
                    </AvatarEdit>
                    <Avatar className="avatar-user" src={user?.avatar_url} name={user.name} size="100" round={true} color={user.color} />
                </AvatarContainer>

                <UploadImage open={openUploadImage} onClose={() => setOpenUploadImage(false)} />

                <FormBuilder
                    id="form"
                    formRef={formRef}
                    fields={fieldsForm}
                    handleSubmit={handleSubmitForm}
                    handleBlur={() => handleSubmitPage()}
                    hideContainer={true}
                />

            </ContainerFields>

        </ContentList>
    );

}

export default Profile;