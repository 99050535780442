import React from "react";

import emptyCard from '../../../assets/empty-state/empty-card.png';
import {
    Container,
    ContainerImage,
    ContainerSubTitle,
    ContainerTitle
} from "./style";

interface emptyStateProps {

}

const EmptyCard: React.FC<emptyStateProps> = () => {

    return (
        <Container>
            <ContainerImage>
                <img src={emptyCard} alt="Sem Tarefas" />
            </ContainerImage>
            <ContainerTitle>
                <h1>Você não possui registros</h1>
            </ContainerTitle>
            <ContainerSubTitle>
                <span>Clique em novo item para adicionar novos registros</span>
            </ContainerSubTitle>
        </Container>
    );

}

export default EmptyCard;