import React from "react";
import { FaIdCardAlt } from "react-icons/fa";
import { IconList, IconPickerItem } from 'react-fa-icon-picker'

import {
    BtnTemplate,
    Template as TemplateContainer,
    TemplateDescription,
    TemplateIconContainer,
    TemplateImage,
    TemplateLabel,
    TemplateName,
    TemplateTag,
    TemplateTags
} from "./style";
import { Template } from '../../../interfaces/Template';

interface TemplateCardProps {
    template: Template;
    handleSelectTemplate: (template: Template, onDetailOpen: boolean) => Promise<void>;
}

const TemplateCard: React.FC<TemplateCardProps> = ({ template, handleSelectTemplate }) => {

    return (
        <TemplateContainer key={template.id_template} onClick={() => handleSelectTemplate(template, true)}>
            <BtnTemplate color={template.color}>
                <TemplateImage url_image={template.image_url} color={template.color} />
                <TemplateIconContainer color={template.color}>
                    {template !== undefined && template.icon !== undefined ?
                        <IconPickerItem
                            icon={template.icon as IconList}
                            color={"white"}
                        /> :
                        <FaIdCardAlt />
                    }
                </TemplateIconContainer>
                <TemplateLabel>
                    <TemplateName>
                        {template.name}
                    </TemplateName>
                    <TemplateDescription>
                        {template.pre_description}
                    </TemplateDescription>
                </TemplateLabel>
                {template.template_tags.length > 0 ? (
                    <TemplateTags>
                        {template.template_tags.map((tag) => {
                            return (
                                <TemplateTag key={tag.id_template_tag} color={tag.color}>
                                    {tag.name}
                                </TemplateTag>
                            )
                        })}
                    </TemplateTags>) : (
                    <></>
                )}
            </BtnTemplate>
        </TemplateContainer>
    );

}

export default TemplateCard;