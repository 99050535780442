import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import styled, { css } from "styled-components";
import Button from '../../components/Button';
import { Dialog } from '@material-ui/core';

interface IconHeaderProps {
    color: string;
}

interface BtnInsertNewAnswerProps {
    color: string;
}

export const DialogContainer = styled(Dialog)`

    .MuiPaper-root {
        border-radius: 10px;
    }

`;

export const Content = styled(DialogContent)`
    background-color: white;   
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: center;
    height: auto;
    width: auto;
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const DialogTop = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #eef2f8;
    margin-top: 15px;    
    width: 100%;
    
`;

export const DialogTopLeft = styled.div`
    width: 65%;
    height: 100%;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: start;
    padding-bottom: 3px;
    padding-top: 3px;    
    margin-left: 15px;

    h1 {
        margin-left: 10px;
        font-size: 20px;
        font-weight: 500;
        color: #060606de;
    }

    svg {
        width: 25px;
        height: 25px;
    }
`;

export const DialogTopCenter = styled.div`
    width: 25%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
`;

export const DialogTopRight = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 30px;
        border: none;
        background-color: transparent;
    }
`;

export const IconHeader = styled.span<IconHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 100%;
    background-color: transparent;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 45px;
        height: 45px;
        background-color: #f4f4f4;
        border-radius: 50%;

        > svg {
            width: 25px;
            height: 25px;

            ${props => props.color && css`
                color: ${props.color} !important;
            `}
        }

    }
`;

export const DialogFooter = styled.div`
  border-top: 1px solid #eef2f8;
  padding: 10px !important;
  width: 100%;
`;

export const DialogFooterCenter = styled.div`
    display: flex;
    justify-content: flex-end;
    align-content: center;
    width: 100%;
`;

export const BtnInsertNewAnswer = styled(Button)<BtnInsertNewAnswerProps>`
    height: 50px;
    width: 95%;
    background-color: #f23b5c;
    color: white;
    border-radius: 5px;
    border: 0;
    font-weight: 500;

    ${props => props.color && css`
        background-color: ${props.color} !important;
    `}
`;

export const Description = styled.div`
    font-size: 15px;
    font-weight: 400;
    color: black;
    margin-bottom: 5px;
`;

export const DependenciesList = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;

    .list-container {
        width: 100%;
    }
`;

export const Dependency = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
`;

export const DependencyName = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: black;
    margin-right: 10px;
`;

export const DependencyType = styled.div`
    font-size: 15px;
    font-weight: 400;
    color: red;
    margin-right: 10px;
`;

export const DependencyObject = styled.div`
    font-size: 15px;
    font-weight: 400;
    color: gray;
`;