import {
    BoxComment,
    BoxUserItem,
    BoxUserLeft,
    BoxUserMail,
    BoxUserName,
    BoxUserRigth,
    BtnPublish,
    CommentActions,
    CommentBottom,
    CommentButton,
    CommentText,
    Container,
    UserComment
} from "./style";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { MentionsInput, Mention, SuggestionDataItem, MentionItem } from 'react-mentions'

import Avatar from 'react-avatar';
import { CardComment } from '../../../interfaces/CardComment';
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { User } from "../../../interfaces/User";
import AvatarCange from "../../AvatarCange";


interface CommentProps {
    card_id?: number;
    flow_id?: number;
    updateComments: () => void;
    setOpenComments: React.Dispatch<React.SetStateAction<boolean>>;
}

const Comment: React.FC<CommentProps> = ({ card_id, flow_id, updateComments, setOpenComments }) => {

    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();
    const { user } = useAuth();

    const [isReadyToComment, setIsReadyToComment] = useState<boolean>(false);
    const [textValue, setTextValue] = useState<string>('');

    const [users, setUsers] = useState<User[]>();
    const [suggestUsers, setSuggestUsers] = useState<SuggestionDataItem[]>();
    const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
    const [mentions, setMentions] = useState<MentionItem[]>([]);

    const [loading, setLoading] = useState<boolean>(false);

    const handleChangeInput = (text: string, mentionItems: MentionItem[]) => {

        if (text !== undefined && text.trim().length > 0) {
            setIsReadyToComment(true);
            setOpenComments(true);
        } else {
            setIsReadyToComment(false);
            setOpenComments(false);
        }

        setTextValue(text);

        //Handle mentions
        if (mentionItems !== undefined && mentionItems.length > 0) {

            const newMentions = mentions;
            newMentions.push(...mentionItems);

            setMentions(newMentions);
        }

    };

    const getCommentMentions = (textComment: string, mentions: MentionItem[]): string[] => {

        let newMentions: string[] = [];

        if (mentions !== undefined && mentions.length > 0) {

            for (let index = 0; index < mentions.length; index++) {
                const mention = mentions[index];

                if (textComment.includes(mention.display)) {
                    if (newMentions.indexOf(mention.id) === -1) {
                        newMentions.push(mention.id);
                    }
                }

            }

        }

        return newMentions;

    };

    const handleAddComment = useCallback(async () => {

        if (formRef.current !== null) {
            await formRef.current.submitForm();
            await formRef.current.reset();
        }

    }, []);



    const handleSubmit = useCallback(async () => {

        if (card_id !== undefined && flow_id !== undefined) {

            setLoading(true);

            const newObjApi: CardComment = {
                card_id,
                description: textValue,
                flow_id,
                mentions: getCommentMentions(textValue, mentions)
            }

            await api
                .post('/card-comment', newObjApi)
                .then(response => {

                    updateComments();

                    setIsReadyToComment(false);
                    setOpenComments(false);
                    setTextValue('');
                    setMentions([]);

                    setLoading(false);

                }).catch(error => {
                    setLoading(false);
                    console.log(error);
                    addToast({
                        type: 'error',
                        title: 'Erro ao inserir um novo item [1]',
                        description: 'Ocorreu um erro ao inserir o registro!',
                    });
                });

        }

    }, [addToast, card_id, flow_id, updateComments, textValue, mentions, setOpenComments]);

    const getUsersApi = useCallback(async () => {

        if (flow_id !== undefined) {

            setLoadingUsers(true);
            api.get(`/user/by-flow`, {
                params: {
                    id_flow: flow_id
                }
            }).then(response => {
                if (response.data !== null) {
                    const respStep: User[] = response.data;

                    const respStepMention: SuggestionDataItem[] = respStep.map((user) => {
                        return {
                            id: user.id_user,
                            display: user.name
                        }
                    });

                    setSuggestUsers(respStepMention)
                    setUsers(respStep);
                    setLoadingUsers(false);
                    setMentions([]);
                }
            });

        }

    }, [flow_id]);

    useEffect(() => {

        if (users === undefined && loadingUsers === false) {
            getUsersApi();
        }

    }, [loadingUsers, users, getUsersApi]);

    return (
        <Container>
            <UserComment>
                <AvatarCange user={user} size="30" />
            </UserComment>
            <BoxComment className='box-comment'>
                <CommentText enabled={isReadyToComment}>
                    <Form ref={formRef} onSubmit={handleSubmit} style={{ padding: '10px' }}>

                        <MentionsInput
                            name="description"
                            placeholder={'Escreva uma atualização ou @mencione alguém...'}
                            onChange={(e, newValue, newPlainTextValue, mentions) =>
                                handleChangeInput(newPlainTextValue, mentions)
                            }
                            ignoreAccents={true}
                            value={textValue}                            
                            className="mentions-input"
                        >
                            <Mention
                                trigger="@"
                                data={suggestUsers !== undefined ? suggestUsers : []}
                                className="mentions-mention"
                                isLoading={loadingUsers}
                                displayTransform={(id, display) => `@${display}`}
                                renderSuggestion={(suggestion) => {

                                    const userSuggest: User | undefined = users?.filter((user) => user.id_user === suggestion.id)[0];

                                    if (userSuggest !== undefined) {
                                        return (
                                            <BoxUserItem key={userSuggest.id_user}>
                                                <BoxUserLeft>
                                                    <AvatarCange user={userSuggest} size="28" />
                                                </BoxUserLeft>
                                                <BoxUserRigth>
                                                    <BoxUserName>{userSuggest.name}</BoxUserName>
                                                    <BoxUserMail>{userSuggest.email}</BoxUserMail>
                                                </BoxUserRigth>
                                            </BoxUserItem>
                                        )
                                    } else {
                                        return (
                                            <BoxUserItem key={suggestion.id}>
                                                <BoxUserLeft>
                                                    <Avatar color={'red'} size="28" name={suggestion.display} round="20px" />
                                                </BoxUserLeft>
                                                <BoxUserRigth>
                                                    <BoxUserName>{suggestion.display}</BoxUserName>
                                                </BoxUserRigth>
                                            </BoxUserItem>
                                        )
                                    }

                                }
                                }
                            />
                        </MentionsInput>
                    </Form>
                </CommentText>
                <CommentBottom enabled={isReadyToComment}>
                    <CommentActions>
                    </CommentActions>
                    <CommentButton>
                        <BtnPublish onClick={handleAddComment} isLoading={loading}>
                            Comentar
                        </BtnPublish>
                    </CommentButton>
                </CommentBottom>
            </BoxComment>
        </Container >
    );

}

export default Comment;