import { AiOutlineClose, AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import {
    BtnInsertNewAnswer,
    ContainerItems,
    Content,
    ContentBody,
    DialogFooter,
    DialogFooterCenter,
    DialogTop,
    DialogTopCenter,
    DialogTopLeft,
    DialogTopRight,
    FieldButton,
    FieldContainer,
    FieldLeft,
    FieldRight,
    FieldTitle,
    FieldsContainer,
    FormTitle,
    HelpContainer,
    HelpIcon,
    HelpItem,
    HelpItems,
    HelpText,
    IconHeader
} from "./styles";
import { MdOutlineAttachMoney, MdOutlineMoneyOffCsred, MdOutlineSubtitles, MdOutlineSubtitlesOff } from "react-icons/md";
import React, { useCallback, useEffect, useState } from "react";

import { BiSave } from "react-icons/bi";
import { Dialog } from '@material-ui/core';
import { FieldProps } from "../../components/Forms/Fields/FieldBuilder";
import { Flow } from '../../interfaces/Flow';
import { Form } from "../../interfaces/Form";
import { IoMdSwitch } from "react-icons/io";
import { Register } from "../../interfaces/Register";
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

export interface ConfigCardProps {
    open: boolean;
    flow?: Flow | undefined;
    register?: Register | undefined;
    onClose: () => void;
}

interface apiRequest {
    id_field?: number;
    form_id?: number;
    type?: number; //1 - Title / 2 - Highligth / 3 - Aggregate
    value?: boolean;
}

const ConfigCard: React.FC<ConfigCardProps> = ({ onClose, open, flow, register }) => {

    const { addToast } = useToast();

    const [forms, setForms] = useState<Form[]>();
    const [apiReq, setApiReq] = useState<apiRequest[]>([]);
    const [loadingInsert, setLoadingInsert] = useState<boolean>(false);

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    const handleSave = useCallback(async () => {

        for (let index = 0; index < apiReq.length; index++) {

            let objApi = {};

            if (apiReq[index].type === 1) {

                objApi = {
                    id_field: apiReq[index].id_field,
                    form_id: apiReq[index].form_id,
                    card_title: apiReq[index].value
                }

            } else if (apiReq[index].type === 2) {

                objApi = {
                    id_field: apiReq[index].id_field,
                    form_id: apiReq[index].form_id,
                    card_highlight: apiReq[index].value
                }

            } else if (apiReq[index].type === 3) {

                objApi = {
                    id_field: apiReq[index].id_field,
                    form_id: apiReq[index].form_id,
                    card_aggregate: apiReq[index].value
                }

            }

            setLoadingInsert(true);
            await api.post('/field/card-config', objApi)
                .then(response => {
                    setLoadingInsert(false);
                }).catch(error => {
                    setLoadingInsert(false);
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar os registros na base de daods!',
                        description: 'Ocorreu um erro ao salvar o registro! :(',
                    });
                });

        }

        setApiReq([]);
        handleClose();

    }, [apiReq, handleClose, addToast]);

    const handleSetTitle = useCallback(async (fieldUpd: FieldProps, formUpd: Form) => {

        let apiReqNew = apiReq;

        //Atualiza o field alterado
        setForms(
            forms?.map((form) => {
                if (form.id_form === formUpd.id_form) {
                    form.fields.map((field) => {
                        if (field.id_field === fieldUpd.id_field) {

                            if (field.card_title === "1") {
                                field.card_title = "0";
                            } else {
                                field.card_title = "1";
                            }

                            const newItem: apiRequest = {
                                id_field: field.id_field,
                                form_id: field.form_id,
                                type: 1,
                                value: field.card_title === "1" ? true : false
                            }

                            apiReqNew.push(newItem);

                        }
                        return field;
                    })
                }
                return form
            })
        );

        //Só vai setar todos como false caso o field foi setado como true
        if (fieldUpd.card_title === "1") {
            setForms(
                forms?.map((form) => {
                    form.fields.map((field) => {
                        if (field.id_field !== fieldUpd.id_field) {

                            if (field.card_title === "1") {
                                field.card_title = "0";

                                const newItem: apiRequest = {
                                    id_field: field.id_field,
                                    form_id: field.form_id,
                                    type: 1,
                                    value: field.card_title === "1" ? true : false
                                }

                                apiReqNew.push(newItem);
                            }

                        }
                        return field;
                    })
                    return form
                })
            );
        }

        setApiReq(apiReqNew);

    }, [forms, apiReq]);

    const handleSetHighlight = useCallback(async (fieldUpd: FieldProps, formUpd: Form) => {

        setForms(
            forms?.map((form) => {
                if (form.id_form === formUpd.id_form) {
                    form.fields.map((field) => {
                        if (field.id_field === fieldUpd.id_field) {

                            if (field.card_highlight === "1") {
                                field.card_highlight = "0";
                            } else {
                                field.card_highlight = "1";
                            }

                            const newItem: apiRequest = {
                                id_field: field.id_field,
                                form_id: field.form_id,
                                type: 2,
                                value: field.card_highlight === "1" ? true : false
                            }

                            setApiReq([
                                ...apiReq,
                                newItem
                            ]);

                        }

                        return field;
                    })
                }
                return form
            })
        );

    }, [forms, apiReq]);

    const handleSetAggregate = useCallback(async (fieldUpd: FieldProps, formUpd: Form) => {

        setForms(
            forms?.map((form) => {
                if (form.id_form === formUpd.id_form) {
                    form.fields.map((field) => {
                        if (field.id_field === fieldUpd.id_field) {

                            if (field.card_aggregate === "1") {
                                field.card_aggregate = "0";
                            } else {
                                field.card_aggregate = "1";
                            }

                            const newItem: apiRequest = {
                                id_field: field.id_field,
                                form_id: field.form_id,
                                type: 3,
                                value: field.card_aggregate === "1" ? true : false
                            }

                            setApiReq([
                                ...apiReq,
                                newItem
                            ]);

                        }

                        return field;
                    })
                }
                return form
            })
        );

    }, [forms, apiReq]);

    useEffect(() => {

        let arrFields = [] as Form[];

        //Normalização de dados
        if (flow !== undefined) {

            //Formulário Inicial
            if (flow.form_init !== undefined && flow.form_init !== null) {

                //Trata o nome do form inicial
                if (flow.form_init.name.includes("FI")) {
                    flow.form_init.name = flow.form_init.name.replace("FI", "Formulário Inicial");
                }
                arrFields.push(flow.form_init);
            }

            //Forms Steps
            if (flow.flow_steps !== undefined) {
                for (let index = 0; index < flow.flow_steps.length; index++)
                    //Somente adiciona caso tenha algum campo    
                    if (flow.flow_steps[index].form.fields.length > 0) {

                        //Trata o nome do step
                        if (flow.flow_steps[index].form.name.includes("STP")) {
                            flow.flow_steps[index].form.name = flow.flow_steps[index].form.name.replace("STP", "Etapa");
                        }

                        arrFields.push(flow.flow_steps[index].form);
                    }
            }

            if (arrFields !== undefined) {
                setForms(arrFields);
            }

        } else if (register !== undefined) {

            //Campos do Cadastro
            if (register.form !== undefined && register.form !== null) {

                //Trata o nome do form inicial
                if (register.form.name.includes("RE")) {
                    register.form.name = register.form.name.replace("RE", "Campos do Cadastro");
                }
                arrFields.push(register.form);

                if (arrFields !== undefined) {
                    setForms(arrFields);
                }

            }

        }

    }, [flow, register]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={flow !== undefined && flow?.color !== undefined ? flow?.color : register !== undefined && register?.color !== undefined ? register?.color : "black"}>
                        <div>
                            <IoMdSwitch />
                        </div>
                    </IconHeader>
                    <h1>
                        {"Configurar Exibição"}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose></AiOutlineClose></button>
                </DialogTopRight>
            </DialogTop>

            <Content>
                <ContentBody container>
                    <HelpContainer>
                        <HelpItems>
                            <HelpItem>
                                <HelpIcon>
                                    <MdOutlineSubtitles />
                                </HelpIcon>
                                <HelpText>
                                    <b>{"Título: "}</b>{"Ativando esta opção você faz com que o campo seja o título do seu cartão. Somente um campo pode ser escolhido"}
                                </HelpText>
                            </HelpItem>
                            <HelpItem>
                                <HelpIcon>
                                    <AiOutlineEye />
                                </HelpIcon>
                                <HelpText>
                                    <b>{"Destaque: "}</b>{"Ativando esta opção você faz com que o campo apareça destacado no cartão"}
                                </HelpText>
                            </HelpItem>
                            <HelpItem>
                                <HelpIcon>
                                    <MdOutlineAttachMoney />
                                </HelpIcon>
                                <HelpText>
                                    <b>{"Agregação: "}</b>{"Ativando esta opção você irá totalizar os valores do campo, podendo ter uma visualização agregada"}
                                </HelpText>
                            </HelpItem>
                        </HelpItems>

                    </HelpContainer>

                    <ContainerItems>
                        {forms?.map((form) => {
                            return (
                                <FieldsContainer key={form.id_form}>
                                    <FormTitle>
                                        {form.name}
                                    </FormTitle>
                                    {form.fields.filter((f) => f.type !== "TEXT_FORMULA_FIELD" && f.type !== "SLA_CONFIGURATOR_FIELD" && f.type !== "INPUT_RICH_TEXT_FIELD").map((field: FieldProps) => {
                                        return (
                                            <FieldContainer key={field.id_field}>
                                                <FieldLeft>
                                                    <FieldTitle>
                                                        {field.title}
                                                    </FieldTitle>
                                                </FieldLeft>
                                                <FieldRight>
                                                    <FieldButton onClick={() => handleSetTitle(field, form)}>
                                                        {field.card_title === "1" ?
                                                            <MdOutlineSubtitles /> :
                                                            <MdOutlineSubtitlesOff />
                                                        }
                                                    </FieldButton>
                                                    <FieldButton onClick={() => handleSetHighlight(field, form)}>
                                                        {field.card_highlight === "1" ?
                                                            <AiOutlineEye /> :
                                                            <AiOutlineEyeInvisible />
                                                        }
                                                    </FieldButton>
                                                    <FieldButton onClick={() => handleSetAggregate(field, form)}>
                                                        {String(field.card_aggregate) === "1" ?
                                                            <MdOutlineAttachMoney /> :
                                                            <MdOutlineMoneyOffCsred />
                                                        }
                                                    </FieldButton>
                                                </FieldRight>
                                            </FieldContainer>
                                        );
                                    })}
                                </FieldsContainer>
                            );
                        })}
                    </ContainerItems>
                </ContentBody>
            </Content>

            <DialogFooter>
                <DialogFooterCenter>
                    <BtnInsertNewAnswer icon={BiSave} onClick={() => handleSave()} isLoading={loadingInsert}>
                        Salvar
                    </BtnInsertNewAnswer>
                </DialogFooterCenter>
            </DialogFooter>
        </Dialog >
    );
};

export default ConfigCard;

