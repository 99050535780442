import styled, { keyframes, css } from "styled-components";

interface ContainerProps {
    size: 'max' | 'min' | 'mid';
    color: string;
}

interface DaysProps {
    size: 'max' | 'min' | 'mid';
}

const pulse = keyframes`
    0% {
        transform: scale(1); 
    }
    50% {
        transform: scale(1.03); 
    }
    100% {
        transform: scale(1); 
    }
`;

export const Container = styled.div<ContainerProps>`
    background-color: #2ee31e;    

    ${(props) => props.size === 'max' && props.color ? css`
        width: 170px;
        height: 170px;
        background-color: ${props.color};
        box-shadow: 0px 0px 15px 2px ${props.color};
    ` : props.size === 'mid' && props.color ? css`
        width: 60px;
        height: 60px;   
        background-color: ${props.color};
        box-shadow: 0px 0px 15px -1px ${props.color};
    ` : css`
        width: 45px;
        height: 45px;   
        cursor: pointer;  
        background-color: ${props.color};
        box-shadow: 0px 0px 15px -1px ${props.color};   
    `}

    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${pulse} 1.5s infinite ease-in-out;    
`;

export const ContainerBackground = styled.div`
    background-color: white;
    border-radius: 100%;
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ContainerDays = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
`;

export const Days = styled.div<DaysProps>`
    
    font-weight: 600;

    ${(props) => props.size === 'max' ? css`
        font-size: 3.5em;
        margin-right: 5px;
    ` : props.size === 'mid' ? css`
        font-size: 1.4em;
        margin-right: 0px;
    ` : css`
        font-size: 1.2em;
        margin-right: 0px;
        cursor: pointer;
    `}
`;

export const DaysText = styled.div`
    margin-bottom: 0.6em;
    font-size: 1.1em;
`;