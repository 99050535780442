import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from "@unform/core";
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

import DegradeLine from '../../../components/DegradeLine';
import FormBuilder from '../../../components/Forms/FormBuilder';
import api from '../../../services/api';
import logo from '../../../assets/wendata-logo.png';
import {
    AnimationContainer,
    Body,
    Bottom,
    BottomLeft,
    BottomRight,
    BtnStart,
    Container,
    Content,
    ContentList,
    Header,
    HeaderLeft,
    HeaderRight,
    ProgressBar,
    ProgressBarPercent
} from './styles';
import { FieldProps, Fields } from '../../../components/Forms/Fields/FieldBuilder';
import { Onboarding } from '../../../interfaces/Onboarding';
import { useToast } from '../../../hooks/toast';
import { useMediaQuery } from 'react-responsive';

interface RouteParams {
    hash?: string;
}

const fieldsForm: FieldProps[] = [
    {
        name: "name_onboarding",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 1,
        title: "Qual é seu nome?",
        description: "",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["É necessário que seja preenchido o campo Seu nome!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "phone_onboarding",
        type: Fields.PHONE_FIELD,
        required: false,
        index: 3,
        title: "E qual o seu número de telefone?",
        description: "",
        placeholder: "Digite aqui...",
        validation_type: "string"
    },
    {
        name: "password_onboarding",
        type: Fields.PASSWORD_FIELD,
        required: true,
        index: 2,
        title: "Escolha uma senha",
        description: "",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["É necessário que seja preenchido o campo de senha!"]
            },
            {
                type: "min",
                params: [8, "O campo senha deve conter mais de 8 caracteres!"]
            }
        ]
    }
]

interface formProps {
    name_onboarding: string;
    password_onboarding: string;
    phone_onboarding?: string;
}

const PersonalData: React.FC = () => {

    const { params } = useRouteMatch<RouteParams>();
    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();
    const history = useHistory();
    const [onboarding, setOnboarding] = useState<Onboarding>();
    const [loading, setLoading] = useState<boolean>(false);
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    const handleSubmitPage = useCallback(async () => {
        if (formRef.current !== undefined) {
            formRef.current?.submitForm();
        }
    }, []);

    const handleSubmit = useCallback(async (data: object[]) => {

        if (formRef.current !== null) {

            const dataNormalized = formRef.current.getData() as unknown as formProps;
            setLoading(true);
            await api
                .post('/onboarding/', {
                    status: "2",
                    email: onboarding?.email,
                    hash: params.hash,
                    name: dataNormalized.name_onboarding,
                    password: dataNormalized.password_onboarding,
                    phone: dataNormalized?.phone_onboarding
                })
                .then(response => {
                    setLoading(false);
                    history.push('/onboarding-step-3-1/' + params.hash);

                }).catch(error => {
                    setLoading(false);
                    console.log(error);
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar os dados!',
                        description: 'Ocorreu um erro ao tentar salvar os dados na base!',
                    });
                });

        }

    }, [addToast, params.hash, onboarding, history]);

    useEffect(() => {

        api.get(`/onboarding`, {
            params: {
                hash: params.hash
            }
        }).then(response => {

            const apiOnboarding: Onboarding = response.data;

            setOnboarding(apiOnboarding);

            if (apiOnboarding.status === "E") { //Se já está finalizado o onboarding
                history.push('/');
                addToast({
                    type: 'error',
                    title: 'Este onboarding já foi finalizado!',
                    description: 'Este onboarding já foi encerrado, inicie um novo ou faça o login!',
                });
            }

        }).catch(error => {
            history.push('/onboarding/');
            addToast({
                type: 'error',
                title: 'Não foi encontrado o seu onboarding',
                description: 'Não foi possível localizar o seu onboarding!',
            });

        });


    }, [params.hash, addToast, history]);

    return (
        !isMobile ?
            <Container>
                <title>Crie sua conta no Cange!</title>
                <Content>
                    <AnimationContainer>
                        <Header>
                            <HeaderLeft>
                                <h1>Conte um pouco sobre você</h1>
                            </HeaderLeft>
                            <HeaderRight>
                                <a href='https://www.cange.me'>
                                    <img className='logo-cange' src={logo} alt="Logo wendata" />
                                    <img className='logo-mini-cange' src={logo} alt="Logo wendata" />
                                </a>
                            </HeaderRight>
                        </Header>
                        <DegradeLine />
                        <Body>
                            <ContentList>
                                <FormBuilder
                                    id="formOnboardingTwo"
                                    formRef={formRef}
                                    fields={fieldsForm}
                                    handleSubmit={handleSubmit}
                                    hideContainer={true}
                                />
                            </ContentList>

                        </Body>
                        <Bottom>
                            <BottomLeft>
                                <ProgressBar>
                                    <ProgressBarPercent percent={40} />
                                </ProgressBar>
                            </BottomLeft>
                            <BottomRight>
                                <BtnStart type="submit" style={{ padding: "0px" }} onClick={handleSubmitPage} isLoading={loading}>Continuar</BtnStart>
                            </BottomRight>
                        </Bottom>
                    </AnimationContainer>
                </Content>
            </Container> :
            <Container>
            <title>Crie sua conta no Cange!</title>
                <Content>
                    <AnimationContainer>
                        <Header>
                            <HeaderRight>
                                <a href='https://www.cange.me'>
                                    <img className='logo-cange' src={logo} alt="Logo wendata" />
                                    <img className='logo-mini-cange' src={logo} alt="Logo wendata" />
                                </a>
                            </HeaderRight>
                        </Header>
                        <DegradeLine />
                        <Body>
                            <ContentList>
                                <FormBuilder
                                    id="formOnboardingTwo"
                                    formRef={formRef}
                                    fields={fieldsForm}
                                    handleSubmit={handleSubmit}
                                    hideContainer={true}
                                />
                            </ContentList>
                        </Body>
                        <Bottom>
                            <BottomLeft>
                                <ProgressBar>
                                    <ProgressBarPercent percent={40} />
                                </ProgressBar>
                            </BottomLeft>
                            <BottomRight>
                                <BtnStart type="submit" style={{ padding: "0px" }} onClick={handleSubmitPage} isLoading={loading}>Continuar</BtnStart>
                            </BottomRight>
                        </Bottom>
                    </AnimationContainer>
                </Content>
            </Container>
    );
}

export default PersonalData;
