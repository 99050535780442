import {
    Animation,
    CleanBox,
    CleanBoxText,
    CleanBoxTitle,
    Container, ContainerLoader, WorkspaceItem, WorkspaceItemIcon
} from "./style";
import React, { useCallback, useEffect, useRef, useState } from "react";

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { Workspace } from "../../../interfaces/Workspace";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { BsBoxes } from "react-icons/bs";
import { Link } from "react-router-dom";
import lottie from 'lottie-web';
import AddNewWorkspace from "../../../dialogs/AddNewWorkspace";

interface defaultProps {
}

const MyWorkspaces: React.FC<defaultProps> = () => {

    const { addToast } = useToast();
    const [workspaces, setWorkspaces] = useState<Workspace[]>();
    const [loading, setLoading] = useState<boolean>(true);
    const lottieContainer = useRef<HTMLDivElement>(null);
    const [openNewWorkspace, setOpenNewWorkspace] = useState<boolean>(false);

    const handleNewWorkspaceClose = useCallback(async () => {

        setOpenNewWorkspace(false)

    }, []);

    const getWorkspacesApi = useCallback(async () => {

        setLoading(true);
        api.get(`/workspace/by-user`, {}
        ).then(response => {
            if (response.data !== null) {

                const respWorkspace: Workspace[] = response.data;

                if (respWorkspace !== undefined) {
                    setWorkspaces(respWorkspace);

                }

            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            console.log(error);
            addToast({
                type: 'error',
                title: 'Erro ao carregar os seus workspaces',
                description: 'Ocorreu ao tentar carregar os seus workspaces!',
            });
        });

    }, [addToast]);

    useEffect(() => {

        getWorkspacesApi();

    }, [getWorkspacesApi]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../assets/lottie/loader.json')
            })
        }
    }, []);

    return (
        loading ?
            <ContainerLoader>
                <Animation className="lottieContainer" ref={lottieContainer} />
            </ContainerLoader > :

            <Container>
                {workspaces === undefined || workspaces.length === 0 ?
                    <CleanBox onClick={() => {
                        setOpenNewWorkspace(true);
                    }}>
                        <BsBoxes />
                        <CleanBoxTitle>
                            Seus workspaces ficarão aqui :)
                        </CleanBoxTitle>
                        <CleanBoxText>
                            Workspaces são espaços de trabalho para você organizar fluxos e os membros de cada área da sua empresa em um só lugar.
                        </CleanBoxText>
                    </CleanBox> :
                    <></>
                }

                <AddNewWorkspace
                    open={openNewWorkspace}
                    selectedWorkspace={undefined}
                    onClose={handleNewWorkspaceClose}
                />

                {workspaces?.map((wp) => {
                    return (
                        <Link key={wp.id_workspace} to={'/workspace/' + wp.hash} style={{ textDecoration: 'none' }}>
                            <WorkspaceItem
                                key={wp.id_workspace}
                                color={wp.color}
                                selectedStyle={false}
                                title={wp.name}
                            >
                                <WorkspaceItemIcon color={wp.color}>
                                    <IconPickerItem
                                        icon={wp.icon as IconList}
                                        color={wp.color}
                                    />
                                </WorkspaceItemIcon>
                                <label>{wp.name}</label>
                            </WorkspaceItem>
                        </Link>
                    )
                })}
            </Container>
    );

}

export default MyWorkspaces;