import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const AvatarContainer = styled.div`
  position: relative;
  margin-right: 5px;

  span {
    color: white;
  }

  &:hover > div {
    visibility: visible;
  }
`;

export const RemoveButton = styled.div`
  position: absolute;
  top: -7px;
  right: -7px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  visibility: hidden;
  transition: visibility 0.2s;
`;

export const SelectedIcon = styled.div`
  position: absolute;
  top: -7px;
  right: -7px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #4d4d4d;
  }
`;

export const AddButton = styled.div`
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 5px;

    background-color: transparent;
    border: 1px dashed #4d4d4d;

    span.plus-add-button {
        font-size: 18px;
        color: #4d4d4d;
    }
`;

export const AvatarHiddenContainer = styled.div`
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 5px;
    font-size: 12px;

    background-color: transparent;
    border: 1px solid #4d4d4d;

    span {
    font-size: 18px;
    color: #4d4d4d;
    }
`;


export const BoxContainer = styled(Box)`
    position: absolute;

    left: 0px;
    bottom: 35px;

    z-index: 1;
    min-width: 250px;
    border-radius: 5px;
    padding: 5px;
    padding-bottom: 10px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 0px;    
    margin-right: 10px;
`;

export const BoxItemsContainer = styled.div`
    max-height: 170px;
    overflow-y: auto;
`;

export const BoxUserSelected = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    font-size: 12px;
    margin-bottom: 10px;
    border-radius: 0px 0px 5px 5px;
    background-color: #893be9;
    border-top: 1px solid #80808061;
    font-weight: 500;
    color: white;
`;

export const BoxUserRemove = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 50px;
    padding: 5px;

    svg {
        height: 15px;
        width: 15px;
        color: black;
    }

    :hover {
        background-color: white;
    }
`;

export const BoxUserItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px 5px 10px;
    border: 1px dashed transparent;

    :hover {
        background-color: #f4f5f7;
        border-radius: 15px;
        border: 1px dashed #e8f2ff;
    }
`;

export const BoxUserLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const BoxUserRigth = styled.div`
    margin-left: 15px;
    width: 100%;
`;

export const BoxUserName = styled.div`
    font-size: 14px;
    color: black;
    font-weight: 400;
    overflow: hidden;
`;

export const BoxUserMail = styled.div`
    font-size: 11px;
    color: gray;
    overflow: hidden;
`;

export const BoxSearchContainer = styled.div`
    padding: 10px 10px 10px 10px;
`;

export const SearchInput = styled.input`
    width: 100%;
    height: 35px;
    padding: 5px 15px 5px 15px;
    border-radius: 15px;
    border: 1px solid #f4f5f7;
    background-color: #f4f5f7;
    font-size: 14px;
`;

export const BtnRemoveUser = styled.button`
    margin: 5px;
    font-size: 13px;
    float: right;
    padding: 3px 5px;
    background-color: transparent;
    border: none;
    border: 1px dashed transparent;

    :hover {
        background-color: #f4f5f7;
        border-radius: 15px;
        border: 1px dashed #e8f2ff;
    }
`;