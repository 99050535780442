import styled, { css } from "styled-components";

interface ContainerProps {
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  padding: 5px 0px 0px 0px;

  ${(props) => props.isErrored && css`
      div {
        border-color: red;
      }
  `}

  .react-select__menu {
    position:relative;
    z-index: 99999;
  }
`;

export const Title = styled.div`
  color: gray;
`;
