import styled, { css } from "styled-components";

interface TaskAlertProps {
    color?: string;
}

interface FlowIconProps {
    color?: string;
}

export const Container = styled.div`
    margin-top: 10px;
    max-height: 230px;
    //overflow: auto;

    .rdt_TableBody {
        overflow-y: auto;
        overflow-x: hidden;
    }
`;

export const FlowContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    margin-bottom: 3px;
    padding: 3px;
    border-radius: 5px;

    span {
        font-size: 14px;
        font-weight: 500;
        color: gray;
    }
`;

export const FlowIcon = styled.div<FlowIconProps>`
    display: flex;
    height: 25px;
    width: 25px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;

    ${(props) =>
        props.color &&
        css`
      background-color: ${props.color + "1e"};
    `}

    div {
        display: flex;
        font-size: 13px !important;
        color: rgb(158, 55, 237);
        padding: 2px;
    }

`;

export const TaskAlert = styled.div<TaskAlertProps>`
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    overflow: auto;
    padding: 3px 7px 3px 7px;
    color: white;
    font-weight: 500;
    white-space: nowrap;
    font-size: 11px;
    
    ${(props) =>
        props.color &&
        css`
      background-color: ${props.color};
    `}

`;

export const TitleContainer = styled.div`
    display: block;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    h3 {
        font-size: 13px;
        font-weight: 400;
        color: black;
        white-space: nowrap;
        overflow: hidden;
    }

    span {
        font-size: 11px;
        font-weight: 400;
        color: gray;
        white-space: nowrap;
        overflow: hidden;
    }
`;

export const ContainerLoader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(305px - 65px);
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 30% !important;
        max-width: 150px;
    }
    
`;