import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    AppBarHeaderContainer,
    ButtonClose,
    ButtonSave,
    ContainerCloseButton,
    ContainerConfig,
    ContainerConfigForm,
    ContainerConfigLeft,
    ContainerConfigRight,
    ContainerConfigRightHeader,
    ContainerConfigRightHeaderSearch,
    ContainerConfigRightHeaderTitle,
    ContainerForm,
    DividerMenu,
    LogoField,
    LogoFieldBtnRemove,
    LogoFieldBtnUpload,
    LogoFieldButtons,
    LogoFieldContent,
    LogoFieldImage,
    LogoFieldTitle
} from "./style";

import { Dialog } from '@material-ui/core';
import { FaTag } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Flow } from "../../interfaces/Flow";
import { IconList } from "react-fa-icon-picker";
import FormPubliConfigBar from "./FormPubliConfigBar";
import { BsArrowBarRight, BsCardImage, BsToggleOff, BsToggleOn } from "react-icons/bs";
import PublicForm from "../../components/PublicForm";
import api from "../../services/api";
import { Form } from "../../interfaces/Form";
import { useToast } from "../../hooks/toast";
import FormBuilder from "../../components/Forms/FormBuilder";
import { FormHandles } from "@unform/core";
import { FieldProps, Fields } from "../../components/Forms/Fields/FieldBuilder";
import UploadImageForm from "./UploadImageForm";
import DialogAction from "../DialogAction";
import { URL_FORM_PUBLIC } from "../../config/app";
import { ButtonActive } from "./FormPubliConfigBar/style";
import AppBarHeader from "../../components/AppBarHeader";
import { Step } from "../../interfaces/Step";

const fieldsFormInitConfig: FieldProps[] = [
    {
        name: "form_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 1,
        title: "Título",
        description: "Digite o título da página",
        max_length: 150
    },
    {
        name: "form_description",
        type: Fields.INPUT_RICH_TEXT_FIELD,
        index: 2,
        title: "Descrição",
        description: "Digite a descrição da página"
    },
    {
        name: "form_color_brand",
        type: Fields.COLOR_PICKER_FIELD,
        index: 3,
        title: "Cor do botão",
        description: "Defina uma cor para personalizar o botão de envio",
        turn_on_field: true,
        variation: '2'
    },
    {
        name: "form_button_text",
        type: Fields.TEXT_SHORT_FIELD,
        index: 4,
        title: "Texto do botão de envio",
        description: "Digite o texto que será exibido no botão de envio",
        turn_on_field: true,
        max_length: 45
    },
    {
        name: "form_color_background",
        type: Fields.COLOR_PICKER_FIELD,
        index: 5,
        title: "Cor do fundo",
        description: "Defina uma cor para personalizar a página",
        turn_on_field: true,
        variation: '2'
    },
    {
        name: "form_show_another_answer",
        type: Fields.RADIO_BOX_FIELD,
        required: false,
        index: 6,
        title: "Exibir botão para envio de outra resposta",
        description: "Este campo irá definir se será possível enviar mais de uma resposta no formulário",
        help_text: "Este campo irá definir se será possível enviar mais de uma resposta no formulário. Se sim, será exibido um botão para envio de outra resposta.",
        options: [
            { value: "S", label: "Sim" },
            { value: "N", label: "Não" }
        ],
        turn_on_field: true
    },
    {
        name: "form_sent_message",
        type: Fields.INPUT_RICH_TEXT_FIELD,
        index: 7,
        title: "Mensagem exibir após envio",
        description: "Digite a mensagem que será exibida após o envio do formulário",
        help_text: "Digite a mensagem que será exibida após o envio do formulário. Esta mensagem será exibida para o respondente logo após o envio do formulário.",
        turn_on_field: true
    },
]


const fieldsFormStepConfig: FieldProps[] = [
    {
        name: "form_title",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 1,
        title: "Título",
        description: "Digite o título da página",
        max_length: 150
    },
    {
        name: "form_description",
        type: Fields.INPUT_RICH_TEXT_FIELD,
        index: 2,
        title: "Descrição",
        description: "Digite a descrição da página"
    },
    {
        name: "form_color_background",
        type: Fields.COLOR_PICKER_FIELD,
        index: 5,
        title: "Cor do fundo",
        description: "Defina uma cor para personalizar a página",
        turn_on_field: true,
        variation: '2'
    },
    {
        name: "form_sent_message",
        type: Fields.INPUT_RICH_TEXT_FIELD,
        index: 7,
        title: "Mensagem exibir após envio",
        description: "Digite a mensagem que será exibida após o envio do formulário",
        help_text: "Digite a mensagem que será exibida após o envio do formulário. Esta mensagem será exibida para o respondente logo após o envio do formulário.",
        turn_on_field: true
    },
]

interface FormPublicProps {
    form_title?: string;
    form_description?: string;
    form_color_brand?: string;
    form_button_text?: string;
    form_color_background?: string;
    form_show_another_answer?: string;
    form_sent_message?: string;
}

interface FormPublicConfigProps {
    open: boolean;
    flow: Flow;
    form_id: number;
    isFromStep?: boolean;
    card_id?: number;
    onClose: () => void;
}

const FormPublicConfig: React.FC<FormPublicConfigProps> = ({ onClose, open, flow, form_id, isFromStep, card_id }) => {

    const { addToast } = useToast();
    const formRefConfig = useRef<FormHandles>(null);

    const [activePublicForm, setActivePublicForm] = useState<boolean>(false);
    const [openConfig, setOpenConfig] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const [loadingSaving, setLoadingSaving] = useState<boolean>(false);
    const [pendingSaving, setPendingSaving] = useState<boolean>(false);
    const [openCloseConfirm, setOpenCloseConfirm] = useState(false);
    const [defaultValueForm, setDefaultValueForm] = useState<FormPublicProps>();
    const [form, setForm] = useState<Form>();
    const [copiedLink, setCopiedLink] = useState(false);
    const [linkForm, setLinkForm] = useState<string>();

    const [openUploadLogo, setOpenUploadLogo] = useState(false);
    const [openUploadCover, setOpenUploadCover] = useState(false);

    const [step, setStep] = useState<Step>();

    const handleCopyClick = () => {
        navigator.clipboard.writeText(URL_FORM_PUBLIC + "/public/" + form?.public_hash);

        setCopiedLink(true);
    };

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    const handleUploadLogoClose = useCallback(async (id_attachment?: number, form_logo_url?: string) => {

        //Update the form object
        if (form !== undefined) {
            setForm({
                ...form,
                logo_attachment_id: id_attachment,
                logo_url: form_logo_url
            });

            setPendingSaving(true);
        }

        setOpenUploadLogo(false);

    }, [form]);

    const handleRemoveLogo = useCallback(async () => {

        //Update the form object
        if (form !== undefined) {
            setForm({
                ...form,
                logo_attachment_id: undefined,
                logo_url: undefined
            });

            setPendingSaving(true);
        }

        setOpenUploadLogo(false);

    }, [form]);

    const handleUploadCoverClose = useCallback(async (id_attachment?: number, form_logo_url?: string) => {

        //Update the form object
        if (form !== undefined) {
            setForm({
                ...form,
                cover_attachment_id: id_attachment,
                cover_url: form_logo_url
            });

            setPendingSaving(true);
        }

        setOpenUploadCover(false);

    }, [form]);

    const handleRemoveCover = useCallback(async () => {

        //Update the form object
        if (form !== undefined) {
            setForm({
                ...form,
                cover_attachment_id: undefined,
                cover_url: undefined
            });

            setPendingSaving(true);
        }

        setOpenUploadLogo(false);

    }, [form]);

    const postFormApi = useCallback(async (onClose?: boolean) => {

        if (form_id !== undefined && flow.id_flow !== undefined && form !== undefined) {

            setLoadingSaving(true);

            await api.post(`/form/public-form`, {
                id_form: form_id,
                flow_id: flow.id_flow,
                logo_url: form.logo_url !== null ? form.logo_url : undefined,
                logo_attachment_id: form.logo_attachment_id !== null ? form.logo_attachment_id : undefined,
                cover_url: form.cover_url !== null ? form.cover_url : undefined,
                cover_attachment_id: form.cover_attachment_id !== null ? form.cover_attachment_id : undefined,
                title: form.title !== null ? form.title : undefined,
                description: form.description !== null ? form.description : undefined,
                color_brand: form.color_brand !== null ? form.color_brand : undefined,
                button_text: form.button_text !== null ? form.button_text : undefined,
                color_background: form.color_background !== null ? form.color_background : undefined,
                show_another_answer: form.show_another_answer !== null ? form.show_another_answer : undefined,
                sent_message: form.sent_message !== null ? form.sent_message : undefined
            }).then(response => {

                //const retApi: Form = response.data;

                if (onClose) {
                    handleClose();
                }

                setPendingSaving(false);
                setLoadingSaving(false);
            }).catch(error => {
                setLoadingSaving(false);
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Erro ao alterar o status do formulário',
                    description: 'Ocorreu um erro ao tentar alterar o status do formulário!',
                });
            });
        }

    }, [form_id, flow.id_flow, addToast, form, handleClose]);

    const handleSavePage = useCallback(async () => {

        if (formRefConfig.current !== null) {

            const dataNormalized = formRefConfig.current.getData() as unknown as FormPublicProps;

            //Update the form object
            if (form !== undefined) {
                setForm({
                    ...form,
                    title: dataNormalized.form_title,
                    description: dataNormalized.form_description,
                    color_brand: dataNormalized.form_color_brand,
                    button_text: dataNormalized.form_button_text,
                    color_background: dataNormalized.form_color_background,
                    show_another_answer: dataNormalized.form_show_another_answer,
                    sent_message: dataNormalized.form_sent_message
                });

                setPendingSaving(true);
            }

        }

    }, [form]);

    const handleSubmitForm = useCallback(async (data: object[]) => {

        await handleSavePage();

    }, [handleSavePage]);

    const handleSubmitConfig = useCallback(async () => {
        if (formRefConfig.current !== undefined) {
            formRefConfig.current?.submitForm();
        }
    }, []);

    const postFormStatusApi = useCallback(async (status: string) => {

        if (form_id !== undefined && flow.id_flow !== undefined) {

            await api.post(`/form/public-form`, {
                id_form: form_id,
                flow_id: flow.id_flow,
                public_form: status,
            }).then(response => {

                const retApi: Form = response.data;

                if (retApi !== undefined) {

                    if (retApi.public_form === "S") {

                        //Update the form object
                        if (form !== undefined) {
                            setForm({
                                ...form,
                                public_form: "S",
                                public_hash: retApi.public_hash,
                                public_dt_last_update: retApi.public_dt_last_update,
                            });
                        }

                        setActivePublicForm(true);

                        addToast({
                            type: 'success',
                            title: 'Wow! O formulário está público e disponível',
                            description: 'Agora você pode compartilhar o formulário a partir do link disponível!',
                        });

                    } else if (retApi.public_form === "N") {

                        setActivePublicForm(false);

                        //Update the form object
                        if (form !== undefined) {
                            setForm({
                                ...form,
                                public_form: "S",
                                public_hash: retApi.public_hash,
                                public_dt_last_update: retApi.public_dt_last_update,
                            });
                        }

                        addToast({
                            type: 'success',
                            title: 'O formulário público agora está inativo',
                            description: 'Não será mais possível utilizar um link externo para inserir dados!',
                        });

                    }

                }

            }).catch(error => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Erro ao alterar o status do formulário',
                    description: 'Ocorreu um erro ao tentar alterar o status do formulário!',
                });
            });
        }

    }, [form_id, flow.id_flow, addToast, form]);

    const handleChangeStatus = useCallback(async (status: boolean) => {

        const statusForm = status === true ? "S" : "N";

        setActivePublicForm(status);

        await postFormStatusApi(statusForm);

    }, [postFormStatusApi]);

    const getFormApi = useCallback(async () => {

        setLoading(true);

        await api.get(`/form`, {
            params: {
                id_form: form_id
            }
        }).then(response => {
            if (response.data !== null) {

                let respForm: Form = response.data;

                if (respForm !== undefined) {

                    //Default values
                    if (respForm.color_background === undefined || respForm.color_background === null) {
                        respForm.color_background = "#f4f5f7";
                    }

                    if (respForm.title === undefined || respForm.title === null) {
                        respForm.title = !isFromStep ? flow.name : flow.name + " [" + step?.name + "]"
                    }

                    if (respForm.public_form !== undefined && respForm.public_form !== null) {
                        if (respForm.public_form === "S") {
                            setActivePublicForm(true);
                        } else {
                            setActivePublicForm(false);
                        }
                    } else {
                        setActivePublicForm(false);
                    }

                    if (!firstLoad) {

                        const formConfig: FormPublicProps = {
                            form_title: respForm.title,
                            form_description: respForm.description,
                            form_color_brand: respForm.color_brand,
                            form_button_text: respForm.button_text,
                            form_color_background: respForm.color_background,
                            form_show_another_answer: respForm.show_another_answer,
                            form_sent_message: respForm.sent_message
                        }

                        setDefaultValueForm(formConfig);
                    }

                    setForm(respForm);
                }
            }
            setLoading(false);
            setFirstLoad(false);
        }).catch(error => {
            console.log(error);
            addToast({
                type: 'error',
                title: 'Erro ao carregar o Formulário',
                description: 'Ocorreu ao tentar carregar o formulário!',
            });
        });

    }, [addToast, form_id, flow.name, firstLoad, step, isFromStep]);

    useEffect(() => {

        if (flow !== undefined && flow.flow_steps !== undefined && flow.flow_steps !== null && flow.flow_steps.length > 0) {

            const step = flow.flow_steps.find(step => step.form_id === form_id);

            if (step !== undefined) {

                setStep(step);
            }

        } 

        if (card_id !== undefined && card_id !== undefined) {

            //TODO: Get the card object

        }

    }, [flow, form_id, card_id, open]);

    useEffect(() => {

        if (open) {
            getFormApi();
        }

    }, [open, getFormApi]);

    useEffect(() => {

        if (formRefConfig.current !== null && form !== undefined) {

            const formConfig: FormPublicProps = {
                form_title: form.title,
                form_description: form.description,
                form_color_brand: form.color_brand,
                form_button_text: form.button_text,
                form_color_background: form.color_background,
                form_show_another_answer: form.show_another_answer,
                form_sent_message: form.sent_message
            }

            formRefConfig.current.setData(formConfig);

        }

    }, [form]);

    useEffect(() => {

        if (form !== undefined && form.public_hash !== undefined && form.public_hash !== null) {
            setLinkForm(URL_FORM_PUBLIC + "/public/" + form?.public_hash);
        } else {
            setLinkForm(undefined);
        }

    }, [form]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            fullScreen={true}
            open={open}
            style={{ zIndex: '999999' }}
        >

            <AppBarHeaderContainer>
                <AppBarHeader
                    title={!isFromStep ? flow.name : flow.name + " [" + step?.name + "]"}
                    icon={FaTag}
                    iconDynamic={!isFromStep ? flow.icon as IconList : step?.icon as IconList}
                    iconDynamicColor={!isFromStep ? flow.color : step?.color}
                    badgeInformation={!isFromStep ? "Formulário Público" : "Formulário Público da Etapa"}
                >

                    <ButtonSave
                        className="new-item-btn"
                        type="button"
                        color="#f23b5c"
                        margin="0px 15px 0px 0px"
                        onClick={() => {
                            postFormApi(true);
                        }}
                        isLoading={loadingSaving}
                    >
                        Salvar
                    </ButtonSave>

                    <ButtonClose type="button" onClick={pendingSaving ? () => setOpenCloseConfirm(true) : onClose}>
                        <AiOutlineClose />
                    </ButtonClose>

                </AppBarHeader>
            </AppBarHeaderContainer>

            <DialogAction
                open={openCloseConfirm}
                message={"Você tem certeza que deseja sair sem salvar?"}
                actions_items={[
                    "Perda das alterações realizadas no formulário público"
                ]}
                disclaimer={"Lembrando que após a confirmação não será mais possível recuperar os dados referentes às alterações!"}
                onSubmmit={handleClose}
                onClose={() => setOpenCloseConfirm(false)}
                type={1}
            />

            <FormPubliConfigBar
                isFromStep={isFromStep}
                copiedLink={copiedLink}
                isLoading={loadingSaving}
                activePublicForm={activePublicForm}
                formLink={linkForm}
                setActivePublicForm={handleChangeStatus}
                handleOpenConfig={() => setOpenConfig(!openConfig)}
                handleCopyLink={handleCopyClick}
            />

            <ContainerConfig>
                <ContainerConfigLeft isSplited={openConfig}>

                    {!loading && !firstLoad && form !== undefined && (
                        <PublicForm
                            flow_id={flow.id_flow}
                            form={form}
                            isConfig={true}
                        />
                    )}
                </ContainerConfigLeft>
                <ContainerConfigRight isSplited={openConfig}>
                    <ContainerConfigForm>
                        <ContainerConfigRightHeader>

                            <ContainerCloseButton>
                                <ButtonClose type="button" onClick={() => setOpenConfig(false)}>
                                    <BsArrowBarRight />
                                </ButtonClose>
                            </ContainerCloseButton>

                            <ContainerConfigRightHeaderTitle>
                                <h3>Configuração</h3>
                                <span>Configure e personalize a exibição do formulário público</span>
                            </ContainerConfigRightHeaderTitle>

                            <ContainerConfigRightHeaderSearch>
                            </ContainerConfigRightHeaderSearch>
                        </ContainerConfigRightHeader>

                        <LogoField>
                            <LogoFieldTitle>
                                <h3>Status do Formulário</h3>
                                <span>Defina se o formulário está público ou não</span>
                            </LogoFieldTitle>
                            <LogoFieldContent>
                                <ButtonActive type="button"
                                    onClick={() => handleChangeStatus(!activePublicForm)}
                                    style={{ fontSize: '13px', marginLeft: '-2px' }}
                                >
                                    {activePublicForm ? <BsToggleOn style={{ fontSize: '18px' }} color="#61bd4f" /> : <BsToggleOff style={{ fontSize: '18px' }} />}
                                    {activePublicForm ? <div>Público</div> : <div>Não Público</div>}
                                </ButtonActive>
                            </LogoFieldContent>
                        </LogoField>


                        <DividerMenu style={{ marginBottom: '0px' }} />

                        <LogoField>
                            <LogoFieldTitle>
                                <h3>Logo</h3>
                                <span>Personalize a logo do formulário</span>
                            </LogoFieldTitle>
                            <LogoFieldContent>
                                <LogoFieldImage onClick={() => setOpenUploadLogo(true)}>
                                    {form !== undefined && form.logo_url !== undefined && form.logo_url !== null ?
                                        <img src={form.logo_url} alt="Imagem de capa" /> :
                                        <BsCardImage />
                                    }
                                </LogoFieldImage>
                                <LogoFieldButtons>
                                    <LogoFieldBtnUpload type="button" onClick={() => setOpenUploadLogo(true)}>Carregar Imagem</LogoFieldBtnUpload>
                                    {form !== undefined && form.logo_url !== undefined && form.logo_url !== null && (
                                        <LogoFieldBtnRemove type="button" onClick={() => handleRemoveLogo()}>Remover</LogoFieldBtnRemove>
                                    )}
                                </LogoFieldButtons>
                            </LogoFieldContent>
                        </LogoField>

                        {openUploadLogo && (
                            <UploadImageForm
                                open={openUploadLogo}
                                onClose={handleUploadLogoClose}
                                title="Carregue o seu logo"
                                width={180}
                                height={180}
                                scaleImage={0.2}
                            />
                        )}

                        <LogoField>
                            <LogoFieldTitle>
                                <h3>Imagem de capa</h3>
                                <span>Insira uma imagem para a capa do formulário</span>
                            </LogoFieldTitle>
                            <LogoFieldContent>
                                <LogoFieldImage style={{ width: '200px', height: '60px' }} onClick={() => setOpenUploadCover(true)}>
                                    {form !== undefined && form.cover_url !== undefined && form.cover_url !== null ?
                                        <img src={form.cover_url} alt="Imagem de capa" /> :
                                        <BsCardImage />
                                    }
                                </LogoFieldImage>
                                <LogoFieldButtons>
                                    <LogoFieldBtnUpload type="button" onClick={() => setOpenUploadCover(true)} title="Carregue a sua imagem">Carregar Imagem</LogoFieldBtnUpload>
                                    {form !== undefined && form.cover_url !== undefined && form.cover_url !== null && (
                                        <LogoFieldBtnRemove type="button" onClick={() => handleRemoveCover()}>Remover</LogoFieldBtnRemove>
                                    )}
                                </LogoFieldButtons>
                            </LogoFieldContent>
                        </LogoField>

                        {openUploadCover && (
                            <UploadImageForm
                                open={openUploadCover}
                                onClose={handleUploadCoverClose}
                                width={800}
                                height={240}
                                scaleImage={0.3}
                            />
                        )}

                        <DividerMenu />

                        <ContainerForm>
                            <FormBuilder
                                id="form"
                                formRef={formRefConfig}
                                fields={!isFromStep ? fieldsFormInitConfig : fieldsFormStepConfig}
                                initialValue={defaultValueForm}
                                handleSubmit={handleSubmitForm}
                                handleBlur={() => handleSubmitConfig()}
                                hideContainer={true}
                            />
                        </ContainerForm>

                    </ContainerConfigForm>
                </ContainerConfigRight>
            </ContainerConfig>

        </Dialog >
    );

}

export default FormPublicConfig;