import styled, { css } from "styled-components";

import Grid from '@material-ui/core/Grid';
import Button from "../../components/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

interface ContainerColorProps {
  type: number;
}

export const Container = styled.div`

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 25px;


`;

export const DialogMain = styled(Dialog)`
  z-index: 999999 !important
`;

export const Content = styled(DialogContent)`
padding: 0px !important;
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: center;
    height: auto;
    width: auto;
    /*background-color: white;*/
`;

export const ContainerImage = styled.div`
    margin: 30px;

    img {
      height: 100px;
      width: 100px;
    }
`;


export const ContainerText = styled.div`
    margin: 20px;
    max-width: 250px;
    overflow-wrap: anywhere;
    text-align: center;
`;

export const ContainerColor = styled.div<ContainerColorProps>`
  height: 15px;
  width: 100%;

  //Warning
  ${props => props.type === 1 && css`
    background-color: #e24d4b;
  `}

  //Sucess
  ${props => props.type === 2 && css`
    background-color: #34ba7c;
  `}

  //Error
  ${props => props.type === 3 && css`
    background-color: #e24d4b;
  `}
`;


export const DialogFooter = styled(DialogActions)`
  text-align: left;
  border-top: 1px solid #eef2f8;
  padding: 15px !important;
`;

export const ButtonConfirm = styled(Button)`
  height: 36px;
  width: 120px;
  margin: 0px 0px 0px 0px;
`;

export const ButtonCancel = styled(Button)`
  height: 36px;
  width: 100px;
  margin: 0px 20px 0px 0px;
  color: gray;
`;