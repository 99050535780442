import React, { useCallback, useRef, useState } from "react";
import * as Yup from 'yup';

import {
    Content,
    ContentBody,
    InviteTitle,
    InviteDescription,
    InviteContainer,
    InviteContainerTitle,
    InviteInput,
    InviteContainerButton,
    InviteButtonSkip,
    InviteButton,
    Animation,
    ContainerAnimation,
    ConfettiArea,
    CloseButton,
    BoxUserType,
    ContainerAcessType,
    DisclaimerLicense
} from "./style";

import { Dialog } from '@material-ui/core';
import { FiMail } from "react-icons/fi";
import { FormHandles } from "@unform/core";
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';
import { Form } from "@unform/web";
import { useMediaQuery } from "react-responsive";
import api from "../../services/api";
import confetti from '../../assets/confetti.png';
import Lottie from "lottie-web";
import { getFeatureControlByPlan } from "../../middlewares/FeatureControl";
import { useAuth } from "../../hooks/auth";

interface InviteTeamProps {
    open: boolean;
    onClose: () => void;
}

interface EmailInputProps {
    id: number,
    email: string
    name?: string,
    type?: string
}

interface InviteTeamApiProps {
    emails: EmailInputProps[],
    flow_id?: number,
    register_id?: number,
    workspace_id?: number,
}

const defaultEmailInput: EmailInputProps[] = [
    {
        id: 1,
        email: '',
        type: 'M'
    },
    {
        id: 2,
        email: '',
        type: 'M'
    },
    {
        id: 3,
        email: '',
        type: 'M'
    },
    {
        id: 4,
        email: '',
        type: 'M'
    },
]

const InviteTeam: React.FC<InviteTeamProps> = ({ onClose, open }) => {

    const { addToast } = useToast();
    const { user } = useAuth();
    
    const formRef = useRef<FormHandles>(null);
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const lottieContainer = useRef<HTMLDivElement>(null);

    const [members, setMembers] = useState<EmailInputProps[]>(defaultEmailInput);
    const [membersInvited, setMembersInvited] = useState<EmailInputProps[]>(defaultEmailInput);
    const [loadingInviting, setLoadingInviting] = useState<boolean>(false);
    const [ended, setEnded] = useState<boolean>(false);
    

    const handleSubmit = useCallback(async (data: string[]) => {

        try {

            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                1: Yup.string().email('O e-mail inserido é inválido! Verifique ou tente outro e-mail').optional(),
                2: Yup.string().email('O e-mail inserido é inválido! Verifique ou tente outro e-mail').optional(),
                3: Yup.string().email('O e-mail inserido é inválido! Verifique ou tente outro e-mail').optional(),
                4: Yup.string().email('O e-mail inserido é inválido! Verifique ou tente outro e-mail').optional(),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            let newApiObject: EmailInputProps[] = [];

            if (data !== undefined) {

                setLoadingInviting(true);

                if (data[1] !== undefined && data[1] !== null && data[1] !== "") {
                    newApiObject.push({ id: 1, email: data[1], name: data[1].substr(0, data[1].indexOf("@")) });
                }
                if (data[2] !== undefined && data[2] !== null && data[2] !== "") {
                    newApiObject.push({ id: 2, email: data[2], name: data[2].substr(0, data[2].indexOf("@")) });
                }
                if (data[3] !== undefined && data[3] !== null && data[3] !== "") {
                    newApiObject.push({ id: 3, email: data[3], name: data[3].substr(0, data[3].indexOf("@")) });
                }
                if (data[4] !== undefined && data[4] !== null && data[4] !== "") {
                    newApiObject.push({ id: 4, email: data[4], name: data[4].substr(0, data[4].indexOf("@")) });
                }

                //Add the type in newApiObject from members
                newApiObject = newApiObject.map((item) => {
                    let member = members.find(member => member.id === item.id);
                    if (member !== undefined) {
                        item.type = member.type;
                    }
                    return item;
                });

                const apiObj: InviteTeamApiProps = {
                    emails: newApiObject
                }

                setMembersInvited(newApiObject);

                if (newApiObject !== undefined && newApiObject.length > 0) {
                    //Call api to invite
                    await api
                        .post('/invite-user/multiple', apiObj)
                        .then(response => {

                            setEnded(true);

                            if (lottieContainer.current) {
                                Lottie.loadAnimation({
                                    container: lottieContainer.current,
                                    renderer: 'svg',
                                    loop: true,
                                    autoplay: true,
                                    animationData: require('../../assets/lottie/confetti.json')
                                })
                            }

                            setLoadingInviting(false);
                        }).catch(error => {
                            setLoadingInviting(false);
                            addToast({
                                type: 'error',
                                title: 'Erro ao convidar algum dos usuários',
                                description: 'Verifique o e-mail digitado e convide novamente!',
                            });
                        });

                } else {
                    setLoadingInviting(false);
                    addToast({
                        type: 'error',
                        title: 'Não foi possível convidar os usuários!',
                        description: 'Preencha pelo menos um e-mail para convidar!',
                    });
                }

            }

        } catch (err) {

            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);
                formRef.current?.setErrors(errors);
            }

            console.log(err);
        }
    }, [addToast, members]);

    const handleChangeTypeUserInvited = useCallback((id: number, type: string) => {

        let newMembers = members.map(member => {
            if (member.id === id) {
                return { ...member, type: type }
            }
            return member;
        });

        setMembers(newMembers);

    }, [members]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            fullScreen={isMobile}
            style={{ zIndex: '99999' }}
        >
            <Content>
                <ContentBody container>

                    {!ended ?
                        <>
                            <InviteTitle>
                                Convide mais pessoas para a sua equipe 🥳
                            </InviteTitle>

                            <InviteDescription style={{ marginBottom: '40px' }}>
                                Você está evoluindo rápido! Para que você possa aproveitar o máximo do <b>Cange</b>, o ideal é que você convide mais pessoas para a sua equipe
                            </InviteDescription>

                            <InviteContainer>
                                <Form ref={formRef} onSubmit={handleSubmit}>
                                    <InviteContainerTitle>
                                        Insira o e-mail das pessoas que você deseja convidar:
                                    </InviteContainerTitle>

                                    {members.map((member, index) => (
                                        <ContainerAcessType key={index}>
                                            <InviteInput key={member.id} name={String(member.id)} icon={FiMail} placeholder="Insira um e-mail de um colega" />
                                            <BoxUserType key={index} name="select" value={member.type} onChange={e => handleChangeTypeUserInvited(member.id, e.target.value)}>
                                                <option value="A">Administrador</option>
                                                <option value="M">Membro</option>
                                                <option value="I">Membro Individual</option>
                                                <option value="S">Solicitante</option>
                                                <option value="V">Visualizador</option>
                                            </BoxUserType>
                                        </ContainerAcessType>
                                    ))}

                                    {getFeatureControlByPlan(8, user.company) && (
                                        <DisclaimerLicense>Será adicionado automaticamente as licenças para estes usuários no seu plano</DisclaimerLicense>
                                    )}

                                    <InviteContainerButton>
                                        <InviteButtonSkip onClick={() => onClose()}>
                                            Fazer isso mais tarde
                                        </InviteButtonSkip>
                                        <InviteButton type="submit" isLoading={loadingInviting}>
                                            Enviar convites
                                        </InviteButton>
                                    </InviteContainerButton>
                                </Form>
                            </InviteContainer>
                        </> :
                        <ContainerAnimation>
                            <ConfettiArea style={{ position: 'relative' }}>
                                <Animation className="lottieContainer" ref={lottieContainer} />
                                <img src={confetti} alt="Confetti" />
                                <h1>Parabéns! Você convidou <b style={{ color: "#f23b5d" }}>{membersInvited.filter((e) => e.email !== undefined && e.email.trim() !== "").length} {membersInvited.filter((e) => e.email !== undefined && e.email.trim() !== "").length > 1 ? "pessoas" : "pessoa"}</b> para <b style={{ color: "#f23b5d" }}>colaborar</b> com você!</h1>
                                <h3 style={{ color: "gray" }}>Já enviamos um e-mail com as instruções para os seus convidados, logo eles estarão aqui com você</h3>
                            </ConfettiArea>
                            <h3>Aproveite e deixe tudo configurado para quando eles chegarem, se tiver alguma dúvida, pode <a href="https://www.cange.me/contato" target="_blank" style={{ textDecoration: 'none', color: "#f23b5d" }} rel="noopener noreferrer">clicar aqui</a> e nos enviar uma mensagem 😊</h3>
                            <CloseButton onClick={() => {
                                setEnded(false);
                                onClose();
                            }}>
                                Fechar
                            </CloseButton>
                        </ContainerAnimation>
                    }

                </ContentBody>
            </Content>
        </Dialog >
    );

}

export default InviteTeam;