import styled from "styled-components";


export const Item = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;    
    border: 1px solid #f23b5d;
    border-radius: 10px;
    min-height: auto;
    margin-top: 0px;
    cursor: pointer;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
    transition: transform 0.3s ease;
    background-color: white;

    :hover {
        transform: scale(1.01);
    }
`;

export const ItemLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 5px;
`;

export const ItemTitle = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    font-weight: 500;    
`;

export const ItemIcon = styled.div`
    padding: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f23b5d;
    border-radius: 10px;
    color: white;
    margin-right: 15px;
`;

export const ItemDescription = styled.div`
    font-size: 13px;
    margin-top: 15px;
    color: gray;
`;

export const ItemRight = styled.div`
    position: absolute;
    right: 20px;
`;

export const ItemBadgeStatus = styled.div`
    background-color: gray;
    padding: 5px 10px;
    color: white;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    min-width: 80px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        margin-right: 5px;
        margin-top: 1px;
    }
`;