import styled from "styled-components";

export const Container = styled.div`

`;

export const ContainerImage = styled.div`
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-height: 350px;
        margin-bottom: 50px;
    }
`;

export const ContainerTitle = styled.div`
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const ContainerSubTitle = styled.div`
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    color: gray;
    text-align: center;
`;
