import React from "react";
import { IconPickerItem } from "react-fa-icon-picker";

import {
    BtnTemplate,
    Container,
    FlowNewContainerBottom,
    FlowNewItemButton,
    FlowNewItemContainer,
    TemplateDescription
} from "./style";

interface TemplateCardBlankProps {
    onClick: () => Promise<void>;
}

const TemplateCardBlank: React.FC<TemplateCardBlankProps> = ({ onClick }) => {

    return (
        <Container>
            <BtnTemplate onClick={onClick}>
                <FlowNewItemContainer>
                    <FlowNewItemButton>
                        <IconPickerItem
                            icon={'FaPlus'}
                            color={"white"}
                        />
                    </FlowNewItemButton>
                </FlowNewItemContainer>
                <FlowNewContainerBottom>
                    <h2>
                        Criar um novo fluxo
                    </h2>
                    <TemplateDescription>
                        Feito para usuários sem experiência com desenvolvimento, em poucos passos é possível construir um novo fluxo
                    </TemplateDescription>
                </FlowNewContainerBottom>
            </BtnTemplate>
        </Container>
    );

}

export default TemplateCardBlank;