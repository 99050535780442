export default function getFormatLabel(
    value: string,
    limit: number,
): string {

    let newValue = value; 

    if (value.length > limit) {

        newValue = value.substring(0, limit) + "...";

    }

    return newValue;

}