import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaPen } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { Link } from "react-router-dom";

import Button from "../../components/Button";
import FormAnswerRecord from "../../components/FormAnswerRecord";
import api from '../../services/api';
import getAccessControl from "../../middlewares/AccessControl";
import {
    BtnEditForm,
    ContainerItems,
    Content,
    ContentBody,
    DialogContainer,
    DialogFooter,
    DialogTop,
    DialogTopCenter,
    DialogTopLeft,
    DialogTopRight,
    IconHeader,
    ContainerBody,
    ContainerContent
} from "./styles";
import { DialogFooterLeft } from "../NewField/styles";
import { Form } from "../../interfaces/Form";
import { FormAnswer } from "../../interfaces/FormAnswer";
import { useToast } from '../../hooks/toast';
import { NavigationBarItemType } from "../../components/AppBarHeader";
import { FormAnswerField } from "../../interfaces/FormAnswerField";
import { TbCirclesRelation } from "react-icons/tb";
import { IoMdListBox } from "react-icons/io";
import AppBarHeaderPocket from "../../components/AppBarHeaderPocket";
import RelationshipTab from "./RelationshipTab";
import DialogAction from "../DialogAction";

export interface RecordProps {
    open: boolean;
    selectedValue: FormAnswer;
    id_register?: number;
    form: Form;
    typeUser: string;
    name?: string;
    color?: string;
    icon?: string;
    hash?: string;
    onClose: () => void;
}

export interface CtrlEditProps {
    id_field: number;
    open: boolean;
}

const Record: React.FC<RecordProps> = ({ onClose, open, selectedValue, name, color, icon, form, hash, typeUser, id_register }) => {

    const { addToast } = useToast();

    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [openDialogDeleteWithRelationship, setOpenDialogDeleteWithRelationship] = useState(false);
    const [navBarSelected, setNavBarSelected] = useState(1);
    const [relationshipItems, setRelationshipItems] = useState<FormAnswerField[]>();
    const [loadingDelete, setLoadingDelete] = useState(false);

    const navBarItems: NavigationBarItemType[] = [
        {
            index: 1,
            description: "Informações",
            icon: IoMdListBox,
            active: navBarSelected === 1 ? true : false,
            onClick: () => setNavBarSelected(1)
        },
        {
            index: 2,
            description: "Conexões",
            icon: TbCirclesRelation,
            active: navBarSelected === 2 ? true : false,
            onClick: () => setNavBarSelected(2),
            badgeItems: relationshipItems !== undefined ? relationshipItems.length : undefined,
            hideItem: relationshipItems !== undefined && relationshipItems.length > 0 ? false : true
        }
    ]

    const dlgDelHandleSubmmit = useCallback(async (withRelationship?: boolean) => {

        if (selectedValue !== undefined) {
            setLoadingDelete(true);
            await api
                .delete('/form/answer', {
                    params: {
                        id_form_answer: selectedValue.id_form_answer,
                        withRelationship: withRelationship
                    }
                })
                .then(response => {

                    setOpenDialogDelete(false);
                    onClose();

                    addToast({
                        type: 'success',
                        title: 'Registro deletado com sucesso!',
                        description: 'O seu registro foi deletado com sucesso!'
                    });
                    setLoadingDelete(false);
                }).catch(error => {
                    setLoadingDelete(false);
                    const errMessage: string = error.response.data.message;
                    addToast({
                        type: 'error',
                        title: 'Erro ao deletar o Registro',
                        description: errMessage,
                    });
                });
        }

    }, [addToast, selectedValue, onClose]);

    const handleClose = useCallback(async () => {

        onClose();

    }, [onClose]);

    const getApiRelationship = useCallback(async () => {

        if (selectedValue !== undefined && selectedValue.id_form_answer !== undefined && id_register !== undefined) {

            api.get(`/register/relationship`, {
                params: {
                    id_register: id_register,
                    id_form_answer: selectedValue.id_form_answer
                }
            }).then(response => {
                if (response.data !== null) {

                    let apiRet: FormAnswerField[] = response.data.formsAnswerFields;

                    setRelationshipItems(apiRet);

                }
            });

        }

    }, [selectedValue, id_register]);

    useEffect(() => {

    }, [open]);

    useEffect(() => {

        //Get the Relationship
        getApiRelationship();

    }, [getApiRelationship]);

    return (
        <DialogContainer
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={color !== undefined ? color : "gray"}>
                        <div>
                            <IconPickerItem
                                icon={icon as IconList}
                                color={color}
                            />
                        </div>
                    </IconHeader>
                    <h1>
                        {name}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter>
                    {getAccessControl(43, typeUser) ?
                        hash !== undefined ?
                            <Link to={'/register/2/' + hash + "/edit"} style={{ textDecoration: 'none' }}>
                                <BtnEditForm>
                                    <FaPen />
                                    <h3>
                                        Editar campos
                                    </h3>
                                </BtnEditForm>
                            </Link> :
                            <></> : <></>
                    }
                </DialogTopCenter>

                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>

            <ContainerBody>

                <AppBarHeaderPocket
                    navBarItems={navBarItems}
                    typeUser={typeUser}
                />

                <ContainerContent>
                    {navBarSelected === 1 ?
                        <Content>
                            <ContentBody>
                                <ContainerItems>
                                    <FormAnswerRecord
                                        selectedValue={selectedValue}
                                        form={form}
                                        typeUser={typeUser}
                                        hideContainer={true}
                                        register_id={id_register}
                                    />
                                </ContainerItems>
                            </ContentBody>
                        </Content> :
                        navBarSelected === 2 ?
                            <Content>
                                <RelationshipTab
                                    id_form_answer={selectedValue.id_form_answer}
                                    register_id={id_register}
                                    totConections={relationshipItems !== undefined ? relationshipItems.length : 0}
                                />
                            </Content> :
                            <></>
                    }
                </ContainerContent>
            </ContainerBody>

            {getAccessControl(42, typeUser) ?
                <DialogFooter>
                    <DialogFooterLeft>
                        <Button onClick={() => {
                            if (relationshipItems !== undefined && relationshipItems.length > 0) {
                                setOpenDialogDeleteWithRelationship(!openDialogDeleteWithRelationship)
                            } else {
                                setOpenDialogDelete(!openDialogDelete)
                            }
                        }} icon={FiTrash2} type="button" color="#e24d4b" height="36px" margin="0px 0px 0px 0px" style={{ width: '130px' }}>Excluir</Button>
                        <DialogAction
                            open={openDialogDelete}
                            message={"Você realmente deseja excluir este registro?"}
                            actions_items={[
                                "Exclusão do registro atual;",
                                "Exclusão de suas referências em outros cadastros e fluxos;"
                            ]}
                            disclaimer={"Lembrando que esta ação não poderá ser revertida e você perderá todos os dados deste registro"}
                            onClose={() => setOpenDialogDelete(false)}
                            onSubmmit={dlgDelHandleSubmmit}
                            isLoading={loadingDelete}
                            type={1}
                        />

                        <DialogAction
                            open={openDialogDeleteWithRelationship}
                            message={"Existem " + relationshipItems?.length + " conexões com este registro. Você realmente deseja excluir este registro e todas as suas conexões?"}
                            actions_items={[
                                "Exclusão dos " + relationshipItems?.length + " registros conectados (cartões e registros);",
                                "Exclusão do registro atual;",
                                "Exclusão de suas referências em outros cadastros e fluxos;"
                            ]}
                            disclaimer={"Lembrando que esta ação não poderá ser revertida e você perderá todos os dados deste registro"}
                            onClose={() => setOpenDialogDeleteWithRelationship(false)}
                            onSubmmit={() => dlgDelHandleSubmmit(true)}
                            isLoading={loadingDelete}
                            type={1}
                        />
                    </DialogFooterLeft>
                </DialogFooter> : <></>
            }
        </DialogContainer >
    );
};

export default Record;

