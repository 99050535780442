import { Animation, Container, ContainerLoader, IconContainerLeft, IconContainerRight } from "./styles";
import { IconList, IconPickerItem } from 'react-fa-icon-picker'
import React, { ButtonHTMLAttributes, useEffect, useRef } from "react";

import { IconBaseProps } from "react-icons";
import lottie from 'lottie-web';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    color?: string;
    height?: string;
    margin?: string;
    icon?: React.ComponentType<IconBaseProps>;
    icon_side?: string; //left - right
    color_text?: string;
    iconDynamic?: IconList;
    iconDynamicColor?: string;
    children?: React.ReactNode;
    isLoading?: boolean;
    tooltip?: string;
}

const Button: React.FC<ButtonProps> = ({ color, height, margin, icon: Icon, children, icon_side, color_text, iconDynamic, iconDynamicColor, isLoading, tooltip, ...rest }) => {

    const lottieContainer = useRef<HTMLDivElement>(null);

    if (icon_side === undefined) {
        icon_side = "left";
    }

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../assets/lottie/loader-circle-white.json')
            })
        }
    }, [isLoading]);

    return (
        <Container type="button" title={tooltip} color={color} height={height} margin={margin} color_text={color_text} disabled={isLoading} {...rest}>
            {iconDynamic !== undefined || Icon ?
                isLoading !== undefined && isLoading ?
                    <></> :
                    <IconContainerLeft>
                        {iconDynamic === undefined ?
                            <>
                                {Icon && icon_side === "left" &&
                                    <Icon size="20" />
                                }
                            </> :
                            <IconPickerItem
                                icon={iconDynamic}
                                color={iconDynamicColor}
                            />
                        }
                    </IconContainerLeft> :
                <></>
            }
            {isLoading !== undefined && isLoading ?
                <ContainerLoader>
                    <Animation className="lottieContainer" ref={lottieContainer} />
                </ContainerLoader> :
                children
            }
            {
                Icon && icon_side === "right" ?
                    isLoading !== undefined && isLoading ?
                        <></> :
                        <IconContainerRight>
                            {iconDynamic === undefined ?
                                <Icon size="20" /> :
                                <IconPickerItem
                                    icon={iconDynamic}
                                    color={iconDynamicColor}
                                />
                            }
                        </IconContainerRight> :
                    <></>
            }
        </Container >
    );
};

export default Button;