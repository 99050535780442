import React, { useEffect, useState } from "react";

import {
    Container,
    CopyBtn
} from "./style";
import { TbCheck, TbCopy } from "react-icons/tb";

interface LinkPreviewProps {
    link: string;
}
const LinkPreview: React.FC<LinkPreviewProps> = ({ link }) => {

    const [copied, setCopied] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(link);

        setCopied(true);
    };

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, 2000);
        }
    }, [copied]);

    return (
        <Container>            
            <CopyBtn className="btn-copy-link" onClick={() => handleCopyClick()}>
                {!copied ?
                    <>
                        <TbCopy />
                        <span>
                            Copiar
                        </span>
                    </> :
                    <>
                        <TbCheck />
                        <span>
                            Copiado
                        </span>
                    </>
                }

            </CopyBtn>
            <a href={link} target="_blank" rel="noreferrer">{link}</a>
        </Container>
    );

}

export default LinkPreview;