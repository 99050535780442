import {
    BtnTemplate,
    Container,
    FlowNewContainerBottom,
    FlowNewItemButton,
    FlowNewItemContainer,
    TemplateDescription
} from "./style";

import { FaRegSadTear } from "react-icons/fa";
import React from "react";

const TemplateCardRequest: React.FC = () => {

    return (
        <a href="https://www.cange.me/contato" target="_blank" style={{ textDecoration: 'none'}} rel="noopener noreferrer">
        <Container>
            <BtnTemplate >
                <FlowNewItemContainer>
                    <FlowNewItemButton>
                        <FaRegSadTear/>
                    </FlowNewItemButton>
                </FlowNewItemContainer>
                <FlowNewContainerBottom>
                    <h2>
                        Não encontrou o seu template?
                    </h2>
                    <TemplateDescription>
                        Clique aqui e nos envie qual template você gostaria de ver por aqui, ficaremos felizes em escutar você 
                    </TemplateDescription>
                </FlowNewContainerBottom>
            </BtnTemplate>
        </Container>
        </a>
    );

}

export default TemplateCardRequest;