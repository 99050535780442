import styled, { keyframes } from 'styled-components';

import background from '../../assets/background_gradient.png';

export const Container = styled.div`
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;    
    justify-content: center;
    background-color: #f6f8f9;

    flex: 1;
    background: url(${background}) no-repeat center;
    background-size: cover;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 25px;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);

    width: 100%;
    max-width: 550px;
    background-color: white;

    @media(max-width: 800px) {
        height: 100%;
        border-radius: 0px;
    }


    img {
        margin-bottom: 35px;        
        height: 72px;
        width: 370px;
    }

`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    } 
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const AnimationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: ${appearFromLeft} 1s;

    form {
        margin: 80px 0;
        //min-width: 400px;
        text-align: center;

        padding: 0px 10%;
        width: 100%;

        h1 {
            color: #717171;
            margin-bottom: 35px;
        }    

        button {
            margin-top: 40px;
            background-color: rgba(251,50,92,1);

            :hover {
                background: linear-gradient( 90deg ,rgba(255,140,47,1) 25%,rgba(251,50,92,1) 50%,rgba(147,55,237,1) 75%);
            }
        }

        div {

            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
`;

export const NoAccount = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 550px;

    padding-top: 35px;
    color: #6f7782;
    font-size: 17px;

`;

export const HelpLogin = styled.div`
    position: sticky;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 550px;
    margin-top: 20px !important;
    font-size: 14px;
    font-weight: 500;
    color: #9d37ee;

    :hover {
        text-decoration: underline;
    }

`;

export const BackGround = styled.div`
    flex: 1;
    background-color: #f6f8f9;
    background-size: cover;
`;