import styled, { css } from "styled-components";

interface DropContainerProps {
    isDragActive: boolean;
    isDragReject: boolean;
}

interface UploadMessageProps {
    type: string;
}

export const Container = styled.div`

`;

export const DropContainer = styled.div<DropContainerProps>`

    border: 1px dashed #eef2f8;
    border-radius: 5px;
    cursor: pointer;
    max-height: 50px;
    display: flex;

    transition: 0.2s ease;

    ${props => props.isDragActive && css`
        border-color: "#78e5d5";
    `}

    ${props => props.isDragReject && css`
        border-color: "#e57878";
    `}

    :hover {

        border: 1px dashed #f23b5c;    

        p {

            color: #f23b5c;

            svg {
                color: #f23b5c;
            }

        }

    }

`;

export const UploadMessage = styled.p<UploadMessageProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 15px 0;    
    font-size: 14px;
    font-weight: 400;
    color: #4d4d4d;

    ${props => props.type === "default" && css`
        color: "#999";
    `}

    ${props => props.type === "error" && css`
        color: "#e57878";
    `}

    ${props => props.type === "sucess" && css`
        color: "#78e5d5";
    `}

    svg {
        height: 15px;
        width: 15px;
        color: #4d4d4d;
        margin-right: 5px;
        margin-left: 5px;
        min-width: 15px;
    }
`;