import {
    ApiBox,
    ApiBoxActions,
    ApiBoxDescription,
    ApiBoxIcon,
    ButtonDelete,
    ButtonNewToken,
    ContainerApi,
    ContainerApiHeader,
    ContainerFields,
    ContentList
} from "./style";
import React, { useCallback, useEffect, useState } from "react";

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { CompanyUser } from "../../../interfaces/CompanyUser";
import { IoIosApps } from "react-icons/io";
import { FaTrash } from "react-icons/fa";
import { IoAdd } from "react-icons/io5";
import AddNewToken from "./AddNewToken";
import DialogAction from "../../../dialogs/DialogAction";
import { getFeatureControlByPlan } from "../../../middlewares/FeatureControl";
import { useAuth } from "../../../hooks/auth";
import ButtonUpgrade from "../../../components/Fremium/ButtonUpgrade";

const AppUserConfig: React.FC = () => {

    const { addToast } = useToast();
    const { user } = useAuth();

    const [loading, setLoading] = useState(false);
    const [companyUsers, setCompanyUsers] = useState<CompanyUser[]>([]);
    const [openNewToken, setOpenNewToken] = useState(false);
    const [stateDelItem, setStateDelItem] = useState<{ open: boolean, index: number | undefined }>({ open: false, index: undefined });

    const getApiKey = useCallback(async () => {

        setLoading(true);

        api.get(`/user/apikey`, {})
            .then(response => {

                const retApis = response.data as CompanyUser[];

                if (retApis !== undefined && retApis.length > 0) {
                    setCompanyUsers(retApis);
                } else {
                    setCompanyUsers([]);
                }

                setLoading(false);
            }).catch(error => {
                console.log(error);
                setLoading(false);
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar as chaves de API',
                    description: 'Ocorreu um erro ao buscar a chave de API!'
                });
            });


    }, [addToast]);

    const deleteApiKey = useCallback(async (id_company_user?: number) => {

        if (id_company_user !== undefined) {

            api
                .delete(`/user/apikey`, {
                    params: {
                        id_company_user: id_company_user
                    }
                })
                .then(response => {

                    getApiKey();

                }).catch(error => {
                    console.log(error);
                    addToast({
                        type: 'error',
                        title: 'Erro ao excluir a chave de API',
                        description: 'Ocorreu um erro ao deletar a chave de API!'
                    });
                });

        }

    }, [addToast, getApiKey]);

    useEffect(() => {

        getApiKey();

    }, [getApiKey, openNewToken]);

    return (
        <ContentList>

            <ContainerFields item xs={12} md={6}>

                <ContainerApiHeader>
                    <h3>Tokens de acesso pessoal</h3>
                    <span>Os tokens de acesso pessoal foram criados para oferecer aos desenvolvedores uma maneira conveniente de integrar com outras plataformas e automatizar tarefas.</span>
                </ContainerApiHeader>

                <ContainerApi>

                    {loading && (
                        <ApiBox>
                            <ApiBoxIcon>
                                <IoIosApps />
                            </ApiBoxIcon>
                            <ApiBoxDescription>
                                Carregando...
                            </ApiBoxDescription>
                            <ApiBoxActions>
                            </ApiBoxActions>
                        </ApiBox>
                    )}

                    {companyUsers.map((companyUser, index) => {

                        return (
                            <ApiBox key={index}>
                                <ApiBoxIcon>
                                    <IoIosApps />
                                </ApiBoxIcon>
                                <ApiBoxDescription>
                                    {companyUser.name}
                                </ApiBoxDescription>
                                <ApiBoxActions>
                                    <ButtonDelete onClick={() => setStateDelItem({ open: true, index: companyUser.id_company_user })}>
                                        <FaTrash />
                                    </ButtonDelete>
                                </ApiBoxActions>
                            </ApiBox>
                        )
                    })}

                    <DialogAction
                        open={stateDelItem.open}
                        message={"Você tem certeza que deseja excluir este token de acesso?"}
                        actions_items={[
                            "Removerá todo o acesso que este token possui",
                            "Todos os aplicativos conectados com este token serão desconectados",
                        ]}
                        disclaimer={"Lembrando que esta ação não poderá ser revertida e você não poderá recuperar este token!"}
                        onClose={() => setStateDelItem({ open: false, index: undefined })}
                        onSubmmit={() => deleteApiKey(stateDelItem.index)}
                        type={1}
                    />

                </ContainerApi>

                {getFeatureControlByPlan(4, user.company) ?
                    <ButtonNewToken onClick={() => setOpenNewToken(!openNewToken)} style={companyUsers === undefined || companyUsers.length === 0 ? { marginTop: '-5px' } : {}}>
                        <IoAdd />
                        Criar novo token
                    </ButtonNewToken> :
                    <ButtonUpgrade
                        buttonText="Fazer o upgrade"
                    />
                }



                {openNewToken && (
                    <AddNewToken open={openNewToken} onClose={() => setOpenNewToken(false)} />
                )}

            </ContainerFields>

        </ContentList>
    );

}

export default AppUserConfig;