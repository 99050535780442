import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    ButtonAddCheckListItem,
    CLCheckbox,
    CLDeleteButton,
    CLDescription,
    CLDescriptionContainer,
    CLItem,
    ChecklistBody,
    ProgressBarTask,
    ProgressBarTaskLine,
    ProgressBarTaskLineInside,
    ProgressBarTaskPercent
} from "./style";
import { useField } from "@unform/core";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { CheckListItem } from "../../../../../interfaces/CheckListItem";
import ErrorDescription from "../../../../ErrorDescription";

interface CheckListFieldProps {
    name: string;
    variation?: string;
    onForceBlur?: () => void;
}

export interface inputItemLabelProps {
    id_field_option?: number;
    field_id?: number;
    value: string;
    label: string;
    order: string;
    hide?: string;
    old_id_field_option?: number;
}

const CheckListField: React.FC<CheckListFieldProps> = ({ name, variation, onForceBlur }) => {

    const { fieldName, registerField, error, defaultValue } = useField(name);

    const [items, setItems] = useState<CheckListItem[]>([]);
    const [percentage, setPercentage] = React.useState<number | undefined>(0);
    const [disabled] = useState(variation !== undefined && variation !== null && variation === '1');

    const [lastCheckListItemAdded, setLastCheckListItemAdded] = useState<CheckListItem>();
    const lastCheckListItemAddedRef = useRef<HTMLInputElement | null>();

    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | undefined>(undefined);

    const handleAddItem = useCallback(async () => {

        //Create the checkbox hash
        const dateNowTime = new Date().getTime();
        var hashLib = require('object-hash');

        let allowInsert = true;

        //Valid if the last item is empty or not
        if (items.length > 0) {
            let lastItem = items[items.length - 1];
            if (lastItem.description.trim() === '') {

                const element = document.getElementById(lastItem.hash);
                if (element) {
                    element.focus();
                }

                allowInsert = false;
            }
        }

        if (allowInsert) {
            const hashCheckListItem = hashLib(dateNowTime + name + items.length + 1);

            const newItem: CheckListItem = {
                hash: hashCheckListItem,
                description: '',
                checked: "N",
                index: items.length + 1,
            };

            setItems([...items, newItem]);

            setLastCheckListItemAdded(newItem);

        }

    }, [items, name]);


    const handleItemChange = useCallback(async (itemId: string, newName?: string, checked?: string) => {

        if (items !== undefined && items.length > 0) {
            const updatedItems = items.map((item) => {
                if (item.hash === itemId) {
                    if (newName !== undefined) {
                        item.description = newName;
                    } else {
                        item.checked = checked !== undefined ? checked : "N";
                    }
                }
                return item;
            });

            setItems(updatedItems);

            if (onForceBlur !== undefined && checked === undefined) {

                const SEARCH_DELAY = 1500; // 1 second delay

                clearTimeout(searchTimeout as NodeJS.Timeout);
                setSearchTimeout(
                    setTimeout(() => {
                        onForceBlur();
                    }, SEARCH_DELAY)
                );
                
            } else if (onForceBlur !== undefined) {
                onForceBlur();
            }
        }

    }, [items, onForceBlur, searchTimeout]);

    const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>, itemId?: string, checked?: string) => {

        if (event.key === "Enter" && (event.ctrlKey || event.metaKey) && itemId !== undefined) {
            handleItemChange(itemId, undefined, checked);
        } else if (event.key === "Enter") {
            handleAddItem();
        }

        event.stopPropagation();
    };

    const deleteCheckListItem = useCallback(async (checkListItem: CheckListItem) => {

        if (items !== undefined && items.length > 0) {
            const updatedItems = items.filter((item) => item.hash !== checkListItem.hash);

            setItems(updatedItems);
        }

    }, [items]);

    useEffect(() => {

        //Calculate the percentage of the checklist
        let percentage = 0;
        if (items !== undefined && items.length > 0) {
            const totalItemsChecked = items.filter((item) => item.checked === 'S').length;
            percentage = totalItemsChecked / items.length;
        }

        setPercentage(percentage);

    }, [items]);

    useEffect(() => {

        if (lastCheckListItemAddedRef.current !== null && lastCheckListItemAddedRef.current !== undefined) {
            lastCheckListItemAddedRef.current.focus();
        }

    }, [lastCheckListItemAdded, lastCheckListItemAdded?.dt_last_update]);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: lastCheckListItemAddedRef.current,
            getValue: () => {
                if (items !== undefined) {
                    let retItems = items.filter(item => item.description !== "");

                    //Transform the array to the correct format inputItemLabelProps
                    const newItems: inputItemLabelProps[] = retItems.map((item, index) => {

                        //Stringify the CheckList to send to the server as a string
                        const value = JSON.stringify(item);

                        return {
                            id_field_option: item.id_check_list_item,
                            value: String(index + 1),
                            label: value,
                            order: String(index),
                            checked: item.checked
                        };
                    });

                    if (newItems.length <= 0) {
                        return [];
                    } else {

                        //Order the array by order
                        newItems.sort((a, b) => {
                            if (a.order !== undefined && b.order !== undefined) {
                                return Number(a.order) - Number(b.order);
                            } else {
                                return 0;
                            }
                        });

                        return newItems;
                    }
                }
            },
            setValue: (refs, value) => {
                if (value !== undefined && value !== null) {

                    let newValue: inputItemLabelProps[] = value;

                    //Order the array by order
                    newValue.sort((a, b) => {
                        if (a.order !== undefined && b.order !== undefined) {
                            return Number(a.order) - Number(b.order);
                        } else {
                            return 0;
                        }
                    });

                    //Transform the array to the correct format CheckListItem
                    const newItems: CheckListItem[] = newValue.map((item, index) => {

                        //Get the item.value and parse it to a CheckListItem
                        const itemValue: CheckListItem = JSON.parse(item.label);

                        return {
                            id_check_list_item: item.id_field_option !== undefined ? item.id_field_option : 0,
                            hash: itemValue.hash,
                            description: itemValue.description,
                            index: index,
                            checked: itemValue.checked !== undefined ? itemValue.checked : "N"
                        };
                    });

                    setItems(newItems);
                }
            },
            clearValue: () => {
                setItems([]);
            }
        });
    }, [items, fieldName, registerField, setItems, defaultValue]);

    useEffect(() => {

        if (typeof defaultValue === "object") {

            const arrDefault = defaultValue as unknown as inputItemLabelProps[];

            let newArr: inputItemLabelProps[] = [];

            for (let index = 0; index < arrDefault.length; index++) {
                const element = arrDefault[index];

                if (element.label !== undefined) {

                    newArr.push({
                        id_field_option: element.id_field_option,
                        field_id: element.field_id,
                        value: element.value,
                        label: element.label,
                        order: element.order,
                        hide: element.hide,
                        old_id_field_option: element.old_id_field_option
                    });

                } else {

                    const element = arrDefault[index] as unknown as string;

                    newArr.push({
                        value: String(index + 1),
                        label: element,
                        order: "0"
                    });

                }

            }

            //Order the array by order
            newArr.sort((a, b) => {
                if (a.order !== undefined && b.order !== undefined) {
                    return Number(a.order) - Number(b.order);
                } else {
                    return 0;
                }
            });

            //Transform the array to the correct format CheckListItem
            const newItems: CheckListItem[] = newArr.map((item, index) => {

                //Get the item.value and parse it to a CheckListItem
                const itemValue: CheckListItem = JSON.parse(item.label);

                return {
                    id_check_list_item: item.id_field_option !== undefined ? item.id_field_option : 0,
                    hash: itemValue.hash,
                    description: itemValue.description,
                    index: index,
                    checked: itemValue.checked !== undefined ? itemValue.checked : "N"
                };
            });

            setItems(newItems);

        } else if (typeof defaultValue === "string") {

            const arrObjDefault = defaultValue as unknown as inputItemLabelProps;

            const arrDefault = [arrObjDefault] as inputItemLabelProps[];

            let newArr: inputItemLabelProps[] = [];

            for (let index = 0; index < arrDefault.length; index++) {
                const element = arrDefault[index];

                if (element.label !== undefined) {

                    newArr.push({
                        id_field_option: element.id_field_option,
                        field_id: element.field_id,
                        value: element.value,
                        label: element.label,
                        order: element.order,
                        hide: element.hide,
                        old_id_field_option: element.old_id_field_option
                    });

                } else {

                    const element = arrDefault[index] as unknown as string;

                    newArr.push({
                        value: String(index + 1),
                        label: element,
                        order: "0"
                    });

                }

            }

            //Order the array by order
            newArr.sort((a, b) => {
                if (a.order !== undefined && b.order !== undefined) {
                    return Number(a.order) - Number(b.order);
                } else {
                    return 0;
                }
            });

            //Transform the array to the correct format CheckListItem
            const newItems: CheckListItem[] = newArr.map((item, index) => {

                //Get the item.value and parse it to a CheckListItem
                const itemValue: CheckListItem = JSON.parse(item.label);

                return {
                    id_check_list_item: item.id_field_option !== undefined ? item.id_field_option : 0,
                    hash: itemValue.hash,
                    description: itemValue.description,
                    index: index,
                    checked: itemValue.checked !== undefined ? itemValue.checked : "N"
                };
            });

            setItems(newItems);

        }

    }, [defaultValue]);

    return (
        <>
            <ChecklistBody>

                <ProgressBarTask style={{ marginBottom: '10px' }}>
                    <ProgressBarTaskLine>
                        <ProgressBarTaskLineInside width={percentage !== undefined ? percentage * 100 : 0} />
                    </ProgressBarTaskLine>
                    <ProgressBarTaskPercent>{String(Math.round((Number(percentage) * 100)))}%</ProgressBarTaskPercent>
                </ProgressBarTask>

                {items && items.length > 0 ? items.sort((a, b) => a.index - b.index).map((item, index) => {
                    return (
                        <CLItem
                            key={index}
                        >

                            <CLCheckbox
                                onClick={() => {
                                    handleItemChange(item.hash, undefined, item.checked === undefined ? "S" : item.checked === "S" ? "N" : "S")
                                }}
                            >
                                {item.checked && item.checked === "S" ?
                                    <BsCheckCircleFill color="#61bd4f" size={'20px'} /> :
                                    <BsCircle color="#9e37ed" size={'20px'} />
                                }
                            </CLCheckbox>

                            <CLDescriptionContainer>
                                <CLDescription
                                    id={item.hash}
                                    className="input-description-cl-item"
                                    ref={(ref) => {
                                        //Set the ref inside of this object to use it later
                                        if (item.hash === lastCheckListItemAdded?.hash) {
                                            lastCheckListItemAddedRef.current = ref;
                                        }
                                    }}                                    
                                    key={index}
                                    placeholder="Digite aqui uma descrição para o novo item..."
                                    type="text"
                                    disabled={disabled}
                                    title={item.description}
                                    value={item.description}
                                    checked={item.checked === undefined ? false : item.checked === "S" ? true : false}
                                    onChange={(e) => {
                                        handleItemChange(item.hash, e.currentTarget.value);
                                        e.stopPropagation();
                                    }}
                                    onKeyUp={(e) => {
                                        handleEnterPress(e, item.hash, item.checked === undefined ? "S" : item.checked === "S" ? "N" : "S");
                                    }}
                                />
                            </CLDescriptionContainer>

                            {!disabled ?
                                <CLDeleteButton type="button" className="btn-delete-cl-item" onClick={() => deleteCheckListItem(item)}>
                                    <AiFillCloseCircle />
                                </CLDeleteButton> :
                                <></>
                            }

                        </CLItem>
                    )
                }) :
                    <></>
                }

                {!disabled ?
                    <ButtonAddCheckListItem type="button" onClick={(e) => {
                        e.stopPropagation();
                        handleAddItem();
                    }}>
                        <FaPlus />
                        Adicionar item
                    </ButtonAddCheckListItem> :
                    <></>
                }

            </ChecklistBody>
            {error && (<ErrorDescription title={error} />)}
        </>
    );

}

export default CheckListField;