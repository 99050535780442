import { Box } from "@material-ui/core";
import styled from "styled-components";

export const BoxContainer = styled(Box)`
    position: absolute;
    //top: auto;
    top: auto;
    z-index: 999;
    min-width: 250px;
    border-radius: 5px;    
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);    
    margin-right: 10px;
    padding: 15px 15px 10px 15px;
    right: 10px;
    margin-top: -10px;
    
    @media(max-width: 800px){
        left: 0px;
    }
`;

export const FilterContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 5px;
`;

export const SelectInput = styled.select`
    height: 35px;
    border-radius: 5px 0px 0px 5px;
    border-color: #808080bf;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 200px;
`;

export const SelectComparator = styled.select`
    height: 35px;
    border-radius: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: #a0a0a0;
    font-size: 14px;
    padding: 5px;
    color: black;
`;

export const DeleteButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #a0a0a0;
    border-radius: 5px;
    margin-left: 5px;

    height: 35px;
    width: 35px !important;
    margin-top: 0px !important;
    
    svg {
        width: 13px;
        height: 13px;
    }

    :hover {
        border: 1px solid #f23b5c;
        background-color: white;
        color: #f23b5c;
    }
`;

export const FilterBottom = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`;

export const AddNewFilter = styled.button`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: #f4f4f4;   
    border-radius: 5px;     

    svg {
        font-size: 13px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        opacity: 0.8;
    }
`;

export const CleanFilter = styled.button`
    position: absolute;
    right: 80px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    margin-top: 5px;

    border-radius: 5px;
    padding: 5px 15px;

    background-color: transparent;
    color: gray;

    svg {
        font-size: 11px;
        margin-right: 5px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
`;

export const GoFilter = styled.button`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    margin-top: 5px;

    position: absolute;
    right: -10px;
    background: #e9f0f7;
    border-radius: 5px;
    padding: 5px 15px;

    svg {
        font-size: 13px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
`;