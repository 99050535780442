import { DataProps } from "../..";
import EmptyData from "../../../EmptyState/EmptyData";
import React, { useEffect, useState } from "react";
import { ResponsiveFunnel } from "@nivo/funnel";
import { colorsElement } from "../../getColorsElement";
import getPercentualOfTotal from "../../getPercentualOfTotal";
import getChartFormatValue from "../../getChartFormatValue";

interface FunnelChartProps {
    dataElement?: DataProps[];
    type: "vertical" | "horizontal";
    show_time?: string;
    axis_y_field_id?: number;
    axis_x_field_id?: number;
    axis_y_field_type?: string;
    axis_x_field_type?: string;
    axis_y_field_variation?: string;
}

const dataDefault = [
    {
        id: "Etapa 1",
        label: "Etapa 1",
        value: 170,
        labelAux: "Auxxxxx"
    },
    {
        id: "Etapa 2",
        label: "Etapa 2",
        value: 150,
        labelAux: "Auxxxxx"
    },
    {
        id: "Etapa 3",
        label: "Etapa 3",
        value: 70,
        labelAux: "Auxxxxx"
    },
    {
        id: "Etapa 4",
        label: "Etapa 4",
        value: 50,
        labelAux: "Auxxxxx"
    },
    {
        id: "Etapa 5",
        label: "Etapa 5",
        value: 12,
        labelAux: "Auxxxxx"
    }
];

const FunnelChart: React.FC<FunnelChartProps> = ({ dataElement, axis_y_field_type, axis_y_field_id, show_time, type, axis_y_field_variation }) => {

    const [total, setTotal] = useState<number>(0);
    const [totalLabel, setTotalLabel] = useState<string>("");

    useEffect(() => {

        let newTotal = 0;

        if (dataElement !== undefined && dataElement !== null && dataElement.length > 0) {
            dataElement.map((v) => {
                if (v.value !== undefined) {
                    newTotal = newTotal + v.value;
                }
                return v;
            })

            if (axis_y_field_id === -2) {
                setTotal(Math.round(newTotal * 10) / 10);
            } else {
                setTotal(newTotal);
            }

            if (show_time !== undefined && axis_y_field_id === -2) {
                if (show_time === "day") {
                    setTotalLabel(" Dias");
                } else if (show_time === "hour") {
                    setTotalLabel(" Horas");
                } else if (show_time === "min") {
                    setTotalLabel(" Minutos");
                } else if (show_time === "sec") {
                    setTotalLabel(" Segundos");
                }
            } else {
                setTotalLabel("");
            }

        }

    }, [dataElement, axis_y_field_id, show_time]);

    return (
        <>
            {dataElement !== undefined && dataElement?.length > 0 ?
                <ResponsiveFunnel
                    data={dataElement !== undefined ? dataElement : dataDefault}
                    //Static Down
                    direction={type}
                    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                    colors={colorsElement}
                    valueFormat={value => (getChartFormatValue(value, axis_y_field_type, 2, axis_y_field_variation) + totalLabel)}
                    borderWidth={5}
                    shapeBlending={0.8}
                    spacing={0}
                    interpolation="smooth"
                    tooltip={({ part: { data } }) => (
                        <div
                            style={{
                                padding: 12,
                                borderRadius: 5,
                                fontSize: 13,
                                background: '#222222',
                                color: '#ffffff'
                            }}
                        >
                            {data.id}: ({getChartFormatValue(data.value, axis_y_field_type, 2, axis_y_field_variation) + totalLabel}) <strong>{getPercentualOfTotal(data.value, total)}</strong>

                        </div>
                    )}
                    labelColor="#ffffff"
                    theme={{
                        fontSize: 12,
                        fontFamily: "Roboto"
                    }}
                    currentPartSizeExtension={5}
                    currentBorderWidth={10}
                    motionConfig="wobbly"
                    afterSeparatorLength={0}
                    beforeSeparatorLength={0}
                /> :
                <EmptyData />
            }
        </>
    );

}

export default FunnelChart;