import styled from "styled-components";
import { Dialog } from '@material-ui/core';

export const DialogContainer = styled(Dialog)`

    background-color: transparent;

    .MuiPaper-root {
        border-radius: 10px;
        background-color: transparent;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-shadow: none;
        width: auto;
    }

`;