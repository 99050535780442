import React, { useCallback, useState } from "react";
import { IconBaseProps } from "react-icons";
import { RiArrowDownSLine } from "react-icons/ri";
import { RiArrowUpSLine } from "react-icons/ri";

import {
    BodyContainer,
    BoxOver,
    Container,
    ContainerBox,
    DropDownButton,
    DropDownItems,
    LeftContainer,
    RightContainer
} from "./styles";
import { BsThreeDots } from "react-icons/bs";
import DropDownItem from "../DropDownList/DropDownItem";
import { FaCopy, FaPen } from "react-icons/fa";
import DropDownDivider from "../DropDownList/DropDownDivider";
import { ClickAwayListener } from "@material-ui/core";

interface FieldBoxProps {
    title: string;
    field_type?: string;
    index?: number;
    icon: React.ComponentType<IconBaseProps>;
    hide_order?: boolean;
    color?: string;
    color_background?: string;
    color_icon?: string;
    isBeta?: boolean;
    moveField?: (type: string, index: number) => void; //type: up - pra cima, down - pra baixo
    editField?: (index: number) => void;
    copyField?: (index: number) => void;
}

const FieldBox: React.FC<FieldBoxProps> = ({ title, field_type, index, icon: Icon, hide_order, moveField, editField, copyField, color, color_background, color_icon, isBeta }) => {

    const alignCenter: boolean = hide_order ? true : false;
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const handleClickAway = () => {
        setMenuOpen(false);
    };

    const handleMoveField = useCallback(async (type: string, index: number | undefined) => {

        if (index === undefined) {
            index = 0;
        }

        if (moveField !== undefined) {
            moveField(type, index);
        }

    }, [moveField]);

    const handleEditField = useCallback(async (index: number | undefined) => {

        if (index === undefined) {
            index = 0;
        }

        if (editField !== undefined) {
            editField(index);
        }

    }, [editField]);

    const handleCopyField = useCallback(async (index: number | undefined) => {

        if (index === undefined) {
            index = 0;
        }

        if (copyField !== undefined) {
            copyField(index);
        }

    }, [copyField]);

    return (
        <Container>

            <ContainerBox color={color} colorBackground={color_background}>
                <LeftContainer color={color} colorBackground={color_background} colorIcon={color_icon} onClick={() => handleEditField(index)} >
                    <Icon size="20" />
                </LeftContainer>

                <BodyContainer alignCenter={alignCenter} onClick={() => handleEditField(index)} >
                    <h1>
                        {title.length <= 30 ? title : title.substring(0, 30) + "..."}
                        {isBeta ?
                            <span>Beta</span> :
                            <></>
                        }
                    </h1>
                    {!!field_type ?
                        <h2>
                            {field_type}
                        </h2> :
                        <> </>
                    }
                </BodyContainer>

                <RightContainer color={color}>
                    <DropDownButton
                        className="col-hed-conf"
                        color={color !== undefined ? color : "#9e37ed"}
                        onClick={() => setMenuOpen(!menuOpen)}
                        isOpen={menuOpen}
                    >
                        <BsThreeDots />
                        {menuOpen && (
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <BoxOver>
                                    <DropDownItems color={"#4d4d4d"}>
                                        <DropDownItem title="Editar" icon={FaPen} color={"#f23b5c"} onClick={(e) => {
                                            if (e !== undefined) {
                                                e.stopPropagation();
                                            }
                                            handleEditField(index)
                                        }} />

                                        {copyField && (
                                            <DropDownItem title="Duplicar" icon={FaCopy} color={"#f23b5c"} onClick={(e) => {
                                                if (e !== undefined) {
                                                    e.stopPropagation();
                                                }
                                                handleCopyField(index);
                                                setMenuOpen(false);
                                            }} />
                                        )}

                                        {!hide_order && (
                                            <>
                                                <DropDownDivider />
                                                <DropDownItem title="Mover para cima" icon={RiArrowUpSLine} color={"#f23b5c"} onClick={(e) => {
                                                    if (e !== undefined) {
                                                        e.stopPropagation();
                                                    }
                                                    handleMoveField("up", index)
                                                }} />
                                                <DropDownItem title="Mover para baixo" icon={RiArrowDownSLine} color={"#f23b5c"} onClick={(e) => {
                                                    if (e !== undefined) {
                                                        e.stopPropagation();
                                                    }
                                                    handleMoveField("down", index)
                                                }} />
                                            </>
                                        )}
                                    </DropDownItems>
                                </BoxOver>
                            </ClickAwayListener>
                        )}
                    </DropDownButton>
                </RightContainer>
            </ContainerBox>
        </Container>
    )
};

export default FieldBox;