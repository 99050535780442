import styled, { css, keyframes } from 'styled-components';

import Button from '../../components/Button';
import Select from "react-select";
import imageTemplate from '../../assets/template-example.jpg';

interface MenuItemTemplateIconProps {
    color: string;
}

interface TemplateTagProps {
    color: string;
}

interface DetailTemplateLogoProps {
    color: string;
}

interface DetailTemplateRelatedItemLogoProps {
    color: string;
}

interface DetailTemplateRelatedItemProps {
    color: string;
}

interface DetailTemplateImageProps {
    url_image?: string;
}

interface MenuItemTemplateProps {
    color: string;
    selectedStyle: boolean;
}

interface MenuItemTemplateStarProps {
    color: string;
    selected: boolean;
}

export const ContainerLoader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 30% !important;
        max-width: 150px;
    }
    
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);

    width: 100%;
    height: 100%;
    //margin: 20px;
    //max-height: 930px;
    max-width: 1400px;
    background-color: white;

    /*
    img {
        margin-bottom: 35px;        
        height: 72px;
        width: 370px;
    }
    */

    @media(max-height: 550px) {
        min-height: unset;
        max-width: unset;
        overflow: auto;
    }
`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    } 
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const AnimationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    form {
        margin: 80px 0;
        min-width: 400px;
        text-align: center;

        h1 {
            color: black;
            margin-bottom: 35px;
        }    

        button {
            margin-top: 60px;
        }
    }
`;

export const BackGround = styled.div`
    flex: 1;
    background-color: #f6f8f9;
    background-size: cover;
`;

export const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    padding-top: 30px;
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 30px;

    border-bottom: 1px solid #80808040;
`;

export const HeaderLeft = styled.div`
    width: 100%;

    h1 {
        font-size: 24px;
        color: black;
        font-weight: bold;
        font-family: Roboto, helvetica;
        letter-spacing: 0.8px;
    }

    h3 {
        color: #676879;
        text-align: left;
        margin-top: 8px;
        font-weight: 400;
        font-size: 16px;
        margin-block-end: unset; 
    }

    @media(max-width: 800px) {
        h3 {
            display: none;
        }
    }

`;

export const Body = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start; 
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-shrink: 1;
    overflow: auto;

    h3 {
        animation: ${appearFromLeft} 1s;
        color: #676879;
        text-align: left;
        margin-top: 8px;
        font-weight: 400;
        font-size: 16px;
        margin-block-end: unset; 
        margin-bottom: 30px;
    }    
    
    @media(max-width: 800px) {
        display: block;
    }
`;

export const LeftContainer = styled.div`    
    height: 100%;
    width: 30%;
    max-width: 260px;
    border-right: 1px solid #80808040;

    @media(max-width: 800px) {
        width: 100%;
        max-width: unset;
        height: auto;
    }

    @media(max-height: 550px) {
        overflow: auto;
    }
    
`;

export const RightContainer = styled.div`
    height: 100%;
    //width: 70%;
    position: relative;
    width: 100%;
    flex-shrink: 1;
    padding: 0px 4px 0px 0px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media(max-width: 800px) {
        height: auto;
    }
`;

export const MenuItemTemplate = styled.li<MenuItemTemplateProps>`
    margin: 15px;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    margin-right: 20px;
    min-height: 35px;
    
    :hover {
        ${(props) => props.color && css`
            color: ${props.color};
            font-weight: 500;
            background-color: ${props.color + "1e"};
            border-radius: 10px;
        `}
    }

    ${(props) => props.selectedStyle && css`
            color: ${props.color};
            font-weight: 500;
            background-color: ${props.color + "1e"};
            border-radius: 10px;
    `}
`;

export const MenuItemTemplateStar = styled.li<MenuItemTemplateStarProps>`
    margin: 15px;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //height: 5%;
    font-size: 14px;
    font-weight: 400;
    margin-right: 20px;
    min-height: 35px;

    :hover {
        ${(props) => props.color && css`
            color: ${props.color};
            font-weight: 500;
            background-color: ${props.color + "1e"};
            border-radius: 10px;
        `}
    }

    ${(props) => props.selected && css`
        color: ${props.color};
            font-weight: 500;
            background-color: ${props.color + "1e"};
            border-radius: 10px;
    `}

    @media(max-width: 800px){
        height: 40px;
        margin-right: 10px;
        margin-left: 10px;

        div {
            height: 40px;
            width: 40px;
        }
    }
`;

export const MenuItemTemplateIcon = styled.div<MenuItemTemplateIconProps>`
    border: 1px solid #f23b5c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    border-radius: 10px;
    background-color: #f23b5c;
    margin-right: 10px;    
    min-height: 35px;
    min-width: 35px;

    ${(props) => props.color && css`
        border: 1px solid ${props.color};
        background-color: ${props.color};
    `}

    svg {
        color: white;
    }

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        font-size: 18px !important;
        
        svg {
            color: white;
        }
    }
`;

export const MenuLabel = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-left: 15px;
    font-weight: 500;
    font-size: 13px;
    color: #000000b5;
    //height: 5%;
    margin-top: 15px;
    max-height: 40px;
`;

export const MenuTemplateItems = styled.ul`
    overflow: auto;
    //height: 60%;
    height: calc(100% - 220px);

    @media(max-width: 800px) {
        display: none;
    }

    @media(max-height: 550px) {
        min-height: 150px;
    }
`;

export const SelectMenu = styled(Select)`
    margin-top: 10px;
    padding-right: 10px;
    padding-left: 10px;

    display: none;

    @media(max-width: 800px) {
        display: block;

        .react-select__control {
            height: 40px;
        }
    }
`;

export const MenuBottom = styled.div`
    //height: 15%;
    //max-height: 70px;
    position: relative;
    bottom: 0;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media(max-width: 800px) {
        display: none;
    }
`;

export const SearchTemplate = styled.div`
    margin-top: 20px;
    margin-bottom: 10px;
    border: 1px solid #dfdfdf;
    margin-left: 10px;
    margin-right: 10px;
    //height: 10%;
    height: 40px;
    max-height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px;

    svg {
        margin-left: 10px;
        margin-right: 10px;
        height: 20px;
        width: 20px;
        color: #dfdfdf;
    }

    @media(max-width: 800px){
        border-radius: 5px;
        border-color: #cccccc;
    }
`;

export const SearchTemplateInput = styled.input`
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 20px;
`;

export const BtnStart = styled(Button)`
    margin-top: 0px !important;
    height: 45px !important;
    margin-right: 10px;
    margin-left: 10px;
    min-width: 200px;
    max-width: 235px;
    padding-right: 25px;
    
    background: rgba(147,55,237,1);

    :hover {
        background: rgba(147,55,237,0.7);
    }
`;

export const ContainerTemplates = styled.div`
    position: relative;
    width: 100%;
    max-height: 100%;
    padding: 20px;
    overflow-y: auto;
`;

export const GridTemplates = styled.div`
    --gap: var(--grid-gap,20px);
    --min-col-width: var(--grid-min-column-width,300px);
    --max-col-width: var(--grid-max-column-width,1fr);
    position: relative;
    will-change: width, height;
    transition: height 0.25s ease-in-out 0.25s;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(var(--min-col-width),var(--max-col-width)));
    grid-gap: var(--gap);
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
`;

export const TemplateTag = styled.div<TemplateTagProps>`
    padding: 5px 10px 5px 10px;
    background-color: #673ab7;
    border-radius: 5px;
    color: white;
    font-size: 11px;
    font-weight: 500;
    margin-right: 10px;

    ${(props) => props.color && css`
        background-color: ${props.color};
    `}

    @media(max-width: 800px){
        background-color: #c1c2c9;
    }

`;

export const DetailTemplateBack = styled.div`
    margin: 15px;

    button {
        background: transparent;
        border: none;

        svg {
            height: 20px;
            width: 20px;
            color: #676879;
        }
    }
`;

export const DetailTemplateBackMobile = styled.div`
    display: none;

    button {
        background: transparent;
        border: none;

        svg {
            height: 20px;
            width: 20px;
        }
    }

    @media(max-width: 800px){
        display: flex;
        position: absolute;
        background: #c1c2c9;
        padding: 15px;
        margin: 20px;
        border-radius: 20px;

        button {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                color: white;
            }
        }
    }
`;

export const DetailTemplateContainer = styled.div`
    
`;

export const DetailTemplateLeft = styled.div`
    overflow: auto;
    height: 100%;
    width: 30%;
    max-width: 260px;
    border-right: 1px solid #80808040;

    @media(max-width: 800px){
        display: none;
    }
`;

export const DetailTemplateRight = styled.div`
    overflow-x: auto !important;
    height: 100%;
    position: relative;
    width: 100%;
    flex-shrink: 1;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    //overflow: hidden;
`;

export const DetailTemplateLogo = styled.div<DetailTemplateLogoProps>`
    margin: 20px;
    margin-right: 45px;
    background-color: red;
    margin-left: 45px;
    height: 140px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-content: center;

    ${(props) => props.color && css`
        background-color: ${props.color};
    `}

    div {
        font-size: 24px;
        color: white;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            height: 50px;
            width: 50px;
        }
    }
`;

export const DetailTemplateResource = styled.div`
    margin: 15px;
    margin-top: 35px;
    
    h2 {
        font-size: 16px;
        margin-bottom: 15px;
        color: black;
    }
`;

export const DetailTemplateResourceItems = styled.div`
    display: inline;
    justify-content: flex-start;
    align-items: center;
`;

export const DetailTemplateResourceItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 5px;

    div {
        display: flex;
        
        svg {
            margin-right: 10px;
            height: 20px;
            width: 20px;
        }
    }

    h4 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 400;
        font-size: 14px;
    }
`;

export const DetailTemplateRelated = styled.div`
    margin: 15px;
    margin-top: 35px;
    
    h2 {
        font-size: 16px;
        margin-bottom: 15px;
        color: black;
    }
`;

export const DetailTemplateRelatedItems = styled.div`
   
`;

export const DetailTemplateRelatedItem = styled.div<DetailTemplateRelatedItemProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin-bottom: 15px;
    margin-left: 5px;

    ${(props) => props.color && css`
        :hover {
            background-color: ${props.color+"1a"};
            
            border-radius: 10px;
        }
    `}
`;

export const DetailTemplateRelatedItemLogo = styled.div<DetailTemplateRelatedItemLogoProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background-color: #fd0000;
    border-radius: 10px;
    height: 45px;
    width: 45px;
    color: white;
    min-height: 45px;
    min-width: 45px;

    ${(props) => props.color && css`
        background-color: ${props.color};
    `}

    div {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            height: 20px;
            width: 20px;
        }
    }
`;

export const DetailTemplateRelatedItemLabel = styled.div`
    margin-right: 10px;
    
    h4 {
        overflow: hidden;
        //text-overflow: ellipsis;
        //white-space: nowrap;
        color: black;
        font-size: 14px;
    }

    span {
        font-size: 13px;
    }
`;

export const DetailTemplateImage = styled.div<DetailTemplateImageProps>`
    background: url(${imageTemplate}) no-repeat center;
    margin-bottom: 15px;
    height: 100px;
    min-height: 100px;
    border-bottom: 5px solid #c1c2c9;

    ${(props) => props.url_image && css`
        background: url(${props.url_image}) center !important;
    `}
    
`;

export const DetailTemplateName = styled.div`
    h2 {
        font-size: 24px;
        color: black;
        font-weight: 500;
        margin-left: 25px;
        margin-bottom: 5px;
    }

    span {
        margin-left: 25px;
        font-weight: 400;
        font-size: 15px;
    }
`;

export const DetailTemplateButtonUse = styled.div`

    @media(max-width: 800px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const DetailTemplateButtonUseBottom = styled.div`

    display: none;

    @media(max-width: 800px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const troubleAnimation = keyframes` 
    0% {
        transform: rotate(1deg);
        transform: rotate(3deg);
    }
    25% {
        transform: rotate(-1deg);
        transform: rotate(-3deg);
    }
    50% {
        transform: rotate(1deg);
        transform: rotate(3deg);
    }
    75% {
        transform: rotate(-1deg);
        transform: rotate(-3deg);
    }
    100% {
        transform: rotate(1deg);
        transform: rotate(3deg);
    }
`

export const BtnUseTemplate = styled(Button)`
    margin-top: 0px !important;
    height: 45px !important;
    margin-right: 10px;
    margin-left: 10px;
    min-width: 150px;
    max-width: 170px;
    padding-right: 25px;

    position: absolute;
    top: 75px;
    right: 15px;
    
    background: rgba(147,55,237,1);

    animation: ${troubleAnimation} 2s;
    animation-iteration-count: infinite;

    :hover {
        background: #a058e7;
    }

    @media(max-width: 800px) {
        position: static;
        animation: none;
        width: 85% !important;
        padding: 0px;
        max-width: none;
        margin: 15px 10px !important;    
    }
`;

export const DetailTemplateTags = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    margin-left: 25px;
    overflow-x: auto;
`;

export const DetailTemplatePreReady = styled.div`
    p {
        font-size: 16px;
        line-height: 24px;
        margin: 25px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    ul {
        margin: 25px;
        margin-left: 50px; 
        margin-top: 0px;	
    }

    ul, li {
        margin-bottom: 5px;
    }
`;

export const DetailTemplateDescription = styled.div`
    p {
        font-size: 16px;
        line-height: 24px;
        margin: 25px;
    }
`;


export const DetailTemplateCarousel = styled.div`
    width: 100%;
    
    .carousel-root {
        margin: 30px;
    }
`;

