import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    height: 60px;
    width: 80%;
    max-width: 600px;
    border-radius: 10px;
    background-color: #242424;
`;

export const LeftContainer = styled.div`
    float: left;
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 15px;

    span {
        font-size: 15px;
        letter-spacing: 0.3px;
        font-weight: 500;
        margin-left: 5px;
        user-select: none;
    }
`;

export const RightContainer = styled.div`
    float: right;
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 15px;
    border-left: 1px solid #818181;
`;

export const ButtonAction = styled.div`
    position: relative;
    padding: 10px;
    color: white;
    border-radius: 10px;
    margin-right: 15px;
    width: 35px;
    height: 35px;
    cursor: pointer;

    svg {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    :hover {
        background-color: #81818145;        
    }
`;

export const ContainerAction = styled.div`
    position: relative;
`;