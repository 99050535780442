import React, { useCallback, useEffect, useState } from 'react';
import { Calendar as BigCalendar, EventProps, HeaderProps, ToolbarProps, View, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/pt-br';

import {
    Container,
    ContainerToolbar,
    LabelMonthYear,
    ContainerButtonLeft,
    ButtonToday,
    ButtonPrev,
    ButtonNext,
    ContainerButtonRight,
    ButtonChangeView,
    ContainerWeekHeader,
    LabelDayWeek,
    LabelDay,
    ButtonShowWeekend,
    ContainerEvent,
    EventTitle,
    EventAvatar,
    ContainerLoader
} from "./style";
import { Card } from '../../interfaces/Card';
import { Flow } from '../../interfaces/Flow';
import { UpdateControlProps } from '../../pages/Flow';
import { FilterFlowProps } from '../FilterBar/FilterDialog';
import getCardFiltered from '../../middlewares/getCardFiltered';
import getCardSorted from '../../middlewares/getCardSorted';
import { FieldProps } from '../Forms/Fields/FieldBuilder';
import getFields from '../../utils/getFields';
import updateFormulaFromCard from '../../utils/Formula/updateFormulaFromCard';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { useAuth } from '../../hooks/auth';

interface CalendarProps {
    selectedValue: Flow;
    updateControl?: UpdateControlProps;
    testModel?: boolean;
    filterFlow?: FilterFlowProps;
    printComponentRef?: React.MutableRefObject<null>;
    onEventClicked: (card: Card) => Promise<void>;
}

const localizer = momentLocalizer(moment);
moment.locale('pt-br');
moment.tz.setDefault("America/São_Paulo");

const Calendar: React.FC<CalendarProps> = ({ selectedValue, testModel, filterFlow, updateControl, printComponentRef, onEventClicked }) => {

    const { addToast } = useToast();
    const { user } = useAuth();

    const [view, setView] = useState<View>('month');
    const [showWeekend, setShowWeekend] = useState<boolean>(false);
    const [cards, setCards] = useState<Card[]>();
    const [cardsFiltered, setCardsFiltered] = useState<Card[]>();
    const [loading, setLoading] = useState<boolean>(true);

    const getApiData = useCallback(async () => {

        try {

            if (selectedValue !== undefined && selectedValue.id_flow > 0
                && selectedValue.flow_steps !== undefined && selectedValue.flow_steps.length > 0) {

                const response = await api.get(`/card/by-flow`, {
                    params: {
                        flow_id: selectedValue.id_flow,
                        isTestModel: testModel !== undefined ? testModel : undefined
                    }
                });

                if (response.data !== null) {
                    let cardsApi: Card[] = response.data;
                    setCards(cardsApi);
                }

            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            addToast({
                type: 'error',
                title: 'Erro ao abrir o Fluxo',
                description: 'Ocorreu ao tentar abrir o Fluxo!',
            });
        }
    }, [addToast, selectedValue, testModel]);

    const CustomToolbar = (toolbar: ToolbarProps) => {
        const goToBack = () => {
            toolbar.onNavigate('PREV');
        };

        const goToNext = () => {
            toolbar.onNavigate('NEXT');
        };

        const goToCurrent = () => {
            const now = new Date();
            toolbar.date.setMonth(now.getMonth());
            toolbar.date.setFullYear(now.getFullYear());
            toolbar.onNavigate('TODAY');
        };

        const goToView = (view: View) => {
            toolbar.onView(view);
        };

        const label = () => {
            const date = moment(toolbar.date);
            return (
                <span>{date.format('MMMM')} de {date.format('YYYY')}</span>
            );
        };

        return (
            <ContainerToolbar>
                <ContainerButtonLeft>
                    <ButtonToday onClick={goToCurrent}>Hoje</ButtonToday>
                    <ButtonPrev onClick={goToBack}>&#8249;</ButtonPrev>
                    <ButtonNext onClick={goToNext}>&#8250;</ButtonNext>
                </ContainerButtonLeft>

                <LabelMonthYear>{label()}</LabelMonthYear>

                {loading !== undefined && loading ?
                    <ContainerLoader>
                        Carregando...
                    </ContainerLoader> :
                    <></>
                }

                <ContainerButtonRight>
                    {view === 'work_week' || view === 'week' ?
                        <ButtonShowWeekend onClick={() => {
                            setShowWeekend(!showWeekend);

                            if (view === 'work_week') {
                                setView('week');
                                goToView('week');
                            } else if (view === 'week') {
                                setView('work_week');
                                goToView('work_week');
                            }
                        }}>
                            {!showWeekend ?
                                <AiFillEye /> :
                                <AiFillEyeInvisible />
                            }
                            Fim de semana
                        </ButtonShowWeekend> :
                        <></>
                    }
                    <ButtonChangeView onClick={() => {
                        if (view === 'month') {

                            const view: View = showWeekend ? 'week' : 'work_week';

                            setView(view);
                            goToView(view);

                        } else {

                            setView('month');
                            goToView('month');

                        }
                    }}>{view === 'month' ? 'Semana' : 'Mês'}</ButtonChangeView>
                </ContainerButtonRight>
            </ContainerToolbar >
        );
    };

    const CustomWeekHeader = (header: HeaderProps) => {

        const [dayWeek, day] = header.label.split("+");

        //Check if the header.date is today
        const isToday = moment(header.date).isSame(moment(), 'day');

        return (
            <ContainerWeekHeader>
                <LabelDayWeek isToday={isToday}>{dayWeek}</LabelDayWeek>
                <LabelDay isToday={isToday}>{day}</LabelDay>
            </ContainerWeekHeader>
        )

    }

    const CustomMonthEvent = (event: EventProps) => {

        const card: Card = event.event as Card;

        return (
            <ContainerEvent style={{ height: '40px' }} color={card.flow_step?.color}>
                {card !== undefined && card.user !== undefined ?
                    <EventAvatar name={card.user.name} color={card.user.color} size="25" round={true} /> :
                    <></>
                }
                <EventTitle>{event.title}</EventTitle>
            </ContainerEvent>
        )

    }

    const CustomWeekEvent = (event: EventProps) => {

        const card: Card = event.event as Card;

        return (
            <ContainerEvent style={{ height: '40px' }} color={card.flow_step?.color}>
                {card !== undefined && card.user !== undefined ?
                    <EventAvatar name={card.user.name} color={card.user.color} size="25" round={true} /> :
                    <></>
                }
                <EventTitle>{event.title}</EventTitle>
            </ContainerEvent>
        )

    }

    const components = {
        toolbar: CustomToolbar,
        work_week: {
            header: CustomWeekHeader,
            event: CustomWeekEvent
        },
        week: {
            header: CustomWeekHeader,
            event: CustomWeekEvent
        },
        month: {
            event: CustomMonthEvent,
        }
    };

    useEffect(() => {

        if (updateControl?.card !== undefined && updateControl.card.id_card > 0) {

            const newCard = updateControl.card;

            setCards((prevCards: Card[]) => {

                const updatedCards = [...prevCards];

                const index = updatedCards.findIndex((card) => card.id_card === newCard.id_card);

                if (index !== -1) {
                    if (newCard.archived === "S") {
                        updatedCards.splice(index, 1);
                    } else {
                        updatedCards[index] = newCard;
                    }
                } else {
                    updatedCards.push(newCard);
                }

                return updatedCards;
            });

        } else if (selectedValue !== undefined && selectedValue.id_flow > 0) {
            getApiData();
        }

    }, [selectedValue, getApiData, testModel, updateControl]);

    useEffect(() => {

        let newCards: Card[] | undefined = cards;

        if (newCards !== undefined && filterFlow !== undefined) {

            newCards = getCardFiltered(newCards, user.id_user, filterFlow.conditions, filterFlow.searchText);
            newCards = getCardSorted(newCards, filterFlow.orderBy);

            let fields: FieldProps[] = [];
            if (selectedValue.form_init !== undefined && selectedValue.flow_steps !== undefined) {
                fields = getFields(selectedValue.form_init, selectedValue.flow_steps);
            }

            newCards = newCards.map((card) => {

                let newCard = card;

                const start = moment(card.dt_due).toDate();
                const end = moment(card.dt_due).add(1, 'hour').toDate();

                newCard.start = start;
                newCard.end = end;

                return updateFormulaFromCard(newCard, fields);
            });

            setCardsFiltered(newCards);

        }

    }, [filterFlow, cards, selectedValue, user.id_user]);

    return (
        <Container style={{ width: '100%', height: 'calc(100% - 10px)' }} ref={printComponentRef}>

            <BigCalendar
                localizer={localizer}
                events={cardsFiltered}
                views={['month', 'work_week', 'week']}
                view={view}
                step={60}
                onView={(view) => setView(view)}
                formats={{
                    dayFormat: "dddd+DD MMM",
                }}
                startAccessor="start"
                endAccessor="end"
                allDayAccessor={(object: Card) => {

                    const date = moment(object.dt_due);
                    return date.get('hour') === 0 && date.get('minute') === 0 && date.get('second') === 0;

                }}
                components={components}
                enableAutoScroll={true}
                timeslots={1}
                showMultiDayTimes={true}
                scrollToTime={new Date()}
                showAllEvents={true}
                onSelectEvent={(event: Card) => onEventClicked(event)}
            />

        </Container>
    );

}

export default Calendar;