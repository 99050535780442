import React, { useCallback, useEffect, useState } from "react";

import {
    BottomButton,
    BottomContainer,
    BottomDescription,
    ButtonPlanFree,
    ContainerHeader,
    ContainerLogo,
    ContainerPricing,
    Content,
    ContentBody,
    DialogTopRight,
    HeaderDicountInformation,
    HeaderInformation,
    HeaderTitle,
    MainDialog,
} from "./style";

import { AiOutlineClose } from "react-icons/ai";
import { useAuth } from "../../../hooks/auth";
import logoCange from '../../../assets/wendata-logo.png';
import { CompanyPlan } from "../../../interfaces/CompanyPlan";
import { IoLogoWhatsapp } from "react-icons/io";
import { UserUse } from "../../../interfaces/UserUse";
import api from "../../../services/api";
import { maskFormulaCurrency } from "../../../components/Forms/Fields/Components/Formula";
import { useToast } from "../../../hooks/toast";
import { useHistory } from "react-router-dom";
import { PRICING_TABLE_ID, PUBLISHABLE_KEY } from "../../../config/app";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

interface ChoosePlanProps {
    open: boolean;
    isEndTrial?: boolean;
    onClose: () => void;
}

const linkWhatsapp = "https://api.whatsapp.com/send?phone=551151989897&text=Ol%C3%A1!%20Estou%20fazendo%20o%20Trial%20e%20estou%20com%20d%C3%BAvida,%20pode%20me%20ajudar?";

const ChoosePlan: React.FC<ChoosePlanProps> = ({ onClose, open, isEndTrial }) => {

    const { user } = useAuth();
    const { addToast } = useToast();

    const history = useHistory();

    const [companyPlan, setCompanyPlan] = useState<CompanyPlan>();
    const [totalDiscount, setTotalDiscount] = useState<number>();

    const setFreePlanApi = useCallback(async () => {

        await api
            .post('/plan/company', {
                plan_id: 1
            })
            .then(response => {
                history.push('/dashboard');
                onClose();
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Não foi possível atribuir o plano Free na sua conta!',
                    description: 'Por favor, entre em contato com o suporte!',
                });
            });

    }, [addToast, history, onClose]);

    const getStatsApi = useCallback(async () => {

        await api
            .get(`/user/stats`)
            .then(response => {

                const apiRet: UserUse[] = response.data;

                if (apiRet !== undefined && apiRet.length > 0) {

                    const totValue = apiRet[0].tot_user * 15 * 12; //Tot Users * Diference Between MonthXYear * 12 months

                    setTotalDiscount(totValue);

                }

            });

    }, []);

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    useEffect(() => {

        getStatsApi();

        if (user !== undefined && user.company !== undefined) {
            const newCompany = user.company;
            if (newCompany.company_plan !== undefined && newCompany.company_plan !== null && newCompany.company_plan.length > 0) {
                setCompanyPlan(newCompany.company_plan[0]);
            }
        }

    }, [getStatsApi, user]);

    return (
        <MainDialog
            fullWidth={true}
            maxWidth="lg"
            fullScreen={true}
            open={open}
        >
            <Content>
                <ContentBody container>

                    <ContainerLogo>
                        <img src={logoCange} alt="Logo Cange" />

                        {!isEndTrial && (
                            <DialogTopRight>
                                <button onClick={handleClose}><AiOutlineClose /></button>
                            </DialogTopRight>
                        )}
                    </ContainerLogo>

                    <ContainerHeader>
                        <HeaderTitle>
                            {!isEndTrial ?
                                <>Escolha um plano para liberar <b style={{ color: '#9e37ee' }}>todos os recursos ✨</b></> :
                                <>Seu Trial terminou, escolha um plano para manter <b style={{ color: '#9e37ee' }}>todos os recursos ✨</b></>
                            }
                        </HeaderTitle>
                        <HeaderInformation>
                            <p>No momento o seu plano é o <u>{companyPlan?.plan.name}</u></p>
                            {totalDiscount !== undefined && totalDiscount > 0 && (
                                <HeaderDicountInformation>
                                    Economize até <b>{maskFormulaCurrency(String(totalDiscount))}</b> no plano anual
                                </HeaderDicountInformation>
                            )}
                        </HeaderInformation>

                    </ContainerHeader>

                    <ContainerPricing>
                        <stripe-pricing-table
                            style={{ width: '100%' }}
                            customer-email={user.email}
                            pricing-table-id={PRICING_TABLE_ID}
                            publishable-key={PUBLISHABLE_KEY}
                            quantity-plan={50}
                        />
                    </ContainerPricing>

                    <BottomContainer>
                        <BottomDescription style={isEndTrial ? { color: '#027947', fontWeight: '500' } : {}}>
                            Não era isto que você estava esperando?
                        </BottomDescription>
                        <BottomButton href={linkWhatsapp} target="_blank" style={isEndTrial ? { textDecoration: 'none', color: "#ebfdf2", backgroundColor: '#027947' } : { textDecoration: 'none' }} rel="noopener noreferrer">
                            <IoLogoWhatsapp />
                            Fale com a gente
                        </BottomButton>
                    </BottomContainer>

                    {isEndTrial ?
                        <BottomContainer style={{ marginTop: '30px', marginBottom: '15px' }}>
                            <BottomDescription>
                                Prefiro não ter acesso às principais funcionalidades e <ButtonPlanFree onClick={() => setFreePlanApi()}>quero seguir com o plano Grátis</ButtonPlanFree>
                            </BottomDescription>
                        </BottomContainer> :
                        <></>
                    }

                </ContentBody>
            </Content>
        </MainDialog >
    );

}

export default ChoosePlan;