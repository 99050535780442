import "react-datepicker/dist/react-datepicker.css";
import './datePicker.scss';

import {
  Container,
  ContainerInLine,
  DatePickerInput,
  DatePickerInputInLine,
  ContainerCalendarInLine,
  ContainerFastButtons,
  BtnShortCut
} from "./styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReactDatePickerProps, registerLocale } from "react-datepicker";
import { addDays } from 'date-fns';
import ErrorDescription from "../../../../ErrorDescription";
import { IconBaseProps } from "react-icons";
import pt from "date-fns/locale/pt-BR";
import { useField } from "@unform/core";

registerLocale("pt", pt);

interface PickerProps extends Omit<ReactDatePickerProps, "onChange"> {
  name: string;
  placeholder?: string;
  title?: string;
  icon?: React.ComponentType<IconBaseProps>;
  description?: string;
  onInLine?: boolean;
  variation?: string;
  onForceBlur?: () => void;
}


const DatePicker: React.FC<PickerProps> = ({
  name,
  description,
  placeholder,
  title,
  icon: Icon,
  onInLine,
  variation,
  onForceBlur,
  ...rest
}) => {

  const datepickerRef = useRef(null);

  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [date, setDate] = useState<any>(defaultValue !== undefined ? new Date(defaultValue) : undefined);
  const [isFocused, setIsFocused] = useState(false);

  const dateFormatVariation: string = variation !== undefined && variation === "2" ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'
  const placeholderTextVariation: string = variation !== undefined && variation === "2" ? 'Selecione a data e hora' : 'Selecione a data...'
  const isDateTime: boolean = variation !== undefined && variation === "2" ? true : false;

  const handleInputFocused = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    if (onForceBlur) {
      onForceBlur();
    }
  }, [onForceBlur]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      getValue: (ref: any) => {
        if (date !== undefined) {
          return date;
        } else {
          return undefined;
        }
      },
      setValue: (ref, value) => {
        if (value !== undefined && value !== null) {
          setDate(new Date(value as string));
        }
      },
      clearValue: (ref: any) => {
        ref.clear();
      }
    });

  }, [fieldName, registerField, defaultValue, date]);

  return (
    <>
      {onInLine === true ?
        <ContainerCalendarInLine>
          <ContainerFastButtons>
            <BtnShortCut onClick={() => setDate(addDays(new Date(new Date().setHours(0, 0, 0, 0)), -1))}>
              Ontem
            </BtnShortCut>
            <BtnShortCut onClick={() => setDate(new Date(new Date().setHours(0, 0, 0, 0)))}>
              Hoje
            </BtnShortCut>
            <BtnShortCut onClick={() => setDate(addDays(new Date(new Date().setHours(0, 0, 0, 0)), 1))}>
              Amanhã
            </BtnShortCut>
          </ContainerFastButtons>
          <ContainerInLine>
            <DatePickerInputInLine
              ref={datepickerRef}
              onChange={setDate}
              selected={date}
              onFocus={handleInputFocused}
              onBlur={handleInputBlur}
              onCalendarClose={handleInputBlur}
              dateFormat={dateFormatVariation}
              timeFormat={isDateTime ? "HH:mm" : undefined}              
              locale={pt}
              showTimeInput={isDateTime}
              timeInputLabel="Hora"
              timeCaption="Hora"
              inline
              {...rest}
            />
          </ContainerInLine>
        </ContainerCalendarInLine> :
        <>
          <Container
            isErrored={!!error}
            isFocused={isFocused}
          >
            {Icon && !onInLine ? <Icon size="20" /> : <></>}
            <DatePickerInput
              ref={datepickerRef}
              selected={date}
              onChange={setDate}
              placeholderText={placeholderTextVariation}
              onFocus={handleInputFocused}
              onBlur={handleInputBlur}
              onCalendarClose={handleInputBlur}
              dateFormat={dateFormatVariation}
              locale={pt}
              showTimeSelect={isDateTime}
              timeInputLabel="Hora"
              timeCaption="Hora"
              {...rest}
            />
          </Container>
        </>

      }
      {error && (<ErrorDescription title={"Deve ser selecionada uma opção do campo " + title + " (obrigatório)!"} />)}
    </>
  );
};

export default DatePicker;
