import styled from "styled-components";
import { Box } from "@material-ui/core";

export const BoxContainer = styled(Box)`
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    background-color: #242425;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
`;

export const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 65px;
    border-bottom: 1px solid #f4f5f7;
`;

export const HeaderLeft = styled.div`
    margin-left: 13px;
    margin-top: 4px;

    img {
        height: 30px
    }
`;

export const HeaderRight = styled.div`
    position: fixed;
    right: 0px;
    color: white;
    display: flex;

    svg {
        margin-right: 13px;
        font-size: 35px;
    }
`;

export const Body = styled.div`
    height: 100%;
`;

export const MenuItem = styled.div`

    display: flex;
    justify-content: flex-start;
    align-content: center;
    padding: 8px;
    padding-left: 20px;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        background-color: #818181;
        border-radius: 50px;
        margin-right: 10px;
        color: white;
        font-size: 20px;
    }

    h2 {
        font-size: 18px;
        font-weight: 400;
        color: white;
        font-family: 'Roboto',sans-serif;
        text-align: center;
        vertical-align: middle;
        display: flex;
        justify-content: center;
        align-items: center;
    }

`;

export const DividerContainer = styled.div`
    border-bottom: 1px solid #818181;
    padding-top: 10px;
    margin-bottom: 10px;
`;