import { maskFormulaPercent } from "../Forms/Fields/Components/Formula";

export default function getChartFormatValue(
    value: number,
    type?: string,
    decimalPlaces: number = 2,
    variation?: string
): string {

    if (type === undefined || (type !== "CURRENCY_FIELD" && type !== "NUMBER_FIELD" && type !== "FORMULA_FIELD")) {
        return String(value);
    }

    const num = parseFloat(String(value));
    if (isNaN(num)) return String(value);

    const formattedNumber = formatNumber(num, decimalPlaces);

    if (type === "CURRENCY_FIELD") {
        return `R$ ${formattedNumber}`;
    } else if (type === "NUMBER_FIELD") {
        return formattedNumber;
    } else if (type === "FORMULA_FIELD") {
        if (variation !== undefined) {
            if (variation === "1") { //Number
                return formattedNumber;
            } else if (variation === "2") { //Currency
                return `R$ ${formattedNumber}`;
            } else if (variation === "3") { //Percent
                return maskFormulaPercent(String(value));
            } else {
                return formattedNumber;
            }

        }
    }

    return String(value);
}

function formatNumber(num: number, decimalPlaces: number): string {
    if (num >= 1_000_000_000) {
        return `${(Math.round(num / 1_000_000_000 * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)).toFixed(decimalPlaces)}B`;
    } else if (num >= 1_000_000) {
        return `${(Math.round(num / 1_000_000 * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)).toFixed(decimalPlaces)}M`;
    } else if (num >= 1_000) {
        return `${(Math.round(num / 1_000 * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)).toFixed(decimalPlaces)}k`;
    } else {
        return num.toFixed(decimalPlaces);
    }
}