import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    Animation,
    ContainerLoader,
    DividerGroup,
    LogBody,
    LogBodyChild,
    LogDateExec,
    LogDescription,
    LogExpandIcon,
    LogFieldContainer,
    LogFieldContainerGroup,
    LogFieldTitle,
    LogFieldValue,
    LogHeader,
    LogItem,
    LogItemChild,
    LogItemChildStatus,
    LogItemChildTitle,
    LogItemChildType,
    LogItemsContainer,
    LogName,
    LogStatusIcon
} from "./style";
import AutomationLog from "../../../../interfaces/AutomationLog";
import { AiOutlineCheck, AiOutlineClockCircle, AiOutlineClose, AiOutlinePlayCircle } from "react-icons/ai";
import { IconBaseProps } from "react-icons";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import lottie from "lottie-web";
import api from "../../../../services/api";
import { formatDateToDateRead } from "../../../../utils/formatDateToDateRead";
import { useToast } from "../../../../hooks/toast";
import { IconList, IconPickerItem } from "react-fa-icon-picker";

interface LogAutomationItemProps {
    logAutomation: AutomationLog;
    isExpanded: boolean;
    isShowAutomationName: boolean;
    onExpand: (key: string) => void;
}

const LogAutomationItem: React.FC<LogAutomationItemProps> = ({ logAutomation, isExpanded, isShowAutomationName, onExpand }) => {

    const { addToast } = useToast();

    const lottieInProgress = useRef<HTMLDivElement>(null);
    const lottieContainer = useRef<HTMLDivElement>(null);

    const [expandedGroups, setExpandedGroups] = useState<string[]>([]);

    const [loading, setLoading] = useState<boolean>();
    const [automationLog, setAutomationLog] = useState<AutomationLog>(logAutomation);

    // '0 - Waiting, 1 - Running, 2 - Partial, Waiting outTime, 3 - Error, 4 - Successful'

    const color = logAutomation.status === '4' ? "#61bd4f" : logAutomation.status === '3' ? "#f23b5d" : logAutomation.status === '2' ? "#f2c744" : logAutomation.status === '1' ? "#4581b7" : "#f2c744";
    const Icon: React.ComponentType<IconBaseProps> = logAutomation.status === '4' ? AiOutlineCheck : logAutomation.status === '3' ? AiOutlineClose : logAutomation.status === '2' ? AiOutlineClockCircle : logAutomation.status === '1' ? AiOutlinePlayCircle : AiOutlineClockCircle;
    const description = logAutomation.status === '4' ? "Executado com sucesso" : logAutomation.status === '3' ? "Erro na execução" : logAutomation.status === '2' ? "Aguardando a execução" : logAutomation.status === '1' ? "Em execução" : "Aguardando a execução";

    const toggleGroup = (key: string) => {

        if (expandedGroups.includes(key)) {
            setExpandedGroups(expandedGroups.filter((group) => group !== key));
        } else {
            setExpandedGroups([...expandedGroups, key]);
        }
    };

    const getAutomationLog = useCallback(async (id_automation_log: number) => {

        setLoading(true);

        await api.get(`/automation/log`, {
            params: {
                flow_id: logAutomation.flow_id,
                id_automation_log: id_automation_log,
                automation_id: logAutomation.automation_id
            }
        }).then(response => {
            if (response.data !== null) {
                let resp: AutomationLog = response.data;

                resp.dayGroup = formatDateToDateRead(new Date(resp.dt_started));
                resp.dateTimeRedable = formatDateToDateRead(new Date(resp.dt_started), true);

                //Set readable date for each log item                       
                resp.automation_log_items.forEach((log_items) => {
                    log_items.dateStartRedable = formatDateToDateRead(new Date(log_items.dt_started), true);
                    log_items.dateEndRedable = formatDateToDateRead(new Date(log_items.dt_ended), true);

                    return log_items;
                });

                setAutomationLog(resp);
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            addToast({
                type: "error",
                title: "Erro ao buscar histórico de execução.",
                description: "Ao tentar buscar o histórico de execução, ocorreu um erro."
            });
        });

    }, [addToast, logAutomation]);

    useEffect(() => {
        if (lottieInProgress.current) {
            lottie.loadAnimation({
                container: lottieInProgress.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../../assets/lottie/loader-circle-white.json')
            })
        }
    }, [logAutomation]);

    useEffect(() => {

        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../../assets/lottie/loader.json')
            });
        }

    }, [loading]);

    useEffect(() => {

        if (isExpanded) {
            getAutomationLog(logAutomation.id_automation_log);
        }

    }, [isExpanded, getAutomationLog, logAutomation.id_automation_log]);

    return (
        <LogItem>
            <LogHeader>
                <LogExpandIcon onClick={() => onExpand(String(automationLog.id_automation_log))}>
                    {isExpanded ?
                        <FaCaretDown /> :
                        <FaCaretRight />
                    }
                </LogExpandIcon>
                <LogStatusIcon color={color}>
                    {automationLog.status === '1' ?
                        <Animation className={"lottieInProgress"} ref={lottieInProgress} /> :
                        <Icon />
                    }
                </LogStatusIcon>
                {isShowAutomationName ?
                    <LogName>{automationLog.automation.name}</LogName> :
                    <></>
                }
                <LogDescription>
                    {description}
                </LogDescription>
                <LogDateExec>{automationLog.dateTimeRedable}</LogDateExec>
            </LogHeader>
            {isExpanded ?
                <LogBody>

                    <DividerGroup />


                    {loading ?
                        <ContainerLoader>
                            <Animation className="lottieContainer" ref={lottieContainer} />
                        </ContainerLoader > :
                        <LogItemsContainer>
                            {automationLog.automation_log_items !== undefined && automationLog.automation_log_items.map((logItem, index) => {

                                let isExpandedChild = expandedGroups.includes(String(logItem.id_automation_log_item));

                                 // 0 - Waiting, 1 - Running, 2 - Error, 3 - Successful, 4 - Skipped
                                const color = logItem.status === '4' ? '#808080' : logItem.status === '3' ? "#61bd4f" : logItem.status === '2' ? "#f23b5d" : logItem.status === '1' ? "#4581b7" : "#f2c744";
                                const Icon: React.ComponentType<IconBaseProps> = logItem.status === '4' ? AiOutlineClose : logItem.status === '3' ? AiOutlineCheck : logItem.status === '2' ? AiOutlineClose : logItem.status === '1' ? AiOutlinePlayCircle : AiOutlineClockCircle;
                                const description = logItem.status === '4' ? "Ignorado por condicional" : logItem.status === '3' ? "Sucesso" : logItem.status === '2' ? "Erro" : logItem.status === '1' ? "Executando" : "Aguardando";

                                return (
                                    <LogItemChild key={index}>
                                        <LogHeader>
                                            <LogExpandIcon onClick={() => toggleGroup(String(logItem.id_automation_log_item))}>
                                                {isExpandedChild ?
                                                    <FaCaretDown /> :
                                                    <FaCaretRight />
                                                }
                                            </LogExpandIcon>
                                            <LogStatusIcon
                                                style={{ borderRadius: "5px", width: "30px", height: "30px", minWidth: "30px", minHeight: "30px" }}
                                                color={logItem.action !== undefined && logItem.action !== null ? logItem.action.color : logItem.trigger !== undefined && logItem.trigger !== null ? logItem.trigger.color : "blue"}>
                                                {logItem.action !== undefined && logItem.action !== null && logItem.action.icon !== undefined ?
                                                    <IconPickerItem
                                                        icon={logItem.action.icon as IconList}
                                                        color={"white"}
                                                    /> : logItem.trigger !== undefined && logItem.trigger !== null && logItem.trigger.icon !== undefined ?
                                                        <IconPickerItem
                                                            icon={logItem.trigger.icon as IconList}
                                                            color={"white"}
                                                        /> :
                                                        <AiOutlineClockCircle />
                                                }
                                            </LogStatusIcon>
                                            <LogItemChildTitle style={{ color: 'black' }}>{logItem.action !== undefined && logItem.action !== null ? logItem.action.name : logItem.trigger !== undefined && logItem.trigger !== null ? logItem.trigger.name : "(Sem título)"}</LogItemChildTitle>
                                            <LogItemChildType>{logItem.action !== undefined && logItem.action !== null ? "Ação" : logItem.trigger !== undefined && logItem.trigger !== null ? "Gatilho" : "Condicional"}</LogItemChildType>
                                            <LogItemChildStatus>
                                                <Icon color={color} />
                                                <span style={{ color: color }}>{description}</span>
                                            </LogItemChildStatus>
                                        </LogHeader>
                                        {isExpandedChild ?
                                            <LogBodyChild style={isShowAutomationName ? {} : { marginLeft: '0px' }}>

                                                <LogFieldContainer>
                                                    <LogFieldTitle>Mensagem</LogFieldTitle>
                                                    <LogFieldValue>{logItem.message}</LogFieldValue>
                                                </LogFieldContainer>

                                                <LogFieldContainerGroup>
                                                    <LogFieldContainer>
                                                        <LogFieldTitle>Data Inicial</LogFieldTitle>
                                                        <LogFieldValue>{logItem.dateStartRedable}</LogFieldValue>
                                                    </LogFieldContainer>

                                                    <LogFieldContainer>
                                                        <LogFieldTitle>Data Final</LogFieldTitle>
                                                        <LogFieldValue>{logItem.dateEndRedable}</LogFieldValue>
                                                    </LogFieldContainer>
                                                </LogFieldContainerGroup>

                                                {logItem.type_object_input !== undefined && logItem.type_object_input !== null && logItem.type_object_input !== "" ?
                                                    <>
                                                        <LogFieldContainer>
                                                            <LogFieldTitle>Tipo objeto entrada</LogFieldTitle>
                                                            <LogFieldValue>{logItem.type_object_input}</LogFieldValue>
                                                        </LogFieldContainer>

                                                        <LogFieldContainer>
                                                            <LogFieldTitle>Objeto entrada</LogFieldTitle>
                                                            <LogFieldValue>{logItem.object_input}</LogFieldValue>
                                                        </LogFieldContainer>
                                                    </> :
                                                    <></>
                                                }

                                                {logItem.type_object_reference !== undefined && logItem.type_object_reference !== null && logItem.type_object_reference !== "" ?
                                                    <>
                                                        <LogFieldContainer>
                                                            <LogFieldTitle>Tipo objeto referência</LogFieldTitle>
                                                            <LogFieldValue>{logItem.type_object_reference}</LogFieldValue>
                                                        </LogFieldContainer>

                                                        <LogFieldContainer>
                                                            <LogFieldTitle>Objeto referência</LogFieldTitle>
                                                            <LogFieldValue>{logItem.object_reference}</LogFieldValue>
                                                        </LogFieldContainer>
                                                    </> :
                                                    <></>
                                                }

                                                {logItem.type_object_output !== undefined && logItem.type_object_output !== null && logItem.type_object_output !== "" ?
                                                    <>
                                                        <LogFieldContainer>
                                                            <LogFieldTitle>Tipo objeto saída</LogFieldTitle>
                                                            <LogFieldValue>{logItem.type_object_output}</LogFieldValue>
                                                        </LogFieldContainer>

                                                        <LogFieldContainer>
                                                            <LogFieldTitle>Objeto saída</LogFieldTitle>
                                                            <LogFieldValue>{logItem.object_output}</LogFieldValue>
                                                        </LogFieldContainer>
                                                    </> :
                                                    <></>
                                                }

                                            </LogBodyChild> :
                                            <></>
                                        }
                                    </LogItemChild>
                                );
                            })}
                        </ LogItemsContainer>
                    }

                </LogBody> :
                <></>
            }
        </LogItem>
    );

}

export default LogAutomationItem;