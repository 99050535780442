import { format, isAfter, isBefore, isSameDay } from 'date-fns';

import { FilterConditionItem } from "../components/FilterBar/FilterDialog";
import convertDMYtoDate from '../utils/convertDMYtoDate';
import { FormAnswer } from '../interfaces/FormAnswer';
import getDynamicDate from '../utils/getDynamicDate';

export default function getFormAnswerFiltered(formAnswers: FormAnswer[], user_id: number, conditions?: FilterConditionItem[], filterSearchText?: string): FormAnswer[] {

    let newFormAnswers = formAnswers;

    //Search Bar
    if (filterSearchText !== undefined && filterSearchText !== "") {

        newFormAnswers = newFormAnswers.filter((formAnswer) => {

            let ret = false;

            formAnswer.form_answer_fields.map((answer_field) => {

                const answerFieldValue = answer_field.valueString !== undefined && answer_field.valueString !== null ? answer_field.valueString.toLocaleLowerCase() : "";

                if (answerFieldValue.includes(filterSearchText.toLocaleLowerCase())) {
                    ret = true;
                }

                return answer_field;
            })

            return ret
        })

    }

    //Filter component
    if (conditions !== undefined) {
        for (let index = 0; index < conditions.length; index++) {
            const condition = conditions[index];

            //Valid if can keep - Check here
            const validExec = (condition.valueOptions === undefined || condition.valueOptions.length < 1) && (condition.value === undefined || condition.value === "") && condition.selectedComparator !== "Está em branco" && condition.selectedComparator !== "Não está em branco" ? false : true;

            if (validExec) {

                newFormAnswers = newFormAnswers.filter((formAnswer) => {

                    let ret = true;
                    let findField = false;

                    if (condition.selectedField.id_field === -1) { //Data de Criação

                        findField = true;

                        if (formAnswer.dt_created !== undefined) {

                            const answerFieldValue = format(new Date(formAnswer.dt_created), "dd/MM/yyyy HH:mm");
                            const value = condition.valueOptions !== undefined && condition.valueOptions.length >= 1 ? condition.valueOptions[0] : undefined;

                            if (value !== undefined && value.includes("-") && value !== "-4") {

                                const dynamicFilter = getDynamicDate(value);

                                if (dynamicFilter.isPeriod && dynamicFilter.endDate !== undefined) {

                                    const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));
                                    const dtStartDate = dynamicFilter.startDate;
                                    const dtEndDate = dynamicFilter.endDate;

                                    ret = (isBefore(dtItemDate, dtEndDate) || isSameDay(dtItemDate, dtEndDate)) && (isAfter(dtItemDate, dtStartDate) || isSameDay(dtItemDate, dtStartDate));

                                } else {

                                    const dtTargetDate = dynamicFilter.startDate;
                                    const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                    ret = isSameDay(dtItemDate, dtTargetDate);

                                }

                            } else if (condition.selectedComparator === "Igual a" && value !== undefined && value !== "") {

                                const dtTargetDate = new Date(convertDMYtoDate(value));
                                const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                ret = isSameDay(dtItemDate, dtTargetDate);

                            } else if (condition.selectedComparator === "Antes de" && value !== undefined && value !== "") {

                                const dtTargetDate = new Date(convertDMYtoDate(value));
                                const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                ret = isBefore(dtItemDate, dtTargetDate);

                            } else if (condition.selectedComparator === "Depois de" && value !== undefined && value !== "") {

                                const dtTargetDate = new Date(convertDMYtoDate(value));
                                const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                ret = isAfter(dtItemDate, dtTargetDate);

                            } else if (condition.selectedComparator === "Igual a ou depois" && value !== undefined && value !== "") {

                                const dtTargetDate = new Date(convertDMYtoDate(value));
                                const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                ret = isAfter(dtItemDate, dtTargetDate) || value === answerFieldValue;

                            } else if (condition.selectedComparator === "Igual a ou antes" && value !== undefined && value !== "") {

                                const dtTargetDate = new Date(convertDMYtoDate(value));
                                const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                ret = isBefore(dtItemDate, dtTargetDate) || value === answerFieldValue;

                            } else if (condition.selectedComparator === "Não é igual a" && value !== undefined && value !== "") {
                                ret = value !== answerFieldValue;
                            } else if (condition.selectedComparator === "Está em branco") {
                                ret = answerFieldValue === "" || answerFieldValue === undefined;
                            } else if (condition.selectedComparator === "Não  está em branco") {
                                ret = answerFieldValue !== "" && answerFieldValue !== undefined;
                            }

                        } else if (formAnswer.dt_created === undefined && condition.selectedComparator === "Está em branco") {
                            ret = true;
                        } else {
                            ret = false;
                        }

                    } else if (condition.selectedField.id_field === -2) { //Criado por

                        const answerFieldValue = formAnswer.user_created !== undefined ? formAnswer.user_created.toLocaleLowerCase().trim() : "";
                        const value = condition.value.toLocaleLowerCase().trim();

                        if (condition.selectedComparator === "Contém" && value !== "") {
                            ret = answerFieldValue.includes(value);
                        } else if (condition.selectedComparator === "Não contém" && value !== "") {
                            ret = !answerFieldValue.includes(value);
                        } else if (condition.selectedComparator === "Igual a" && value !== "") {
                            ret = answerFieldValue === value;
                        } else if (condition.selectedComparator === "Não é igual a" && value !== "") {
                            ret = answerFieldValue !== value;
                        } else if (condition.selectedComparator === "Está em branco") {
                            ret = answerFieldValue === "" || answerFieldValue === undefined;
                        } else if (condition.selectedComparator === "Não está em branco") {
                            ret = answerFieldValue !== "" && answerFieldValue !== undefined;
                        }

                    } else if (condition.selectedField.id_field === -3) { //Identificador

                        const answerFieldValue = formAnswer.id_form_answer !== undefined ? formAnswer.id_form_answer.toString().toLocaleLowerCase().trim() : "";
                        const value = condition.value.toLocaleLowerCase().trim();

                        if (condition.selectedComparator === "Contém" && value !== "") {
                            ret = answerFieldValue.includes(value);
                        } else if (condition.selectedComparator === "Não contém" && value !== "") {
                            ret = !answerFieldValue.includes(value);
                        } else if (condition.selectedComparator === "Igual a" && value !== "") {
                            ret = answerFieldValue === value;
                        } else if (condition.selectedComparator === "Não é igual a" && value !== "") {
                            ret = answerFieldValue !== value;
                        } else if (condition.selectedComparator === "Está em branco") {
                            ret = answerFieldValue === "" || answerFieldValue === undefined;
                        } else if (condition.selectedComparator === "Não está em branco") {
                            ret = answerFieldValue !== "" && answerFieldValue !== undefined;
                        }

                    } else if (condition.selectedField.id_field !== undefined && condition.selectedField.id_field > 0) { //Custom Field

                        formAnswer.form_answer_fields.map((answer_field) => {

                            if (answer_field.field_id === condition.selectedField.id_field) {

                                if (condition.nameComponent === "OPTION_FILTER") {

                                    const answerFieldValue = answer_field.value;
                                    let values = condition.valueOptions;

                                    //If is User Combo Box
                                    if (condition.selectedField !== undefined && condition.selectedField.type !== undefined && condition.selectedField.type === "COMBO_BOX_USER_FIELD") {
                                        //Valid if the values has the -1 value to change to the logged user
                                        if (values !== undefined && values.length > 0 && values.includes("-1")) {
                                            values = values.map((value) => {
                                                if (value === "-1") {
                                                    return String(user_id);
                                                }
                                                return value;
                                            })
                                        }
                                    }

                                    if (condition.selectedComparator === "Igual a" && values !== undefined && values.length > 0) {
                                        const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                        if (filterConditions.length >= 1) {
                                            ret = true;
                                        } else if (!findField && ret) {
                                            ret = false;
                                        }
                                    } else if (condition.selectedComparator === "Não é igual a" && values !== undefined && values.length > 0) {
                                        const filterConditions = values.filter((cond) => answerFieldValue === cond);

                                        if (filterConditions.length >= 1) {
                                            ret = false;
                                        } else {
                                            ret = true;
                                        }
                                    } else if (condition.selectedComparator === "É uma das" && values !== undefined && values.length > 0) {

                                        const filterConditions = values.filter((cond) => answer_field.value === cond);

                                        if (filterConditions.length >= 1) {
                                            ret = true;
                                        } else if (!findField && ret) {
                                            ret = false;
                                        }
                                    } else if (condition.selectedComparator === "Não é uma das" && values !== undefined && values.length > 0) {

                                        const filterConditions = values.filter((cond) => answer_field.value === cond);

                                        if (filterConditions.length >= 1) {
                                            ret = false;
                                        } else {
                                            ret = true;
                                        }
                                    } else if (condition.selectedComparator === "Está em branco") {
                                        ret = answerFieldValue === "" || answerFieldValue === undefined;
                                    }

                                } else if (condition.nameComponent === "DATE_FILTER") {

                                    const answerFieldValue = answer_field.valueString.toLocaleLowerCase();
                                    const value = condition.valueOptions !== undefined && condition.valueOptions.length >= 1 ? condition.valueOptions[0] : undefined;

                                    if (value !== undefined && value.includes("-") && value !== "-4") {

                                        const dynamicFilter = getDynamicDate(value);

                                        if (dynamicFilter.isPeriod && dynamicFilter.endDate !== undefined) {

                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));
                                            const dtStartDate = dynamicFilter.startDate;
                                            const dtEndDate = dynamicFilter.endDate;

                                            ret = (isBefore(dtItemDate, dtEndDate) || isSameDay(dtItemDate, dtEndDate)) && (isAfter(dtItemDate, dtStartDate) || isSameDay(dtItemDate, dtStartDate));

                                        } else {

                                            const dtTargetDate = dynamicFilter.startDate;
                                            const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                            ret = isSameDay(dtItemDate, dtTargetDate);

                                        }

                                    } else if (condition.selectedComparator === "Igual a" && value !== undefined && value !== "") {

                                        const dtTargetDate = new Date(convertDMYtoDate(value));
                                        const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                        ret = isSameDay(dtItemDate, dtTargetDate);

                                    } else if (condition.selectedComparator === "Antes de" && value !== undefined && value !== "") {

                                        const dtTargetDate = new Date(convertDMYtoDate(value));
                                        const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                        ret = isBefore(dtItemDate, dtTargetDate);

                                    } else if (condition.selectedComparator === "Depois de" && value !== undefined && value !== "") {

                                        const dtTargetDate = new Date(convertDMYtoDate(value));
                                        const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                        ret = isAfter(dtItemDate, dtTargetDate);

                                    } else if (condition.selectedComparator === "Igual a ou depois" && value !== undefined && value !== "") {

                                        const dtTargetDate = new Date(convertDMYtoDate(value));
                                        const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                        ret = isAfter(dtItemDate, dtTargetDate) || value === answerFieldValue;

                                    } else if (condition.selectedComparator === "Igual a ou antes" && value !== undefined && value !== "") {

                                        const dtTargetDate = new Date(convertDMYtoDate(value));
                                        const dtItemDate = new Date(convertDMYtoDate(answerFieldValue));

                                        ret = isBefore(dtItemDate, dtTargetDate) || value === answerFieldValue;

                                    } else if (condition.selectedComparator === "Não é igual a" && value !== undefined && value !== "") {
                                        ret = value !== answerFieldValue;
                                    } else if (condition.selectedComparator === "Está em branco") {
                                        ret = answerFieldValue === "" || answerFieldValue === undefined;
                                    } else if (condition.selectedComparator === "Não  está em branco") {
                                        ret = answerFieldValue !== "" && answerFieldValue !== undefined;
                                    }

                                } else {

                                    const answerFieldValue = answer_field.valueString.toLocaleLowerCase();
                                    const answerFieldValueNumber = answer_field.value;
                                    const value = condition.value.toLocaleLowerCase();

                                    if (condition.selectedComparator === "Contém" && value !== "") {
                                        ret = answerFieldValue.includes(value);
                                    } else if (condition.selectedComparator === "Não contém" && value !== "") {
                                        ret = !answerFieldValue.includes(value);
                                    } else if (condition.selectedComparator === "Igual a" && value !== "") {
                                        if (answer_field.field.type === 'CURRENCY_FIELD' || answer_field.field.type === 'NUMBER_FIELD') {
                                            ret = Number(answerFieldValueNumber) === Number(value);
                                        } else {
                                            ret = answerFieldValue === value;
                                        }
                                    } else if (condition.selectedComparator === "Não é igual a" && value !== "") {
                                        if (answer_field.field.type === 'CURRENCY_FIELD' || answer_field.field.type === 'NUMBER_FIELD') {
                                            ret = Number(answerFieldValueNumber) !== Number(value);
                                        } else {
                                            ret = answerFieldValue === value;
                                        }
                                    } else if (condition.selectedComparator === "Menor que" && value !== "") {
                                        ret = Number(answerFieldValueNumber) < Number(value);
                                    } else if (condition.selectedComparator === "Maior que" && value !== "") {
                                        ret = Number(answerFieldValueNumber) > Number(value);
                                    } else if (condition.selectedComparator === "Igual a ou maior que" && value !== "") {
                                        ret = Number(answerFieldValueNumber) >= Number(value);
                                    } else if (condition.selectedComparator === "Igual a ou menor que" && value !== "") {
                                        ret = Number(answerFieldValueNumber) <= Number(value);
                                    } else if (condition.selectedComparator === "Está em branco") {
                                        ret = answerFieldValue === "" || answerFieldValue === undefined;
                                    } else if (condition.selectedComparator === "Não está em branco") {
                                        ret = answerFieldValue !== "" && answerFieldValue !== undefined;
                                    }

                                }

                                findField = true;

                            }

                            return answer_field;
                        })

                    }

                    //If not find the field_id
                    if (!findField) {

                        if (condition.nameComponent === "OPTION_FILTER" || condition.nameComponent === "DATE_FILTER") {

                            if (condition.selectedComparator === "Igual a" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                ret = false;
                            } else if (condition.selectedComparator === "É uma das" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                ret = false;
                            } else if (condition.selectedComparator === "Antes de" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                ret = false;
                            } else if (condition.selectedComparator === "Depois de" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                ret = false;
                            } else if (condition.selectedComparator === "Igual a ou depois" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                ret = false;
                            } else if (condition.selectedComparator === "Igual a ou antes" && condition.valueOptions !== undefined && condition.valueOptions.length >= 1) {
                                ret = false;
                            } else if (condition.selectedComparator === "Não está em branco") {
                                ret = false;
                            }

                        } else {

                            if (condition.selectedComparator === "Contém" && condition.value !== "") {
                                ret = false;
                            } else if (condition.selectedComparator === "Igual a" && condition.value !== "") {
                                ret = false;
                            } else if (condition.selectedComparator === "Maior que" && condition.value !== "") {
                                ret = false;
                            } else if (condition.selectedComparator === "Menor que" && condition.value !== "") {
                                ret = false;
                            } else if (condition.selectedComparator === "Igual a ou maior que" && condition.value !== "") {
                                ret = false;
                            } else if (condition.selectedComparator === "Igual a ou menor que" && condition.value !== "") {
                                ret = false;
                            } else if (condition.selectedComparator === "Não está em branco") {
                                ret = false;
                            }

                        }

                    }

                    return ret
                })
            }

        }
    }

    return newFormAnswers;
}