import React, { createContext, useCallback, useContext, useState } from "react";

import api from '../services/api';
import getBrowser from "../utils/getBrowser";
import getOS from "../utils/getOS";
import { User } from "../interfaces/User";
import { useTracking } from "./tracking";
import { CompanyPlan } from "../interfaces/CompanyPlan";

interface AuthState {
    token: string;
    user: User;
}

interface SignInCredentials {
    email: string;
    password: string;
    isHashPassword?: boolean;
    hashOnboarding?: string;
    isOnboarding?: boolean;
    from?: string;
}

interface AuthContextData {
    user: User;
    plan?: CompanyPlan;
    token: string;
    signIn(credentials: SignInCredentials): Promise<User>;
    signOut(): void;
    renewUser(): void;
}

interface AuthProviderProps {
    children?: React.ReactNode;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {

    const { addTracking, removeTracking } = useTracking();

    const [data, setData] = useState<AuthState>(() => {
        const token = localStorage.getItem('@wendata:token');
        const user = localStorage.getItem('@wendata:user');

        if (token && user) {

            api.defaults.headers.authorization = `Bearer ${token}`;

            return { token, user: JSON.parse(user) };
        }

        return {} as AuthState;
    });



    const signIn = useCallback(async (credentials: SignInCredentials) => {

        const { email, password, isHashPassword, hashOnboarding, isOnboarding } = credentials;

        const response = await api.post('session', {
            email,
            password,
            isHashPassword,
            hashOnboarding
        })

        const { token, user } = response.data;

        delete user.password;

        const dataApi = {
            path_name: window.location.pathname,
            language: window.navigator.language,
            operating_system: getOS(window),
            browser: getBrowser(window),
            screen_width: window.screen.width,
            screen_height: window.screen.height,
            user_id: user.id_user
        }

        await api.post('/log-client-info', dataApi);

        localStorage.setItem('@wendata:token', token);
        localStorage.setItem('@wendata:user', JSON.stringify(user));
        localStorage.setItem('@wendata:sbIsCollapsed', "true");

        if (isOnboarding) {
            localStorage.setItem('@wendata:onboarding', "true");
            localStorage.setItem('@wendata:onboarding-invite-team', "false");
        }

        api.defaults.headers.authorization = `Bearer ${token}`;

        setData({ token, user });

        return user;

    }, []);

    const renewUser = useCallback(async () => {

        const marginHours = 6 * 60 * 60 * 1000; //6 hours in miliseconds 
        let isRenewToken = false;

        if (data.user !== undefined) {

            //Rule to renew the token
            const currentToken = localStorage.getItem("@wendata:token");
            if (currentToken !== undefined && currentToken !== null) {
                const tokenParse = JSON.parse(window.atob(currentToken.split('.')[1]));
                const decodedJwt = tokenParse;

                if (((decodedJwt.exp * 1000) - marginHours) < Date.now()) {
                    isRenewToken = true;
                }
            }

            if (!isRenewToken) {
                await api.get(`/user/open`, {
                    params: {
                        id_user: data.user.id_user,
                        withTracking: 'true'
                    }
                }).then(response => {
                    if (response.data !== null) {
                        const userApi: User = response.data;

                        if (userApi !== undefined) {

                            if (JSON.stringify(data.user) !== JSON.stringify(userApi)) {

                                localStorage.setItem('@wendata:user', JSON.stringify(userApi));

                                const currentToken = localStorage.getItem("@wendata:token");                            

                                if (currentToken !== null) {
                                    setData({ token: currentToken, user: userApi });
                                }

                            }

                            //Set the tracking - With Validations
                            if (userApi.tracking !== undefined && userApi.tracking !== null && userApi.tracking.length > 0) {

                                const trackingRunning = userApi.tracking[0];

                                if (trackingRunning !== undefined && trackingRunning.id_time_tracking !== undefined) {
                                    addTracking(trackingRunning);
                                } else {
                                    removeTracking();
                                }

                            } else {
                                removeTracking();
                            }

                        }

                    }
                });
            } else { //Renew token

                await api.post(`/user/renew`, {}).then(response => {
                    if (response.data !== null) {
                        const { token, user } = response.data;

                        localStorage.setItem('@wendata:token', token);
                        localStorage.setItem('@wendata:user', JSON.stringify(user));

                        api.defaults.headers.authorization = `Bearer ${token}`;                        

                        setData({ token, user });

                    }
                });

            }

        }

    }, [data.user, addTracking, removeTracking]);

    const signOut = useCallback(() => {
        localStorage.removeItem('@wendata:token');
        localStorage.removeItem('@wendata:user');

        removeTracking();

        setData({} as AuthState);
    }, [removeTracking]);

    return (
        <AuthContext.Provider value={{ user: data.user, signIn, signOut, renewUser, token: data.token }}>
            {children}
        </AuthContext.Provider>
    );
};

function useAuth(): AuthContextData {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
}

export { AuthProvider, useAuth, };

