import styled from "styled-components";

export const Container = styled.div`

    h1 {
        font-size: 16px;
        font-weight: 500;
        color: gray;
        padding-left: 5px;
        margin-bottom: 15px;
    }
`;