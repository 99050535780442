import styled, { css } from "styled-components";

interface ElementItemProps {
    color: string;
}

interface ElementItemIconProps {
    rotation?: number;
}

export const ElementItem = styled.div<ElementItemProps>`
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    grid-column: span 1 / auto;
    margin: 10px;
    min-height: 90px;
    padding: 10px;
    color: #f23b5c;
    border: 1px solid;
    background-color: transparent;

    ${(props) => props.color && css`
        color: ${props.color};
    `}

    :hover {

        ${(props) => props.color && css`
            color: white !important;
            background-color: ${props.color};

            div {
                color: white !important;
            }
        `}

    }
`;

export const ElementItemIcon = styled.div<ElementItemIconProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    svg {
        font-size: 30px;
    }

    ${(props) => props.rotation && css`        
        transform: rotate(${props.rotation}deg);
    `}
`;

export const ElementItemTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: black;
    text-align: center;
    font-weight: 500;
`;
