import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    Animation,
    Container,
    ContainerBody,
    ContainerEmpty,
    ContainerIlustration,
    ContainerInfo
} from "./style";
import { Flow } from "../../../interfaces/Flow";
import lottie from "lottie-web";
import api from "../../../services/api";
import AutomationLog from "../../../interfaces/AutomationLog";
import { useToast } from "../../../hooks/toast";
import { ContainerLoader } from "../style";
import LogAutomationItem from "./LogAutomationItem";
import { formatDateToDateRead } from "../../../utils/formatDateToDateRead";
import emptyAutomations from '../../../assets/empty-state/empty-automations.png';

interface RunHistoryProps {
    open: boolean;
    flow: Flow;
    automation_id?: number;
    days: number;
}

const RunHistory: React.FC<RunHistoryProps> = ({ open, flow, automation_id, days }) => {

    const [loading, setLoading] = useState<boolean>();
    const lottieContainer = useRef<HTMLDivElement>(null);

    const { addToast } = useToast();

    const [logAutomations, setLogAutomations] = useState<AutomationLog[]>([]);
    const [expandedGroups, setExpandedGroups] = useState<string[]>([]);

    const toggleGroup = (key: string) => {
        if (expandedGroups.includes(key)) {
            setExpandedGroups(expandedGroups.filter((group) => group !== key));
        } else {
            setExpandedGroups([...expandedGroups, key]);
        }
    };

    const getAutomationsLog = useCallback(async () => {

        setLoading(true);

        await api.get(`/automation/run-history`, {
            params: {
                flow_id: flow.id_flow,
                automation_id: automation_id,
                days: days
            }
        }).then(response => {
            if (response.data !== null) {
                let resp: AutomationLog[] = response.data;

                //Fill the dateGroup and dateTimeReadable fields            
                resp.forEach((log) => {
                    log.dayGroup = formatDateToDateRead(new Date(log.dt_started));
                    log.dateTimeRedable = formatDateToDateRead(new Date(log.dt_started), true);
                });

                setLogAutomations(resp);
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            addToast({
                type: "error",
                title: "Erro ao buscar histórico de execução.",
                description: "Ao tentar buscar o histórico de execução, ocorreu um erro."
            });
        });

    }, [addToast, automation_id, days, flow.id_flow]);

    useEffect(() => {

        if (open) {
            getAutomationsLog();
        }

    }, [open, getAutomationsLog]);

    useEffect(() => {

        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../assets/lottie/loader.json')
            });
        }

    }, [loading]);

    return (
        <Container>

            {!loading && logAutomations.length === 0 && (
                <ContainerEmpty>
                    <ContainerIlustration>
                        <img src={emptyAutomations} alt="Sem Automações" />
                    </ContainerIlustration>
                    <ContainerInfo>
                        <h3>Você ainda não possui logs de execução neste fluxo</h3>
                        <p>Durante a execução das automações, os registros detalhados do processo serão exibidos nesta área.</p>
                    </ContainerInfo>
                </ContainerEmpty>
            )}

            {loading ?
                <ContainerLoader>
                    <Animation className="lottieContainer" ref={lottieContainer} />
                </ContainerLoader > :
                <>
                    <ContainerBody>
                        {logAutomations !== undefined && logAutomations.length > 0 && logAutomations.map((log, index) => {

                            let isExpanded = expandedGroups.includes(String(log.id_automation_log));

                            return (
                                <LogAutomationItem
                                    key={index}
                                    logAutomation={log}
                                    isExpanded={isExpanded}
                                    isShowAutomationName={automation_id === undefined}
                                    onExpand={toggleGroup}
                                />
                            )
                        })}
                    </ContainerBody>
                </>
            }

        </Container>
    );

}

export default RunHistory;