import { Grid } from "@material-ui/core";
import styled, { css } from "styled-components";
import Card from "../../../dialogs/Card";

interface CellStepProps {
    color?: string;
}

interface RegisterDataProps {
    color?: string;
}

export const Container = styled.div`
    height: calc(100% - 60px);
`;

export const ContainerFields = styled(Grid)`
    background-color: white;
    width: 100%;
    border-radius: 10px 10px 10px 10px;    
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
`;

export const ContainerList = styled.div`    
    overflow: auto;
`;

export const CellStep = styled.div<CellStepProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    font-size: 13px;
    color: white;
    font-weight: 500;
    border-radius: 10px;
    white-space: nowrap;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 7px;
        padding: 0px !important;
        
        svg {
            font-size: 13px;
        }
        
    }
    
    ${(props) => props.color && css`
        background-color: ${props.color};
    `}
`;

export const AvatarAdd = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed gray;
    padding: 9px;
    border-radius: 20px;
    font-size: 13px;

    svg {
        color: gray;
    }
    
`;

export const CellDueAdd = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed gray;
    padding: 7px;
    border-radius: 20px;
    font-size: 13px;
    width: 70px;

    svg {
        color: gray;        
    }
    
`;

export const CellTagAdd = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed gray;
    padding: 5px;
    border-radius: 20px;
    font-size: 15px;
    width: 70px;

    svg {
        color: gray;        
    }
    
`;

export const CellTag = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const RegisterData = styled.span<RegisterDataProps>`
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 5px;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 5px;
    color: rgba(51,51,51,0.9);
    background-color: #4681b81e;

    ${(props) => props.color && css`
      background-color: ${props.color + "1e"};
    `}
`

export const CardDialogContainer = styled(Card)`

`;