import Grid from '@material-ui/core/Grid';
import styled from "styled-components";

export const ContentList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
`;

export const BreakArea = styled.div`
    margin-bottom: 30px !important;
`;

export const ContainerFields = styled(Grid)`
    background-color: white;
    min-width: 600px;
    border-radius: 10px 10px 10px 10px;
    padding-right: 4%;
    padding-left: 4%;
    padding-top: 1%;
    padding-bottom: 2%;
    display: flex;
    justify-content: center;
    height: auto;
`;