import React, { useCallback, useRef, useState } from "react";

import {
    Content,
    ContentBody,
    DialogTop,
    DialogTopLeft,
    DialogTopCenter,
    DialogTopRight,
    IconHeader,
    DialogFooter,
    DialogFooterCenter,
    BtnInsertNewAnswer,
    ContainerAdrress,
    TitleAddress,
    CopyBtn,
    HelpContainer,
    HelpItems,
    HelpItem,
    HelpIcon,
    HelpText,
    AddressValue,
    Address
} from "./style";

import { Dialog } from '@material-ui/core';
import { AiOutlineClose } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { IoIosApps } from "react-icons/io";
import { FieldProps, Fields } from "../../../../components/Forms/Fields/FieldBuilder";
import { FormHandles } from "@unform/core";
import { useToast } from "../../../../hooks/toast";
import api from "../../../../services/api";
import FormBuilder from "../../../../components/Forms/FormBuilder";
import { TbAlertCircle, TbCheck, TbCircleKey, TbCopy } from "react-icons/tb";

const fieldsForm: FieldProps[] = [
    {
        name: "name_token",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 0,
        title: "Nome do token",
        description: "Digite um nome para identificar o token",
        placeholder: "Para que será usado o token?",
        max_length: 74,
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nova Nome do token é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome do token deve conter mais de 2 caracteres!"]
            }
        ]
    }
]

interface AddNewTokenObjectProps {
    name_token: string;
}

interface AddNewTokenProps {
    open: boolean;
    onClose: () => void;
}

const AddNewToken: React.FC<AddNewTokenProps> = ({ onClose, open }) => {

    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();

    const [statusModal, setStatusModal] = useState<number>(1); // 1 - Form / 2 - Sucess
    const [loading, setLoading] = useState<boolean>(false);
    const [token, setToken] = useState<string>();
    const [copied, setCopied] = useState<boolean>(false);

    const handleCopyClick = () => {

        if (token !== undefined) {
            navigator.clipboard.writeText(token);

            setCopied(true);
        }

    }

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    const handleSubmitForm = useCallback(async (data: object[]) => {

        if (formRef.current !== null) {

            const dataNormalized = formRef.current.getData() as unknown as AddNewTokenObjectProps;

            if (dataNormalized.name_token === undefined) {
                addToast({
                    type: 'error',
                    title: 'Erro ao criar o token!',
                    description: 'O campo Nome do token é obrigatório!'
                });
                return;
            }

            setLoading(true);

            await api
                .post('/user/apikey', {
                    name: dataNormalized.name_token
                })
                .then(response => {

                    const retToken = response.data as string;

                    if (retToken !== undefined) {
                        setToken(retToken);
                        setStatusModal(2);
                    }

                    formRef.current?.reset();
                    setLoading(false);
                }).catch(error => {
                    setLoading(false);
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar a senha :[',
                        description: 'Ocorreu um erro ao salvar a senha na base de dados!',
                    });
                });


        }

    }, [addToast]);

    const handleSubmitPage = useCallback(async () => {
        if (formRef.current !== undefined) {
            formRef.current?.submitForm();
        }
    }, []);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={"gray"}>
                        <div>
                            <IoIosApps />
                        </div>
                    </IconHeader>
                    <h1>
                        {"Criar novo token"}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>

            <Content>
                <ContentBody container>

                    {statusModal === 1 ?
                        <FormBuilder
                            id="form"
                            formRef={formRef}
                            fields={fieldsForm}
                            handleSubmit={handleSubmitForm}
                            hideContainer={true}
                        /> : statusModal === 2 &&
                        <>
                            <HelpContainer>
                                <HelpItems>
                                    <HelpItem>
                                        <HelpIcon>
                                            <TbAlertCircle />
                                        </HelpIcon>
                                        <HelpText>
                                            Atenção! Copie agora este token e salve em um local seguro, pois ele não será exibido novamente.
                                        </HelpText>
                                    </HelpItem>
                                </HelpItems>
                            </HelpContainer>

                            <ContainerAdrress>
                                <TitleAddress>
                                    <TbCircleKey />
                                    <h3>Chave do Campo</h3>
                                </TitleAddress>
                                <AddressValue>
                                    <Address>{token}</Address>
                                    <CopyBtn onClick={handleCopyClick}>
                                        {copied ?
                                            <>
                                                <TbCheck />
                                                Copiado
                                            </> :
                                            <>
                                                <TbCopy />
                                                Copiar
                                            </>
                                        }
                                    </CopyBtn>
                                </AddressValue>
                            </ContainerAdrress>
                        </>
                    }

                </ContentBody>


            </Content>
            <DialogFooter>
                <DialogFooterCenter>
                    {statusModal === 1 ?
                        <BtnInsertNewAnswer color={"#f23b5c"} icon={BiSave} isLoading={loading} onClick={() => handleSubmitPage()}>
                            Salvar
                        </BtnInsertNewAnswer> :
                        statusModal === 2 &&
                        <BtnInsertNewAnswer color={"#f23b5c"} icon={BiSave} isLoading={loading} onClick={() => onClose()}>
                            Fechar
                        </BtnInsertNewAnswer>
                    }
                </DialogFooterCenter>
            </DialogFooter>
        </Dialog >
    );

}

export default AddNewToken;