import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    border: 1px solid #80808045;
    border-radius: 5px;
    margin-bottom: 10px;
`;

export const ConfigSectionTitle = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    border-bottom: 1px solid #dddddd;
    color: #4d4d4d;
    font-weight: 500;
    position: relative;
    padding: 10px;

    svg { 
        margin-right: 10px;
    }
`;

export const ConfigSectionContent = styled.div`
    padding: 15px 10px;
`;
