import styled from "styled-components";

export const Container = styled.div`
    padding: 10px;
    padding-top: 0px;
`;

export const ContainerButton = styled.div`
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #eef2f8;
`;

export const EmailButton = styled.button`
    font-weight: 500;
    padding: 3px 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #9e37ed;
    border: none;
    background-color: white;
    border-radius: 10px;

    svg {
        margin-right: 5px;
    }

    :hover {
        background-color: #9e37ed;
        color: white;        
    }
`;

export const ContainerAdrress = styled.div`
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #eef2f8;
`;

export const TitleAddress = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;

    h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;
        margin-top: 2px;
        font-size: 12px;
        color: #84939f;
        font-weight: 500;
        text-transform: uppercase;
    }

    svg {
        min-width: 15px;
        font-size: 15px;
        font-weight: 600;
        color: #84939f;
    }

`;

export const AddressValue = styled.div`
    padding: 10px;
    background-color: #f4f5f7;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`;

export const Address = styled.div`
    margin-right: 10px;
    font-size: 12px;
    overflow: hidden;
`;

export const CopyBtn = styled.button`
    position: absolute;
    right: 10px;
    font-weight: 500;
    padding: 3px 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #9e37ed;
    border: none;
    background-color: #f4f5f7;

    svg {
        margin-right: 5px;
    }

    :hover {
        background-color: #9e37ed;
        color: white;
        border-radius: 10px;
    }
`;

export const ContainerEmails = styled.div`
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #eef2f8;
`;

export const EmailTitleContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative; 
`;

export const EmailTitle = styled.div`
    font-weight: 500;
    color: black;
    margin-left: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
`;

export const EmailTotal = styled.div`
    position: absolute;
    right: 0px;
    font-size: 10px;
    margin-right: 5px;
    background-color: #f6ebfd;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 500;
`;

export const DividerGroup = styled.div`
    border: 1px solid #eef2f8;
    width: 100%;
    margin-top: 10px;
`;

export const TagOrigin = styled.span`
    font-size: 10px;
    margin-right: 5px;
    background-color: #f6ebfd;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 400;
    text-transform: uppercase;
    color: black;
    margin-left: 5px;
`;

export const EmailBody = styled.div``;

export const CleanBox = styled.div`
    display: grid;
    justify-items: center;
    align-content: center;
    padding: 10px;
    margin: 10px;
    margin-top: 20px;
    border-radius: 10px;
    border: 1px dashed #80808059;
    min-height: 250px;
    font-size: 15px;
    color: gray;

    svg {
        font-size: 40px;
        margin-bottom: 10px;
    }
`;

export const CleanBoxText = styled.div`
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 13px;
    margin-top: 2px;
`;