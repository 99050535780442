import styled, { css } from "styled-components";

interface BarValueProps {
    barValue: number | string;
}

interface ValueItemProps {
    hideTarget?: boolean;
}

export const Container = styled.div`
    width: 200px;
    min-width: 230px;
    height: 66px;
    border: 1px solid whitesmoke;
    border-radius: 5px;
    display: block;
    margin-right: 15px;
    padding: 10px;
    position: relative;
`;

export const NameItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    div {
        font-size: 14px;
        font-weight: 500;
        color: black;
    }
`;

export const ValueContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    right: 10px;
    top: 10px;
    position: absolute;
`;

export const ValueItem = styled.div<ValueItemProps>`
    font-size: 18px;
    font-weight: 500;
    color: black;

    ${props => props.hideTarget && css`
        font-size: 35px;
    `}
`;

export const TargetItem = styled.div`
    font-size: 15px;
    font-weight: 500; 
    color: black;
`;

export const BarItem = styled.div`
    width: 100%;
    height: 10px;
    border-radius: 20px;
    margin-top: 15px;
    border: 1px solid whitesmoke;
`;

export const BarValue = styled.div<BarValueProps>`
    width: 70%;
    height: 9px;
    border-radius: 20px;
    //background: linear-gradient(90deg,#ff8c2f 25%,#fb325c 50%,#9337ed 75%);
    background-color: #fb325c;

    ${props => props.barValue && css`
        width: ${(props.barValue) + "%"};
    `}

    max-width: 100% !important;
`;

export const HelpText = styled.button`
  margin-left: 5px;
  display: inline-block;
  background-color: transparent;
  border: none;

  svg {
    margin: 0;
    font-size: 13px;
  }
`;