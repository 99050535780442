import Grid from '@material-ui/core/Grid';
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const Content = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: auto;
`;