import styled from "styled-components";

export const Container = styled.div`
    margin-left: 5px;
`;

export const NoCommentData = styled.div`
    height: 100%;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #eef2f8;
    font-size: 13px;
    color: #838383;
    margin-top: -10px;
`;