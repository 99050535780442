import { DataElementProps } from "../..";
import { DataProps } from "../..";
import EmptyData from "../../../EmptyState/EmptyData";
import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { colorsElement } from "../../getColorsElement";
import getChartFormatValue from "../../getChartFormatValue";

interface ColumnChartProps {
    dataElement?: DataProps[];
    colors?: string[];
    show_time?: string;
    type: "vertical" | "horizontal";
    typeField?: string;
    axis_y_field_id?: number;
    axis_y_field_variation?: string;
    axis_x_field_id?: number;
    axis_y_field_type?: string;
    axis_x_field_type?: string;
    color?: string;
}

const dataDefault = [
    {
        id: "Item 1",
        label: "Item 1",
        value: 80,
    },
    {
        id: "Item 2",
        label: "Item 2",
        value: 110,
    },
    {
        id: "Item 3",
        label: "Item 3",
        value: 20,
    },
    {
        id: "Item 4",
        label: "Item 4",
        value: 150,
    },
    {
        id: "Item 5",
        label: "Item 5",
        value: 12,
    }
];

const ColumnChart: React.FC<ColumnChartProps> = ({ dataElement, colors, show_time, type, axis_y_field_id, color, axis_y_field_type, axis_y_field_variation }) => {

    const [data, setData] = useState<DataElementProps[]>();
    const [margin, setMargin] = useState({ top: 10, right: 20, bottom: 20, left: 50 });

    useEffect(() => {

        if (type === "horizontal") {
            //Get the max length from the label
            let maxLenLabel: number = 0;

            if (dataElement !== undefined && dataElement !== null && dataElement.length > 0) {
                dataElement?.map((de) => {
                    if (de.label.toString().length > maxLenLabel) {
                        maxLenLabel = de.label.length;
                    }
                    return de;
                });
            }

            setMargin({ top: 20, right: 20, bottom: 50, left: ((maxLenLabel * 7) + 15) })
        }

        if (dataElement !== undefined && dataElement !== null && dataElement.length > 0) {

            const newData = dataElement?.map((m) => {

                delete m.labelAux;

                return m;
            })

            setData(newData as DataElementProps[]);

        }

    }, [dataElement, type])

    return (
        <>
            {dataElement !== undefined && dataElement?.length > 0 ?
                <ResponsiveBar
                    data={data !== undefined ? data : dataDefault}
                    keys={["value"]}
                    indexBy="id"                    
                    label={d => {

                        if (show_time !== undefined && axis_y_field_id === -2) {
                            if (show_time === "day") {
                                return d.data.value + " Dias";
                            } else if (show_time === "hour") {
                                return d.data.value + " Horas";
                            } else if (show_time === "min") {
                                return d.data.value + " Minutos";
                            } else if (show_time === "sec") {
                                return d.data.value + " Segundos";
                            }
                        }

                        //return d.data.value + "";
                        return getChartFormatValue(d.data.value, axis_y_field_type, 2, axis_y_field_variation)
                    }}

                    //Static Down
                    tooltipLabel={d => {

                        if (show_time !== undefined && axis_y_field_id === -2) {
                            if (show_time === "day") {
                                return d.data.label + " (Dias)";
                            } else if (show_time === "hour") {
                                return d.data.label + " (Horas)";
                            } else if (show_time === "min") {
                                return d.data.label + " (Minutos)";
                            } else if (show_time === "sec") {
                                return d.data.label + " (Segundos)";
                            }
                        }

                        return d.data.label + "";
                    }}
                    margin={margin}
                    padding={0.1}
                    layout={type}
                    borderRadius={5}
                    valueScale={{ type: "linear" }}
                    indexScale={{ type: "band", round: true }}
                    colors={color !== undefined ? [color] : colorsElement}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: "middle",
                        legendOffset: 32,
                        format: value => {
                            if (type === "horizontal") {
                                return getChartFormatValue(value, axis_y_field_type, 0, axis_y_field_variation);
                            } else {
                                return value;
                            }                                                    
                        },
                    }}
                    valueFormat={value => getChartFormatValue(value, axis_y_field_type, 2, axis_y_field_variation)}
                    axisLeft={{
                        tickSize: 0,
                        tickPadding: 10,
                        tickRotation: 0,
                        legendPosition: "middle",
                        legendOffset: -40,
                        format: value => {
                            if (type === "vertical") {
                                return getChartFormatValue(value, axis_y_field_type, 0, axis_y_field_variation);
                            } else {
                                return value;
                            }                                                    
                        },
                    }}
                    theme={{
                        fontSize: 12,
                        fontFamily: "Roboto"

                    }}
                    labelSkipWidth={15}
                    labelSkipHeight={15}                                                       
                    labelTextColor={{
                        from: "color",
                        modifiers: [["brighter", 10]]
                    }}
                    role="application"
                /> :
                <EmptyData />
            }
        </>
    );

}

export default ColumnChart;