import styled from "styled-components";
import { Box } from "@material-ui/core";

import Button from "../../../../Button";

interface ContainerProps {
    isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  padding: 5px 0px 0px 0px;
`;

export const Title = styled.div`
  color: gray;
`;

export const ButtonActionAdd = styled(Button)`
    height: 35px;
    margin-bottom: 10px;
    font-weight: 500;
    justify-content: flex-start;
    overflow: hidden;
    font-size: 15px;
    color: #4d4d4d;

    svg {
        width: 15px;
    }
`;

export const ButtonAddUser = styled.button`
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin-top: 5px;
`;

export const ButtonAddLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    margin-top: 1px;

    svg {
        height: 15px;
        width: 15px;
        color: #f23b5c;
    }
`;

export const ButtonAddRight = styled.div`
    color: #f23b5c;
    font-size: 15px;
    font-weight: 400;
    margin-left: 5px;
`;

export const BoxContainer = styled(Box)`
    //position: absolute;
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    z-index: 1;
    min-width: 250px;
    max-width: 300px;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    margin-top: 15px;
    padding-bottom: 10px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-right: 25px;
`;

export const BoxItemsContainer = styled.div`
    max-height: 170px;
    overflow-y: auto;
`;

export const BoxUserSelected = styled.div`
    //margin-top: 15px;
`;

export const BoxUserRemove = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 50px;
    padding: 5px;

    svg {
        height: 15px;
        width: 15px;
        color: black;
    }

    :hover {
        background-color: white;
    }
`;

export const BoxUserItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px 5px 10px;
    border: 1px dashed transparent;

    :hover {
        background-color: #f4f5f7;
        border-radius: 15px;
        border: 1px dashed #e8f2ff;
    }
`;

export const BoxUserLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BoxUserRigth = styled.div`
    margin-left: 15px;
    width: 100%;
`;

export const BoxUserName = styled.div`
    font-size: 14px;
    color: black;
    font-weight: 400;
    overflow: hidden;
`;

export const BoxUserMail = styled.div`
    font-size: 11px;
    color: gray;
    overflow: hidden;
`;

export const BoxSearchContainer = styled.div`
    padding: 10px 10px 10px 10px;
`;

export const SearchInput = styled.input`
    width: 100%;
    height: 35px;
    padding: 5px 15px 5px 15px;
    border-radius: 15px;
    border: 1px solid #f4f5f7;
    background-color: #f4f5f7;
    font-size: 14px;
`;
