import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    Animation,
    Container,
    ContainerBottomText,
    ContainerError,
    ContainerErrorBox,
    ContainerErrorButton,
    ContainerErrorDescription,
    ContainerErrorIcon,
    ContainerErrorTitle,
    ContainerLoader,
    ContainerLoading
} from "./style";
import { useRouteMatch } from "react-router-dom";
import api from "../../services/api";
import { Form } from "../../interfaces/Form";
import PublicForm from "../../components/PublicForm";
import { TbError404Off } from "react-icons/tb";

import logoCange from '../../assets/wendata-logo.png';
import Lottie from "lottie-web";

interface RouteParams {
    hash?: string;
    id?: string;
}

const PublicFormPage: React.FC = () => {

    const { params } = useRouteMatch<RouteParams>();

    const lottieContainer = useRef<HTMLDivElement>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [form, setForm] = useState<Form>();
    const [idFlow, setIdFlow] = useState<number>();
    const [notFoundPage, setNotFoundPage] = useState<boolean>(false);

    const getFormApi = useCallback(async () => {

        if (params.hash !== undefined) {

            setLoading(true);

            //Get form data
            await api.get(`/form-public`, {
                params: {
                    public_hash: String(params.hash),
                    public_hash_id: params.id,
                }
            }).then(response => {
                if (response.data !== null) {

                    let respForm: Form = response.data;

                    if (respForm !== undefined) {

                        setForm(respForm);

                        if (respForm.flow !== undefined) {
                            setIdFlow(respForm.flow.id_flow);
                        } else if (respForm.flow_step !== undefined) {
                            setIdFlow(respForm.flow_step.flow_id);
                        }
                    
                        if (respForm.fields === undefined || respForm.fields.length === 0) { //If form has no fields
                            setNotFoundPage(true);
                        } else if (params.id !== undefined) { //If has id, is form step

                            if (respForm.card === undefined || respForm.card === null) { //If not found the card
                                setNotFoundPage(true);                                                   
                            } else if (respForm.card.flow_step?.form_id !== respForm.id_form) { //If it is not in the same step                            
                                setNotFoundPage(true);                                
                            } else if (respForm.card.complete === 'S') { //If the card is complete
                                setNotFoundPage(true);                                
                            } else if (respForm.card.archived === 'S') { //If the card is archived
                                setNotFoundPage(true);                                
                            }

                        }

                    }
                }

                setLoading(false);
            }).catch(error => {
                setLoading(false);
                console.log(error);
                setNotFoundPage(true);
            });
        }

    }, [params.hash, params.id]);

    useEffect(() => {

        if (params.hash !== undefined) {
            getFormApi();
        } else {
            setNotFoundPage(true);
        }

    }, [params.hash, getFormApi]);

    useEffect(() => {

        if (lottieContainer.current) {
            Lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../assets/lottie/loader.json')
            });
        }

    }, [loading]);

    return (
        <>
            {loading ?
                <title>Carregando...</title> :
                notFoundPage ?
                    <title>Formulário não encontrado</title> :
                    <title>{form !== undefined && form.title !== undefined && form.title !== null && form.title !== '' ? form?.title : "Formulário"}</title>
            }

            {loading ?
                <ContainerLoading>
                    <ContainerLoader>
                        <Animation className="lottieContainer" ref={lottieContainer} />
                    </ContainerLoader >
                </ContainerLoading> :
                notFoundPage ?
                    <ContainerError>
                        <ContainerErrorBox>
                            <ContainerErrorIcon>
                                <TbError404Off />
                            </ContainerErrorIcon>
                            <ContainerErrorTitle>
                                Não foi possível encontrar este formulário
                            </ContainerErrorTitle>
                            <ContainerErrorDescription>
                                Pode ser que o link esteja incorreto ou o formulário tenha sido desativado  pelo criador.
                            </ContainerErrorDescription>
                            <ContainerErrorButton href={"https://www.cange.me?utm_source=cange&utm_campaign=public-form-error"} rel="noreferrer" target="_blank">
                                Voltar para o Cange
                            </ContainerErrorButton>
                        </ContainerErrorBox>

                        <ContainerBottomText href={"https://www.cange.me?utm_source=cange&utm_campaign=public-form"} rel="noreferrer" target="_blank">
                            <img src={logoCange} alt="Logo Cange" />
                        </ContainerBottomText>
                    </ContainerError> :
                    <Container>

                        {form !== undefined && idFlow !== undefined && form.fields !== undefined && form.fields.length > 0 && (
                            <PublicForm
                                form={form}
                                flow_id={idFlow}
                            />
                        )}

                    </Container>
            }
        </>

    );

}

export default PublicFormPage;