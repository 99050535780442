import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    margin: 7px !important;
    font-size: 13px;
    font-weight: 500;
    color: red;

    svg {
        height: 15px;
        width: 15px;
    }

    div {
        margin-left: 5px;
        margin-top: 1px;
    }

`;