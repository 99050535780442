import styled from 'styled-components';

export const Input = styled.input`
    height: 35px;
    border-radius: 0px 5px 5px 0px;
    border: solid 1px #808080bf;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 200px;

    ::placeholder {
        font-size: 14px;
        padding: 5px;
        color: gray;
    }
`;