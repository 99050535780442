import styled from "styled-components";

export const Container = styled.div`
    overflow-wrap: break-word;
    word-break: break-word;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    a {
        width: calc(100% - 15px);
    }

    :hover {
        margin-left: -25px;

        .btn-copy-link {
            display: flex;
        }
    }
`;

export const CopyBtn = styled.button`    
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    color: #f23c5d;
    display: none;
    padding-top: 2px;
    font-size: 15px;
    overflow-wrap: normal;
    word-break: normal;

    transition: transform 0.3s ease;

    span {
        display: none;
        font-size: 12px;
        color: #f23c5d;
    }

    :hover {
        transform: scale(1.1);
        color: #f23c5d;

        svg {
            margin-right: 5px;
        }

        span {
            display: flex;
        }
    }
`;