import React, { useEffect, useCallback, useState } from "react";
import {
    Container,
    ContainerGroup,
    GroupHeader,
    GroupName,
    GroupItems,
    GroupBody,
    DividerGroup,
    GroupLoading,
    TagOrigin,
    ContainerOptions,
    ButtonGroupLeft,
    ButtonGroupRight
} from "./style";
import { FormAnswerField } from "../../../interfaces/FormAnswerField";
import api from "../../../services/api";
import { Card } from "../../../interfaces/Card";
import ItemRegister, { ComboItem } from "../../../components/ItemRegister";
import formAnswerToComboItemFromFlow from "../../../utils/formAnswerToComboItemFromFlow";
import ContentLoader from "react-content-loader";
import { FormAnswer } from "../../../interfaces/FormAnswer";
import formAnswerToComboItemFromRegister from "../../../utils/formAnswerToComboItemFromRegister";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";

interface RelationshipTabProps {
    id_form_answer?: number;
    register_id?: number;
    formAnswerFields?: FormAnswerField[];
    totConections?: number;
}

const LoaderContainer = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={'100%'}
        viewBox="0 0 500 210"
        backgroundColor="#ffffff"
        foregroundColor="#f3f3f3"
    >
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="200" />
    </ContentLoader>
)

const RelationshipTab: React.FC<RelationshipTabProps> = ({ formAnswerFields, id_form_answer, register_id, totConections }) => {

    const [itemsFlow, setItemsFlow] = useState<ComboItem[]>([]);
    const [itemsRegister, setItemsRegister] = useState<ComboItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
    const [withArchived, setWithArchived] = useState<number>(0);

    const toggleGroup = (flowName: string) => {
        if (expandedGroups.includes(flowName)) {
            setExpandedGroups(expandedGroups.filter((group) => group !== flowName));
        } else {
            setExpandedGroups([...expandedGroups, flowName]);
        }
    };

    function compareItems(a: ComboItem, b: ComboItem): number {
        // Critério 1: Items com o campo completed igual a "S"
        if (a.complete === 'S' && b.complete !== 'S') {
            return 1; // a vem antes de b
        }
        if (a.complete !== 'S' && b.complete === 'S') {
            return -1; // a vem depois de b
        }

        // Critério 3: Items com o campo due_date_completed mais antigos
        if (a.due_date_completed && b.due_date_completed) {
            const dateA = new Date(a.due_date_completed).getTime();
            const dateB = new Date(b.due_date_completed).getTime();
            return dateA - dateB; // Ordenação ascendente por data
        }

        // Critério 2: Items com o campo due_date_completed igual a null ou undefined
        if (!a.due_date_completed && b.due_date_completed) {
            return 1; // a vem antes de b
        }
        if (a.due_date_completed && !b.due_date_completed) {
            return -1; // a vem depois de b
        }

        if (a.id_register !== undefined && b.id_register !== undefined) {
            return a.id_register - b.id_register;
        }

        // Se todos os critérios forem iguais, mantém a ordem original
        return 0;

    }

    const getApiRelationship = useCallback(async () => {

        if (id_form_answer !== undefined && register_id !== undefined) {

            setLoading(true);

            await api.get(`/register/relationship`, {
                params: {
                    id_form_answer: id_form_answer,
                    id_register: register_id,
                    withCards: "S",
                    removeArchived: withArchived === 0 ? "S" : "N"
                }
            }).then(response => {
                if (response.data !== null) {

                    let apiRetCard: Card[] = response.data.cards;
                    let apiRetRegister: FormAnswer[] = response.data.registerAnswers;
                    let apiRetRelationship: FormAnswerField[] = response.data.formsAnswerFields;
                    let newComboItemsFlow: ComboItem[] = [];
                    let newComboItemRegister: ComboItem[] = [];

                    if (apiRetCard !== undefined && apiRetCard.length > 0) {
                        for (let index = 0; index < apiRetCard.length; index++) {
                            const card = apiRetCard[index];

                            if (card.flow !== undefined) {
                                const newComboItem: ComboItem = formAnswerToComboItemFromFlow(card, card.flow);

                                newComboItemsFlow.push(newComboItem);
                            }
                        }
                    }

                    //Sort the items
                    newComboItemsFlow = newComboItemsFlow.sort(compareItems);

                    setItemsFlow(newComboItemsFlow);

                    if (apiRetRegister !== undefined && apiRetRegister.length > 0) {
                        for (let index = 0; index < apiRetRegister.length; index++) {
                            const fa = apiRetRegister[index];

                            const reg = apiRetRelationship.filter((f) => f.form_answer_id === fa.id_form_answer);

                            if (reg !== undefined && reg[0].form_answer?.register !== undefined) {

                                const newComboItem: ComboItem = formAnswerToComboItemFromRegister(fa, reg[0].form_answer.register);

                                newComboItemRegister.push(newComboItem);

                            }

                        }
                    }

                    setItemsRegister(newComboItemRegister);

                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });

        }

    }, [id_form_answer, register_id, withArchived]);

    const groupedCards: { [flowName: string]: ComboItem[] } = itemsFlow.reduce(
        (acc, item) => {
            if (item.originGroup !== undefined) {
                if (acc[item.originGroup]) {
                    acc[item.originGroup].push(item);
                } else {
                    acc[item.originGroup] = [item];
                }
            }
            return acc;
        },
        {} as { [flowName: string]: ComboItem[] }
    );

    const groupedRegisters: { [registerName: string]: ComboItem[] } = itemsRegister.reduce(
        (acc, item) => {
            if (item.originGroup !== undefined) {
                if (acc[item.originGroup]) {
                    acc[item.originGroup].push(item);
                } else {
                    acc[item.originGroup] = [item];
                }
            }
            return acc;
        },
        {} as { [registerName: string]: ComboItem[] }
    );

    useEffect(() => {

        getApiRelationship();

    }, [formAnswerFields, getApiRelationship]);

    return (
        <Container>

            <ContainerOptions>
                <ButtonGroupLeft active={withArchived === 0} onClick={() => setWithArchived(0)}>
                    Sem cartões arquivados
                </ButtonGroupLeft>
                <ButtonGroupRight active={withArchived === 1} onClick={() => setWithArchived(1)}>
                    Com cartões arquivados
                </ButtonGroupRight>
            </ContainerOptions>

            {loading ?
                <GroupLoading>

                    {withArchived === 1 && totConections !== undefined && totConections > 50 ?
                        <span style={{ marginTop: '15px', marginBottom: '30px', fontSize: '14px' }}>O carregamento pode ser um pouco mais demorado que o normal, aguarde...</span> :
                        <></>
                    }

                    <LoaderContainer />
                </GroupLoading> :
                <>
                    {Object.entries(groupedCards).map(([flowName, itemsFlow]) => {

                        const isExpanded = expandedGroups.includes(flowName);

                        return (
                            <ContainerGroup key={flowName}>
                                <GroupHeader onClick={() => toggleGroup(flowName)}>
                                    <GroupName>
                                        {isExpanded ?
                                            <BsToggleOn /> :
                                            <BsToggleOff />
                                        }
                                        {flowName}
                                        <TagOrigin>Fluxo</TagOrigin>
                                    </GroupName>
                                    <GroupItems>{itemsFlow.length}</GroupItems>
                                </GroupHeader>
                                {isExpanded && (
                                    <>
                                        <DividerGroup />
                                        <GroupBody>
                                            {itemsFlow.map((item) => (
                                                <ItemRegister key={item.id_register} type={'Flow'} item={item} />
                                            ))}
                                        </GroupBody>
                                    </>
                                )}
                            </ContainerGroup>
                        )
                    })}
                    {Object.entries(groupedRegisters).map(([registerName, itemsRegister]) => {

                        const isExpanded = expandedGroups.includes(registerName);

                        return (
                            <ContainerGroup key={registerName}>
                                <GroupHeader onClick={() => toggleGroup(registerName)}>
                                    <GroupName>
                                        {isExpanded ?
                                            <BsToggleOn /> :
                                            <BsToggleOff />
                                        }
                                        {registerName}
                                        <TagOrigin>Cadastro</TagOrigin>
                                    </GroupName>
                                    <GroupItems>{itemsRegister.length}</GroupItems>
                                </GroupHeader>
                                {isExpanded && (
                                    <>
                                        <DividerGroup />
                                        <GroupBody>
                                            {itemsRegister.map((item) => (
                                                <ItemRegister key={item.id_register} type={'Register'} item={item} />
                                            ))}
                                        </GroupBody>
                                    </>
                                )}
                            </ContainerGroup>
                        )
                    })}
                </>
            }

        </Container>
    );

}

export default RelationshipTab;