import styled from "styled-components";

export const Container = styled.div`

    padding: 24px;

`;

export const Title = styled.div`

    font-weight: 400;
    color: #515358;
    font-size: 30px;
    margin-bottom: 16px;
`;

export const Description = styled.div`

    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 0.05px;
    color: #515358;
    margin-top: 16px;
    margin-bottom: 16px;
    
`;

export const ButtonTourBack = styled.div`

    background-color: white;
    padding: 10px;
    border: 1px solid #515358;
    color: #515358;
    display: flex;
    border-radius: 20px;
    
`;

export const ButtonTour = styled.div`

    background-color: #f23b5c;
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 15px;
    border-radius: 20px;
    font-weight: 500;
    color: white;
    
`;

export const HighlightTip = styled.div`
    line-height: 1.75;
    margin-top: 16px;
    margin-bottom: 16px;
    color: #9e37ec;
    padding: 10px;
    font-size: 14px;
    letter-spacing: 0.02px;
    border-radius: 10px;
    background-color: #9e37ec1e;
    border-left: 10px solid;
`;

