import styled from "styled-components";

export const Container = styled.div`
background-color: transparent;
    border: 0px;
    padding: 16px;
    width: 100%;

    //border-bottom: 1px solid gray;
    color: black;

    display: flex;
    align-items: center;

    & + div {
        margin-top: 30px;
    }

    input {
        flex: 1;
        background: transparent;
        border: 0;
            
        ::placeholder {
            color: gray;
        }

        
    }

    svg {
        margin-right: 16px;
        color: gray;
    }

    title {
        color: red;
        text-size-adjust: 10px;
    }
`;
