import styled, { css } from "styled-components";

import { Box } from "@material-ui/core";
import Button from "../../../../Button";

interface ContainerProps {
    isErrored: boolean;
}

interface ItemRegisterIconProps {
    color?: string;
}

interface ItemRegisterProps {
    color?: string;
}

interface FormItemsCounterProps {
    color?: string;
}

interface FormItemsContainerProps {
    color?: string;
}

interface FormItemsHeaderProps {
    color?: string;
}

interface ItemRegisterHeadProps {
    color?: string;
    selected: boolean;
}

interface ProgressBarTaskLineInsideProps {
    width?: number;
}

interface ButtonNewProps {
    color?: string;
}

export const Container = styled.div<ContainerProps>`
  //padding: 16px 0px 0px 0px;
`;

export const ButtonAddRegister = styled.button`
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin-top: 15px;
`;

export const ButtonAddLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    margin-top: 1px;

    svg {
        height: 15px;
        width: 15px;
        color: #f23b5c;
    }
`;

export const ButtonAddRight = styled.div`
    color: #f23b5c;
    font-size: 15px;
    font-weight: 400;
    margin-left: 5px;
`;

export const BoxContainer = styled(Box)`
    position: relative;
    //position: absolute;
    z-index: 1;
    top: auto;
    //right: auto;
    left: auto;
    min-width: 250px;
    //max-width: 350px;
    width: auto;
    border-radius: 5px;
    padding: 5px;
    margin-top: 15px;
    padding-bottom: 10px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-right: 25px;

    @media(max-width: 800px){
        margin-right: 0px;
    }
`;

export const BoxItemsContainer = styled.div`
    max-height: 200px;
    overflow-y: auto;
`;

export const BoxSearchContainer = styled.div`
    display: flex;
    padding: 10px 10px 10px 10px;

    @media(max-width: 800px){
        display: block;

        div {
            width: 100%;
            margin: 0px;
            margin-top: 5px;
        }
    }
`;

export const SearchInput = styled.input`
    width: 100%;
    height: 35px;
    padding: 5px 15px 5px 15px;
    border-radius: 15px;
    border: 1px solid #f4f5f7;
    background-color: #f4f5f7;
    font-size: 14px;
`;

export const ItemRegisterContainer = styled.div<ItemRegisterProps>`
    margin: 15px;
    border: 2px solid #9e37ed1e;
    border-radius: 12px;

    ${(props) => props.color && css`
        border: 2px solid ${props.color + "1e"};
    `}

    :hover {
        box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    }
`;

export const ItemRegisterHead = styled.li<ItemRegisterHeadProps>`
    position: relative;
    width: 100%;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    min-height: 45px;
    
    ${(props) => props.color && css`
        color: ${props.color};
        font-weight: 500;
        background-color: ${props.color + "1e"};
        border-radius: 10px;
    `}
    
    ${(props) => props.selected && css`
            color: ${props.color};
            font-weight: 500;
            background-color: ${props.color + "1e"};
            border-radius: 10px;
    `}
`;


export const ItemRegisterIcon = styled.div<ItemRegisterIconProps>`
    border: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    border-radius: 10px;
    background-color: gray;
    margin-right: 10px;    
    min-height: 45px;
    min-width: 45px;

    ${(props) => props.color && css`
        border: 1px solid ${props.color};
        background-color: ${props.color};
    `}

    svg {
        color: white;
    }

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        font-size: 18px !important;
        
        svg {
            color: white;
        }
    }
`;

export const ItemRegisterDetailContainer = styled.div`
    margin-bottom: 5px;
`;

export const ItemRegisterDetail = styled.div`
    margin-left: 10px;
`;

export const ItemRegisterDetailHead = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;

    svg {
        min-width: 12px;
        font-size: 12px;
        font-weight: 600;
        color: #84939f;
    }

    h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 5px;
        font-size: 11px;
        color: #84939f;
        font-weight: 500;
        margin-top: 2px;
        text-transform: uppercase;
    }
`;

export const ItemRegisterDetailBody = styled.div`
  margin-left: 17px;
  font-weight: 500;
  font-size: 13px;
  color: #4d4d4d;
  overflow: hidden;
`;

export const RegisterRemoveContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 50px;
    padding: 5px;
    right: 0;
    position: absolute;
    margin-right: 5px;
`;

export const RegisterRemoveBox = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 50px;
    padding: 5px;

    svg {
        height: 15px;
        width: 15px;
        color: black;
    }

    :hover {
        background-color: white;
    }
`;

export const ItemRegisterTitle = styled.div`

    h1 {
        font-size: 14px;
        font-weight: 400;
    }

    span {
        font-size: 12px;
        color: gray;
    }

`;

export const FormItemsContainer = styled.div<FormItemsContainerProps>`
    border-radius: 10px;
    border: 1px solid gray;
    border-left: 5px solid gray;
    margin-top: 10px;
    background-color: white;

    ${(props) => props.color && css`
        border-color: ${props.color};
    `}
`;

export const FormItemsForm = styled.div`
    padding: 10px 10px 0px 10px !important;
    min-height: 200px;

    form {
        padding: 10px 10px 10px 10px !important;
    }
`;

export const ButtonNew = styled(Button)<ButtonNewProps>`
    width: auto;
    min-width: 135px;
    margin-top: 15px;
    float: right;
    background-color: #4680B8;
    height: 30px;
    font-size: 13px;
    font-weight: 500;

    ${(props) => props.color && css`
        background-color: ${props.color};
    `}

    :hover {
        background-color: #4680B8;
        opacity: 0.8;

        ${(props) => props.color && css`
            background-color: ${props.color};
        `}
    }
`;

export const ButtonBack = styled(Button)<ButtonNewProps>`

    width: auto;
    min-width: 90px;
    margin-top: 15px;
    float: right;
    background-color: #f0f1f3;
    margin-right: 10px;
    color: black;
    height: 30px;
    font-size: 13px;
    font-weight: 500;

    ${(props) => props.color && css`
        background-color: ${props.color};
        color: white;
    `}

    :hover {
        background-color: #f0f1f3;
        opacity: 0.8;

        ${(props) => props.color && css`
            background-color: ${props.color};
            color: white;
        `}
    }

`;

export const FormItemsHeader = styled.div<FormItemsHeaderProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid gray;
    padding: 10px 15px;
    margin-right: 10px;
    margin-left: 10px;

    ${(props) => props.color && css`
        border-bottom: 1px solid ${props.color + "1e"};
    `}
`;

export const FormItemsClose = styled.div`

`;

export const FormItemsCounter = styled.div<FormItemsCounterProps>`
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 2px;
    position: relative;
    float: right;
    display: flex;
    height: 25px;
    width: 100px;
    min-width: 80px;
    background-color: #f13c5d;
    padding: 5px 5px;
    color: white;
    border-radius: 15px;

    ${(props) => props.color && css`
        background-color: ${props.color};
    `}
`;

export const BoxAddNew = styled.div`
    font-size: 13px;
    white-space: nowrap;
    padding: 9px 15px;
    font-weight: 500;
    width: 55%;
    display: flex;
    color: #f23c5d;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-right: 5px;
    margin-left: 15px;

    :hover {
        background-color: #f23c5d;        
        color: white;
    }
`;

export const NoResults = styled.div`
    color: black;
    font-style: italic;
    font-size: 15px;
    margin-top: 5px;
    margin-left: 20px;
    margin-bottom: 5px;
`;

export const ProgressBarTask = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ProgressBarTaskLine = styled.div`
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ededed;
    margin-right: 10px;
    margin-left: 5px;
`;

export const ProgressBarTaskLineInside = styled.div<ProgressBarTaskLineInsideProps>`
    height: 10px;
    min-height: 10px;
    width: 100%;
    background: rgb(255,140,47);
    background: linear-gradient(90deg, rgba(255,140,47,1) 25%, rgba(251,50,92,1) 50%, rgba(147,55,237,1) 75%);

    width: 0%;
    border-radius: 10px;

    ${(props) => props.width && css`
        width: ${props.width + "%"};
    `}
`;

export const ProgressBarTaskPercent = styled.div`
    font-weight: 500;
    margin-right: 10px;
`;