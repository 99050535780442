import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";

import {
    ButtonLink,
    Container, FilterBarDaysButtonClick, FilterBarTasksLeft, FilterBarUsersButtonLink, SearchInput, Input, BoxContainer, BoxItemsContainer, BoxItem, BoxMultiple, BoxSpan, NoResults, BoxAvatar, FilterDaysLabel, FilterDaysInput, FilterDaysConfirm, BoxItemsContainerDays
} from "./style";
import { FiCalendar } from "react-icons/fi";
import { MdOutlineFilterList } from "react-icons/md";
import { AiFillThunderbolt, AiOutlineSearch } from "react-icons/ai";
import { IoIosCloseCircle } from "react-icons/io";
import { LogHistory } from "../../../../interfaces/LogHistory";
import { User } from "../../../../interfaces/User";
import { ClickAwayListener } from "@material-ui/core";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import AvatarCange from "../../../../components/AvatarCange";

interface ActivityFilterBarProps {
    logItems: LogHistory[];
    days: number;
    setLogItemsFiltered: React.Dispatch<React.SetStateAction<LogHistory[]>>;
    setAllExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    setDays: React.Dispatch<React.SetStateAction<number>>;
}

interface UserFilter extends User {
    selected?: boolean;
}

const ActivityFilterBar: React.FC<ActivityFilterBarProps> = ({ logItems, days, setLogItemsFiltered, setAllExpanded, setDays }) => {

    const [openFilterDays, setOpenFilterDays] = useState(false);
    const [tempDays, setTempDays] = useState<number>(days);

    const [users, setUsers] = useState<UserFilter[]>([]);
    const [openFilterUsers, setOpenFilterUsers] = useState(false);
    const [conditionsUsers, setConditionsUsers] = useState<string[]>([]);
    const [filterStateMessageUsers, setFilterStateMessageUsers] = useState<string>();

    const [searchField, setSearchField] = useState<boolean>();
    const [searchText, setSearchText] = useState<string>("");
    const searchInputRef = useRef<HTMLInputElement>(null);

    const onSearch = (text: React.FormEvent<HTMLInputElement>) => {

        setSearchText(text?.currentTarget.value);

    };

    const handleClickAway = () => {
        setOpenFilterUsers(false);
        setOpenFilterDays(false);

        setDays(tempDays);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value;

        // Remove caracteres não numéricos
        inputValue = inputValue.replace(/\D/g, '');

        // Garante que o valor esteja no intervalo de 1 a 99
        if (inputValue !== '' && (parseInt(inputValue) < 1 || parseInt(inputValue) > 99)) {
            return;
        }

        if (!isNaN(Number(inputValue))) {
            const newDays: number = Number(inputValue);

            setTempDays(newDays);
        }
    };

    const handleSelect = useCallback((user: UserFilter) => {

        const newCondition: string[] = [];

        //Update the Options, set selected or no
        setUsers(users?.map((opt) => {
            if (opt.id_user === user.id_user) {
                if (opt.selected === undefined) {
                    opt.selected = true;
                    newCondition.push(String(opt.id_user));
                } else if (opt.selected === false) {
                    opt.selected = true;
                    newCondition.push(String(opt.id_user));
                } else {
                    opt.selected = false;
                }
            } else if (opt.selected) {
                newCondition.push(String(opt.id_user));
            }
            return opt;
        }));

        setConditionsUsers(newCondition);

    }, [users]);

    useEffect(() => {

        let newUsers: UserFilter[] = []

        logItems.map((log) => {

            //Build the users array
            const validFlow = newUsers.filter((u) => u.id_user === log.user_id);
            if (validFlow !== undefined && validFlow.length === 0) { //There isn't flow 
                if (log.user !== undefined) {

                    let newUserFilter: UserFilter = log.user;

                    if (conditionsUsers !== undefined && conditionsUsers.length > 0) {
                        const isSelected = conditionsUsers.filter((co) => co === String(log.user_id));
                        if (isSelected.length > 0) {
                            newUserFilter.selected = true;
                        }
                    }

                    newUsers.push(newUserFilter);
                }
            }

            //Build the users array from the children
            if (log.log_children !== undefined) {
                log.log_children.map((log_child) => {

                    const validFlow = newUsers.filter((u) => u.id_user === log_child.user_id);
                    if (validFlow !== undefined && validFlow.length === 0) { //There isn't flow 
                        if (log_child.user !== undefined) {

                            let newUserFilter: UserFilter = log_child.user;

                            if (conditionsUsers !== undefined && conditionsUsers.length > 0) {
                                const isSelected = conditionsUsers.filter((co) => co === String(log_child.user_id));
                                if (isSelected.length > 0) {
                                    newUserFilter.selected = true;
                                }
                            }

                            newUsers.push(newUserFilter);
                        }
                    }

                    return log_child;
                })
            }

            return log;
        });

        setUsers(newUsers);

    }, [conditionsUsers, logItems]);

    useEffect(() => {

        let newLogItems = logItems;
        let messageUsers: string = "";
        let isFilteredUsers: boolean = false;

        //Search Bar
        if (searchText !== undefined && searchText !== "") {

            isFilteredUsers = true;

            newLogItems = newLogItems.filter((log: LogHistory) => {

                let ret = false;

                //Search in the log
                if (log.description !== undefined && log.description !== null && log.description.includes(searchText.toLocaleLowerCase())) {
                    ret = true;
                } else if (log.date_group !== undefined && log.date_group !== null && log.date_group.includes(searchText.toLocaleLowerCase())) {
                    ret = true;
                } else if (log.user !== undefined && log.user !== null && log.user.name.includes(searchText.toLocaleLowerCase())) {
                    ret = true;
                }

                //Search in the log children
                if (log.log_children !== undefined) {
                    log.log_children.map((log_child) => {

                        //Search in the log
                        if (log_child.description !== undefined && log_child.description !== null && log_child.description.includes(searchText.toLocaleLowerCase())) {
                            ret = true;
                        } else if (log_child.date_group !== undefined && log_child.date_group !== null && log_child.date_group.includes(searchText.toLocaleLowerCase())) {
                            ret = true;
                        } else if (log_child.user !== undefined && log_child.user !== null && log_child.user.name.includes(searchText.toLocaleLowerCase())) {
                            ret = true;
                        }

                        return log_child;
                    })
                }

                //Search in the card
                if (log.card !== undefined) {
                    log.card.form_answers?.map((answer) => {

                        answer.form_answer_fields.map((answer_field) => {

                            const answerFieldValue = answer_field.valueString !== undefined && answer_field.valueString !== null ? answer_field.valueString.toLocaleLowerCase() : "";

                            if (answerFieldValue.includes(searchText.toLocaleLowerCase())) {
                                ret = true;
                            }

                            return answer_field;
                        })

                        return answer;
                    })
                }

                return ret
            })

        }

        if (conditionsUsers !== undefined && conditionsUsers.length > 0) {

            isFilteredUsers = true;

            newLogItems = newLogItems.filter((c) => {

                let ret = false;

                //Search in the log children
                if (c.log_children !== undefined) {
                    c.log_children.map((log_child) => {
                        const isFilteredChild = conditionsUsers.filter((co) => String(log_child.user_id) === co);
                        if (isFilteredChild.length > 0) {
                            ret = true;
                        }
                        return log_child;
                    })
                }

                //Search in the log
                const isFiltered = conditionsUsers.filter((co) => String(c.user_id) === co);
                if (isFiltered.length > 0) {
                    ret = true;
                }

                return ret;
            })

            if (conditionsUsers.length > 0) {
                messageUsers += "Atividades do "

                const nameFlowOne = users.filter((us) => String(us.id_user) === conditionsUsers[0]);
                if (nameFlowOne.length > 0) {
                    messageUsers += nameFlowOne[0].name;

                    if (conditionsUsers.length === 2) {
                        messageUsers += " e mais " + (conditionsUsers.length - 1) + " usuário";
                    } else if (conditionsUsers.length > 2) {
                        messageUsers += " e mais " + (conditionsUsers.length - 1) + " usuários";
                    }
                }

            }

            setFilterStateMessageUsers(messageUsers);
        }

        if (!isFilteredUsers) {
            setLogItemsFiltered(logItems);
            setAllExpanded(false);
        } else {
            setAllExpanded(true);
            setLogItemsFiltered(newLogItems);
        }

    }, [conditionsUsers, logItems, users, searchText, setLogItemsFiltered, setAllExpanded]);

    useEffect(() => {

        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }

    }, [searchField]);

    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setSearchText('');
                setSearchField(false);
            }
        };

        window.addEventListener('keydown', handleEscape);

        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, []);

    return (
        <Container>
            <FilterBarTasksLeft>
                <FilterBarDaysButtonClick type="button" onClick={() => {
                    setOpenFilterDays(!openFilterDays);
                    setOpenFilterUsers(false);
                    if (searchText === undefined || searchText === "") {
                        setSearchField(false);
                    }
                }}>
                    <FiCalendar size={15} />
                    Últimos {days} Dias
                </FilterBarDaysButtonClick>
                {openFilterDays ? (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <BoxContainer style={{ left: '110px' }}>
                            <BoxItemsContainerDays>

                                <FilterDaysLabel>Últimos</FilterDaysLabel>
                                <FilterDaysInput type="text" value={tempDays} onChange={handleChange} />
                                <FilterDaysLabel>dias</FilterDaysLabel>
                                <FilterDaysConfirm onClick={handleClickAway}><AiFillThunderbolt/> Salvar</FilterDaysConfirm>


                            </BoxItemsContainerDays>
                        </BoxContainer>
                    </ClickAwayListener>
                ) : <></>}


                <FilterBarUsersButtonLink type="button"
                    onClick={() => {
                        setOpenFilterUsers(!openFilterUsers)
                        setOpenFilterDays(false);
                        if (searchText === undefined || searchText === "") {
                            setSearchField(false);
                        }
                    }}
                    active={conditionsUsers.length >= 1}
                >
                    <MdOutlineFilterList />
                    {conditionsUsers.length >= 1 ? filterStateMessageUsers : "Todos os Usuários"}
                </FilterBarUsersButtonLink>
                {openFilterUsers ? (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <BoxContainer>
                            <BoxItemsContainer>
                                {users !== undefined && users?.length > 0 ?
                                    users?.map((users) => {

                                        const condFiltered = conditionsUsers.filter((co) => co === String(users.id_user));
                                        let isSelected = false;
                                        if (condFiltered !== undefined && condFiltered.length > 0) {
                                            isSelected = true;
                                        }

                                        return (
                                            <BoxItem key={users.id_user} onClick={() => handleSelect(users)}>
                                                <BoxMultiple>
                                                    {(users.selected !== undefined && users.selected === true) || isSelected ?
                                                        <BsToggleOn color="green" /> :
                                                        <BsToggleOff />
                                                    }
                                                </BoxMultiple>
                                                <BoxAvatar><AvatarCange user={users} size="20" /></BoxAvatar>
                                                <BoxSpan>{users.name}</BoxSpan>
                                            </BoxItem>
                                        )
                                    }) :
                                    <NoResults>Sem registro</NoResults>
                                }
                            </BoxItemsContainer>
                        </BoxContainer>
                    </ClickAwayListener>
                ) : <></>}

                {!searchField ?
                    <ButtonLink type="button"
                        onClick={() => {
                            setSearchField(true);
                            setOpenFilterUsers(false);
                        }}>
                        <AiOutlineSearch />
                        <div>Pesquisar</div>
                    </ButtonLink> :
                    <SearchInput>
                        <AiOutlineSearch />
                        <Input
                            ref={searchInputRef}
                            onChange={(e) => onSearch(e)}
                            placeholder="Busque aqui..."
                            value={searchText}
                        />
                        {searchText !== undefined && searchText !== "" ?
                            <IoIosCloseCircle
                                style={{ marginRight: "9px" }}
                                onClick={() => {
                                    setSearchText("");
                                    setSearchField(false);
                                }}
                            /> :
                            <></>
                        }
                    </SearchInput>
                }
            </FilterBarTasksLeft>
        </Container>
    );

}

export default ActivityFilterBar;