import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";

import {
    Content,
    ContentBody,
    DialogTop,
    DialogTopLeft,
    DialogTopCenter,
    DialogTopRight,
    IconHeader,
    DialogFooter,
    DialogFooterCenter,
    BtnInsertNewAnswer,
    ContainerFields,
    AvatarEditorContainer,
    InputFileAvatar,
    ContainerInputFile
} from "./style";

import { Dialog } from '@material-ui/core';
import { FaRegUserCircle } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import api from "../../services/api";
import { Attachment } from "../../interfaces/Attachment";
import AvatarEditor from 'react-avatar-editor'
import { MdAttachFile } from "react-icons/md";
import { uniqueId } from "lodash";
import filesize from "filesize";
import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";

interface UploadImageProps {
    open: boolean;
    onClose: () => void;
}

const UploadImage: React.FC<UploadImageProps> = ({ onClose, open }) => {

    const { user, renewUser } = useAuth();
    const { addToast } = useToast();

    const [step, setStep] = useState(0); //0 - Uploading / 1 - Croping
    const [scale, setScale] = useState<number>(1);
    const [src, setSrc] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const cropRef = useRef<AvatarEditor>(null);

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    const handleSave = useCallback(async () => {
        if (cropRef.current) {
            const dataUrl = cropRef.current.getImage().toDataURL();
            const result = await fetch(dataUrl);
            const blob = await result.blob();
            const file = new File([blob], "avatar.png", { type: "image/png" });

            const uploadedFilsNew: Attachment = {
                file,
                id_attachment: uniqueId(),
                original_name: file.name,
                readableSize: filesize(file.size * 1024),
                progress: 0,
                uploaded: false,
                error: false,
                url: undefined
            };

            const data = new FormData();

            if (uploadedFilsNew.file !== undefined) {

                setLoading(true);

                data.append('file', uploadedFilsNew.file, uploadedFilsNew.original_name);

                api
                    .post('/attachment', data)
                    .then(response => {

                        const retApi: Attachment = response.data

                        if (retApi !== undefined) {

                            api.get(`/attachment/url-download`, {
                                params: {
                                    id_attachment: retApi.id_attachment,
                                    isNeverExpires: 'true'
                                }
                            }).then(response => {

                                const urlFile: { url: string, base64?: string } = response.data;

                                api
                                    .post('/user', {
                                        id_user: user.id_user,
                                        avatar_id: retApi.id_attachment,
                                        avatar_url: urlFile.url
                                    })
                                    .then(response => {

                                        renewUser();

                                    }).catch(error => {
                                        addToast({
                                            type: 'error',
                                            title: 'Erro ao salvar o perfil do usuário',
                                            description: 'Ocorreu um erro ao salvar o perfil do usuário!',
                                        });
                                    });

                            }).catch(error => {
                                addToast({
                                    type: 'error',
                                    title: 'Erro ao fazer o download da imagem',
                                    description: 'Ocorreu um erro ao fazer o download da imagem!',
                                });
                            });

                        }
                        setLoading(false);
                        handleClose();
                    }).catch(error => {
                        setLoading(false);
                        console.log(error);

                    });

            }
        }

    }, [handleClose, addToast, renewUser, user]);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSrc(URL.createObjectURL(e.target.files[0]));
            setStep(1);
        }
    };

    const handleScale = (e: ChangeEvent<HTMLInputElement>) => {
        const scale = parseFloat(e.target.value)
        setScale(scale);
    }

    useEffect(() => {

        setStep(0);

    }, [open]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={"gray"}>
                        <div>
                            <FaRegUserCircle />
                        </div>
                    </IconHeader>
                    <h1>
                        {"Adicionar uma imagem"}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>

            <Content>
                <ContentBody container>

                    <ContainerFields item xs={12} md={12}>

                        {step === 0 ?
                            <>
                                <ContainerInputFile onClick={handleButtonClick}>
                                    <MdAttachFile />
                                    <p>Clique aqui ou arraste arquivos para adicionar uma imagem</p>
                                </ContainerInputFile>
                                <InputFileAvatar
                                    type="file"
                                    accept="image/*"
                                    ref={fileInputRef}
                                    onChange={handleImgChange}
                                />
                            </> : step === 1 ?
                                <AvatarEditorContainer>
                                    <AvatarEditor
                                        ref={cropRef}
                                        image={src || ""}
                                        width={200}
                                        height={200}
                                        border={50}
                                        borderRadius={100}
                                        color={[255, 255, 255, 0.6]} // RGBA
                                        scale={scale}
                                        rotate={0}
                                    />

                                    <input
                                        name="scale"
                                        type="range"
                                        onChange={handleScale}
                                        min={'1'}
                                        max="2"
                                        step="0.01"
                                        defaultValue="1"
                                        style={{ marginTop: '25px' }}
                                    />
                                </AvatarEditorContainer>
                                : <></>}
                    </ContainerFields>

                </ContentBody>
                <DialogFooter>
                    <DialogFooterCenter>
                        {step === 0 ?
                            <></> :
                            <BtnInsertNewAnswer type="button" color={"#f23b5c"} icon={BiSave} onClick={() => handleSave()} isLoading={loading}>
                                Salvar
                            </BtnInsertNewAnswer>
                        }
                    </DialogFooterCenter>
                </DialogFooter>

            </Content>
        </Dialog >
    );

}

export default UploadImage;