import styled, { css } from "styled-components";
import Button from "../../components/Button";

interface ContainerConfigProps {
    isSplited?: boolean;
}

export const AppBarHeaderContainer = styled.div`

    .app-bar-header-left-nevigation-title {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

`;

export const ButtonClose = styled.button`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    width: 45px !important;
    height: 35px !important;
    margin-right: 15px !important;

    svg {
        font-size: 16px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
    
`;

export const ContainerConfig = styled.div`
    display: flex;
`;

export const ContainerConfigLeft = styled.div<ContainerConfigProps>`
    width: 100%;
    height: calc(100vh - 100px);

    ${props => props.isSplited && css`
        width: 70%;
    `}
`;

export const ContainerConfigRight = styled.div<ContainerConfigProps>`
    width: 0%;
    display: none;

    ${props => props.isSplited && css`
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-left: 1px solid #eef2f8;
        overflow: auto;
        height: calc(100vh - 100px);
    `}
`;

export const ContainerConfigForm = styled.div`
    width: 100%;
`;

export const ContainerConfigRightHeader = styled.div`
    padding: 15px;
    width: 100%;
    border-bottom: 1px solid #f4f4f4;    
    position: relative;
`;

export const ContainerConfigRightHeaderTitle = styled.div`

    span {
        color: gray;
        font-size: 12px;
    }
`;

export const ContainerConfigRightHeaderSearch = styled.div`
`;

export const ContainerCloseButton = styled.div`
    position: absolute;
    right: 10px;
    top: 20px;
`;

export const DividerMenu = styled.div`
    width: 100%;
    border-bottom: 1px solid #f4f4f4;
    margin: 20px 0;
`;

export const ContainerForm = styled.div`
    width: 100%;
    padding: 15px;
    padding-top: 0px;

    form {
        padding-top: 0px;
        padding-left: 5px;
        padding-right: 5px;

        .cange-form-title {
            font-size: 14px;
        }

        .cange-form-title-required {
            font-size: 14px;
        }

        .cange-form-description {
            font-size: 12px;
            color: gray;
            font-weight: 400;
        }

        .cange-form-error {
            font-size: 12px;
        }

        .cange-form-error-icon {
            font-size: 12px;
            height: 12px;
            width: 12px;
        }

        .cange-form-help-text {
            margin-top: 0px;
            font-size: 12px;
        }
    }
`;

export const LogoField = styled.div`
    padding: 15px 25px;
    padding-bottom: 0px;
`;

export const LogoFieldTitle = styled.div`

    margin-bottom: 5px;
    
    h3 {
        font-size: 14px;
        margin-bottom: 5px;
        font-weight: 500;
    }

    span {
        font-size: 12px;
        color: gray;
        font-weight: 400;
    }
`;

export const LogoFieldContent = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const LogoFieldImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;

    img {
        width: 100%;
        border-radius: 5px;
    }
`;

export const LogoFieldButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
`;

export const LogoFieldBtnUpload = styled.button`
    border: none;
    background-color: #4681b8;
    color: white;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 5px;        
`;

export const LogoFieldBtnRemove = styled.button`
    border: none;
    background-color: #f23b5c;
    color: white;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 10px;
`;

export const ButtonSave = styled(Button)`
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    max-width: 185px !important;
    width: auto !important;

    font-size: 15px;
    height: 35px !important;

    div {
        margin-top: 3px !important;

        svg {
            width: 18px;
            height: 18px;
        }
    }

    :disabled {
        background-color: #f4f4f4;
        color: #b3b3b3;
    }
`;
