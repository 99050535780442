import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    Content,
    ContentBody,
    DialogTop,
    DialogTopLeft,
    DialogTopCenter,
    DialogTopRight,
    IconHeader,
    DialogFooter,
    DialogFooterCenter,
    BtnInsertNewAnswer
} from "./style";

import { Dialog } from '@material-ui/core';
import { AiOutlineClose } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { FieldProps, Fields } from "../../../components/Forms/Fields/FieldBuilder";
import { FormHandles } from "@unform/core";
import FormBuilder from "../../../components/Forms/FormBuilder";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import { Workspace } from "../../../interfaces/Workspace";
import { BsBoxes } from "react-icons/bs";

const fieldsForm: FieldProps[] = [
    {
        name: "description_ws",
        type: Fields.INPUT_RICH_TEXT_FIELD,
        index: 0,
        title: "Descrição do workspace",
        description: "Digite uma descrição sobre o seu workspace",
        placeholder: "Digite aqui...",
        required: false
    }
]

interface SaveWorkspaceFormProps {
    description_ws: string;
}

interface AddDescriptionProps {
    open: boolean;
    selectedWorkspace?: Workspace;
    onClose: (newWorkspace?: Workspace) => void;
}

const defaultValue: SaveWorkspaceFormProps = {
    description_ws: ""
}

const AddDescription: React.FC<AddDescriptionProps> = ({ onClose, open, selectedWorkspace }) => {

    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();

    const [initValue] = useState<SaveWorkspaceFormProps>(defaultValue);

    const handleClose = useCallback(async (newWorkspace?: Workspace) => {
        onClose(newWorkspace);
    }, [onClose]);

    const handleSavePage = useCallback(async () => {

        if (formRef.current !== null && selectedWorkspace !== undefined) {

            //Normaliza data and call the api
            const dataNormalized = formRef.current.getData() as unknown as SaveWorkspaceFormProps;

            await api
                .post('/workspace', {
                    id_workspace: selectedWorkspace.id_workspace,
                    description: dataNormalized.description_ws
                })
                .then(response => {
                    const newView = response.data as Workspace;

                    onClose(newView);

                    addToast({
                        type: 'success',
                        title: 'Descrição salva com sucesso!',
                        description: 'Wow! O sua descrição foi salva com sucesso! :)',
                    });
                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar o workspace!',
                        description: 'Ocorreu um erro ao salvar o workspace!',
                    });
                });

        }

    }, [selectedWorkspace, addToast, onClose]);

    const handleSubmitForm = useCallback(async (data: object[]) => {

        await handleSavePage();

    }, [handleSavePage]);

    useEffect(() => {

        if (open && selectedWorkspace !== undefined) {

            //Get the all flow views
            api.get(`/workspace/`, {
                params: {
                    id_workspace: selectedWorkspace.id_workspace
                }
            }).then(response => {
                if (response.data !== null) {

                    const respWorkspace: Workspace = response.data;

                    const newDataForm: SaveWorkspaceFormProps = {
                        "description_ws": respWorkspace.description
                    }

                    if (formRef.current !== null) {
                        formRef.current.setData(newDataForm);
                    }

                }
            });

        }

    }, [open, selectedWorkspace]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={() => handleClose()}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={"gray"}>
                        <div>
                            <BsBoxes />
                        </div>
                    </IconHeader>
                    <h1>
                        {selectedWorkspace !== undefined && selectedWorkspace.description !== null && selectedWorkspace.description !== "" ?
                            "Editar a descrição" :
                            "Criar uma descrição"
                        }
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={() => handleClose()}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>

            <Content>
                <ContentBody container>

                    {initValue !== undefined ?
                        <FormBuilder
                            id="form"
                            formRef={formRef}
                            fields={fieldsForm}
                            initialValue={initValue}
                            focusOnForm={true}
                            handleSubmit={handleSubmitForm}
                        /> :
                        <></>
                    }

                </ContentBody>
                <DialogFooter>
                    <DialogFooterCenter>
                        <BtnInsertNewAnswer color={"#23cd78"} icon={BiSave} onClick={() => handleSavePage()}>
                            Salvar
                        </BtnInsertNewAnswer>
                    </DialogFooterCenter>
                </DialogFooter>

            </Content>
        </Dialog >
    );

}

export default AddDescription;