import {
    Container,
    ContainerEmpty,
    StepContainerEmpty,
    StepDataEmpty,
    StepIconContainerEmpty
} from "./styles";
import React, { useCallback, useEffect, useState } from "react";

import { Card } from "../../interfaces/Card";
import Column from "./Column";
import EmptyStep from "../EmptyState/EmptyStep";
import { FaPlus } from "react-icons/fa";
import { FieldProps } from "../Forms/Fields/FieldBuilder";
import { FilterFlowProps } from "../FilterBar/FilterDialog";
import { Flow } from "../../interfaces/Flow";
import { Step } from "../../interfaces/Step";
import getAccessControl from "../../middlewares/AccessControl";
import getKanbanConfig from "../../utils/KanbanConfig/getKanbanConfig";
import { useHistory } from 'react-router-dom';
import { UpdateControlProps } from "../../pages/Flow";
import api from "../../services/api";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { useToast } from "../../hooks/toast";
import getIsAllowedMove from "../../utils/getIsAllowedMove";

interface KanbanProps {
    flow: Flow;
    typeUser: string;
    loaderCard?: boolean;
    updateCard?: Card;
    testModel?: boolean;
    filterFlow?: FilterFlowProps;
    isArchived?: boolean;
    openDetail: (card: Card, kanban_config?: KanbanConfigProps) => void;
    refreshPage?: () => Promise<void>;
    configCard?: () => Promise<void>;
    setUpdateControl: React.Dispatch<React.SetStateAction<UpdateControlProps>>
    updateControl?: UpdateControlProps;
    printComponentRef?: React.MutableRefObject<null>;
}

export interface KanbanConfigProps {
    fieldTitle: FieldProps[];
    fieldHighlight: FieldProps[];
    fieldFormInit: FieldProps[];
    fieldFormAll: FieldProps[];
}

const getListStyle = (isDraggingOver: boolean) => ({
    opacity: isDraggingOver ? 0.8 : 1,
});

const Kanban: React.FC<KanbanProps> = ({ flow, loaderCard, openDetail, refreshPage, configCard, setUpdateControl, updateCard, testModel, filterFlow, typeUser, updateControl, printComponentRef, isArchived }) => {

    const history = useHistory();
    const { addToast } = useToast();

    const [loading, setLoading] = React.useState<boolean>(true);
    const [loadingTop, setLoadingTop] = useState<boolean>(true);
    const [cardsBase, setCardsBase] = React.useState<Card[]>([]);
    const [kabanConfig, setKanbanConfig] = useState<KanbanConfigProps>();

    const [cardReorder, setCardReorder] = useState<{ id_step: number, startIndex: number, endIndex: number } | undefined>(undefined);
    const [cardDragSelect, setCardDragSelect] = useState<Card | undefined>(undefined);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [steps, setSteps] = useState<Step[]>([]);

    const goToEditFlow = useCallback(async (id_step?: number) => {

        if (flow.hash !== undefined && id_step === undefined) {
            history.push('/flow/3/' + flow.hash + '/edit');
        } else if (flow.hash !== undefined && id_step !== undefined) {
            history.push('/flow/3/' + flow.hash + '/edit/' + id_step);
        }

    }, [flow.hash, history]);

    const getApiStepRelationship = useCallback(async () => {

        if (flow !== undefined && flow !== null && flow.id_flow !== undefined) {

            await api.get(`/flow-step/relationship/by-flow`, {
                params: {
                    flow_id: flow.id_flow,
                }
            }).then(response => {
                if (response.data !== null) {

                    let retSteps: Step[] = response.data;

                    let newSteps: Step[] = flow.flow_steps;

                    newSteps = newSteps.map(step => {

                        const stepRelationship = retSteps.find(s => s.id_step === step.id_step);

                        if (stepRelationship !== undefined) {
                            step.relationship_steps = stepRelationship.relationship_steps;
                            step.conditionals = stepRelationship.conditionals;
                        }

                        return step;
                    });

                    setSteps(newSteps);

                }

            });

        }

    }, [flow]);

    const getApiStepCards = useCallback(async () => {

        if (loaderCard) {
            setLoading(true);
        }

        setLoadingTop(true);

        await api.get(`/card/by-flow`, {
            params: {
                flow_id: flow.id_flow,
                isTestModel: testModel !== undefined ? testModel : undefined,
                isArchived: isArchived !== undefined ? isArchived : undefined,
                isWithPreAnswer: true
            }
        }).then(response => {
            if (response.data !== null) {

                let cardsApi: Card[] = response.data;

                setCardsBase(cardsApi);

                setLoading(false);
                setLoadingTop(false);
            }

        });

    }, [flow.id_flow, loaderCard, testModel, isArchived]);

    const getApiCard = useCallback(async (id_card: number) => {

        if (id_card !== undefined && flow?.id_flow !== undefined) {

            await api.get(`/card/v2`, {
                params: {
                    id_card: id_card,
                    flow_id: flow?.id_flow,
                    isTestModel: testModel !== undefined ? testModel : undefined,
                    isIgnoringDelArchStatus: true,
                    isWithPreAnswer: true
                }
            }).then(response => {
                if (response.data !== null) {

                    const newCard: Card = response.data;

                    setUpdateControl({
                        random: (Math.random() * 100) * -1,
                        card: newCard
                    });

                }
            })

        }

    }, [flow?.id_flow, testModel, setUpdateControl]);

    const handleMoveCard = useCallback(async (newStep: Step, currStep: Step, card_id: number) => {

        //Find the card on base 
        const card = cardsBase.find(c => c.id_card === card_id);

        if (card !== undefined) {
            card.flow_step_id = newStep.id_step;
            card.flow_step = newStep;

            setUpdateControl({
                random: (Math.random() * 100) * -1,
                card: card
            });
        }

        await api
            .post('/card/move-step', {
                flow_id: flow.id_flow,
                id_card: card_id,
                to_step_id: newStep.id_step,
                from_step_id: currStep.id_step,
                complete: newStep.isEndStep === "1" ? "S" : "N",
                isActiveAssignments: true
            })
            .then(response => {

                getApiCard(card_id);

                addToast({
                    type: 'success',
                    title: 'Cartão movido com sucesso!',
                    description: 'Congrats! Você moveu o cartão para a etapa ' + newStep.name,
                });


            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao mover o cartão',
                    description: error.response.data.message
                });

                getApiCard(card_id);
            });

    }, [addToast, flow.id_flow, getApiCard, setUpdateControl, cardsBase]);

    const onDragStart = (result: DropResult) => {

        setIsDragging(true);

        const card = cardsBase.find(card => card.id_card === Number(result.draggableId));

        if (card !== undefined) {
            setCardDragSelect(card);
        }

    }

    const onDragEnd = (result: DropResult) => {

        setIsDragging(false);
        setCardDragSelect(undefined);

        if (!result.destination) {
            return;
        }

        //Valid if the card was moved to another column or if it was moved to the same column and changed the order
        if (result.destination.droppableId === result.source.droppableId && result.destination.index === result.source.index) {
            return;
        }

        const { source, destination } = result;

        const sourceStep = steps.find(step => String(step.id_step) === source.droppableId.replace("nested-", ""));

        const destinationStep = steps.find(step => String(step.id_step) === destination.droppableId.replace("nested-", ""));

        if (sourceStep !== undefined && destinationStep !== undefined) {

            if (sourceStep.id_step !== destinationStep.id_step) {

                if (sourceStep.form !== undefined && sourceStep.form.fields !== undefined && sourceStep.form.fields.length > 0) {

                    const card = cardsBase.find(card => card.id_card === Number(result.draggableId));

                    //Open the card
                    if (card !== undefined) {
                        openDetail(card, kabanConfig);
                    }

                } else if (sourceStep.form === undefined || sourceStep.form.fields === undefined || sourceStep.form.fields.length === 0) {

                    //Move directly
                    handleMoveCard(destinationStep, sourceStep, Number(result.draggableId));

                }

            } else if (sourceStep.id_step !== undefined && sourceStep.id_step === destinationStep.id_step) {

                setCardReorder({
                    startIndex: source.index,
                    endIndex: destination.index,
                    id_step: sourceStep.id_step
                });

            }

        }

        return;

    };

    useEffect(() => {

        const requireUpdate = (updateControl?.card !== undefined && updateControl.card.id_card > 0) || (updateControl?.cards !== undefined && updateControl.cards.length > 0);

        if (requireUpdate) {

            let cardsToUpdate: Card[] = [];

            if (updateControl !== undefined && updateControl.cards !== undefined) {
                cardsToUpdate = updateControl.cards;
            }

            if (updateControl !== undefined && updateControl.card !== undefined) {
                cardsToUpdate.push(updateControl.card);
            }

            for (let index = 0; index < cardsToUpdate.length; index++) {

                const newCard = cardsToUpdate[index];

                setCardsBase((prevCards: Card[]) => {

                    const updatedCards = [...prevCards];

                    const index = updatedCards.findIndex((card) => card.id_card === newCard.id_card);

                    let action: 'remove' | 'update' | 'add' = 'update';

                    if (!isArchived) { //Regular Flow
                        if (index !== -1) {
                            if (newCard.archived === "S") {
                                action = 'remove';
                            } else if (newCard.deleted === "S") {
                                action = 'remove';
                            } else {
                                action = 'update';
                            }
                        } else {
                            if (newCard.archived !== "S" && newCard.deleted !== "S") {
                                action = 'add';
                            }
                        }
                    } else {
                        if (index !== -1) {
                            if (newCard.archived === "S" && newCard.deleted !== "S") {
                                action = 'update';
                            } else {
                                action = 'remove';
                            }
                        } else {
                            if (newCard.archived === "S" && newCard.deleted !== "S") {
                                action = 'add';
                            }
                        }
                    }

                    if (action === 'update') {
                        updatedCards[index] = newCard;
                    } else if (action === 'add') {
                        updatedCards.push(newCard);
                    } else if (action === 'remove') {
                        updatedCards.splice(index, 1);
                    }

                    return updatedCards;
                });

            }

        } else {
            getApiStepCards();
        }

    }, [testModel, getApiStepCards, updateControl, isArchived]);

    useEffect(() => {

        const kanbanConfig: KanbanConfigProps = getKanbanConfig(flow);

        setKanbanConfig({
            fieldTitle: kanbanConfig.fieldTitle,
            fieldHighlight: kanbanConfig.fieldHighlight,
            fieldFormInit: kanbanConfig.fieldFormInit,
            fieldFormAll: kanbanConfig.fieldFormAll
        })

        getApiStepRelationship();

    }, [flow, getApiStepRelationship]);

    return (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Container className="kanban-container" ref={printComponentRef}>
                {steps.length > 0 ?
                    <>
                        {steps.length > 0 && kabanConfig !== undefined ?
                            steps.map((step: Step) => {

                                const isAllowedMove = getIsAllowedMove(step, steps, cardDragSelect);

                                const isTheSameStep = cardDragSelect !== undefined ? cardDragSelect?.flow_step_id === step.id_step : true;

                                const isDropDisabled = !isAllowedMove || isTheSameStep ? true : false;

                                return (

                                    <Droppable key={step.id_step} droppableId={String(step.id_step)} isDropDisabled={isDropDisabled}>
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={(el) => {
                                                    provided.innerRef(el)
                                                }}
                                                style={getListStyle(snapshot.isDraggingOver)}
                                            >
                                                <Column
                                                    step={step}
                                                    cardsBase={cardsBase.filter(card => card.flow_step_id === step.id_step)}
                                                    loading={loading}
                                                    loadingTop={loadingTop}
                                                    kanban_config={kabanConfig}
                                                    fieldsAggregate={flow.fields_aggregate}
                                                    key={step.id_step}
                                                    withLoader={loaderCard}
                                                    openDetail={openDetail}
                                                    refreshPage={refreshPage}
                                                    goToEditFlow={goToEditFlow}
                                                    configCard={configCard}
                                                    updateCard={updateCard}
                                                    testModel={testModel}
                                                    filterFlow={filterFlow}
                                                    typeUser={typeUser}
                                                    updateControl={updateControl}
                                                    isArchived={isArchived}
                                                    isDraggingOver={snapshot.isDraggingOver}
                                                    isDropDisabled={isDropDisabled}
                                                    isTheSameStep={isTheSameStep}
                                                    isDragging={isDragging}
                                                    cardReorder={cardReorder}
                                                    setCardReorder={setCardReorder}
                                                />
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                )
                            }) : <></>
                        }
                        {getAccessControl(17, typeUser) ?
                            <StepContainerEmpty onClick={() => goToEditFlow()}>
                                <StepIconContainerEmpty>
                                    <FaPlus />
                                </StepIconContainerEmpty>
                                <StepDataEmpty>
                                    <h2>Criar uma nova etapa</h2>
                                </StepDataEmpty>
                            </StepContainerEmpty> : <></>
                        }
                    </> :
                    <>
                        {getAccessControl(17, typeUser) ?
                            <ContainerEmpty onClick={() => goToEditFlow()}>
                                <EmptyStep />
                            </ContainerEmpty> :
                            <ContainerEmpty>
                                <EmptyStep />
                            </ContainerEmpty>
                        }
                    </>
                }
            </Container>
        </DragDropContext>
    );

}

export default Kanban;