import styled, { css } from "styled-components";

interface WorkspaceItemProps {
    color: string;
    selectedStyle: boolean;
}

interface WorkspaceItemIconProps {
    color: string;
}

interface WorkspaceItemRightProps {
    color?: string;
}

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    overflow-x: auto;
    height: 125px;

    @media (max-width: 650px) {
        justify-content: flex-start;
        height: 130px;
    }
`;

export const WorkspaceItem = styled.li<WorkspaceItemProps>`
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: black;
    margin-right: 15px;
    font-weight: 400;
    padding: 10px;
    width: 100px;
    max-height: 115px;
    min-height: 105px;
    min-width: 90px;
    cursor: pointer;
    position: relative;

    label {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        height: 35px;
    }

    :hover {
        ${(props) => props.color && css`
            color: ${props.color};
            font-weight: 500;
            background-color: ${props.color + "35"};
            border-radius: 5px;            
        `}

        .workspace-item-right {
            display: flex;
        }
    }

    ${(props) => props.selectedStyle && css`
            color: ${props.color};
            font-weight: 500;
            background-color: ${props.color + "35"};
            border-radius: 5px;
    `}
`;

export const WorkspaceItemIcon = styled.div<WorkspaceItemIconProps>`
    border: 1px solid #f23b5c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    border-radius: 5px;
    background-color: #f23b5c;
    min-height: 45px;
    min-width: 45px;

    ${(props) => props.color && css`
        border: 1px solid ${props.color};
        background-color: ${props.color};
    `}

    svg {
        color: white;
    }

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        font-size: 18px !important;
        
        svg {
            color: white;
        }
    }
`;

export const WorkspaceItemRight = styled.div<WorkspaceItemRightProps>`
    position: absolute;
    right: 10px;
    display: none;
    align-items: center;
    justify-content: center;
    
    ${(props) => props.color && css`
        color: ${props.color};            
    `}

    svg {
        font-size: 18px;
        margin-left: 10px;
        cursor: pointer;    
        transition: transform 0.3s ease;    

        :hover {            

            transform: scale(1.1);

        }
    }
`;

export const CleanBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;    
    border-radius: 10px;
    border: 1px dashed #f23b5c;
    font-size: 15px;
    cursor: pointer;
    color: gray;
    width: 100%;
    height: 100%;
    text-align: center;

    svg {
        font-size: 35px;
        margin: 15px;
        margin-right: 25px;
        color: #f23b5c;
    }

    :hover {
        
        color: #f23b5c;
    }
`;

export const CleanBoxText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 13px;
    margin-top: 2px;
    max-width: 380px;
    color: gray;
    margin-right: 15px;

    @media (max-width: 850px) {
        display: none;
    }
`;

export const CleanBoxTitle = styled.h2`
    font-size: 16px;
    text-align: left;
    color: black;
    margin-right: 15px;

    span {
        margin-left: 5px;
        font-size: 20px;        
    }
`;

export const ContainerLoader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 125px;
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 65% !important;
        max-width: 150px;
    }
    
`;