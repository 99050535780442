import styled, { css } from "styled-components";
import { TextField } from "unform-material-ui";

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
  isDisabled: boolean;
}

export const Container = styled.div<ContainerProps>`
  background-color: transparent;
  border: 0px;
  padding: 16px;
  padding-top: 11px;
  width: 100%;
  border-bottom: 1px solid gray;
  color: black;
  display: flex;
  align-items: center;

  & + div {
    margin-top: 30px;
  }
  svg {
    margin-right: 16px;
    margin-bottom: -5px;
    color: gray;
  }

  ${(props) =>
    props.isErrored &&
    css`
      color: red;
      border-bottom-color: red;
      /*
      svg {
        color: red;
      }
      */
    `}
  ${(props) =>
    props.isFocused &&
    css`
      color: #f23b5c;
      border-bottom-color: #f23b5c;
      svg {
        color: #f23b5c;
      }
    `}
  ${(props) =>
    props.isDisabled &&
    css`
      background-color: rgba(188, 188, 188, 0.1);
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    `}  
`;

export const TextInput = styled(TextField)`
  height: 100%;
  width: 100%;
  margin-left: 5px;

  p {
    display: none;
  }

  div {
    padding-bottom: 0px;

    &::before {
      flex: 1;
      background: transparent;
      border-bottom: none !important;
    }

    &::after {
      flex: 1;
      background: transparent;
      border-bottom: none !important;
    }

    textarea {
      flex: 1;
      background: transparent;
      border: 0;

      &::placeholder {
        opacity: 1;
        color: gray;
      }
    }
  }
`;
