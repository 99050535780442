import styled, { css } from "styled-components";

import Button from '../../components/Button';
import { Dialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';

interface IconHeaderProps {
    color: string;
}

interface ButtonGroupProps {
    active: boolean;
}

export const DialogContainer = styled(Dialog)`

    z-index: 9999999;

    .MuiDialog-paperWidthSm {
        max-width: 700px;
        min-height: 90vh;
    }
    
`;

export const Content = styled(DialogContent)`
    background: white;
`;

export const ContentBody = styled(Grid)`
    display: block;
    justify-content: center;
    height: auto;
    width: auto;
    //min-height: 450px;
    margin-bottom: 25px;
`;

export const DialogTop = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #eef2f8;
    margin-top: 15px;
    width: 100%;
    
`;

export const DialogTopLeft = styled.div`
    width: 90%;
    height: 100%;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: start;
    margin-left: 15px;

    h1 {
        margin-left: 10px;
        font-size: 20px;
        font-weight: 500;
        color: black;
    }
`;

export const DialogTopCenter = styled.div`
    width: 25%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
`;

export const DialogTopRight = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 30px;
        border: none;
        background-color: transparent;
    }
`;

export const IconHeader = styled.span<IconHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 100%;
    background-color: transparent;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 45px;
        height: 45px;
        background-color: #f4f4f4;
        border-radius: 50%;

        > svg {
            width: 25px;
            height: 25px;

            ${props => props.color && css`
                color: ${props.color} !important;
            `}
        }

    }
`;

export const DialogFooter = styled.div`
  border-top: 1px solid #eef2f8;
  padding: 15px !important;
  width: 100%;
`;

export const DialogResume = styled.div`
  position: relative;
  border-top: 1px solid #eef2f8;
  padding: 15px !important;
  background-color: white;
`;

export const DialogFooterCenter = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
`;

export const BtnInsertNewAnswer = styled(Button)`
    height: 50px;
    width: 95%;
    background-color: #f23b5c;
    color: white;
    border-radius: 5px;
    border: 0;
    font-weight: 500;
`;

export const FieldHeader = styled.div`
    margin-left: 15px;
`;

export const ContainerAutoComplete = styled.div`
    padding: 8px 24px;
`;

export const DialogFooterLeft = styled.div`
  text-align: left;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: flex-start;
  float: left;
`;

export const DialogFooterRight = styled.div`
  text-align: left;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: flex-start;
  float: right;
`;

export const ButtonEmptyState = styled(Button)`
  height: 35px;
  width: 120px;
`;

export const SpanField = styled.span`
    line-height: 1;
    margin: 0px 2px;
    white-space: nowrap;
    border-radius: 3px;
    border: 1px solid rgb(238,238,238);
    background-color: rgb(248,248,248);
    font-weight: 500;
    margin-left: 10px;
    padding: 7px 10px;
    font-size: 15px;
    color: gray;
    margin-top: 2px;
`;

export const HelpContainer = styled.div`
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 15px;
    margin-bottom: 25px;
    border-radius: 10px;
    background-color: #fff2e8;
`;

export const HelpItems = styled.div`
    padding: 15px;
    padding-bottom: 5px;
    width: 100%;
    height: 100%;
`;

export const HelpItem = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    align-items: stretch;
`;

export const HelpIcon = styled.div`
    width: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;

    svg {
        font-size: 25px;
        color: #ff8c2e;
    }
`;

export const HelpText = styled.div`
    width: 85%;
    font-size: 13px;
`;

export const SubHeaderLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
`;


export const ButtonGroupLeft = styled.button<ButtonGroupProps>`
    border: none;
    font-size: 12px;
    background-color: #f23b5d1e;
    padding: 5px 10px;
    border-radius: 10px 0px 0px 10px;
    border-right: 1px solid white;
    min-width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00000095;

    ${(props) =>
        props.active &&
        css`
        background-color: #f23b5d;
        color: white;
        font-weight: 500;
    `}
`;

export const ButtonGroupRight = styled.button<ButtonGroupProps>`
    border: none;
    font-size: 12px;
    background-color: #f23b5d1e;
    padding: 5px 10px;
    border-radius: 0px 10px 10px 0px;
    min-width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00000095;

    ${(props) =>
        props.active &&
        css`
        background-color: #f23b5d;
        color: white;
        font-weight: 500;
    `}
`;

export const DynamicFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
    max-width: 700px;
    background-color: white;
    border-radius: 8px;
`;

export const DynamicFieldForm = styled.div`
    width: 90%;
    min-width: 100px;
    padding: 25px 10px 25px 10px;
    margin-bottom: 15px;
`;

export const LabelInput = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: black;
    margin-bottom: 15px;
`;

export const SelectInput = styled.select`
    height: 35px;
    border-radius: 5px;
    border-color: #808080bf;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 100%;

`;

export const OptionItem = styled.option`

    svg {
        display: flex;
        font-size: 25px;
    }

`;

export const TitleResume = styled.div`
    border: 1px solid gray;
    border-radius: 10px;
    padding: 5px 10px;
    position: absolute;
    top: -13px;
    background-color: #f8f8f8;
    font-size: 11px;
    color: gray;
    font-weight: 500;
    text-transform: uppercase;
`;

export const ResumeDescription = styled.div`
    font-size: 15px;
    font-weight: 500;
    color: black;
    text-align: center;
    padding: 15px;
    font-weight: 200;
`;

export const ResumeTip = styled.div`
    font-size: 13px;
    font-weight: 400;
    color: gray;
    text-align: center;
`;

export const MappingFlow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eef2f8;
`;

export const MappingFlowTitle = styled.div`
    padding: 5px 8px;
    background-color: #8e8e8e;
    border-radius: 5px 0px 0px 5px;
    font-size: 14px;
    color: white;
    font-weight: 500;
`;

export const MappingFlowItemOrigin = styled.div`
    padding: 5px 8px;
    background-color: #eeeeee;
    font-size: 14px;
    color: #000000c4;
    font-weight: 400;
`;

export const MappingFlowIcon = styled.div`
    padding: 5px 0px;
    background-color: #eeeeee;
    font-size: 16px;
    color: #000000c4;
    font-weight: 500;
    display: flex;
`;

export const MappingFlowItemTarget = styled.div`
    padding: 5px 8px;
    background-color: #eeeeee;
    border-radius: 0px 5px 5px 0px;
    font-size: 14px;
    color: #000000c4;
    font-weight: 400;
`;
