import { Container, Title } from "./styles";
import React, { InputHTMLAttributes, useCallback, useEffect, useRef, useState } from "react";

import ErrorDescription from "../../../../ErrorDescription";
import { IconBaseProps } from "react-icons";
import { useField } from "@unform/core";

interface InputPhoneProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon?: React.ComponentType<IconBaseProps>;
    description?: string;
}

const InputPhone: React.FC<InputPhoneProps> = ({ name, description, icon: Icon, disabled, ...rest }) => {

    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const { fieldName, defaultValue, error, registerField } = useField(name);

    const maskPhone = (value: string) => {
        return value
            .replace(/\D/g, "")
            .replace(/(\d{2})(\d)/, "($1) $2")
            .replace(/(\d{5})(\d{4})(\d)/, "$1-$2");
    };

    const [fieldValue, setFieldValue] = React.useState(defaultValue !== undefined ? maskPhone(defaultValue) : "");

    const handleInputFocused = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: () => {

                if (fieldValue !== undefined) {
                    let value: string = fieldValue;

                    return value
                        .replace(/\D/g, "")
                        .replace(/(\d{2})(\d)/, "($1) $2")
                        .replace(/(\d{5})(\d{4})(\d)/, "$1-$2");

                } else {
                    return undefined;
                }
            },
            setValue: (refs, value) => {

                if (value !== null && value !== undefined) {

                    setFieldValue(value);

                    return value === "" ? undefined : value.replace("(", "").replace(") ", "");
                }

            },
            clearValue: () => {
                setFieldValue("");
            }
        });

    }, [fieldName, registerField, fieldValue]);

    return (
        <>
            {description && <Title>{description}</Title>}
            <Container isErrored={!!error} isFocused={isFocused} isDisabled={!!disabled} >
                {Icon && <Icon size="20" />}
                <input
                    onFocus={handleInputFocused}
                    onBlur={handleInputBlur}
                    onChange={(e) => setFieldValue(maskPhone(e.target.value))}
                    ref={inputRef}
                    disabled={disabled}
                    value={fieldValue}
                    {...rest}
                />

            </Container>

            {error && (<ErrorDescription title={error} />)}
        </>
    );
}

export default InputPhone;