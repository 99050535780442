import styled from "styled-components";

export const Container = styled.div`
    background-color: #f23b5d33;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
`;

export const UpgradeMessageContainer = styled.div`
    margin-left: 15px;
    width: calc(100% - 170px);

    h1 {
        font-size: 16px;
        color: #f23b5d;
    } 

    span {
        font-size: 12px;
        color: gray;
    }
`;

export const UpgradeButtonContainer = styled.div`
    position: absolute;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
`;

export const UpgradeButton = styled.a`
    width: 80%;
    padding: 5px 10px;
    text-decoration: none;
    color: white;
    font-size: 13px;
    border: none;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    height: 28px;
    background: linear-gradient(90deg,rgb(255,140,47) 25%,rgb(251,50,92) 50%,rgb(147,55,237) 75%);
    display: flex;
    justify-content: center;
    align-items: center;
`;