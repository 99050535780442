import { Container, Title } from "./styles";
import React, { InputHTMLAttributes, useCallback, useEffect, useRef, useState } from "react";

import ErrorDescription from "../../../../ErrorDescription";
import { IconBaseProps } from "react-icons";
import { useField } from "@unform/core";

interface InputDocProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon?: React.ComponentType<IconBaseProps>;
    description?: string;
    variation?: string;
}

const InputDoc: React.FC<InputDocProps> = ({ name, description, icon: Icon, disabled, variation, ...rest }) => {

    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const { fieldName, defaultValue, error, registerField } = useField(name);

    const typeDoc: number = variation === "2" ? 2 : variation === "3" ? 3 : 1; // 1 - CPF, 2 - CNPJ, 3 - CPF e CNPJ

    const maskCPF = (value: string) => {
        return value
            .replace(/\D/g, "")
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d{1,2})/, "$1-$2")
            .replace(/(-\d{2})\d+?$/, "$1");
    };

    const maskCNPJ = (value: string) => {
        return value
            .replace(/\D/g, '')
            .substring(0, 14)
            .replace(/^(\d{2})(\d)/, '$1.$2')
            .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
            .replace(/\.(\d{3})(\d)/, '.$1/$2')
            .replace(/(\d{4})(\d)/, '$1-$2')
    };

    const maskBoth = (value: string) => {
        if (value.length <= 14) {
            return value
                .replace(/\D/g, "")
                .replace(/(\d{3})(\d)/, "$1.$2")
                .replace(/(\d{3})(\d)/, "$1.$2")
                .replace(/(\d{3})(\d{1,2})/, "$1-$2")
                .replace(/(-\d{2})\d+?$/, "$1");
        } else {
            return value
                .replace(/\D/g, '')
                .substring(0, 14)
                .replace(/^(\d{2})(\d)/, '$1.$2')
                .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
                .replace(/\.(\d{3})(\d)/, '.$1/$2')
                .replace(/(\d{4})(\d)/, '$1-$2')
        }
    };

    const [fieldValue, setFieldValue] = React.useState(defaultValue === undefined ? "" : typeDoc === 1 ? maskCPF(defaultValue) : typeDoc === 2 ? maskCNPJ(defaultValue) : typeDoc === 3 ? maskBoth(defaultValue) : "");

    const handleInputFocused = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: () => {
                if (fieldValue !== undefined) {
                    let value: string = fieldValue;

                    if (typeDoc === 1) {
                        return value === "" ? undefined : value
                            .replace(/\D/g, "")
                            .replace(/(\d{3})(\d)/, "$1.$2")
                            .replace(/(\d{3})(\d)/, "$1.$2")
                            .replace(/(\d{3})(\d{1,2})/, "$1-$2")
                            .replace(/(-\d{2})\d+?$/, "$1");
                    } else if (typeDoc === 2) {
                        return value === "" ? undefined : value
                            .replace(/\D/g, '')
                            .substring(0, 14)
                            .replace(/^(\d{2})(\d)/, '$1.$2')
                            .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
                            .replace(/\.(\d{3})(\d)/, '.$1/$2')
                            .replace(/(\d{4})(\d)/, '$1-$2')
                    } else if (typeDoc === 3) {
                        if (value.length <= 14) {
                            return value === "" ? undefined : value
                                .replace(/\D/g, "")
                                .replace(/(\d{3})(\d)/, "$1.$2")
                                .replace(/(\d{3})(\d)/, "$1.$2")
                                .replace(/(\d{3})(\d{1,2})/, "$1-$2")
                                .replace(/(-\d{2})\d+?$/, "$1");
                        } else {
                            return value === "" ? undefined : value
                                .replace(/\D/g, '')
                                .substring(0, 14)
                                .replace(/^(\d{2})(\d)/, '$1.$2')
                                .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
                                .replace(/\.(\d{3})(\d)/, '.$1/$2')
                                .replace(/(\d{4})(\d)/, '$1-$2')
                        }
                    }

                } else {
                    return "";
                }
            },
            setValue: (ref, value) => {
                if (value !== null && value !== undefined) {
                    if (typeDoc === 1) {
                        setFieldValue(maskCPF(value))
                    } else if (typeDoc === 2) {
                        setFieldValue(maskCNPJ(value))
                    } else if (typeDoc === 3) {
                        setFieldValue(maskBoth(value))
                    }
                }
            },
            clearValue: () => {
                setFieldValue("");
            }
        });

    }, [fieldName, registerField, fieldValue, typeDoc]);

    return (
        <>
            {description && <Title>{description}</Title>}
            <Container isErrored={!!error} isFocused={isFocused} isDisabled={!!disabled} >
                {Icon && <Icon size="20" />}
                <input
                    onFocus={handleInputFocused}
                    onBlur={handleInputBlur}
                    onChange={(e) => {
                        if (typeDoc === 1) {
                            setFieldValue(maskCPF(e.target.value))
                        } else if (typeDoc === 2) {
                            setFieldValue(maskCNPJ(e.target.value))
                        } else if (typeDoc === 3) {
                            setFieldValue(maskBoth(e.target.value))
                        }
                    }}
                    ref={inputRef}
                    disabled={disabled}
                    value={fieldValue}
                    {...rest}
                />

            </Container>

            {error && (<ErrorDescription title={error} />)}
        </>
    );
}

export default InputDoc;