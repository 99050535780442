import { maskFormulaCurrency, maskFormulaNumber, maskFormulaPercent } from "../components/Forms/Fields/Components/Formula";
import { KanbanConfigProps } from "../components/Kanban";
import { Card } from "../interfaces/Card";
import { FormAnswerField } from "../interfaces/FormAnswerField";
import parseFormula from "../utils/Formula/parseFormula";

interface objectInit {
    [x: string]: string | object
}

export default function getCardFormula(cards: Card[], kanban_config?: KanbanConfigProps): Card[] {

    let newCards = cards;

        //If there is any highlight field
        if (kanban_config !== undefined) {

            //Filter the fields that are formula fields
            const formulaFields = kanban_config.fieldFormAll.filter((f) => f.type === "FORMULA_FIELD" && (String(f.card_aggregate) === "1" || f.card_highlight === "1"));

            if (formulaFields.length > 0) {
                //If there is any card in the column
                if (newCards !== undefined && newCards.length > 0) {
                    for (let index = 0; index < newCards.length; index++) {
                        const card = newCards[index];

                        //Run all formula fields
                        for (let idxA = 0; idxA < formulaFields.length; idxA++) {
                            const fField = formulaFields[idxA];

                            //If there is any form answer                            
                            if (card.form_answers !== undefined && card.form_answers.length > 0) {
                                //Filter the form answers that have the same form_id
                                const formulaFormAnswers = card.form_answers?.filter((fa) => fa.form_id === fField.form_id);

                                if (formulaFormAnswers.length > 0) {
                                    for (let idxB = 0; idxB < formulaFormAnswers.length; idxB++) {
                                        const foFa = formulaFormAnswers[idxB];

                                        const newObjData: objectInit = {};
                                        let maskedValue = "";
                                        let foundAnswer = false;
                                        let maxFormAnswer = 0;

                                        //Nomalize the data from form_answer_fields to object[]
                                        for (let idxC = 0; idxC < foFa.form_answer_fields.length; idxC++) {
                                            const formField = foFa.form_answer_fields[idxC];

                                            if (formField.field.type !== "FORMULA_FIELD") { //Remove the form field                        
                                                newObjData[formField.field.name] = formField.value;
                                            }

                                        }

                                        //Calcule formula value
                                        const calcValue = parseFormula(fField, kanban_config.fieldFormAll, newObjData as unknown as object[]);

                                        //Mask the value
                                        if (fField.variation !== undefined) {
                                            if (fField.variation === "1") { //Number
                                                maskedValue = maskFormulaNumber(calcValue);
                                            } else if (fField.variation === "2") { //Currency
                                                maskedValue = maskFormulaCurrency(calcValue);
                                            } else if (fField.variation === "3") { //Percent
                                                maskedValue = maskFormulaPercent(calcValue);
                                            } else {
                                                maskedValue = maskFormulaNumber(calcValue);
                                            }

                                        }

                                        //Update the all answers - For update the agreggation
                                        if (card.form_answers !== undefined && newCards[index] !== undefined) {
                                            for (let idxD = 0; idxD < card.form_answers.length; idxD++) {
                                                const faUpdate = card.form_answers[idxD];

                                                for (let idxE = 0; idxE < faUpdate.form_answer_fields.length; idxE++) {
                                                    const faFieldsUpdate = faUpdate.form_answer_fields[idxE];

                                                    if (faFieldsUpdate.field_id === fField.id_field) {
                                                        foundAnswer = true;
                                                        card.form_answers[idxD].form_answer_fields[idxE].valueString = maskedValue;
                                                        card.form_answers[idxD].form_answer_fields[idxE].value = calcValue;
                                                    }

                                                    if (maxFormAnswer < faFieldsUpdate.id_form_answer_field) {
                                                        maxFormAnswer = faFieldsUpdate.id_form_answer_field;
                                                    }
                                                }

                                            }
                                        }

                                        //Update the highlitgh answers
                                        card.form_answers_highlitgh = card.form_answers_highlitgh.map((fah) => {

                                            if (fah.field_id === fField.id_field) {

                                                fah.valueString = maskedValue;
                                                fah.value = calcValue;
                                            }

                                            return fah;
                                        });

                                        //If no found the answer 
                                        if (!foundAnswer && fField.id_field !== undefined) {
                                            const newAnswer: FormAnswerField = {
                                                id_form_answer_field: maxFormAnswer + 1,
                                                form_answer_id: foFa.id_form_answer,
                                                field_id: fField.id_field,
                                                index: 0,
                                                value: calcValue,
                                                valueString: maskedValue,
                                                field: fField
                                            }

                                            //Add a New form answer in highlight
                                            if (String(fField.card_highlight) === "1") {
                                                card.form_answers_highlitgh.push(newAnswer);
                                            }

                                            //Add a New form answer in form answer base
                                            card.form_answers = card.form_answers?.map((fa) => {

                                                if (fa.form_id === fField.form_id) {
                                                    fa.form_answer_fields.push(newAnswer);
                                                }

                                                return fa;
                                            })
                                        }

                                        newCards[index] = card;

                                    }
                                }
                            }
                        }
                    }                    

                }
            }
        }

    return newCards;
}