import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

interface ButtonLinkProps {
    active?: boolean;
    color?: string;
}

interface ViewSectionItemProps {
    isActive?: boolean;
    color?: string;
}

interface ButtonAddNewProps {
    color?: string;
}

export const ButonSaveDotRed = styled.div`
    position: absolute;
    left: 8px;
    top: 5px;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #f23b5d;
`;

export const ButtonSave = styled.button<ButtonLinkProps>`
    position: relative;
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;
    //width: 125px;
    max-height: 27px;

    svg {
        font-size: 16px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }

    ${props => props.active && css`
        background-color: #60bd4e;
        border-radius: 5px;   
        font-weight: 500;

        background-color: ${props.color + "1e"};
        color: ${props.color};

        :hover {
            background-color: ${props.color};
            color: white;

            svg {
                color: white;
            }
        }
    `}

    @media(max-width: 800px){ 
        svg {
            margin-right: 0px;
        }

        div.view-save {
            display: none;            
        }
    }
`;
    

export const ButtonLink = styled.button<ButtonLinkProps>`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 15px;
    //width: 125px;
    max-height: 27px;

    svg {
        font-size: 16px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }

    ${props => props.active && css`
        background-color: #60bd4e;
        border-radius: 5px;   
        font-weight: 500;

        background-color: ${props.color + "1e"};
        color: ${props.color};

        :hover {
            background-color: ${props.color};
            color: white;

            svg {
                color: white;
            }
        }
    `}

    @media(max-width: 800px){ 
        svg {
            margin-right: 0px;
        }

        div.view-desc {
            display: none;            
        }
    }
    
`;

export const ButtonClear = styled.button<ButtonLinkProps>`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;
    //width: 125px;
    max-height: 27px;

    svg {
        font-size: 16px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }

    ${props => props.active && css`
        background-color: #60bd4e;
        border-radius: 5px;   
        font-weight: 500;

        background-color: ${props.color + "1e"};
        color: ${props.color};

        :hover {
            background-color: ${props.color};
            color: white;

            svg {
                color: white;
            }
        }
    `}

    @media(max-width: 800px){ 
        svg {
            margin-right: 0px;
        }

        div {
            display: none;            
        }
    }
    
`;

export const BoxContainer = styled(Box)`
    position: absolute;
    top: 35px;
    right: 5px;
    z-index: 10;
    min-width: 350px;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 0px;
    margin-right: 10px;
    max-height: calc(100vh - 150px);
    padding: 15px;
    overflow: auto;

    //max-height: 50%;
    //overflow-y: auto;
`;

export const BoxContainerSaveView = styled(Box)`
    position: absolute;
    top: 30px;
    right: -10px;
    z-index: 10;
    min-width: 260px;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 0px;
    margin-right: 10px;
    max-height: calc(100vh - 150px);
    padding: 10px;

    //max-height: 50%;
    //overflow-y: auto;
`;

export const SearchInput = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    border-radius: 5px;
    border: solid 1px #808080bf;
    width: 100%;
    max-width: 400px;
    color: #4d4d4d;
    font-weight: 600;

    svg {
        font-size: 17px;
        margin-top: 1px;
        margin-left: 9px;
    }
`;

export const Input = styled.input`
    border: none;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 100%;
    max-width: 400px;

    ::placeholder {
        padding: 5px;
        padding-left: 0px;
        color: #4d4d4d;
        font-size: 13px;
    }
`;

export const ContainerViews = styled.div`

`;

export const ContainerCreate = styled.div`

`;

export const ContainerViewSection = styled.div`
    margin-top: 5px;
`;

export const ViewSectionList = styled.div`

`;

export const ViewSectionTitle = styled.div`
    font-size: 13px;
    border-bottom: 1px solid #f4f5f7;
    padding-top: 10px;
    padding-bottom: 5px;
    font-weight: 500;
    color: gray;
`;

export const DividerGroup = styled.div`
    font-size: 13px;
    border-bottom: 1px solid #f4f5f7;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 500;
    color: gray;
`;

export const ViewSectionItem = styled.div<ViewSectionItemProps>`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: black;
    margin-top: 10px;
    padding: 7px 10px;
    border-radius: 10px;

    ${props => props.isActive && css`
        background-color: ${props.color + "1e"};     
        color: ${props.color};     
        font-weight: 500;

        div {
            color: ${props.color};
        }
    `}
    

    :hover {

        background-color: #eadff7;

        ${props => props.color && css`
            background-color: ${props.color + "1e"};            
        `}

        .opt-item {
            display: flex;
        }
    }
`;

export const SectionItemIcon = styled.div`

    margin-right: 5px;

    div { 
        display: flex;
        align-items: center;
        justify-content: center;
        
        svg {
            font-size: 15px;
        }
    }

`;

export const SectionItemTitle = styled.div`
    font-size: 14px;
    color: #4d4d4d;
    margin-right: 20px;
`;

export const SectionItemAction = styled.div`
    display: none;
    position: absolute;
    right: 12px;
    color: gray;
    cursor: pointer;
    z-index: 999;

    svg {
        font-size: 12px;
    }
`;

export const ButtonAddNew = styled.button<ButtonAddNewProps>`
    margin-top: 10px;
    width: 100%;   
    height: 30px; 
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;
    background-color: #f23b5c;
    color: white;
    border-radius: 5px;

    svg {
        font-size: 15px;
        margin-right: 10px;
        margin-top: 1px;
    }

    ${props => props.color && css`
        background-color: ${props.color};     
        color: #4d4d4d;       
    `}

    
`;

export const CleanBox = styled.div`
    display: grid;
    justify-items: center;
    align-content: center;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
    border: 1px dashed #9e37ee;
    min-height: 250px;
    font-size: 15px;
    color: #9e37ee;
    height: calc(100% - 40px);
    width: 100%;
    text-align: center;

    svg {
        font-size: 55px;
        margin-bottom: 10px;
    }
`;

export const CleanBoxTitle = styled.h2`
    font-size: 15px;
    margin-bottom: 10px;

    span {
        margin-left: 5px;
        font-size: 20px;
    }
`;

export const CleanBoxText = styled.div`
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    margin-top: 2px;
    max-width: 500px;
    color: gray;
`;