
export function formatDuration(timeInSeconds: number, pocket?: boolean): string {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    if (pocket) {
        return `${formattedHours}:${formattedMinutes}`;
    } else {
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
};