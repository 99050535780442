import { FieldProps } from "../../components/Forms/Fields/FieldBuilder";
import { Flow } from "../../interfaces/Flow";

export interface KanbanConfigProps {
    fieldTitle: FieldProps[];
    fieldHighlight: FieldProps[];
    fieldFormInit: FieldProps[];
    fieldFormAll: FieldProps[];
}

export default function getKanbanConfig(flow: Flow): KanbanConfigProps {

    let kanbanConfig: KanbanConfigProps;

    //Lógica para setar o título e os itens que serão exibidos no card
    let arrFields = [] as FieldProps[];
    let arrFieldsFormInit = [] as FieldProps[];

    //Formulário Inicial
    if (flow.form_init !== undefined && flow.form_init !== null && flow.form_init.fields !== undefined && flow.form_init.fields !== null) {
        flow.form_init.fields.map((field) => {
            arrFields.push(field);
            arrFieldsFormInit.push(field);
            return field;
        });
    }

    //Forms Steps
    if (flow.flow_steps !== undefined && flow.flow_steps !== null) {
        for (let index = 0; index < flow.flow_steps.length; index++)
            flow.flow_steps[index].form.fields.map((field) => {
                arrFields.push(field);
                return field;
            });
    }

    const fieldTitleArr: FieldProps[] = [];

    const fieldTitle = arrFields.filter((field) => field.card_title === "1");
    if (fieldTitle.length > 0) {
        fieldTitle.map((field) => {
            if (field.id_field !== undefined) {
                fieldTitleArr.push(field);
            }
            return field;
        })
    }

    const fieldHighlightArr: FieldProps[] = [];

    const fieldHighlight = arrFields.filter((field) => field.card_highlight === "1");
    if (fieldHighlight.length > 0) {
        fieldHighlight.map((field) => {
            if (field.id_field !== undefined) {
                fieldHighlightArr.push(field);
            }
            return field;
        })
    }

    kanbanConfig = {
        fieldTitle: fieldTitleArr,
        fieldHighlight: fieldHighlightArr,
        fieldFormInit: arrFieldsFormInit,
        fieldFormAll: arrFields
    }

    return kanbanConfig;

}