import styled from "styled-components";

export const MarginTop = styled.div`
  margin-top: 15px;
`;

export const Container = styled.label`
  height: auto;
  width: 100%;
  padding: 0px 0px 24px 0px;
  box-sizing: border-box;
`;

export const ItemCB = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  position: relative;
  box-sizing: border-box;
`;

export const LabelCB = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  background: #eef2f8;
`;

export const SpanCB = styled.label`
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: black;
  width: 100%;
  padding-left: 10px;
  padding-top: 5px;
`;

export const InputCB = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 10px;

  &:hover ~ ${LabelCB} {
    background: #e5e7ea;
    &::after {
      content: "";
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
  &:checked + ${ItemCB} {
    background: #eef2f8;
    border: 2px solid transparent;
  }

  &:checked + ${LabelCB} {
    background: #eef2f8;
    padding: 5px;
    border: 2px solid #f23b5c;
    &::after {
      content: "";
      display: block;
      background-color: #f23b5c;
      margin-left: -1px;
      margin-top: -1px;
      width: 12px;
      height: 12px;
    }
  }
`;
