import React, { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";

import {
    BottomContainer,
    BottomItems,
    CloseContainer,
    ContentContainer,
    RightPanelContainer,
    TitleContainer,
    TopContainer
} from "./styles";

interface RightPanelProps {
    open: boolean;
    title: string;
    closePanel?: () => void;
    button?: JSX.Element;
    children?: React.ReactNode;
}

const RightPanel: React.FC<RightPanelProps> = ({ open, title, closePanel, children, button }) => {

    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && closePanel !== undefined) {
                closePanel();
            }
        };

        window.addEventListener('keydown', handleEscape);

        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, [closePanel]);

    return (
        <RightPanelContainer isOpen={open}>
            <TopContainer>
                <TitleContainer>
                    <h1>
                        {title}
                    </h1>
                </TitleContainer>
                {closePanel !== undefined ?
                    <CloseContainer>
                        <button onClick={closePanel}><AiOutlineClose></AiOutlineClose></button>
                    </CloseContainer> :
                    <></>
                }
            </TopContainer>
            <ContentContainer>
                {children}
            </ContentContainer>
            {button !== undefined ?
                <BottomContainer>
                    <BottomItems>
                        {button}
                    </BottomItems>
                </BottomContainer> :
                <></>
            }
        </RightPanelContainer>
    );
}

export default RightPanel;