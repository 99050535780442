import { FieldProps } from "../components/Forms/Fields/FieldBuilder";
import { Form } from "../interfaces/Form";
import { Step } from "../interfaces/Step";

export default function getFields(form_init: Form, flow_steps?: Step[]): FieldProps[] {

    let fields: FieldProps[] = [];

    //Get the fields from form init
    if (form_init !== undefined && form_init !== null && form_init.fields !== undefined && form_init.fields !== null) {
        fields.push(...form_init.fields);
    }

    //Get the fields from steps
    if (flow_steps !== undefined && flow_steps !== null) {
        for (let index = 0; index < flow_steps.length; index++) {
            const step = flow_steps[index];

            if (step !== undefined && step.form !== undefined && step.form !== null && step.form.fields !== undefined && step.form.fields !== null) {
                fields.push(...step.form.fields);
            }

        }
    }

    return fields;

}