import { TimeSheetFieldProps } from ".";
import { TimeSheetDataTableProps } from "./getAggregateObject";

export default function getDrillDownObject(dataValue: TimeSheetDataTableProps[], dataFields: TimeSheetFieldProps[], currentLevel: number): TimeSheetDataTableProps[] | undefined {

    let groupedData: TimeSheetDataTableProps[] = [];

    const currentField = dataFields.find((field) => field.index === currentLevel);

    if (currentField === undefined) {
        return undefined;
    }

    //Group the data by the current field
    for (let index = 0; index < dataValue.length; index++) {
        const item = dataValue[index];

        let newItem = {} as TimeSheetDataTableProps;
        newItem = { ...item };

        //Get and Set the key title
        const keyTitle = item[currentField.title];
        newItem.title = keyTitle;

        //Check if the item already exists
        let findItem = groupedData.find((group) => group.title === keyTitle);

        if (findItem === undefined) { //If the item not exists, create a new item 

            //Remove the fields that are not in the group
            delete newItem.user;
            delete newItem.user_id;
            delete newItem.duration;

            groupedData.push(newItem);
        }

    }

    //Run the grouped data and create the new items
    groupedData = groupedData.map((item) => {

        //Get the items that are in the group
        const newItems = dataValue.filter((row) => row[currentField.title] === item.title);

        //Get the duration of the items
        const duration = newItems.reduce((sum: number, item: TimeSheetDataTableProps) => {
            return sum + (item.duration || item['duration'] || 0);
        }, 0);

        item.items = newItems;
        item.duration = duration;

        return item;
    });

    //Run to the next level
    if (currentLevel < dataFields.length) {
        groupedData = groupedData.map((item) => {
            if (item.items !== undefined) {
                item.items = getDrillDownObject(item.items, dataFields, currentLevel + 1);
            }
            return item;
        });
    }

    //Reorder the item.items by the duration, higher to lower
    groupedData = groupedData.map((item) => {
        if (item.items !== undefined) {
            item.items = item.items.sort((a, b) => {
                if (a.duration && b.duration) {
                    return b.duration - a.duration;
                }
                return 0;
            });
        }
        return item;
    });


    //Reorder the items by the duration, higher to lower
    groupedData = groupedData.sort((a, b) => {
        if (a.duration && b.duration) {
            return b.duration - a.duration;
        }        

        return 0;
    });

    return groupedData;

}