import styled from "styled-components";

export const Container = styled.div`

`;

export const EmptyItem = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed gray;
    padding: 7px;
    border-radius: 20px;
    font-size: 18px;
    height: 35px;
    max-height: 35px;
    width: 80px;
    cursor: pointer;
    background-color: transparent;

    svg {
        color: gray;        
    }

    :hover {
        border-color: #f23b5d;

        svg {
            color: #f23b5d;
        }
    }
`

export const ChildrenBtn = styled.button`
    border: none;
    background-color: transparent;
`;