import {
    BadgeInvited,
    BtnInvite,
    CardUser,
    CardUserEmpty,
    CardUserInvited,
    Container,
    ImageContainer,
    ImageContainerEmpty,
    ImageEmpty,
    NameContainer
} from "./style";
import React, { useEffect, useState } from "react";

import Avatar from 'react-avatar';
import { FaPlus } from "react-icons/fa";
import { InvitedUser } from '../../../interfaces/InvitedUser';
import { User } from "../../../interfaces/User";
import api from '../../../services/api';
import getAccessControl from '../../../middlewares/AccessControl';
import { useToast } from '../../../hooks/toast';
import AvatarCange from "../../AvatarCange";

interface defaultProps {
    typeUser: string;
    handleAddNewUser: () => Promise<void>;
}

const MyTeam: React.FC<defaultProps> = ({ handleAddNewUser, typeUser }) => {

    const [users, setUsers] = useState<User[]>();
    const [flowInvitedUsers, setFlowInvitedUsers] = useState<InvitedUser[]>();
    const { addToast } = useToast();

    useEffect(() => {

        api.get(`/user/by-company`, {
        }).then(response => {

            const userApi: User[] = response.data;

            setUsers(userApi);

        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Erro ao buscar o seu Time!',
                description: 'Ocorreu um erro ao carregar o seu Time!',
            });
        });

        api.get(`/invite-user`, {
        }).then(response => {
            if (response.data !== null) {
                const inviteApi: InvitedUser[] = response.data;

                setFlowInvitedUsers(inviteApi);

            }
        });

    }, [addToast]);

    return (
        <Container>
            {getAccessControl(11, typeUser) ?
                <CardUserEmpty onClick={() => handleAddNewUser()}>
                    <ImageContainerEmpty>
                        <ImageEmpty>
                            <FaPlus />
                        </ImageEmpty>
                    </ImageContainerEmpty>
                    <BtnInvite>
                        Convide novos usuários aqui
                    </BtnInvite>
                </CardUserEmpty> : <></>
            }
            {users?.map((user) => {
                return (
                    <CardUser key={user.id_user}>
                        <ImageContainer>
                            <AvatarCange
                                user={user}
                                size="50"
                            />
                        </ImageContainer>
                        <NameContainer>
                            {user.name}
                        </NameContainer>
                    </CardUser>
                )
            })}
            {flowInvitedUsers?.map((invitedUser) => {
                return (
                    <CardUserInvited key={invitedUser.id_invited_user}>
                        <ImageContainer>
                            <Avatar
                                color={"gray"}
                                size="50"
                                name={invitedUser.name}
                                round="100%" />
                        </ImageContainer>
                        <NameContainer>
                            {invitedUser.name + "*"}
                        </NameContainer>
                        <BadgeInvited>
                            Convite Enviado
                        </BadgeInvited>
                    </CardUserInvited>
                )
            })}
        </Container>
    );

}

export default MyTeam;