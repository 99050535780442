import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import styled, { css } from "styled-components";
import Button from '../../../components/Button';

interface IconHeaderProps {
    color: string;
}

interface BtnInsertNewAnswerProps {
    color: string;
}

interface AvatarEditorContainerProps {
    colorEditorBackground?: string;
}

export const Content = styled(DialogContent)`
    background-color: #f4f5f7;   
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: center;
    height: auto;
    width: auto;
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const DialogTop = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #eef2f8;
    margin-top: 15px;
    width: 100%;
    
`;

export const DialogTopLeft = styled.div`
    width: 65%;
    height: 100%;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: start;
    margin-left: 15px;

    h1 {
        margin-left: 20px;
        font-size: 20px;
        font-weight: 500;
        color: black;
    }
`;

export const DialogTopCenter = styled.div`
    width: 25%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
`;

export const DialogTopRight = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 30px;
        border: none;
        background-color: transparent;
    }
`;

export const IconHeader = styled.span<IconHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 100%;
    background-color: transparent;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 45px;
        height: 45px;
        background-color: #f4f4f4;
        border-radius: 50%;

        > svg {
            width: 25px;
            height: 25px;

            ${props => props.color && css`
                color: ${props.color} !important;
            `}
        }

    }
`;

export const DialogFooter = styled.div`
  border-top: 1px solid #eef2f8;
  padding-top: 10px;
  padding-bottom: 15px;
  width: 100%;
`;

export const DialogFooterCenter = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
`;

export const BtnInsertNewAnswer = styled(Button) <BtnInsertNewAnswerProps>`
    height: 50px;
    width: 100%;
    background-color: #f23b5c;
    color: white;
    border-radius: 5px;
    border: 0;
    font-weight: 500;

    ${props => props.color && css`
        background-color: ${props.color} !important;
    `}
`;

export const ContainerFields = styled(Grid)`
    background-color: white;
    border-radius: 10px 10px 10px 10px;
    padding-right: 4%;
    padding-left: 4%;
    padding-top: 1%;
    padding-bottom: 2%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: auto;
`;

export const AvatarEditorContainer = styled.div<AvatarEditorContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    flex-direction: column;

    ${props => props.colorEditorBackground && css`
        canvas {
            background-color: ${props.colorEditorBackground} !important;
        }
    `}
`;

export const InputFileAvatar = styled.input`
    display: none;
`;

export const ContainerInputFile = styled.button`
    border: 1px dashed #eef2f8;
    border-radius: 5px;
    cursor: pointer;
    max-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s ease;
    margin: 20px;
    margin-top: 25px;
    background-color: transparent;

    :hover {
        color: #f23b5d;
        border-color: #f23b5d;

        svg {
            color: #f23b5d;
        }
    }

    p {
        display: flex;
        align-items: center;
        text-align: center;
        padding: 15px 0;
        font-size: 14px;
        font-weight: 400;
    }

    svg {
        height: 15px;
        width: 15px;
        color: #4d4d4d;
        margin-right: 5px;
        margin-left: 5px;
        min-width: 15px;
    }
`;