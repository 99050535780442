import styled from "styled-components";

export const Container = styled.div`

    background-color: #f3f4f6;
    border-radius: 4px;
    padding: 10px;

    h1 {
        font-size: 30px;
        color: #000000c9;
        margin-left: 10px;
    }
`;