import React, { createContext, useCallback, useContext, useState } from 'react';
import { Omit } from 'react-router';

import TrackingDock from '../components/TrackingDock';
import { TimeTracking } from '../interfaces/TimeTracking';

export interface TrackingItem extends TimeTracking {

}

interface TrackingContextData {
    addTracking(message: Omit<TrackingItem, 'id_time_tracking'>): void;
    removeTracking(): void;
}

interface TrackingProviderProps {
    children?: React.ReactNode;
}

const TrackingContext = createContext({} as TrackingContextData);

const TrackingProvider: React.FC<TrackingProviderProps> = ({ children }) => {

    const [tracking, setTracking] = useState<TrackingItem>();

    const addTracking = useCallback((newTracking: TrackingItem) => {
        setTracking(newTracking);
    }, []);

    const removeTracking = useCallback(() => {
        setTracking(undefined);
    }, []);

    return (
        <TrackingContext.Provider value={{ addTracking, removeTracking }}>
            {children}
            <TrackingDock tracking={tracking} />
        </TrackingContext.Provider>
    );
}

function useTracking(): TrackingContextData {
    const context = useContext(TrackingContext);

    if (!context) {
        throw new Error('useTracking must be used within a TrackingProvider');
    }

    return context;
}

export { TrackingProvider, useTracking };