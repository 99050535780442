import styled, { css } from 'styled-components';
import { Box } from "@material-ui/core";

interface BoxSpanProps {
    color?: string;
}

export const Input = styled.input`
    height: 35px;
    border-radius: 0px 5px 5px 0px;
    border: solid 1px #808080bf;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 200px;

    ::placeholder {
        font-size: 14px;
        padding: 5px;
        color: gray;
    }
`;

export const BoxItemsContainer = styled.div`
    max-height: 170px;
    overflow-y: auto;
`;


export const BoxContainer = styled(Box)`
    position: absolute;
    z-index: 999999999;
    top: auto;
    right: auto;
    left: auto;
    min-width: 250px;
    max-width: 300px;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    padding-bottom: 10px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-right: 25px;
    padding-top: 10px;
`;

export const BoxItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    cursor: pointer;

    :hover {
        background-color: #f4f5f7;
        border-radius: 5px;
    }
`;

export const BoxSpan = styled.span<BoxSpanProps>`
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 5px;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 5px;
    color: rgba(51,51,51,0.9);
    background-color: #4681b81e;
    margin-left: 5px;

    ${props => props.color && css`
        background-color: ${props.color};
        color: white;
    `}
`;

export const BoxSpanDynamic = styled.span`
    font-size: 12px;
    font-style: italic;    
    color: gray;
`;

export const NoResults = styled.span`
    color: gray;
    font-style: italic;
    font-size: 13px;
    margin-top: 5px;
    margin-left: 5px;
`;

export const BoxMultiple = styled.div`
    margin-left: 5px;
    display: flex;
    margin-right: 5px;
`;

export const SelectInputValue = styled.div`
    display: inline;
`;

export const SelectInput = styled.div`
    height: 35px;
    display: flex;
    overflow: hidden;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid;
    border-radius: 0px 5px 5px 0px;
    border-color: #808080bf;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 200px;
`;