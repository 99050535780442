import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    ButtonAction,
    ButtonActionBack,
    ButtonSubmitt,
    ButtonTryCange,
    ConatinerSuccessButton,
    Container,
    ContainerAction,
    ContainerActionButtons,
    ContainerActionItems,
    ContainerBackground,
    ContainerBody,
    ContainerBottom,
    ContainerBottomDisclaimer,
    ContainerBottomText,
    ContainerButtonAction,
    ContainerDetails,
    ContainerHeader,
    ContainerInfo,
    ContainerInfoDescription,
    ContainerInfoDescriptionText,
    ContainerInfoImage,
    ContainerInfoTextTitle,
    ContainerMain,
    ContainerRightTitle,
    ContainerSuccess,
    ContainerSuccessIcon,
    ContainerSuccessMessage,
    ContainerSuccessTitle,
    DividerMenu
} from "./style";
import { Form } from "../../interfaces/Form";
import FormBuilder from "../Forms/FormBuilder";
import { FormHandles } from "@unform/core";
import { FormAnswerFieldApi } from "../../interfaces/FormAnswerFieldApi";
import { FormAnswerApi } from "../../interfaces/FormAnswerApi";
import api from "../../services/api";
import { useToast } from "../../hooks/toast";
import logoCange from '../../assets/wendata-logo.png';
import { TbCheckbox } from "react-icons/tb";
import { FieldProps } from "../Forms/Fields/FieldBuilder";
import { Card } from "../../interfaces/Card";
import { Step } from "../../interfaces/Step";
import getCheckConditionalStep from "../Forms/FormBuilder/getCheckConditionalStep";
import { IconList } from "react-fa-icon-picker";

interface PublicFormProps {
    flow_id: number;
    form: Form;
    isConfig?: boolean;
}

const PublicForm: React.FC<PublicFormProps> = ({ form, flow_id, isConfig }) => {

    const { addToast } = useToast();

    const formRef = useRef<FormHandles>(null);

    const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
    const [loadingInsertStep, setLoadingInsertStep] = useState<boolean>(false);

    const [status, setStatus] = useState<number>(0); //0 - Inserting // 1 - Subimited // 2 - Error
    const [fields, setFields] = useState<FieldProps[]>(form.fields);
    const [isFromStep, setIsFromStep] = useState<boolean>(false);

    const [loadingSteps, setLoadingSteps] = React.useState<boolean>(false);
    const [baseSteps, setBaseSteps] = useState<Step[]>();
    const [nextSteps, setNextSteps] = useState<Step[]>();
    const [previousSteps, setPreviousSteps] = useState<Step[]>();
    const [newStep, setNewStep] = useState<Step>();

    const handleAddAnswer = useCallback(async () => {

        if (formRef.current !== null) {
            await formRef.current.submitForm();
        }

    }, []);

    const moveCard = useCallback(async (newStep: Step) => {

        if (form.card !== undefined) {

            await api
                .post('/form-public/card/move-step', {
                    form_public_hash: form.public_hash,
                    card_public_hash: form.card.public_hash,
                    to_step_id: newStep.id_step,
                    from_step_id: form.card.flow_step_id,
                    complete: newStep.isEndStep === "1" ? "S" : "N"
                })
                .then(response => {

                }).catch(error => {

                    if (!isFromStep) {
                        setLoadingInsert(false);
                    } else {
                        setLoadingInsertStep(false);
                    }

                    addToast({
                        type: 'error',
                        title: 'Erro ao mover o cartão',
                        description: error.response.data.message
                    });
                });

        } else {
            if (!isFromStep) {
                setLoadingInsert(false);
            } else {
                setLoadingInsertStep(false);
            }
        }

    }, [addToast, form.card, form.public_hash, isFromStep]);

    const handleSubmit = useCallback(async (data: object[]) => {

        if (form !== undefined && form.id_form !== undefined) {

            //Variables
            const dataNormalized = data as unknown as FormAnswerFieldApi[];
            let objApi: FormAnswerApi | undefined = undefined;
            let valided = true;

            if (!isFromStep) { //Form Init
                objApi = {
                    id_form: form.id_form,
                    public_hash: form.public_hash,
                    origin: "/PublicForm",
                    values: dataNormalized,
                    flow_id: flow_id
                }
            } else if (form.card !== undefined && form.card.flow_step_id !== undefined && form.card.id_card !== undefined && newStep !== undefined) { //Form from step
                objApi = {
                    id_form: form.id_form,
                    public_hash: form.public_hash,
                    origin: "/PublicForm/Step",
                    values: dataNormalized,
                    flow_id: flow_id,
                    flow_step_id: isFromStep ? form.card?.flow_step_id : undefined,
                    card_id: isFromStep ? form.card?.id_card : undefined,
                    moveToStep: isFromStep && newStep !== undefined ? newStep.id_step : undefined
                }
            } else {
                valided = false;
            }

            if (objApi !== undefined && valided) {

                if (!isFromStep) {
                    setLoadingInsert(true);
                } else {
                    setLoadingInsertStep(true);
                }

                await api
                    .post('/form-public/new-answer', objApi)
                    .then(response => {
                        setStatus(1);

                        if (!isFromStep) {
                            setLoadingInsert(false);
                        } else {
                            setLoadingInsertStep(false);
                        }

                        if (isFromStep && newStep !== undefined) {
                            moveCard(newStep);
                        }

                    }).catch(error => {

                        if (!isFromStep) {
                            setLoadingInsert(false);
                        } else {
                            setLoadingInsertStep(false);
                        }

                        console.log(error);
                        setStatus(2);
                        addToast({
                            type: 'error',
                            title: 'Erro ao inserir a sua resposta :(',
                            description: 'Ocorreu um erro ao inserir o registro! [1]',
                        });
                    });

            } else {
                setStatus(2);
                addToast({
                    type: 'error',
                    title: 'Erro ao inserir a sua resposta :(',
                    description: 'Ocorreu um erro ao inserir o registro! [2]',
                });
            }

        }

    }, [addToast, form, flow_id, isFromStep, moveCard, newStep]);

    const handleMoveCard = useCallback(async (stepNew: Step) => {

        await setNewStep(stepNew);

        if (formRef.current !== null) {
            formRef.current.submitForm();
        }

    }, []);

    const getApiNextSteps = useCallback(async (card: Card, preAnswer?: object) => {

        if (card !== undefined) {
            if (card.flow_step_id !== undefined && card.flow_id !== undefined) {

                setLoadingSteps(true);

                await api.get(`/form-public/flow-step/relationship`, {
                    params: {
                        id_step: card.flow_step_id,
                        flow_id: card.flow_id,
                        public_hash: form.public_hash,
                    }
                }).then(response => {
                    if (response.data !== null) {

                        let respStep: Step[] = response.data;

                        if (respStep !== undefined) {

                            setBaseSteps(respStep);

                            for (let index = 0; index < respStep.length; index++) {
                                const s = respStep[index];

                                if (s.conditionals !== undefined && s.conditionals !== null && s.conditionals.length > 0) { //Has any conditional

                                    //Check the conditional
                                    respStep[index] = getCheckConditionalStep(s, preAnswer, undefined, card);

                                }

                            }

                            const restStepCurrent: Step[] = respStep.filter((step) => step.id_step === card?.flow_step_id);
                            if (restStepCurrent.length > 0) {

                                //Logica para separa os steps proximos e anteriores
                                const aNextStep = respStep.filter((step) => step.index > restStepCurrent[0].index && step.step_relationship?.isActive === "1" && (step.isAllowedMoviment === undefined || step.isAllowedMoviment === true));
                                const aPreviousStep = respStep.filter((step) => step.index < restStepCurrent[0].index && step.step_relationship?.isActive === "1" && (step.isAllowedMoviment === undefined || step.isAllowedMoviment === true));

                                setNextSteps(aNextStep);
                                setPreviousSteps(aPreviousStep);

                            }

                        }

                    }

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao buscar as Etapas',
                        description: 'Ocorreu ao tentar abrir as Etapas!',
                    });

                });

            }
        }

        setLoadingSteps(false);

    }, [addToast, form.public_hash]);

    const updateSteps = useCallback((currData: object[], card: Card) => {

        let newSteps = baseSteps;

        if (newSteps !== undefined && newSteps.length > 0) { //Has any previous step

            for (let index = 0; index < newSteps.length; index++) {
                const s = newSteps[index];

                if (s.conditionals !== undefined && s.conditionals !== null && s.conditionals.length > 0) { //Has any conditional

                    //Check the conditional
                    newSteps[index] = getCheckConditionalStep(s, undefined, currData, card);

                }
            }

            //Update the previus and next steps
            const restStepCurrent: Step[] = newSteps.filter((step) => step.id_step === card?.flow_step_id);
            if (restStepCurrent.length > 0) {

                //Logica para separa os steps proximos e anteriores
                const aNextStep = newSteps.filter((step) => step.index > restStepCurrent[0].index && step.step_relationship?.isActive === "1" && (step.isAllowedMoviment === undefined || step.isAllowedMoviment === true));
                const aPreviousStep = newSteps.filter((step) => step.index < restStepCurrent[0].index && step.step_relationship?.isActive === "1" && (step.isAllowedMoviment === undefined || step.isAllowedMoviment === true));

                setNextSteps(aNextStep);
                setPreviousSteps(aPreviousStep);

            }

        }

    }, [baseSteps]);

    const handleBlurForm = useCallback(async () => {

        if (isFromStep) {
            if (formRef.current !== null && form.card !== undefined) {
                const currData: object[] = formRef.current.getData() as object[];

                updateSteps(currData, form.card);
            }
        }

    }, [form.card, updateSteps, isFromStep])

    useEffect(() => {

        if (form.fields !== undefined && form.fields !== null) {
            setFields(form.fields);
        }

        if (form.card !== undefined && form.card !== null && form.card.public_hash !== undefined && form.card.public_hash !== null && form.card.public_hash !== '') {
            setIsFromStep(true);

            getApiNextSteps(form.card);
        }

    }, [form.fields, getApiNextSteps, form.card]);

    return (
        <Container id="public-form" color={form.color_background && form.color_background !== null ? form.color_background : '#f4f5f7'}>

            <div id="public-spacing" style={{ marginTop: '30px' }} />

            {form.cover_url !== undefined && form.cover_url !== null ?
                <ContainerBackground>
                    <img src={form.cover_url} alt="Imagem de capa" />
                </ContainerBackground> :
                <></>
            }

            <ContainerMain>
                {form.logo_url !== undefined && form.logo_url !== null && (
                    <ContainerHeader>
                        <ContainerInfo id="public-logo" style={form.cover_url !== undefined && form.cover_url !== null ? { marginTop: '80px' } : {}}>
                            <ContainerInfoImage>
                                <img src={form.logo_url} alt="Logo da empresa" />
                            </ContainerInfoImage>
                        </ContainerInfo>
                    </ContainerHeader>
                )}

                {(form.description !== undefined && form.description !== null && form.description !== '' && form.description !== '<p><br></p>' && form.description !== '<p></p>') || (form.title !== undefined && form.title !== null && form.title !== '') ?
                    <ContainerHeader>
                        <ContainerDetails>
                            <ContainerInfoDescription>
                                {form.title !== undefined && form.title !== null && form.title !== '' && (
                                    <ContainerInfoTextTitle>
                                        {form.title}
                                    </ContainerInfoTextTitle>
                                )}
                                {status === 0 && form.description !== undefined && form.description !== null && form.description !== '' && form.description !== '<p><br></p>' && form.description !== '<p></p>' && (
                                    <>
                                        <DividerMenu />
                                        <ContainerInfoDescriptionText dangerouslySetInnerHTML={{ __html: form.description !== undefined ? form?.description : "" }} />
                                    </>
                                )}
                            </ContainerInfoDescription>
                        </ContainerDetails>
                    </ContainerHeader> :
                    <></>
                }

                {status === 1 ?
                    <>
                        <ContainerSuccess>
                            <ContainerSuccessIcon>
                                <TbCheckbox />
                            </ContainerSuccessIcon>
                            <ContainerSuccessTitle>
                                Formulário enviado com sucesso!
                            </ContainerSuccessTitle>
                            {form.sent_message && form.sent_message !== null ?
                                <ContainerSuccessMessage dangerouslySetInnerHTML={{ __html: form.sent_message !== undefined ? form?.sent_message : "" }} /> :
                                <ContainerSuccessMessage>{"Obrigado por responder ao formulário. Sua resposta foi enviada com sucesso."}</ContainerSuccessMessage>
                            }
                            {form.show_another_answer && form.show_another_answer !== null && form.show_another_answer === 'S' && (
                                <ConatinerSuccessButton>
                                    <ButtonSubmitt
                                        onClick={isConfig ? () => { } : () => setStatus(0)}
                                        style={{ background: 'transparent', fontWeight: '400', fontSize: '15px', color: form.color_brand && form.color_brand !== null ? form.color_brand : "#f23b5c" }}
                                    >
                                        Enviar outra resposta
                                    </ButtonSubmitt>
                                </ConatinerSuccessButton>
                            )}
                        </ContainerSuccess>
                        <ContainerSuccess>
                            <ContainerSuccessMessage style={{ marginBottom: '10px' }}>
                                Você pode também criar seu próprio formulário personalizado com o Cange
                            </ContainerSuccessMessage>
                            <ConatinerSuccessButton style={{ marginBottom: '15px' }}>
                                <ButtonTryCange
                                    href={"https://www.cange.me?utm_source=cange&utm_campaign=public-form-sucess&utm_term=" + form.company_id} rel="noreferrer" target="_blank"
                                >
                                    Criar meu formulário
                                </ButtonTryCange>
                            </ConatinerSuccessButton>
                        </ContainerSuccess>
                    </> :
                    <>
                        <ContainerBody>
                            <FormBuilder
                                id="formNewAnswer"
                                formRef={formRef}
                                fields={fields}
                                handleSubmit={handleSubmit}
                                handleBlur={handleBlurForm}
                                hideContainer={false}
                                flow_id={flow_id}
                                hideAutoComplete={true}
                                isNewAnswer={true} //Mudar depois da implementação para etapas
                                isPublicForm={isConfig ? false : true}
                                public_hash={isConfig ? undefined : form.public_hash}
                                card_current_id={isFromStep && form.card !== undefined ? form.card?.id_card : undefined}
                                card_current={isFromStep && form.card !== undefined ? form.card : undefined}
                                setFields={setFields}
                                typeUser={'P'}
                                activeHiddenFields={true}
                            />
                        </ContainerBody>

                        <ContainerAction>
                            <ContainerActionButtons>
                                {!isFromStep ?
                                    <ButtonSubmitt
                                        isLoading={loadingInsert}
                                        color={form.color_brand && form.color_brand !== null ? form.color_brand : undefined}
                                        onClick={isConfig ? () => { } : handleAddAnswer}
                                    >
                                        {form.button_text && form.button_text !== null ? form.button_text : "Enviar"}
                                    </ButtonSubmitt> : loadingSteps ? <>Carregando Etapas</> :
                                        <ContainerActionItems>
                                            {previousSteps?.length === 0 && nextSteps?.length === 0 ? <></> : <ContainerRightTitle>Enviar para</ContainerRightTitle>}
                                            <ContainerButtonAction>
                                                {nextSteps?.map((step) => {
                                                    return (
                                                        <ButtonAction
                                                            key={step.id_step}
                                                            iconDynamic={step.icon !== undefined ? step.icon as IconList : "FaEllipsisH" as IconList}
                                                            iconDynamicColor={"white"}
                                                            color={step.color}
                                                            title={step.name}
                                                            onClick={() => handleMoveCard(step)}
                                                            isLoading={loadingInsertStep}
                                                        >
                                                            <span style={{ overflow: "hidden" }}>{step.name}</span>
                                                        </ButtonAction>
                                                    )
                                                })}
                                                {previousSteps?.map((step) => {
                                                    return (
                                                        <ButtonActionBack
                                                            key={step.id_step}
                                                            iconDynamic={step.icon !== undefined ? step.icon as IconList : "FaEllipsisH" as IconList}
                                                            iconDynamicColor={"white"}
                                                            title={step.name}
                                                            color={step.color + "1a"}
                                                            onClick={() => handleMoveCard(step)}
                                                            isLoading={loadingInsertStep}
                                                        >
                                                            <span style={{ overflow: "hidden" }}>{step.name}</span>
                                                        </ButtonActionBack>
                                                    )
                                                })}
                                            </ContainerButtonAction>
                                        </ContainerActionItems>
                                }
                            </ContainerActionButtons>
                        </ContainerAction>
                    </>
                }

                <ContainerBottom>
                    {status === 0 && (
                        <ContainerBottomDisclaimer>
                            Evite enviar informações sensíveis, como senhas ou dados confidenciais, através de formulários cuja origem não seja reconhecida. Verifique sempre a autenticidade deste formulário, assegurando-se de que foi gerado pela sua empresa ou por uma fonte confiável.
                            <a style={{ marginLeft: '5px' }} href="https://www.cange.me/contato" target="_blank" rel="noreferrer">Denunciar abuso</a>
                        </ContainerBottomDisclaimer>
                    )}

                    <ContainerBottomText href={"https://www.cange.me?utm_source=cange&utm_campaign=public-form&utm_term=" + form.company_id} rel="noreferrer" target="_blank">
                        Criado com <img src={logoCange} alt="Logo Cange" />
                    </ContainerBottomText>
                </ContainerBottom>
            </ContainerMain>

        </Container >
    );

}

export default PublicForm;