import styled, { css } from "styled-components";

interface TaskProps {
  isDone: boolean;
}

interface TaskTagProps {
  color: string;
}

interface TaskAlertRightProps {
  color?: string;
}

interface TaskAlertProps {
  isFilled?: boolean;
  color: string;
}

interface RegisterDataProps {
  color?: string;
}

export const Container = styled.div`
  :hover {
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    border-radius: 10px;

    
  }
`;

export const TaskContainer = styled.div`
  padding: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #9e37ed1a;
  border-radius: 5px 5px 0px 0px;
  color: black;
  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;

export const TaskHeader = styled.div`
  display: flex;
  width: 100%;  
  margin-top: 5px;
`;

export const TaskHeaderLeft = styled.div`
  display: flex;
  overflow-x: hidden;
  justify-content: start;
  align-content: start;
  margin-bottom: 5px;

`;

export const TaskTag = styled.span<TaskTagProps>`
    display: flex;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    padding: 3px;
    font-size: 11px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 5px;
    background-color: #f23b5c;
    color: white;
    min-width: 40px;
    min-height: 20px;
    font-weight: 500;
    margin-right: 5px;

    ${(props) => props.color && css`
      background-color: ${props.color} !important;
    `}


`;

export const TaskHeaderRight = styled.div`
  width: 10%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;

  svg {
    font-size: 18px;
  }
`;

export const TaskTitle = styled.div`
  padding: 5px;
  padding-left: 0px;
  padding-bottom: 0px;

  h1 {
    font-size: 16px;
    font-weight: 500;
  }
`;

export const TaskBody = styled.div`
  margin-bottom: 10px;
  //margin-left: 10px;
`;

export const TaskBodyItem = styled.div`
  margin-top: 10px;
`;

export const TaskBodyItemHead = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 3px;

  svg {
    min-width: 13px;
    font-size: 13px;
    font-weight: 600;
    color: #84939f;
  }

  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    font-size: 12px;
    color: #84939f;
    font-weight: 500;
    margin-top: 2px;
    text-transform: uppercase;
  }
`;

export const TaskBodyItemBody = styled.div`
  margin-left: 26px;
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #4d4d4d;
  overflow: hidden;
`;

export const TaskBottom = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
`;

export const TaskBottomLeft = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const TaskBottomRight = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const TaskBottomRightItem = styled.div<TaskProps>`
  display: flex;
  font-size: 13px;
  font-weight: 500;
  justify-content: end;
  align-items: center;
  color: #84939f;
  padding: 5px 5px;

  ${(props) => props.isDone && css`
    background-color: #60bd4f;
    color: white;
    border-radius: 5px;
  `}

  svg {
    font-size: 15px;
    margin-right: 5px;
    color: #84939f;

    ${(props) => props.isDone && css`
      color: white;
    `}
  }
`;

export const TaskAlert = styled.div<TaskAlertProps>`
  display: flex;
  padding: 5px 10px 5px 10px;
  border-radius: 0px 0px 5px 5px;
  margin-bottom: 15px;

  ${(props) =>
    props.color &&
    css`
      background: ${props.color};
    `}

  ${(props) =>
    !props.isFilled && //Se não tiver nenhum valor
    css`

      div {
        display: none;
      }

      ${Container}:hover & {
        min-height: 15px;

        div {
          display: flex;
        }
      }
  `}
    
`;

export const TaskAlertLeft = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;

  svg {
    margin-left: 10px;
    color: white;
    font-size: 15px;
  }
`;

export const TaskAlertRight = styled.div<TaskAlertRightProps>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 13px;
    color: white;
  }

  ${(props) =>
    props.color &&
    css`
      h1 {
        color: ${props.color};
      }
    `}
`;

export const LinkData = styled.div<RegisterDataProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1;
    margin: 0px 2px;
    white-space: nowrap;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 5px;
    text-decoration: inherit;
    color: #4d4d4d;

`
export const LinkDataUrl = styled.a`
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;