import React, { useEffect, useCallback, useState } from "react";

import {
    Container,
    ContainerGroup,
    GroupHeader,
    GroupName,
    GroupItems,
    GroupBody,
    DividerGroup,
    GroupLoading,
    TagOrigin
} from "./style";
import { FormAnswerField } from "../../../interfaces/FormAnswerField";
import api from "../../../services/api";
import { Card } from "../../../interfaces/Card";
import ItemRegister, { ComboItem } from "../../../components/ItemRegister";
import formAnswerToComboItemFromFlow from "../../../utils/formAnswerToComboItemFromFlow";
import ContentLoader from "react-content-loader";
import { FormAnswer } from "../../../interfaces/FormAnswer";
import formAnswerToComboItemFromRegister from "../../../utils/formAnswerToComboItemFromRegister";

interface RelationshipTabProps {
    id_card?: number;
    flow_id?: number;
    formAnswerFields?: FormAnswerField[];
}

const LoaderContainer = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={'100%'}
        viewBox="0 0 500 210"
        backgroundColor="#ffffff"
        foregroundColor="#f3f3f3"
    >
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="200" />
    </ContentLoader>
)

const RelationshipTab: React.FC<RelationshipTabProps> = ({ formAnswerFields, id_card, flow_id }) => {

    const [itemsFlow, setItemsFlow] = useState<ComboItem[]>([]);
    const [itemsRegister, setItemsRegister] = useState<ComboItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getApiRelationship = useCallback(async () => {

        if (id_card !== undefined && flow_id !== undefined) {

            setLoading(true);

            await api.get(`/card/relationship`, {
                params: {
                    id_card: id_card,
                    flow_id: flow_id,
                    withCards: "S"
                }
            }).then(response => {
                if (response.data !== null) {

                    let apiRetCard: Card[] = response.data.cards;
                    let apiRetRegister: FormAnswer[] = response.data.registerAnswers;
                    let apiRetRelationship: FormAnswerField[] = response.data.formsAnswerFields;
                    let newComboItemsFlow: ComboItem[] = [];
                    let newComboItemRegister: ComboItem[] = [];

                    if (apiRetCard !== undefined && apiRetCard.length > 0) {
                        for (let index = 0; index < apiRetCard.length; index++) {
                            const card = apiRetCard[index];

                            if (card.flow !== undefined) {
                                const newComboItem: ComboItem = formAnswerToComboItemFromFlow(card, card.flow);

                                newComboItemsFlow.push(newComboItem);
                            }
                        }
                    }

                    setItemsFlow(newComboItemsFlow);

                    if (apiRetRegister !== undefined && apiRetRegister.length > 0) {
                        for (let index = 0; index < apiRetRegister.length; index++) {
                            const fa = apiRetRegister[index];

                            const reg = apiRetRelationship.filter((f) => f.form_answer_id === fa.id_form_answer);

                            if (reg !== undefined && reg[0].form_answer?.register !== undefined) {

                                const newComboItem: ComboItem = formAnswerToComboItemFromRegister(fa, reg[0].form_answer.register);

                                newComboItemRegister.push(newComboItem);

                            }

                        }
                    }

                    setItemsRegister(newComboItemRegister);

                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });

        }

    }, [id_card, flow_id]);

    const groupedCards: { [flowName: string]: ComboItem[] } = itemsFlow.reduce(
        (acc, item) => {
            if (item.originGroup !== undefined) {
                if (acc[item.originGroup]) {
                    acc[item.originGroup].push(item);
                } else {
                    acc[item.originGroup] = [item];
                }
            }
            return acc;
        },
        {} as { [flowName: string]: ComboItem[] }
    );

    const groupedRegisters: { [registerName: string]: ComboItem[] } = itemsRegister.reduce(
        (acc, item) => {
            if (item.originGroup !== undefined) {
                if (acc[item.originGroup]) {
                    acc[item.originGroup].push(item);
                } else {
                    acc[item.originGroup] = [item];
                }
            }
            return acc;
        },
        {} as { [registerName: string]: ComboItem[] }
    );

    useEffect(() => {

        getApiRelationship();

    }, [formAnswerFields, getApiRelationship]);

    return (
        <Container>

            {loading ?
                <GroupLoading>
                    <LoaderContainer />
                    <LoaderContainer />
                </GroupLoading> :
                <>
                    {Object.entries(groupedCards).map(([flowName, itemsFlow]) => (
                        <ContainerGroup key={flowName}>
                            <GroupHeader>
                                <GroupName>
                                    {flowName}
                                    <TagOrigin>Fluxo</TagOrigin>
                                </GroupName>
                                <GroupItems>{itemsFlow.length}</GroupItems>
                            </GroupHeader>
                            <DividerGroup />
                            <GroupBody>
                                {itemsFlow.map((item) => (
                                    <ItemRegister key={item.id_register} type={'Flow'} item={item} />
                                ))}
                            </GroupBody>
                        </ContainerGroup>
                    ))}
                    {Object.entries(groupedRegisters).map(([registerName, itemsRegister]) => (
                        <ContainerGroup key={registerName}>
                            <GroupHeader>
                                <GroupName>
                                    {registerName}
                                    <TagOrigin>Cadastro</TagOrigin>
                                </GroupName>
                                <GroupItems>{itemsRegister.length}</GroupItems>
                            </GroupHeader>
                            <DividerGroup />
                            <GroupBody>
                                {itemsRegister.map((item) => (
                                    <ItemRegister key={item.id_register} type={'Register'} item={item} />
                                ))}
                            </GroupBody>
                        </ContainerGroup>
                    ))}
                </>
            }

        </Container>
    );

}

export default RelationshipTab;