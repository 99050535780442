import styled from "styled-components";

export const Content = styled.div`
    padding: 4%;
    border-radius: 10px;
    background-color: #e9f1ff;
    height: 100%;
`;

export const DisclaimerPreview = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px dashed #84939f;
    padding: 10px;
    margin: 15px;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 8px;

    h1 {
        margin-left: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #84939f;
    }

    svg {
        height: 18px;
        width: 18px;
        color: #84939f;
    }
`;