import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import styled, { css } from "styled-components";

interface IconHeaderProps {
    color: string;
}

interface FlowItemProps {
    color?: string;
}

export const Content = styled(DialogContent)`
    background-color: white;   
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: auto;
    width: auto;
    margin-top: 15px;
    margin-bottom: 20px;
`;

export const DialogTop = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #eef2f8;
    margin-top: 15px;
    width: 100%;
    
`;

export const DialogTopLeft = styled.div`
    width: 65%;
    height: 100%;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: start;
    margin-left: 15px;

    h1 {
        margin-left: 20px;
        font-size: 20px;
        font-weight: 500;
        color: black;
    }
`;

export const DialogTopCenter = styled.div`
    width: 25%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
`;

export const DialogTopRight = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 30px;
        border: none;
        background-color: transparent;
    }
`;

export const IconHeader = styled.span<IconHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 100%;
    background-color: transparent;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 45px;
        height: 45px;
        background-color: #f4f4f4;
        border-radius: 50%;

        > svg {
            width: 25px;
            height: 25px;

            ${props => props.color && css`
                color: ${props.color} !important;
            `}
        }

    }
`;

export const ContainerButtons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ContainerButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    padding: 10px;
    border-radius: 10px;    
    color: #4d4d4d;

    width: 180px;
    height: 185px;

    transition: transform 0.2s ease; 

    :hover {
        transform: scale(1.03);
        cursor: pointer;
        color: #f23b5c;
        border-color: #f23b5c;
    }
`;

export const ContainerButtonIcon = styled.div`
    padding: 10px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #8080805c;
    padding: 30px;
    margin-bottom: 10px;

    div {
        margin-bottom: 0px;
    }

    svg {
        color: #f23b5c;
        height: 40px;
        width: 40px;
    }
`;

export const ContainerButtonTitle = styled.div`
    font-weight: 500;
    color: black;
    font-size: 15px;
    letter-spacing: 0.2px;
    text-align: center;
`;

export const ContainerButtonDescription = styled.div`
    font-size: 13px;
    margin-top: 5px;
    text-align: center;
`;

export const FlowContainer = styled.div`
    //display: flex;
    align-items: center;

    height: auto;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding-left: 15px;

    overflow-x: auto;

    display: grid;
    --min-col-width: var(--grid-min-column-width,160px);
    --max-col-width: var(--grid-max-column-width,1fr);
    grid-template-columns: repeat(auto-fill,minmax(var(--min-col-width),var(--max-col-width)));
    grid-gap: var(--gap);
    
`;


export const FlowItem = styled.div<FlowItemProps>`
    display: inline-flex;
    align-items: left;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    grid-column: span 1 / auto;
    cursor: pointer;

    margin: 15px;
    margin-left: 0px;
    height: 190px;
    width: 150px;
    min-width: 145px;
    background-color: #f23b5c1a;
    border-radius: 10px;
    border: 1px dashed transparent;
    padding: 15px;

    &:hover {
        background: ${'#f23b5c'};
        transform: translateY(-10px);
        
        h2 {
            color: ${"white"};
        }

        span {
            color: ${"white"};
        }

        svg {
            color: white;
        }

        .flow-star {
            display: flex;
        }

        .flow-config {
            display: flex;
        }
    } 

    h2 {
        font-size: 14px;
        color: black;
    }

    ${props => props.color && css`
        background-color: ${props.color+"1a"};
        
        &:hover {
            background: ${props.color};
        }
    `}
`;


export const FlowHeader = styled.div`
    display: flex;
    width: 100%;
    min-height: 15px;
`;

export const FlowHeaderLeft = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

`;

export const FlowHeaderCenter = styled.div`
    
    display: none;
    justify-content: flex-start;
    align-items: center;

    svg {
        font-size: 17px;
    }

`;

export const FlowHeaderRigth = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const FlowItemContainer = styled.div<FlowItemProps>`
    display: inline-flex;
    -webkit-box-align: center;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;

    width: 70px;
    height: 70px;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 10%;

    border-radius: 10px;

    svg {
        color: #f23b5c;
        height: 40px;
        width: 40px;
    }

    ${props => props.color && css`
        svg {
            color: ${props.color};
            
        }
    `}

`;

export const FlowContainerBottom = styled.div`
    height: 100%;
    width: 100%;

    display: contents;
    justify-content: center;
    align-items: center;

    white-space: normal;
    overflow-wrap: anywhere;

    text-align: center;

    span {
        margin-top: 10px;
        color: grey;
        font-size: 12px;
    }

`;