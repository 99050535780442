import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IconList, IconPickerItem } from "react-fa-icon-picker";

import ElementConfig from "./ElementConfig";
import ElementStore from "./ElementStore";
import {
    Content,
    Dialog,
    DialogTop,
    DialogTopCenter,
    DialogTopLeft,
    DialogTopRight,
    IconHeader
} from "./style";
import { ElementProps, getElementObject } from "../../components/ElementBuilder";
import { Flow } from "../../interfaces/Flow";

interface NewElementProps {
    flow: Flow;
    open: boolean;
    isTestMode: boolean;
    element?: ElementProps;
    onClose: () => void;
    onAddNewElement: (element: ElementProps) => void;
}

const NewElement: React.FC<NewElementProps> = ({ flow, open, isTestMode, element, onClose, onAddNewElement }) => {

    const [elementSelected, setElementSelected] = useState<ElementProps | undefined>(element !== undefined ? element : undefined);
    const [isEditing, setIsEditing] = useState(false);

    const onSelectElement = useCallback(async (element: string) => {

        //Create the element hash
        const dateNowTime = new Date().getTime();
        var hashLib = require('object-hash');

        const hash = await hashLib(dateNowTime + element + flow.id_flow);

        setElementSelected({
            hash: hash,
            type: element,
            width: getElementObject(element).defW,
            height: getElementObject(element).defH
        });

    }, [flow.id_flow]);

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    useEffect(() => {

        if (element !== undefined) {
            setElementSelected(element);
            setIsEditing(true);
        } else {
            setElementSelected(undefined);
            setIsEditing(false);
        }

    }, [element]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={open}
            onClose={handleClose}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader>
                        <div>
                            <IconPickerItem
                                icon={"FaChartArea" as IconList}
                                color={"#f23b5c"}
                            />
                        </div>
                    </IconHeader>
                    <h1>
                        Adicionar elemento
                    </h1>
                </DialogTopLeft>


                <DialogTopCenter>
                </DialogTopCenter>


                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose></AiOutlineClose></button>
                </DialogTopRight>
            </DialogTop>

            <Content>
                {elementSelected === undefined ?
                    <ElementStore onSelectElement={onSelectElement} /> :
                    <ElementConfig
                        flow={flow}
                        element={elementSelected}
                        isTestMode={isTestMode}
                        onUpdateElement={onAddNewElement}
                        onAddNewElement={onAddNewElement}
                        onBackToStore={() => setElementSelected(undefined)}
                        onClose={handleClose}
                        isEditing={isEditing}
                    />
                }
            </Content>
        </Dialog>
    );

}

export default NewElement;