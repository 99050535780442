import styled, { css } from "styled-components";

interface StepItemProps {
    selectedIndex?: number;
    index?: number;
    color?: string;
}

export const Container = styled.div<StepItemProps>`
    display: inline-flex;
    align-items: left;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin: 15px;
    height: auto;
    width: 140px;
    min-width: 120px;
    min-height: 100px;
    background-color: transparent;
    border-width: 2px;
    border-style: solid;
    border-color: #f23b5c;
    border-radius: 10px;
    padding: 10px;
    color: #f23b5c;

    &:hover {
        border-style: 'solid';
        border-width: '2px';
        border-color: '#f23b5c';
        background-color: #f23b5c1a;
        
        h2 {
            color: "black";
        }
    }

    h2 {
        font-size: 14px;
        color: #4d4d4d;
    }

    ${props => props.index !== undefined && props.selectedIndex === props.index && css`
        border-style: ${'solid'};
        border-width: ${'2px'};
        border-color: ${'#f23b5c'};
        background-color: ${'#f23b5c1a'};

        h2 {
            color: ${"black"};
        }
    `}

    ${props => props.color && css`
        border-color: ${props.color};
        color: ${props.color};
        
        &:hover {
            border-color: ${props.color};
            background-color: ${props.color + '1a'};
        }

    `}

    // Caso esteja selecionado e possua uma cor
    ${props => props.index !== undefined && props.selectedIndex === props.index && props.color && css`
        border-color: ${props.color};
        //background-color: ${props.color + '1a'};
        background-color: ${props.color};

        svg {
            color: white;
        }

        h2 {
            color: white;
        }

        &:hover {
            border-color: ${props.color + 'd1'};
            background-color: ${props.color + 'd1'};
        }
    `}
    
`;


export const StepContainerTop = styled.div`
    width: 30px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        //margin-top: 10px;
    
        width: 30px;
        height: 30px;
    }
`;

export const StepContainerBottom = styled.div`
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    white-space: normal;
    overflow-wrap: anywhere;
    text-align: center;

    margin-top: 10px;
`;