import styled, { css } from "styled-components";

interface ButtonLinkProps {
    active?: boolean;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  background-color: white;
  height: 40px;
  border-radius: 0px 0px 10px 10px;
  padding-left: 15px;
  padding-right: 5px;
  border-top: 0.5px solid #eef2f8;
  border-bottom: 0.5px solid #eef2f8;
`;

export const FilterBarLeft = styled.div`
  float: left;
  display: flex;
  height: 100%;
  justify-content: left;
  align-items: center;
  width: 100%;  
`;

export const FilterBarRight = styled.div`
  position: absolute;
  right: 5px;
  top: 6px;
  display: flex;
  justify-content: right;
  align-items: center;
`;

export const ButtonActive = styled.button<ButtonLinkProps>`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;
    background-color: #61bd4f1e;        
    border-radius: 5px;

    svg {
        font-size: 16px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        opacity: 0.9;
    }

    ${props => props.active && css`
        background-color: #60bd4e;
        color: white;
        border-radius: 5px;   
        font-weight: 500;
        
        :hover {
            background-color: #57aa47;
        }
    `}

    @media(max-width: 800px){ 
        svg {
            margin-right: 0px;
        }

        div {
            display: none;            
        }
    }
    
`;

export const ButtonLink = styled.button<ButtonLinkProps>`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;

    svg {
        font-size: 13px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;

        .delete-icon {
            color: red;
        }
    }

    ${props => props.active && css`
        background-color: #60bd4e;
        color: white;
        border-radius: 5px;   
        font-weight: 500;
        
        :hover {
            background-color: #57aa47;
        }
    `}

    @media(max-width: 800px){ 
        svg {
            margin-right: 0px;
        }

        div {
            display: none;            
        }
    }
    
`;

export const LastRunContainer = styled.div`
    font-size: 13px;
    color: #525252;
    font-weight: 400;
    letter-spacing: 0.1px;
    margin-left: 5px;
`;