import React, { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { Dialog } from '@material-ui/core';
import { FaCheck, FaPen, FaPlus, FaTag } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import { FormHandles } from "@unform/core";
import { HiOutlineLightBulb } from "react-icons/hi";
import { MdKeyboardArrowLeft } from "react-icons/md";

import Button from "../../components/Button";
import DialogAction from "../../dialogs/DialogAction";
import FormBuilder from "../../components/Forms/FormBuilder";
import api from '../../services/api';
import {
    BtnInsertNewAnswer,
    ButtonAddNewLabel,
    ContainerItems,
    Content,
    ContentBody,
    DialogFooter,
    DialogFooterCenter,
    DialogTop,
    DialogTopCenter,
    DialogTopLeft,
    DialogTopRight,
    FormContainer,
    FormContainerAdd,
    FormContainerButtons,
    FormHeader,
    HelpContainer,
    HelpIcon,
    HelpItem,
    HelpItems,
    HelpText,
    IconHeader,
    TagContainer,
    TagEdit,
    TagItem,
    TagLeft,
    TagRight
} from "./styles";
import { Card } from "../../interfaces/Card";
import {
    FieldProps,
    Fields
} from "../../components/Forms/Fields/FieldBuilder";
import { FlowTag } from "../../interfaces/FlowTag";
import { useToast } from '../../hooks/toast';

export interface ConfigCardProps {
    open: boolean;
    selectedValue: Card | undefined;
    onClose: (newCard?: Card) => void;
}

const colors: string[] = [
    "#f23b5c",
    "#9e37ed",
    "#4680B8",
    "#61bd4f",
    "#ff8c2f",
    "#795548"
];

const fieldsNewLabel: FieldProps[] = [
    {
        name: "name_label",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 1,
        title: "Nome da Etiqueta",
        description: "Digite o nome da Etiqueta",
        placeholder: "Digite aqui...",
        help_text: "Neste campo é ideal que você escolha um nome para sua etiqueta, para que ela ajude no agrupamento dos cards do seu fluxo",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome do Fluxo é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ],
        max_length: 15
    },
    {
        name: "color_label",
        type: Fields.COLOR_PICKER_FIELD,
        required: true,
        index: 2,
        title: "Cor da Etiqueta",
        description: "Escolha uma cor para a sua Etiqueta",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["Deve ser escolhido uma cor para a sua Etiqueta!"]
            }
        ]
    }
]

interface labelProps {
    name_label: string;
    color_label: string;
    id_flow_tag?: number;
}

const labelDefault: labelProps = {
    name_label: "",
    color_label: "#f23b5c"
}

const AddLabel: React.FC<ConfigCardProps> = ({ onClose, open, selectedValue }) => {

    const formRef = useRef<FormHandles>(null);

    const { addToast } = useToast();
    const [tags, setTags] = useState<FlowTag[]>();
    const [onForm, setOnForm] = useState<boolean>(false);
    const [currLabel, setCurrLabel] = useState<labelProps>(labelDefault);
    const [stateDelLabel, setStateDelLabel] = useState(false);

    const handleSetLabel = useCallback(async (tag: FlowTag) => {

        let newTag = tag;

        if (newTag.id_flow_tag === undefined) { //Precisa inserir um novo item 

            await api
                .post('/flow-tag', {
                    flow_id: selectedValue?.flow_id,
                    description: tag.description,
                    color: tag.color
                })
                .then(response => {

                    if (response.data !== undefined) {
                        newTag = response.data as FlowTag;
                    }

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao inserir Etiqueta',
                        description: 'Ocorreu um erro ao inserir etiqueta na base de dados!',
                    });
                });

        }

        if (newTag.id_flow_tag !== undefined && newTag.id_flow_tag > 0) {

            //Insere o vínculo
            if (newTag.isActive) { //Remove o vínculo
                await api
                    .delete('/flow-tag/card?card_id=' + selectedValue?.id_card + '&flow_tag_id=' + tag.id_flow_tag + '&flow_id=' + selectedValue?.flow_id)
                    .then(response => {

                        setTags(tags?.map((tag) => {
                            if (tag.id_flow_tag === newTag.id_flow_tag) {
                                tag.isActive = false;
                            }
                            return tag;
                        }));

                    }).catch(error => {
                        addToast({
                            type: 'error',
                            title: 'Erro ao inserir Etiqueta',
                            description: 'Ocorreu um erro ao inserir etiqueta na base de dados!',
                        });
                    });

            } else { //Adiciona o vínculo

                await api
                    .post('/flow-tag/card', {
                        flow_id: selectedValue?.flow_id,
                        card_id: selectedValue?.id_card,
                        flow_tag_id: newTag.id_flow_tag
                    })
                    .then(response => {

                        setTags(tags?.map((tag) => {
                            if (tag.id_flow_tag === newTag.id_flow_tag) {
                                tag.isActive = true;
                            }
                            return tag;
                        }));

                    }).catch(error => {
                        addToast({
                            type: 'error',
                            title: 'Erro ao inserir Etiqueta',
                            description: 'Ocorreu um erro ao inserir etiqueta na base de dados!',
                        });
                    });

            }
        }

    }, [addToast, selectedValue, tags]);

    const dialogDelLabelSubmmit = useCallback(async () => {

        if (currLabel !== undefined && onForm && selectedValue !== undefined) {
            await api
                .delete('/flow-tag?flow_id=' + selectedValue.flow_id + '&id_flow_tag=' + currLabel.id_flow_tag)
                .then(response => {

                    setOnForm(false);
                    setCurrLabel(labelDefault);
                    setStateDelLabel(false);

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao deletar a Etiqueta',
                        description: 'Ocorreu um erro ao deletar a Etiqueta!',
                    });
                });
        }

    }, [addToast, currLabel, onForm, selectedValue]);

    const handleEditForm = useCallback(async (tag: FlowTag) => {

        setOnForm(true);

        const labelForm: labelProps = {
            id_flow_tag: tag.id_flow_tag,
            name_label: tag.description,
            color_label: tag.color
        }

        setCurrLabel(labelForm);

    }, []);

    const handleSubmitForm = useCallback(async (data: object[]) => {

        if (formRef.current !== null && selectedValue !== undefined) {

            const dataNormalized = formRef.current.getData() as unknown as labelProps;

            await api
                .post('/flow-tag', {
                    flow_id: selectedValue?.flow_id,
                    id_flow_tag: currLabel.id_flow_tag,
                    description: dataNormalized.name_label,
                    color: dataNormalized.color_label
                })
                .then(response => {

                    setCurrLabel(labelDefault);
                    setOnForm(false);

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao inserir Etiqueta',
                        description: 'Ocorreu um erro ao inserir etiqueta na base de dados!',
                    });
                });

        }


    }, [addToast, selectedValue, currLabel.id_flow_tag]);

    const handleOpenFormLabel = useCallback(async () => {
        setCurrLabel(labelDefault);
        setOnForm(true);
    }, []);

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    const handleSave = useCallback(async () => {

        if (!onForm) {

            handleClose();

        } else {

            if (formRef.current !== undefined) {
                formRef.current?.submitForm();
            }

        }

    }, [handleClose, onForm]);

    const insertDefaultTags = useCallback((tagsApi: FlowTag[]) => {

        let auxColors = colors;

        //Remove as cores já existentes
        if (tagsApi !== undefined) {
            for (let index = 0; index < tagsApi.length; index++) {
                for (let idxB = 0; idxB < colors.length; idxB++) {

                    if (tagsApi[index].color === colors[idxB]) {
                        auxColors = auxColors.filter((color) => color !== tagsApi[index].color);
                    }

                }

            }
        }

        //Insere as cores que não possuem ainda
        if (auxColors !== undefined && auxColors.length > 1 && selectedValue !== undefined) {

            let auxTags = tagsApi !== undefined ? tagsApi : [] as FlowTag[];

            for (let index = 0; index < auxColors.length; index++) {

                const flowTagNew: FlowTag = {
                    flow_id: selectedValue?.flow_id,
                    description: "",
                    color: auxColors[index]
                }

                auxTags.push(flowTagNew);

            }

            return auxTags;

        }

        return tagsApi;

    }, [selectedValue]);

    useEffect(() => {

        let isMounted = true;

        if (isMounted && open) {
            if (formRef.current !== null) {
                formRef.current.setData(currLabel);
            }
        }

        return () => {
            isMounted = false;
        };

    }, [currLabel, open]);

    useEffect(() => {

        let isMounted = true;

        if (isMounted && open) {
            if (selectedValue !== undefined) {

                api.get(`/flow-tag/by-flow`, {
                    params: {
                        flow_id: selectedValue.flow_id
                    }
                }).then(response => {
                    if (response.data !== null) {

                        let respStep: FlowTag[] = response.data;
                        let currTag;

                        for (let index = 0; index < respStep.length; index++) {
                            currTag = selectedValue.card_flow_tags?.filter((tag) => tag.flow_tag_id === respStep[index].id_flow_tag)[0];
                            if (currTag !== undefined) {
                                respStep[index].isActive = true;
                            } else {
                                respStep[index].isActive = false;
                            }
                        }

                        if (respStep !== undefined) {

                            const newSteps = insertDefaultTags(respStep);

                            setTags(newSteps);

                        }
                    }
                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao buscar as Etiquetas',
                        description: 'Ocorreu um erro ao tentar buscar as Etiquetas!',
                    });

                });

            }
        }

        return () => {
            isMounted = false;
        };

    }, [selectedValue, addToast, insertDefaultTags, onForm, open]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={"gray"}>
                        <div>
                            <FaTag />
                        </div>
                    </IconHeader>
                    <h1>
                        {"Etiquetas"}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose></AiOutlineClose></button>
                </DialogTopRight>
            </DialogTop>

            <Content>
                <ContentBody container>

                    {!onForm ? //Normal
                        <>
                            <HelpContainer>
                                <HelpItems>
                                    <HelpItem>
                                        <HelpIcon>
                                            <HiOutlineLightBulb />
                                        </HelpIcon>
                                        <HelpText>
                                            {"Escolha ou crie uma ou mais etiquetas para deixar vinculado ao seu cartão. As etiquetas auxiliam no agrupamento dos cartões ;)"}
                                        </HelpText>
                                    </HelpItem>
                                </HelpItems>

                            </HelpContainer>

                            <ContainerItems>
                                {tags?.map((tag) => {
                                    return (
                                        <TagContainer key={tag.id_flow_tag + tag.color}>
                                            <TagItem color={tag.color} onClick={() => handleSetLabel(tag)}>
                                                <TagLeft>
                                                    <h3>{tag.description}</h3>
                                                </TagLeft>
                                                <TagRight>
                                                    {tag.isActive === true ?
                                                        <FaCheck /> :
                                                        <></>
                                                    }
                                                </TagRight>
                                            </TagItem>
                                            <TagEdit onClick={() => handleEditForm(tag)}>
                                                <FaPen />
                                            </TagEdit>
                                        </TagContainer>

                                    );
                                })}
                            </ContainerItems>
                            <ButtonAddNewLabel icon={FaPlus} onClick={() => handleOpenFormLabel()}>para criar uma nova Etiqueta</ButtonAddNewLabel>
                        </> :

                        <FormContainer>
                            <FormHeader onClick={() => setOnForm(!onForm)}>
                                <MdKeyboardArrowLeft />
                                <h3>Voltar</h3>
                            </FormHeader>
                            <FormContainerAdd>
                                <FormBuilder
                                    id="formAddLabel"
                                    formRef={formRef}
                                    fields={fieldsNewLabel}
                                    handleSubmit={handleSubmitForm}
                                    hideContainer={true}
                                />
                            </FormContainerAdd>
                            {currLabel.id_flow_tag !== undefined && currLabel.id_flow_tag > 0 ?
                                <FormContainerButtons>
                                    <Button onClick={() => setStateDelLabel(!stateDelLabel)} icon={FiTrash2} type="button" color="#e24d4b" height="36px" margin="0px 0px 0px 0px">Excluir</Button>
                                    <DialogAction
                                        open={stateDelLabel}
                                        message={"Você tem certeza que deseja excluir esta Etiqueta?"}
                                        actions_items={[
                                            "Exclusão do etiqueta em todos os cartões;",
                                            "Exclusão de todas as informações da etiqueta;"
                                        ]}
                                        disclaimer={"Lembrando que após a confirmação não será mais possível recuperar os dados referentes a Etiqueta!"}
                                        onSubmmit={dialogDelLabelSubmmit}
                                        onClose={() => setStateDelLabel(!stateDelLabel)}
                                        type={1}
                                    />
                                </FormContainerButtons> :
                                <></>
                            }
                        </FormContainer>

                    }



                </ContentBody>
                {onForm ?
                    <DialogFooter>
                        <DialogFooterCenter>
                            <BtnInsertNewAnswer color={onForm ? "#61bd4f" : "#f23b5c"} icon={BiSave} onClick={() => handleSave()}>
                                Salvar
                            </BtnInsertNewAnswer>
                        </DialogFooterCenter>
                    </DialogFooter> :
                    <></>
                }

            </Content>
        </Dialog >
    );
};

export default AddLabel;