import styled, { css } from "styled-components";

export const Container = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 10px;
`;

export const Content = styled.h1`
    font-size: calc(45px - 20%);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: black;

    ${(props) => props.color && css`
        color: ${props.color};
    `}
`;

export const ContentLabel = styled.span`
    font-size: calc(22px - 20%);
    margin-left: 0px;
    color: gray;
    font-style: italic;
    margin-right: 5px;
`;
