import React, { useCallback, useEffect, useState } from 'react';
import { IconList, IconPickerItem } from 'react-fa-icon-picker'
import { IoMdListBox } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

import DegradeLine from '../../../components/DegradeLine';
import api from '../../../services/api';
import logo from '../../../assets/wendata-logo.png';
import {
    AnimationContainer,
    Body,
    Bottom,
    BottomLeft,
    BottomRight,
    BtnStart,
    Container,
    ContainerGroup,
    Content,
    ContentList,
    GroupTag,
    GroupTagContainer,
    GroupTagIcon,
    GroupTagName,
    Header,
    HeaderLeft,
    HeaderRight,
    ProgressBar,
    BlankContainer,
    ProgressBarPercent
} from './styles';
import { Onboarding } from '../../../interfaces/Onboarding';
import { useToast } from '../../../hooks/toast';
import { useMediaQuery } from 'react-responsive';

interface RouteParams {
    hash?: string;
}

interface SelectOneItem {
    id: number;
    name: string;
    icon: IconList;
    color: string;
    itemsGroup?: SelectOneItem[];
}

const pourposeItems: SelectOneItem[] = [
    {
        id: 1,
        name: "Profissional",
        icon: "FaSuitcase",
        color: "#00bcd4",
        itemsGroup: [
            {
                id: 5,
                name: "Proprietário de negócio",
                icon: "FaRegCircle",
                color: "#00bcd4"
            },
            {
                id: 6,
                name: "Líder de equipe",
                icon: "FaRegCircle",
                color: "#00bcd4"
            },
            {
                id: 7,
                name: "Membro de equipe",
                icon: "FaRegCircle",
                color: "#00bcd4"
            },
            {
                id: 8,
                name: "Freelancer",
                icon: "FaRegCircle",
                color: "#00bcd4"
            },
            {
                id: 9,
                name: "Diretor/Gerente",
                icon: "FaRegCircle",
                color: "#00bcd4"
            },
            {
                id: 10,
                name: "Executivo",
                icon: "FaRegCircle",
                color: "#00bcd4"
            },
        ]
    },
    {
        id: 2,
        name: "Pessoal",
        icon: "FaRegUser",
        color: "#e91e63"
    },
    {
        id: 3,
        name: "Educacional",
        icon: "FaBook",
        color: "#ff9800"
    },
    {
        id: 4,
        name: "Sem fins lucrativos",
        icon: "FaHandsHelping",
        color: "#673ab7"
    },
]

const PurpouseData: React.FC = () => {

    const { params } = useRouteMatch<RouteParams>();
    const { addToast } = useToast();
    const history = useHistory();
    const [onboarding, setOnboarding] = useState<Onboarding>();
    const [selectedPurpouse, setSelectedPurpouse] = useState<SelectOneItem>();
    const [selectedPosition, setSelectedPosition] = useState<SelectOneItem>();
    const [loading, setLoading] = useState<boolean>(false);
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    const handleSubmitPage = useCallback(async () => {

        if (selectedPurpouse !== undefined) {
            setLoading(true);
            await api
                .post('/onboarding/', {
                    status: "3-1",
                    email: onboarding?.email,
                    hash: params.hash,
                    purpouse: selectedPurpouse !== undefined ? selectedPurpouse.name : '',
                    company_position: selectedPosition !== undefined ? selectedPosition.name : ''
                })
                .then(response => {

                    setLoading(false);
                    history.push('/onboarding-step-3/' + params.hash);


                }).catch(error => {
                    setLoading(false);
                    console.log(error);
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar os dados!',
                        description: 'Ocorreu um erro ao tentar salvar os dados na base!',
                    });
                });

        } else {
            addToast({
                type: 'error',
                title: 'Não foi selecionada nenhuma resposta :(',
                description: 'Selecione alguma das respostas acima!',
            });
        }

    }, [addToast, selectedPurpouse, selectedPosition, onboarding, params.hash, history]);

    useEffect(() => {

        api.get(`/onboarding`, {
            params: {
                hash: params.hash
            }
        }).then(response => {

            const apiOnboarding: Onboarding = response.data;

            setOnboarding(apiOnboarding);

        }).catch(error => {
            history.push('/onboarding/');
            addToast({
                type: 'error',
                title: 'Não foi encontrado o seu onboarding',
                description: 'Não foi possível localizar o seu onboarding!',
            });

        });


    }, [params.hash, addToast, history]);

    return (
        <Container>
            <title>Onboarding | Cange</title>
            <Content>
                <AnimationContainer>
                    <Header>
                        {!isMobile && (
                            <HeaderLeft>
                                <h1>Conte um pouco sobre você</h1>
                            </HeaderLeft>
                        )}
                        <HeaderRight>
                            <a href='https://www.cange.me'>
                                <img className='logo-cange' src={logo} alt="Logo wendata" />
                                <img className='logo-mini-cange' src={logo} alt="Logo wendata" />
                            </a>
                        </HeaderRight>
                    </Header>
                    <DegradeLine />
                    <Body>
                        <ContentList>
                            <h2>O que te traz aqui hoje?</h2>
                            <ContainerGroup>
                                {pourposeItems !== undefined ? pourposeItems?.map((item) => (
                                    <GroupTag
                                        onClick={() => {
                                            setSelectedPurpouse(item);
                                            setSelectedPosition(undefined);
                                        }}
                                        isSelected={item === selectedPurpouse}
                                        key={item.id}
                                        color={item.color}
                                    >
                                        <GroupTagContainer isSelected={item === selectedPurpouse}>
                                            <GroupTagIcon>
                                                {item !== undefined && item.icon !== undefined ?
                                                    <IconPickerItem
                                                        icon={item.icon as IconList}
                                                        color={item.color}
                                                    /> :
                                                    <IoMdListBox />
                                                }
                                            </GroupTagIcon>
                                            <GroupTagName>
                                                {item.name}
                                            </GroupTagName>
                                        </GroupTagContainer>
                                    </GroupTag>
                                )) : <></>}
                            </ContainerGroup>
                        </ContentList>

                        {selectedPurpouse !== undefined && selectedPurpouse.id === 1 ? // Is selected and is professional
                            <>
                                <div style={{ marginBottom: '30px' }} />
                                <ContentList>
                                    <h2>O que melhor descreve sua posição atual?</h2>
                                    <ContainerGroup>
                                        {selectedPurpouse !== undefined && selectedPurpouse.itemsGroup !== undefined && selectedPurpouse.itemsGroup.length > 0 ? selectedPurpouse.itemsGroup?.map((item) => (
                                            <GroupTag
                                                onClick={() => setSelectedPosition(item)}
                                                isSelected={item === selectedPosition}
                                                key={item.id}
                                                color={item.color}
                                            >
                                                <GroupTagContainer isSelected={item === selectedPosition}>
                                                    <GroupTagIcon>
                                                        {item === selectedPosition ?
                                                            <IconPickerItem
                                                                icon={"FaRegDotCircle" as IconList}
                                                                color={item.color}
                                                            /> :
                                                            item !== undefined && item.icon !== undefined ?
                                                                <IconPickerItem
                                                                    icon={item.icon as IconList}
                                                                    color={item.color}
                                                                /> :
                                                                <IoMdListBox />
                                                        }
                                                    </GroupTagIcon>
                                                    <GroupTagName>
                                                        {item.name}
                                                    </GroupTagName>
                                                </GroupTagContainer>
                                            </GroupTag>
                                        )) : <></>}
                                    </ContainerGroup>
                                </ContentList>
                            </> :
                            <BlankContainer></BlankContainer>
                        }

                    </Body>
                    <Bottom>
                        <BottomLeft>
                            <ProgressBar>
                                <ProgressBarPercent percent={50} />
                            </ProgressBar>
                        </BottomLeft>
                        <BottomRight>
                            <BtnStart type="submit" onClick={handleSubmitPage} isLoading={loading}>Continuar</BtnStart>
                        </BottomRight>
                    </Bottom>
                </AnimationContainer>
            </Content>
        </Container>
    );
}

export default PurpouseData;
