import React, { useCallback, useRef } from "react";

import {
    BoxOver,
    Container
} from "./style";
import { ClickAwayListener } from "@material-ui/core";
import { FieldProps, Fields } from "../Forms/Fields/FieldBuilder";
import { FormHandles } from "@unform/core";
import FormBuilder from "../Forms/FormBuilder";

interface IconColorPickerFormProps {
    color: string;
    icon: string;
}

const fields: FieldProps[] = [
    {
        name: "color",
        type: Fields.COLOR_PICKER_FIELD,
        required: false,
        index: 0,
        variation: "3",
        title: "Cor",
        description: "Escolha uma cor"
    },
    {
        name: "icon",
        type: Fields.ICON_PICKER_FIELD,
        required: false,
        index: 1,
        title: "Ícone do fluxo",
        description: "Escolha um ícone"
    }
]

interface IconColorPickerProps {
    open: boolean;
    icon?: string;
    color?: string;
    index?: number;
    top?: number;
    left?: number;
    handleClose: () => void;
    setColor: React.Dispatch<React.SetStateAction<string>>;
    setIcon: React.Dispatch<React.SetStateAction<string>>;
}

const IconColorPicker: React.FC<IconColorPickerProps> = ({ open, icon, color, index, top, left, handleClose, setColor, setIcon }) => {

    const formRef = useRef<FormHandles>(null);

    const handleClickAway = () => {
        handleClose();
    };

    const handleSubmitForm = useCallback(async (data: object[]) => {

        const { color, icon } = data as unknown as IconColorPickerFormProps;

        if (color !== undefined && color !== null && color !== "") {
            setColor(color);
        }

        if (icon !== undefined && icon !== null && icon !== "") {
            setIcon(icon);
        }

    }, [setColor, setIcon]);

    const handleSubmitPage = useCallback(async () => {
        if (formRef.current !== undefined) {
            formRef.current?.submitForm();
        }
    }, []);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <BoxOver>
                {open && (
                    <Container index={index} top={top} left={left}>
                        <FormBuilder
                            id="icon-color-picker-form"
                            formRef={formRef}
                            fields={fields}
                            handleSubmit={handleSubmitForm}
                            handleBlur={handleSubmitPage}
                            hideContainer={true}
                            initialValue={{
                                color: color,
                                icon: icon
                            }}
                        />
                    </Container>
                )}
            </BoxOver>
        </ClickAwayListener>
    );
}

export default IconColorPicker;