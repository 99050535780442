import {
    BtnGoToPage,
    ButtonUserAdd,
    ContainerBody,
    ContainerContent,
    ContainerHeader,
    ContainerHeaderRight,
    FlowConfig,
    FlowContainer,
    FlowContainerBottom,
    FlowHeader,
    FlowHeaderCenter,
    FlowHeaderLeft,
    FlowHeaderRigth,
    FlowItem,
    FlowItemContainer,
    FlowNewContainerBottom,
    FlowNewItem,
    FlowNewItemContainer,
    FlowStar
} from "./style";
import { IconList, IconPickerItem } from 'react-fa-icon-picker'
import React, { CSSProperties, useCallback, useEffect, useState } from "react";

import { AiFillLock } from "react-icons/ai";
import { BsGearFill } from "react-icons/bs";
import ContentLoader from "react-content-loader"
import Description from "../../components/Description";
import { FaStar } from "react-icons/fa";
import InviteUser from "../../dialogs/InviteUser";
import { IoMdPersonAdd } from "react-icons/io";
import LayoutContainer from '../../components/LayoutContainer';
import ListContainer from '../../components/ListContainer';
import MyTemplate from "../../components/Widget/MyTemplates";
import PageHeader from '../../components/PageHeader';
import StatsUser from "../../components/Stats/StatsUser";
import { TiFlowMerge } from 'react-icons/ti';
import Title from "../../components/Title";
import api from '../../services/api';
import getAccessControl from "../../middlewares/AccessControl";
import { useAuth } from "../../hooks/auth";
import { Link, useHistory } from 'react-router-dom';
import { useToast } from '../../hooks/toast';

interface FlowProps {
    id_flow?: number;
    hash: string;
    name_flow: string;
    color_flow: string;
    icon_flow: string;
    isPrivate?: string;
    favorite?: string;
    total_cards?: number;
    isStared?: string;
    typeUserAccess?: string;
}

interface FlowApiProps {
    id_flow?: number;
    hash: string;
    name: string;
    color: string;
    icon: string;
    isPrivate?: string;
    favorite?: string;
    total_cards: number;
    isStared?: string;
    typeUserAccess?: string;
}

const flowDefault: FlowProps = {
    hash: "",
    color_flow: "#795548",
    icon_flow: "FaWrench",
    name_flow: "Rascunho",
    isStared: "N"
}

function compare(a: FlowProps, b: FlowProps) {
    if (a.isStared !== undefined && b.isStared !== undefined) {
        if (a.isStared > b.isStared) {
            return -1;
        }
        if (a.isStared < b.isStared) {
            return 1;
        }
    }
    return 0;
}

const LoaderCard = () => (
    <ContentLoader
        speed={2}
        width={145}
        height={190}
        viewBox="0 0 145 190"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="10" ry="10" width="145" height="190" />
    </ContentLoader>
)

const linkStyle: CSSProperties = {
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

const FlowList: React.FC = () => {

    const titlePage = "Fluxos";

    const history = useHistory();
    const { user } = useAuth();

    const { addToast } = useToast();

    const [flows, setFlows] = useState([] as FlowProps[]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingItems] = useState<number[]>([1, 2, 3]);
    const [openInviteUser, setOpenInviteUser] = useState(false);

    const handleGoToTemplateStore = useCallback(async () => {

        history.push('/template-store');

    }, [history]);

    const handleAddNewFlow = useCallback(async () => {

        history.push('/template-store');

    }, [history]);

    const handleConfigFlow = useCallback(async (flow: FlowProps) => {

        if (flow.name_flow === "Rascunho") {

            history.push('/flow/1/' + flow.hash + '/new/');

        } else {

            history.push('/flow/1/' + flow.hash + '/edit/');

        }

    }, [history]);

    const handleStarFlow = useCallback(async (flowStared: FlowProps) => {

        if (flowStared.id_flow !== undefined) {

            const newStared: string = flowStared.isStared === "N" ? "S" : "N";

            await api
                .post('/flow-user-preference', {
                    flow_id: flowStared.id_flow,
                    isStared: newStared
                })
                .then(response => {

                    api.get(`/flow/my-flows`, {
                    }).then(response => {

                        let arrNew: FlowProps[] = [];

                        response.data.map((itemData: FlowApiProps, index: number) => {

                            const flowApiReturn: FlowProps = {
                                id_flow: itemData.id_flow !== null ? itemData.id_flow : undefined,
                                hash: itemData.hash !== null ? itemData.hash : flowDefault.hash,
                                name_flow: itemData.name !== null ? itemData.name : flowDefault.name_flow,
                                isPrivate: itemData.isPrivate !== null ? itemData.isPrivate : flowDefault.isPrivate,
                                icon_flow: itemData.icon !== null ? itemData.icon : flowDefault.icon_flow,
                                color_flow: itemData.color !== null ? itemData.color : flowDefault.color_flow,
                                total_cards: itemData.total_cards !== null ? itemData.total_cards : flowDefault.total_cards,
                                isStared: itemData.isStared !== null ? itemData.isStared : flowDefault.isStared,
                                typeUserAccess: itemData.typeUserAccess !== null ? itemData.typeUserAccess : flowDefault.typeUserAccess
                            }

                            return arrNew[index] = flowApiReturn;

                        });

                        //Order by Favorite
                        arrNew.sort(compare);

                        setFlows(arrNew);

                    }).catch(error => {
                        setLoading(false);
                        addToast({
                            type: 'error',
                            title: 'Erro ao buscar os seus Fluxos',
                            description: 'Ocorreu um erro ao carregar os seus Fluxos!',
                        });
                    });

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao favoritar o Fluxo',
                        description: 'Ocorreu um erro ao tentar favoritar o Fluxo!',
                    });
                });

        }

    }, [addToast]);

    useEffect(() => {

        setLoading(true);

        api.get(`/flow/my-flows`, {
        }).then(response => {

            let arrNew: FlowProps[] = [];

            response.data.map((itemData: FlowApiProps, index: number) => {

                const flowApiReturn: FlowProps = {
                    id_flow: itemData.id_flow !== null ? itemData.id_flow : undefined,
                    hash: itemData.hash !== null ? itemData.hash : flowDefault.hash,
                    name_flow: itemData.name !== null ? itemData.name : flowDefault.name_flow,
                    icon_flow: itemData.icon !== null ? itemData.icon : flowDefault.icon_flow,
                    color_flow: itemData.color !== null ? itemData.color : flowDefault.color_flow,
                    isPrivate: itemData.isPrivate !== null ? itemData.isPrivate : flowDefault.isPrivate,
                    total_cards: itemData.total_cards !== null ? itemData.total_cards : flowDefault.total_cards,
                    isStared: itemData.isStared !== null ? itemData.isStared : flowDefault.isStared,
                    typeUserAccess: itemData.typeUserAccess !== null ? itemData.typeUserAccess : flowDefault.typeUserAccess
                }

                return arrNew[index] = flowApiReturn;

            });

            //Order by Favorite
            arrNew.sort(compare);

            setFlows(arrNew);
            setLoading(false);

        }).catch(error => {
            setLoading(false);
            addToast({
                type: 'error',
                title: 'Erro ao buscar os seus Fluxos',
                description: 'Ocorreu um erro ao carregar os seus Fluxos!',
            });
        });

    }, [addToast]);

    return (
        <LayoutContainer>
            <title>Meus Fluxos | Cange</title>
            <ListContainer>

                {/* Header Page */}
                <PageHeader icon={TiFlowMerge} title={titlePage} iconDynamicColor={"#f23c5d"}>
                    {/* Usuários */}
                    {getAccessControl(6, user.type) ?
                        <>
                            <ButtonUserAdd
                                icon={IoMdPersonAdd}
                                type="button"
                                color="#4680B8"
                                height="36px"
                                margin="0px 15px 0px 0px"
                                onClick={() => setOpenInviteUser(!openInviteUser)}
                            >
                                Convidar
                            </ButtonUserAdd>
                            {openInviteUser ?
                                <InviteUser
                                    key="invite-user-flow-list"
                                    open={openInviteUser}
                                    onClose={() => setOpenInviteUser(false)}
                                    typeUser={user.type}
                                    fullScreen={true}
                                    title="Convide membros para colaborar"
                                /> :
                                <> </>}
                        </> : <></>
                    }
                </PageHeader>

                {/* User Stats */}
                {getAccessControl(88, user.type) && (
                    <StatsUser filter="flow" />
                )}

                {/* Container Body */}
                <ContainerBody>
                    <ContainerContent>

                        <Title
                            title="Meus Fluxos"
                            required={false}
                        />
                        {user.type === "S" ?
                            <Description>
                                Abaixo estão os fluxos que compartilhados com você
                            </Description> :
                            <Description>
                                Abaixo estão os fluxos que foram criados por você ou que alguém compartilhou com você
                            </Description>
                        }

                        <FlowContainer>

                            {getAccessControl(9, user.type) ?
                                <FlowNewItem onClick={handleAddNewFlow}>
                                    <FlowNewItemContainer>
                                        <IconPickerItem
                                            icon={'FaPlus'}
                                            color={"white"}
                                        />
                                    </FlowNewItemContainer>
                                    <FlowNewContainerBottom>
                                        <h2>
                                            Adicionar Novo Fluxo
                                        </h2>
                                    </FlowNewContainerBottom>
                                </FlowNewItem> : <></>
                            }

                            {loading ?
                                loadingItems.map((item) => {
                                    return (
                                        <div key={item} style={{ marginRight: '10px' }}>
                                            <LoaderCard />
                                        </div>
                                    )
                                }) :
                                flows.map((flow) => {
                                    return (
                                        <FlowItem key={flow.hash} color={flow.color_flow}>
                                            <FlowHeader>
                                                <FlowHeaderLeft>
                                                    <FlowStar className="flow-star" isStarred={flow.isStared} onClick={() => handleStarFlow(flow)}>
                                                        <FaStar />
                                                    </FlowStar>
                                                </FlowHeaderLeft>
                                                <FlowHeaderCenter title="Fluxo Privado" className="flow-config">
                                                    {flow.isPrivate === "1" ?
                                                        <AiFillLock />
                                                        : <></>}
                                                </FlowHeaderCenter>
                                                <FlowHeaderRigth>
                                                    {getAccessControl(8, flow !== undefined && flow.typeUserAccess !== undefined ? flow.typeUserAccess : "") ?
                                                        <FlowConfig className="flow-config" onClick={() => handleConfigFlow(flow)}>
                                                            <BsGearFill />
                                                        </FlowConfig> : <></>
                                                    }
                                                </FlowHeaderRigth>
                                            </FlowHeader>
                                            <Link to={() => {
                                                if (flow.name_flow === "Rascunho") {
                                                    return '/flow/1/' + flow.hash + '/new/';
                                                } else {
                                                    return '/flow/' + flow.hash;
                                                }
                                            }} style={linkStyle}>
                                                <div style={{ textAlign: "center", display: "contents" }}>
                                                    <FlowItemContainer color={flow.color_flow}>
                                                        <IconPickerItem
                                                            icon={flow.icon_flow ? flow.icon_flow as IconList : 'FaCodeBranch'}
                                                            color={flow.color_flow ? flow.color_flow : "#f23b5c"}
                                                        />
                                                    </FlowItemContainer>
                                                    <FlowContainerBottom>
                                                        <h2>
                                                            {flow.name_flow}
                                                        </h2>
                                                        {flow?.total_cards !== undefined && flow?.total_cards > 0 ?
                                                            <span>{flow?.total_cards} {flow?.total_cards > 1 ? "Cards" : "Card"}</span> :
                                                            <></>
                                                        }
                                                    </FlowContainerBottom>
                                                </div>
                                            </Link>
                                        </FlowItem>
                                    );
                                })}

                        </FlowContainer>

                    </ContainerContent>
                </ContainerBody>

                {/* My Templates */}
                {getAccessControl(87, user.type) && (
                    <ContainerBody style={{ marginBottom: "15px", border: "2px solid #f23c5d", borderRadius: "10px" }}>
                        <ContainerContent>
                            <ContainerHeader>
                                <Title
                                    title="Templates sugeridos para você"
                                    required={false}
                                    font_size="18px"
                                />
                                <ContainerHeaderRight style={{ width: 'calc(100% - 260px)' }}>
                                    <BtnGoToPage onClick={() => handleGoToTemplateStore()}>Ver todos templates</BtnGoToPage>
                                </ContainerHeaderRight>
                            </ContainerHeader>
                            <MyTemplate />
                        </ContainerContent>
                    </ContainerBody>
                )}

                <div style={{ marginBottom: "15px", color: "transparent" }}>.</div>

            </ListContainer>

        </LayoutContainer>
    );

}

export default FlowList;