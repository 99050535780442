import styled, { css } from "styled-components";
import Button from "../Button";

interface BoxUserItemProps {
    selected?: boolean;
}

interface ContainerProps {
    isInLine?: boolean;
    left?: number;
    top?: number;
    isAbove?: boolean;
}

export const Container = styled.div<ContainerProps>`
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #242424;
    border-radius: 10px;
    min-width: 200px;
    max-width: 400px;
    color: white;

    ${props => props.isInLine && css`
        position: fixed;
        margin-top: 10px;
        z-index: 9999;
        bottom: auto;
    `}

    ${props => props.left && css`
        left: ${props.left}px;
    `}

    ${props => props.top && css`
        top: ${props.top}px;
    `}

    ${props => props.isAbove && css`
        bottom: ${props.top}px;
        top: auto;
    `}
`;

export const BoxSearchContainer = styled.div`
    padding: 15px;
    padding-top: 0px;
`;

export const BoxShortCutsContainer = styled.div`
    padding: 15px;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ShortCutBtn = styled.div`
    cursor: pointer;
    color: white;
    font-size: 13px;
    padding: 3px 8px;
    border-radius: 5px;
    margin-right: 10px;
    background-color: #81818145;
    min-width: 75px;
    text-align: center;

    :hover {
        background-color: white;
        color: black;
    }
`;

export const SearchInput = styled.input`
    width: 100%;
    height: 35px;
    padding: 5px 15px 5px 15px;
    border-radius: 15px;
    border: 1px solid #f4f5f7;
    background-color: #f4f5f7;
    font-size: 14px;
`;

export const BoxItemsContainer = styled.div`    
    overflow-y: auto;
    padding: 15px;
    padding-bottom: 5px;
`;

export const BoxTagItem = styled.div<{ color?: string, selected?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    border-radius: 5px;
    padding: 10px;
    font-size: 13px;
    margin-bottom: 7px;
    max-width: 180px;
    cursor: pointer;
    position: relative;

    ${props => props.color && css`
        background-color: ${props.color};
    `}

    ${props => props.selected && css`
        border: 2px solid white;
    `}

    :hover {
        ${props => props.color && css`   
            opacity: 0.9;

            div {
                color: #4d4d4d;
            }
        `}
    }

    svg {
        margin-right: 7px;
        font-size: 13px;        
    }
`;

export const BoxOptionsItem = styled.div<{ color?: string, selected?: boolean }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 30px;
    border-radius: 5px;
    padding: 10px;
    font-size: 13px;
    margin-bottom: 7px;
    min-width: 200px;
    cursor: pointer;
    position: relative;

    ${props => props.selected && css`
        border: 2px solid white;
        background-color: ${props.color};
    `}

    :hover {
        ${props => props.color && css`   
            opacity: 0.9;
            background-color: ${props.color};

            div {
                color: #4d4d4d;
            }
        `}
    }

    svg {
        margin-right: 10px;
        font-size: 13px;        
    }
`;

export const BoxTagDivider = styled.div`
    border-top: 1px solid #81818145;
    margin-bottom: 10px;
    margin-top: 3px;
`;

export const BoxUserItem = styled.div<BoxUserItemProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px 5px 10px;
    border: 1px dashed transparent;
    color: white;
    margin-bottom: 5px;
    user-select: none;
    cursor: pointer;
    border-radius: 15px;

    :hover {
        background-color: #f4f5f7;
        color: black;
    }

    ${props => props.selected && css`
        background-color: #f4f5f7;
        color: black;
    `}
`;

export const BoxUserLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BoxUserRigth = styled.div`
    margin-left: 15px;
    width: 100%;
`;

export const BoxUserName = styled.div`
    font-size: 14px;    
    font-weight: 400;
    overflow: hidden;
`;

export const BoxUserMail = styled.div`
    font-size: 11px;
    color: gray;
    overflow: hidden;
`;

export const DatePickerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .react-datepicker {
        background: transparent;
    }

    .react-datepicker-popper {
        box-shadow: 0 0 6px rgb(0 0 0 / 20%);
        border-radius: 5px;
        padding: 10px;
        background-color: white;
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker__header {
        border: none;
    }
    
    .react-datepicker__time-container {
        border-left: 1px solid #eef2f8;
    }

    .react-datepicker__day {
        color: white;

        :hover {
            color: black;
        }
    }

    .react-datepicker__day-name {
        font-weight: 600;
        color: white;
    }

    .react-datepicker__day--outside-month {
        color: gray;
    }

    .react-datepicker__day--today {
        border-radius: 0.3rem;
        background-color: #ffecef;
        color: black;
    }    

    .react-datepicker-time__caption {
        display: none;
    }

    .react-datepicker-time__input-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: none;
        border-radius: 5px;
        padding: 0px;
    }

    .react-datepicker__input-time-container {
        margin-left: 0px;
    }

    .react-datepicker__day--selected {
        background-color: #f23b5d;
    }

    .react-datepicker__time-list-item--selected {
        background-color: #f23b5d !important;
    }

    .react-datepicker__time-list-item--selected:hover {
        background-color: #f23b5d !important;
    }

    .react-datepicker__day--selected {
        background-color: #f23b5d;
        color: white;
    }

    .react-datepicker__time-list-item {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .react-datepicker-time__header {
        font-weight: 500;
        font-size: 12.8px;
    }

    .react-datepicker__day-names {
        margin-top: 10px;
    }

    .react-datepicker__current-month::first-letter {
        text-transform: uppercase;
    }

    .react-datepicker-time__input {
        width: 100%;
        margin-left: 0px !important;
    }

    .react-datepicker-time__input input {
        border: 1px solid #eef2f8;
        border-radius: 5px;        
        width: 100% !important;
        text-align: center;
        font-size: 12.8px;
        font-size: 14px;
        padding: 7px;
        cursor: pointer;
        color: white;
        background-color: #81818145;

        svg {
            color: #81818145;
        }
    }

    .react-datepicker__current-month {
        color: white;
    }

    .react-datepicker__day--keyboard-selected {
        background-color: white;
        color: black;
    }
`;

export const ActionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;           
`;

export const BtnCancel = styled(Button)`
    cursor: pointer;
    color: white;
    font-size: 13px;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 10px;
    background-color: #81818145;
    min-width: 75px;
    height: 25px;
    text-align: center;

    :hover {
        background-color: white;
        color: black;
    }
`;

export const BtnConfirm = styled(Button)`
    cursor: pointer;
    color: white;
    font-size: 13px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #f23b5d;
    min-width: 75px;
    height: 25px;
    text-align: center;

    :hover {
        background-color: white;
        color: black;
    }
`;