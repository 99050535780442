import React, { useRef, useState } from "react";

import {
    ChildrenBtn,
    Container,
    EmptyItem
} from "./style";
import { BsCalendarPlus, BsTag } from "react-icons/bs";
import QuickActionBox from "../QuickActionBox";
import { ClickAwayListener } from "@material-ui/core";
import { FiUserPlus } from "react-icons/fi";
import getAccessControl from "../../middlewares/AccessControl";

interface TableQuickActionProps {
    type: 'user' | 'due_date' | 'tag' | 'options';
    isSelected?: boolean;
    card_id: number;
    flow_id: number;
    children?: React.ReactNode;
    typeUser: string;
    onForceUpdate?: (id_card_items?: number[], isUpdateAll?: boolean) => void;
    onClose?: () => void;
}

const TableQuickAction: React.FC<TableQuickActionProps> = ({ type, card_id, flow_id, isSelected, children, typeUser, onForceUpdate, onClose }) => {

    const buttonRef = useRef<HTMLButtonElement>(null);

    const [open, setOpen] = React.useState<boolean>(false);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const [isAbove, setIsAbove] = useState(false);

    const handleClick = () => {
        setOpen(!open);

        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            const menuHeight = type === 'user' ? 200 : type === 'tag' ? 250 : type === 'due_date' ? 300 : 250;
            const menuWidth = type === 'user' ? 280 : type === 'tag' ? 200 : type === 'due_date' ? 275 : 250;

            const spaceBelow = window.innerHeight - rect.bottom;
            const spaceAbove = rect.top;

            if (spaceBelow < menuHeight && spaceAbove >= menuHeight) {
                setIsAbove(true);
                setMenuPosition({
                    top: window.innerHeight - rect.bottom + 40,
                    left: rect.left + window.scrollX + rect.width / 2 - menuWidth / 2 + 45,
                });
            } else {
                setIsAbove(false);
                setMenuPosition({
                    top: rect.top + window.scrollY + 30,
                    left: rect.left + window.scrollX + rect.width / 2 - menuWidth / 2 + 45,
                });
            }
        }
    }

    const handleClickAway = () => {
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Container>

                {isSelected ? <ChildrenBtn ref={buttonRef} onClick={() => getAccessControl(96, typeUser) ? handleClick() : {}}>{children}</ChildrenBtn> : <></>}

                {!isSelected && (
                    <EmptyItem ref={buttonRef} onClick={() => getAccessControl(96, typeUser) ? handleClick() : {}}>
                        {type === 'tag' ?
                            <BsTag size={18} /> :
                            type === 'user' ?
                                <FiUserPlus size={16} /> :
                                type === 'due_date' ?
                                    <BsCalendarPlus size={16} /> :
                                    <></>
                        }
                    </EmptyItem>
                )}

                <div>
                    {open ? (
                        <QuickActionBox
                            key={"quick-action-box"}
                            type={type}
                            flow_id={flow_id}
                            totalSelected={1}
                            id_card_items={[card_id]}
                            isInLine={true}
                            posTop={menuPosition.top}
                            posLeft={menuPosition.left}
                            isAbove={isAbove}
                            removeConfirm={true}
                            onForceUpdate={onForceUpdate}
                            onClose={handleClose}
                        />
                    ) : <></>
                    }
                </div>

            </Container>
        </ClickAwayListener>
    );

}

export default TableQuickAction;