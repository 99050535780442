import { FieldProps } from "../../components/Forms/Fields/FieldBuilder";
import { evaluate } from 'mathjs'

export interface ParseFormulaProps {

}

interface objectInit {
    [x: string]: string | object
}

export default function parseFormula(formulaField: FieldProps, fields: FieldProps[], currData?: object[]): string {

    let result = "";

    //Check if have any data to work
    if (currData !== undefined && currData !== undefined && formulaField.formula !== undefined) {

        let formula = formulaField.formula;
        let data = currData as unknown as objectInit;

        let fieldsToParse: string[] = [];
        let isActive = false;
        let isError = false;
        let tmpField = "";

        //Generate the array with the id of fields to parse
        for (let index = 0; index < formula.length; index++) {

            if (formula[index] === "{") {
                isActive = true;
                tmpField += "{"
            } else if (formula[index] === "}") {
                isActive = false;
                tmpField += "}"
                fieldsToParse.push(tmpField);
                tmpField = "";
            } else if (isActive) {
                tmpField += formula[index];
            }

        }

        //Generate the array with the id of fields to parse
        for (let index = 0; index < fieldsToParse.length; index++) {

            const field = fields.filter((f) => String(f.name) === fieldsToParse[index].replace(/{|}/g, ""))

            if (field.length > 0) {

                const fieldValue = data[field[0].name] as string;

                if (fieldValue !== undefined) {
                    formula = formula.replace(fieldsToParse[index], fieldValue);
                } else {
                    isError = true;
                }

            } else {
                isError = true;
            }

        }

        if (!isError) {
            result = evaluate(formula);
        } 

        return result;

    } 
    
    return result;

}