import React, { useEffect } from "react";
import { ResponsiveLine } from "@nivo/line";
import { colorsElement } from "../../getColorsElement";
import { DataProps } from "../..";
import getChartFormatValue from "../../getChartFormatValue";
import getPercentualOfTotal from "../../getPercentualOfTotal";
import getFormatLabel from "../../getFormatLabel";

interface LineChartProps {
  dataElement?: DataProps[];
  show_time?: string;
  typeField?: string;
  axis_y_field_id?: number;
  axis_x_field_id?: number;
  axis_y_field_type?: string;
  axis_y_field_variation?: string;
  axis_x_field_type?: string;
  color?: string;
}

export interface DataPropsLine {
  id: string;
  color?: string;
  data: {
    x: string;
    y: number;
  }[];
  labelAux?: string;
}

const dataDefault = [
  {
    id: "Valor 1",
    data: [
      {
        x: "10/12",
        y: 12
      },
      {
        x: "11/12",
        y: 15
      },
      {
        x: "12/12",
        y: 5
      },
      {
        x: "13/12",
        y: 18
      }
    ]
  },
  {
    id: "Valor 2",
    data: [
      {
        x: "10/12",
        y: 15
      },
      {
        x: "11/12",
        y: 1
      },
      {
        x: "12/12",
        y: 5
      },
      {
        x: "13/12",
        y: 18
      }
    ]
  }
];

// Function to transform the data to the format expected by the chart
export function transformData(data: DataProps[]): DataPropsLine[] {
  // Map all unique ids
  const uniqueIds = [...new Set(data.map(item => item.labelAux || ''))];
  // Map all unique labels
  const uniqueLabels = [...new Set(data.map(item => item.label))];

  // Create the data points
  const result = uniqueIds.map(id => {
    const dataPoints = uniqueLabels.map(label => {
      const matchingItem = data.find(item => item.labelAux === id && item.label === label);
      return {
        x: label,
        y: matchingItem !== undefined && matchingItem.value !== undefined ? matchingItem.value : 0
      };
    });

    return {
      id: id || '', // If the id is undefined, set it as an empty string
      data: dataPoints,
      labelAux: id
    };
  });

  return result;
}

const BREAK_LINE = 6;

const LineChartStacked: React.FC<LineChartProps> = ({ dataElement, show_time, axis_y_field_id, color, axis_y_field_type, axis_y_field_variation }) => {

  const [data, setData] = React.useState<DataPropsLine[]>(transformData(dataElement !== undefined ? dataElement : []));

  useEffect(() => {

    if (dataElement !== undefined && dataElement !== null && dataElement.length > 0) {

      const data = transformData(dataElement);

      setData(data);
    }

  }, [dataElement, color]);

  return (
    <>
      <ResponsiveLine
        data={data !== undefined ? data : dataDefault}
        margin={data !== undefined && data.length > BREAK_LINE ? { top: 10, right: 30, bottom: 70, left: 50 } :
          { top: 10, right: 30, bottom: 60, left: 50 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          reverse: false
        }}
        colors={colorsElement}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        enableArea={true}
        areaOpacity={0.3}
        lineWidth={3}
        axisBottom={{
          tickSize: 0,
          tickPadding: 15,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: -50,
          format: value => {
            return getChartFormatValue(value, axis_y_field_type, 0, axis_y_field_variation);
          },
        }}
        enablePoints={true}
        enableSlices="x"
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="data.yFormatted"
        pointLabelYOffset={-12}
        useMesh={true}
        sliceTooltip={({ slice }) => {

          //Reorder the points by value 9 -> 0
          slice.points.sort((a, b) => {
            return Number(b.data.yFormatted) - Number(a.data.yFormatted);
          });

          //Calc the total value
          let total = 0;

          slice.points.map(point => {
            total += Number(point.data.yFormatted);
            return point;
          });

          return (
            <div
              style={{
                padding: 12,
                borderRadius: 5,
                fontSize: 13,
                background: '#222222',
                color: '#ffffff',
                marginTop: '10px',
                marginBottom: '0px'
              }}
            >
              {slice.points.map(point => (
                Number(point.data.yFormatted) > 0 && (
                  <div
                    key={point.id}
                  >
                    <div style={{ fontSize: '13px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                      <div
                        key={point.id}
                        style={{
                          backgroundColor: point.serieColor,
                          padding: '3px',
                          width: '10px',
                          height: '10px',
                          marginRight: '10px',
                        }}
                      />
                      {point.serieId} - {getChartFormatValue(Number(point.data.yFormatted), axis_y_field_type, 2, axis_y_field_variation) + " (" + getPercentualOfTotal(Number(point.data.yFormatted), total) + ")"}
                    </div>
                  </div>
                )
              ))}
            </div>
          )
        }}
        legends={
          data !== undefined && data.length > BREAK_LINE ?
            [{
              anchor: 'bottom',
              direction: 'row',
              itemHeight: 20,
              itemWidth: 125,
              toggleSerie: true,
              translateY: 50,
              symbolShape: 'square',
              symbolSpacing: 3,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ],
              data: data
                .slice(0, Math.floor(data.length / 2))
                .map((cur, index) => ({
                  id: cur.id,
                  label: (cur.labelAux !== undefined ? getFormatLabel(cur.labelAux, 15) : getFormatLabel(cur.id, 15)),
                  color: colorsElement[index]
                }))
            },
            {
              anchor: 'bottom',
              direction: 'row',
              itemHeight: 20,
              itemWidth: 125,
              toggleSerie: true,
              translateY: 70,
              symbolShape: 'square',
              symbolSpacing: 3,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ],
              data: data
                .slice(Math.floor(data.length / 2))
                .map((cur, index) => ({
                  id: cur.id,
                  label: (cur.labelAux !== undefined ? getFormatLabel(cur.labelAux, 15) : getFormatLabel(cur.id, 15)),
                  color: colorsElement[Math.floor(data.length / 2) + index]
                }))
            }] :
            [{
              anchor: 'bottom',
              direction: 'row',
              itemHeight: 20,
              itemWidth: 120,
              toggleSerie: true,
              translateY: 60,
              symbolShape: 'square',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }]
        }
      />
    </>
  );

}

export default LineChartStacked;