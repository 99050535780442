import styled, { keyframes, css } from 'styled-components';
import { shade } from "polished";

interface MenuActionProps {
    color?: string;
    hoverTop?: number;
    hoverLeft?: number;
}

interface IconContainerProps {
    color?: string;
}

interface LogoContainerProps {
    isLogoCustom?: boolean;
}

const vibration = keyframes`
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-1px);
    }
    50% {
      transform: translateX(1px);
    }
    75% {
      transform: translateX(-1px);
    }
    100% {
      transform: translateX(1px);
    }
`;

export const Container = styled.div`

`;

export const IconContainer = styled.div<IconContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        display: flex;
        justify-content: center;
        align-items: center; 
    }

    :hover {
        div {
            svg {
                ${(props) => props.color && css`
                    color: ${props.color} !important;
                `}
            }
        }
    }
`;

export const LogoContainer = styled.div<LogoContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 550px;
    height: 58px;

    margin-top: 2px;
    padding: 13px;
    padding-right: 15px;

    img {
        height: 30px
    }

    @media (max-width: 767.98px) {
        align-items: flex-start;
        height: 60px;

        img {
            height: 30px;
        }
    }

    ${(props) => props.isLogoCustom === true && css`
        img.img-logo {
            height: 70px;
        }

        img.img-logo-mini {
            height: 45px;
        }

        @media (max-width: 767.98px) {
            align-items: flex-start;
            height: 60px;

            img {
                height: 30px;
            }
        }
    `}
`;

export const WelcomeContainer = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-top: 3px;

`;

export const UserImg = styled.img`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 3px solid #f23b5c;
`;

export const UserImgCollapsed = styled.img`
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 3px solid #f23b5c;
`;

export const DividerContainer = styled.div`
    border-bottom: 1px solid #818181;
`;

export const FooterContainer = styled.div`
    padding: 10px 25px 10px 25px;
`;

export const MenuAction = styled.div<MenuActionProps>`
    color: white;

    span.pro-icon-wrapper {

        ${(props) => props.color && css`
            background-color: ${props.color} !important;
            color: white;
        `}
    }
    
    :hover {
        .icon-container-cl {            
            div {
                svg {
                    ${(props) => props.color && css`
                        color: ${props.color} !important;
                    `}
                }
            }
        }

        .pro-item-content {
            ${(props) => props.color && css`
                background-color: ${props.color} !important;
            `}   
            
            ${(props) => props.hoverTop && css`
                top: ${props.hoverTop + 'px'} !important;
            `}   

            ${(props) => props.hoverLeft && css`
                left: ${props.hoverLeft + 'px'} !important;
            `}   
        }
    }
`;

export const ButtonContainer = styled.button`

    background: #f23b5c;
    height: 50px;
    width: 200px;
    border: 0;
    padding: 0;
    color: white;
    font-weight: 500;
    margin-top: 60px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 15px 20px 10px 20px;
    border-radius: 30px 30px 0px 0px;
    background-color: #f23b5c;

    color: white;
    font-weight: 500;


    svg {
        margin-left: 20px;
        width: 20px;
        height: 20px;
    }

    strong {
        margin-top: 2px;
    }

    &:hover {
        background: ${shade(0.2, '#f23b5c')}
    } 
`;

export const AvatarName = styled.span`
    font-weight: 500;
    margin-left: 10px;
    color: white;
`
export const MenuMobile = styled.div`
    display: flex;
    color: white;
    position: absolute;
    right: 13px;
    font-size: 25px;
`;

export const NotificationActive = styled.div`
    position: absolute;
    left: 45px;
    top: 8px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #f23b5c;

    animation: ${vibration} 0.5s linear;   
    animation-delay: 1s;
`;