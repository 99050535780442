import {
    ButtonUserAdd,
    ContainerBody,
    ContainerContent,
    RegisterConfig,
    RegisterContainer,
    RegisterContainerBottom,
    RegisterHeader,
    RegisterHeaderCenter,
    RegisterHeaderLeft,
    RegisterHeaderRigth,
    RegisterItem,
    RegisterItemContainer,
    RegisterNewContainerBottom,
    RegisterNewItem,
    RegisterNewItemContainer,
    RegisterStar,
} from "./style";
import { FaStar, FaTable } from "react-icons/fa";
import { IconList, IconPickerItem } from 'react-fa-icon-picker'
import React, { CSSProperties, useCallback, useEffect, useState } from "react";

import { AiFillLock } from "react-icons/ai";
import { BsGearFill } from "react-icons/bs";
import ContentLoader from "react-content-loader";
import Description from "../../components/Description";
import InviteUser from "../../dialogs/InviteUser";
import { IoMdPersonAdd } from "react-icons/io";
import LayoutContainer from '../../components/LayoutContainer';
import ListContainer from '../../components/ListContainer';
import PageHeader from "../../components/PageHeader";
import { Register } from "../../interfaces/Register";
import StatsUser from "../../components/Stats/StatsUser";
import Title from "../../components/Title";
import api from '../../services/api';
import getAccessControl from "../../middlewares/AccessControl";
import { useAuth } from "../../hooks/auth";
import { Link, useHistory } from 'react-router-dom';
import { useToast } from '../../hooks/toast';

const linkStyle: CSSProperties = {
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

const registerDefault: Register = {
    hash: "",
    color: "#795548",
    icon: "FaWrench",
    name: "Rascunho",
    isStared: "N",
    total_registers: 0
}

function compare(a: Register, b: Register) {
    if (a.isStared !== undefined && b.isStared !== undefined) {
        if (a.isStared > b.isStared) {
            return -1;
        }
        if (a.isStared < b.isStared) {
            return 1;
        }
    }
    return 0;
}

const LoaderCard = () => (
    <ContentLoader
        speed={2}
        width={145}
        height={190}
        viewBox="0 0 145 190"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="10" ry="10" width="145" height="190" />
    </ContentLoader>
)

const RegisterList: React.FC = () => {

    const titlePage = "Cadastros";
    const history = useHistory();
    const { user } = useAuth();
    const { addToast } = useToast();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingItems] = useState<number[]>([1, 2, 3]);
    const [registers, setRegisters] = useState([] as Register[]);
    const [openInviteUser, setOpenInviteUser] = useState(false);

    const handleConfigRegister = useCallback(async (register: Register) => {

        history.push('/register/1/' + register.hash + '/edit/');

    }, [history]);

    const handleStarRegister = useCallback(async (register: Register) => {

        if (register.id_register !== undefined) {

            const newStared: string = register.isStared === "N" ? "S" : "N";

            await api
                .post('/register-user-preference', {
                    register_id: register.id_register,
                    isStared: newStared
                })
                .then(response => {

                    api.get(`/register/my-registers`, {
                    }).then(response => {

                        let arrNew: Register[] = [];

                        response.data.map((itemData: Register, index: number) => {

                            const flowApiReturn: Register = {
                                id_register: itemData.id_register !== null ? itemData.id_register : undefined,
                                hash: itemData.hash !== null ? itemData.hash : registerDefault.hash,
                                name: itemData.name !== null ? itemData.name : registerDefault.name,
                                icon: itemData.icon !== null ? itemData.icon : registerDefault.icon,
                                color: itemData.color !== null ? itemData.color : registerDefault.color,
                                isPrivate: itemData.isPrivate !== null ? itemData.isPrivate : registerDefault.isPrivate,
                                total_registers: itemData.total_registers !== null ? itemData.total_registers : registerDefault.total_registers,
                                isStared: itemData.isStared !== null ? itemData.isStared : registerDefault.isStared,
                                typeUserAccess: itemData.typeUserAccess !== null ? itemData.typeUserAccess : registerDefault.typeUserAccess,
                                form_id: itemData.form_id !== null ? itemData.form_id : registerDefault.form_id
                            }

                            return arrNew[index] = flowApiReturn;

                        });

                        //Order by Favorite
                        arrNew.sort(compare);

                        setRegisters(arrNew);

                    }).catch(error => {
                        setLoading(false);
                        addToast({
                            type: 'error',
                            title: 'Erro ao buscar os seus Cadastros',
                            description: 'Ocorreu um erro ao carregar os seus Cadastros!',
                        });
                    });

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao favoritar o Cadastro',
                        description: 'Ocorreu um erro ao tentar favoritar o Cadastro!',
                    });
                });

        }

    }, [addToast]);

    const handleAddNewRegister = useCallback(async () => {

        await api
            .post('/register/new', {})
            .then(response => {
                history.push('/register/1/' + response.data.hash + "/new");
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao inserir um novo Cadastro',
                    description: 'Ocorreu um erro ao inserir um novo Cadastro!',
                });
            });

    }, [addToast, history]);

    useEffect(() => {

        setLoading(true);

        api.get(`/register/my-registers`, {
        }).then(response => {

            let arrNew: Register[] = [];

            response.data.map((itemData: Register, index: number) => {

                const flowApiReturn: Register = {
                    id_register: itemData.id_register !== null ? itemData.id_register : undefined,
                    hash: itemData.hash !== null ? itemData.hash : registerDefault.hash,
                    name: itemData.name !== null ? itemData.name : registerDefault.name,
                    icon: itemData.icon !== null ? itemData.icon : registerDefault.icon,
                    color: itemData.color !== null ? itemData.color : registerDefault.color,
                    isPrivate: itemData.isPrivate !== null ? itemData.isPrivate : registerDefault.isPrivate,
                    total_registers: itemData.total_registers !== null ? itemData.total_registers : registerDefault.total_registers,
                    isStared: itemData.isStared !== null ? itemData.isStared : registerDefault.isStared,
                    typeUserAccess: itemData.typeUserAccess !== null ? itemData.typeUserAccess : registerDefault.typeUserAccess,
                    form_id: itemData.form_id !== null ? itemData.form_id : registerDefault.form_id
                }

                return arrNew[index] = flowApiReturn;

            });

            //Order by Favorite
            arrNew.sort(compare);

            setRegisters(arrNew);
            setLoading(false);

        }).catch(error => {
            setLoading(false);
            addToast({
                type: 'error',
                title: 'Erro ao buscar os seus Cadastros',
                description: 'Ocorreu um erro ao carregar os seus Cadastros!',
            });
        });

    }, [addToast]);

    return (
        <LayoutContainer>
            <title>Meus Cadastros | Cange</title>
            <ListContainer>

                {/* Header Page */}
                <PageHeader icon={FaTable} title={titlePage} iconDynamicColor={"#f23c5d"}>

                    {/* Usuários */}
                    {getAccessControl(7, user.type) ?
                        <>
                            <ButtonUserAdd
                                icon={IoMdPersonAdd}
                                type="button"
                                color="#4680B8"
                                height="36px"
                                margin="0px 15px 0px 0px"
                                onClick={() => setOpenInviteUser(!openInviteUser)}
                            >
                                Convidar
                            </ButtonUserAdd>
                            <InviteUser
                                open={openInviteUser}
                                onClose={() => setOpenInviteUser(false)}
                                typeUser={user.type}
                                fullScreen={true}
                                title="Convide membros para colaborar"
                            />
                        </> : <></>
                    }

                </PageHeader>

                {/* User Stats */}
                <StatsUser filter="register" />

                {/* Container Body */}
                <ContainerBody>
                    <ContainerContent>

                        <Title
                            title="Meus Cadastros"
                            required={false}
                        />
                        <Description>Abaixo estão os cadastros que foram criados por você ou que alguém compartilhou com você</Description>

                        <RegisterContainer>

                            {getAccessControl(34, user.type) ?
                                <RegisterNewItem onClick={handleAddNewRegister}>
                                    <RegisterNewItemContainer>
                                        <IconPickerItem
                                            icon={'FaPlus'}
                                            color={"white"}
                                        />
                                    </RegisterNewItemContainer>
                                    <RegisterNewContainerBottom>
                                        <h2>
                                            Adicionar Novo Cadastro
                                        </h2>
                                    </RegisterNewContainerBottom>
                                </RegisterNewItem> : <></>
                            }

                            {loading ?
                                loadingItems.map((item) => {
                                    return (
                                        <div key={item} style={{ marginRight: '10px' }}>
                                            <LoaderCard />
                                        </div>
                                    )
                                }) :
                                registers.map((register) => {
                                    return (
                                        <RegisterItem key={register.hash} color={register.color}>
                                            <RegisterHeader>
                                                <RegisterHeaderLeft>
                                                    <RegisterStar className="register-star" isStarred={register.isStared} onClick={() => handleStarRegister(register)}>
                                                        <FaStar />
                                                    </RegisterStar>
                                                </RegisterHeaderLeft>
                                                <RegisterHeaderCenter title="Fluxo Privado">
                                                    {register.isPrivate === "1" ?
                                                        <AiFillLock />
                                                        : <></>}
                                                </RegisterHeaderCenter>
                                                <RegisterHeaderRigth>
                                                    {getAccessControl(33, register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : "") ?
                                                        <RegisterConfig className="register-config" onClick={() => handleConfigRegister(register)}>
                                                            <BsGearFill />
                                                        </RegisterConfig> : <></>
                                                    }
                                                </RegisterHeaderRigth>
                                            </RegisterHeader>
                                            <Link to={() => {
                                                if (register.name === "Rascunho") {
                                                    return '/register/1/' + register.hash + '/new/';
                                                } else if (register.form_id === null || register.form_id === undefined) {
                                                    return '/register/2/' + register.hash + '/new/';
                                                } else {
                                                    return '/register/' + register.hash;
                                                }
                                            }} style={linkStyle}>
                                                <div style={{ textAlign: "center", display: "contents" }}>
                                                    <RegisterItemContainer color={register.color}>
                                                        <IconPickerItem
                                                            icon={register.icon ? register.icon as IconList : 'FaCodeBranch'}
                                                            color={register.color ? register.color : "#f23b5c"}
                                                        />
                                                    </RegisterItemContainer>
                                                    <RegisterContainerBottom>
                                                        <h2>
                                                            {register.name}
                                                        </h2>
                                                        {register?.total_registers !== undefined && register?.total_registers > 0 ?
                                                            <span>{register?.total_registers} {register?.total_registers > 1 ? "Registros" : "Registro"}</span> :
                                                            <></>
                                                        }
                                                    </RegisterContainerBottom>
                                                </div>
                                            </Link>
                                        </RegisterItem>
                                    );
                                })}

                        </RegisterContainer>

                    </ContainerContent>
                </ContainerBody>

            </ListContainer>
        </LayoutContainer>

    );

}

export default RegisterList;