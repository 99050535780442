import styled, { css } from "styled-components";
import { Box } from "@material-ui/core";

import Button from "../Button";
import { User } from "../../interfaces/User";

interface ContainerProps {
    isErrored: boolean;
}

interface ButtonActionAddProps {
    isFilled: User | undefined;
}

export const Container = styled.div<ContainerProps>`
  padding: 16px 0px 0px 0px;

  ${(props) => props.isErrored && css`
      div {
        border-color: red;
      }
  `}
`;

export const Title = styled.div`
  color: gray;
`;

export const ButtonActionAdd = styled(Button)<ButtonActionAddProps>`
    height: 35px;
    font-weight: 500;
    justify-content: flex-start;
    overflow: hidden;
    font-size: 15px;
    color: #4d4d4d;
    margin-bottom: 10px;

    svg {
        width: 15px;
    }

    ${(props) => props.isFilled !== undefined && css`
      margin-bottom: 0px !important;
      border-radius: 5px 5px 0px 0px !important;
    `}
`;

export const BoxContainer = styled(Box)`
    position: fixed;
    z-index: 1;
    min-width: 250px;
    //max-width: 300px;
    //width: 100%;
    border-radius: 5px;
    padding: 5px;
    padding-bottom: 10px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 0px;
    right: 30px;
    margin-right: 10px;
`;

export const BoxItemsContainer = styled.div`
    max-height: 170px;
    overflow-y: auto;
`;

export const BoxUserSelected = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    font-size: 12px;
    margin-bottom: 10px;
    border-radius: 0px 0px 5px 5px;
    background-color: #893be9;
    border-top: 1px solid #80808061;
    font-weight: 500;
    color: white;
`;

export const BoxUserRemove = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 50px;
    padding: 5px;

    svg {
        height: 15px;
        width: 15px;
        color: black;
    }

    :hover {
        background-color: white;
    }
`;

export const BoxUserItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px 5px 10px;
    border: 1px dashed transparent;

    :hover {
        background-color: #f4f5f7;
        border-radius: 15px;
        border: 1px dashed #e8f2ff;
    }
`;

export const BoxUserLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BoxUserRigth = styled.div`
    margin-left: 15px;
    width: 100%;
`;

export const BoxUserName = styled.div`
    font-size: 14px;
    color: black;
    font-weight: 400;
    overflow: hidden;
`;

export const BoxUserMail = styled.div`
    font-size: 11px;
    color: gray;
    overflow: hidden;
`;

export const BoxSearchContainer = styled.div`
    padding: 10px 10px 10px 10px;
`;

export const SearchInput = styled.input`
    width: 100%;
    height: 35px;
    padding: 5px 15px 5px 15px;
    border-radius: 15px;
    border: 1px solid #f4f5f7;
    background-color: #f4f5f7;
    font-size: 14px;
`;

export const BtnRemoveUser = styled.button`
    margin: 5px;
    font-size: 13px;
    float: right;
    padding: 3px 5px;
    background-color: transparent;
    border: none;
    border: 1px dashed transparent;

    :hover {
        background-color: #f4f5f7;
        border-radius: 15px;
        border: 1px dashed #e8f2ff;
    }
`;
