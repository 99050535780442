import React, { useEffect, useState, useCallback } from "react";
import { format } from 'date-fns';

import {
    Container,
    BlockInit,
    BlocksContainer,
    Block,
    BlockEnd,
    BlockIcon,
    BlockName,
    TraceStepContainer,
    TraceStepTitle,
    TraceStepUser,
    TraceStepDateTime,
    TraceStepDuration,
    TraceStepSLA,
    TraceStepLine,
    TraceStepDateTimeEntry,
    TraceStepDateTimeExit,
    TraceStepDateTimeTime,
    TraceStepDateTimeDate
} from "./style";
import { CardMovement } from "../../interfaces/CardMovement";
import { differenceInDays, differenceInHours, differenceInMilliseconds, differenceInMinutes } from "date-fns";
import { FaCheck, FaFlagCheckered, FaRegClock } from "react-icons/fa";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { styled } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { Flow } from "../../interfaces/Flow";
import { Step } from "../../interfaces/Step";
import AvatarCange from "../AvatarCange";

interface CardMovementLineProps {
    card_movements: CardMovement[];
    flow?: Flow;
    current_step?: Step;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    "& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom": {
        backgroundColor: "white",
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const CardMovementLine: React.FC<CardMovementLineProps> = ({ card_movements, flow, current_step }) => {

    const [movements, setMovements] = useState<CardMovement[]>(card_movements);
    const [movementTotal, setMovementTotal] = useState<CardMovement>();

    function padTo2Digits(num: number) {
        return num.toString().padStart(2, '0');
    }

    const convertMsToTime = useCallback((milliseconds: number) => {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        seconds = seconds % 60;
        minutes = minutes % 60;
        hours = hours % 24;

        let ret = "";

        if (days > 1) {
            ret = days + " Dias ";
        } else if (days === 1) {
            ret = days + " Dia "
        }

        if (hours > 1) {
            ret += padTo2Digits(hours) + " Horas "
        } else if (hours === 1) {
            ret += padTo2Digits(hours) + " Hora "
        }

        if (minutes > 1) {
            ret += padTo2Digits(minutes) + " Minutos"
        } else if (minutes === 1) {
            ret += padTo2Digits(minutes) + " Minuto"
        } else if (hours < 1 && days <= 1) {
            if (seconds > 1) {
                ret += padTo2Digits(seconds) + " Segundos"
            } else if (seconds === 1) {
                ret += padTo2Digits(seconds) + " Segundo"
            }
        }

        return ret;

    }, [])

    useEffect(() => {

        let newMovements = card_movements;
        let daysDueTotal = 0;

        newMovements = newMovements.map((m) => {

            if (m.dt_entry !== undefined) {
                m.dt_entry_string = format(
                    new Date(m.dt_entry),
                    "dd/MM/yyyy"
                );

                m.hr_entry_string = format(
                    new Date(m.dt_entry),
                    "HH:mm"
                );
            }

            if (m.dt_exit !== undefined) {
                m.dt_exit_string = format(
                    new Date(m.dt_exit),
                    "dd/MM/yyyy"
                );

                m.hr_exit_string = format(
                    new Date(m.dt_exit),
                    "HH:mm"
                );
            }

            if (m.dt_exit !== undefined && m.dt_exit !== null) { //If is out of this step
                m.isMovementEnded = true;
                m.days_spent = differenceInDays(new Date(m.dt_exit), new Date(m.dt_entry));
                m.hours_spent = differenceInHours(new Date(m.dt_exit), new Date(m.dt_entry), { roundingMethod: 'round' });
                m.minutes_spent = differenceInMinutes(new Date(m.dt_exit), new Date(m.dt_entry));
                m.duration = convertMsToTime(differenceInMilliseconds(new Date(m.dt_exit), new Date(m.dt_entry)));
            } else {
                m.isMovementEnded = false;
            }

            if (m.flow_step !== undefined && m.flow_step.due_time !== undefined) {
                daysDueTotal += m.flow_step?.due_time
            }

            return m;
        })

        setMovements(newMovements);

        //Create Movement Total
        const firstMovement = newMovements.filter((m: CardMovement) => m.isMovementEnded).sort(function (a, b) {
            return a.id_card_movement - b.id_card_movement;
        })[0];

        const lastMovement = newMovements.filter((m: CardMovement) => m.isMovementEnded).sort(function (a, b) {
            return b.id_card_movement - a.id_card_movement;
        })[0];

        if (firstMovement !== undefined && lastMovement !== undefined) {

            const newMovementTotal: CardMovement = {
                id_card_movement: 999999,
                card_id: firstMovement.card_id,
                flow_step_id: 0,
                user_entry_id: firstMovement.user_entry_id,
                dt_entry: firstMovement.dt_entry,
                user_exit_id: lastMovement.user_exit_id,
                dt_exit: lastMovement.dt_exit,
                user_entry: firstMovement.user_entry,
                user_exit: lastMovement.user_exit,
                due_time: daysDueTotal
            }

            if (newMovementTotal.dt_exit !== undefined) {
                newMovementTotal.days_spent = differenceInDays(new Date(newMovementTotal.dt_exit), new Date(newMovementTotal.dt_entry));
                newMovementTotal.hours_spent = differenceInHours(new Date(newMovementTotal.dt_exit), new Date(newMovementTotal.dt_entry), { roundingMethod: 'round' });
                newMovementTotal.minutes_spent = differenceInMinutes(new Date(newMovementTotal.dt_exit), new Date(newMovementTotal.dt_entry));
                newMovementTotal.duration = convertMsToTime(differenceInMilliseconds(new Date(newMovementTotal.dt_exit), new Date(newMovementTotal.dt_entry)));
                newMovementTotal.isMovementEnded = true;

                if (newMovementTotal.dt_entry !== undefined) {
                    newMovementTotal.dt_entry_string = format(
                        new Date(newMovementTotal.dt_entry),
                        "dd/MM/yyyy"
                    );

                    newMovementTotal.hr_entry_string = format(
                        new Date(newMovementTotal.dt_entry),
                        "HH:mm"
                    );
                }

                if (newMovementTotal.dt_exit !== undefined) {
                    newMovementTotal.dt_exit_string = format(
                        new Date(newMovementTotal.dt_exit),
                        "dd/MM/yyyy"
                    );

                    newMovementTotal.hr_exit_string = format(
                        new Date(newMovementTotal.dt_exit),
                        "HH:mm"
                    );
                }
            }

            setMovementTotal(newMovementTotal);

        }

    }, [card_movements, convertMsToTime]);

    return (

        movements.filter((m) => m.isMovementEnded === true).length > 0 ?
            <Container>
                <BlockInit>
                    <FaFlagCheckered />
                </BlockInit>

                <BlocksContainer>
                    {movements.filter((m) => m.isMovementEnded === true).map((m) => {
                        return (
                            <LightTooltip key={m.id_card_movement} arrow title={
                                <React.Fragment>
                                    <TraceStepContainer>
                                        <TraceStepTitle>
                                            <BlockIcon>
                                                <IconPickerItem
                                                    icon={m.flow_step?.icon as IconList}
                                                    color={"black"}
                                                />
                                                <BlockName style={{ color: "black" }}>{m.flow_step?.name}</BlockName>
                                            </BlockIcon>
                                        </TraceStepTitle>
                                        <TraceStepUser>
                                            <AvatarCange user={m.user_entry} size="40" />
                                            <TraceStepLine />
                                            <AvatarCange user={m.user_exit} size="40" />
                                        </TraceStepUser>
                                        <TraceStepDateTime>
                                            <TraceStepDateTimeEntry>
                                                <TraceStepDateTimeDate>
                                                    {m.dt_entry_string}
                                                </TraceStepDateTimeDate>
                                                <TraceStepDateTimeTime>
                                                    {m.hr_entry_string}
                                                </TraceStepDateTimeTime>
                                            </TraceStepDateTimeEntry>
                                            <TraceStepDateTimeExit>
                                                <TraceStepDateTimeDate>
                                                    {m.dt_exit_string}
                                                </TraceStepDateTimeDate>
                                                <TraceStepDateTimeTime>
                                                    {m.hr_exit_string}
                                                </TraceStepDateTimeTime>
                                            </TraceStepDateTimeExit>
                                        </TraceStepDateTime>
                                        <TraceStepDuration>
                                            {m.duration + " na Etapa"}
                                        </TraceStepDuration>
                                        {m.flow_step !== undefined && m.flow_step.due_time !== null ?
                                            <TraceStepSLA>
                                                Prazo de {m.flow_step?.due_time} dias definido na etapa
                                            </TraceStepSLA> :
                                            <></>
                                        }
                                    </TraceStepContainer>
                                </React.Fragment>
                            }>
                                <Block key={m.id_card_movement} color={m.flow_step?.color} >
                                    <BlockIcon>
                                        <IconPickerItem
                                            icon={m.flow_step?.icon as IconList}
                                            color={"white"}
                                        />
                                    </BlockIcon>
                                    {movements.filter((m) => m.isMovementEnded === true).length <= 3 ?
                                        <BlockName>{m.flow_step?.name}</BlockName> :
                                        <></>
                                    }
                                </Block>
                            </LightTooltip>

                        )
                    })}
                </BlocksContainer>

                <LightTooltip key={movementTotal?.id_card_movement} arrow title={
                    <React.Fragment>
                        {movementTotal !== undefined ?
                            <TraceStepContainer>
                                <TraceStepTitle>
                                    <BlockIcon>
                                        {flow !== undefined ?
                                            <>
                                                <IconPickerItem
                                                    icon={flow.icon as IconList}
                                                    color={"black"}
                                                />
                                                <BlockName style={{ color: "black" }}>{flow.name}</BlockName>
                                            </> :
                                            <></>}
                                    </BlockIcon>
                                </TraceStepTitle>
                                <TraceStepUser>
                                    <AvatarCange user={movementTotal.user_entry} size="40" />
                                    <TraceStepLine />
                                    <AvatarCange user={movementTotal.user_exit} size="40" />
                                </TraceStepUser>
                                <TraceStepDateTime>
                                    <TraceStepDateTimeEntry>
                                        <TraceStepDateTimeDate>
                                            {movementTotal.dt_entry_string}
                                        </TraceStepDateTimeDate>
                                        <TraceStepDateTimeTime>
                                            {movementTotal.hr_entry_string}
                                        </TraceStepDateTimeTime>
                                    </TraceStepDateTimeEntry>
                                    <TraceStepDateTimeExit>
                                        <TraceStepDateTimeDate>
                                            {movementTotal.dt_exit_string}
                                        </TraceStepDateTimeDate>
                                        <TraceStepDateTimeTime>
                                            {movementTotal.hr_exit_string}
                                        </TraceStepDateTimeTime>
                                    </TraceStepDateTimeExit>
                                </TraceStepDateTime>
                                <TraceStepDuration>
                                    {movementTotal.duration + " no Fluxo"}
                                </TraceStepDuration>
                            </TraceStepContainer> :
                            <></>}
                    </React.Fragment>
                }>
                    {current_step !== undefined && current_step.isEndStep === "1" ?
                        <BlockEnd color="#61bd4f">
                            <FaCheck />
                        </BlockEnd> :
                        <BlockEnd>
                            <FaRegClock />
                        </BlockEnd>
                    }
                </LightTooltip>
            </Container> :
            <></>
    );

}

export default CardMovementLine;