import styled, { css } from "styled-components";

interface CLDescriptionProps {
    checked?: boolean;
}

interface ProgressBarTaskLineInsideProps {
    width?: number;
}

export const Container = styled.div`
    padding: 10px;
    padding-top: 0px;
`;

export const ContainerChecklist = styled.div`
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #eef2f8;

    :hover {
        .cl-total {
            display: none;
        }

        .cl-btn-delete {
            display: flex;
        }
    }
`;

export const ChecklistTitleContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative; 
`;

export const ChecklistTitle = styled.input`
    font-weight: 500;
    color: black;
    margin-left: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;    
    background-color: white;
    width: calc(100% - 50px);
    padding: 3px;
    padding-left: 6px;
    border: none;

    :focus {
        border: 1px solid #9e37ed;
        border-radius: 5px;
        padding: 2px;
        padding-left: 5px;
    }
`;

export const ChecklistTotal = styled.div`
    position: absolute;
    right: 0px;
    font-size: 10px;
    margin-right: 5px;
    background-color: #f6ebfd;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 500;
`;

export const DividerGroup = styled.div`
    border: 1px solid #eef2f8;
    width: 100%;
    margin-top: 10px;
`;

export const TagOrigin = styled.span`
    font-size: 10px;
    margin-right: 5px;
    background-color: #f6ebfd;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 400;
    text-transform: uppercase;
    color: black;
    margin-left: 5px;
`;

export const ChecklistBody = styled.div`
    padding-right: 5px;
    padding-left: 5px;
`;

export const CleanBox = styled.div`
    display: grid;
    justify-items: center;
    align-content: center;
    padding: 10px;
    margin: 10px;
    margin-top: 20px;
    border-radius: 10px;
    border: 1px dashed #80808059;
    min-height: 250px;
    font-size: 15px;
    color: gray;

    svg {
        font-size: 40px;
        margin-bottom: 10px;
    }
`;

export const CleanBoxText = styled.div`
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 13px;
    margin-top: 2px;
`;

export const ContainerButton = styled.div`
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #eef2f8;
`;

export const ChecklistButton = styled.button`
    font-weight: 500;
    padding: 3px 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #9e37ed;
    border: none;
    background-color: white;
    border-radius: 10px;

    svg {
        margin-right: 5px;
    }

    :hover {
        background-color: #9e37ed;
        color: white;        
    }
`;

export const CLCheckbox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    cursor: pointer;
`;

export const CLDescriptionContainer = styled.div`
  flex: 1;
  input {
    border: none;
    outline: none;   
  }
`;


export const CLDeleteButton = styled.button`
    display: none;
    border: none;
    background: transparent;
    color: #9e37ed;
    margin-left: 5px;
    transition: transform 0.3s ease;

    :hover {
        transform: scale(1.2);
    }
`;

export const CLDescription = styled.input<CLDescriptionProps>`
    color: black;
    margin-left: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;    
    background-color: white;
    width: 100%;
    padding: 8px;
    padding-left: 6px;
    border: none;

    :hover {
        border: 1px solid #f6ebfd;
        border-radius: 5px;
        padding: 7px;
        padding-left: 5px;
        width: calc(100% - 10px);
    }

    :focus {
        border: 1px solid #9e37ed;
        border-radius: 5px;
        padding: 7px;
        padding-left: 5px;
        width: calc(100% - 10px);
    }

    ${props => props.checked && css`
        text-decoration: line-through;
        color: #808080;
    `}
`;

export const CLItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: grab;

    :hover {
        .btn-delete-cl-item {
            display: flex;
        }
    }

    .input-description-cl-item {       

        :focus {
            .btn-delete-cl-item  {
                display: flex;
            }
        }
    }
`;

export const ProgressBarTask = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ProgressBarTaskLine = styled.div`
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ededed;
    margin-right: 10px;
    margin-left: 5px;
`;

export const ProgressBarTaskLineInside = styled.div<ProgressBarTaskLineInsideProps>`
    height: 10px;
    min-height: 10px;
    width: 100%;
    background: rgb(255,140,47);
    background: linear-gradient(90deg, rgba(255,140,47,1) 25%, rgba(251,50,92,1) 50%, rgba(147,55,237,1) 75%);

    width: 0%;
    border-radius: 10px;

    ${(props) => props.width && css`
        width: ${props.width + "%"};
    `}
`;

export const ProgressBarTaskPercent = styled.div`
    font-weight: 500;
    margin-right: 10px;
`;

export const ButtonAddCheckListItem = styled.button`
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1.5px;
    color: #9e37ed;
    padding: 5px 7px;
    border-radius: 5px;

    svg {
        margin-right: 5px;
    }

    :hover {
        background-color: #f6edfd;
    }
`;

export const GroupLoading = styled.div`
    padding: 10px;
`;

export const CheckListDeleteButton = styled.button`
    color: #9e37ed;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    font-size: 17px;
    padding: 3px 8px;
    border: none;
    background-color: transparent;
    transition: transform 0.3s ease;

    :hover {
        transform: scale(1.2);
    }
`;