import React, { useEffect } from "react";
import { ResponsiveLine } from "@nivo/line";
import { colorsElement } from "../../getColorsElement";
import { DataProps } from "../..";
import getChartFormatValue from "../../getChartFormatValue";

interface LineChartProps {
  dataElement?: DataProps[];
  show_time?: string;
  typeField?: string;
  axis_y_field_id?: number;
  axis_x_field_id?: number;
  axis_y_field_type?: string;
  axis_y_field_variation?: string;
  axis_x_field_type?: string;
  color?: string;
}

export interface DataPropsLine {
  id: string;
  color?: string;
  data: {
    x: string;
    y: number;
  }[];
  labelAux?: string;
}

const dataDefault = [
  {
    id: "Valor 1",
    data: [
      {
        x: "10/12",
        y: 12
      },
      {
        x: "11/12",
        y: 15
      },
      {
        x: "12/12",
        y: 5
      },
      {
        x: "13/12",
        y: 18
      }
    ]
  },
  {
    id: "Valor 2",
    data: [
      {
        x: "10/12",
        y: 15
      },
      {
        x: "11/12",
        y: 1
      },
      {
        x: "12/12",
        y: 5
      },
      {
        x: "13/12",
        y: 18
      }
    ]
  }
];

function transformData(input: DataProps[], color?: string): DataPropsLine[] {

  /* Input Type 
    export interface DataProps {
        id: string | number;
        value: number;
        label: string;
        labelAux?: string;
    }
  */

  /* Output Type
    export interface DataPropsLine {
      id: string;
      data: {
        x: string;
        y: number;
      }[];
      labelAux?: string;
    }
  */

  //Transform the data from a type to another, all the data is dynamic, follow the types above
  let data: DataPropsLine[] = [
    {
      id: "Valor",
      color: color,
      data: []
    }
  ];

  //Run into input to create a new data structure
  input.map((d) => {

    //Create a new data structure
    data[0].data.push({
      x: d.label,
      y: d.value
    });

    return d;
  });

  return data;

}

const LineChart: React.FC<LineChartProps> = ({ dataElement, show_time, axis_y_field_id, color, axis_y_field_type, axis_y_field_variation }) => {

  const [data, setData] = React.useState<DataPropsLine[]>(transformData(dataElement !== undefined ? dataElement : []));

  useEffect(() => {

    if (dataElement !== undefined && dataElement !== null && dataElement.length > 0) {

      const data = transformData(dataElement, color);

      setData(data);
    }

  }, [dataElement, color]);

  return (
    <>      
      <ResponsiveLine
        data={data !== undefined ? data : dataDefault}
        //Static Down
        margin={{ top: 25, right: 30, bottom: 30, left: 50 }}
        yScale={{
          type: "linear",
          min: 0,
          max: "auto",
          stacked: true,
          reverse: false,          
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: -40,
          format: value => {
            return getChartFormatValue(value, axis_y_field_type, 0, axis_y_field_variation);
          },
        }}
        axisBottom={{
          tickPadding: 10,
          tickSize: 0
        }}        
        pointLabelYOffset={-15}
        enablePointLabel={true}
        areaBlendMode="normal"
        pointLabel={d => {
          return getChartFormatValue(Number(d.y), axis_y_field_type, 2, axis_y_field_variation);
        }}
        tooltipFormat={value => {
          return getChartFormatValue(Number(value), axis_y_field_type, 2, axis_y_field_variation);
        }}
        tooltip={({ point }) => {
          return (
            <div
              style={{
                padding: 12,
                borderRadius: 5,
                fontSize: 13,
                background: '#222222',
                color: '#ffffff'
              }}
            >
              <div style={{ marginBottom: '10px', fontSize: '13px', display: 'flex', justifyContent: 'center' }}>
                <strong>{point.data.xFormatted}</strong>
              </div>
              <div>
                {getChartFormatValue(Number(point.data.yFormatted), axis_y_field_type, 2, axis_y_field_variation)}
              </div>
            </div>
          )
        }}
        yFormat=" >-.2f"
        curve="linear"
        enableArea={true}
        areaOpacity={0.4}
        colors={colorsElement}
        useMesh={true}
        lineWidth={3}
        pointSize={10}
        pointColor={"#ffffff"}
        pointBorderWidth={3}
        pointBorderColor={{ from: "serieColor" }}
        animate={true}
        motionConfig={"gentle"}
      />
    </>
  );

}

export default LineChart;