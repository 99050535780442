import { OrderConditionItem } from "../components/FilterBar/FilterDialog";
import { FormAnswer } from "../interfaces/FormAnswer";
import { FormAnswerField } from "../interfaces/FormAnswerField";

export default function getFormAnswerSorted(formAnswers: FormAnswer[], orders?: OrderConditionItem[]): FormAnswer[] {

    let newFormAnswers = formAnswers;

    //Order component
    if (orders !== undefined && orders.length > 0) {

        //Reverse the orders order
        const newOrders = orders.slice().reverse();

        for (const condition of newOrders) {

            const { selectedField, selectedOrder } = condition;

            //Get the direction side
            const direction = getOrderByDirection(selectedOrder);

            newFormAnswers = newFormAnswers.sort((a, b) => {

                if (selectedField.id_field < 0) { //Is standart field 

                    if (selectedField.id_field === -1) { // Criado em

                        const checkA: string | undefined = a.dt_created !== undefined ? a.dt_created : undefined;
                        const checkB: string | undefined = b.dt_created !== undefined ? b.dt_created : undefined;

                        if (checkA && checkB) {
                            const dateA = new Date(checkA).getTime();
                            const dateB = new Date(checkB).getTime();

                            if (direction === 1) {
                                return dateA - dateB;
                            } else {
                                return dateB - dateA;
                            }
                        }

                        if (!checkA && checkB) {
                            return 1 * direction;
                        }

                        if (checkA && !checkB) {
                            return -1 * direction;
                        }

                    } else if (selectedField.id_field === -2) { // Criado por

                        const checkA: string | undefined = a.user_created !== undefined && a.user_created !== undefined ? a.user_created : undefined;
                        const checkB: string | undefined = b.user_created !== undefined && b.user_created !== undefined ? b.user_created : undefined;

                        if (checkA !== undefined && checkB !== undefined) {
                            if (checkA < checkB) {
                                return -1 * direction;
                            }
                            if (checkA > checkB) {
                                return 1 * direction;
                            }
                        } else if (checkA !== undefined) {
                            return 1 * direction;
                        } else if (checkB !== undefined) {
                            return 1 * direction;
                        }

                    } else if (selectedField.id_field === -3) { // Identificador

                        const checkA: number | undefined = a.id_form_answer !== undefined && a.id_form_answer !== undefined ? a.id_form_answer : undefined;
                        const checkB: number | undefined = b.id_form_answer !== undefined && b.id_form_answer !== undefined ? b.id_form_answer : undefined;

                        if (checkA !== undefined && checkB !== undefined) {
                            if (checkA < checkB) {
                                return -1 * direction;
                            }
                            if (checkA > checkB) {
                                return 1 * direction;
                            }
                        } else if (checkA !== undefined) {
                            return 1 * direction;
                        } else if (checkB !== undefined) {
                            return 1 * direction;
                        }

                    }

                } else { //Is custom field

                    let checkA: FormAnswerField | undefined = undefined;
                    let checkB: FormAnswerField | undefined = undefined;

                    //Card A
                    const faCheckA = a;
                    if (faCheckA !== undefined) {
                        checkA = faCheckA.form_answer_fields.filter((faf) => faf.field_id === condition.selectedField.id_field)[0];
                    }

                    //Card B
                    const faCheckB = b;
                    if (faCheckB !== undefined) {
                        checkB = faCheckB.form_answer_fields.filter((faf) => faf.field_id === condition.selectedField.id_field)[0];
                    }

                    if (selectedOrder === "A → Z" || selectedOrder === "Z → A") {

                        if (checkA?.valueString !== undefined && checkB?.valueString !== undefined) {
                            if (checkA.valueString < checkB.valueString) {
                                return -1 * direction;
                            }
                            if (checkA.valueString > checkB.valueString) {
                                return 1 * direction;
                            }
                        } else if (checkA !== undefined) {
                            return 1 * direction;
                        } else if (checkB !== undefined) {
                            return 1 * direction;
                        }

                    } else if (selectedOrder === "Primeiro → Último" || selectedOrder === "Último → Primeiro") {

                        if (checkA?.valueString !== undefined && checkB?.valueString !== undefined) {
                            if (checkA.valueString < checkB.valueString) {
                                return -1 * direction;
                            }
                            if (checkA.valueString > checkB.valueString) {
                                return 1 * direction;
                            }
                        } else if (checkA !== undefined) {
                            return 1 * direction;
                        } else if (checkB !== undefined) {
                            return 1 * direction;
                        }

                    } else if (selectedOrder === "1 → 9" || selectedOrder === "9 → 1") {

                        if (checkA?.value !== undefined && checkB?.value !== undefined) {
                            if (selectedField.type === "CURRENCY_FIELD" || selectedField.type === "NUMBER_FIELD") {
                                if (Number(checkA.value) < Number(checkB.value)) {
                                    return -1 * direction;
                                }
                                if (Number(checkA.value) > Number(checkB.value)) {
                                    return 1 * direction;
                                }
                            } else {
                                if (checkA.value < checkB.value) {
                                    return -1 * direction;
                                }
                                if (checkA.value > checkB.value) {
                                    return 1 * direction;
                                }
                            }
                        } else if (checkA !== undefined) {
                            if (selectedField.type === "CURRENCY_FIELD" || selectedField.type === "NUMBER_FIELD") {
                                return 1 * direction;
                            } else {
                                return -1 * direction;
                            }
                        } else if (checkB !== undefined) {
                            if (selectedField.type === "CURRENCY_FIELD" || selectedField.type === "NUMBER_FIELD") {
                                return -1 * direction;
                            } else {
                                return 1 * direction;
                            }
                        } else {
                            return -1 * direction;
                        }

                    } else if (selectedOrder === "Verdadeiro → Falso" || selectedOrder === "Falso → Verdadeiro") {

                        if (checkA?.value !== undefined && checkB?.value !== undefined) {
                            if (checkA.value < checkB.value) {
                                return -1 * direction;
                            }
                            if (checkA.value > checkB.value) {
                                return 1 * direction;
                            }
                        } else if (checkA !== undefined) {
                            return 1 * direction;
                        } else if (checkB !== undefined) {
                            return 1 * direction;
                        }

                    }

                }

                return 0; // Se todos os campos forem iguais, mantém a ordem original

            });
        }

    } else { //When no have a order setted

        newFormAnswers = sortByPreviousNext(newFormAnswers);

    }

    return newFormAnswers;
}

const sortByPreviousNext = (formAnswers: FormAnswer[]): FormAnswer[] => {

    const compareByDtCreated = (a: FormAnswer, b: FormAnswer): number => {
        const dateA = new Date(a.dt_created).getTime();
        const dateB = new Date(b.dt_created).getTime();

        return dateA - dateB;
    };

    const sortedCards = [...formAnswers];

    sortedCards.sort(compareByDtCreated);

    return sortedCards;
};

function getOrderByDirection(selectedOrder: string): number {

    //Type 1 - "A → Z" ou "Z → A"
    if (selectedOrder === "A → Z") {
        return 1;
    } else if (selectedOrder === "Z → A") {
        return -1;
    }

    //Type 2 - "Primeiro → Último" ou "Último → Primeiro"
    if (selectedOrder === "Primeiro → Último") {
        return 1;
    } else if (selectedOrder === "Último → Primeiro") {
        return -1;
    }

    //Type 3 - "1 → 9" ou "9 → 1"
    if (selectedOrder === "1 → 9") {
        return 1;
    } else if (selectedOrder === "9 → 1") {
        return -1;
    }

    //Type 4 - "Verdadeiro → Falso" ou "Falso → Verdadeiro"
    if (selectedOrder === "Verdadeiro → Falso") {
        return 1;
    } else if (selectedOrder === "Falso → Verdadeiro") {
        return -1;
    }

    return 1;
}
