import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    Container
} from "./style";
import { useField } from "@unform/core";
import { FaLink } from "react-icons/fa";
import ErrorDescription from "../../../../ErrorDescription";

interface LinkProps {
    name: string;
}

const Link: React.FC<LinkProps> = ({ name }) => {

    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const { fieldName, defaultValue, error, registerField } = useField(name);
    const [valueText, setValueText] = useState<string>(defaultValue !== undefined ? defaultValue : "");

    const handleInputFocused = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: () => {
                return valueText;
            },
            setValue: (refs, value) => { //Não remover o ref

                if (value !== null) {
                    setValueText(value);
                } else {
                    setValueText("");
                }
            },
            clearValue: () => {
                setValueText("");
            }
        });

    }, [fieldName, registerField, valueText]);

    return (
        <>
            <Container className="container-input" isErrored={!!error} isFocused={isFocused} isDisabled={false} >
                <FaLink />
                <input
                    onFocus={handleInputFocused}
                    onBlur={handleInputBlur}
                    onChange={(e) => setValueText(e.currentTarget.value)}
                    value={valueText}
                    defaultValue={defaultValue}
                    ref={inputRef}
                    name={name}
                />

            </Container>

            {error && (<ErrorDescription title={error} />)}
        </>
    );

}

export default Link;