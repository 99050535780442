import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px;
    margin-top: 20px;
    overflow-x: auto;
`;

export const CardUser = styled.div`
    max-width: 130px;
    max-height: 130px;
    min-height: 130px;
    min-width: 130px;
    margin-right: 15px;
    padding: 15px;
    display: block;
    justify-content: center;
    align-items: center;
    border: 1px solid #f4f4f4;
    border-radius: 10px;
`;

export const CardUserInvited = styled.div`
    max-width: 130px;
    max-height: 130px;
    min-height: 130px;
    min-width: 130px;
    margin-right: 15px;
    padding: 15px;
    display: grid;
    justify-content: center;
    align-items: center;
    border: 1px dashed #f4f4f4;
    border-radius: 10px;
`;

export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const NameContainer = styled.div`
    color: #000000d9;
    font-size: 13px;
    text-align: center; 
    margin-top: 14px;
    height: auto;
    overflow-wrap: break-word;
`;

export const CardUserEmpty = styled.div`
    max-width: 130px;
    max-height: 130px;
    min-height: 130px;
    min-width: 130px;
    margin-right: 15px;
    padding: 15px;
    display: block;
    justify-content: center;
    align-items: center;
    border: 1px dashed #4681b8;
    border-radius: 10px;

    :hover {
        border: 1px solid #f23c5d;
        div {
            div {                
                background-color: #f23c5d;
                border-color: white;

                svg {
                    color: white;
                }
            }                                
        }

        button {
            color: #f23c5d;
        }
    }
`;

export const DescriptionInvite = styled.div`

`;

export const BtnInvite = styled.button`
    margin-top: 15px;
    border: none;
    background: transparent;
    font-size: 14px;
    color: #4681b8;
`;

export const ImageContainerEmpty = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ImageEmpty = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border: 1px dashed #4681b8;
    border-radius: 20px;

    svg {
        color: #4681b8;
        width: 20px;
        height: 20px;
    }
`;

export const BadgeInvited = styled.span`
    text-align: center;
    font-size: 10px;
    background-color: gray;
    padding: 1px 8px 1px 8px;
    margin-top: 10px;
    color: white;
    font-weight: 500;
    border-radius: 15px;
`;
