import React from "react";

import {
    Container,
    MainList
} from "./style";

interface ListProps {
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

const List: React.FC<ListProps> = ({ children, style }) => {

    return (
        <Container className="list-container">
            <MainList style={style}>
                {children}
            </MainList>
        </Container>
    );

}

export default List;