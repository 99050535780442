import React from "react";
import { AiOutlineFunnelPlot, AiOutlineNumber } from "react-icons/ai";
import { BsPieChartFill } from "react-icons/bs";
import { FaCode, FaTable } from "react-icons/fa";

import ColumnChart from "./Elements/ColumnChart";
import Embed from "./Elements/Embed";
import FunnelChart from "./Elements/FunnelChart";
import LineChart from "./Elements/LineChart";
import NumberChart from "./Elements/NumberChart";
import PieChart from "./Elements/PieChart";
import {
    ElementItem,
    ElementItemIcon,
    ElementItemTitle
} from "./style";
import { RiBarChartFill, RiBarChartHorizontalFill, RiBubbleChartFill } from "react-icons/ri";
import { MdOutlineStackedBarChart, MdShowChart, MdSsidChart } from "react-icons/md";
import LineChartStacked from "./Elements/LineChartStacked";
import HeatMapChart from "./Elements/HeatMapChart";
import ColumnStackedChart from "./Elements/ColumnStackedChart";
import { TbChartDonut3, TbChartPieFilled } from "react-icons/tb";

//Object
export interface ElementProps {
    id_panel_element?: number;
    hash: string;
    panel_id?: number;
    title?: string;
    help_text?: string;
    type: string;
    pos_axis_x?: number;
    pos_axis_y?: number;
    width: number;
    height: number;
    axis_x_field_id?: number;
    axis_x_field_type?: string;
    axis_y_field_id?: number;
    axis_y_field_type?: string;
    axis_y_field_variation?: string;
    group_by_field_id?: number;
    group_by_field_type?: string;
    group_by_field_date?: string;
    calc_function?: string;
    show_time?: string;
    group_date?: string;
    color?: string;
    sort_by?: string;
    filter?: string;
    embed?: string;
    dataElement?: DataProps[];

    //If add here, needs add to at ElementConfig line 139
    //If add here, needs add to at Dashboard line 261
}

export interface DataProps {
    id: string | number;
    value: number;
    label: string;
    labelAux?: string;
}

export interface DataElementProps {
    [key: string]: string | number;
    id: string | number;
    value: number;
    label: string;
}

interface CardElementProps {
    type: string,
    rotationIcon?: number,
    onSelectElement: (type: string) => void;
}

/* Components */
const ColumnChartElement: React.FC<ElementProps> = ({ dataElement, show_time, color, axis_y_field_id, axis_x_field_id, axis_x_field_type, axis_y_field_type, axis_y_field_variation }) => {
    return <ColumnChart dataElement={dataElement} show_time={show_time} type="vertical" color={color} axis_y_field_id={axis_y_field_id} axis_x_field_id={axis_x_field_id} axis_x_field_type={axis_x_field_type} axis_y_field_type={axis_y_field_type} axis_y_field_variation={axis_y_field_variation} />;
};
const ColumnStackedChartElement: React.FC<ElementProps> = ({ dataElement, show_time, color, axis_y_field_id, axis_x_field_id, axis_x_field_type, axis_y_field_type, axis_y_field_variation }) => {
    return <ColumnStackedChart dataElement={dataElement} show_time={show_time} type="vertical" color={color} axis_y_field_id={axis_y_field_id} axis_x_field_id={axis_x_field_id} axis_x_field_type={axis_x_field_type} axis_y_field_type={axis_y_field_type} axis_y_field_variation={axis_y_field_variation} />;
};
const BarChartElement: React.FC<ElementProps> = ({ dataElement, show_time, color, axis_y_field_id, axis_x_field_id, axis_x_field_type, axis_y_field_type, axis_y_field_variation }) => {
    return <ColumnChart dataElement={dataElement} show_time={show_time} type="horizontal" color={color} axis_y_field_id={axis_y_field_id} axis_x_field_id={axis_x_field_id} axis_x_field_type={axis_x_field_type} axis_y_field_type={axis_y_field_type} axis_y_field_variation={axis_y_field_variation} />;
};
const LineChartElement: React.FC<ElementProps> = ({ dataElement, show_time, axis_y_field_id, color, axis_y_field_type, axis_y_field_variation }) => {
    return <LineChart dataElement={dataElement} show_time={show_time} axis_y_field_id={axis_y_field_id} color={color} axis_y_field_type={axis_y_field_type} axis_y_field_variation={axis_y_field_variation} />;
};
const LineChartStackedElement: React.FC<ElementProps> = ({ dataElement, show_time, axis_y_field_id, color, axis_y_field_type, axis_y_field_variation }) => {
    return <LineChartStacked dataElement={dataElement} show_time={show_time} axis_y_field_id={axis_y_field_id} color={color} axis_y_field_type={axis_y_field_type} axis_y_field_variation={axis_y_field_variation} />;
};
const HeatMapChartElement: React.FC<ElementProps> = ({ dataElement, show_time, axis_y_field_id, color, axis_y_field_type, axis_y_field_variation }) => {
    return <HeatMapChart dataElement={dataElement} show_time={show_time} axis_y_field_id={axis_y_field_id} color={color} axis_y_field_type={axis_y_field_type} axis_y_field_variation={axis_y_field_variation} />;
};
const PieChartElement: React.FC<ElementProps> = ({ dataElement, show_time, axis_y_field_id, axis_x_field_id, axis_x_field_type, axis_y_field_type, axis_y_field_variation }) => {
    return <PieChart type="pie" dataElement={dataElement} show_time={show_time} axis_y_field_id={axis_y_field_id} axis_x_field_id={axis_x_field_id} axis_x_field_type={axis_x_field_type} axis_y_field_type={axis_y_field_type} axis_y_field_variation={axis_y_field_variation} />;
};
const DonutChartElement: React.FC<ElementProps> = ({ dataElement, show_time, axis_y_field_id, axis_x_field_id, axis_x_field_type, axis_y_field_type, axis_y_field_variation }) => {
    return <PieChart type='donut' dataElement={dataElement} show_time={show_time} axis_y_field_id={axis_y_field_id} axis_x_field_id={axis_x_field_id} axis_x_field_type={axis_x_field_type} axis_y_field_type={axis_y_field_type} axis_y_field_variation={axis_y_field_variation} />;
};
const QuarterChartElement: React.FC<ElementProps> = ({ dataElement, show_time, axis_y_field_id, axis_x_field_id, axis_x_field_type, axis_y_field_type, axis_y_field_variation }) => {
    return <PieChart type='quarter' dataElement={dataElement} show_time={show_time} axis_y_field_id={axis_y_field_id} axis_x_field_id={axis_x_field_id} axis_x_field_type={axis_x_field_type} axis_y_field_type={axis_y_field_type} axis_y_field_variation={axis_y_field_variation} />;
};
const FunnelChartElement: React.FC<ElementProps> = ({ dataElement, show_time, axis_y_field_id, axis_x_field_id, axis_x_field_type, axis_y_field_type, axis_y_field_variation }) => {
    return <FunnelChart type="horizontal" dataElement={dataElement} show_time={show_time} axis_y_field_id={axis_y_field_id} axis_x_field_id={axis_x_field_id} axis_x_field_type={axis_x_field_type} axis_y_field_type={axis_y_field_type} axis_y_field_variation={axis_y_field_variation} />;
};
const FunnelChartElementVertical: React.FC<ElementProps> = ({ dataElement, show_time, axis_y_field_id, axis_x_field_id, axis_x_field_type, axis_y_field_type, axis_y_field_variation }) => {
    return <FunnelChart type="vertical" dataElement={dataElement} show_time={show_time} axis_y_field_id={axis_y_field_id} axis_x_field_id={axis_x_field_id} axis_x_field_type={axis_x_field_type} axis_y_field_type={axis_y_field_type} axis_y_field_variation={axis_y_field_variation} />;
};
const NumberChartElement: React.FC<ElementProps> = ({ dataElement, color, show_time, axis_y_field_id, axis_y_field_type, axis_y_field_variation }) => {
    return <NumberChart dataElement={dataElement} color={color} show_time={show_time} axis_y_field_id={axis_y_field_id} axis_y_field_type={axis_y_field_type} axis_y_field_variation={axis_y_field_variation} />;
};
const SimpleTableElement: React.FC<ElementProps> = () => {
    return <div>SimpleTableElement</div>;
};
const EmbedElement: React.FC<ElementProps> = ({ embed }) => {
    return <Embed embed={embed} />;
};

/* Relationship */
export const Elements = {
    COLUMN_CHART: "COLUMN_CHART",
    COLUMN_STACKED_CHART: "COLUMN_STACKED_CHART",
    BAR_CHART: "BAR_CHART",
    LINE_CHART: "LINE_CHART",
    LINE_CHART_STACKED: "LINE_CHART_STACKED",
    PIE_CHART: "PIE_CHART",
    DONUT_CHART: "DONUT_CHART",
    QUARTER_CHART: "QUARTER_CHART",
    FUNNEL_CHART: "FUNNEL_CHART",
    FUNNEL_CHART_VERTICAL: "FUNNEL_CHART_VERTICAL",
    NUMBER_CHART: "NUMBER_CHART",
    SIMPLE_TABLE: "SIMPLE_TABLE",
    EMBED: "EMBED",
    HEAT_MAP_CHART: "HEAT_MAP_CHART",
};

export const CardElement: React.FC<CardElementProps> = ({ type, rotationIcon, onSelectElement }) => {

    const objElement = getElementObject(type);
    const Icon = objElement.icon;

    return (
        <ElementItem
            onClick={() => onSelectElement(objElement.type)}
            color={objElement.color}
        >
            <ElementItemIcon rotation={rotationIcon}>
                <Icon />
            </ElementItemIcon>
            <ElementItemTitle>
                {objElement.title}
            </ElementItemTitle>
        </ElementItem>
    );
};

/* Object Element ElementTypeProps */
export const getElementObject = (type: string) => {
    const relationFields = {
        [Elements.COLUMN_CHART]: {
            type: "COLUMN_CHART",
            icon: RiBarChartFill,
            title: "Gráfico de coluna",
            color: "#009688",
            element: ColumnChartElement,
            minW: 3,
            minH: 2,
            defW: 6,
            defH: 2,
            isResizable: true,
        },
        [Elements.COLUMN_STACKED_CHART]: {
            type: "COLUMN_STACKED_CHART",
            icon: MdOutlineStackedBarChart,
            title: "Gráfico de coluna empilhada",
            color: "#009688",
            element: ColumnChartElement,
            minW: 3,
            minH: 2,
            defW: 6,
            defH: 2,
            isResizable: true,
        },
        [Elements.BAR_CHART]: {
            type: "BAR_CHART",
            icon: RiBarChartHorizontalFill,
            title: "Gráfico de barras",
            color: "#009688",
            element: BarChartElement,
            minW: 3,
            minH: 2,
            defW: 6,
            defH: 2,
            isResizable: true,
        },
        [Elements.LINE_CHART]: {
            type: "LINE_CHART",
            icon: MdShowChart,
            title: "Gráfico de linha",
            color: "#f44336",
            element: LineChartElement,
            minW: 3,
            minH: 2,
            defW: 6,
            defH: 2,
            isResizable: true,
        },
        [Elements.LINE_CHART_STACKED]: {
            type: "LINE_CHART_STACKED",
            icon: MdSsidChart,
            title: "Gráfico de linha empilhada",
            color: "#f44336",
            element: LineChartElement,
            minW: 3,
            minH: 2,
            defW: 6,
            defH: 2,
            isResizable: true,
        },
        [Elements.PIE_CHART]: {
            type: "PIE_CHART",
            icon: BsPieChartFill,
            title: "Gráfico de pizza",
            color: "#00bcd4",
            element: PieChartElement,
            minW: 3,
            minH: 2,
            defW: 6,
            defH: 2,
            isResizable: true,
        },
        [Elements.DONUT_CHART]: {
            type: "DONUT_CHART",
            icon: TbChartDonut3,
            title: "Gráfico de donut",
            color: "#00bcd4",
            element: DonutChartElement,
            minW: 3,
            minH: 2,
            defW: 6,
            defH: 2,
            isResizable: true,
        },
        [Elements.QUARTER_CHART]: {
            type: "QUARTER_CHART",
            icon: TbChartPieFilled,
            title: "Gráfico de quarto",
            color: "#00bcd4",
            element: QuarterChartElement,
            minW: 3,
            minH: 2,
            defW: 4,
            defH: 2,
            isResizable: true,
        },
        [Elements.FUNNEL_CHART]: {
            type: "FUNNEL_CHART",
            icon: AiOutlineFunnelPlot,
            title: "Funil horizontal",
            color: "#9d37ed",
            element: FunnelChartElement,
            minW: 3,
            minH: 1,
            defW: 6,
            defH: 2,
            isResizable: true,
        },
        [Elements.HEAT_MAP_CHART]: {
            type: "HEAT_MAP_CHART",
            icon: RiBubbleChartFill,
            title: "Mapa de calor",
            color: "#9d37ed",
            element: HeatMapChartElement,
            minW: 3,
            minH: 2,
            defW: 6,
            defH: 2,
            isResizable: true,
        },
        [Elements.FUNNEL_CHART_VERTICAL]: {
            type: "FUNNEL_CHART_VERTICAL",
            icon: AiOutlineFunnelPlot,
            title: "Funil vertical",
            color: "#9d37ed",
            element: FunnelChartElementVertical,
            minW: 3,
            minH: 1,
            defW: 4,
            defH: 3,
            isResizable: true,
        },
        [Elements.NUMBER_CHART]: {
            type: "NUMBER_CHART",
            icon: AiOutlineNumber,
            title: "Indicador",
            color: "#ff9800",
            element: NumberChartElement,
            minW: 1,
            minH: 1,
            defW: 3,
            defH: 1,
            isResizable: true,
        },
        [Elements.SIMPLE_TABLE]: {
            type: "SIMPLE_TABLE",
            icon: FaTable,
            title: "Tabela Simples",
            color: "#fe8c2f",
            element: SimpleTableElement,
            minW: 6,
            minH: 2,
            defW: 6,
            defH: 2,
            isResizable: true,
        },
        [Elements.EMBED]: {
            type: "EMBED",
            icon: FaCode,
            title: "Embed (Incorporar)",
            color: "#795548",
            element: EmbedElement,
            minW: 6,
            minH: 2,
            defW: 6,
            defH: 2,
            isResizable: true,
        }

    }

    return relationFields[type];
};

export const getElementComponent = (type: string) => {
    const relationElement = {
        [Elements.COLUMN_CHART]: ColumnChartElement,
        [Elements.COLUMN_STACKED_CHART]: ColumnStackedChartElement,
        [Elements.BAR_CHART]: BarChartElement,
        [Elements.LINE_CHART]: LineChartElement,
        [Elements.LINE_CHART_STACKED]: LineChartStackedElement,
        [Elements.PIE_CHART]: PieChartElement,
        [Elements.DONUT_CHART]: DonutChartElement,
        [Elements.QUARTER_CHART]: QuarterChartElement,
        [Elements.FUNNEL_CHART]: FunnelChartElement,
        [Elements.FUNNEL_CHART_VERTICAL]: FunnelChartElementVertical,
        [Elements.NUMBER_CHART]: NumberChartElement,
        [Elements.SIMPLE_TABLE]: SimpleTableElement,
        [Elements.EMBED]: EmbedElement,
        [Elements.HEAT_MAP_CHART]: HeatMapChartElement,
    };

    return relationElement[type];
};
