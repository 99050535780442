import React from 'react';
import {
    Redirect,
    Route as ReactDOMRoute,
    RouteProps as ReactDOMRouteProps
} from 'react-router-dom';

import { useAuth } from '../hooks/auth'

interface RouteProps extends ReactDOMRouteProps {
    isPrivate?: boolean;
    isBothContext?: boolean;
    title?: string;
    exceptType?: string[];
    component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
    isPrivate = false,
    isBothContext = false,
    exceptType = [],
    title,
    component: Component,
    ...rest
}) => {

    const { user } = useAuth();

    return (
        <ReactDOMRoute
            {...rest}
            render={({ location }) => {
                return isPrivate === !!user && (!exceptType.includes(user !== undefined && user.type !== undefined ? user.type : "")) ? (
                    <Component />
                ) : (
                    isBothContext ? <Component /> :
                        <Redirect
                            to={{
                                pathname: isPrivate ?
                                    '/' : '/dashboard',
                                state: { from: location }
                            }}
                        />
                );
            }}
        />
    );

};

export default Route;