import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import styled, { css } from "styled-components";
import Button from '../../components/Button';
import { Dialog } from '@material-ui/core';

interface IconHeaderProps {
    color: string;
}

interface BtnInsertNewAnswerProps {
    color: string;
}

export const DialogContainer = styled(Dialog)`

    .MuiPaper-root {
        border-radius: 10px;
    }

`;

export const Content = styled(DialogContent)`
    background-color: white;   
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: center;
    height: auto;
    width: auto;
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const DialogTop = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #eef2f8;
    margin-top: 15px;
    width: 100%;
    
`;

export const DialogTopLeft = styled.div`
    width: 65%;
    height: 100%;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: start;
    padding-bottom: 3px;
    padding-top: 3px;
    margin-left: 5px;

    h1 {
        margin-left: 20px;
        font-size: 20px;
        font-weight: 500;
        color: #060606de;
    }
`;

export const DialogTopCenter = styled.div`
    width: 25%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
`;

export const DialogTopRight = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 30px;
        border: none;
        background-color: transparent;
    }
`;

export const IconHeader = styled.span<IconHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 100%;
    background-color: transparent;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 45px;
        height: 45px;
        background-color: #f4f4f4;
        border-radius: 50%;

        > svg {
            width: 25px;
            height: 25px;

            ${props => props.color && css`
                color: ${props.color} !important;
            `}
        }

    }
`;

export const DialogFooter = styled.div`
  border-top: 1px solid #eef2f8;
  padding: 12px !important;
  width: 100%;
`;

export const DialogFooterCenter = styled.div`
    display: flex;
    justify-content: flex-end;
    align-content: center;
    width: 100%;
`;

export const BtnInsertNewAnswer = styled(Button)<BtnInsertNewAnswerProps>`
    height: 50px;
    width: 95%;
    background-color: #f23b5c;
    color: white;
    border-radius: 5px;
    border: 0;
    font-weight: 500;

    ${props => props.color && css`
        background-color: ${props.color} !important;
    `}
`;

export const ContainerForm = styled.div`
    width: 100%;
    padding: 15px;
    padding-top: 0px;
    padding-bottom: 0px;

    form {
        padding-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 0px;

        .cange-form-title {
            font-size: 14px;
        }

        .cange-form-title-required {
            font-size: 14px;
        }

        .cange-form-description {
            font-size: 12px;
            color: gray;
            font-weight: 400;
        }

        .cange-form-error {
            font-size: 12px;
        }

        .cange-form-error-icon {
            font-size: 12px;
            height: 12px;
            width: 12px;
        }

        .cange-form-help-text {
            margin-top: 0px;
            font-size: 12px;
        }
    }
`;

export const BoxItemsContainer = styled.div`    
    overflow-y: auto;
`;

export const BoxItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    cursor: pointer;

    :hover {
        background-color: #f4f5f7;
        border-radius: 5px;
    }
`;

export const BoxMultiple = styled.div`
    margin-left: 10px;
    display: flex;
    margin-right: 5px;
`;

export const BoxSpan = styled.span`
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 5px;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 5px;
    color: rgba(51,51,51,0.9);
    background-color: #4681b81e;
    margin-left: 5px;
    user-select: none;
`;

export const BoxSpanSoon = styled.span`
    background-color: #9337ed;
    color: white;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 10px;
    margin-left: -10px;
`;