export default function convertDMYtoDate(dateDMY: string, isDateTime: boolean = false) {
    const dateTimeParts = dateDMY.split(" ");
    const datePart = dateTimeParts[0];
    const timePart = dateTimeParts[1];

    const dateParts = datePart.split("/");
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const year = parseInt(dateParts[2], 10);

    let hours = 0;
    let minutes = 0;

    if (timePart) {
        const timeParts = timePart.split(":");
        hours = parseInt(timeParts[0], 10);
        minutes = parseInt(timeParts[1], 10);
    }

    if (isDateTime) {
        const dat = new Date(year, month, day, hours, minutes);
        return dat;
    } else {
        const dat = new Date(year, month, day);
        return dat;
    }
}