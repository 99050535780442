import React from "react";
import { IconBaseProps } from "react-icons";

import {
    ConfigSectionContent,
    ConfigSectionTitle,
    Container
} from "./style";

interface ConfigSectionProps {
    name: string;
    icon: React.ComponentType<IconBaseProps>;
    children?: React.ReactNode;
}

const ConfigSection: React.FC<ConfigSectionProps> = ({ name, icon: Icon, children }) => {

    return (
        <Container>
            <ConfigSectionTitle>
                <Icon />
                {name}
            </ConfigSectionTitle>
            <ConfigSectionContent>
                {children}
            </ConfigSectionContent>
        </Container>
    );

}

export default ConfigSection;