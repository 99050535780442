import { Box, ClickAwayListener } from "@material-ui/core";
import {
    BoxContainer,
    BoxItemsContainer,
    BoxSearchContainer,
    BoxUserItem,
    BoxUserLeft,
    BoxUserMail,
    BoxUserName,
    BoxUserRemove,
    BoxUserRigth,
    BoxUserSelected,
    ButtonAddLeft,
    ButtonAddRight,
    ButtonAddUser,
    Container,
    SearchInput
} from "./styles";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { AiOutlineClose } from 'react-icons/ai';
import ErrorDescription from "../../../../ErrorDescription";
import { FaPlus } from "react-icons/fa";
import { User } from '../../../../../interfaces/User';
import api from '../../../../../services/api';
import { Props as reacSelectProps } from "react-select";
import { useField } from "@unform/core";
import AvatarCange from "../../../../AvatarCange";

interface ComboOption {
    value: string;
    label: string;
}

interface Props extends reacSelectProps {
    name: string;
    options?: ComboOption[];
    description?: string;
    placeholder?: string;
    btnDescription?: string;
    form_id?: number;
    children?: React.ReactNode;
    variation?: string; //1 - Normal Use By Form // 2 - Picker from Step
    isPublicForm?: boolean;
    public_hash?: string;
    register_base_id?: number;
}

const ComboBoxUser: React.FC<Props> = ({
    name,
    options,
    description,
    placeholder,
    btnDescription,
    children,
    form_id,
    variation,
    isPublicForm,
    public_hash,
    register_base_id,
    ...rest
}) => {

    const selectRef = useRef(null);
    const { fieldName, error, registerField, defaultValue } = useField(name);
    const [open, setOpen] = React.useState(false);
    const [searchValue] = React.useState<string>();
    const [users, setUsers] = useState<User[]>();
    const [userSelected, setUserSelected] = useState<User>();
    const [usersFiltered, setUsersFiltered] = useState<User[]>();
    const [startValue, setStartValue] = useState<number | undefined>(defaultValue);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const handleSelectUser = useCallback(async (user: User) => {

        setUserSelected(user);
        setOpen(false);

    }, []);

    const handleRemoveUser = useCallback(async () => {

        setUserSelected(undefined);

    }, []);

    const handleFilterUsers = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {

        if (users !== undefined) {

            const searchText = e.currentTarget.value.toLowerCase();

            let usersNew = users;

            const usersName = usersNew.filter((user) => user.name.toLowerCase().includes(searchText));

            setUsersFiltered(usersName);

        }

    }, [users]);

    placeholder = placeholder !== undefined && placeholder !== "" && placeholder !== null ? placeholder : "Selecione...";

    useEffect(() => {

        if (variation === "2" || (register_base_id !== undefined && register_base_id !== null && register_base_id > 0)) {
            api.get(`/user/by-company`, {
            }).then(response => {
                if (response.data !== null) {
                    const respStep: User[] = response.data;

                    setUsers(respStep);
                    setUsersFiltered(respStep);

                    if (startValue !== undefined && respStep !== undefined) {
                        const newUserSelected = respStep.filter((u) => u.id_user === startValue);

                        if (newUserSelected.length > 0) {
                            setUserSelected(newUserSelected[0]);
                        }
                    } else {
                        setUserSelected(undefined);
                    }

                }
            }).catch(error => {
                console.log(error.response.data);
            });
        } else {

            const endpoint = isPublicForm ? `/form-public/user/by-form` : `/user/by-flow`;

            api.get(endpoint, {
                params: {
                    form_id: form_id,
                    public_hash: public_hash !== undefined ? public_hash : undefined
                }
            }).then(response => {
                if (response.data !== null) {
                    const respStep: User[] = response.data;

                    setUsers(respStep);
                    setUsersFiltered(respStep);

                    if (defaultValue !== undefined && respStep !== undefined) {
                        const newUserSelected = respStep.filter((u) => String(u.id_user) === defaultValue);

                        if (newUserSelected.length > 0) {
                            setUserSelected(newUserSelected[0]);
                        }
                    }

                }
            }).catch(error => {
                console.log(error.response.data);
            });
        }

    }, [form_id, defaultValue, variation, startValue, public_hash, isPublicForm, register_base_id]);

    useEffect(() => {

        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref: any) => {
                if (userSelected !== undefined) {
                    return userSelected.id_user;
                } else {
                    return {};
                }
            },
            setValue: (ref, value: any) => {
                if (value !== null) {
                    setStartValue(value);
                } else if (value === null || value === undefined) {
                    setStartValue(undefined)
                }
            },
            clearValue: () => {
                setUserSelected(undefined);
            }
        });

    }, [fieldName, registerField, rest.isMulti, userSelected, users]);

    return (
        <>
            <Container isErrored={!!error}>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Box>

                        {userSelected === undefined ?
                            <ButtonAddUser type="button" onClick={handleClick}>
                                <ButtonAddLeft>
                                    <FaPlus />
                                </ButtonAddLeft>
                                <ButtonAddRight>
                                    {btnDescription !== undefined ? btnDescription : "Adicionar um responsável"}
                                </ButtonAddRight>
                            </ButtonAddUser> :
                            <></>
                        }

                        {userSelected !== undefined ?
                            <BoxUserSelected>
                                <BoxUserItem>
                                    <BoxUserLeft>
                                        <AvatarCange user={userSelected} size="30" />
                                    </BoxUserLeft>
                                    <BoxUserRigth>
                                        <BoxUserName>{userSelected.name}</BoxUserName>
                                        <BoxUserMail>{userSelected.email}</BoxUserMail>
                                    </BoxUserRigth>
                                    <BoxUserRemove onClick={() => handleRemoveUser()}>
                                        <AiOutlineClose />
                                    </BoxUserRemove>
                                </BoxUserItem>
                            </BoxUserSelected> :
                            <></>
                        }
                        {open ? (
                            <BoxContainer>

                                <BoxSearchContainer>
                                    <SearchInput
                                        placeholder="Buscar usuários"
                                        defaultValue={searchValue}
                                        onChange={handleFilterUsers}
                                    >

                                    </SearchInput>
                                </BoxSearchContainer>

                                <BoxItemsContainer>
                                    {usersFiltered?.map((user) => {
                                        return (
                                            <BoxUserItem key={user.email} onClick={() => handleSelectUser(user)}>
                                                <BoxUserLeft>
                                                    <AvatarCange user={user} size="30" />
                                                </BoxUserLeft>
                                                <BoxUserRigth>
                                                    <BoxUserName>{user.name}</BoxUserName>
                                                    <BoxUserMail>{user.email}</BoxUserMail>
                                                </BoxUserRigth>
                                            </BoxUserItem>
                                        )
                                    })}
                                </BoxItemsContainer>

                            </BoxContainer>
                        ) : null}
                    </Box>
                </ClickAwayListener>
            </Container>
            {error && (<ErrorDescription title={error} />)}
        </>
    );
};
export default ComboBoxUser;
