import styled, { css, keyframes } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';

interface ContainerBodyProps {
    hideFilterBar?: boolean;
}

interface DrillDownItemProps {
    level: number;
}

interface DrillDownItemHeaderColumnProps {
    isEven?: boolean;
}

interface KpiIconContainerProps {
    color?: string;
    activeMotion?: boolean;
}

const pulse = keyframes`
    0% {
      transform: scale(1); 
    }
    50% {
      transform: scale(1.1); 
    }
    100% {
      transform: scale(1); 
    }
`;

export const ContainerBody = styled.div<ContainerBodyProps>`
    display: flex;
    height: calc(100% - 95px);
    align-items: baseline;
    justify-content: left;
    margin-top: 10px;
    width: 100%;

    ${props => props.hideFilterBar && css`
        height: calc(100% - 60px);
    `}
`;

export const ContainerContent = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px;
`;

export const Container = styled.div`
    height: 100%;
    max-height: calc(100vh - 70px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

export const ContainerLeft = styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

export const ContainerRight = styled(Grid)`
    @media(max-width: 959px){
        display: none !important;
    }
    
`;

export const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const ChartItem = styled.div`
    width: 100%;
    background: white;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
`;

export const ChartItemTitle = styled.div`
    color: black;
    font-weight: 500;
    font-size: 15px;
    border-bottom: 1px solid #f4f5f7;
    padding-bottom: 10px;
`;

export const ChartItemBody = styled.div`
    margin-top: 15px;
`;

export const HelpText = styled.button`
  margin-left: 5px;
  display: inline-block;
  background-color: transparent;
  border: none;

  svg {
    margin: 0;
    font-size: 13px;
  }
`;

export const ColumnsContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 15px;
    background-color: white;
    border-radius: 10px;
    overflow-x: auto;
    padding-bottom: 5px;
    padding-left: 0px;
`;

export const AddNewColumn = styled.button`
    background-color: white;
    max-height: 40px;
    border: 1px dashed #f23b5d;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #f23b5d;
    min-width: 130px;

    svg {
        font-size: 10px;
        margin-right: 5px;
    }
`;

export const BoxContainer = styled(Box)`
    position: absolute;
    z-index: 999999;
    top: 165px;
    left: 110px;
    z-index: 9;
    min-width: 400px;
    max-width: 100%;
    border-radius: 5px;
    padding: 10px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 0px;
    margin-right: 10px;
`;

export const BoxItemsContainer = styled.div`
    max-height: 300px;
    overflow-y: auto;
    padding-right: 10px;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 5px;
`;

export const BoxItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    cursor: pointer;

    :hover {
        background-color: #f4f5f7;
        border-radius: 5px;
    }
`;

export const BoxSpan = styled.span`
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 5px;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 5px;
    color: rgba(51,51,51,0.9);
    background-color: #4681b81e;
    margin-left: 5px;
`;

export const NoResults = styled.span`
    color: gray;
    font-style: italic;
    font-size: 11px;
    margin-top: 5px;
    margin-left: 5px;
`;

export const ContainerGroup = styled.div`
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #eef2f8;
    padding-bottom: 0px;
    background-color: white;
`;

export const GroupHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
`;

export const GroupName = styled.h3`
    font-weight: 500;
    color: black;
    margin-left: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    
    svg {
        margin-right: 10px;
    }
`;

export const GroupItems = styled.span`
    margin-left: 10px;
    font-size: 10px;
    margin-right: 5px;
    background-color: #f6ebfd;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 500;
    color: white;
`;

export const GroupBody = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const DividerGroup = styled.div`
    border: 1px solid #eef2f8;
    width: 100%;
`;

export const ColumnReport = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f4f5f7;
    margin-left: 15px;
    border-radius: 10px;
    background-color: #f23b5d;
    white-space: nowrap;
`;

export const ColumnReportTitle = styled.span`
    font-size: 13px;
    font-weight: 500;
    margin-right: 10px;
    color: white;
`;

export const ColumnReportRemove = styled.button`
    display: flex;
    background-color: transparent;
    border: none;
    font-size: 13px;
    color: white;
    cursor: pointer;
`;

export const DrillDownItem = styled.div<DrillDownItemProps>`
    border-radius: 5px;    
    padding-right: 0px;    
    padding-bottom: 0px;
    background-color: transparent;
    width: 100%;

    ${props => props.level > 1 && css`
        padding-left: 20px;
        padding-right: 10px;
    `}
`;

export const DrillDownItemHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-color: white;
    padding: 15px;    
    border-radius: 10px;
    margin-bottom: 10px;
`;

export const DrillDownItemHeaderName = styled.div`
    font-weight: 500;
    color: black;
    margin-left: 10px;    
    font-size: 15px;
    width: calc(100% - 100px);
`;

export const DrillDownItemHeaderColumn = styled.div<DrillDownItemHeaderColumnProps>`
    font-weight: 500;
    color: black;
    margin-left: 10px;    
    font-size: 15px;
    width: 100px;
    text-align: center;

    ${props => props.isEven && css`
        color: gray;
    `}
`;

export const DrillDownItemBody = styled.div`
    
`;

export const DrillDownItemDivider = styled.div`
    border: 1px solid #eef2f8;
    width: 100%;
`;


export const KpiItemsContainer = styled.div`    
    display: flex;
    align-items: center;
    height: auto;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding-left: 5px;
    margin-top: 0px;
    margin-bottom: 15px;
`;

export const KpiContainer = styled.div<KpiIconContainerProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    background-color: #0000ff1e;
    border-radius: 5px;
    margin-right: 10px;
    min-width: 230px;

    ${props => props.color && css`
        background-color: ${props.color};
    `}
`;

export const KpiIconContainer = styled.div<KpiIconContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    margin-right: 10px;
    background-color: blue;
    border-radius: 5px;
    color: white;
    font-size: 18px;

    ${props => props.color && css`
        background-color: ${props.color};
    `}

    ${props => props.activeMotion && css`
        svg {
            animation: ${pulse} 1s infinite;
        }
    `}
`;

export const KpiInformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const KpiValue = styled.div`
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 2px;
    color: #4d4d4d;
`;

export const KpiDescription = styled.div`
    font-size: 11px;
    color: gray;
`;

export const ContainerLoader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 95vh;
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 30% !important;
        max-width: 150px;
    }
    
`;