import styled, { css, keyframes } from "styled-components";

interface EmailContainerProps {
    isRead?: boolean;
    isSelected?: boolean;
}

interface EmailIconContainerProps {
    isRead?: boolean;
}

interface EmailIconProps {
    isRead?: boolean;
}

const pulse = keyframes`
    0% {
      transform: scale(1); 
    }
    50% {
      transform: scale(1.1); 
    }
    100% {
      transform: scale(1); 
    }
`;

export const EmailContainer = styled.div<EmailContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ededed;
    padding: 10px;
    background-color: #eef2f8;
    position: relative;
    color: black;

    ${(props) => props.isRead === false && css`
        font-weight: 500;
        background-color: white;
        color: black;
    `}

    ${(props) => props.isSelected && css`
        background-color: #c2dbff;
        color: black;
        border-bottom: 1px solid #c2dbff;
    `}

    :hover {
        .email-icon-container {
            display: flex;
        }

        .email-details {
            display: none;
        }

        box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
        z-index: 2;
    }
    
`;

export const EmailFrom = styled.div`
    overflow: hidden;
    font-size: 14px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const EmailSubject = styled.div`
    font-size: 13px;
    margin-bottom: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    span {
        color: gray;
        margin-left: 1px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-flex-basis: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        box-flex: 1;
        -webkit-flex-grow: 1;
        flex-grow: 1;
        -webkit-flex-shrink: 1;
        flex-shrink: 1;
        text-align: left;
    }
`;

export const EmailIconContainer = styled.div<EmailIconContainerProps>`
    display: none;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    padding: 5px 5px;
    position: absolute;
    right: 35px;
    top: -15px;
    z-index: 10;

    ${(props) => props.isRead === false && css`
        background-color: #eef2f8;
    `}
`;

export const EmailIcon = styled.div<EmailIconProps>`
    margin-right: 3px;
    margin-left: 3px;
    padding: 3px 5px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        background-color: #eef2f8;

        ${(props) => props.isRead === false && css`
            background-color: white;
        `}
    }
`;

export const EmailHeaderContainer = styled.div`
    width: 100%;
`;

export const EmailDetails = styled.div`
    position: absolute;
    align-items: center;
    justify-content: center;
    display: flex;
    right: 10px;
    top: 10px;
`;

export const EmailDate = styled.div`
    font-size: 12px;
`;

export const IconNotRead = styled.div`
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    color: #893be9;

    animation: ${pulse} 1s infinite;
`;