import { Container, InputCB, ItemCB, LabelCB, MarginTop, SpanCB } from "./styles";
import React, { InputHTMLAttributes, useEffect, useRef } from "react";

import ErrorDescription from "../../../../ErrorDescription";
import { IconBaseProps } from "react-icons";
import { useField } from "@unform/core";

interface FieldOptionsProps {
    id_field_option?: number;
    field_id?: number;
    value: string;
    label: string;
    order?: string;
    hide?: string;
    old_id_field_option?: number;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon?: React.ComponentType<IconBaseProps>;
    description?: string;
    option?: FieldOptionsProps;
    placeHolder?: string;
}

const CheckBoxOne: React.FC<InputProps> = ({ name, option, placeHolder }) => {
    const inputRefs = useRef<HTMLInputElement>();
    const { fieldName, registerField, defaultValue, error } = useField(name);

    if (option === undefined) {
        option = {
            value: "1",
            label: placeHolder !== undefined ? placeHolder : ""
        };
    }

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRefs.current,
            getValue(ref) {
                return ref.checked;
            },
            setValue(ref, value) {
                if (value === "0"){
                    ref.checked = false;
                    return false;
                } else if (value === "1") {
                    ref.checked = true;
                    return true;
                } else if (!value) {
                    ref.checked = false;
                } else if (value) {
                    ref.checked = true;
                } 
            }, 
            clearValue: (ref) => {
                ref.checked = false;
                return ref;
            }
        });
    }, [fieldName, registerField]);

    return (
        <MarginTop>
            <Container>
                <ItemCB key={option.value}>
                    <InputCB
                        id={option.value}
                        ref={(elRef) => (inputRefs.current = elRef as HTMLInputElement)}
                        type="checkbox"
                        name={fieldName}
                        value={option.value}
                        defaultChecked={defaultValue === option.value}
                    />
                    <LabelCB />
                    <SpanCB htmlFor={option.value}>{option.label}</SpanCB>
                </ItemCB>
            </Container>
            {error && (<ErrorDescription title={error} />)}
        </MarginTop>
    );
};

export default CheckBoxOne;
