import React, { useEffect } from "react";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import { DataProps } from "../..";
import getChartFormatValue from "../../getChartFormatValue";
import getFormatLabel from "../../getFormatLabel";

interface HeatMapProps {
  dataElement?: DataProps[];
  show_time?: string;
  typeField?: string;
  axis_y_field_id?: number;
  axis_x_field_id?: number;
  axis_y_field_type?: string;
  axis_x_field_type?: string;
  axis_y_field_variation?: string;
  color?: string;
}

export interface DataPropsLine {
  id: string;
  color?: string;
  data: {
    x: string;
    y: number;
  }[];
  labelAux?: string;
}

// Function to transform the data to the format expected by the chart
export function transformData(data: DataProps[]): DataPropsLine[] {
  // Map all unique ids
  const uniqueIds = [...new Set(data.map(item => item.labelAux || ''))];
  // Map all unique labels
  const uniqueLabels = [...new Set(data.map(item => item.label))];

  // Create the data points
  const result = uniqueIds.map(id => {
    const dataPoints = uniqueLabels.map(label => {
      const matchingItem = data.find(item => item.labelAux === id && item.label === label);
      return {
        x: label,
        y: matchingItem !== undefined && matchingItem.value !== undefined ? matchingItem.value : 0
      };
    });

    return {
      id: id || '', // If the id is undefined, set it as an empty string
      data: dataPoints,
      labelAux: id
    };
  });

  return result;
}

const HeatMapChart: React.FC<HeatMapProps> = ({ dataElement, show_time, axis_y_field_id, color, axis_y_field_type, axis_y_field_variation }) => {

  const [data, setData] = React.useState<DataPropsLine[]>(transformData(dataElement !== undefined ? dataElement : []));

  useEffect(() => {

    if (dataElement !== undefined && dataElement !== null && dataElement.length > 0) {

      const data = transformData(dataElement);

      setData(data);
    }

  }, [dataElement, color]);

  return (
    <>
      <ResponsiveHeatMap
        data={data}
        margin={{ top: 70, right: 10, bottom: 60, left: 110 }}
        valueFormat={(value) => getChartFormatValue(value, axis_y_field_type, 0, axis_y_field_variation)}
        xOuterPadding={0.1}
        yOuterPadding={0.1}
        axisTop={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -90,
          legend: '',
          legendOffset: 46,
          truncateTickAt: 0,
          format: value => {
            return getFormatLabel(value, 15);
          }
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: 'middle',
          legend: '',
          legendOffset: -72,
          truncateTickAt: 0,
          format: value => {
            return getFormatLabel(value, 15);
          }

        }}
        colors={{
          type: 'diverging',
          scheme: 'orange_red',
        }}
        borderColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              0
            ]
          ]
        }}
        labelTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              3
            ]
          ]
        }}
        legends={[
          {
            anchor: 'bottom',
            translateX: 1,
            translateY: 15,
            length: 400,
            thickness: 8,
            direction: 'row',
            tickPosition: 'after',
            tickSize: 2,
            tickSpacing: 5,
            tickOverlap: false,
            tickFormat: '>-.2s',
            title: '',
            titleAlign: 'start',
            titleOffset: 7
          }
        ]}
      />
    </>
  );

}

export default HeatMapChart;