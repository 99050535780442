import lottie from 'lottie-web';
import React, { useEffect, useRef } from "react";

import {
    Animation,
    BtnRefresh,
    Container,
    ContainerDescription
} from "./style";

const LoaderUseTemplate: React.FC = () => {

    const lottieContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../assets/lottie/building-template.json')
            })
        }
    },[])

    return (
        <Container>
            <Animation className="lottieContainer" ref={lottieContainer}/>
            <ContainerDescription>
                <h1>Espera aí! Estamos criando o seu template, para que <b>sua experiência seja a melhor!</b> Aqui no Cange ;)</h1>
                <h3>Estamos finalizando a criação do seu template. Se estiver demorando muito, <BtnRefresh onClick={() => window.location.reload()}>clique aqui</BtnRefresh>.</h3>
            </ContainerDescription>
        </Container>
    );

}

export default LoaderUseTemplate;