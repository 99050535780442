import React, { useCallback, useEffect, useRef, useState } from "react";
import api from "../../../../../services/api";
import { ResponsiveLine, Serie } from '@nivo/line';
import { formatDateToDateRead } from "../../../../../utils/formatDateToDateRead";
import { Animation, ContainerLoader } from "./style";
import lottie from "lottie-web";

interface LineChartProps {
    flow_id: number;
    days: number;
}

interface LineChartDataProps {
    date_update: string;
    date_formated?: string;
    total_activities: number;
}

const LineChart: React.FC<LineChartProps> = ({ flow_id, days }) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<Serie[]>();
    const lottieContainer = useRef<HTMLDivElement>(null);

    const getApiData = useCallback(async (id_flow: number) => {
        
        setLoading(true);
        api.get(`/activity/daily-updates/by-flow`, {
            params: {
                flow_id: id_flow,
                days: days
            }
        }).then(response => {
            if (response.data !== null) {

                const newData = response.data as LineChartDataProps[];

                //Format the date to date_formated 
                newData.map((d) => {
                    d.date_formated = formatDateToDateRead(new Date(d.date_update));
                    return d;
                });

                //Transform Line Chart Data To Nivo Data
                const lineData = [
                    {
                        id: "updates",
                        color: 'blue',
                        data: newData.map((d) => {
                            return {
                                x: d.date_formated,
                                y: d.total_activities
                            }
                        })
                    }
                ] as Serie[];

                setData(lineData);

            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            console.log(error);
        });

    }, [days]);

    useEffect(() => {

        getApiData(flow_id);

    }, [getApiData, flow_id]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../../../assets/lottie/loader.json')
            })
        }
    }, []);

    return (
        <>
            {loading ?
                <ContainerLoader>
                    <Animation className="lottieContainer" ref={lottieContainer} />
                </ContainerLoader > :
                <div style={{ height: '300px' }}>
                    {data !== undefined ?
                        <ResponsiveLine
                            data={data}
                            margin={{ top: 35, right: 15, bottom: 60, left: 50 }}
                            xScale={{ type: 'point' }}
                            yScale={{
                                type: 'linear',
                                min: 'auto',
                                max: 'auto',
                                stacked: true,
                                reverse: false
                            }}
                            yFormat=" >-.2f"
                            curve="catmullRom"
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Dia',
                                legendOffset: 36,
                                legendPosition: 'middle'
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Atualizações',
                                legendOffset: -40,
                                legendPosition: 'middle'
                            }}
                            colors={['#9e37ed']}
                            lineWidth={3}
                            pointSize={10}
                            pointColor={{ theme: 'background' }}
                            pointBorderWidth={2}
                            pointBorderColor={{ from: 'serieColor' }}
                            enablePointLabel={true}
                            pointLabel="y"
                            pointLabelYOffset={-12}
                            enableArea={true}
                            areaOpacity={0.05}
                            useMesh={true}

                        />
                        :
                        <>no data</>
                    }
                </div>
            }
        </>
    );

}

export default LineChart;