import { Box } from "@material-ui/core";
import Button from "../../../components/Button";
import FormBuilder from "../../../components/Forms/FormBuilder";
import styled, { css } from "styled-components";

interface FilterButtonProps {
    active?: boolean;
}

export const Container = styled.div`
    display: flex;
    height: 100%;
`;

export const LeftSide = styled.div`
    width: 70%;
    border-right: 1px solid #eef2f7;
    position: relative;
    padding: 15px;
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const TitleInput = styled.input`
    width: calc(100% - 200px);
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 5px;
    font-size: 25px;
    font-weight: 500;
    color: black;
    border-color: #eef2f6;

    ::placeholder {
        color: gray;
    }

    :focus {    
        color: black;
    }
`;

export const FilterButton = styled.button<FilterButtonProps>`
    width: 190px;
    margin: 10px;
    margin-right: 0px;
    height: 50px;
    border-radius: 5px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #4d4d4d;
    
    svg {
        font-size: 20px;
        margin-right: 7px;
    }

    ${props => props.active && css`
        background-color: #60bd4e;
        color: white;
        border-radius: 5px;   
        font-weight: 500;
        
        :hover {
            background-color: #57aa47;
        }
    `}
`;

export const ContentElement = styled.div`
    width: 100%;    
    padding: 20px;
    padding-top: 0px;
    height: calc(100% - 51px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const RightSide = styled.div`
    width: 30%;
    min-width: 300px;
    overflow: auto;
    padding: 15px;
    position: relative;
`;

export const BackBtn = styled.button`
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: underline;
    color: gray;
    border: none;
    background: transparent;

    svg {
        font-size: 15px;
        margin-right: 5px;
    }
`;

export const SectionScroll = styled.div`
    height: calc(100% - 85px);
    overflow: auto;
    margin-top: 10px;
`;

export const ContentConfig = styled.div`
    margin-top: 15px;
`;

export const ConfigTitle = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    margin-bottom: 10px;
    color: #4d4d4d;

    svg {
        margin-right: 5px;
        font-size: 15px;
    }
`;

export const FormField = styled(FormBuilder)`
    & + div {
      margin-bottom: 15px;
      height: 100% !important;
    }
`;

export const BottomAction = styled.div`
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    right: 15px;
`;

export const ButtonCancel = styled(Button)`
  height: 36px;
  width: 100px;
  margin: 0px 0px 0px 0px;
  color: gray;
`;

export const ButtonConfirm = styled(Button)`
  height: 36px;
  width: 120px;
  margin: 0px 0px 0px 15px;
`;

export const Divider = styled.div`

    border-bottom: 1px solid #dddddd;
    margin-top: 15px;
    margin-bottom: 15px;

`;

export const LabelInput = styled.div`
    font-size: 13px;
    margin-bottom: 7px;
    margin-left: 2px;
    color: #4d4d4d;
    font-weight: 500;
`;

export const SelectInput = styled.select`
    height: 35px;
    border-radius: 5px;
    border-color: #808080bf;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 100%;
`;

export const TextAreaInput = styled.textarea`
    border-radius: 5px;
    border-color: #808080bf;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 100%;
    resize: none;
`;

export const OptionItem = styled.option`

    svg {
        display: flex;
        font-size: 25px;
    }

`;

export const ColorPickerContainer = styled.div`

    border: 1px solid gray;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 5px;

    .circle-picker {
        width: 295px !important
    }

`;

export const BoxContainer = styled(Box)`
    position: absolute;
    //top: auto;
    top: 80px;
    right: 5px;
    z-index: 999;
    min-width: 250px;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 0px;
    margin-right: 10px;

    //max-height: 50%;
    //overflow-y: auto;
    
    @media(max-width: 800px){
        left: 0px;
    }
`;

export const FilterContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    padding-bottom: 5px;
`;

export const SelectInputFilter = styled.select`
    height: 35px;
    border-radius: 5px 0px 0px 5px;
    border-color: #808080bf;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 200px;
`;

export const SelectComparator = styled.select`
    height: 35px;
    border-radius: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: #a0a0a0;
    font-size: 14px;
    padding: 5px;
    color: black;
`;

export const DeleteButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #a0a0a0;
    border-radius: 5px;
    margin-left: 5px;

    height: 35px;
    width: 35px !important;
    margin-top: 0px !important;
    
    svg {
        width: 13px;
        height: 13px;
    }

    :hover {
        border: 1px solid #f23b5c;
        background-color: white;
        color: #f23b5c;
    }
`;

export const FilterBottom = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const AddNewFilter = styled.button`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    margin-top: 5px;

    svg {
        font-size: 13px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
`;

export const CleanFilter = styled.button`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    margin-top: 5px;

    position: absolute;
    right: 95px;
    border-radius: 5px;
    padding: 5px 15px;

    background-color: transparent;
    color: gray;

    svg {
        font-size: 11px;
        margin-right: 5px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
`;

export const GoFilter = styled.button`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    margin-top: 5px;

    position: absolute;
    right: 5px;
    background: #e9f0f7;
    border-radius: 5px;
    padding: 5px 15px;

    svg {
        font-size: 13px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
`;

export const ContainerLoader = styled.div`
    display: block;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 400;
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 50% !important;
    }
    
`;