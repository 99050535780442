import styled, { css } from "styled-components";
import { Box } from "@material-ui/core";

interface ItemProps {
    active?: boolean;
    color?: string;
}

interface SearchInputProps {
    isActive?: boolean;
}

interface ButtonAddNewProps {
    color?: string;
}

interface BoxContainerProps {
    iscolapsed?: string;
}

interface WorkspaceItemProps {
    color: string;
    selectedStyle: boolean;
}

interface WorkspaceItemIconProps {
    color: string;
}

interface WorkspaceItemRightProps {
    color?: string;
}

export const BoxContainer = styled(Box) <BoxContainerProps>`
    position: fixed;
    top: 5px;
    min-width: 300px;
    z-index: 1;
    height: calc(100vh - 10px);
    left: 85px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);

    ${(props) => props.iscolapsed === 'false' && css`
        left: 255px !important;
    `}
`;

export const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #f4f5f7;
    padding-right: 35px;

    color: black;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.2px;
    
    span {
        position: absolute;
        right: 20px;
        font-size: 13px;
        font-weight: 400;
        margin-left: 5px;
        font-style: italic;
        color: gray;
    }
`;

export const HeaderLeft = styled.div`
    margin-right: 15px;
    margin-left: 15px;

    img {
        height: 30px
    }
`;

export const HeaderRight = styled.div`
    width: 100%;
    text-align: center; 

    h1 {
        font-size: 16px;
        font-weight: 400;
        color: #323338;
    }

    span {
        font-size: 13px;
        color: grey;
    }
`;

export const Body = styled.div`
    padding: 5px 15px 5px 15px;
    height: calc(100% - 85px);
`;

export const TitleSection = styled.div`
    color: gray;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
`;

export const Item = styled.div<ItemProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    padding-left: 15px;
    border-radius: 5px;

    :hover {
        background-color: #f3e7fe;
    }

    span {
        font-size: 15px;
        color: #323338;
    }

    ${(props) => props.color && css`
        :hover {
            background-color: ${props.color + "1e"};

            span {
                font-weight: 500;
            }
        }
    `}

    ${(props) => props.color && props.active && css`
        background-color: ${props.color + "1e"};
        span {
            font-weight: 500;
        }
    `}
`;

export const ItemIcon = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;

    svg {
        height: 20px;
        width: 20px;
        color: #323338;
    }
`;

export const Divider = styled.div`
    border-bottom: 1px solid #f4f5f7;
    margin-bottom: 10px;
    margin-top: 10px;
`;


export const SearchInput = styled.div<SearchInputProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    border-radius: 5px;
    border: solid 1px #808080bf;
    width: 100%;
    max-width: 400px;
    color: #4d4d4d;
    font-weight: 600;

    ${(props) => props.isActive && css`
        border: solid 2px #f23c5d;
    `}

    svg {
        font-size: 17px;
        margin-top: 1px;
        margin-left: 9px;
    }
`;

export const Input = styled.input`
    border: none;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 100%;
    max-width: 400px;

    ::placeholder {
        padding: 5px;
        padding-left: 0px;
        color: #4d4d4d;
        font-size: 13px;
    }
`;

export const SearchContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 15px;
`;

export const ButtonAddNew = styled.button<ButtonAddNewProps>`
    margin-top: 10px;
    width: 100%;   
    height: 30px; 
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 14px;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 15px;
    background-color: #f23b5c;
    color: white;
    border-radius: 5px;
    transition: transform 0.3s ease; 

    svg {
        font-size: 14px;
        margin-right: 10px;
        margin-top: 1px;
    }

    ${props => props.color && css`
        background-color: ${props.color};     
        color: #4d4d4d;       
    `}

    :hover {
        transform: scale(1.02);
    }
`;

export const ContainerWorkspaceItems = styled.div`
    height: calc(100% - 25px);
    overflow: auto;
`;

export const WorkspaceTitle = styled.div`
    font-size: 13px;
    border-bottom: 1px solid rgb(244, 245, 247);
    padding-top: 10px;
    padding-bottom: 5px;
    font-weight: 500;
    color: gray;
    margin-bottom: 15px;
`;

export const WorkspaceItem = styled.li<WorkspaceItemProps>`
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    height: 35px;
    width: 35px;
    margin-bottom: 15px;
    cursor: pointer;
    width: 100%;
    position: relative;

    :hover {
        ${(props) => props.color && css`
            color: ${props.color};
            font-weight: 500;
            background-color: ${props.color + "35"};
            border-radius: 5px;            
        `}

        .workspace-item-right {
            display: flex;
        }
    }

    ${(props) => props.selectedStyle && css`
            color: ${props.color};
            font-weight: 500;
            background-color: ${props.color + "35"};
            border-radius: 5px;
    `}
`;

export const WorkspaceItemIcon = styled.div<WorkspaceItemIconProps>`
    border: 1px solid #f23b5c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    border-radius: 5px;
    background-color: #f23b5c;
    margin-right: 10px;    
    min-height: 35px;
    min-width: 35px;

    ${(props) => props.color && css`
        border: 1px solid ${props.color};
        background-color: ${props.color};
    `}

    svg {
        color: white;
    }

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        font-size: 18px !important;
        
        svg {
            color: white;
        }
    }
`;

export const WorkspaceItemRight = styled.div<WorkspaceItemRightProps>`
    position: absolute;
    right: 10px;
    display: none;
    align-items: center;
    justify-content: center;
    
    ${(props) => props.color && css`
        color: ${props.color};            
    `}

    svg {
        font-size: 18px;
        margin-left: 10px;
        cursor: pointer;    
        transition: transform 0.3s ease;    

        :hover {            

            transform: scale(1.1);

        }
    }
`;

export const CleanBox = styled.div`
    display: grid;
    justify-items: center;
    align-content: center;
    padding: 10px;
    border-radius: 10px;
    border: 1px dashed gray;
    min-height: 250px;
    font-size: 15px;
    color: gray;
    height: calc(100% - 5px);
    width: 100%;
    max-width: 270px;
    text-align: center;
    cursor: pointer;

    svg {
        font-size: 35px;
        margin-bottom: 15px;
        color: #f23b5c;
    }

    :hover {
        border: 1px dashed #f23b5c;
        color: #f23b5c;
    }
`;

export const CleanBoxText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 13px;
    margin-top: 2px;
    max-width: 500px;
    color: gray;
`;

export const CleanBoxTitle = styled.h2`
    font-size: 17px;
    margin-bottom: 10px;
    text-align: center;    
    color: black;

    span {
        margin-left: 5px;
        font-size: 20px;
    }
`;
