import { OrderConditionItem } from "../components/FilterBar/FilterDialog";
import { Card } from "../interfaces/Card";
import { FormAnswerField } from "../interfaces/FormAnswerField";

export default function getCardSorted(cards: Card[], orders?: OrderConditionItem[]): Card[] {

    let newCards = cards;

    //Order component
    if (orders !== undefined && orders.length > 0) {

        //Reverse the orders order
        const newOrders = orders.slice().reverse();

        for (const condition of newOrders) {

            const { selectedField, selectedOrder } = condition;

            //Get the direction side
            const direction = getOrderByDirection(selectedOrder);

            newCards = newCards.sort((a, b) => {

                if (selectedField.id_field < 0) { //Is standart field 


                    if (selectedField.id_field === -1) { // Data de Vencimento

                        const checkA: string | undefined = a.dt_due !== undefined ? a.dt_due : undefined;
                        const checkB: string | undefined = b.dt_due !== undefined ? b.dt_due : undefined;

                        if (checkA && checkB) {
                            const dateA = new Date(checkA).getTime();
                            const dateB = new Date(checkB).getTime();

                            if (direction === 1) {
                                return dateA - dateB;
                            } else {
                                return dateB - dateA;
                            }
                        }

                        if (!checkA && checkB) {
                            return 1 * direction;
                        }

                        if (checkA && !checkB) {
                            return -1 * direction;
                        }

                    } else if (selectedField.id_field === -2) { // Data de Criação

                        const checkA: string | undefined = a.dt_created !== undefined ? a.dt_created : undefined;
                        const checkB: string | undefined = b.dt_created !== undefined ? b.dt_created : undefined;

                        if (checkA && checkB) {
                            const dateA = new Date(checkA).getTime();
                            const dateB = new Date(checkB).getTime();

                            if (direction === 1) {
                                return dateA - dateB;
                            } else {
                                return dateB - dateA;
                            }
                        }

                        if (!checkA && checkB) {
                            return 1 * direction;
                        }

                        if (checkA && !checkB) {
                            return -1 * direction;
                        }

                    } else if (selectedField.id_field === -3) { // Responsável

                        const checkA: string | undefined = a.user !== undefined && a.user.name !== undefined ? a.user.name : undefined;
                        const checkB: string | undefined = b.user !== undefined && b.user.name !== undefined ? b.user.name : undefined;

                        if (checkA !== undefined && checkB !== undefined) {
                            if (checkA < checkB) {
                                return -1 * direction;
                            }
                            if (checkA > checkB) {
                                return 1 * direction;
                            }
                        } else if (checkA !== undefined) {
                            return 1 * direction;
                        } else if (checkB !== undefined) {
                            return 1 * direction;
                        }

                    } else if (selectedField.id_field === -4) { // Etapa

                        const checkA: string | undefined = a.flow_step !== undefined && a.flow_step.name !== undefined ? a.flow_step.name : undefined;
                        const checkB: string | undefined = b.flow_step !== undefined && b.flow_step.name !== undefined ? b.flow_step.name : undefined;

                        if (checkA !== undefined && checkB !== undefined) {
                            if (checkA < checkB) {
                                return -1 * direction;
                            }
                            if (checkA > checkB) {
                                return 1 * direction;
                            }
                        } else if (checkA !== undefined) {
                            return 1 * direction;
                        } else if (checkB !== undefined) {
                            return 1 * direction;
                        }

                    } else if (selectedField.id_field === -5) { // Responsável

                        const checkA: string | undefined = a.user_creator !== undefined && a.user_creator.name !== undefined ? a.user_creator.name : undefined;
                        const checkB: string | undefined = b.user_creator !== undefined && b.user_creator.name !== undefined ? b.user_creator.name : undefined;

                        if (checkA !== undefined && checkB !== undefined) {
                            if (checkA < checkB) {
                                return -1 * direction;
                            }
                            if (checkA > checkB) {
                                return 1 * direction;
                            }
                        } else if (checkA !== undefined) {
                            return 1 * direction;
                        } else if (checkB !== undefined) {
                            return 1 * direction;
                        }
                    }

                } else { //Is custom field

                    let checkA: FormAnswerField | undefined = undefined;
                    let checkB: FormAnswerField | undefined = undefined;

                    //Card A
                    if (a.form_answers !== undefined) {
                        const faCheckA = a.form_answers.filter((f) => f.form_id === condition.selectedField.form_id);
                        if (faCheckA !== undefined && faCheckA.length > 0) {
                            checkA = faCheckA[faCheckA.length - 1].form_answer_fields.filter((faf) => faf.field_id === condition.selectedField.id_field)[0];
                        }
                    }

                    //Card B
                    if (b.form_answers !== undefined) {
                        const faCheckB = b.form_answers.filter((f) => f.form_id === condition.selectedField.form_id);
                        if (faCheckB !== undefined && faCheckB.length > 0) {
                            checkB = faCheckB[faCheckB.length - 1].form_answer_fields.filter((faf) => faf.field_id === condition.selectedField.id_field)[0];
                        }
                    }

                    if (selectedOrder === "A → Z" || selectedOrder === "Z → A") {

                        if (checkA?.valueString !== undefined && checkB?.valueString !== undefined) {
                            if (checkA.valueString < checkB.valueString) {
                                return -1 * direction;
                            }
                            if (checkA.valueString > checkB.valueString) {
                                return 1 * direction;
                            }
                        } else if (checkA !== undefined) {
                            return 1 * direction;
                        } else if (checkB !== undefined) {
                            return 1 * direction;
                        }

                    } else if (selectedOrder === "Primeiro → Último" || selectedOrder === "Último → Primeiro") {

                        if (checkA?.valueString !== undefined && checkB?.valueString !== undefined) {
                            if (checkA.valueString < checkB.valueString) {
                                return -1 * direction;
                            }
                            if (checkA.valueString > checkB.valueString) {
                                return 1 * direction;
                            }
                        } else if (checkA !== undefined) {
                            return 1 * direction;
                        } else if (checkB !== undefined) {
                            return 1 * direction;
                        }

                    } else if (selectedOrder === "1 → 9" || selectedOrder === "9 → 1") {

                        if (checkA?.value !== undefined && checkB?.value !== undefined) {
                            if (selectedField.type === "CURRENCY_FIELD" || selectedField.type === "NUMBER_FIELD") {
                                if (Number(checkA.value) < Number(checkB.value)) {
                                    return -1 * direction;
                                }
                                if (Number(checkA.value) > Number(checkB.value)) {
                                    return 1 * direction;
                                }
                            } else {
                                if (checkA.value < checkB.value) {
                                    return -1 * direction;
                                }
                                if (checkA.value > checkB.value) {
                                    return 1 * direction;
                                }
                            }
                        } else if (checkA !== undefined) {
                            if (selectedField.type === "CURRENCY_FIELD" || selectedField.type === "NUMBER_FIELD") {
                                return 1 * direction;
                            } else {
                                return -1 * direction;
                            }
                        } else if (checkB !== undefined) {
                            if (selectedField.type === "CURRENCY_FIELD" || selectedField.type === "NUMBER_FIELD") {
                                return -1 * direction;
                            } else {
                                return 1 * direction;
                            }
                        } else {
                            return -1 * direction;
                        }

                    } else if (selectedOrder === "Verdadeiro → Falso" || selectedOrder === "Falso → Verdadeiro") {

                        if (checkA?.value !== undefined && checkB?.value !== undefined) {
                            if (checkA.value < checkB.value) {
                                return -1 * direction;
                            }
                            if (checkA.value > checkB.value) {
                                return 1 * direction;
                            }
                        } else if (checkA !== undefined) {
                            return 1 * direction;
                        } else if (checkB !== undefined) {
                            return 1 * direction;
                        }

                    }

                }

                return 0; // Se todos os campos forem iguais, mantém a ordem original

            });
        }

    } else { //When no have a order setted

        newCards = sortByPreviousNext(newCards);

    }

    return newCards;
}

const sortByPreviousNext = (cards: Card[]): Card[] => {

    const compareByDtCreated = (a: Card, b: Card): number => {
        const dateA = new Date(a.dt_created).getTime();
        const dateB = new Date(b.dt_created).getTime();

        return dateA - dateB;
    };

    const compareByDtLastMoved = (a: Card, b: Card): number => {
        const dateA = new Date(a.dt_created).getTime();
        const dateB = new Date(b.dt_created).getTime();

        return dateA > dateB ? 1 : -1;
    };

    const sortedCards = [...cards];

    sortedCards.sort((a, b) => {

        if (a.index !== undefined && a.index !== null && b.index !== undefined && b.index !== null) {
            if (a.index < b.index) return -1;
            if (a.index > b.index) return 1;
            if (a.index === b.index && a.dt_last_moved !== undefined && b.dt_last_moved !== undefined && a.dt_last_moved !== null && b.dt_last_moved !== null) {
                return compareByDtLastMoved(a, b);
            }
        }

        if (a.index !== undefined && a.index !== null && (b.index === undefined || b.index === null)) {
            return -1;
        }

        if (b.index !== undefined && b.index !== null && (a.index === undefined || a.index === null)) {
            return 1;
        }

        return compareByDtCreated(a, b);

    });

    return sortedCards;
};

function getOrderByDirection(selectedOrder: string): number {

    //Type 1 - "A → Z" ou "Z → A"
    if (selectedOrder === "A → Z") {
        return 1;
    } else if (selectedOrder === "Z → A") {
        return -1;
    }

    //Type 2 - "Primeiro → Último" ou "Último → Primeiro"
    if (selectedOrder === "Primeiro → Último") {
        return 1;
    } else if (selectedOrder === "Último → Primeiro") {
        return -1;
    }

    //Type 3 - "1 → 9" ou "9 → 1"
    if (selectedOrder === "1 → 9") {
        return 1;
    } else if (selectedOrder === "9 → 1") {
        return -1;
    }

    //Type 4 - "Verdadeiro → Falso" ou "Falso → Verdadeiro"
    if (selectedOrder === "Verdadeiro → Falso") {
        return 1;
    } else if (selectedOrder === "Falso → Verdadeiro") {
        return -1;
    }

    return 1;
}
