import React, { useState, useCallback, useEffect } from "react";
import { IconPicker as IcoPicker, IconList } from 'react-fa-icon-picker'
import { useField } from "@unform/core";
import { Container } from "./style";
import ErrorDescription from "../../../../ErrorDescription";

interface PickerProps {
    name: string;
    onForceBlur?: () => void;
}

const IconPicker: React.FC<PickerProps> = ({ name, onForceBlur }) => {

    const { fieldName, registerField, defaultValue, error } = useField(name);
    const [icon, setIcon] = useState(defaultValue || "FaCodeBranch" as IconList)

    const handleChangeComplete = useCallback((icon: IconList) => {
        setIcon(icon);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: icon,
            getValue: icon => {
                return icon
            },
            setValue: (ref, value) => {
                setIcon(value as IconList);
            },
            clearValue: ref => {
                setIcon("" as IconList);
            },
        });
    }, [fieldName, registerField, icon]);

    useEffect(() => {
        if (onForceBlur && icon !== undefined && icon !== null && icon !== "") {
            onForceBlur();
        }
    }, [icon, onForceBlur]);

    return (
        <>
            <Container className="icon-picker-container">
                <IcoPicker
                    value={icon}
                    onChange={handleChangeComplete}
                    hideSearch={false}
                />
            </Container>
            {error && (<ErrorDescription title={error} />)}
        </>
    );

}

export default IconPicker;