import styled from "styled-components";

export const DockPanel = styled.div`
    position: absolute;
    bottom: 10px;
    height: 60px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    left: 50%;
    transform: translate(-50%, 0px);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
    z-index: 1;

    svg {
        font-size: 35px;
        margin-right: 10px;
        transition: transform 0.3s ease;

        :hover {
            transform: scale(1.1);
        }
    }

    :hover {
        opacity: 1;
    }
`;

export const DockTimer = styled.div`
    color: black;
    margin-right: 10px;
`;

export const DockTaskTitle = styled.div`
    font-size: 14px;
    margin-right: 35px;
    min-width: 200px;
    color: #000000d4;
`;

export const DockTaskButton = styled.button`
    border: none;
    font-size: 10px;
    text-transform: uppercase;
    padding: 5px 10px;
    background-color: #9e37ec;
    color: white;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;

    transition: transform 0.3s ease;

    :hover {
        transform: scale(1.1);
    }
`;
