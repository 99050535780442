import React from 'react';

import { Container } from './styles';

interface ListContainerProps {
    statePage?: number;
    className?: string;
    children?: React.ReactNode;
}

const ListContainer: React.FC<ListContainerProps> = ({statePage, children, className }) => {
    return (
        <Container className={className}>    
           { children }
        </Container>
    );
}

export default ListContainer;