import {
    Animation,
    Container,
    ContainerLoader,
    Element,
    ElementBody,
    ElementContainer,
    ElementHeader,
    ElementHeaderDrag,
    ElementHeaderFilter,
    ElementHeaderOptions,
    InputTitle
} from "./style";
import { ElementProps, getElementComponent, getElementObject } from "../ElementBuilder";
import { FaCopy, FaFilter, FaTrash } from "react-icons/fa";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import React, { useCallback, useEffect, useRef, useState } from "react";

import ElementMenu from "./ElementMenu";
import EmptyElement from "../EmptyState/EmptyElement";
import { Flow } from "../../interfaces/Flow";
import { MdOutlineDragIndicator } from "react-icons/md";
import MenuDivider from "../Menu/MenuDivider";
import MenuItem from "../Menu/MenuItem";
import { Panel } from "../../interfaces/Panel";
import api from "../../services/api";
import lottie from 'lottie-web';
import { useToast } from "../../hooks/toast";
import getAccessControl from "../../middlewares/AccessControl";
import { BsFillGearFill } from "react-icons/bs";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface DashboardProps {
    flow?: Flow;
    panel?: Panel;
    typeUser: string;
    loading: boolean;
    printComponentRef?: React.MutableRefObject<null>;
    onUpdatePanel: (panel: Panel) => void;
    onUpdateStatusSavedPanel: (status: number) => void;
    onCopyElement: (element: ElementProps) => void;
    onEditElement: (element: ElementProps) => void;
    onSavePanel: (panel: Panel, withLoadData?: boolean) => Promise<void>;
    loadPanel: () => void;
    loadData: () => void;
    pendingSaving: boolean;
    setPendingSaving: React.Dispatch<React.SetStateAction<boolean>>;
}

const Dashboard: React.FC<DashboardProps> = ({ flow, panel, loading, typeUser, printComponentRef, onUpdatePanel, onUpdateStatusSavedPanel, onCopyElement, loadPanel, onSavePanel, onEditElement, pendingSaving, setPendingSaving }) => {

    const [layout, setLayout] = useState<Layout[]>([]);
    const lottieContainers = useRef<(HTMLDivElement | null)[]>([]);
    const lottieContainer = useRef<HTMLDivElement>(null);
    const { addToast } = useToast();

    const onDeleteElement = useCallback(async (hash: string) => {

        let newPanel = panel;

        onUpdateStatusSavedPanel(3);

        if (panel !== undefined && flow !== undefined) {

            api
                .delete('/panel/element', {
                    params: {
                        hash: hash,
                        panel_id: panel.id_panel,
                        flow_id: flow.id_flow
                    }
                })
                .then(response => {

                    if (newPanel !== undefined) {
                        //Delete the element from Panel
                        newPanel.elements = newPanel.elements?.filter((e) => e.hash !== hash);
                        newPanel.pendingUpd = true;

                        onUpdatePanel(newPanel);

                        onUpdateStatusSavedPanel(1);
                    }

                }).catch(error => {
                    console.log(error);
                    onUpdateStatusSavedPanel(1);
                    addToast({
                        type: 'error',
                        title: 'Erro ao deletar o elemento',
                        description: 'Ocorreu um erro ao deletar o elemento!',
                    });
                });

        }


    }, [addToast, flow, onUpdatePanel, onUpdateStatusSavedPanel, panel]);

    const onChangeTitle = useCallback(async (title: string, hash?: string) => {

        let newPanel = panel;

        if (newPanel !== undefined && hash !== undefined) {
            newPanel?.elements?.map((e) => {
                if (e.hash === hash) {
                    e.title = title;
                }
                return e;
            })

            newPanel.pendingUpd = true;

            onUpdatePanel(newPanel);

            if (!pendingSaving) {
                setPendingSaving(true);
                onUpdateStatusSavedPanel(2);
            }
        }

    }, [pendingSaving, onUpdatePanel, panel, onUpdateStatusSavedPanel, setPendingSaving]);

    const onLayoutChange = useCallback(async (currentLayout: Layout[], allLayouts: ReactGridLayout.Layouts) => {

        setLayout(currentLayout);

        if (panel !== undefined) {
            if (panel.elements !== undefined && panel.elements.length > 0) {

                if (panel.elements.length !== layout.length) {
                    if (layout.length === 0 && panel.elements.length === 1) {
                        await onSavePanel(panel, true);
                    } else {
                        await onSavePanel(panel);
                    }

                }

                panel?.elements.map((element, index) => {

                    const elSelected = currentLayout.filter((e) => e.i === element.hash);

                    if (currentLayout[index].isResizable !== undefined) { //Bug - Primeiro save
                        element.pos_axis_x = elSelected[0].x;
                        element.pos_axis_y = elSelected[0].y;
                        element.width = elSelected[0].w;
                        element.height = elSelected[0].h;
                    }

                    return element;
                });

                if (layout !== undefined && layout.length > 0) {
                    panel.pendingUpd = true;
                    setPendingSaving(true);
                    onUpdateStatusSavedPanel(2);

                } else if (!pendingSaving) {

                    setPendingSaving(true);
                    onUpdateStatusSavedPanel(2);

                }


                onUpdatePanel(panel);
            }
        }

    }, [onUpdatePanel, panel, layout, pendingSaving, onUpdateStatusSavedPanel, onSavePanel, setPendingSaving]);

    const savePanel = useCallback(async () => {

        if (panel !== undefined) {
            await onSavePanel(panel);
        }

    }, [panel, onSavePanel]);

    useEffect(() => {

        if (panel !== undefined) {

            if (panel.elements !== undefined && panel.elements.length > 0) {

                let newLayout: Layout[] = [];

                for (let index = 0; index < panel.elements.length; index++) {
                    const element = panel.elements[index];

                    const newElement: Layout = {
                        i: element.hash,
                        x: element.pos_axis_x !== undefined ? element.pos_axis_x : 0,
                        y: element.pos_axis_y !== undefined ? element.pos_axis_y : 0,
                        w: element.width !== undefined ? element.width : getElementObject(element.type).defW,
                        h: element.height !== undefined ? element.height : getElementObject(element.type).defH,
                        minW: getElementObject(element.type).minW,
                        minH: getElementObject(element.type).minH,
                        isResizable: getElementObject(element.type).isResizable
                    }

                    newLayout.push(newElement);

                }

                if (panel.pendingUpd) {
                    setPendingSaving(true);
                    onUpdateStatusSavedPanel(2);
                }

                if (panel.pendingUpdData) {
                    loadPanel();
                }

                setLayout(newLayout);

            }

        } else if (!loading) {
            loadPanel();
        }

    }, [flow, panel, loadPanel, loading, onUpdateStatusSavedPanel, setPendingSaving]);

    useEffect(() => {
        let interval = setInterval(() => {
            if (pendingSaving) {
                savePanel();

                setPendingSaving(false);
                onUpdateStatusSavedPanel(1);
            }
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, [pendingSaving, savePanel, onUpdateStatusSavedPanel, setPendingSaving]);

    useEffect(() => {
        lottieContainers.current.forEach((container) => {
            if (container) {
                lottie.loadAnimation({
                    container,
                    renderer: "svg",
                    loop: true,
                    autoplay: true,
                    animationData: require("../../assets/lottie/loader.json"),
                });
            }
        });
    }, [loading]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../assets/lottie/loader.json')
            })
        }
    }, [loading]);

    return (
        <>
            {panel !== undefined ?
                <Container ref={printComponentRef}>
                    <ResponsiveGridLayout
                        className="layout"
                        layouts={{ lg: layout, md: layout, sm: layout, xs: layout, xxs: layout }}
                        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                        cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                        onLayoutChange={onLayoutChange}
                        draggableHandle=".drag-handle"
                    >
                        {panel?.elements?.map((element, index) => {

                            const ElementComponent = getElementComponent(element.type);

                            const length = panel?.elements?.length !== undefined ? panel?.elements?.length : 0;

                            const title = element.title !== undefined ? element.title : "";

                            return (
                                <Element key={element.hash} zIndex={length - index}>
                                    <ElementContainer>
                                        <ElementHeader>
                                            {getAccessControl(53, typeUser) ?
                                                <ElementHeaderDrag className="drag-handle">
                                                    <MdOutlineDragIndicator />
                                                </ElementHeaderDrag> :
                                                <></>
                                            }
                                            <InputTitle
                                                maxLength={45}
                                                defaultValue={title}
                                                onChange={(e) => onChangeTitle(e.currentTarget.value, element.hash)}
                                                disabled={!getAccessControl(54, typeUser)}
                                            />
                                            {element.filter !== undefined && element.filter !== null && element.filter !== "null" && (
                                                <ElementHeaderFilter onClick={() => getAccessControl(52, typeUser) ? onEditElement(element) : undefined}>
                                                    <FaFilter />
                                                </ElementHeaderFilter>
                                            )}
                                            {getAccessControl(52, typeUser) ?
                                                <ElementHeaderOptions>
                                                    <ElementMenu>
                                                        <MenuItem title="Editar" icon={BsFillGearFill} color={"#61bd4f"} onClick={() => onEditElement(element)} />
                                                        <MenuItem title="Duplicar" icon={FaCopy} color={"#61bd4f"} onClick={() => onCopyElement(element)} />
                                                        <MenuDivider />
                                                        <MenuItem title="Excluir" icon={FaTrash} color={"#ff0000"} onClick={() => onDeleteElement(element.hash)} />
                                                    </ElementMenu>
                                                </ElementHeaderOptions> :
                                                <></>
                                            }
                                        </ElementHeader>
                                        <ElementBody>
                                            {loading ?
                                                <ContainerLoader id={"loading-" + index}>
                                                    <Animation
                                                        id={"loading2-" + index}
                                                        className="lottieContainer"
                                                        ref={(el) =>
                                                        (lottieContainers.current[
                                                            index
                                                        ] = el)
                                                        }
                                                    />
                                                </ContainerLoader> :
                                                <ElementComponent
                                                    id_panel_element={element.id_panel_element}
                                                    hash={element.hash}
                                                    panel_id={element.panel_id}
                                                    title={element.title}
                                                    help_text={element.help_text}
                                                    type={element.type}
                                                    axis_x_field_id={element.axis_x_field_id}
                                                    axis_x_field_type={element.axis_x_field_type}
                                                    axis_y_field_id={element.axis_y_field_id}
                                                    axis_y_field_type={element.axis_y_field_type}
                                                    axis_y_field_variation={element.axis_y_field_variation}
                                                    group_by_field_id={element.group_by_field_id}
                                                    group_by_field_type={element.group_by_field_type}
                                                    group_by_field_date={element.group_by_field_date}
                                                    calc_function={element.calc_function}
                                                    show_time={element.show_time}
                                                    filter={element.filter}
                                                    group_date={element.group_date}
                                                    color={element.color}
                                                    sort_by={element.sort_by}
                                                    width={element.width}
                                                    height={element.height}
                                                    embed={element.embed}
                                                    dataElement={element.dataElement}
                                                />
                                            }
                                        </ElementBody>
                                    </ElementContainer>
                                </Element>
                            )

                        })}
                    </ResponsiveGridLayout>

                    {panel !== undefined && (panel.elements === undefined || panel.elements.length <= 0) ?
                        <EmptyElement /> :
                        <></>
                    }

                </Container > :
                <Animation className="lottieContainer" ref={lottieContainer} />
            }
        </>
    );

}

export default Dashboard;