import styled from "styled-components";

export const Container = styled.div`

    margin-top: 15px;

    .ql-toolbar.ql-snow {
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .ql-container.ql-snow {
        border: 1px solid #ccc !important;
        border-radius: 5px;
    }
`;
