import React from 'react';
import ReactDOM from 'react-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { TourProvider } from "@reactour/tour";

import App from './App';
import { ButtonTour, ButtonTourBack } from './utils/Steps/styles';
import { stepsOnboardingFlow } from "./utils/Steps";

ReactDOM.render(
  <React.StrictMode>
    <TourProvider
      steps={stepsOnboardingFlow}
      showCloseButton={true}
      disableFocusLock={true}
      disableInteraction={false}
      onClickMask={({ setCurrentStep, currentStep, setIsOpen }) => {
        if (currentStep === stepsOnboardingFlow.length - 1) {
          setIsOpen(false)
        }
        setCurrentStep(s => (s === stepsOnboardingFlow.length - 1 ? 0 : s + 1))
      }}
      styles={{
        popover: base => ({
          ...base,
          borderRadius: "5px",
          maxWidth: "520px"
        }),
        badge: base => ({
          ...base,
          backgroundColor: "#f23b5c"
        }),
        dot: base => ({
          ...base,
          display: "none"
        }),
        controls: base => ({
          ...base,
          marginTop: 0
        }),
      }}
      nextButton={({
        Button,
        currentStep,
        stepsLength,
        setIsOpen,
        setCurrentStep,
      }) => {
        const last = currentStep === stepsLength - 1
        return (
          <ButtonTour
            onClick={() => {
              if (last) {
                setIsOpen(false)
              } else {
                setCurrentStep(s => (s === stepsOnboardingFlow.length - 1 ? 0 : s + 1))
              }
            }}
          >
            Próximo
          </ButtonTour>
        )
      }}
      prevButton={({ currentStep, stepsLength, setIsOpen, setCurrentStep }) => {
        const first = currentStep === 0
        //const next = first ? stepsOnboardingFlow.length - 1 : currentStep - 1
        return (
          first ?
            (
              <></>
            ) :
            (
              <ButtonTourBack
                className="custom-btn"
                onClick={() => {
                  if (first) {
                    setCurrentStep(s => stepsOnboardingFlow.length - 1)
                  } else {
                    setCurrentStep(s => s - 1)
                  }
                }}
              >
                <FiArrowLeft />
              </ButtonTourBack>
            )
        )
      }}
    >
      <App />
    </TourProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
