import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import styled, { css } from "styled-components";
import Button from '../../components/Button';
import { Dialog } from '@material-ui/core';

interface IconHeaderProps {
    color: string;
}

interface BtnInsertNewAnswerProps {
    color: string;
}

export const DialogContainer = styled(Dialog)`

    .MuiPaper-root {
        border-radius: 10px;
    }

    .MuiDialog-paperWidthMd {
        max-width: 800px;
    }

`;

export const Content = styled(DialogContent)`
    background-color: white;   
    max-width: 800px;
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: auto;
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const DialogTop = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #eef2f8;
    margin-top: 15px;
    width: 100%;
    
`;

export const DialogTopLeft = styled.div`
    width: 65%;
    height: 100%;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: start;
    padding-bottom: 3px;
    padding-top: 3px;
    margin-left: 20px;

    h1 {
        margin-left: 10px;
        font-size: 20px;
        font-weight: 500;
        color: #060606de;
    }
`;

export const AutomationConfigItemImage = styled.div<{ size?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
    padding: 5px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    ${props => props.size && css`
        width: ${props.size}px;
        height: ${props.size}px;
        min-width: ${props.size}px;
        min-height: ${props.size}px;
        
        svg {
            width: ${props.size}px;
            height: ${props.size}px
        }
    `}
`;

export const DialogTopCenter = styled.div`
    width: 25%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
`;

export const DialogTopRight = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 30px;
        border: none;
        background-color: transparent;
    }
`;

export const IconHeader = styled.span<IconHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 100%;
    background-color: transparent;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 45px;
        height: 45px;
        background-color: #f4f4f4;
        border-radius: 50%;

        > svg {
            width: 25px;
            height: 25px;

            ${props => props.color && css`
                color: ${props.color} !important;
            `}
        }

    }
`;

export const DialogFooter = styled.div`
  border-top: 1px solid #eef2f8;
  padding: 12px !important;
  width: 100%;
`;

export const DialogFooterCenter = styled.div`
    display: flex;
    justify-content: flex-end;
    align-content: center;
    width: 100%;
`;

export const StatusContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
`;

export const StatusBox = styled.div<{ color: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    padding: 3px 8px;
    border-radius: 5px;
    height: 25px;
    user-select: none;

    span {
        font-size: 12px;        
    }

    svg {
        font-size: 13px;
        margin-right: 5px;
    }

    ${props => props.color && css`
        border: 1px solid ${props.color + "1e"};
        color: ${props.color};
    `}

`;

export const BtnInsertNewAnswer = styled(Button) <BtnInsertNewAnswerProps>`
    height: 50px;
    width: 95%;
    background-color: #f23b5c;
    color: white;
    border-radius: 5px;
    border: 0;
    font-weight: 500;
    font-size: 15px;

    ${props => props.color && css`
        background-color: ${props.color} !important;
    `}
`;

export const ContainerForm = styled.div`
    width: 100%;
    padding: 15px;
    padding-top: 0px;
    padding-bottom: 0px;

    form {
        padding-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 0px;

        .cange-form-title {
            font-size: 14px;
        }

        .cange-form-title-required {
            font-size: 14px;
        }

        .cange-form-description {
            font-size: 12px;
            color: gray;
            font-weight: 400;
        }

        .cange-form-error {
            font-size: 12px;
        }

        .cange-form-error-icon {
            font-size: 12px;
            height: 12px;
            width: 12px;
        }

        .cange-form-help-text {
            margin-top: 0px;
            font-size: 12px;
        }
    }
`;

export const ContainerTypeAuth = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
`;

export const ButtonTypeAuth = styled.button<{ selected?: boolean }>`    
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;    
    padding: 5px 10px;
    margin: 5px;    
    font-size: 13px;
    height: 35px;    
    min-width: 130px;
    border: 1px solid #eef2f8;
    border-radius: 5px;
    margin-right: 10px;
    margin-top: 10px;
    
    svg {
        margin-right: 10px;
        font-size: 16px;
    }

    :hover {
        background-color: #f4f4f4;
        border-radius: 5px;
    }

    ${props => props.selected && css`
        background-color: #f4f4f4;
        border-radius: 5px;
        font-weight: 500;
    `}
`;

export const IntegrationComboContainerTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: black;
    margin-bottom: 5px;
    margin-left: 5px;
`;

export const IntegrationComboContainerDescription = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: gray;
    margin-bottom: 5px;
    margin-left: 5px;
`;