import React from "react";

import {
    Container
} from "./style";

const DegradeLine: React.FC = () => {

    return (
        <Container/>
    );

}

export default DegradeLine;