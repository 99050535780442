import styled, { keyframes } from 'styled-components';

import Button from '../../../components/Button';
import background from '../../../assets/background_gradient.png';

export const Container = styled.div`
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;    
    justify-content: center;
    background: url(${background}) no-repeat center;

    flex: 1;
    
    background-color: #8080801c;
    background-size: cover;

    @media(max-width: 750px) {
        background: none;
        background-color: white;
    }

`;

export const ContainerMobile = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;    
    justify-content: center;
    background: url(${background}) no-repeat center;

    flex: 1;
    
    background-color: #8080801c;
    background-size: cover;

    @media(max-width: 750px) {
        background: none;
        background-color: white;
    }

`;


export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    border-radius: 25px;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);

    width: 100%;
    height: auto;
    //min-width: 550px;
    min-height: 500px;
    max-width: 700px;
    max-height: 650px;
    background-color: white;

    img {
        margin-bottom: 35px;        
        height: 72px;
        width: 370px;
    }

    @media(max-width: 750px) {
        box-shadow: none;
    }

`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    } 
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const AnimationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    form {
        margin: 80px 0;
        min-width: 400px;
        text-align: center;

        h1 {
            color: black;
            margin-bottom: 35px;
        }    

        button {
            margin-top: 60px;
        }
    }
`;

export const BackGround = styled.div`
    flex: 1;
    background-color: #f6f8f9;
    background-size: cover;
`;

export const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 20px;

    border-bottom: 1px solid #80808040;

    @media(max-width: 800px) {
        padding: 20px;
        padding-left: 10px;
    }
`;

export const HeaderLeft = styled.div`
    width: 100%;

    h1 {
        font-size: 24px;
        color: black;
        font-weight: bold;
        font-family: Roboto, helvetica;
        letter-spacing: 0.8px;
        font-weight: 500;

        @media(max-width: 800px) {
            font-size: 22px;
        }
    }

    h3 {
        color: #676879;
        text-align: left;
        margin-top: 8px;
        font-weight: 400;
        font-size: 16px;
        margin-block-end: unset; 
    }
    
`;

export const HeaderRight = styled.div`
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 150px;

    img {
        margin-bottom: 0px !important;
        height: auto;
        width: 170px;
    }

    .logo-mini-cange {
        display: none;        
    }

    @media(max-width: 800px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 0px 0px 0px 0px;

        .logo-cange {
            display: none;    
        }

        .logo-mini-cange {
            display: flex;  
            width: 100%;
            max-width: 130px;
            height: auto;  
        }
    }
`;

export const Body = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start; 
    width: auto;
    max-width: 100%;
    height: 100%;

    form {
        margin: 0px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    @media(max-width: 750px) {
        form {
            min-width: auto;
        }
    }
`;

export const ContainerStep = styled.div`

    margin-top: 30px;

    padding: 0px 10%;

    h1 {
        animation: ${appearFromLeft} 1s;
        font-size: 24px;
        color: black;
        font-weight: 500;
        font-family: Roboto, helvetica;
        margin-bottom: 40px;
    }   

    @media(max-width: 750px) {
        h1 {
            font-size: 24px;
        }

        padding-right: 20px;
        padding-left: 20px;
    }

`;

export const ContainerStart = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;

        display: block;
        justify-content: center;
        align-items: center;
`;

export const ContainerInput = styled.div`
    margin-bottom: 10px;
    width: 100%;
    animation: ${appearFromLeft} 1s;

    div.container-input {
        border: 2px solid rgba(147,55,237,1);
        height: 48px;
        border-radius: 5px;
    }

    input {
        font-size: 14px;        

        ::placeholder {            
            font-size: 15px;                        
        }
    }

    svg.icon-input {
        color: rgba(147,55,237,1) !important;
    }
`;

export const ContainerButton = styled.div`

    animation: ${appearFromLeft} 1s;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;

    button {
        margin: 0px;
        padding: 0px;            
    }

`;

export const BtnStart = styled(Button)`
    margin-top: 0px !important;
    height: 45px !important;
    width: 170px;
    margin-left: 30px;
    //padding-right: 25px;
    margin-right: 15px;
    
    background: rgba(147,55,237,1);
    margin-bottom: 10px;

    :hover {
        background: rgba(147,55,237,0.7);
    }
`;

export const NoAccount = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 550px;

    padding-top: 30px;
    color: #6f7782;
    font-size: 17px;

`;

export const Disclaimer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;


    margin-top: 60px;
    color: #6f7782;
    font-size: 13px;

    p {
        margin: 0px 15px;
    }

    @media(max-width: 750px) {
        h1 {
            font-size: 18px;
        }

        max-width: 550px;
        padding-right: 20px;
        padding-left: 20px;
    }

`;

export const ContainerClaim = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;    
    font-size: 25px;
    margin-top: 80px;

    padding-right: 30px;
    padding-left: 30px;
`;

export const Claim = styled.div`
    width: 40%;
    padding-left: 15px;
    padding-right: 15px;
    color: #000000e6;
    font-size: 13px;

    @media(max-width: 750px) {
        width: 50%;
    }
`;

export const ContainerTopAction = styled.div`
    position: absolute;
    top: 30px;
    right: 30px;

    display: flex;
    justify-content: flex-end;
    align-items: center;       
    
    a {
        text-decoration: none;
    }
`;

export const AksToAction = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;    
    font-size: 15px;
    color: white;
    font-weight: 500;
`;

export const BtnAction = styled(Button)`    
    height: 40px !important;
    width: 110px;
    margin-left: 20px;
    font-size: 15px;
    text-decoration: none;

    background: white;
    color: #000000e6;

    :hover {
        opacity: 0.8;
        background-color: white;
    }
`;