import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
    AddNewColumn,
    Animation,
    BoxContainer,
    BoxItem,
    BoxItemsContainer,
    BoxSpan,
    ChartItem,
    ChartItemBody,
    ChartItemTitle,
    ColumnReport,
    ColumnReportRemove,
    ColumnReportTitle,
    ColumnsContainer,
    Container,
    ContainerBody,
    ContainerContent,
    ContainerGroup,
    ContainerLeft,
    ContainerLoader,
    ContainerRight,
    DividerGroup,
    DrillDownItem,
    DrillDownItemBody,
    DrillDownItemHeader,
    DrillDownItemHeaderColumn,
    DrillDownItemHeaderName,
    GroupBody,
    GroupHeader,
    GroupName,
    HelpText,
    KpiContainer,
    KpiDescription,
    KpiIconContainer,
    KpiInformationContainer,
    KpiValue,
    NoResults,
} from './style';
import { useAuth } from '../../../hooks/auth';
import TimeSheetFilterBar from './TimeSheetFilterBar';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import { Card } from '../../../interfaces/Card';
import { FaCaretDown, FaCaretRight, FaPlus, FaUserFriends } from 'react-icons/fa';
import { Flow } from '../../../interfaces/Flow';
import getAggregateObject, { TimeSheetDataTableProps } from './getAggregateObject';
import { FiHelpCircle } from 'react-icons/fi';
import getFieldsFromCard from './getFieldsFromCard';
import { ClickAwayListener } from '@material-ui/core';
import { IoCloseCircle } from 'react-icons/io5';
import AvatarCange from '../../../components/AvatarCange';
import { User } from '../../../interfaces/User';
import getDrillDownObject from './getDrillDownObject';
import TimeSheetRankingChart from './TimeSheetRankingChart';
import getTimeSheetByDay from './getTimeSheetByDay';
import TimeSheetLineChart from './TimeSheetLineChart';
import { AiFillClockCircle } from 'react-icons/ai';
import getInsightsFromCards, { TimeSheetInsightsProps } from './getInsightsFromCards';
import Dialog from "../../../dialogs/Dialog";
import lottie from "lottie-web";
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import FreemiumFeature from '../../../components/Fremium/FreemiumFeature';
import { getFeatureControlByPlan } from '../../../middlewares/FeatureControl';

interface TimeSheetProps {

}

export interface TimeSheetFieldProps {
    id_field: number;
    name: string;
    title: string;
    type: string;
    index: number;
    flow_id?: number;
    register_id?: number;
    origin: string;
    typeOrigin: 'FormAnswer' | 'TimeTracking' | 'Card';
    indexOrigin: number;
    fieldsGroup?: TimeSheetFieldProps[];
}

//Function to get the first day of the week
const getFirstDayWeek = (date: Date) => {
    const firstDay = date.getDate() - date.getDay();
    return new Date(date.setDate(firstDay));
};

//Function to get the last day of the week
const getLastDayWeek = (date: Date) => {
    const lastDay = date.getDate() - date.getDay() + 6;
    return new Date(date.setDate(lastDay));
};

const TimeSheet: React.FC<TimeSheetProps> = (props) => {

    const { user } = useAuth();
    const { addToast } = useToast();

    const [startDate, setStartDate] = useState<Date>(getFirstDayWeek(new Date()));
    const [endDate, setEndDate] = useState<Date>(getLastDayWeek(new Date()));

    const [users, setUsers] = useState<User[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);

    const [data, setData] = useState<TimeSheetDataTableProps[]>([]);
    const [cards, setCards] = useState<Card[]>([]);

    const [selectedFlows, setSelectedFlows] = useState<number[]>([]);
    const [flowsBase, setFlowsBase] = useState<Flow[]>([]);

    const [fields, setFields] = useState<TimeSheetFieldProps[]>([]);
    const [fieldsSelected, setFieldsSelected] = useState<TimeSheetFieldProps[]>([]);
    const [openSelectField, setOpenSelectField] = useState(false);
    const [expandedFieldGroups, setExpandedFieldGroups] = useState<string[]>([]);

    const [hierarchicalData, setHierarchicalData] = useState<TimeSheetDataTableProps[]>();
    const [rankingData, setRankingData] = useState<TimeSheetDataTableProps[]>();
    const [timeSheetByDate, setTimeSheetByDate] = useState<TimeSheetDataTableProps[]>();
    const [expandedDataGroups, setExpandedDataGroups] = useState<string[]>([]);

    const [insights, setInsights] = useState<TimeSheetInsightsProps>();

    const [optArchived, setOptArchived] = useState<boolean>(true);

    const [loading, setLoading] = useState<boolean>(true);
    const lottieContainer = useRef<HTMLDivElement>(null);

    const [open, setOpen] = React.useState(false);
    const [dialogProps, setDialogProps] = React.useState({ title: '', value: '' });

    const handleDialog = (title: string, value: string) => {
        setDialogProps({
            title: title,
            value: value
        });
        setOpen(!open);
    };

    const handleClickAway = () => {
        setOpenSelectField(false);
    };

    const formatDuration = (timeInSeconds: number): string => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    const toggleDataGroup = (origin: string) => {
        if (expandedDataGroups.includes(origin)) {
            setExpandedDataGroups(expandedDataGroups.filter((group) => group !== origin));
        } else {
            setExpandedDataGroups([...expandedDataGroups, origin]);
        }
    };

    const renderLevel = (hierarchicalDataObject: TimeSheetDataTableProps[], fields: TimeSheetFieldProps[], currentLevel: number): any => {

        const objBase = hierarchicalDataObject;

        return objBase.map((item) => {

            //Get if is the last level
            const isLastLevel = currentLevel === fields.length;
            let lastLevelData = item !== undefined ? item.items : undefined;

            //Control the expanded data
            const keyExpand = item.title !== undefined ? item.title + currentLevel : "0" + currentLevel;
            let isExpanded = expandedDataGroups.includes(keyExpand);

            //Valid if is multiple of 2
            const isEven = currentLevel % 2 === 0;

            return (
                <DrillDownItem key={keyExpand} level={currentLevel}>
                    <DrillDownItemHeader onClick={() => toggleDataGroup(keyExpand)}>

                        {isExpanded ?
                            <FaCaretDown /> :
                            <FaCaretRight />
                        }
                        <DrillDownItemHeaderName>{item.title}</DrillDownItemHeaderName>
                        <DrillDownItemHeaderColumn isEven={isEven}>{item.duration !== undefined ? formatDuration(item.duration) : 0}</DrillDownItemHeaderColumn>

                    </DrillDownItemHeader>
                    <DrillDownItemBody>
                        {isLastLevel ? (
                            isExpanded && (
                                <>
                                    {lastLevelData !== undefined && lastLevelData.length > 0 && lastLevelData.map((item: TimeSheetDataTableProps, index: number) => {

                                        if (item === undefined) {
                                            return <></>;
                                        }

                                        return (
                                            <DrillDownItemHeader key={index} style={{ marginLeft: '40px', marginRight: '10px' }}>

                                                <AvatarCange user={item.user as User} size={"25"} />
                                                <DrillDownItemHeaderName style={{ marginLeft: '10px' }}>{item.user?.name}</DrillDownItemHeaderName>
                                                <DrillDownItemHeaderColumn style={{ marginLeft: '10px' }}>{item.duration !== undefined ? formatDuration(item.duration) : 0}</DrillDownItemHeaderColumn>

                                            </DrillDownItemHeader>
                                        )
                                    })}
                                </>
                            )
                        ) :
                            isExpanded && item.items !== undefined && item.items.length > 0 && (
                                renderLevel(item.items, fields, currentLevel + 1)
                            )}
                    </DrillDownItemBody>
                </DrillDownItem>
            );
        });

    };

    const groupedFields: { [groupField: string]: TimeSheetFieldProps[] } = fields.sort((a, b) => a.indexOrigin - b.indexOrigin).reduce(
        (acc, item) => {

            if (item.origin !== undefined) {
                if (acc[item.origin]) {
                    acc[item.origin].push(item);
                } else {
                    acc[item.origin] = [item];
                }
            }

            return acc;
        },
        {} as { [groupField: string]: TimeSheetFieldProps[] }
    );

    const toggleGroup = (origin: string) => {
        if (expandedFieldGroups.includes(origin)) {
            setExpandedFieldGroups(expandedFieldGroups.filter((group) => group !== origin));
        } else {
            setExpandedFieldGroups([...expandedFieldGroups, origin]);
        }
    };

    const downloadCSVData = useCallback(async () => {

        if (!getFeatureControlByPlan(6, user.company)) {
            addToast({
                type: 'error',
                title: 'Recurso disponível apenas para o Plano Professional!',
                description: 'Para ter acesso a este recurso, faça o upgrade para o Plano Professional!'
            });
        }

        try {

            if (data !== undefined && data.length >= 0) {

                //Create the object to export
                let obj: object[] = [];
                for (let index = 0; index < data.length; index++) {
                    const d = data[index];

                    let objCard: any = {
                        dataStart: format(startDate, 'dd/MM/yyyy', { locale: ptBR }),
                        dataEnd: format(endDate, 'dd/MM/yyyy', { locale: ptBR }),
                        id_user: d.user?.id_user,
                        name: d.user?.name,
                        email: d.user?.email,
                        durationFormated: d.duration !== undefined ? formatDuration(d.duration) : 0,
                        duration: d.duration !== undefined ? d.duration : 0
                    }

                    //Run into d fields to get the values
                    Object.entries(d).forEach(([key, value]) => {
                        if (key !== "user_id" && key !== "user" && key !== "duration") {
                            objCard[key] = value;
                        }
                    });

                    obj.push(objCard);
                }

                const csv = Papa.unparse(obj);
                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
                saveAs(blob, 'TimeSheet - Cange - ' + format(startDate, 'dd/MM/yyyy', { locale: ptBR }) + ' - ' + format(endDate, 'dd/MM/yyyy', { locale: ptBR }) + '.csv');

            } else {
                addToast({
                    type: 'error',
                    title: 'Não foi possível encontrar dados para exportar!',
                    description: 'Tente redefinir o período e os filtros novamente!',
                });
            }

        } catch (error) {
            addToast({
                type: 'error',
                title: 'Erro ao carregar os dados do Fluxo',
                description: 'Ocorreu ao tentar carregar os dados do Fluxo!',
            });
        }

    }, [addToast, data, endDate, startDate, user.company]);

    const addNewField = useCallback(async (field: TimeSheetFieldProps) => {

        let newField = field;

        newField.index = fieldsSelected.length + 1;

        setFieldsSelected([...fieldsSelected, field]);

    }, [fieldsSelected]);

    const removeField = useCallback(async (field: TimeSheetFieldProps) => {

        //Remove the field
        let newFields = fieldsSelected.filter(f => f.id_field !== field.id_field);

        //Reorder the fields
        newFields = newFields.map((f, index) => {
            f.index = index + 1;
            return f;
        });

        setFieldsSelected(newFields);
        //setData([]);

    }, [fieldsSelected]);

    const getApiMyFlows = useCallback(async () => {

        await api.get(`/flow/my-flows`, {
            params: {

            }
        }).then(response => {
            if (response.data !== null) {

                const respFlow: Flow[] = response.data;

                setFlowsBase(respFlow);

            }
        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Não foi possível buscar os seus fluxos!',
                description: 'Ocorreu ao tentar carregar os dados dos fluxos!',
            });
        });

    }, [addToast]);

    const getApiUsers = useCallback(async () => {

        setLoading(true);

        await api.get(`/user/by-company`, {
        }).then(response => {
            if (response.data !== null) {

                const respUser: User[] = response.data;

                setUsers(respUser);

            }
        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Não foi possível buscar todos os usuários!',
                description: 'Ocorreu ao tentar carregar os dados dos usuários!',
            });
        });

    }, [addToast]);

    const getApiPanelData = useCallback(async (
        selectedFlows: number[],
        selectedUsers: number[],
        optArchived: boolean,
        startDate: Date,
        endDate: Date
    ) => {

        setLoading(true);

        let startDateSearch = startDate;
        let endDateSearch = endDate;

        //Valid if the difference between the dates is more than 31 days
        const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays > 31) {
            addToast({
                type: 'error',
                title: 'Período muito longo! Maior do que 31 dias!',
                description: 'O período selecionado é muito longo, tente um período menor!',
            });

            //Set the current week to the dates
            setStartDate(getFirstDayWeek(new Date()));
            setEndDate(getLastDayWeek(new Date()));

            startDateSearch = getFirstDayWeek(new Date());
            endDateSearch = getLastDayWeek(new Date());
        }

        //Set hour 00:00
        startDateSearch.setHours(0, 0, 0, 0);
        endDateSearch.setHours(0, 0, 0, 0);

        //Format dtStart and dtEnd to yyyy-mm-dd
        const dtStart = startDateSearch.toISOString().split('T')[0];
        const dtEnd = endDateSearch.toISOString().split('T')[0];

        await api.post(`/time-tracking/time-sheet`, {
            flows_id: selectedFlows,
            users_id: selectedUsers,
            isFilteringArchived: optArchived ? 'true' : undefined,
            date_start: dtStart,
            date_end: dtEnd,
        }).then(response => {
            if (response.data !== null) {

                const respCards: Card[] = response.data;

                const fields = getFieldsFromCard(respCards);

                setFields(fields);

                setCards(respCards);

            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            addToast({
                type: 'error',
                title: 'Erro ao carregar as informações do Time sheet!',
                description: 'Ocorreu ao tentar carregar os dados do Time sheet!',
            });
        });

    }, [addToast]);

    useEffect(() => {

        //Just one time
        getApiMyFlows();

        getApiUsers();

    }, [getApiMyFlows, getApiUsers]);

    useEffect(() => {

        if (data !== undefined && data.length > 0 && fieldsSelected !== undefined && fieldsSelected.length > 0) {

            const hierarchicalData = getDrillDownObject(data, fieldsSelected, 1);

            setHierarchicalData(hierarchicalData);

        }

    }, [data, fieldsSelected]);


    useEffect(() => {

        if (cards !== undefined) {
            if (cards.length > 0) {

                const newData: TimeSheetDataTableProps[] = getAggregateObject(cards, fieldsSelected);

                if (newData !== undefined && newData.length > 0) {
                    setData(newData);
                }

            }
        }

        if (cards !== undefined) {
            const newRanking = getAggregateObject(cards);

            setRankingData(newRanking);

            const newTimeSheetByDay = getTimeSheetByDay(cards);

            setTimeSheetByDate(newTimeSheetByDay);

            const newInsights = getInsightsFromCards(cards);

            setInsights(newInsights);

        }

    }, [cards, fieldsSelected]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../assets/lottie/loader.json')
            })
        }
    }, [loading]);

    return (
        <>
            <Dialog
                title={dialogProps.title}
                value={dialogProps.value}
                status={open}
                handleDialog={() => setOpen(!open)}
            />

            <FreemiumFeature
                title={"Painel de Insights"}
                description={"O Painel de Insights do Cange oferece uma visão completa e detalhada sobre o tempo dedicado a cada tarefa e processo. Ele é projetado para quem deseja maximizar a produtividade, proporcionando um acompanhamento preciso do andamento de atividades. Com ele, você pode monitorar o tempo investido, identificar áreas que precisam de ajustes e melhorar a eficiência geral da sua equipe."}
                isPaidFeature={!getFeatureControlByPlan(6, user.company)}
                buttonText={"Fazer Upgrade"}
                planRequired={"Professional"}
                maxWidth={500}
            >
                <TimeSheetFilterBar
                    flowsBase={flowsBase}
                    usersBase={users}
                    startDate={startDate}
                    endDate={endDate}
                    selectedFlows={selectedFlows}
                    selectedUsers={selectedUsers}
                    optArchived={optArchived}
                    setOptArchived={setOptArchived}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setSelectedFlows={setSelectedFlows}
                    setSelectedUsers={setSelectedUsers}
                    downloadCSV={downloadCSVData}
                    forceUpdate={() => getApiPanelData(selectedFlows, selectedUsers, optArchived, startDate, endDate)}
                />

                <ContainerBody>
                    <ContainerContent>

                        <Container>
                            <ContainerLeft
                                item
                                xs={12}
                                sm={12}
                                md={8}
                                lg={8}
                                style={{ marginBottom: '10px', maxHeight: '85vh', overflowY: 'auto' }}
                            >

                                {loading ?
                                    <ContainerLoader>
                                        <Animation className="lottieContainer" ref={lottieContainer} />
                                    </ContainerLoader > :
                                    <>
                                        {insights !== undefined && (
                                            <ChartItem>
                                                <ChartItemTitle>
                                                    Insights

                                                    <HelpText type="button" onClick={() => handleDialog(
                                                        'Insights',
                                                        'Aqui são exibidos alguns indicadores importantes para o acompanhamento do tempo de trabalho. Entenda quanto o seu time trabalhou e quantas pessoas estiveram envolvidas nas atividades.'
                                                    )}>
                                                        <FiHelpCircle />
                                                    </HelpText>
                                                </ChartItemTitle>

                                                <ChartItemBody style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                    <KpiContainer color={"#f23b5d1e"}>
                                                        <KpiIconContainer
                                                            color={"#f23b5d"}
                                                            activeMotion={true}
                                                        >
                                                            <AiFillClockCircle />
                                                        </KpiIconContainer>
                                                        <KpiInformationContainer>
                                                            <KpiValue>
                                                                {insights.totDuration !== undefined ? formatDuration(insights.totDuration) : 0}
                                                            </KpiValue>
                                                            <KpiDescription>
                                                                Total de horas trabalhadas
                                                            </KpiDescription>
                                                        </KpiInformationContainer>
                                                    </KpiContainer>

                                                    <KpiContainer color={"#ff8c2f1e"}>
                                                        <KpiIconContainer
                                                            color={"#ff8c2f"}
                                                            activeMotion={true}
                                                        >
                                                            <FaUserFriends />
                                                        </KpiIconContainer>
                                                        <KpiInformationContainer>
                                                            <KpiValue>
                                                                {insights.totUsers !== undefined ? insights.totUsers : 0}
                                                            </KpiValue>
                                                            <KpiDescription>
                                                                Total de colaboradores
                                                            </KpiDescription>
                                                        </KpiInformationContainer>
                                                    </KpiContainer>
                                                </ChartItemBody>

                                            </ChartItem>
                                        )}


                                        <ChartItem>
                                            <ChartItemTitle>
                                                Tabela dinâmica

                                                <HelpText type="button" onClick={() => handleDialog(
                                                    'Tabela dinâmica',
                                                    'Aqui você pode montar a sua própria tabela dinâmica, escolhendo os campos que deseja visualizar. Aproveite para personalizar a visualização e entender como o tempo de trabalho é distribuído com as informações que são mais relevantes para você e o seu negócio.'
                                                )}>
                                                    <FiHelpCircle />
                                                </HelpText>
                                            </ChartItemTitle>
                                            <ColumnsContainer>

                                                <AddNewColumn onClick={() => setOpenSelectField(!openSelectField)}>
                                                    <FaPlus />
                                                    Adicionar Campo
                                                </AddNewColumn>

                                                {fieldsSelected !== undefined && fieldsSelected.length > 0 ?
                                                    fieldsSelected.sort((a, b) => a.index - b.index).map((field) => {

                                                        return (
                                                            <ColumnReport key={field.id_field}>
                                                                <ColumnReportTitle>{field.title}</ColumnReportTitle>
                                                                <ColumnReportRemove onClick={() => removeField(field)}><IoCloseCircle /></ColumnReportRemove>
                                                            </ColumnReport>
                                                        )

                                                    }) :
                                                    <></>
                                                }

                                                {openSelectField ? (
                                                    <ClickAwayListener onClickAway={handleClickAway}>
                                                        <BoxContainer style={{ top: '280px' }}>
                                                            <BoxItemsContainer>
                                                                {fields !== undefined && fields?.length > 0 ?
                                                                    Object.entries(groupedFields).map(([origin, fields]) => {

                                                                        let isExpanded = expandedFieldGroups.includes(origin);

                                                                        if (origin === "Cadastros Compartilhados") {
                                                                            isExpanded = true;
                                                                        }

                                                                        if (fields.filter((f) => {
                                                                            const isFieldSelected = fieldsSelected.some((fs) => fs.id_field === f.id_field);

                                                                            return !isFieldSelected;
                                                                        }).length <= 0) {
                                                                            return <></>;
                                                                        }

                                                                        return (
                                                                            <ContainerGroup key={origin}>
                                                                                <GroupHeader onClick={() => toggleGroup(origin)}>
                                                                                    <GroupName>
                                                                                        {isExpanded ?
                                                                                            <FaCaretDown /> :
                                                                                            <FaCaretRight />
                                                                                        }
                                                                                        {origin}
                                                                                    </GroupName>
                                                                                </GroupHeader>
                                                                                {isExpanded && (
                                                                                    <>
                                                                                        <DividerGroup />
                                                                                        <GroupBody>

                                                                                            {fields.filter((f) => {
                                                                                                const isFieldSelected = fieldsSelected.some((fs) => fs.id_field === f.id_field);

                                                                                                return !isFieldSelected;
                                                                                            }).map((field) => {

                                                                                                return (
                                                                                                    <BoxItem key={field.id_field} onClick={() => addNewField(field)}>
                                                                                                        <BoxSpan>{field.title}</BoxSpan>
                                                                                                    </BoxItem>
                                                                                                )

                                                                                            })}

                                                                                        </GroupBody>
                                                                                    </>
                                                                                )}
                                                                            </ContainerGroup>
                                                                        )
                                                                    }) :
                                                                    <NoResults>Sem registro</NoResults>
                                                                }
                                                            </BoxItemsContainer>
                                                        </BoxContainer>
                                                    </ClickAwayListener>
                                                ) : <></>}
                                            </ColumnsContainer>
                                        </ChartItem>

                                        {hierarchicalData !== undefined && fieldsSelected !== undefined && fieldsSelected.length > 0 ?
                                            <>
                                                {renderLevel(hierarchicalData, fieldsSelected, 1)}
                                            </> :
                                            <></>
                                        }
                                    </>}

                            </ContainerLeft>
                            <ContainerRight
                                item
                                xs={1}
                                sm={1}
                                md={4}
                                lg={4}
                                style={{ marginBottom: '10px', maxHeight: '85vh', overflowY: 'auto', marginLeft: '10px' }}
                            >

                                <ChartItem>
                                    <ChartItemTitle>
                                        Ranking de horas

                                        <HelpText type="button" onClick={() => handleDialog(
                                            'Ranking de Horas',
                                            'Este espaço celebra a colaboração, mostrando quem mais contribuiu com o tempo de trabalho com horas totais apontadas. O ranking é atualizado em tempo real, mostrando os colaboradores que mais se dedicaram ao longo do tempo. Aproveite para reconhecer e agradecer quem mais se dedicou!'
                                        )}>
                                            <FiHelpCircle />
                                        </HelpText>
                                    </ChartItemTitle>
                                    <ChartItemBody>
                                        <TimeSheetRankingChart rankingData={rankingData} isLoading={loading} />
                                    </ChartItemBody>
                                </ChartItem>

                                <ChartItem>
                                    <ChartItemTitle>
                                        Total de horas por Dia

                                        <HelpText type="button" onClick={() => handleDialog(
                                            'Total de horas por Dia',
                                            'Este gráfico mostra a distribuição do tempo de trabalho ao longo do tempo, permitindo visualizar a evolução do tempo de trabalho por dia. Aproveite para identificar os dias de maior e menor produtividade e entender como o tempo de trabalho é distribuído ao longo do tempo.'
                                        )}>
                                            <FiHelpCircle />
                                        </HelpText>
                                    </ChartItemTitle>
                                    <ChartItemBody>
                                        <TimeSheetLineChart timeSheetByDate={timeSheetByDate} isLoading={loading} />
                                    </ChartItemBody>
                                </ChartItem>

                            </ContainerRight>
                        </Container >



                    </ContainerContent>
                </ContainerBody>
            </FreemiumFeature>
        </>
    );
};

export default TimeSheet;