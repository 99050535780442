import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    padding: 30px;
    padding-top: 15px;
    margin-bottom: 10px;
    padding-bottom: 15px;
`;

export const ItemAutomation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;    
    border: 1px solid #f23b5d;
    border-radius: 10px;
    min-height: 150px;
    margin-top: 45px;
    cursor: pointer;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
    transition: transform 0.3s ease;

    :hover {
        transform: scale(1.01);
    }
`;

export const ItemAutomationLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 5px;
`;

export const ItemAutomationTitle = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
`;

export const ItemAutomationIcon = styled.div`
    padding: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f23b5d;
    border-radius: 10px;
    color: white;
    margin-right: 15px;
`;

export const ItemAutomationDescription = styled.div`
    font-size: 14px;
    color: gray;
`;

export const ItemAutomationRight = styled.div`
    margin-left: 15px;
`;

export const ItemAutomationBadgeStatus = styled.div`
    background-color: gray;
    padding: 5px 10px;
    color: white;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 500;
    min-width: 80px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        margin-right: 5px;
        margin-top: 1px;
    }
`;
