import Avatar from "react-avatar";
import styled, { css } from "styled-components";

interface LabelDayWeekProps {
    isToday?: boolean;
}

interface LabelDayProps {
    isToday?: boolean;
}

interface ContainerProps {
    showWeekend?: boolean;
}

interface ContainerEventProps {
    color?: string;
}

export const Container = styled.div<ContainerProps>`

    background-color: white;
    padding: 15px;
    border-radius: 10px;

    .rbc-toolbar-label {
        font-size: 20px;
        color: black;
        font-weight: 500;
    }

    .rbc-header {
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1.3px;
        color: #7e7e7e;
    }

    .rbc-today {
        background-color: white;
    }

    .rbc-date-cell.rbc-now {

        .rbc-button-link {
            padding: 5px;
            background-color: #808080;
            margin-top: 5px;
            border-radius: 5px;
            color: white;
            font-size: 15px;
            font-weight: 500;
        }

    }
    
    .rbc-date-cell.rbc-current {

        .rbc-button-link {
            padding: 5px;
            background-color: #f23b5c;
            margin-top: 5px;
            border-radius: 5px;
            color: white;
            font-size: 15px;
            font-weight: 500;
        }

    }

    .rbc-button-link {
        padding: 5px;
        margin-top: 5px;
        border-radius: 5px;
        color: #4d4d4d;
        font-size: 15px;
        font-weight: 500;
    }

    .rbc-day-bg.rbc-off-range-bg {
        opacity: 0.8;
    }

    .rbc-date-cell.rbc-off-range {
        .rbc-button-link {
            opacity: 0.8;
        }
    }

    .rbc-time-view .rbc-row {
        box-sizing: border-box;
        min-height: 60px;
    }

    .rbc-row-content {
        .rbc-row {
            min-height: 20px;
        }
    }

    .rbc-row.rbc-time-header-cell {
        .rbc-header {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            button.rbc-button-link {
                margin-top: 0px;
            }
        }
    }

    .rbc-time-gutter.rbc-time-column {
        .rbc-timeslot-group {
            border-bottom: none;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            margin-left: 5px;

            .rbc-label {
                display: flex;
                color: #4e4e4e;
                font-weight: 400;
                font-size: 13px;
                padding-right: 0px;
            }
        }
    }

    .rbc-label.rbc-time-header-gutter {
        min-width: 52.3594px;
    }

    .rbc-timeslot-group {
        min-height: 50px;
    }

    .rbc-day-slot.rbc-time-column {
        .rbc-timeslot-group {
            min-height: 65px;
        }

        .rbc-event-label {
            color: #808080;
            font-size: 11px;
            margin-bottom: 2px;
        }

        .rbc-event {
            background: transparent;
            border: 1px solid transparent;
        }
    }

    .rbc-time-gutter.rbc-time-column .rbc-timeslot-group {
        min-height: 65px;
    }

    .rbc-event {
        background: transparent;
    }

    .rbc-date-cell {
        margin-bottom: 5px;
    }

    .rbc-current-time-indicator {
        height: 2px;
        background-color: #f23b5c;
    }

    .rbc-row-content-scroll-container {
        margin-top: 5px;
    }

`;

export const ContainerToolbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    padding-bottom: 10px;
`;

export const LabelMonthYear = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 20px;
    margin-right: 15px;
`;

export const ContainerButtonLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ButtonToday = styled.button`
    border: none;
    background-color: #f4f4f4;  
    border-radius: 5px;
    display: flex;
    align-items: center;
    color: #4d4d4d;
    font-size: 14px;
    padding: 5px 10px;
    margin-right: 20px;
    justify-content: flex-end;
    font-weight: 500;

    transition: transform 0.3s ease;

    :hover {
        opacity: 0.8;
        transform: scale(1.05);
    }
    
`;

export const ButtonPrev = styled.button`
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    border: none;
    font-size: 30px;
    color: grey;
    font-weight: 300;
    margin-right: 20px;
    cursor: pointer;

    transition: transform 0.3s ease;

    :hover {
        transform: scale(1.2);
    }
`;

export const ButtonNext = styled.button`
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    border: none;
    font-size: 30px;
    color: grey;
    font-weight: 300;
    margin-right: 20px;
    cursor: pointer;    

    transition: transform 0.3s ease;

    :hover {
        transform: scale(1.2);
    }
`;

export const ContainerButtonRight = styled.div`
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ButtonShowWeekend = styled.button`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;    
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;
    background: transparent;
    color: #4d4d4d;
    border-radius: 5px;

    svg {
        font-size: 16px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
    
`;

export const ButtonChangeView = styled.button`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;    
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;
    background: #f23b5c;
    color: white;
    border-radius: 5px;
    height: 27px;

    svg {
        font-size: 16px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        opacity: 0.8;
    }
    
`;

export const ContainerWeekHeader = styled.div`

`;

export const LabelDayWeek = styled.div<LabelDayWeekProps>`
    font-weight: 400;
    margin-bottom: 5px;

    ${props => props.isToday && css`
        color: #f23b5d;
    `}
`;

export const LabelDay = styled.div<LabelDayProps>`
    display: flex;
    color: grey;
    font-weight: 500;
    font-size: 13px;

    ${props => props.isToday && css`
        color: #f23b5d;
        opacity: 0.8;
    `}
`;

export const ContainerEvent = styled.div<ContainerEventProps>`
    height: 40px;
    background-color: #f23b5c;
    border-radius: 10px;
    padding: 5px;
    padding-bottom: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 5px;

    ${props => props.color && css`
        background-color: ${props.color};
    `}
`;

export const EventTitle = styled.span`
    font-size: 12px;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: inline-block;
`;

export const EventAvatar = styled(Avatar)`
    
`;

export const ContainerLoader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #4d4d4d;
    height: 100%;
`;


export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;   

    svg {
        height: 100% !important;
    }
`;