import styled from "styled-components";
import Button from "../Button";

interface ContainerProps {
    color?: string;
}

export const Container = styled.div<ContainerProps>`
    display: block;
    height: 100%;
    overflow: auto;
    position: relative;

    ${props => props.color && `
        background-color: ${props.color};
    `}

`;

export const ContainerMain = styled.div`
    position: relative;
    z-index: 3;
`;

export const ContainerBackground = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 240px;
    z-index: 1;
    
    img {
        width: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-color: #f0f0f0;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

export const ContainerHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const ContainerBody = styled.div`

    #formNewAnswer {
        padding: 25px 0px 10px 0px;
    }

    margin-top: 15px;
`;

export const ContainerInfo = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 700px;    
`;

export const ContainerDetails = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 700px;
    margin-top: 15px;
`;

export const ContainerInfoImage = styled.div`
    width: 180px;
    min-height: 180px;
    max-height: 180px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        border-radius: 10px;
    }
`;

export const ContainerAddImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 145px;
    width: 160px;
    border-radius: 10px;
    border: 1px dashed #808080;
    
    svg {
        font-size: 18px;
        margin-right: 10px;
    }

    span { 
        font-size: 15px;
    }
`;

export const ContainerInfoText = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    width: calc(100% - 200px);
    min-height: 180px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
    margin-left: 15px;
`;

export const ContainerInfoDescription = styled.div`
    width: 100%;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);    
`;

export const ContainerInfoCard = styled.div`
    width: 100%;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);    
`;

export const DividerMenu = styled.div`
    width: 100%;
    border-bottom: 1px solid #f4f4f4;
    margin: 10px 0;
`;

export const ContainerInfoTextTitle = styled.div`
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: black;
`;

export const ContainerInfoDescriptionText = styled.div`    
    font-weight: 400;
    color: gray;
    overflow-wrap: anywhere;
    font-size: 15px;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
`;

export const ContainerAction = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const ContainerActionButtons = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 700px;
    margin-top: 15px;
    margin-bottom: 5px;
`;

export const ButtonSubmitt = styled(Button)`
    min-width: 150px;
    width: auto;
    height: 45px;

    @media (max-width: 768px) {
        width: 100%;
    }    
`;

export const ButtonTryCange = styled.a`
    color: gray;
    height: 40px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: #f4f5f7;
    border-radius: 5px;
    width: 180px;

    :hover {
        opacity: 0.8;
    }
`;

export const ContainerBottom = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const ContainerBottomDisclaimer = styled.div`
    max-width: 700px;
    font-size: 12px;
    color: black;
    font-weight: 400;
`;

export const ContainerBottomText = styled.a`
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 700px;    
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 10px 30px;
    border-radius: 10px;
    color: black;
    opacity: 0.9;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;

    img {
        width: 80px;
        margin-left: 10px;
    }
`;

export const ContainerSuccess = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    color: black;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
`;

export const ContainerSuccessIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    svg {
        font-size: 85px;
        color: #4caf50;
    }
`;

export const ContainerSuccessTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    font-size: 22px;
`;

export const ContainerSuccessMessage = styled.div`
    font-weight: 400;
    color: gray;
    overflow-wrap: anywhere;
    font-size: 15px;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 30px;

    img {
        width: 80px;
        margin-left: 10px;
    }
`;

export const ConatinerSuccessButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
`;


export const ContainerButtonAction = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;  
    width: 100%;  
`;

export const ButtonAction = styled(Button)`
    height: 45px;        
    font-weight: 500;
    justify-content: flex-start;
    overflow: hidden;
    font-size: 16px;
    margin-right: 10px;
    max-width: 230px;

    div {
        margin-top: 0px;
    }

    svg {
        font-size: 16px;
    }
    
`;

export const ButtonActionBack = styled(Button)`
    height: 45px;        
    font-weight: 500;
    justify-content: flex-start;
    overflow: hidden;
    font-size: 16px;
    margin-right: 10px;
    max-width: 230px;
    color: #4d4d4d;

    div {
        margin-top: 0px;
    }

    svg {
        font-size: 16px;
        color: #4d4d4d;
    }
    
`;

export const ContainerRightTitle = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
    font-size: 12px;
    color: #84939f;
    font-weight: 500;
    margin-top: 2px;
    text-transform: uppercase;
`;

export const ContainerActionItems = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
`;