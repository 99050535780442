import { GroupItems, HelpText, RequiredBox, TitleBox, TitleContainer } from "./styles";

import Dialog from "../../dialogs/Dialog";
import { FiHelpCircle } from "react-icons/fi";
import React from "react";

interface TitleProps {
    title: string;
    required?: boolean;
    help_text?: string;
    font_size?: string;
    font_weight?: string;
    color?: string;
    justify_content?: string;
    badge?: string;
}

const Title: React.FC<TitleProps> = ({ title, required, help_text, font_size, font_weight, color, justify_content, badge }) => {

    /* Dialog controller */
    const [open, setOpen] = React.useState(false);
    const handleDialog = () => {
        setOpen(!open);
    };

    return (
        <TitleContainer
            font_size={font_size}
            font_weight={font_weight}
            color={color}
            justify_content={justify_content}
        >
            {required ? <RequiredBox className="cange-form-title-required">* </RequiredBox> : <b></b>}
            <TitleBox className="cange-form-title">
                {title}
            </TitleBox>
            {(help_text === undefined || help_text === null) ? (
                <b></b>
            ) : (
                <HelpText className="cange-form-help-text" type="button" onClick={handleDialog} title="Clique para mais informações">
                    <FiHelpCircle />
                    <Dialog
                        title={title}
                        value={help_text}
                        status={open}
                        handleDialog={handleDialog}
                    />
                </HelpText>
            )}
            {badge !== undefined ?
                <GroupItems
                    color={color !== undefined ? color : "#f23c5d"}
                >
                    {badge}
                </GroupItems> :
                <></>
            }
        </TitleContainer>
    )
};

export default Title;