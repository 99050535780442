import React, { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { Dialog } from '@material-ui/core';
import { FaCalendarAlt } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import { FormHandles } from "@unform/core";
import { HiOutlineLightBulb } from "react-icons/hi";
import {
    format
} from 'date-fns';

import Button from "../../components/Button";
import FormBuilder from "../../components/Forms/FormBuilder";
import api from '../../services/api';
import {
    BtnInsertNewAnswer,
    ContainerItems,
    Content,
    ContentBody,
    DialogFooter,
    DialogFooterCenter,
    DialogTop,
    DialogTopCenter,
    DialogTopLeft,
    DialogTopRight,
    FormContainerButtons,
    HelpContainer,
    HelpIcon,
    HelpItem,
    HelpItems,
    HelpText,
    IconHeader
} from "./styles";
import { Card as CardInterface } from "../../interfaces/Card";
import {
    FieldProps,
    Fields
} from "../../components/Forms/Fields/FieldBuilder";
import { useToast } from '../../hooks/toast';

export interface AddDueDateProps {
    open: boolean;
    selectedValue: CardInterface | undefined;
    onClose: () => void;
}

const fieldsNewLabel: FieldProps[] = [
    {
        name: "due_date",
        type: Fields.DATE_PICKER_FIELD,
        index: 1,
        title: "",
        description: "",
        variation: "2",
        inline_picker: true,
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["É necessário escolher uma data!"]
            }
        ]
    }
]

interface dueDateProps {
    due_date: Date;
}
const dueDateDefault: dueDateProps = {
    due_date: new Date(new Date().setHours(0, 0, 0, 0))
}

const AddDueDate: React.FC<AddDueDateProps> = ({ onClose, open, selectedValue }) => {

    const { addToast } = useToast();

    const formRef = useRef<FormHandles>(null);
    const [dateDue, setDateDue] = useState<dueDateProps>(dueDateDefault);

    const handleSubmitForm = useCallback(async (data: object[]) => {

        if (formRef.current !== null && selectedValue !== undefined) {

            const dataNormalized = formRef.current.getData() as unknown as dueDateProps;

            //Formata a data                                                
            const dateDueType = new Date(dataNormalized.due_date);
            const dateDueFormated = format(
                dateDueType,
                "yyyy-MM-dd HH:mm"
            );

            await api
                .put('/card', {
                    flow_id: selectedValue?.flow_id,
                    id_card: selectedValue.id_card,
                    dt_due: dateDueFormated
                })
                .then(response => {

                    onClose();

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao inserir Etiqueta',
                        description: 'Ocorreu um erro ao inserir etiqueta na base de dados!',
                    });
                });

        }

    }, [addToast, onClose, selectedValue]);

    const handleRemoveDueDate = useCallback(async () => {

        if (selectedValue !== undefined) {

            await api
                .put('/card', {
                    flow_id: selectedValue?.flow_id,
                    id_card: selectedValue.id_card,
                    dt_due: null
                })
                .then(response => {

                    onClose();

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao inserir Etiqueta',
                        description: 'Ocorreu um erro ao inserir etiqueta na base de dados!',
                    });
                });

        }

    }, [addToast, onClose, selectedValue]);

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    const handleSave = useCallback(async () => {

        if (formRef.current !== undefined) {
            formRef.current?.submitForm();
        }

    }, []);

    useEffect(() => {

        if (selectedValue !== undefined) {
            if (selectedValue?.dt_due_string !== undefined && selectedValue?.dt_due !== undefined) {
                const dueDateNew: dueDateProps = {
                    due_date: new Date(selectedValue?.dt_due)
                }

                setDateDue(dueDateNew);

                if (formRef?.current !== null) {
                    formRef.current.setData(dueDateNew);
                }

            }

        } else {
            let dueDateNew: dueDateProps = {
                due_date: new Date(new Date().setHours(0, 0, 0, 0))
            }

            setDateDue(dueDateNew);
        }

    }, [selectedValue, open]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={"gray"}>
                        <div>
                            <FaCalendarAlt />
                        </div>
                    </IconHeader>
                    <h1>
                        {"Data de Vencimento"}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose></AiOutlineClose></button>
                </DialogTopRight>
            </DialogTop>

            <Content>
                <ContentBody container>
                    <HelpContainer>
                        <HelpItems>
                            <HelpItem>
                                <HelpIcon>
                                    <HiOutlineLightBulb />
                                </HelpIcon>
                                <HelpText>
                                    {"Defina uma data para o vencimento/entrega deste cartão. A data de vencimento auxilia na priorização e acompanhamento das etapas do seu fluxo"}
                                </HelpText>
                            </HelpItem>
                        </HelpItems>

                    </HelpContainer>

                    <ContainerItems>
                        <FormBuilder
                            id="formAddLabel"
                            formRef={formRef}
                            fields={fieldsNewLabel}
                            initialValue={dateDue}
                            handleSubmit={handleSubmitForm}
                            hideContainer={true}
                        />
                    </ContainerItems>

                    {selectedValue?.dt_due_string !== undefined ?
                        <FormContainerButtons>
                            <div>
                                <Button onClick={() => handleRemoveDueDate()} icon={FiTrash2} type="button" color="gray" height="36px" margin="0px 0px 0px 0px">Remover data</Button>
                            </div>
                        </FormContainerButtons> :
                        <></>
                    }

                </ContentBody>
            </Content>

            <DialogFooter>
                <DialogFooterCenter>
                    <BtnInsertNewAnswer icon={BiSave} onClick={() => handleSave()}>
                        Salvar
                    </BtnInsertNewAnswer>
                </DialogFooterCenter>
            </DialogFooter>
        </Dialog >
    );
};

export default AddDueDate;

