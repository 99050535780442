import getCheckConditionalStep from "../components/Forms/FormBuilder/getCheckConditionalStep";
import { Card } from "../interfaces/Card";
import { Step } from "../interfaces/Step";

export default function getIsAllowedMove(step: Step, steps: Step[], card?: Card): boolean {

    let newStep = step;

    if (card !== undefined) {
        newStep = getCheckConditionalStep(step, undefined, undefined, card);
        
        if (newStep !== undefined && newStep.isAllowedMoviment !== undefined && newStep.isAllowedMoviment === false) {        
            return false;
        }

        const currStep = steps.find(s => s.id_step === card.flow_step_id);

        if (currStep !== undefined && currStep.relationship_steps !== undefined && currStep.relationship_steps !== null && currStep.relationship_steps.length > 0) {

            const rel = currStep.relationship_steps.filter(rel => rel.step_available_id === newStep.id_step && rel.isActive === "0");

            if (rel !== undefined && rel.length > 0) {
                return false;
            }

        }

    }

    return true;

}