import styled, { css } from "styled-components";

import { Box } from "@material-ui/core";

interface ButtonLinkProps {
    active?: boolean;
}

interface BoxContainerProps {
    itemnavbar?: number
}

export const SearchInput = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    border-radius: 5px;
    border: solid 1px #808080bf;
    width: 100%;
    max-width: 400px;
    color: #4d4d4d;
    font-weight: 600;

    svg {
        font-size: 17px;
        margin-top: 1px;
        margin-left: 9px;
    }
`;

export const Input = styled.input`
    border: none;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 100%;
    max-width: 400px;
    margin-right: 5px;

    ::placeholder {
        padding: 5px;
        padding-left: 0px;
        color: #4d4d4d;
        font-size: 13px;
    }
`;

export const Container = styled.div``;

export const BoxContainer = styled(Box)<BoxContainerProps>`
    position: absolute;
    //top: auto;
    top: 35px;
    left: 115px;
    z-index: 999;
    min-width: 250px;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 0px;
    margin-right: 10px;

    //max-height: 50%;
    //overflow-y: auto;
    
    @media(max-width: 800px){
        left: 0px;
    }

    ${props => props.itemnavbar === 2 && css`
        left: 15px;
    `}
`;

export const BoxContainerOrder = styled(Box)`
    position: absolute;
    left: 200px;
    top: 35px;
    z-index: 999;
    min-width: 250px;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 0px;
    margin-right: 10px;

    //max-height: 50%;
    //overflow-y: auto;

    @media(max-width: 800px){
        left: 0px;
    }
`;

export const ButtonLink = styled.button<ButtonLinkProps>`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;

    svg {
        font-size: 16px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }

    ${props => props.active && css`
        background-color: #60bd4e;
        color: white;
        border-radius: 5px;   
        font-weight: 500;
        
        :hover {
            background-color: #57aa47;
        }
    `}

    @media(max-width: 800px){ 
        svg {
            margin-right: 0px;
        }

        div {
            display: none;            
        }
    }
    
`;

export const FilterContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    padding-bottom: 5px;
`;

export const SelectInput = styled.select`
    height: 35px;
    border-radius: 5px 0px 0px 5px;
    border-color: #808080bf;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 200px;
`;

export const SelectComparator = styled.select`
    height: 35px;
    border-radius: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: #a0a0a0;
    font-size: 14px;
    padding: 5px;
    color: black;
`;

export const SelectComparatorOrder = styled.select`
    height: 35px;
    border-radius: 0px 5px 5px 0px;
    border-left: 0px;
    border-color: #a0a0a0;
    font-size: 14px;
    padding: 5px;
    color: black;
    min-width: 160px;
`;

export const InputFilter = styled.input`
    height: 30px;
    border-radius: 0px 5px 5px 0px;
    border: solid 1px #808080bf;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 200px;

    ::placeholder {
        font-size: 14px;
        padding: 5px;
        color: gray;
    }
`;

export const FilterBottom = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const AddNewFilter = styled.button`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    margin-top: 5px;

    svg {
        font-size: 13px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
`;

export const DeleteButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #a0a0a0;
    border-radius: 5px;
    margin-left: 5px;

    height: 35px;
    width: 35px !important;
    margin-top: 0px !important;
    
    svg {
        width: 13px;
        height: 13px;
    }

    :hover {
        border: 1px solid #f23b5c;
        background-color: white;
        color: #f23b5c;
    }
`;

export const GoFilter = styled.button`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    margin-top: 5px;

    position: absolute;
    right: 5px;
    background: #e9f0f7;
    border-radius: 5px;
    padding: 5px 15px;

    svg {
        font-size: 13px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
`;

export const CleanFilter = styled.button`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    margin-top: 5px;

    position: absolute;
    right: 95px;
    border-radius: 5px;
    padding: 5px 15px;

    background-color: transparent;
    color: gray;

    svg {
        font-size: 11px;
        margin-right: 5px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
`;

export const BoxContainerField = styled(Box)`
    position: absolute;
    left: 15px;
    top: 35px;
    z-index: 999;
    min-width: 300px;
    //max-height: calc(100vh - 150px);
    border-radius: 5px;
    padding: 15px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 0px;
    margin-right: 10px;
    //overflow-x: auto;

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #F5F5F5;
    }

    :hover::-webkit-scrollbar {
        width: 8px;
        height: 8px;   
    }

    @media(max-width: 800px){
        left: 0px;
    }
`;

export const FieldsContainer = styled.div` 
    border-bottom: 2px solid #f4f5f7;
    margin-bottom: 10px;
    padding-bottom: 5px;   
    padding-right: 5px;

    h2 {
        font-size: 14px;
        color: black;
        margin-bottom: 2px;
        font-weight: 500;
    }

    span {
        font-size: 12px;
        margin-left: 1px;
    }
`;

export const FieldViewItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: 5px;
    padding: 5px;    
    border-radius: 5px;
    cursor: pointer;    

    :hover {
        background-color: #f4f4f4;

        svg {
            color: #f23b5c;        
        }
    }
`;

export const FieldViewItemToggle = styled.div`    
    display: flex;
    margin-right: 10px;
    cursor: pointer;
`;

export const FieldViewItemIcon = styled.div`
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FieldViewItemTitle = styled.div`
    font-size: 13px;
    margin-left: 5px;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 35ch;
`;

export const FieldViewItemOrigin = styled.div`
    font-size: 11px;
    margin-left: 5px;
    margin-right: 20px;
`;

export const FieldViewItemDrag = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 2px;
    cursor: grab;
`;