import styled, { css } from "styled-components";

interface CurrentPlanProps {
    isPaid?: boolean;
}

export const ContainerStatsUser = styled.div`
    display: flex;

    @media(max-width: 800px){
        display: block;
    }
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;    
    width: 100%;

    @media(max-width: 800px){
        width: 100% !important;
    }
`;

export const ContainerContent = styled.div`
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 20px; 
`;

export const ContainerHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;

    div {
        display: flex;
        margin-bottom: 0px;

        button {
            display: flex;
        }
    }

    @media(max-width: 800px) {
        display: block;
    }
`;

export const ContainerHeaderRight = styled.div`
    width: calc(100% - 115px);
    display: flex;
    justify-content: end;
    align-items: center;  
    
    @media(max-width: 800px){
        width: 100% !important;
        text-align: center;
        margin: 0px;
        padding: 0px;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
`;

export const BtnGoToPage = styled.a`
    border: none;
    background: transparent;
    font-size: 12px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    color: #f23b5c;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 15px;

    :hover {
        background-color: #f23b5c;
        color: white; 
    }
`;

export const StatsItems = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
    overflow: auto;

    ::-webkit-scrollbar {
        display: none;
    }

`;

export const CurrentPlanContainer = styled.div`
    height: 100%;
    border-radius: 5px;
    position: relative;
`;

export const CurrentPlanDesc = styled.div`
    font-weight: 400;
    text-align: center;
    font-size: 15px;
    letter-spacing: 0.1px;
`;

export const CurrentFrequencyDesc = styled.div`
    font-weight: 400;
    text-align: center;
    color: black;
    font-size: 12px;
    letter-spacing: 0.2px;
`;

export const CurrentPlan = styled.div<CurrentPlanProps>`
    font-size: 25px;
    font-weight: 500;
    color: black;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;

    ${props => props.isPaid && css`
        background: -webkit-linear-gradient(0deg,rgb(255,140,47) 25%,rgb(251,50,92) 50%,rgb(147,55,237) 75%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 15px;
        font-size: 24px;
        letter-spacing: 2px;
    `}
    
`;

export const UpgradeButton = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const InfoDaysRemaining = styled.div`

`;

export const BtnUpgrade = styled.a`
    width: 80%;
    padding: 5px 10px;
    text-decoration: none;
    color: white;
    font-size: 13px;
    border: none;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    background: linear-gradient(90deg,rgb(255,140,47) 25%,rgb(251,50,92) 50%,rgb(147,55,237) 75%);
`;

export const ExcededPlanContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 20px;
    margin-top: 20px;
    background-color: #f23b5c;
    border-radius: 10px;
    color: white;
    font-size: 15px;
`;

export const ExcededPlanIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
`;

export const ExcededPlanDesc = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        b {
            font-weight: 500;
        }
    }
`;

export const ExcededPlanButton = styled.div`
    position: absolute;
    right: 15px;
`;
