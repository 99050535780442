import styled, { css } from "styled-components";
import Grid from '@material-ui/core/Grid';
import { Box } from "@material-ui/core";

interface BoxMenuItemProps {
    isSelect?: boolean;
}

export const Container = styled.div`
    height: 100%;
    max-height: calc(100vh - 70px);
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: left;
    
    width: 100%;
    height: 100%;    

    @media(max-width: 960px){
        .container-right {
            display: none !important;
        }
    }
`;

export const ContainerLeft = styled(Grid)`

`;

export const ContainerCenter = styled(Grid)`

`;

export const ContainerRight = styled(Grid)`
    margin-bottom: 10px;
`;

export const ContainerBox = styled(Grid)`
    background-color: white;
    border-radius: 10px;
    height: 100%;    
`;

export const ContainerInbox = styled(Grid)`
    background-color: white;
    height: 100%;    
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

export const BoxHeader = styled.div`
    font-size: 20px;
    color: black;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 10px;

`;

export const NewEmailButton = styled.button`
    border: none;
    background-color: #9e37ee;
    color: white;
    padding: 10px 10px;
    font-size: 14px;
    width: 100%;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 20px;
    min-height: 42px;

    svg {
        margin-right: 10px;
    }

    :hover {
        opacity: 0.8;
    }
`;

export const BoxMenuContainer = styled.div``;

export const BoxMenuItem = styled.div<BoxMenuItemProps>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    color: gray;
    padding: 12px 10px;
    border-radius: 10px;
    font-size: 15px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;

    svg {
        margin-right: 10px;
    }

    ${props => props.isSelect && css`
        color: #9e37ee;
        background-color: #f4f5f7;
        font-weight: 500;
    `}

    :hover {
        color: #9e37ee;
        background-color: #f4f5f7;
    }

    @media(max-width: 768px){
        display: flex;
        justify-content: center;
        align-items: center;

        .box-menu-item-icon {
            font-size: 30px;
        }

        .box-menu-item-text {
            display: none;
        }

        .box-menu-item-detail {
            display: none;
        }
    }

`;
export const BoxMenuItemText = styled.div``;

export const BoxMenuItemDetail = styled.div`
    position: absolute;
    right: 10px;
    margin-left: 5px;
    font-size: 12px;
    padding: 3px 5px;
    background-color: #9e37ee;
    color: white;
    border-radius: 5px;
`;

export const ContainerAdrress = styled.div`
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #eef2f8;

    bottom: 20px;
    position: absolute;
    width: calc(100% - 20px);
    max-height: 95px;

    @media(max-width: 768px){
        display: none;
    }
`;

export const TitleAddress = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;

    h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;
        font-size: 12px;
        color: #84939f;
        font-weight: 500;
        text-transform: uppercase;
    }

    svg {
        min-width: 15px;
        font-size: 15px;
        font-weight: 600;
        color: #84939f;
    }

`;

export const AddressValue = styled.div`
    padding: 10px;
    background-color: #f4f5f7;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`;

export const Address = styled.div`
    margin-right: 10px;
    font-size: 12px;
    overflow: hidden;
`;

export const CopyBtn = styled.button`
    position: absolute;
    right: 10px;
    font-weight: 500;
    padding: 3px 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #9e37ed;
    border: none;
    background-color: #f4f5f7;
    border-radius: 10px;

    svg {
        margin-right: 5px;
    }

    :hover {
        background-color: #9e37ed;
        color: white;
    }
`;

export const ContainerGrid = styled(Grid)`

`;


export const SearchContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    border-radius: 5px;
    border: solid 1px #808080bf;
    width: 100%;
    color: #4d4d4d;
    font-weight: 600;

    svg {
        font-size: 17px;
        margin-top: 1px;
        margin-left: 9px;
    }
`;

export const SearchInput = styled.input`
    border: none;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 100%;
    max-width: 400px;

    ::placeholder {
        padding: 5px;
        padding-left: 0px;
        color: #4d4d4d;
        font-size: 13px;
    }
`;


export const HeaderInboxList = styled.div`    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;

    min-height: 50px;
    border-bottom: 1px solid #f4f5f7;
`;

export const HeaderInboxListButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 10px;

    font-size: 18px;
    padding: 5px;
    border: none;
    background-color: transparent;
    border-radius: 15px;
    color: gray;

    :hover {
        background-color: #9e37ee;
        color: white;
    }
`;

export const HeaderInboxListButtonRight = styled.button`
    position: absolute;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 18px;
    padding: 5px;
    border: none;
    background-color: transparent;
    border-radius: 15px;
    color: gray;

    :hover {
        background-color: #9e37ee;
        color: white;
    }
`;

export const HeaderInboxMail = styled.div`
    //min-height: 40px;
    border-bottom: 1px solid #f4f5f7;
    height: 100%;
`;

export const BodyInboxList = styled.div`
    height: calc(100% - 49px);
    overflow-y: auto;
    border-radius: 0px 0px 0px 10px;
`;

export const CleanBoxTitle = styled.h2`
    font-size: 20px;
    margin-bottom: 5px;

    span {
        margin-left: 5px;
        font-size: 20px;
    }
`;

export const CleanBox = styled.div`
    display: grid;
    justify-items: center;
    align-content: center;
    padding: 10px;
    margin: 10px;
    margin-top: 20px;
    border-radius: 10px;
    border: 1px dashed #9e37ee;
    min-height: 250px;
    font-size: 15px;
    color: #9e37ee;
    height: calc(100% - 40px);
    width: 100%;

    svg {
        font-size: 55px;
        margin-bottom: 10px;
    }
`;

export const CleanBoxText = styled.div`
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    margin-top: 2px;
    max-width: 500px;
    color: gray;
`;

export const CardContainer = styled.div`
    padding: 10px;
    padding-bottom: 0px;
    padding-top: 0px;
    flex: 1;
    overflow: hidden;
`;

export const CardContainerBlank = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 49px;
    position: relative;

    color: #808080;
    padding: 10px;
    margin: 15px 12px;
    border: 1px dashed #80808059;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;

    svg {
        margin-right: 5px;
        font-size: 17px;
        margin-top: 2px;
    }

    :hover {
        color: #9e37ee;
        border: 1px dashed #9e37ee;
    }
`;

export const CardContainerBlankTitle = styled.div``;

export const EmailContainerTop = styled.div`
    border-top: 1px solid #f4f5f7;
    height: 100%;
    overflow: auto;
`

export const BoxContainer = styled(Box)`
    position: absolute;
    z-index: 99;
    top: -2px;
    left: -2px;
    width: calc(100% + 3px);
    border-radius: 10px;
    padding: 5px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
`;

export const BoxSearchContainer = styled.div`
    display: flex;
    padding: 10px 10px 10px 10px;

    @media(max-width: 800px){
        display: block;

        div {
            width: 100%;
            margin: 0px;
            margin-top: 5px;
        }
    }
`;

export const SearchInputCard = styled.input`
    width: 100%;
    height: 35px;
    padding: 5px 15px 5px 15px;
    border-radius: 15px;
    border: 1px solid #f4f5f7;
    background-color: #f4f5f7;
    font-size: 14px;
`;

export const BoxItemsContainer = styled.div`
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
    padding-left: 10px;
`;

export const NoResults = styled.div`
    color: black;
    font-style: italic;
    font-size: 15px;
    margin-top: 5px;
    margin-left: 20px;
    margin-bottom: 5px;
`;

export const BoxContainerBody = styled.div`
    
`;