import React, { useCallback, useEffect, useState } from 'react';

import SideBar from '../../components/Sidebar';
import api from '../../services/api';
import { BtnRefresh, ConfirmationMail, Container } from './styles';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import TrialAssistant from '../TrialAssistant';
import { CompanyPlan } from '../../interfaces/CompanyPlan';
import { useHistory } from 'react-router-dom';

interface LayoutContainerProps {
    children?: React.ReactNode;
}

const LayoutContainer: React.FC<LayoutContainerProps> = ({ children }) => {

    const [rtl] = useState(false);
    const [toggled] = useState(false);
    const { user, token, renewUser, signOut } = useAuth();
    const [mailConfirmation, setMailConfirmation] = useState<boolean>(false);
    const [companyPlan, setCompanyPlan] = useState<CompanyPlan>();
    const [isOnTrial, setIsOnTrial] = useState<boolean>(false);
    const { addToast } = useToast();
    const history = useHistory();

    const handleResendConfirmMail = useCallback(async () => {

        await api
            .post('/user/resend-confirm-mail')
            .then(response => {
                addToast({
                    type: 'success',
                    title: 'E-mail enviado com sucesso!',
                    description: 'Dentro de instantes o e-mail estará entrando na sua caixa de entrada!',
                });
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao reenviar o email',
                    description: 'Ocorreu um erro ao reenviar o e-mail de confirmação!',
                });
            });

    }, [addToast]);

    useEffect(() => {

        const tokenParse = JSON.parse(window.atob(token.split('.')[1]));
        const decodedJwt = tokenParse;
        if (decodedJwt.exp * 1000 < Date.now()) {
            addToast({
                type: 'error',
                title: 'Sua sessão no Cange expirou!',
                description: 'Por favor, faça o seu login novamente!',
            });
            signOut();
        }

        //Mail confirmation rule
        if (user !== undefined) {
            if (user.status === "P") {
                setMailConfirmation(true);
            } else {
                setMailConfirmation(false);
            }
        }

    }, [user, token, signOut, mailConfirmation, addToast, history]);

    useEffect(() => {

        //Trial rule
        if (user !== undefined && user.company !== undefined && user.company.company_plan !== undefined && user.company.company_plan.length > 0) {
            if (user.company.company_plan[0].plan_id === 6) {
                setIsOnTrial(true);
                setCompanyPlan(user.company.company_plan[0]);
            }
        }

        //Redirect to dashboard if trial ended
        const action = new URLSearchParams(window.location.search).get('action');
        if (user !== undefined && user.company !== undefined && user.company.company_plan !== undefined && user.company.company_plan.length > 0) {
            if (user.company.company_plan[0].plan_id === 6 && user.isEndedTrial === true && action !== "stripe-paid") {                
                history.push('/dashboard?action=trial-ended');                
            }
        }

    }, [user, history]);

    useEffect(() => {

        renewUser();

    }, [mailConfirmation, renewUser]);

    return (
        <div className={`app ${rtl ? 'rtl' : ''} ${toggled ? 'toggled' : ''}`}>
            <SideBar />
            <Container>
                {mailConfirmation ?
                    <ConfirmationMail>Precisamos que você <b style={{ marginLeft: "5px" }}>confirme seu e-mail</b>. Foi enviado para a sua caixa de entrada um e-mail com um link de confirmação. Caso queira reenviar, <BtnRefresh onClick={handleResendConfirmMail}>clique aqui</BtnRefresh>.</ConfirmationMail> :
                    <></>
                }
                {isOnTrial && companyPlan !== undefined ?
                    <TrialAssistant companyPlan={companyPlan} /> :
                    <></>
                }
                {children}
            </Container>
        </div>
    );
};

export default LayoutContainer;