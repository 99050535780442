import React from "react";

import { DescriptionContainer } from "./styles";

interface DescriptionProps {
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

const Description: React.FC<DescriptionProps> = ({ children, style }) => {
    return (
        <DescriptionContainer className="cange-form-description" style={style}>
            {children}
        </DescriptionContainer>
    )
};

export default Description;