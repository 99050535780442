import React, { useCallback, useEffect } from "react";

import {
    ButtonActive,
    ButtonLink,
    Container, FilterBarLeft, FilterBarRight,
    LastRunContainer
} from "./style";
import { BsToggleOff, BsToggleOn, BsTrash } from "react-icons/bs";
import DialogAction from "../../../DialogAction";
import api from "../../../../services/api";
import { useToast } from "../../../../hooks/toast";
import { AiOutlineHistory } from "react-icons/ai";
import { formatDateToDateRead } from "../../../../utils/formatDateToDateRead";

interface NewAutomationConfigBarProps {
    activeAutomation: boolean;
    id_automation?: number;
    flow_id?: number;
    last_run?: string;
    setActiveAutomation: React.Dispatch<React.SetStateAction<boolean>>
    handleOpenLogHistory: () => void;
    onClose?: () => void;
}

const NewAutomationConfigBar: React.FC<NewAutomationConfigBarProps> = ({ activeAutomation, id_automation, flow_id, last_run, setActiveAutomation, onClose, handleOpenLogHistory }) => {

    const { addToast } = useToast();

    const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = React.useState<boolean>(false);
    const [lastRun, setLastRun] = React.useState<string>();

    const setDeleteAutomationApi = useCallback(async () => {

        if (id_automation !== undefined && flow_id !== undefined) {

            setLoadingDelete(true);

            await api.delete('/automation', {
                params: {
                    id_automation: id_automation,
                    flow_id: flow_id
                }
            }).then((response) => {

                const resultApi: boolean = response.data as boolean;

                if (resultApi) {
                    if (onClose) {
                        onClose();
                    }
                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro ao deletar a automação da base de dados',
                        description: 'Ocorreu um erro ao tentar deletar a automação na base de dados!',
                    });
                }
                setLoadingDelete(false);
            }).catch((error) => {
                setLoadingDelete(false);
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Erro ao deletar a automação na base de dados',
                    description: 'Ocorreu um erro ao tentar deletar a automação na base de dados!',
                });
            });
        }

    }, [addToast, onClose, id_automation, flow_id]);

    useEffect(() => {

        if (last_run !== undefined && last_run !== null && last_run !== "") {

            //Convert to date format
            const last_run_date = new Date(last_run);

            const last_run_string = formatDateToDateRead(last_run_date, true);

            setLastRun(last_run_string);
        }

    }, [last_run]);

    return (
        <Container>
            <FilterBarLeft>
                <ButtonActive type="button"
                    onClick={() => setActiveAutomation(!activeAutomation)}
                >
                    {activeAutomation ? <BsToggleOn color="#61bd4f" /> : <BsToggleOff />}
                    {activeAutomation ? <div>Ativa</div> : <div>Inativa</div>}
                </ButtonActive>

                {id_automation !== undefined && flow_id !== undefined && (
                    <LastRunContainer>
                        {lastRun ? "Última execução " + lastRun : "A automação ainda não foi executada"}
                    </LastRunContainer>
                )}
            </FilterBarLeft>
            <FilterBarRight>
                {/*
                <ButtonLink type="button"
                    onClick={() => {

                    }}
                >
                    <BsGearFill />
                    <div>Configurações</div>
                </ButtonLink>
                */}

                {id_automation !== undefined && flow_id !== undefined ?
                    <ButtonLink type="button" onClick={() => handleOpenLogHistory()}>
                        <AiOutlineHistory />
                        Histórico de execução
                    </ButtonLink> :
                    <></>
                }

                {id_automation !== undefined && flow_id !== undefined ?
                    <ButtonLink type="button"
                        title="Excluir automação"
                        onClick={() => {
                            setOpenDeleteDialog(true);
                        }}
                    >
                        <BsTrash className="delete-icon" style={{ marginRight: '0px', fontSize: '16px' }} />
                    </ButtonLink> :
                    <></>
                }

                {openDeleteDialog && (
                    <DialogAction
                        open={openDeleteDialog}
                        message={"Você tem certeza que deseja excluir esta automação?"}
                        actions_items={[
                            "Perda de todas as configurações da automação",
                        ]}
                        disclaimer={"Lembrando que após a confirmação não será mais possível recuperar os dados referentes a Automação!"}
                        onSubmmit={() => setDeleteAutomationApi()}
                        onClose={() => setOpenDeleteDialog(false)}
                        isLoading={loadingDelete}
                        type={1}
                    />
                )}
            </FilterBarRight>
        </Container>
    );

}

export default NewAutomationConfigBar;