import styled from "styled-components";

export const Container = styled.div`
    padding: 10px;
    padding-top: 0px;
`;

export const ContainerGroup = styled.div`
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #eef2f8;
    padding-bottom: 0px;
`;

export const GroupHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`;

export const GroupName = styled.h3`
    font-weight: 500;
    color: black;
    margin-left: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
`;

export const GroupItems = styled.span`
    position: absolute;
    right: 0px;
    font-size: 10px;
    margin-right: 5px;
    background-color: #f6ebfd;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 500;
`;

export const GroupBody = styled.div`

`;

export const GroupLoading = styled.div`
    padding: 10px;
`;

export const DividerGroup = styled.div`
    border: 1px solid #eef2f8;
    width: 100%;
    margin-top: 10px;
`;

export const TagOrigin = styled.span`
    font-size: 10px;
    margin-right: 5px;
    background-color: #f6ebfd;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 400;
    text-transform: uppercase;
    color: black;
    margin-left: 5px;
`;