import {
    AggregationItemName,
    AggregationItemValue,
    Animation,
    AutomaticData,
    BtnConfigureAgg,
    ColumnAggregationItem,
    ColumnDescription,
    ColumnsAggregation,
    ColumnsHeader,
    ColumnsHeaderIcon,
    ColumnsHeaderRight,
    ColumnsHeaderSpan,
    ColumnsHeaderTitle,
    ColumnsItems,
    ColumnsItemsDisabled,
    Container,
    ContainerBtnConfigure,
    ContainerLoader,
    DropDownInfo,
    InputTitle
} from "./styles";
import { BsArrowBarLeft, BsArrowBarRight, BsCalculator, BsFillArchiveFill } from "react-icons/bs";
import { FaPen, FaPlus, FaTrash } from "react-icons/fa";
import { FieldProps, getMaskField } from "../../Forms/Fields/FieldBuilder";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Card } from "../../../interfaces/Card";
import ConfigSteps from "../../../dialogs/ConfigSteps";
import DialogAction from "../../../dialogs/DialogAction";
import ContentLoader from "react-content-loader"
import { FaMailBulk } from "react-icons/fa";
import { FilterFlowProps } from "../../FilterBar/FilterDialog";
import { KanbanConfigProps } from "..";
import { MdEditAttributes } from "react-icons/md";
import Menu from "../../Menu";
import MenuDivider from "../../Menu/MenuDivider";
import MenuItem from "../../Menu/MenuItem";
import { Step } from "../../../interfaces/Step";
import Task from "../Task"
import api from '../../../services/api';
import getAccessControl from "../../../middlewares/AccessControl";
import getCardFiltered from "../../../middlewares/getCardFiltered";
import getCardSorted from "../../../middlewares/getCardSorted";
import lottie from 'lottie-web';
import { useToast } from '../../../hooks/toast';
import { UpdateControlProps } from "../../../pages/Flow";
import getCardFormula from "../../../middlewares/getCardFormula";
import { Draggable, Droppable } from "react-beautiful-dnd";
import getCardAggregations from "../../../middlewares/getCardAggregations";
import IconColorPicker from "../../IconColorPicker";
import { useAuth } from "../../../hooks/auth";

interface ColumnProps {
    step: Step;
    typeUser: string;
    kanban_config?: KanbanConfigProps
    withLoader?: boolean;
    updateCard?: Card;
    testModel?: boolean;
    filterFlow?: FilterFlowProps;
    updateControl?: UpdateControlProps;
    cardsBase?: Card[];
    loading?: boolean;
    loadingTop?: boolean;
    fieldsAggregate?: FieldProps[];
    isArchived?: boolean;
    isDraggingOver?: boolean;
    isDragging?: boolean;
    isDropDisabled?: boolean;
    isTheSameStep?: boolean;
    cardReorder?: { id_step: number, startIndex: number, endIndex: number };
    setCardReorder?: React.Dispatch<React.SetStateAction<{ startIndex: number; endIndex: number } | undefined>>
    openDetail: (card: Card, kanban_config?: KanbanConfigProps) => void;
    refreshPage?: () => Promise<void>;
    goToEditFlow?: (id_step?: number) => Promise<void>;
    configCard?: () => Promise<void>;
}

interface AggregateItemsProps {
    columnName: string;
    typeField: string;
    typeAggregate: string;
    variation?: string;
    value: number;
}

const LoaderCard = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={135}
        viewBox="0 0 262 135"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="135" />
    </ContentLoader>
)

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    opacity: isDragging ? 0.9 : 1,
    ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean) => ({
    opacity: isDraggingOver ? 0.8 : 1,
});

const Column: React.FC<ColumnProps> = ({ step, kanban_config, updateCard, withLoader = true, openDetail, refreshPage, goToEditFlow, configCard, testModel, filterFlow, typeUser, updateControl, cardsBase, loading, loadingTop, fieldsAggregate, isArchived, isDraggingOver, cardReorder, isDropDisabled, isTheSameStep, isDragging, setCardReorder }) => {

    const lottieContainer = useRef<HTMLDivElement>(null);
    const [openConfigStep, setOpenConfigStep] = useState(false);
    const [stateDelStep, setStateDelStep] = React.useState(false);

    const [cards, setCards] = React.useState<Card[]>([]);
    const [aggregateItems, setAggregateItems] = React.useState<AggregateItemsProps[]>([]);
    const { addToast } = useToast();
    const { user } = useAuth();

    const [loadingItems, setLoadingItems] = React.useState<number[]>([1]);

    const [nameStep, setNameStep] = useState<string>("");
    const [colorStep, setColorStep] = useState<string>("");
    const [iconStep, setIconStep] = useState<string>("");

    const [openIconColorPicker, setOpenIconColorPicker] = useState<boolean>(false);

    const moveCardApi = useCallback(async (id_card: number, index: number, prev_card_id?: number, next_card_id?: number) => {

        if (step !== undefined && step.flow_id !== undefined) {

            let newIndex = id_card;
            if (prev_card_id !== undefined && next_card_id !== undefined) {
                newIndex = (prev_card_id + next_card_id) / 2;
            } else if (next_card_id !== undefined) {
                newIndex = next_card_id - 0.5;
            } else if (prev_card_id !== undefined) {
                newIndex = prev_card_id + 0.5;
            }

            setCards((cards) =>
                cards.map((card) => {
                    if (card.id_card === id_card) {
                        card.index = newIndex;
                    }
                    return card;
                }));

            api.post(`/card/move`, {
                flow_id: step.flow_id,
                id_card: id_card,
                index: newIndex,
                previus_card_id: prev_card_id,
                next_card_id: next_card_id
            });

        }

    }, [step]);

    const reorder = useCallback((list: any[], startIndex: number, endIndex: number) => {

        const result = Array.from(list);
        const resultBase = Array.from(list);

        if (filterFlow?.orderBy === undefined || filterFlow?.orderBy.length === 0) {

            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            if (result[endIndex] !== undefined) {
                moveCardApi(
                    result[endIndex].id_card,
                    endIndex,
                    result[endIndex - 1] !== undefined && result[endIndex - 1].index !== undefined ? result[endIndex - 1].index : result[endIndex - 1] !== undefined && result[endIndex - 1].id_card ? result[endIndex - 1].id_card : undefined,
                    result[endIndex + 1] !== undefined && result[endIndex + 1].index !== undefined ? result[endIndex + 1].index : result[endIndex + 1] !== undefined && result[endIndex + 1]?.id_card !== undefined ? result[endIndex + 1].id_card : undefined
                );
            } else {
                return resultBase;
            }
        }

        return result;

    }, [moveCardApi, filterFlow?.orderBy]);

    const configStepsClose = useCallback(async () => {
        setOpenConfigStep(false);
    }, []);

    const handleUpdateOrderStep = useCallback(async (upDown: string) => {

        if (step !== undefined && step.id_step !== undefined) {
            await api
                .put('/flow-step/reorder', {
                    id_step: step.id_step,
                    flow_id: step.flow_id,
                    upDown
                })
                .then(response => {

                    if (refreshPage !== undefined) {
                        refreshPage();
                    }

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao ordenar a etapa',
                        description: 'Ocorreu um erro ao ordenar a etapa na base de dados!',
                    });
                });
        }

    }, [addToast, refreshPage, step]);

    const dialogDelStepSubmmit = useCallback(async () => {

        const stepId = step.id_step as number;
        const flowId = step.flow_id as number;

        if (stepId > 0 && flowId > 0) {

            await api
                .delete('/flow-step?id_step=' + stepId + '&flow_id=' + flowId)
                .then(response => {

                    if (refreshPage !== undefined) {
                        refreshPage();
                    }

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao deletar a Etapa',
                        description: 'Ocorreu um erro ao deletar a Etapa!',
                    });
                });

        } else {
            addToast({
                type: 'error',
                title: 'Erro ao deletar a Etapa',
                description: 'Erro nos parâmetros! Ocorreu um erro ao deletar a Etapa!',
            });
        }

    }, [refreshPage, addToast, step.flow_id, step.id_step]);

    const handleArchiveCompleteCards = useCallback(async () => {

        let result: boolean = true;

        if (cards !== undefined) {
            cards.forEach(async (element) => {

                if (element.complete === "S") {
                    await api
                        .put('/card', {
                            flow_id: step.flow_id,
                            id_card: element.id_card,
                            archived: "S"
                        })
                        .then(response => {
                        }).catch(error => {
                            result = false;
                        });
                }

            });
        }

        if (!result) {
            addToast({
                type: 'error',
                title: 'Erro ao arquivar os cartões',
                description: 'Ocorreu um erro ao tentar arquivar os cartões desta etapa!',
            });
        }

        if (refreshPage !== undefined) {
            refreshPage();
        }

    }, [addToast, cards, step.flow_id, refreshPage]);

    const getAggregationsColumn = useCallback(async (newCards: Card[]) => {

        if (fieldsAggregate !== undefined && fieldsAggregate.length >= 0) {
            const newAggregation = getCardAggregations(fieldsAggregate, newCards);
            setAggregateItems(newAggregation);
        }

    }, [fieldsAggregate]);

    const getRandomLoadingItems = useCallback(async () => {

        const max = Math.floor(4);
        const min = Math.ceil(1);

        const random = Math.floor(Math.random() * (max - min)) + min;
        let loadingItemsNew: number[] = [];
        for (let index = 0; index < random; index++) {
            loadingItemsNew.push(index);
        }

        setLoadingItems(loadingItemsNew);

    }, []);

    const handleSaveColorIcon = useCallback(async () => {

        if (step !== undefined && colorStep !== undefined && iconStep !== undefined && colorStep !== "" && iconStep !== "" && (colorStep !== step.color || iconStep !== step.icon)) {

            await api.put('/flow-step', {
                id_step: step.id_step,
                flow_id: step.flow_id,
                color: colorStep,
                icon: iconStep
            }).then(response => {
                if (response.data !== null) {
                    addToast({
                        type: 'success',
                        title: 'Cor e ícone da Etapa salvos!',
                        description: 'A cor e o ícone da Etapa foram salvos com sucesso!',
                    });
                }
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao salvar a cor e o ícone da Etapa',
                    description: 'Ocorreu um erro ao salvar a cor e o ícone da Etapa!',
                });
            });

        }

    }, [addToast, step, colorStep, iconStep]);

    const handleCloseIconColorPicker = useCallback(() => {

        setOpenIconColorPicker(false);

        handleSaveColorIcon();

    }, [handleSaveColorIcon]);

    const handleSaveNameStep = useCallback(async () => {

        if (step !== undefined && nameStep !== undefined && nameStep !== "" && nameStep !== step.name) {

            await api.put('/flow-step', {
                id_step: step.id_step,
                flow_id: step.flow_id,
                name: nameStep
            }).then(response => {
                if (response.data !== null) {
                    addToast({
                        type: 'success',
                        title: 'Nome da Etapa salva!',
                        description: 'O nome da Etapa foi salva com sucesso!',
                    });
                }
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao salvar o nome da Etapa',
                    description: 'Ocorreu um erro ao salvar o nome da Etapa!',
                });
            });

        }

    }, [addToast, step, nameStep]);

    useEffect(() => {
        getRandomLoadingItems();
    }, [loading, getRandomLoadingItems]);

    useEffect(() => {

        if (cardsBase !== undefined) {

            let cardsApi: Card[] = cardsBase;

            //Adjust the index for order default
            cardsApi = cardsApi.map((card) => {
                if (card.index === undefined || card.index === null) {
                    card.index = card.id_card;
                }
                return card;
            });

            cardsApi = getCardFiltered(cardsApi, user.id_user, filterFlow?.conditions, filterFlow?.searchText);
            cardsApi = getCardSorted(cardsApi, filterFlow?.orderBy);
            cardsApi = getCardFormula(cardsApi, kanban_config);

            setCards(cardsApi);

        }

    }, [cardsBase, filterFlow, kanban_config, user.id_user]);

    useEffect(() => {

        getAggregationsColumn(cards);

    }, [cards, getAggregationsColumn]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../assets/lottie/loader-circle-white.json')
            })
        }
    }, [loadingTop]);

    useEffect(() => {

        if (step !== undefined) {
            setNameStep(step.name);
            setColorStep(step.color !== undefined ? step.color : "");
            setIconStep(step.icon !== undefined ? step.icon : "");
        }

    }, [step]);

    useEffect(() => {

        if (cardReorder !== undefined && cardReorder.id_step === step.id_step) {
            const updatedItems = reorder(
                cards,
                cardReorder.startIndex,
                cardReorder.endIndex
            );

            setCards(updatedItems);

            if (setCardReorder !== undefined) {
                setCardReorder(undefined);
            }

        }

    }, [cardReorder, cards, reorder, setCardReorder, step.id_step]);

    return (
        <Container className="column-container">
            <ColumnsHeader color={isArchived ? "#4d4d4d" : colorStep !== undefined ? colorStep : "#9e37ed"}>
                <ColumnsHeaderIcon onClick={() => setOpenIconColorPicker(true)}>

                    {iconStep !== undefined ?
                        <IconPickerItem
                            icon={iconStep as IconList}
                            color={"white"}
                        /> :
                        <FaMailBulk />
                    }

                </ColumnsHeaderIcon>
                {openIconColorPicker && getAccessControl(15, typeUser) &&
                    <IconColorPicker
                        key={step.id_step}
                        open={openIconColorPicker}
                        icon={iconStep}
                        color={colorStep}
                        index={step.index}
                        handleClose={handleCloseIconColorPicker}
                        setColor={setColorStep}
                        setIcon={setIconStep}
                    />
                }
                <ColumnsHeaderTitle>
                    <InputTitle
                        type="input"
                        maxLength={25}
                        value={nameStep}
                        disabled={!getAccessControl(16, typeUser)}
                        onChange={(e) => setNameStep(e.currentTarget.value)}
                        onBlur={handleSaveNameStep}
                    />
                </ColumnsHeaderTitle>
                <ColumnsHeaderRight>
                    {getAccessControl(16, typeUser) ?
                        <>
                            <Menu color={isArchived ? "#4d4d4d" : colorStep !== undefined ? colorStep : "#9e37ed"}>
                                <MenuItem title="Editar relacionamento das etapas" icon={MdEditAttributes} color={colorStep !== undefined ? colorStep : "#9e37ed"} onClick={() => setOpenConfigStep(!openConfigStep)} />
                                {configCard !== undefined ?
                                    <MenuItem title="Configurar agregações de campos" icon={BsCalculator} color={colorStep !== undefined ? colorStep : "#9e37ed"} onClick={() => configCard()} /> :
                                    <></>
                                }
                                {!isArchived ?
                                    <MenuItem title="Arquivar cards finalizados" icon={BsFillArchiveFill} color={colorStep !== undefined ? colorStep : "#9e37ed"} onClick={() => handleArchiveCompleteCards()} /> :
                                    <></>}
                                <MenuDivider />
                                <MenuItem title="Mover para a direita" icon={BsArrowBarRight} color={colorStep !== undefined ? colorStep : "#9e37ed"} onClick={() => handleUpdateOrderStep("up")} />
                                <MenuItem title="Mover para a esquerda" icon={BsArrowBarLeft} color={colorStep !== undefined ? colorStep : "#9e37ed"} onClick={() => handleUpdateOrderStep("down")} />
                                <MenuDivider />
                                <MenuItem title="Criar uma nova etapa" icon={FaPlus} color={"#61bd4f"} onClick={() => goToEditFlow !== undefined ? goToEditFlow() : undefined} />
                                <MenuDivider />
                                <MenuItem title="Editar esta etapa" icon={FaPen} color={colorStep !== undefined ? colorStep : "#9e37ed"} onClick={() => goToEditFlow !== undefined ? goToEditFlow(step.id_step) : undefined} />
                                <MenuItem title="Excluir esta etapa" icon={FaTrash} color={"#ff0000"} onClick={() => setStateDelStep(!stateDelStep)} />
                                <MenuDivider />
                                <DropDownInfo style={{ paddingBottom: '0px' }}>
                                    <span>Identificador:</span> <AutomaticData>{step.id_step}</AutomaticData>
                                </DropDownInfo>
                            </Menu>

                            {openConfigStep && (
                                <ConfigSteps
                                    open={openConfigStep}
                                    onClose={configStepsClose}
                                    flow_id={step.flow_id}
                                    flow_step_id={step.id_step}
                                />
                            )}

                            {stateDelStep ?
                                <DialogAction
                                    key={step.id_step}
                                    open={stateDelStep}
                                    message={"Você tem certeza que deseja excluir a etapa: " + step.name + "?"}
                                    actions_items={[
                                        "Exclusão de todos os cartões que estão nesta etapa;",
                                        "Exclusão de todas as informações que foram inseridas na etapa;",
                                        "Exclusão de todas as métricas de tempo da etapa;",
                                        "Exclusão de todas as informações sobre a etapa"
                                    ]}
                                    disclaimer={"Lembrando que após a confirmação não será mais possível recuperar os dados referente a etapa e seus cartões!"}
                                    onSubmmit={dialogDelStepSubmmit}
                                    onClose={() => setStateDelStep(false)}
                                    type={1}
                                /> :
                                <></>
                            }
                        </> : <></>
                    }

                    {cards !== undefined && cards?.length >= 0 && !loadingTop ?
                        <ColumnsHeaderSpan className="column-count-container" color={isArchived ? "#4d4d4d" : colorStep !== undefined ? colorStep : "#9e37ed"}>
                            <span>
                                {cards?.length}
                            </span>
                        </ColumnsHeaderSpan> : loadingTop ?
                            <ContainerLoader>
                                <Animation className="lottieContainer" ref={lottieContainer} />
                            </ContainerLoader> :
                            <></>
                    }
                </ColumnsHeaderRight>
            </ColumnsHeader>
            {aggregateItems.length > 0 ?
                <ColumnsAggregation color={colorStep !== undefined ? colorStep : "#9e37ed"}>
                    {aggregateItems.map((item) => (
                        <ColumnAggregationItem key={item.columnName}>
                            {getAccessControl(18, typeUser) ?
                                <AggregationItemName style={{ cursor: 'pointer' }} onClick={configCard}>
                                    {item.columnName}
                                    <FaPen />
                                </AggregationItemName> :
                                <AggregationItemName style={{ cursor: 'pointer' }}>
                                    {item.columnName}
                                </AggregationItemName>
                            }
                            <AggregationItemValue>
                                {item.value !== undefined ? getMaskField(item.typeField, item.value, item.variation) : "0"}
                            </AggregationItemValue>
                        </ColumnAggregationItem>
                    ))}
                </ColumnsAggregation> :
                <>
                    {getAccessControl(18, typeUser) ?
                        <ContainerBtnConfigure style={{ cursor: 'pointer' }} color={colorStep !== undefined ? colorStep : "#9e37ed"}>
                            <BtnConfigureAgg className="btnConfigAgg" onClick={configCard}><FaPlus /> Adicionar uma agregação</BtnConfigureAgg>
                        </ContainerBtnConfigure> : <></>
                    }
                </>
            }

            {isDragging && !isTheSameStep && isDropDisabled ? (
                <ColumnsItemsDisabled isDraggingOver={isDraggingOver} color={colorStep}>
                    <ColumnDescription>
                        <p>Você não pode mover para esta etapa</p>
                    </ColumnDescription>
                </ColumnsItemsDisabled>
            ) : <></>}

            <ColumnsItems isDraggingOver={isDraggingOver} color={colorStep} isDropDisabled={(isTheSameStep ? false : isDropDisabled)}>
                <Droppable key={step.id_step} droppableId={'nested-' + String(step.id_step)} isDropDisabled={isTheSameStep ? false : isDropDisabled}>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {loading ?
                                loadingItems.map((item) => {
                                    return (
                                        <div key={item} style={{ marginBottom: '10px' }}>
                                            <LoaderCard key={item} />
                                        </div>
                                    )
                                }) :
                                cards?.map((card: Card, index: number) => {
                                    return (
                                        <Draggable key={card.id_card} draggableId={String(card.id_card)} index={index} isDragDisabled={!getAccessControl(69, typeUser)}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <Task
                                                        key={card.id_card}
                                                        color={colorStep !== undefined ? colorStep : "#9e37ed"}
                                                        kanban_config={kanban_config}
                                                        filterFlow={filterFlow}
                                                        card={card}
                                                        openDetail={openDetail}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                <ColumnDescription>
                    <p>{step.description}</p>
                </ColumnDescription>
            </ColumnsItems>
        </Container >
    );

}

export default Column;
