import styled, { css } from 'styled-components';

interface ContainerProps {
    color?: string;
}

interface IconHeaderProps {
    color?: string;
}

export const LeftHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;

    h1, h2, h3, h4 {
        font-size: 25px;
        font-weight: 700;
    }
    
`;

export const RightHeader = styled.div`
    display: flex;
    align-items: unset;
    justify-content: right;

    margin-left: auto;

    button {
        margin-right: 10px;
        height: 45px;
        width: 150px;
    }
`;

export const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;

    height: 60px;
    width: 100%;
    background-color: white;
    //border-radius: 10px; 
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);

    margin-top: 15px;
    border-radius: 10px;

    ${(props) => props.color && css`        
        border: 1px solid ${props.color + '70'};
    `}

    > svg {
        color: black;

    }

    h1, h2, h3, h4 {
        color: #4d4d4d;
        font-weight: 500;
    }

`;

export const IconHeader = styled.span<IconHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 15px;
    margin-left: 5px;
    margin-right: 10px;
    width: 60px;
    height: 100%;
    background-color: transparent;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 45px;
        height: 45px;
        background-color: #f4f4f4;
        border-radius: 10px;

        ${props => props.color && css`
            background-color: ${props.color + "1e"};
        `}

        > svg {
            width: 25px;
            height: 25px;
        }

    }
`;

export const ButtonsHeader = styled.span`

    display: flex;
    align-items: center;
    justify-content: right;

`;
