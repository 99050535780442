import Switch from "@material-ui/core/Switch";
import styled, { css } from "styled-components";

interface AutoCompleteContainerProps {
  isFilled?: boolean;
}

interface FormContainerProps {
  idField?: string;
  hideField?: boolean;
}

interface AutoCompleteOnBtnProps {
  idField?: string;
}

export const FormContainer = styled.div<FormContainerProps>`
  margin-bottom: 20px;

  :hover {
    #auto-complete${props => props.idField} {
      display: flex;

      .auto-complete-on {
        span {
          display: flex;
        }
      }
    }    
  }

  ${props => props.hideField === true && css`
    display: none;
  `}
`;

export const SwitchBase = styled(Switch)`
  float: right;
  margin-top: -23px;
`;

export const TitleContainer = styled.div`
  display: flex;
`;

export const AutoCompleteContainer = styled.div<AutoCompleteContainerProps>`
  display: none;
  margin-left: 5px;
  cursor: pointer;

  ${props => !!props.isFilled && css`
        display: flex;
  `}

  :hover {
    color: #f23b5d;
  }
`;

export const AutoComplete = styled.div`

`;

export const AutoCompleteBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-top: 1px;

  span {
    margin-left: 2px;
    font-size: 12px;
    letter-spacing: 0.5px;
  }
`;

export const AutoCompleteOnBtn = styled.div<AutoCompleteOnBtnProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-top: 1px;

  span {
    display: none;
    margin-left: 2px;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: #f23b5d;
  }
`;
