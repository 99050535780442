import React, { useEffect } from "react";

import { FormAnswerField } from "../../../../../../interfaces/FormAnswerField";

import { ChecklistBody, ProgressBarTask, ProgressBarTaskLine, ProgressBarTaskLineInside, ProgressBarTaskPercent } from "../../CheckListField/style";

interface ComboBoxFlowProgressViewProps {
    value: FormAnswerField[];
    colorBar?: string;
}

const ComboBoxFlowProgressView: React.FC<ComboBoxFlowProgressViewProps> = ({ value, colorBar }) => {

    const [percentage, setPercentage] = React.useState<number | undefined>(0);

    useEffect(() => {

        //Calculate the percentage of the checklist
        let totalItemsChecked = 0;
        let percentage = 0;

        //Run into the value array and valid the percentage, considering if relatedOriginIsEnded is equal 'S' is done and 'N' or undefined or null is not done
        for (let index = 0; index < value.length; index++) {
            const item = value[index];

            if (item.relatedOriginIsEnded === 'S') {
                totalItemsChecked++;
            }

        }

        if (value.length > 0) {
            percentage = totalItemsChecked / value.length;
        } else {
            percentage = 100
        }

        setPercentage(percentage);

    }, [value]);


    return (
        <>
            <ChecklistBody style={{ width: '100%' }}>

                <ProgressBarTask style={{ marginBottom: '10px' }}>
                    <ProgressBarTaskLine>
                        <ProgressBarTaskLineInside colorBar={colorBar} width={percentage !== undefined ? percentage * 100 : 0} />
                    </ProgressBarTaskLine>
                    <ProgressBarTaskPercent style={{ minWidth: '35px', textAlign: 'center', marginRight: '0px' }}>{String(Math.round((Number(percentage) * 100)))}%</ProgressBarTaskPercent>
                </ProgressBarTask>

            </ChecklistBody>
        </>
    );

}

export default ComboBoxFlowProgressView;