import React, { useState } from "react";
import { ClickAwayListener } from "@material-ui/core";

import {
    BoxOver,
    ColumnsHeaderConfig,
    Container
} from "./style";
import { IconBaseProps } from "react-icons";

interface DropDownListProps {
    icon: React.ComponentType<IconBaseProps>;
    color?: string;
    children?: React.ReactNode;
    name?: string;
}

const DropDownList: React.FC<DropDownListProps> = ({ icon: Icon, color, children, name }) => {

    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const handleClickAway = () => {
        setMenuOpen(false);
    };

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <BoxOver>
                    <ColumnsHeaderConfig
                        className="col-hed-conf"
                        color={color !== undefined ? color : "#9e37ed"}
                        onClick={() => setMenuOpen(!menuOpen)}
                        isOpen={menuOpen}
                    >                        
                                              
                        {name !== undefined && (name)}
                        <Icon />  
                    </ColumnsHeaderConfig>
                    {menuOpen ? (
                        <Container className="col-hed-con-conf">
                            {children}
                        </Container>
                    ) : (<></>)}
                </BoxOver>
            </ClickAwayListener>
        </>
    );

}

export default DropDownList;