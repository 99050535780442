import {
    AutomaticData,
    BoxIcon,
    ContainerFields,
    ContainerList,
    ContentList,
} from "./style"
import React, { useCallback, useEffect, useState } from "react";

import { useToast } from '../../../hooks/toast';
import NavigationBar, { NavigationBarProps } from "../../../components/NavigationBar";
import api from "../../../services/api";
import { Flow } from "../../../interfaces/Flow";
import { Workspace } from "../../../interfaces/Workspace";
import { Register } from "../../../interfaces/Register";
import List from "../../../components/List";
import ListItem from "../../../components/List/ListItem";
import ListColumn from "../../../components/List/ListColumn";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import ListText from "../../../components/List/ListText";
import { formatDateToDateRead } from "../../../utils/formatDateToDateRead";
import { FaLock, FaLockOpen, FaUsersCog } from "react-icons/fa";
import ListButton from "../../../components/List/ListButton";
import InviteUser from "../../../dialogs/InviteUser";

const itemsNavBar: NavigationBarProps = {
    items: [
        {
            label: 'Fluxos',
            id: 1,
            segment: 'flow',
            active: true
        },
        {
            label: 'Espaços de Trabalho',
            id: 2,
            segment: 'workspaces',
            active: false
        },
        {
            label: 'Cadastros',
            id: 3,
            segment: 'register',
            active: false
        }
    ]
}

const ControlAcess: React.FC = () => {

    const { addToast } = useToast();

    const [navBarSelected, setNavBarSelected] = useState(1); //1 -> Flow / 2 -> Workspaces / 3 -> Register
    const [navBarItems, setNavBarItems] = useState(itemsNavBar.items);

    const [flows, setFlows] = useState<Flow[]>([]);
    const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
    const [registers, setRegisters] = useState<Register[]>([]);

    const [flowSelected, setFlowSelected] = useState<Flow | undefined>(undefined);
    const [workspaceSelected, setWorkspaceSelected] = useState<Workspace | undefined>(undefined);
    const [registerSelected, setRegisterSelected] = useState<Register | undefined>(undefined);

    const [openInviteUser, setOpenInviteUser] = useState(false);

    const getFlowApi = useCallback(async () => {

        api.get('/flow/by-company')
            .then(response => {

                const retFlows: Flow[] = response.data;

                if (retFlows !== undefined && retFlows.length > 0) {
                    setFlows(retFlows);

                    //Update badge
                    setNavBarItems(oldArr =>
                        oldArr.map((item) => {
                            if (item.id === 1) {
                                item.badge = retFlows.length.toString();
                            }
                            return item;
                        })
                    );
                }

            })
            .catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar os fluxos',
                    description: 'Não foi possível buscar os fluxos, tente novamente mais tarde.'
                });
            });

    }, [addToast]);

    const getWorkspaceApi = useCallback(async () => {

        api.get('/workspace/by-company')
            .then(response => {

                const retWorkspaces: Workspace[] = response.data;

                if (retWorkspaces !== undefined && retWorkspaces.length > 0) {
                    setWorkspaces(retWorkspaces);

                    //Update badge
                    setNavBarItems(oldArr =>
                        oldArr.map((item) => {
                            if (item.id === 2) {
                                item.badge = retWorkspaces.length.toString();
                            }
                            return item;
                        })
                    );
                }

            })
            .catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar os workspaces',
                    description: 'Não foi possível buscar os workspaces, tente novamente mais tarde.'
                });
            });

    }, [addToast]);

    const getRegisterApi = useCallback(async () => {

        api.get('/register/by-company')
            .then(response => {

                const retRegisters: Register[] = response.data;

                if (retRegisters !== undefined && retRegisters.length > 0) {
                    setRegisters(retRegisters);

                    //Update badge
                    setNavBarItems(oldArr =>
                        oldArr.map((item) => {
                            if (item.id === 3) {
                                item.badge = retRegisters.length.toString();
                            }
                            return item;
                        })
                    );
                }

            })
            .catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar os cadastros',
                    description: 'Não foi possível buscar os cadastros, tente novamente mais tarde.'
                });
            });

    }, [addToast]);

    useEffect(() => {

        getFlowApi();
        getWorkspaceApi();
        getRegisterApi();

    }, [getFlowApi, getWorkspaceApi, getRegisterApi]);

    return (
        <ContentList>

            {/* Forms */}
            <ContainerFields item xs={12} md={12} lg={12} sm={12}>

                <NavigationBar
                    items={navBarItems}
                    navBarSelected={navBarSelected}
                    setNavBarSelected={setNavBarSelected}
                />

                {navBarSelected === 1 && (
                    <ContainerList>
                        <List>
                            <ListItem>
                                <ListColumn flex flexGrow={2}>
                                    <ListText header>
                                        Fluxo
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Identificador
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Cartões
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Criado por
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Criado em
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Privacidade
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Ações
                                    </ListText>
                                </ListColumn>
                            </ListItem>

                            {flows !== undefined && flows.length > 0 && flows.map((flow, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListColumn aglinItems="flex-start" flex flexGrow={2}>
                                            <a href={`/flow/${flow.hash}`} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                                                <BoxIcon color={flow.color}>
                                                    <IconPickerItem
                                                        icon={flow.icon as IconList}
                                                        color={"white"}
                                                    />
                                                </BoxIcon>
                                            </a>
                                            <a href={`/flow/${flow.hash}`} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                                                <ListText fontSize="15px" fontWeight="500">
                                                    {flow.name}
                                                </ListText>
                                            </a>
                                        </ListColumn>
                                        <ListColumn>
                                            <ListText>
                                                <AutomaticData>{flow?.id_flow}</AutomaticData>
                                            </ListText>
                                        </ListColumn>
                                        <ListColumn>
                                            <ListText>
                                                {flow.total_cards}
                                            </ListText>
                                        </ListColumn>
                                        <ListColumn>
                                            <ListText color="gray">
                                                {flow.user_creator !== undefined && flow.user_creator.name !== undefined ? flow.user_creator.name : ""}
                                            </ListText>
                                        </ListColumn>
                                        <ListColumn>
                                            <ListText color="gray">
                                                {flow.dt_created !== undefined ? formatDateToDateRead(new Date(flow.dt_created)) : ""}
                                            </ListText>
                                        </ListColumn>
                                        <ListColumn>
                                            <ListText color="gray">
                                                {flow !== undefined && flow.isPrivate !== undefined && flow.isPrivate === '1' ?
                                                    <>
                                                        <FaLock style={{ marginRight: '5px', color: 'gray' }} /> {"Fluxo privado"}
                                                    </> :
                                                    <>
                                                        <FaLockOpen style={{ marginRight: '5px', color: 'gray' }} /> {"Fluxo público"}
                                                    </>
                                                }
                                            </ListText>
                                        </ListColumn>
                                        <ListColumn>
                                            <ListButton
                                                title={"Acessos"}
                                                Icon={FaUsersCog}
                                                color="#6666661a"
                                                onClick={() => {
                                                    setFlowSelected(flow);
                                                    setWorkspaceSelected(undefined);
                                                    setRegisterSelected(undefined);
                                                    setOpenInviteUser(true);
                                                }}
                                            />
                                        </ListColumn>

                                    </ListItem>
                                )
                            })}
                        </List>
                    </ContainerList>
                )}

                {navBarSelected === 2 && (
                    <ContainerList>
                        <List>
                            <ListItem>
                                <ListColumn flex flexGrow={2}>
                                    <ListText header>
                                        Espaço de trabalho
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Identificador
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Criado em
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Privacidade
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Ações
                                    </ListText>
                                </ListColumn>
                            </ListItem>

                            {workspaces !== undefined && workspaces.length > 0 && workspaces.map((workspace, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListColumn aglinItems="flex-start" flex flexGrow={2}>
                                            <a href={`/workspace/${workspace.hash}`} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                                                <BoxIcon color={workspace.color}>
                                                    <IconPickerItem
                                                        icon={workspace.icon as IconList}
                                                        color={"white"}
                                                    />
                                                </BoxIcon>
                                            </a>
                                            <a href={`/workspace/${workspace.hash}`} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                                                <ListText fontSize="15px" fontWeight="500">
                                                    {workspace.name}
                                                </ListText>
                                            </a>
                                        </ListColumn>
                                        <ListColumn>
                                            <ListText>
                                                <AutomaticData>{workspace?.id_workspace}</AutomaticData>
                                            </ListText>
                                        </ListColumn>
                                        <ListColumn>
                                            <ListText color="gray">
                                                {workspace.dt_created !== undefined ? formatDateToDateRead(new Date(workspace.dt_created)) : ""}
                                            </ListText>
                                        </ListColumn>
                                        <ListColumn>
                                            <ListText color="gray">
                                                {workspace !== undefined && workspace.isPrivate !== undefined && workspace.isPrivate === 'N' ?
                                                    <>
                                                        <FaLock style={{ marginRight: '5px', color: 'gray' }} /> {"Fluxo privado"}
                                                    </> :
                                                    <>
                                                        <FaLockOpen style={{ marginRight: '5px', color: 'gray' }} /> {"Fluxo público"}
                                                    </>
                                                }
                                            </ListText>
                                        </ListColumn>
                                        <ListColumn>
                                            <ListButton
                                                title={"Acessos"}
                                                Icon={FaUsersCog}
                                                color="#6666661a"
                                                onClick={() => {
                                                    setFlowSelected(undefined);
                                                    setWorkspaceSelected(workspace);
                                                    setRegisterSelected(undefined);
                                                    setOpenInviteUser(true);
                                                }}
                                            />
                                        </ListColumn>

                                    </ListItem>
                                )
                            })}
                        </List>
                    </ContainerList>
                )}

                {navBarSelected === 3 && (
                    <ContainerList>
                        <List>
                            <ListItem>
                                <ListColumn flex flexGrow={2}>
                                    <ListText header>
                                        Cadastro
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Identificador
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Registros
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Criado por
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Criado em
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Privacidade
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Ações
                                    </ListText>
                                </ListColumn>
                            </ListItem>

                            {registers !== undefined && registers.length > 0 && registers.map((register, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListColumn aglinItems="flex-start" flex flexGrow={2}>
                                            <a href={`/register/${register.hash}`} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                                                <BoxIcon color={register.color}>
                                                    <IconPickerItem
                                                        icon={register.icon as IconList}
                                                        color={"white"}
                                                    />
                                                </BoxIcon>
                                            </a>
                                            <a href={`/register/${register.hash}`} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                                                <ListText fontSize="15px" fontWeight="500">
                                                    {register.name}
                                                </ListText>
                                            </a>
                                        </ListColumn>
                                        <ListColumn>
                                            <ListText>
                                                <AutomaticData>{register?.id_register}</AutomaticData>
                                            </ListText>
                                        </ListColumn>
                                        <ListColumn>
                                            <ListText>
                                                {register.total_registers}
                                            </ListText>
                                        </ListColumn>
                                        <ListColumn>
                                            <ListText color="gray">
                                                {register.user_creator !== undefined && register.user_creator.name !== undefined ? register.user_creator.name : ""}
                                            </ListText>
                                        </ListColumn>
                                        <ListColumn>
                                            <ListText color="gray">
                                                {register.dt_created !== undefined ? formatDateToDateRead(new Date(register.dt_created)) : ""}
                                            </ListText>
                                        </ListColumn>
                                        <ListColumn>
                                            <ListText color="gray">
                                                {register !== undefined && register.isPrivate !== undefined && register.isPrivate === '1' ?
                                                    <>
                                                        <FaLock style={{ marginRight: '5px', color: 'gray' }} /> {"Fluxo privado"}
                                                    </> :
                                                    <>
                                                        <FaLockOpen style={{ marginRight: '5px', color: 'gray' }} /> {"Fluxo público"}
                                                    </>
                                                }
                                            </ListText>
                                        </ListColumn>
                                        <ListColumn>
                                            <ListButton
                                                title={"Acessos"}
                                                Icon={FaUsersCog}
                                                color="#6666661a"
                                                onClick={() => {
                                                    setFlowSelected(undefined);
                                                    setWorkspaceSelected(undefined);
                                                    setRegisterSelected(register);
                                                    setOpenInviteUser(true);
                                                }}
                                            />
                                        </ListColumn>

                                    </ListItem>
                                )
                            })}
                        </List>
                    </ContainerList>
                )}

                {openInviteUser && (
                    <InviteUser
                        open={openInviteUser}
                        onClose={() => setOpenInviteUser(false)}
                        flow={flowSelected}
                        workspace={workspaceSelected}
                        register={registerSelected}
                        typeUser={"A"}
                        isFromAdminPanel={true}
                        title="Gerencie o acesso"
                    />
                )}


            </ContainerFields>

        </ContentList>
    );

}

export default ControlAcess;