import { ComboItem, ComboItemDetail } from "../components/ItemRegister";
import { FieldProps, getItemComponentIcon } from "../components/Forms/Fields/FieldBuilder";
import { maskFormulaCurrency, maskFormulaNumber, maskFormulaPercent } from "../components/Forms/Fields/Components/Formula";

import { FormAnswer } from "../interfaces/FormAnswer";
import { Register } from "../interfaces/Register";
import parseFormula from "./Formula/parseFormula";

interface objectInit {
    [x: string]: string | object
}

export default function formAnswerToComboItemFromRegister(formAnswer: FormAnswer, register: Register): ComboItem {

    let newFormAnswer = formAnswer;

    //Title Rule
    const title: string = newFormAnswer.title === undefined ? "(Sem Título)" : newFormAnswer.title + "";

    //Formula Fields Rule
    let fields: FieldProps[] = [];
    for (let index = 0; index < newFormAnswer.form_answer_fields.length; index++) {
        fields.push(newFormAnswer.form_answer_fields[index].field);
    }

    //Filter the fields that are formula fields
    const formulaFields = fields.filter((f) => f.type === "FORMULA_FIELD" && (f.card_highlight === "1" || f.card_title === "1"));
    if (formulaFields.length > 0) {

        const newObjData: objectInit = {};
        let maskedValue = "";

        //Nomalize the data from form_answer_fields to object[]
        for (let index = 0; index < newFormAnswer.form_answer_fields.length; index++) {
            const formField = newFormAnswer.form_answer_fields[index];

            if (formField.field.type !== "FORMULA_FIELD") { //Remove the form field                        
                newObjData[formField.field.name] = formField.value;
            }
        }

        //Run all formula fields
        for (let index = 0; index < formulaFields.length; index++) {
            const fField = formulaFields[index];

            //Calcule formula value
            const calcValue = parseFormula(fField, fields, newObjData as unknown as object[]);

            //Mask the value
            if (fField.variation !== undefined) {
                if (fField.variation === "1") { //Number
                    maskedValue = maskFormulaNumber(calcValue);
                } else if (fField.variation === "2") { //Currency
                    maskedValue = maskFormulaCurrency(calcValue);
                } else if (fField.variation === "3") { //Percent
                    maskedValue = maskFormulaPercent(calcValue);
                } else {
                    maskedValue = maskFormulaNumber(calcValue);
                }
            }

            //Update the form_answer_fields
            for (let index = 0; index < newFormAnswer.form_answer_fields.length; index++) {
                if (newFormAnswer.form_answer_fields[index].field_id === fField.id_field) {
                    newFormAnswer.form_answer_fields[index].value = calcValue;
                    newFormAnswer.form_answer_fields[index].valueString = maskedValue;
                }
            }

            //Update the form_answers_highlitgh
            for (let index = 0; index < newFormAnswer.form_answers_highlitgh.length; index++) {
                if (newFormAnswer.form_answers_highlitgh[index].field_id === fField.id_field) {
                    newFormAnswer.form_answers_highlitgh[index].value = calcValue;
                    newFormAnswer.form_answers_highlitgh[index].valueString = maskedValue;
                }
            }

        }

    }

    //Details Rule
    let detailsNew: ComboItemDetail[] = [];
    if (newFormAnswer.form_answers_highlitgh !== undefined) {
        for (let idxB = 0; idxB < newFormAnswer.form_answers_highlitgh.length; idxB++) {
            const highlights = newFormAnswer.form_answers_highlitgh[idxB];

            const detailNew: ComboItemDetail = {
                title: highlights.field.title,
                Icon: getItemComponentIcon(highlights.field.type),
                value: highlights.valueString
            }

            detailsNew.push(detailNew);

        }
    }

    const itemNew: ComboItem = {
        id_register: newFormAnswer.id_form_answer,
        title: title,
        hash: register.hash,
        icon: register.icon,
        color: register.color,
        origin: register.name,
        originGroup: register.name,
        details: detailsNew,
        formAnswer: newFormAnswer
    }

    return itemNew;

}