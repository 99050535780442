import { maskFormulaCurrency, maskFormulaNumber, maskFormulaPercent } from "../../components/Forms/Fields/Components/Formula";

import { Card } from "../../interfaces/Card";
import { FieldProps } from "../../components/Forms/Fields/FieldBuilder";
import { FormAnswerField } from "../../interfaces/FormAnswerField";
import parseFormula from "./parseFormula";

interface objectInit {
    [x: string]: string | object
}

export default function updateFormulaFromCard(card: Card, fieldsBase?: FieldProps[]): Card {

    let newCard = card;

    //Formula Fields Rule
    let fields: FieldProps[] = [];
    if (fieldsBase !== undefined) {
        fields = fieldsBase;
    } else {
        if (newCard.form_answers !== undefined && newCard.form_answers.length > 0) {
            for (let index = 0; index < newCard.form_answers.length; index++) {
                for (let idxA = 0; idxA < newCard.form_answers[index].form_answer_fields.length; idxA++) {
                    fields.push(newCard.form_answers[index].form_answer_fields[idxA].field);
                }
            }
        }
    }

    //Filter the fields that are formula fields
    const formulaFields = fields.filter((f) => f.type === "FORMULA_FIELD");
    if (formulaFields.length > 0) {

        //Run all formula fields
        for (let index = 0; index < formulaFields.length; index++) {
            const fField = formulaFields[index];

            //If there is any form answer                            
            if (newCard.form_answers !== undefined && newCard.form_answers.length > 0) {
                //Filter the form answers that have the same form_id
                const formulaFormAnswers = newCard.form_answers?.filter((fa) => fa.form_id === fField.form_id);

                if (formulaFormAnswers.length > 0) {
                    for (let idxA = 0; idxA < formulaFormAnswers.length; idxA++) {
                        let foFa = formulaFormAnswers[idxA];

                        const newObjData: objectInit = {};
                        let maskedValue = "";
                        let foundAnswer = false;
                        let maxFormAnswer = 0;

                        //Nomalize the data from form_answer_fields to object[]
                        for (let idxC = 0; idxC < foFa.form_answer_fields.length; idxC++) {
                            const formField = foFa.form_answer_fields[idxC];

                            if (formField.field.type !== "FORMULA_FIELD") { //Remove the form field                        
                                newObjData[formField.field.name] = formField.value;
                            }
                        }

                        //Calcule formula value - Filter the fields to get just the fields from a form
                        const calcValue = parseFormula(fField, fields.filter((f) => f.form_id === foFa.form_id), newObjData as unknown as object[]);

                        //Mask the value
                        if (fField.variation !== undefined) {
                            if (fField.variation === "1") { //Number
                                maskedValue = maskFormulaNumber(calcValue);
                            } else if (fField.variation === "2") { //Currency
                                maskedValue = maskFormulaCurrency(calcValue);
                            } else if (fField.variation === "3") { //Percent
                                maskedValue = maskFormulaPercent(calcValue);
                            } else {
                                maskedValue = maskFormulaNumber(calcValue);
                            }

                        }

                        if (newCard.form_answers !== undefined) {
                            //Update the form_answer_fields
                            for (let idxD = 0; idxD < newCard.form_answers.length; idxD++) {
                                if (newCard.form_answers[idxD].form_answer_fields !== undefined) {
                                    for (let idxE = 0; idxE < newCard.form_answers[idxD].form_answer_fields.length; idxE++) {
                                        if (newCard.form_answers[idxD].form_answer_fields[idxE].field_id === fField.id_field) {
                                            foundAnswer = true;
                                            newCard.form_answers[idxD].form_answer_fields[idxE].value = calcValue;
                                            newCard.form_answers[idxD].form_answer_fields[idxE].valueString = maskedValue;
                                        }

                                        //Get the max FormAnswer
                                        if (maxFormAnswer < newCard.form_answers[idxD].form_answer_fields[idxE].id_form_answer_field) {
                                            maxFormAnswer = newCard.form_answers[idxD].form_answer_fields[idxE].id_form_answer_field;
                                        }
                                    }
                                }
                            }

                            //Update the form_answers_highlitgh
                            if (newCard.form_answers_highlitgh !== undefined) {
                                for (let idxE = 0; idxE < newCard.form_answers_highlitgh.length; idxE++) {
                                    if (newCard.form_answers_highlitgh[idxE].field_id === fField.id_field) {
                                        newCard.form_answers_highlitgh[idxE].value = calcValue;
                                        newCard.form_answers_highlitgh[idxE].valueString = maskedValue;
                                    }
                                }
                            }

                            //If no found the answer 
                            if (!foundAnswer && fField.id_field !== undefined) {
                                const newAnswer: FormAnswerField = {
                                    id_form_answer_field: maxFormAnswer + 1,
                                    form_answer_id: foFa.id_form_answer,
                                    field_id: fField.id_field,
                                    index: 0,
                                    value: calcValue,
                                    valueString: maskedValue,
                                    field: fField
                                }

                                //Add a New form answer in highlight
                                if (String(fField.card_highlight) === "1") {
                                    card.form_answers_highlitgh.push(newAnswer);
                                }

                                //Add a New form answer in form answer base
                                card.form_answers = card.form_answers?.map((fa) => {

                                    if (fa.form_id === fField.form_id && fa.id_form_answer === foFa.id_form_answer) {
                                        fa.form_answer_fields.push(newAnswer);
                                    }

                                    return fa;
                                })
                            }


                        }
                    }
                }
            }
        }
    }

    return newCard;

}