import styled from "styled-components";

export const Container = styled.div`
    
    background-color: transparent;
    border: 0px;
    padding: 16px;
    width: 100%;

    border-bottom: 1px solid gray;
    color: black;

    display: flex;
    align-items: center;

    input {
        flex: 1;
        background: transparent;
        border: 0;
            
        ::placeholder {
            color: gray;
        }
        
    }

    :hover {
        .buttons-label {
            display: flex;
        }

        .hide-item-active {
            display: none;
        }
    }
    
`;

export const ElementHeaderDrag = styled.div`
    display: flex;
    font-size: 17px;
    color: #4d4d4d;
    cursor: grab;
    color: #f23b5c;
    margin-right: 16px;
`;

export const ElementButtons = styled.div`
    display: none;

    button {
        border: none;
        background-color: transparent;
        margin-left: 16px;    
        display: flex;
        justify-content: center;
        align-items: center;            

        svg {
            color: #f23b5c;
            transition: transform 0.3s ease;        

            :hover {
                transform: scale(1.1);
            }
        }
    }
`;