import React from "react";

import emptyTask from '../../../assets/empty-state/empty-task.png';
import {
    Container,
    ContainerImage,
    ContainerSubTitle,
    ContainerTitle
} from "./style";

interface emptyStateProps {

}

const EmptyNotification: React.FC<emptyStateProps> = () => {

    return (
        <Container>
            <ContainerImage>
                <img src={emptyTask} alt="Sem Notificações" />
            </ContainerImage>
            <ContainerTitle>
                <h1>Você não possui notificações</h1>
            </ContainerTitle>
            <ContainerSubTitle>
                <span>Fique tranquilo, se aparecer algo novo, avisaremos você!</span>
            </ContainerSubTitle>
        </Container>
    );

}

export default EmptyNotification;