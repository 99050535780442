import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import LayoutContainer from '../../components/LayoutContainer';
import ListContainer from '../../components/ListContainer';
import LoaderUseTemplate from "../Loader/LoaderUseTemplate";
import api from '../../services/api';
import TemplateCentral, { RouteTemplateCentralParams } from "../TemplateCentral";
import {
    Container,
    MainContainer
} from "./style";
import { Flow } from "../../interfaces/Flow";
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import NewFlow from "../../dialogs/NewFlow";

interface objectInit {
    [x: string]: string
}

function useQueryParams(): objectInit[] {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    let params: objectInit[] = [];

    for (let param of searchParams) {
        params.push({ [param[0]]: param[1] });
    }

    return params;
}

const TemplateStore: React.FC = () => {

    const { user } = useAuth();
    const { addToast } = useToast();
    const history = useHistory();
    const { params } = useRouteMatch<RouteTemplateCentralParams>();
    const queryParams = useQueryParams();

    const [loading, setLoading] = useState<boolean>(false);
    const [workspaceId, setWorkspaceId] = useState<number>();
    const [paramsTemplate, setParamsTemplate] = useState<RouteTemplateCentralParams>();

    const [openNewFlow, setOpenNewFlow] = useState<boolean>(false);

    const handleFromScratch = useCallback(async () => {

        setOpenNewFlow(true);

    }, []);

    const handleUseTemplate = useCallback(async (template_id: number) => {

        let flowHash = "";

        if (template_id > 0) {

            setLoading(true);

            await api.post('/template/use-count', {
                id_template: template_id
            })

            await api
                .post('/template/use-template', {
                    id_template: template_id,
                    id_user: user.id_user,
                    company_id: user.company_id,
                    workspace_id: workspaceId
                })
                .then(response => {

                    const flowApi: Flow = response.data;

                    flowHash = flowApi.hash;

                }).catch(error => {
                    console.log(error);
                    history.push('/dashboard');
                    addToast({
                        type: 'error',
                        title: 'Erro ao criar template!',
                        description: 'Ocorreu um erro ao tentar criar o template na base de dados!',
                    });
                });

            if (flowHash !== "") {
                history.push('/flow/' + flowHash + "/onboarding");
            } else {
                history.push('/my-flows');
            }

        }

    }, [addToast, history, user.company_id, user.id_user, workspaceId]);

    useEffect(() => {

        if (params !== undefined) {
            setParamsTemplate(params);
        }

        //Valid queryParams
        if (queryParams !== undefined && queryParams.length > 0) {

            //Find Workspace query
            const workspace = queryParams.find(param => param.workspace !== undefined);

            if (workspace !== undefined) {
                if (!isNaN(Number(workspace['workspace']))) {
                    setWorkspaceId(Number(workspace['workspace']));
                }
            }
        }

    }, [params, queryParams]);


    return (
        <>
            {loading ?
                <>
                    <title>Criando seu template... | Cange</title>
                    <LoaderUseTemplate />
                </> :
                <MainContainer>
                    <title>Central de Templates | Cange</title>
                    <LayoutContainer>
                        <ListContainer>
                            <Container>
                                <TemplateCentral
                                    onboarding={undefined}
                                    handleTemplate={handleUseTemplate}
                                    handleFromScratch={handleFromScratch}
                                    params={paramsTemplate}
                                    typeUser={user.type}
                                />
                                {openNewFlow && (
                                    <NewFlow
                                        open={openNewFlow}
                                        workspaceId={workspaceId}
                                        onClose={() => setOpenNewFlow(false)}
                                    />
                                )}
                            </Container>
                        </ListContainer>
                    </LayoutContainer>
                </MainContainer>
            }
        </>
    );

}

export default TemplateStore;