import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const ContentContainer = styled.div`
    width: 100%;
    background-color: white;
    //border-radius: 10px;



`;


export const Header = styled.div`

`;