import axios from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080/"; //"https://cange-back-hml.herokuapp.com";

const api = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*'
    }
});

//Intercept all requests
api.interceptors.request.use(function (config) {

    const currentToken = localStorage.getItem("@wendata:token");

    if (currentToken !== undefined && currentToken !== null) {
        const tokenParse = JSON.parse(window.atob(currentToken.split('.')[1]));
        const decodedJwt = tokenParse;

        if ((decodedJwt.exp * 1000) < Date.now()) {
            localStorage.removeItem('@wendata:token');
            localStorage.removeItem('@wendata:user');
        }
    } else {
        localStorage.removeItem('@wendata:token');
        localStorage.removeItem('@wendata:user');
    }

    return config;
}, function (error) {
    return Promise.reject(error);
});

export default api;