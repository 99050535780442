import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, ClickAwayListener } from "@material-ui/core";
import {
    BoxContainer,
    ButtonLink,
    Input,
    SearchInput,
    ContainerViews,
    DividerGroup,
    ContainerViewSection,
    ViewSectionTitle,
    ViewSectionList,
    ViewSectionItem,
    SectionItemIcon,
    SectionItemTitle,
    SectionItemAction,
    ButtonAddNew,
    ButtonClear,
    CleanBox,
    CleanBoxText,
    CleanBoxTitle,
    ButtonSave,
    BoxContainerSaveView
} from "./style";
import { MdOutlineViewList } from "react-icons/md";
import { AiFillSave, AiFillThunderbolt, AiOutlineSave, AiOutlineSearch } from "react-icons/ai";
import { IoIosCloseCircle } from "react-icons/io";
import { IconPickerItem } from "react-fa-icon-picker";
import { FaPen, FaRegSave } from "react-icons/fa";
import AddNewView from "../../../dialogs/AddNewView";
import { FilterFlowProps } from "../FilterDialog";
import api from "../../../services/api";
import { FlowView } from "../../../interfaces/FlowView";
import { useAuth } from "../../../hooks/auth";
import { useToast } from "../../../hooks/toast";
import { BsEraser } from "react-icons/bs";
import ContentLoader from "react-content-loader";
import DropDownItem from "../../DropDownList/DropDownItem";
import getAccessControl from "../../../middlewares/AccessControl";

interface ViewFlowProps {
    flow_id: number;
    schema?: FilterFlowProps,
    selectedViewId?: number,
    initFilterFlow?: FilterFlowProps,
    hasUpdates?: boolean,
    typeUser: string,
    onClickView: (schema: FilterFlowProps, dialogOpen: boolean) => void;
}

const LoaderContainer = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={'200px'}
        viewBox="0 0 300 200"
        backgroundColor="#ffffff"
        foregroundColor="#f3f3f3"
    >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="200" />
    </ContentLoader>
)

const ViewFlow: React.FC<ViewFlowProps> = ({ schema, flow_id, selectedViewId, initFilterFlow, onClickView, typeUser }) => {

    const [openList, setOpenList] = useState<boolean>();
    const [searchText, setSearchText] = useState<string>("");
    const [flowViews, setFlowViews] = useState<FlowView[]>();
    const [flowViewsBase, setFlowViewsBase] = useState<FlowView[]>();
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const { user } = useAuth();
    const { addToast } = useToast();

    const [openNewView, setOpenNewView] = useState<boolean>(false);
    const [openSaveView, setOpenSaveView] = useState<boolean>(false);

    const [selectedView, setSelectedView] = useState<FlowView>();
    const [editFlowView, setEditFlowView] = useState<FlowView>();

    const [loading, setLoading] = useState<boolean>();

    const handleClickAway = () => {
        setOpenList(false);
        setOpenSaveView(false)
    };

    const onSearch = (text: React.FormEvent<HTMLInputElement>) => {
        setSearchText(text?.currentTarget.value);

        const textToSearch = text?.currentTarget.value;

        if (textToSearch !== undefined && textToSearch.trim() !== "") {

            const newItems = flowViews?.filter((fv) => fv.name.toLocaleLowerCase().includes(textToSearch));

            setFlowViews(newItems);

        } else {

            setFlowViews(flowViewsBase);

        }
    };

    const handleNewViewClose = useCallback(async (newView?: FlowView) => {

        setOpenNewView(false)

        if (newView !== undefined) {
            setSelectedView(newView);

            let newSchema = JSON.parse(newView.schema) as FilterFlowProps;
            newSchema.flow_view_id = newView.id_flow_view;

            onClickView(newSchema, false);
        }

    }, [onClickView]);

    const handleSaveView = useCallback(async () => {

        if (selectedView !== undefined) { //Update the view

            await api
                .post('/flow-view', {
                    id_flow_view: selectedView.id_flow_view,
                    flow_id: flow_id,
                    name: selectedView.name,
                    schema: JSON.stringify(schema)
                })
                .then(response => {
                    setOpenList(false);
                    addToast({
                        type: 'success',
                        title: 'Visualização salva com sucesso!',
                        description: 'Wow! A sua visualização foi salva com sucesso! :)',
                    });
                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar a visualização!',
                        description: 'Ocorreu um erro ao salvar a visualização!',
                    });
                });

        } else { //Save the schema in the flow

            if (schema !== undefined && schema.flow_view_id === undefined && schema.fieldView !== undefined) {
                //Save the filter flow as the flow preference
                await api
                    .post('/flow', {
                        id_flow: flow_id,
                        schema_view: JSON.stringify(schema.fieldView)
                    })
                    .then(response => {
                        addToast({
                            type: 'success',
                            title: 'Visualização padrão salva com sucesso!',
                            description: 'Wow! A visualização padrão foi salva com sucesso! :)',
                        });
                    }).catch(error => {
                        addToast({
                            type: 'error',
                            title: 'Erro ao salvar a visualização!',
                            description: 'Ocorreu um erro ao salvar a visualização!',
                        });
                    });
            }

        }

    }, [schema, addToast, flow_id, selectedView]);

    const dialogDeleteSubmmit = useCallback(async () => {

        if (selectedView !== undefined) {

            await api.delete('/flow-view', {
                params: {
                    id_flow_view: selectedView.id_flow_view,
                    flow_id: selectedView.flow_id
                }
            }).then(response => {

                setSelectedView(undefined);
                handleNewViewClose();

                return true;

            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao deletar a visualização',
                    description: 'Ocorreu um erro ao tentar deletar a visualização!',
                });
            });


        }

    }, [addToast, selectedView, handleNewViewClose]);

    useEffect(() => {

        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }

    }, [openList]);

    useEffect(() => {

        if (flow_id !== undefined && (openList || selectedViewId !== undefined)) {

            setLoading(true);

            //Get the all flow views
            api.get(`/flow-view/by-flow`, {
                params: {
                    flow_id: flow_id
                }
            }).then(response => {
                if (response.data !== null) {
                    const respFlowView: FlowView[] = response.data;

                    if (selectedViewId !== undefined && !openList && firstLoad) {
                        const fv = respFlowView.find((fv) => fv.id_flow_view === selectedViewId);

                        if (fv !== undefined) {
                            let newSchema = JSON.parse(fv?.schema) as FilterFlowProps;
                            newSchema.flow_view_id = fv?.id_flow_view;

                            onClickView(newSchema, false);
                            setSelectedView(fv);
                        }
                    }

                    setFirstLoad(false);
                    setSearchText("");
                    setFlowViews(respFlowView);
                    setFlowViewsBase(respFlowView);
                    setLoading(false);
                }
            }).catch(error => {
                setLoading(false);
            });

        }

    }, [flow_id, selectedViewId, openList, onClickView, firstLoad]);

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box style={{ display: "flex", width: "100%" }}>

                    {selectedView ?
                        <ButtonClear type="button"
                            onClick={() => {
                                setSelectedView(undefined);
                                onClickView({ fieldView: initFilterFlow?.fieldView, conditions: [], orderBy: [], searchText: "", flow_view_id: undefined }, false);
                            }}
                        >
                            <BsEraser />
                            <div>Limpar</div>
                        </ButtonClear> :
                        <></>
                    }

                    {((selectedView && selectedView.user_id_creator === user.id_user) ||
                        (selectedView === undefined)) && getAccessControl(77, typeUser) ?
                        <>
                            <ButtonSave type="button"
                                onClick={() => {
                                    setOpenSaveView(!openSaveView)
                                    setOpenList(false)
                                }}
                                title="Salvar visualização atual"
                            >
                                <AiOutlineSave />

                                {openSaveView !== undefined && openSaveView === true ?
                                    <BoxContainerSaveView>
                                        {selectedView === undefined ?
                                            getAccessControl(76, typeUser) ?
                                                <DropDownItem title="Salvar visualização para todos" icon={FaRegSave} color={"#f23b5c"} onClick={() => handleSaveView()} /> :
                                                <></> :
                                            <DropDownItem title="Salvar visualização atual" icon={FaRegSave} color={"#f23b5c"} onClick={() => handleSaveView()} />
                                        }
                                        <DropDownItem title="Criar nova visualização" icon={AiFillThunderbolt} color={"#9d37ed"} onClick={() => {
                                            setOpenNewView(true);
                                            setEditFlowView(undefined);
                                        }} />
                                    </BoxContainerSaveView> :
                                    <></>
                                }
                            </ButtonSave>
                        </>
                        :
                        <></>
                    }

                    <ButtonLink type="button"
                        onClick={() => {
                            setOpenList(true);
                            setOpenSaveView(false);
                        }}
                        active={selectedView !== undefined}
                        color={selectedView !== undefined ? selectedView.color : undefined}
                    >
                        {selectedView === undefined ?
                            <MdOutlineViewList /> :
                            <IconPickerItem
                                icon={selectedView.icon}
                                color={selectedView.color}
                            />
                        }
                        <div className="view-desc">{selectedView !== undefined ? selectedView.name : "Visualizações"}</div>
                    </ButtonLink>

                    {openList ? (
                        <BoxContainer>

                            <SearchInput>
                                <AiOutlineSearch />
                                <Input
                                    onChange={(e) => onSearch(e)}
                                    placeholder="Busque aqui..."
                                    value={searchText}
                                    ref={searchInputRef}
                                />
                                {searchText !== undefined && searchText !== "" ?
                                    <IoIosCloseCircle
                                        style={{ marginRight: "9px" }}
                                        onClick={() => {
                                            setSearchText("");
                                            setFlowViews(flowViewsBase);
                                        }}
                                    /> :
                                    <></>
                                }
                            </SearchInput>

                            <ContainerViews>

                                {!loading && (flowViewsBase === undefined || flowViewsBase.length === 0) ?
                                    <CleanBox>
                                        <MdOutlineViewList />
                                        <CleanBoxTitle>
                                            Personalize suas visualizações de dados para um acesso simplificado e rápido
                                        </CleanBoxTitle>
                                        <CleanBoxText>
                                            Não perca tempo filtrando e ordenando as mesmas informações! Faça os filtros do seu jeito e crie agora a sua primeira visualização.
                                        </CleanBoxText>
                                    </CleanBox> :
                                    <></>
                                }

                                {loading && (flowViewsBase === undefined || flowViewsBase.length === 0) ? (
                                    <div style={{ marginTop: '10px' }}>
                                        <LoaderContainer />
                                    </div>
                                ) : <></>}

                                {flowViews && flowViews?.filter((fv) => fv.user_id_creator === user.id_user).length > 0 ?
                                    <ContainerViewSection>
                                        <ViewSectionTitle>
                                            Minhas Visualizações
                                        </ViewSectionTitle>
                                        <ViewSectionList>

                                        {flowViews && flowViews?.filter((fv) => fv.user_id_creator === user.id_user).sort((a, b) => a.name.localeCompare(b.name)).map((fv) => {

                                                let newFilterFlow: FilterFlowProps = JSON.parse(fv.schema) as FilterFlowProps;
                                                newFilterFlow.flow_view_id = fv.id_flow_view;

                                                return (
                                                    <ViewSectionItem
                                                        key={fv.id_flow_view}
                                                        isActive={selectedView?.id_flow_view === fv.id_flow_view}
                                                        onClick={() => {
                                                            onClickView(newFilterFlow, false);
                                                            setSelectedView(fv);
                                                            setOpenList(false);
                                                        }}
                                                        color={fv.color}>
                                                        <SectionItemIcon>
                                                            <IconPickerItem
                                                                icon={fv.icon}
                                                                color={fv.color}
                                                            />
                                                        </SectionItemIcon>
                                                        <SectionItemTitle>
                                                            {fv.name}
                                                        </SectionItemTitle>
                                                        <SectionItemAction className="opt-item" onClick={() => {
                                                            setOpenNewView(true);
                                                            setEditFlowView(fv)
                                                        }}>
                                                            <FaPen />
                                                        </SectionItemAction>
                                                    </ViewSectionItem>
                                                )
                                            })}

                                        </ViewSectionList>
                                    </ContainerViewSection> :
                                    <></>
                                }

                                {flowViews && flowViews?.filter((fv) => fv.user_id_creator !== user.id_user).length > 0 ?
                                    <ContainerViewSection>
                                        <ViewSectionTitle>
                                            Visualizações Públicas
                                        </ViewSectionTitle>
                                        <ViewSectionList>

                                            {flowViews?.filter((fv) => fv.user_id_creator !== user.id_user).sort((a, b) => a.name.localeCompare(b.name)).map((fv) => {

                                                let newFilterFlow: FilterFlowProps = JSON.parse(fv.schema) as FilterFlowProps;
                                                newFilterFlow.flow_view_id = fv.id_flow_view;

                                                return (
                                                    <ViewSectionItem
                                                        key={fv.id_flow_view}
                                                        color={fv.color}
                                                        isActive={selectedView?.id_flow_view === fv.id_flow_view}
                                                        onClick={() => {
                                                            onClickView(newFilterFlow, false);
                                                            setSelectedView(fv);
                                                            setOpenList(false);
                                                        }}>
                                                        <SectionItemIcon>
                                                            <IconPickerItem
                                                                icon={fv.icon}
                                                                color={fv.color}
                                                            />
                                                        </SectionItemIcon>
                                                        <SectionItemTitle>
                                                            {fv.name}
                                                        </SectionItemTitle>
                                                    </ViewSectionItem>
                                                )
                                            })}

                                        </ViewSectionList>
                                    </ContainerViewSection> :
                                    <></>
                                }

                            </ContainerViews>

                            {getAccessControl(77, typeUser) ?
                                <ContainerViewSection style={{ marginTop: '0px' }}>
                                    <DividerGroup />

                                    {selectedView && selectedView.user_id_creator === user.id_user ?
                                        <ButtonAddNew onClick={() => handleSaveView()}>
                                            <AiFillSave />
                                            Salvar esta visualização
                                        </ButtonAddNew> :
                                        <></>
                                    }

                                    <ButtonAddNew onClick={() => {
                                        setOpenNewView(true);
                                        setEditFlowView(undefined);
                                    }} color="#9d37ed1e">
                                        <AiFillThunderbolt />
                                        Criar nova visualização
                                    </ButtonAddNew>

                                </ContainerViewSection> :
                                <></>
                            }

                        </BoxContainer>
                    ) : <></>
                    }

                </Box>
            </ClickAwayListener >
            <AddNewView
                open={openNewView}
                schema={schema}
                flow_id={flow_id}
                selectedView={editFlowView}
                onClose={handleNewViewClose}
                dialogDeleteSubmmit={dialogDeleteSubmmit}
            />
        </>
    );
};

export default ViewFlow;
