
export default function getNamesFromEmail(email?: string, flowAddress?: string, flowName?: string): string {

    if (email) {

        let names: string = "";

        if (flowAddress && flowName && flowAddress === email) {
            names += flowName;
        } else {
            names += email;
        }

        return names;

    } else {
        return "(Sem registro)";
    }

}