import {
  BoxContainer,
  BoxItemsContainer,
  Container,
  ShortCutBarContainer,
  ShortCutDesc,
  ShortCutItem,
  StatusIcon,
  TextInput
} from "./styles";
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";

import { BsFillCheckCircleFill } from "react-icons/bs";
import { ClickAwayListener } from "@material-ui/core";
import ErrorDescription from "../../../../ErrorDescription";
import { FieldProps } from "../../FieldBuilder";
import { IconBaseProps } from "react-icons";
import ItemFormulaBox from "./ItemFormula";
import { MdError } from "react-icons/md";
import { evaluate } from "mathjs";
import { useField } from "@unform/core";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  description?: string;
  placeholder?: string;
  rowMax?: number;
  formFields?: FieldProps[];
}

export interface ItemFormula {
  hash: string;
  type: number; //0 - Field / 1 - Formula
  typeField: string;
  nameLabel: string;
}

const TextAreaFormula: React.FC<InputProps> = ({
  name,
  description,
  icon: Icon,
  disabled,
  placeholder,
  rowMax,
  formFields,
  ...rest
}) => {

  const { fieldName, registerField, error, defaultValue } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRefAux = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [openHelper, setOpenHelper] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [valueFormula, setValueFormula] = useState<string>("");
  const [valueHash, setValueHash] = useState<string>("");
  const [itemsFormula, setItemsFormula] = useState<ItemFormula[]>([]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    });
  }, [fieldName, registerField]);

  const handleClickAway = () => {
    setOpenHelper(false);
  };

  const handleInputFocused = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    //setIsFilled(!!inputRef.current?.value);
  }, []);

  const onClickItemFormula = useCallback((nameLabel: string) => {

    if (valueFormula !== undefined) {
      setValueFormula(valueFormula + "{" + nameLabel + "} ");
    } else {
      setValueFormula("{" + nameLabel + "} ");
    }

  }, [valueFormula]);

  const handleValidFormula = useCallback(() => {

    let ret: boolean = true;

    if (valueFormula !== undefined) {
      var re = /{(.*?)}/;
      var splited = valueFormula.split(re);

      let newFormula = valueFormula;

      //Unparse to DB      
      for (let index = 0; index < splited.length; index++) {
        const item = splited[index];
        const fieldToCheck = formFields?.filter((field) => field.title.trim() === item.trim());

        //Items that not found relationship
        if (fieldToCheck !== undefined && fieldToCheck?.length > 0) {
          newFormula = newFormula?.replace("{" + item + "}", "5");
        }
      }

      //Try the formula 
      if (ret) {

        try {
          evaluate(newFormula);
        } catch (error) {
          console.log(error);
          ret = false;
        }

      }

    }

    setIsChecked(ret);

  }, [formFields, valueFormula]);

  const onChangeFormula = useCallback((text: string) => {

    setOpenHelper(true);
    setValueFormula(text);

  }, []);

  const onClickItemShortCut = useCallback((nameLabel: string) => {

    if (valueFormula !== undefined) {
      setValueFormula(valueFormula + "" + nameLabel + " ");
    } else {
      setValueFormula(nameLabel + " ");
    }

  }, [valueFormula]);

  const maxRow = !!rowMax ? rowMax : 8;

  useEffect(() => {
    handleValidFormula();
  }, [handleValidFormula, valueFormula]);

  useEffect(() => {

    const allowTypes = "CURRENCY_FIELD|NUMBER_FIELD";

    let newItemsFormula: ItemFormula[] = [];

    if (formFields !== undefined) {
      for (let index = 0; index < formFields.length; index++) {
        const field = formFields[index];
        if (allowTypes.includes(field.type)) {

          const newItem: ItemFormula = {
            hash: field.name,
            type: 0, //0 - Field / 1 - Formula
            typeField: field.type,
            nameLabel: field.title
          }

          newItemsFormula.push(newItem)

        }

      }
    }

    setItemsFormula(newItemsFormula);

  }, [formFields]);

  useEffect(() => {

    setValueFormula(defaultValue);

  }, [defaultValue]);


  useEffect(() => {

    let newFormula = valueFormula;
    let newValue = valueFormula;

    if (valueFormula !== undefined) {

      var re = /{(.*?)}/;
      var splited = valueFormula.split(re);

      //Unparse to DB      
      for (let index = 0; index < splited.length; index++) {
        const item = splited[index];

        const fieldToReplace = formFields?.filter((field) => field.title.trim() === item.trim());

        if (fieldToReplace !== undefined && fieldToReplace?.length > 0) {
          newFormula = newFormula?.replace("{" + item + "}", "{" + fieldToReplace[0].name + "}");
        }
      }

      //Parse to view
      let isParsed = false;
      for (let index = 0; index < splited.length; index++) {
        const item = splited[index];

        const fieldToReplace = formFields?.filter((field) => field.name.trim() === item.trim());

        if (fieldToReplace !== undefined && fieldToReplace?.length > 0) {
          isParsed = true;
          newValue = newValue?.replace("{" + item + "}", "{" + fieldToReplace[0].title + "}");
        }
      }

      //If dont is checked, send a blank value
      if (isChecked) {
        setValueHash(newFormula);
      } else {
        setValueHash("");
      }

      if (isParsed) {
        setValueFormula(newValue);
      }

    } else {
      setValueHash(newFormula);
    }

  }, [valueFormula, formFields, isChecked]);

  return (
    <>
      <input
        style={{ fontSize: '10px', display: 'none' }}
        value={valueHash || ''}
        ref={inputRef}
        name={name}
        readOnly
      />
      <Container
        isErrored={!!error}
        isFocused={isFocused}
        isDisabled={!!disabled}
      >
        {Icon && <Icon size="22" />}
        <TextInput
          id="textarea"
          onFocus={handleInputFocused}
          onBlur={handleInputBlur}
          name={"inputAux"}
          inputRef={inputRefAux}
          onClick={() => {
            setOpenHelper(true)
          }}
          onChange={(e) => {
            onChangeFormula(e.currentTarget.value)
          }}
          placeholder={placeholder}
          multiline
          value={valueFormula || ''}
          maxRows={maxRow}
          error={false}
        >
        </TextInput>
        <StatusIcon>
          {valueFormula !== undefined && valueFormula !== "" ?
            isChecked ?
              <BsFillCheckCircleFill color="#2dba6a" size={18} /> :
              <MdError color="#db5c6b" size={18} />
            : <></>}
        </StatusIcon>
      </Container>
      {/* Help Formula */}
      {openHelper ? (
        <ClickAwayListener onClickAway={handleClickAway}>
          <BoxContainer>

            <ShortCutBarContainer>

              <ShortCutDesc>Atalhos:</ShortCutDesc>
              <ShortCutItem key="+" type="button" onClick={() => onClickItemShortCut("+")}>+</ShortCutItem>
              <ShortCutItem key="-" type="button" onClick={() => onClickItemShortCut("-")}>-</ShortCutItem>
              <ShortCutItem key="*" type="button" onClick={() => onClickItemShortCut("*")}>*</ShortCutItem>
              <ShortCutItem key="/" type="button" onClick={() => onClickItemShortCut("/")}>/</ShortCutItem>
              <ShortCutItem key="(" type="button" onClick={() => onClickItemShortCut("(")}>(</ShortCutItem>
              <ShortCutItem key=")" type="button" onClick={() => onClickItemShortCut(")")}>)</ShortCutItem>

            </ShortCutBarContainer>

            <BoxItemsContainer>

              {itemsFormula.map((item) => {
                return (
                  <ItemFormulaBox key={item.hash} item={item} onClick={onClickItemFormula} />
                )
              })}

            </BoxItemsContainer>

          </BoxContainer>
        </ClickAwayListener>
      ) : null}

      {error && (<ErrorDescription title={error} />)}
    </>
  );
};

export default TextAreaFormula;
