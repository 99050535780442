import React, { useEffect, useState } from "react";

import { FilterComponentProps } from "../../FilterDialog";
import { Input } from "./style";

const FilterInput: React.FC<FilterComponentProps> = ({ comparator, onEvent, value }) => {

    const [isBlank, setIsBlank] = useState<boolean>(false);

    useEffect(() => {

        if (comparator === "Está em branco" || comparator === "Não está em branco") {
            setIsBlank(true);
        } else {
            setIsBlank(false);
        }

    }, [comparator]);

    return (
        <>
            {isBlank ?
                <Input disabled={true} onChange={onEvent} value={value}/> :
                <Input 
                    placeholder="Digite aqui..." 
                    onChange={onEvent}
                    value={value}
                />
            }
        </>
    );
}

export default FilterInput;