import {
    ContainerFields,
    ContentList,
    DividerMenu,
    LogoField,
    LogoFieldBtnRemove,
    LogoFieldBtnUpload,
    LogoFieldButtons,
    LogoFieldContent,
    LogoFieldImage,
    LogoFieldTitle
} from "./style";
import { FieldProps, Fields } from "../../../components/Forms/Fields/FieldBuilder";
import React, { useCallback, useEffect, useRef, useState } from "react";

import FormBuilder from "../../../components/Forms/FormBuilder";
import { FormHandles } from "@unform/core";
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { Company } from "../../../interfaces/Company";
import { BsCardImage } from "react-icons/bs";
import UploadImageForm from "../../../dialogs/FormPublicConfig/UploadImageForm";
import FreemiumFeature from "../../../components/Fremium/FreemiumFeature";
import { getFeatureControlByPlan } from "../../../middlewares/FeatureControl";

const fieldsForm: FieldProps[] = [
    {
        name: "name",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 1,
        title: "Nome da Organização",
        description: "Personalize o Cange com o nome da sua organização",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome do Cadastro é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ],
        max_length: 100
    }
]

const linkWhatsapp = "https://api.whatsapp.com/send?phone=551151989897&text=Ol%C3%A1!%20Quero%20saber%20como%20posso%20personalizar%20o%20Cange%20com%20a%20identidade%20visual%20da%20minha%20marca%20%F0%9F%98%80";

const CompanyProfile: React.FC = () => {

    const formRef = useRef<FormHandles>(null);

    const { user, renewUser } = useAuth();
    const { addToast } = useToast();

    const [openUploadLogoMini, setOpenUploadLogoMini] = useState(false);
    const [openUploadLogo, setOpenUploadLogo] = useState(false);

    const goToContactPage = useCallback(() => {
        
        window.open(linkWhatsapp, "_blank");

    }, []);

    const handleSavePage = useCallback(async () => {

        if (formRef.current !== null && user.company !== undefined && user.company !== null) {

            const dataNormalized = formRef.current.getData() as unknown as Company;

            api
                .post('/company', {
                    id_company: user.company.id_company,
                    name: dataNormalized.name
                })
                .then(response => {

                    renewUser();

                    addToast({
                        type: 'success',
                        title: 'Perfil da Organização salvo com sucesso!',
                        description: 'As informações do perfil da Organização foram salvas com sucesso!',
                    });

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar o perfil da Organização',
                        description: 'Ocorreu um erro ao salvar o perfil da Organização, tente novamente!',
                    });
                });

        }

    }, [addToast, user.company, renewUser]);

    const handleRemoveLogoMini = useCallback(async () => {

        if (user.company !== undefined && user.company !== null) {

            api
                .post('/company', {
                    id_company: user.company.id_company,
                    logo_mini_id: null,
                    logo_mini_url: null
                })
                .then(response => {

                    renewUser();

                    addToast({
                        type: 'success',
                        title: 'Logo da Organização removido com sucesso!',
                        description: 'O logo da Organização foi removido com sucesso!',
                    });

                    setOpenUploadLogo(false);

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar o perfil da Organização',
                        description: 'Ocorreu um erro ao salvar o perfil da Organização, tente novamente!',
                    });

                    setOpenUploadLogo(false);
                });

        }

    }, [addToast, renewUser, user.company]);

    const handleRemoveLogo = useCallback(async () => {

        if (user.company !== undefined && user.company !== null) {

            api
                .post('/company', {
                    id_company: user.company.id_company,
                    logo_id: null,
                    logo_url: null
                })
                .then(response => {

                    renewUser();

                    addToast({
                        type: 'success',
                        title: 'Logo da Organização removido com sucesso!',
                        description: 'O logo da Organização foi removido com sucesso!',
                    });

                    setOpenUploadLogo(false);

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar o perfil da Organização',
                        description: 'Ocorreu um erro ao salvar o perfil da Organização, tente novamente!',
                    });

                    setOpenUploadLogo(false);
                });

        }


    }, [addToast, renewUser, user.company]);

    const handleUploadLogoMiniClose = useCallback(async (id_attachment?: number, form_logo_url?: string) => {

        if (user.company !== undefined && user.company !== null && id_attachment !== undefined && form_logo_url !== undefined && getFeatureControlByPlan(5, user.company)) {

            api
                .post('/company', {
                    id_company: user.company.id_company,
                    logo_mini_id: id_attachment,
                    logo_mini_url: form_logo_url
                })
                .then(response => {

                    renewUser();

                    addToast({
                        type: 'success',
                        title: 'Logo da Organização salvo com sucesso!',
                        description: 'O logo da Organização foram salvas com sucesso!',
                    });

                    setOpenUploadLogoMini(false);

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar o perfil da Organização',
                        description: 'Ocorreu um erro ao salvar o perfil da Organização, tente novamente!',
                    });

                    setOpenUploadLogoMini(false);
                });

        } else {
            setOpenUploadLogoMini(false);
        }

    }, [addToast, renewUser, user.company]);

    const handleUploadLogoClose = useCallback(async (id_attachment?: number, form_logo_url?: string) => {

        if (user.company !== undefined && user.company !== null && id_attachment !== undefined && form_logo_url !== undefined && getFeatureControlByPlan(5, user.company)) {

            api
                .post('/company', {
                    id_company: user.company.id_company,
                    logo_id: id_attachment,
                    logo_url: form_logo_url
                })
                .then(response => {

                    renewUser();

                    addToast({
                        type: 'success',
                        title: 'Logo da Organização salvo com sucesso!',
                        description: 'O logo da Organização foram salvas com sucesso!',
                    });

                    setOpenUploadLogo(false);

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar o perfil da Organização',
                        description: 'Ocorreu um erro ao salvar o perfil da Organização, tente novamente!',
                    });

                    setOpenUploadLogo(false);
                });

        } else {
            setOpenUploadLogo(false);
        }

    }, [addToast, renewUser, user.company]);

    const handleSubmitForm = useCallback(async (data: object[]) => {

        await handleSavePage();

    }, [handleSavePage]);

    const handleSubmitPage = useCallback(async () => {
        if (formRef.current !== undefined) {
            formRef.current?.submitForm();
        }
    }, []);

    useEffect(() => {

        if (formRef.current !== null && user !== null && user.company !== undefined && user.company !== null) {
            formRef.current.setData(user.company);
        }

    }, [user]);

    return (
        <ContentList>

            {/* Forms */}
            <ContainerFields item xs={12} md={6}>

                <FormBuilder
                    id="form"
                    formRef={formRef}
                    fields={fieldsForm}
                    handleSubmit={handleSubmitForm}
                    handleBlur={() => handleSubmitPage()}
                    hideContainer={true}
                />

                <DividerMenu />

                <FreemiumFeature
                    isPaidFeature={!getFeatureControlByPlan(5, user.company)}
                    title="Identidade visual personalizada"
                    description="Personalize a identidade visual do Cange com a marca da sua organização."
                    planRequired="Enterprise"
                    buttonText="Falar com time de vendas"
                    handleUpgrade={() => goToContactPage()}                    
                >
                    <LogoField>
                        <LogoFieldTitle>
                            <h3>Logo</h3>
                            <span>Personalize o Cange com a marca da sua organização</span>
                        </LogoFieldTitle>
                        <LogoFieldContent>
                            <LogoFieldImage style={{ background: '#272727' }} onClick={() => setOpenUploadLogoMini(true)}>
                                {user !== undefined && user.company !== undefined && user.company.logo_mini_url !== undefined && user.company.logo_mini_url !== null ?
                                    <img src={user.company.logo_mini_url} alt="Imagem de capa" /> :
                                    <BsCardImage />
                                }
                            </LogoFieldImage>
                            <LogoFieldButtons>
                                <LogoFieldBtnUpload type="button" onClick={() => setOpenUploadLogoMini(true)}>Carregar Imagem</LogoFieldBtnUpload>
                                {user !== undefined && user.company !== undefined && user.company.logo_mini_url !== undefined && user.company.logo_mini_url !== null && (
                                    <LogoFieldBtnRemove type="button" onClick={() => handleRemoveLogoMini()}>Remover</LogoFieldBtnRemove>
                                )}
                            </LogoFieldButtons>
                        </LogoFieldContent>
                    </LogoField>

                    {openUploadLogoMini && (
                        <UploadImageForm
                            open={openUploadLogoMini}
                            onClose={handleUploadLogoMiniClose}
                            title="Carregue o seu logo"
                            colorEditorBackground="#272727"
                            width={180}
                            height={180}
                            scaleImage={0.5}
                        />
                    )}

                    <LogoField>
                        <LogoFieldContent>
                            <LogoFieldImage style={{ width: '180px', height: '70px', background: '#272727' }} onClick={() => setOpenUploadLogo(true)}>
                                {user !== undefined && user.company !== undefined && user.company.logo_url !== undefined && user.company.logo_url !== null ?
                                    <img src={user.company.logo_url} alt="Imagem de capa" /> :
                                    <BsCardImage />
                                }
                            </LogoFieldImage>
                            <LogoFieldButtons>
                                <LogoFieldBtnUpload type="button" onClick={() => setOpenUploadLogo(true)}>Carregar Imagem</LogoFieldBtnUpload>
                                {user !== undefined && user.company !== undefined && user.company.logo_url !== undefined && user.company.logo_url !== null && (
                                    <LogoFieldBtnRemove type="button" onClick={() => handleRemoveLogo()}>Remover</LogoFieldBtnRemove>
                                )}
                            </LogoFieldButtons>
                        </LogoFieldContent>
                    </LogoField>

                    {openUploadLogo && (
                        <UploadImageForm
                            open={openUploadLogo}
                            onClose={handleUploadLogoClose}
                            title="Carregue o seu logo"
                            colorEditorBackground="#272727"
                            width={180}
                            height={70}
                            scaleImage={0.5}
                        />
                    )}

                </FreemiumFeature>


            </ContainerFields>

        </ContentList>
    );

}

export default CompanyProfile;