import {
    Container,
    ContainerSubTitle,
    ContainerTitle
} from "./style";

import React from "react";

interface emptyStateProps {
    pocket?: boolean;
}

const EmptyData: React.FC<emptyStateProps> = ({ pocket }) => {

    return (
        <Container>
            <ContainerTitle>
                <h1>Não há dados a serem exibidos</h1>
            </ContainerTitle>
            {!pocket && (
                <ContainerSubTitle>
                    <span>Este elemento será preenchido com informações à medida que você continuar trabalhando no <b>Cange</b></span>
                </ContainerSubTitle>
            )}
        </Container>
    );

}

export default EmptyData;