import React, { useCallback } from "react";

import {
    Content,
    ContentBody,
    DialogContainer,
    DialogTop,
    DialogTopLeft,
    DialogTopCenter,
    DialogTopRight,
    DialogFooter,
    DialogFooterCenter,
    BtnInsertNewAnswer,
    Description,
    DependenciesList
} from "./style";

import { AiOutlineClose } from "react-icons/ai";
import List from "../../components/List";
import ListItem from "../../components/List/ListItem";
import ListColumn from "../../components/List/ListColumn";
import ListText from "../../components/List/ListText";

export interface DependencyErrorApi {
    name: string,
    type: string,
    id: number,
    object: 'register' | 'flow'
}

interface DependencyErrorProps {
    open: boolean;
    title: string;
    description: string;
    icon: React.ReactNode;
    dependencies: DependencyErrorApi[];
    onClose: () => void;
}

const DependencyError: React.FC<DependencyErrorProps> = ({ onClose, open, dependencies, title, description, icon }) => {

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    return (
        <DialogContainer
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
        >
            <DialogTop>
                <DialogTopLeft>
                    {icon}
                    <h1>
                        {title}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>
            <Content>
                <ContentBody container>

                    <Description>{description}</Description>

                    <DependenciesList>

                        <List style={{ width: '100%' }}>
                            <ListItem>
                                <ListColumn flex flexGrow={5}>
                                    <ListText header>
                                        Objeto
                                    </ListText>
                                </ListColumn>
                                <ListColumn flex flexGrow={2}>
                                    <ListText header>
                                        Perfil
                                    </ListText>
                                </ListColumn>
                                <ListColumn>
                                    <ListText header>
                                        Tipo
                                    </ListText>
                                </ListColumn>
                            </ListItem>

                            {dependencies.map((dep, index) => (
                                <ListItem key={index}>
                                    <ListColumn flex flexGrow={5} aglinItems={'flex-start'}>
                                        <ListText fontWeight="500" fontSize="15px">
                                            {dep.name}
                                        </ListText>
                                    </ListColumn>
                                    <ListColumn flex flexGrow={2}>
                                        <ListText color="red">
                                            {dep.type === 'A' ? 'Administrador' : dep.type === 'M' ? 'Membro' : dep.type === 'I' ? 'Membro individual' : dep.type === 'S' ? 'Solicitante' : dep.type === 'R' ? 'Visão restrita' : 'Visualizador'}
                                        </ListText>
                                    </ListColumn>
                                    <ListColumn>
                                        <ListText color="gray">
                                            {dep.object === 'register' ? 'Registro' : 'Fluxo'}
                                        </ListText>
                                    </ListColumn>
                                </ListItem>
                            ))}
                        </List>
                    </DependenciesList>

                </ContentBody>
            </Content>
            <DialogFooter>
                <DialogFooterCenter>
                    <BtnInsertNewAnswer color={"gray"} style={{ width: '130px', height: '36px' }} onClick={() => onClose()}>
                        Fechar
                    </BtnInsertNewAnswer>
                </DialogFooterCenter>
            </DialogFooter>
        </DialogContainer >
    );

}

export default DependencyError;