import React, { useEffect, useRef, useState } from "react";

import {
    Container
} from "./style";
import { useField } from "@unform/core";

interface IdProps {
    name: string;
}

const Id: React.FC<IdProps> = ({ name }) => {

    const inputRef = useRef<HTMLInputElement>(null);

    const { fieldName, defaultValue, registerField } = useField(name);
    const [valueText, setValueText] = useState<string>(defaultValue !== undefined ? defaultValue : "#");

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: () => {
                return "#";
            },
            setValue: (refs, value) => { //Não remover o ref
                if (value !== null) {
                    setValueText(value);
                } else {
                    setValueText("");
                }
            },
            clearValue: () => {
                setValueText("");
            }
        });

    }, [fieldName, registerField, valueText]);

    return (
        <Container>
            <input
                value={valueText}
                defaultValue={defaultValue}
                ref={inputRef}
                name={name}
                disabled={true}
            />
        </Container>
    );

}

export default Id;