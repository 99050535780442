import React, { useEffect, useState } from "react";

import HistoryItem from "./HistoryItem";
import { CardComment } from "../../../interfaces/CardComment";
import {
    Container, 
    NoCommentData
} from "./style";
import getAccessControl from "../../../middlewares/AccessControl";

interface HistoryProps {
    cardComments: CardComment[];
    typeUser: string;
    onDeleteComment: (id_card_comment?: number) => void;
    onFixComment: (cardComment?: CardComment) => void;
}

const History: React.FC<HistoryProps> = ({ cardComments, typeUser, onFixComment, onDeleteComment }) => {

    const [comments, setComments] = useState<CardComment[]>();

    useEffect(() => {

        setComments(cardComments);

    }, [cardComments]);

    return (
        <Container>
            {comments?.map((comment) => {
                return (
                    <HistoryItem
                        key={comment.id_card_comment}
                        id={0}
                        cardComment={comment}
                        onDeleteComment={onDeleteComment}
                        onFixComment={onFixComment}
                        typeUser={typeUser}
                    />
                )
            })}

            {(comments === undefined || comments.length === 0) && !getAccessControl(44, typeUser) ?
                <NoCommentData>
                    Sem comentários para serem exibidos
                </NoCommentData> :
                <></>
            }

        </Container>
    );

}

export default History;