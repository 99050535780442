import React, { useEffect } from "react";

import { FormAnswerField } from "../../../../../../interfaces/FormAnswerField";

import { Container, StepItem, StepItemInside, StepItemInsideEnd, StepItemInsideStart } from "./style";
import { FaCheck, FaFlagCheckered, FaRegClock } from "react-icons/fa";

interface ComboBoxFlowStepViewProps {
    value: FormAnswerField[];
    colorBar?: string;
}

const ComboBoxFlowStepView: React.FC<ComboBoxFlowStepViewProps> = ({ value, colorBar }) => {

    const [valueBase, setValueBase] = React.useState<FormAnswerField>();
    const [array, setArray] = React.useState<number[]>([]);

    useEffect(() => {

        //Get the valueBase
        if (value !== undefined && value.length > 0) {

            if (value[0].relatedOriginStepsTotal !== undefined) {
                let array = [];
                for (let index = 0; index < value[0].relatedOriginStepsTotal; index++) {
                    array.push(index);
                }
                setArray(array);
            }

            setValueBase(value[0]);
        }

    }, [value]);

    return (
        <>
            <Container>
                <StepItem key={-1} style={{ border: 'none', width: '30px', marginRight: '1px' }}>
                    <StepItemInsideStart>
                        <FaFlagCheckered />
                    </StepItemInsideStart>
                </StepItem>

                {valueBase !== undefined && array.length > 0 && array.map((item) => {
                    return (
                        <StepItem key={item} style={(valueBase.relatedOriginStepsCurrent !== undefined && valueBase.relatedOriginStepsCurrent > item) || (valueBase.relatedOriginStepsCurrent === item && valueBase.relatedOriginIsEnded === "S") ? { borderColor: 'white', borderTop: 'none', borderBottom: 'none' } : {}}>
                            <StepItemInside
                                color={valueBase.relatedOriginColor !== undefined ? valueBase.relatedOriginColor : undefined}
                                isEnded={(valueBase.relatedOriginStepsCurrent !== undefined && valueBase.relatedOriginStepsCurrent > item) || (valueBase.relatedOriginStepsCurrent === item && valueBase.relatedOriginIsEnded === "S") ? true : false}
                                style={
                                    valueBase.relatedOriginStepsCurrent !== undefined && valueBase.relatedOriginStepsCurrent === item && valueBase.relatedOriginIsEnded !== "S" ?
                                        { width: '130px', display: 'flex', justifyContent: 'center', alignItems: 'center' } :
                                        {}
                                }
                            >
                                {valueBase.relatedOriginStepsCurrent !== undefined && valueBase.relatedOriginStepsCurrent === item && valueBase.relatedOriginIsEnded !== "S" ?
                                    <>{valueBase.relatedOriginStepsCurrentName}</> :
                                    <></>}
                            </StepItemInside>
                        </StepItem>
                    )
                })}

                <StepItem key={-2} style={{ border: 'none', width: '30px', marginLeft: '1px' }}>
                    <StepItemInsideEnd color={valueBase !== undefined && valueBase.relatedOriginIsEnded === "S" ? "#61bd4f" : undefined}>
                        {valueBase !== undefined && valueBase.relatedOriginIsEnded === "S" ?
                            <FaCheck /> :
                            <FaRegClock />
                        }
                    </StepItemInsideEnd>
                </StepItem>
            </Container>
        </>
    );

}

export default ComboBoxFlowStepView;