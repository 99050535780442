import React, { useCallback } from "react";

import {
    DialogFooter,
    Content,
    ButtonCancel,
    ButtonConfirm,
    ContentBody,
    DialogMain,
    Container,
    ContainerImage,
    ContainerColor,
    ContainerTitle,
    ContainerActions,
    ContainerDisclaimer,
} from "./styles";

import imgChecked from '../../assets/checked.png';
import imgError from '../../assets/error.png';
import imgWarning from '../../assets/warning.png';

interface DialogActionProps {
    open: boolean;
    type: number; //1 - Warning / 2 - Sucess / 3 - Error
    message: string;
    actions_items?: string[];
    isLoading?: boolean;
    disclaimer?: string;
    onSubmmit: () => Promise<void>;
    onClose: () => void;
}

const DialogAction: React.FC<DialogActionProps> = ({ open, onSubmmit, onClose, type, message, actions_items, disclaimer, isLoading }) => {

    const btnConfirmColor = type === 1 ? "#e24d4b" : type === 2 ? "#34ba7c" : type === 4 ? "#ff8c2f" : "#e24d4b";

    const handleClose = useCallback(async () => {
        if (!isLoading) {
            onClose();
        }
    }, [onClose, isLoading]);

    const handleSubmmit = useCallback(async () => {

        await onSubmmit();

        onClose();

    }, [onSubmmit, onClose]);

    return (

        <DialogMain
            fullWidth={false}
            maxWidth="lg"
            open={open}
            onClose={handleClose}
        >
            <Content>
                <ContentBody container>

                    <ContainerColor type={type}>

                    </ContainerColor>

                    <Container>

                        <ContainerImage>
                            {type === 1 ?
                                <img src={imgWarning} alt="Imagem de Aviso" /> :
                                type === 2 ?
                                    <img src={imgChecked} alt="Imagem de Sucesso" /> :
                                    type === 3 ?
                                        <img src={imgError} alt="Imagem de Erro" /> :
                                        <></>
                            }
                        </ContainerImage>

                        <ContainerTitle>
                            {message}
                        </ContainerTitle>

                        {actions_items !== undefined && actions_items.length > 0 && (
                            <ContainerActions>
                                <span>Esta ação implicará na:</span>
                                <ul>
                                    {actions_items?.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </ContainerActions>
                        )}

                        {disclaimer !== undefined && (
                            <ContainerDisclaimer>
                                {disclaimer}
                            </ContainerDisclaimer>
                        )}

                    </Container>

                </ContentBody>
            </Content>

            <DialogFooter>
                <ButtonConfirm type="button" form="configForm" color={btnConfirmColor} onClick={() => handleSubmmit()} isLoading={isLoading}>Confirmar</ButtonConfirm>
                <ButtonCancel type="button" color="#f4f4f4" onClick={() => handleClose()}>Cancelar</ButtonCancel>
            </DialogFooter>
        </DialogMain>

    );
};

export default DialogAction;
