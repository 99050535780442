import React, { useEffect, useState } from "react";

import { DataProps } from "../..";
import EmptyData from "../../../EmptyState/EmptyData";
import { ResponsivePie } from "@nivo/pie";
import {
    TotalNumber
} from "./styles";
import { colorsElement } from "../../getColorsElement";
import getChartFormatValue from "../../getChartFormatValue";
import getPercentualOfTotal from "../../getPercentualOfTotal";

interface PieChartProps {
    dataElement?: DataProps[];
    type?: 'pie' | 'donut' | 'quarter';
    show_time?: string;
    axis_y_field_id?: number;
    axis_x_field_id?: number;
    axis_y_field_type?: string;
    axis_x_field_type?: string;
    axis_y_field_variation?: string;
}

const dataDefault = [
    {
        id: "Item 1",
        label: "Item 1",
        value: 80,
    },
    {
        id: "Item 2",
        label: "Item 2",
        value: 110,
    },
    {
        id: "Item 3",
        label: "Item 3",
        value: 20,
    },
    {
        id: "Item 4",
        label: "Item 4",
        value: 150,
    },
    {
        id: "Item 5",
        label: "Item 5",
        value: 12,
    }
];

const PieChart: React.FC<PieChartProps> = ({ type, dataElement, axis_y_field_id, show_time, axis_y_field_type, axis_y_field_variation }) => {

    const [total, setTotal] = useState<number>(0);
    const [totalLabel, setTotalLabel] = useState<string>("");

    useEffect(() => {

        let newTotal = 0;

        if (dataElement !== undefined && dataElement !== null && dataElement.length > 0) {
            dataElement.map((v) => {
                if (v.value !== undefined) {
                    newTotal = newTotal + v.value;
                }
                return v;
            })

            if (axis_y_field_id === -2) {
                setTotal(Math.round(newTotal * 10) / 10);
            } else {
                setTotal(newTotal);
            }

            if (show_time !== undefined && axis_y_field_id === -2) {
                if (show_time === "day") {
                    setTotalLabel(" Dias");
                } else if (show_time === "hour") {
                    setTotalLabel(" Horas");
                } else if (show_time === "min") {
                    setTotalLabel(" Minutos");
                } else if (show_time === "sec") {
                    setTotalLabel(" Segundos");
                }
            } else {
                setTotalLabel("");
            }

        }

    }, [dataElement, axis_y_field_id, show_time]);

    return (
        <>
            {dataElement !== undefined && dataElement?.length > 0 ?
                <>
                    {type === 'donut' && (
                        <TotalNumber>
                            {getChartFormatValue(total, axis_y_field_type, 2, axis_y_field_variation) + totalLabel}
                        </TotalNumber>
                    )}
                    <ResponsivePie
                        data={dataElement !== undefined ? dataElement : dataDefault}
                        //Static Down
                        margin={
                            type === 'quarter' ?
                                { top: 30, right: -80, bottom: 15, left: 30 } :
                                { top: 30, right: 130, bottom: 30, left: 130 }}
                        innerRadius={type === 'pie' ? 0 : type === 'quarter' ? 0.55 : 0.60}
                        startAngle={type === 'quarter' ? 270 : 0}
                        fit={true}
                        padAngle={type === 'quarter' ? 1 : 0}
                        cornerRadius={type === 'quarter' ? 10 : 0}
                        borderWidth={type === 'quarter' ? 5 : 0}
                        borderColor={{
                            from: "color",
                            modifiers: [["brighter", 10]]
                        }}
                        theme={{
                            fontSize: 12,
                            fontFamily: "Roboto"

                        }}
                        tooltip={({ datum: { id, value } }) => (
                            <div
                                style={{
                                    padding: 12,
                                    borderRadius: 5,
                                    fontSize: 13,
                                    background: '#222222',
                                    color: '#ffffff'
                                }}
                            >
                                {id} ({getChartFormatValue(value, axis_y_field_type, 2, axis_y_field_variation) + totalLabel}) <strong>{getPercentualOfTotal(value, total)}</strong>

                            </div>
                        )}
                        valueFormat={value => getPercentualOfTotal(value, total)}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabel={e => e.id + " (" + getChartFormatValue(e.value, axis_y_field_type, 2, axis_y_field_variation) + ")"}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: "color" }}
                        arcLabelsRadiusOffset={type === 'pie' ? 0.70 : 0.5}
                        arcLinkLabelsDiagonalLength={type === 'quarter' ? 7 : 10}
                        arcLabelsSkipAngle={10}
                        colors={colorsElement}
                        arcLabelsTextColor={{
                            from: "color",
                            modifiers: [["brighter", 10]]
                        }}
                    />
                </> :
                <EmptyData />
            }
        </>
    );

}

export default PieChart;