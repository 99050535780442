import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

import LoaderUseTemplate from '../../Loader/LoaderUseTemplate';
import TemplateCentral from '../../TemplateCentral';
import api from '../../../services/api';
import {
    Container
} from './styles';
import { Flow } from '../../../interfaces/Flow';
import { Onboarding } from '../../../interfaces/Onboarding';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { TEMPLATE_BASE_ID } from '../../../config/app';

interface RouteParams {
    hash?: string;
}

const TemplateData: React.FC = () => {

    const { addToast } = useToast();
    const history = useHistory();
    const { signIn } = useAuth();
    const { params } = useRouteMatch<RouteParams>();
    const [onboarding, setOnboarding] = useState<Onboarding>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleUseTemplate = useCallback(async (template_id: number) => {

        let sucess: boolean = false;
        let onboardingApi: Onboarding | undefined = onboarding;
        let flowHash = "";

        if (onboarding !== undefined && template_id > 0) {

            const password: string = onboarding.password !== undefined ? onboarding.password : "";

            if (password !== "") {

                setLoading(true);

                await api
                    .post('/onboarding/', {
                        status: "E",
                        email: onboarding.email,
                        hash: params.hash,
                        template_id: template_id
                    })
                    .then(response => {

                        sucess = true;

                        onboardingApi = response.data;

                        api.post('/template/use-count', {
                            id_template: template_id
                        })

                    }).catch(error => {
                        console.log(error);
                        addToast({
                            type: 'error',
                            title: 'Erro ao salvar os dados!',
                            description: 'Ocorreu um erro ao tentar salvar os dados na base!',
                        });
                    });

            } else {
                history.push('/onboarding-step-2/' + onboarding.hash);
                addToast({
                    type: 'error',
                    title: 'Erro ao salvar os dados!',
                    description: 'Encontramos inconsistência nos seus dados, cadastre sua senha de novo',
                });
            }

            if (sucess && onboardingApi !== undefined) {

                await api
                    .post('/template/use-template', {
                        id_template: template_id,
                        id_user: onboardingApi.user_id,
                        company_id: onboardingApi.company_id
                    })
                    .then(response => {

                        const flowApi: Flow = response.data;

                        flowHash = flowApi.hash;

                    }).catch(error => {
                        console.log(error);
                        history.push('/dashboard');
                        addToast({
                            type: 'error',
                            title: 'Erro ao criar template!',
                            description: 'Ocorreu um erro ao tentar criar o template na base de dados!',
                        });
                    });

                const user = await signIn({
                    email: onboarding.email,
                    password: password,
                    isHashPassword: true,
                    hashOnboarding: onboarding.hash,
                    isOnboarding: true
                });

                if (user !== undefined && !user.isStartedTrial) {
                    if (flowHash !== "") {
                        history.push('/flow/' + flowHash + "/onboarding?action=start-trial");
                    } else {
                        history.push('/dashboard?action=start-trial');
                    }
                } else {
                    if (flowHash !== "") {
                        history.push('/flow/' + flowHash + "/onboarding");
                    } else {
                        history.push('/my-dashboard');
                    }
                }


            }

        }

    }, [addToast, history, onboarding, params.hash, signIn]);

    const handleFromScratch = useCallback(async () => {

        let sucess: boolean = false;
        let onboardingApi: Onboarding | undefined = onboarding;
        let flowHash = "";

        if (onboarding !== undefined) {

            const password: string = onboarding.password !== undefined ? onboarding.password : "";

            if (password !== "") {

                setLoading(true);

                await api
                    .post('/onboarding/', {
                        status: "E",
                        email: onboarding.email,
                        hash: params.hash,
                        template_id: TEMPLATE_BASE_ID
                    })
                    .then(response => {

                        sucess = true;

                        onboardingApi = response.data;

                        api.post('/template/use-count', {
                            id_template: TEMPLATE_BASE_ID
                        })

                    }).catch(error => {
                        console.log(error);
                        addToast({
                            type: 'error',
                            title: 'Erro ao salvar os dados!',
                            description: 'Ocorreu um erro ao tentar salvar os dados na base!',
                        });
                    });

            } else {
                history.push('/onboarding-step-2/' + onboarding.hash);
                addToast({
                    type: 'error',
                    title: 'Erro ao salvar os dados!',
                    description: 'Encontramos inconsistência nos seus dados, cadastre sua senha de novo',
                });
            }

            if (sucess && onboardingApi !== undefined) {

                await api
                    .post('/template/use-template', {
                        id_template: TEMPLATE_BASE_ID,
                        id_user: onboardingApi.user_id,
                        name_flow: 'Novo Fluxo',
                        isTestMode: 'false',
                        company_id: onboardingApi.company_id
                    })
                    .then(response => {

                        const flowApi: Flow = response.data;

                        flowHash = flowApi.hash;

                    }).catch(error => {
                        console.log(error);
                        history.push('/dashboard');
                        addToast({
                            type: 'error',
                            title: 'Erro ao criar template!',
                            description: 'Ocorreu um erro ao tentar criar o template na base de dados!',
                        });
                    });

                const user = await signIn({
                    email: onboarding.email,
                    password: password,
                    isHashPassword: true,
                    hashOnboarding: onboarding.hash,
                    isOnboarding: true
                });

                if (user !== undefined && !user.isStartedTrial) {
                    if (flowHash !== "") {
                        history.push('/flow/' + flowHash + "/onboarding-scratch?action=start-trial");
                    } else {
                        history.push('/dashboard?action=start-trial');
                    }
                } else {
                    if (flowHash !== "") {
                        history.push('/flow/' + flowHash);
                    } else {
                        history.push('/my-dashboard');
                    }
                }


            }

        }

    }, [addToast, history, onboarding, params.hash, signIn]);

    useEffect(() => {

        api.get(`/onboarding`, {
            params: {
                hash: params.hash
            }
        }).then(response => {

            const apiOnboarding: Onboarding = response.data;

            setOnboarding(apiOnboarding);

            if (apiOnboarding.status === "E") { //Se já está finalizado o onboarding
                history.push('/');
                addToast({
                    type: 'error',
                    title: 'Este onboarding já foi finalizado!',
                    description: 'Este onboarding já foi encerrado, inicie um novo ou faça o login!',
                });
            }

        }).catch(error => {
            history.push('/onboarding/');
            addToast({
                type: 'error',
                title: 'Não foi encontrado o seu onboarding',
                description: 'Não foi possível localizar o seu onboarding!',
            });

        });

    }, [params.hash, addToast, history]);

    return (
        <>
            <title>Onboarding | Cange</title>
            {loading ? <LoaderUseTemplate /> :
                <Container>
                    <TemplateCentral
                        onboarding={onboarding}
                        handleTemplate={handleUseTemplate}
                        handleFromScratch={handleFromScratch}
                        typeUser="A"
                        isOnboarding={true}
                    />
                </Container>
            }
        </>
    );
}

export default TemplateData;
