import React, { useState } from "react";
import { BsGearFill } from "react-icons/bs";
import LayoutContainer from '../../components/LayoutContainer';
import ListContainer from '../../components/ListContainer';
import {
    BoxHeader,
    BoxMenuContainer,
    BoxMenuItem,
    BoxMenuItemText,
    Container,
    ContainerBox,
    ContainerCenter,
    ContainerLeft,
    Content,
    NewButton
} from "./style";
import { useAuth } from "../../hooks/auth";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import CompanyProfile from "./CompanyProfile";
import PageHeader from "../../components/PageHeader";
import InviteUser from "../../dialogs/InviteUser";
import { FaPlus, FaShieldAlt } from "react-icons/fa";
import ControlAcess from "./ControlAcess";

interface CompanyConfigsProps {

}

const CompanyConfigs: React.FC<CompanyConfigsProps> = () => {

    const { user } = useAuth();
    const [navBarSelected, setNavBarSelected] = useState(1);
    const [openInviteUser, setOpenInviteUser] = useState(false);

    return (
        <LayoutContainer>
            <title>{"Painel do Administrador"} | Cange</title>
            <ListContainer>

                {/* Header Page */}
                <PageHeader
                    title={"Painel do Administrador"}
                    icon={HiOutlineOfficeBuilding}
                    iconDynamicColor={"gray"}
                />

                {openInviteUser ?
                    <InviteUser
                        key="invite-user-dashboard"
                        open={openInviteUser}
                        onClose={() => setOpenInviteUser(false)}
                        typeUser={user.type}
                        fullScreen={true}
                        title="Convide membros para colaborar"
                    /> :
                    <></>
                }

                {/* Inputs Form */}
                <Container>
                    <Content container>
                        <ContainerLeft>
                            <ContainerBox style={{ marginRight: '10px', padding: '10px', position: 'relative' }}>

                                <BoxHeader>
                                    {user.company?.name}
                                </BoxHeader>

                                <NewButton onClick={() => setOpenInviteUser(!openInviteUser)}>
                                    <FaPlus />
                                    Convidar Pessoas
                                </NewButton>

                                <BoxMenuContainer>

                                    <BoxMenuItem onClick={() => setNavBarSelected(1)} isSelect={navBarSelected === 1}>
                                        <BsGearFill className="box-menu-item-icon" />
                                        <BoxMenuItemText className="box-menu-item-text">
                                            Geral
                                        </BoxMenuItemText>
                                    </BoxMenuItem>

                                    <BoxMenuItem onClick={() => setNavBarSelected(2)} isSelect={navBarSelected === 2}>
                                        <FaShieldAlt className="box-menu-item-icon" />
                                        <BoxMenuItemText className="box-menu-item-text">
                                            Controle de Acesso
                                        </BoxMenuItemText>
                                    </BoxMenuItem>

                                </BoxMenuContainer>
                            </ContainerBox>
                        </ContainerLeft>
                        <ContainerCenter>

                            {navBarSelected === 1 ?
                                <CompanyProfile /> :
                                navBarSelected === 2 ?
                                    <ControlAcess /> :
                                    <></>
                            }

                        </ContainerCenter>

                    </Content>
                </Container>

            </ListContainer>
        </LayoutContainer>

    );

}

export default CompanyConfigs;