import styled from "styled-components";

export const Container = styled.div`    
    overflow: auto;
    margin-right: 5px;
`;

export const MainList = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: auto;    

    border-radius: 5px;
`;
