import React, { useCallback, useEffect, useState } from "react";

import {
    ButtonLink,
    Container,
    FilterBarLeft,
    FilterBarRight,
    BoxContainer,
    BoxItemsContainer,
    BoxItem,
    BoxMultiple,
    BoxAvatar,
    BoxSpan,
    NoResults,
    BoxButtonsContainer,
    BtnMarkAll,
    BtnMarkNone,
    ContainerDate,
    DatePickerInput,
    LabelDate,
    BtnMarkDate,
    BoxButtonsTopContainer,
} from "./style";
import { MdCalendarMonth, MdOutlineFilterList } from "react-icons/md";
import { BsDownload, BsGearFill, BsToggleOff, BsToggleOn } from "react-icons/bs";
import { Flow } from "../../../../interfaces/Flow";
import { ClickAwayListener } from "@material-ui/core";
import AvatarCange from "../../../../components/AvatarCange";
import { User } from "../../../../interfaces/User";
import { format } from "date-fns";

interface TimeSheetFilterBarProps {
    flowsBase: Flow[];
    usersBase: User[];
    startDate: Date;
    endDate: Date;
    selectedFlows: number[];
    selectedUsers: number[];
    optArchived: boolean;
    setOptArchived: React.Dispatch<React.SetStateAction<boolean>>;
    setStartDate: React.Dispatch<React.SetStateAction<Date>>;
    setEndDate: React.Dispatch<React.SetStateAction<Date>>;
    setSelectedFlows: React.Dispatch<React.SetStateAction<number[]>>;
    setSelectedUsers: React.Dispatch<React.SetStateAction<number[]>>;
    forceUpdate: () => void;
    downloadCSV: () => void;
}

interface UpdateControlProps {
    selectedFlows: number[];
    selectedUsers: number[];
    startDate: Date;
    endDate: Date;
}

interface UserFilter extends User {
    selected?: boolean;
}

interface FlowFilter extends Flow {
    selected?: boolean;
}

//Function to get the first day of the month
const getFirstDay = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
};

//Function to get the last day of the month
const getLastDay = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

//Function to get today
const getToday = () => {
    return new Date();
};

//Function to get the first day of the week
const getFirstDayWeek = (date: Date) => {
    const firstDay = date.getDate() - date.getDay();
    return new Date(date.setDate(firstDay));
};

//Function to get the last day of the week
const getLastDayWeek = (date: Date) => {
    const lastDay = date.getDate() - date.getDay() + 6;
    return new Date(date.setDate(lastDay));
};

const TimeSheetFilterBar: React.FC<TimeSheetFilterBarProps> = ({ flowsBase, usersBase, startDate, endDate, selectedUsers, selectedFlows, optArchived, setOptArchived, setSelectedFlows, setSelectedUsers, setStartDate, setEndDate, forceUpdate, downloadCSV }) => {

    const [firstRender, setFirstRender] = useState<boolean>(true);
    const [updateControl, setUpdateControl] = useState<UpdateControlProps>({
        selectedFlows: selectedFlows,
        selectedUsers: selectedUsers,
        startDate: startDate,
        endDate: endDate
    });

    const [openFilterDate, setOpenFilterDate] = useState(false);
    const [filterStateMessageDate, setFilterStateMessageDate] = useState<string>();

    const [openFilterFlows, setOpenFilterFlows] = useState(false);
    const [conditionsFlows, setConditionsFlows] = useState<number[]>([]);
    const [filterStateMessageFlows, setFilterStateMessageFlows] = useState<string>();
    const [flows, setFlows] = useState<FlowFilter[]>([]);

    const [openFilterUsers, setOpenFilterUsers] = useState(false);
    const [conditionsUsers, setConditionsUsers] = useState<number[]>([]);
    const [filterStateMessageUsers, setFilterStateMessageUsers] = useState<string>();
    const [users, setUsers] = useState<UserFilter[]>([]);

    const [openFilterOptions, setOpenFilterOptions] = useState(false);

    //Create a function to compare two array<number> and return true if are equals
    const compareArray = (arr1: number[], arr2: number[]) => {
        return arr1.length === arr2.length && arr1.sort().every(function (value, index) { return value === arr2.sort()[index] });
    };

    const handleClickAway = useCallback(() => {
        setOpenFilterFlows(false);
        setOpenFilterUsers(false);
        setOpenFilterDate(false);
        setOpenFilterOptions(false);

        //Compare the updateControl with the current state, if is different, then forceUpdate
        let isChanged = false;

        if (updateControl !== undefined && (
            compareArray(updateControl.selectedFlows, selectedFlows) ||
            compareArray(updateControl.selectedUsers, selectedUsers) ||
            updateControl.startDate !== startDate ||
            updateControl.endDate !== endDate
        )) {
            isChanged = true;
        }


        if (updateControl === undefined || isChanged) {
            forceUpdate();

            setUpdateControl({
                selectedFlows: selectedFlows,
                selectedUsers: selectedUsers,
                startDate: startDate,
                endDate: endDate
            });
        }

    }, [forceUpdate, startDate, endDate, selectedFlows, selectedUsers, updateControl]);

    const handleMarkDateToday = useCallback(() => {

        setStartDate(getToday());
        setEndDate(getToday());

    }, [setStartDate, setEndDate]);

    const handleMarkDateThisWeek = useCallback(() => {

        setStartDate(getFirstDayWeek(getToday()));
        setEndDate(getLastDayWeek(getToday()));

    }, [setStartDate, setEndDate]);

    const handleMarkDateThisMonth = useCallback(() => {

        setStartDate(getFirstDay(getToday()));
        setEndDate(getLastDay(getToday()));

    }, [setStartDate, setEndDate]);

    const handleMarkAllFlows = useCallback(() => {

        let newFlows: FlowFilter[] = flowsBase;

        if (flowsBase !== undefined && flowsBase.length > 0) {
            newFlows = newFlows.map((flow) => {
                flow.selected = true;
                return flow;
            });

            setFlows(newFlows);
            setSelectedFlows(newFlows.map((flow) => flow.id_flow));
            setConditionsFlows(newFlows.map((flow) => flow.id_flow));
        }

    }, [flowsBase, setSelectedFlows]);

    const handleMarkNoneFlows = useCallback(() => {

        let newFlows: FlowFilter[] = flowsBase;

        if (flowsBase !== undefined && flowsBase.length > 0) {
            newFlows = newFlows.map((flow) => {
                flow.selected = false;
                return flow;
            });

            setFlows(newFlows);
            setSelectedFlows([]);
            setConditionsFlows([]);
        }

    }, [flowsBase, setSelectedFlows]);

    const handleMarkAllUsers = useCallback(() => {

        let newUsers: UserFilter[] = usersBase;

        if (usersBase !== undefined && usersBase.length > 0) {
            newUsers = newUsers.map((user) => {
                user.selected = true;
                return user;
            });

            setUsers(newUsers);
            setSelectedUsers(newUsers.map((user) => user.id_user));
            setConditionsUsers(newUsers.map((user) => user.id_user));
        }

    }, [usersBase, setSelectedUsers]);

    const handleMarkNoneUsers = useCallback(() => {

        let newUsers: UserFilter[] = usersBase;

        if (usersBase !== undefined && usersBase.length > 0) {
            newUsers = newUsers.map((user) => {
                user.selected = false;
                return user;
            });

            setUsers(newUsers);
            setSelectedUsers([]);
            setConditionsUsers([]);
        }

    }, [usersBase, setSelectedUsers]);

    const handleSelectFlow = useCallback((flow: FlowFilter) => {

        const newCondition: number[] = [];

        const newFlows = flows?.map((opt) => {
            if (opt.id_flow === flow.id_flow) {
                if (opt.selected === undefined) {
                    opt.selected = true;
                    newCondition.push(opt.id_flow);
                } else if (opt.selected === false) {
                    opt.selected = true;
                    newCondition.push(opt.id_flow);
                } else {
                    opt.selected = false;
                }
            } else if (opt.selected === true) {
                newCondition.push(opt.id_flow);
            }
            return opt;
        })

        //Update the Options, set selected or no
        setFlows(newFlows);
        setSelectedFlows(newCondition);
        setConditionsFlows(newCondition);

    }, [flows, setSelectedFlows]);

    const handleSelectUser = useCallback((user: UserFilter) => {

        const newCondition: number[] = [];

        //Update the Options, set selected or no
        setUsers(users?.map((opt) => {
            if (opt.id_user === user.id_user) {
                if (opt.selected === undefined) {
                    opt.selected = true;
                    newCondition.push(opt.id_user);
                } else if (opt.selected === false) {
                    opt.selected = true;
                    newCondition.push(opt.id_user);
                } else {
                    opt.selected = false;
                }
            } else if (opt.selected) {
                newCondition.push(opt.id_user);
            }
            return opt;
        }));

        setConditionsUsers(newCondition);
        setSelectedUsers(newCondition);

    }, [users, setSelectedUsers]);

    useEffect(() => {

        let newFlows: FlowFilter[] = flowsBase;
        setFlows(newFlows);

    }, [flowsBase]);

    useEffect(() => {

        let newUsers: UserFilter[] = usersBase;
        setUsers(newUsers);

    }, [usersBase]);

    useEffect(() => {

        let messageUsers: string = "";
        let messageFlows: string = "";
        let messageDate: string = "";

        if (conditionsUsers.length > 0) {
            messageUsers += "Horas do "

            const nameFlowOne = users.filter((us) => us.id_user === conditionsUsers[0]);
            if (nameFlowOne.length > 0) {
                messageUsers += nameFlowOne[0].name;

                if (conditionsUsers.length === 2) {
                    messageUsers += " e mais " + (conditionsUsers.length - 1) + " usuário";
                } else if (conditionsUsers.length > 2) {
                    messageUsers += " e mais " + (conditionsUsers.length - 1) + " usuários";
                }
            }

        }

        if (conditionsFlows.length > 0) {
            messageFlows += "Horas do "

            const nameFlowOne = flows.filter((fl) => fl.id_flow === conditionsFlows[0]);
            if (nameFlowOne.length > 0) {
                messageFlows += nameFlowOne[0].name;

                if (conditionsFlows.length === 2) {
                    messageFlows += " e mais " + (conditionsFlows.length - 1) + " fluxo";
                } else if (conditionsFlows.length > 2) {
                    messageFlows += " e mais " + (conditionsFlows.length - 1) + " fluxos";
                }
            }

        }

        //Check the start date and end date, valid first if both are the same = today, then if both are the same = this week, then if both are the same = this month
        if (startDate !== undefined && startDate !== null && endDate !== undefined && endDate !== null) {

            //Get the date base after compare to check if is today, this week or this month
            const dateBase = new Date();
            dateBase.setHours(0, 0, 0, 0);

            const startDateCompare = new Date(startDate);
            startDateCompare.setHours(0, 0, 0, 0);

            const endDateCompare = new Date(endDate);
            endDateCompare.setHours(0, 0, 0, 0);

            //Check if is today
            if (startDateCompare.getTime() === dateBase.getTime() && endDateCompare.getTime() === dateBase.getTime()) { //Check if is today
                messageDate = "Hoje";
            } else if (startDateCompare.getTime() === getFirstDayWeek(dateBase).getTime() && endDateCompare.getTime() === getLastDayWeek(dateBase).getTime()) { //Check if is this week
                messageDate = "Esta Semana";
            } else if (startDateCompare.getTime() === getFirstDay(dateBase).getTime() && endDateCompare.getTime() === getLastDay(dateBase).getTime()) { //
                messageDate = "Este Mês";
            } else if (messageDate === "") { //Check if is a custom date
                messageDate = "Período de " + format(startDate, 'dd/MM/yy') + " até " + format(endDate, 'dd/MM/yy');
            } else {
                messageDate = "Período";
            }

        }

        setFilterStateMessageUsers(messageUsers);
        setFilterStateMessageFlows(messageFlows);
        setFilterStateMessageDate(messageDate);

    }, [conditionsFlows, conditionsUsers, flows, users, startDate, endDate]);

    useEffect(() => {

        if (firstRender) {
            setFirstRender(false);
            forceUpdate();
        }

    }, [firstRender, forceUpdate]);

    return (
        <Container>
            <FilterBarLeft>

                <ButtonLink type="button"
                    onClick={() => {
                        setOpenFilterDate(!openFilterDate);
                        setOpenFilterFlows(false);
                        setOpenFilterUsers(false);
                        setOpenFilterOptions(false);
                    }}
                    active={filterStateMessageDate !== undefined &&
                        filterStateMessageDate !== "Período" &&
                        filterStateMessageDate !== "Esta Semana"
                    }
                >
                    <MdCalendarMonth style={{ marginTop: '0px', marginBottom: '2px' }} />
                    <div>{filterStateMessageDate !== undefined ? filterStateMessageDate : "Período"}</div>
                </ButtonLink>
                {openFilterDate ? (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <BoxContainer style={{ top: '40px', left: '16px' }}>
                            <BoxButtonsTopContainer>
                                <BtnMarkDate onClick={() => handleMarkDateToday()}>Hoje</BtnMarkDate>
                                <BtnMarkDate onClick={() => handleMarkDateThisWeek()}>Esta Semana</BtnMarkDate>
                                <BtnMarkDate onClick={() => handleMarkDateThisMonth()}>Este Mês</BtnMarkDate>
                            </BoxButtonsTopContainer>
                            <BoxItemsContainer style={{ padding: '5px' }}>

                                <LabelDate>Data Inicial</LabelDate>
                                <ContainerDate>
                                    <DatePickerInput
                                        selected={startDate}
                                        onChange={(d: Date) => setStartDate(d)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Escolha aqui..."
                                    />
                                </ContainerDate>

                                <LabelDate>Data Final</LabelDate>
                                <ContainerDate>
                                    <DatePickerInput
                                        selected={endDate}
                                        onChange={(d: Date) => setEndDate(d)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Escolha aqui..."
                                    />
                                </ContainerDate>

                            </BoxItemsContainer>
                        </BoxContainer>
                    </ClickAwayListener>
                ) : <></>}

                <ButtonLink type="button"
                    onClick={() => {
                        setOpenFilterFlows(!openFilterFlows)
                        setOpenFilterUsers(false);
                        setOpenFilterDate(false);
                        setOpenFilterOptions(false);
                    }}
                    active={conditionsFlows.length >= 1 && conditionsFlows.length !== flows.length}
                >
                    <MdOutlineFilterList />
                    {conditionsFlows.length >= 1 && conditionsFlows.length !== flows.length ? filterStateMessageFlows : "Todos os Fluxos"}
                </ButtonLink>
                {openFilterFlows ? (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <BoxContainer style={{ top: '40px', left: '142px' }}>
                            <BoxItemsContainer>
                                {flows !== undefined && flows?.length > 0 ?
                                    flows?.sort((a, b) => a.name.localeCompare(b.name)).map((flow) => {

                                        const condFiltered = conditionsFlows.filter((co) => co === flow.id_flow);
                                        let isSelected = false;
                                        if (condFiltered !== undefined && condFiltered.length > 0) {
                                            isSelected = true;
                                        }

                                        return (
                                            <BoxItem key={flow.id_flow} onClick={() => handleSelectFlow(flow)}>
                                                <BoxMultiple>
                                                    {(flow.selected !== undefined && flow.selected === true) || isSelected ?
                                                        <BsToggleOn color="green" /> :
                                                        <BsToggleOff />
                                                    }
                                                </BoxMultiple>
                                                <BoxSpan>{flow.name}</BoxSpan>
                                            </BoxItem>
                                        )
                                    }) :
                                    <NoResults>Sem registro</NoResults>
                                }
                            </BoxItemsContainer>
                            <BoxButtonsContainer>
                                <BtnMarkAll onClick={() => handleMarkAllFlows()}>Marcar Todos</BtnMarkAll>
                                <BtnMarkNone onClick={() => handleMarkNoneFlows()}>Desmarcar Todos</BtnMarkNone>
                            </BoxButtonsContainer>
                        </BoxContainer>
                    </ClickAwayListener>
                ) : <></>}

                <ButtonLink type="button"
                    onClick={() => {
                        setOpenFilterUsers(!openFilterUsers)
                        setOpenFilterFlows(false);
                        setOpenFilterDate(false);
                        setOpenFilterOptions(false);
                    }}
                    active={conditionsUsers.length >= 1 && conditionsFlows.length !== flows.length}
                >
                    <MdOutlineFilterList />
                    {conditionsUsers.length >= 1 && conditionsFlows.length !== flows.length ? filterStateMessageUsers : "Todos os Usuários"}
                </ButtonLink>
                {openFilterUsers ? (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <BoxContainer style={{ top: '40px', left: '287px' }}>
                            <BoxItemsContainer>
                                {users !== undefined && users?.length > 0 ?
                                    users?.sort((a, b) => a.name.localeCompare(b.name)).map((users) => {

                                        const condFiltered = conditionsUsers.filter((co) => co === users.id_user);
                                        let isSelected = false;
                                        if (condFiltered !== undefined && condFiltered.length > 0) {
                                            isSelected = true;
                                        }

                                        return (
                                            <BoxItem key={users.id_user} onClick={() => handleSelectUser(users)}>
                                                <BoxMultiple>
                                                    {(users.selected !== undefined && users.selected === true) || isSelected ?
                                                        <BsToggleOn color="green" /> :
                                                        <BsToggleOff />
                                                    }
                                                </BoxMultiple>
                                                <BoxAvatar><AvatarCange user={users} size="20" /></BoxAvatar>
                                                <BoxSpan>{users.name}</BoxSpan>
                                            </BoxItem>
                                        )
                                    }) :
                                    <NoResults>Sem registro</NoResults>
                                }
                            </BoxItemsContainer>
                            <BoxButtonsContainer>
                                <BtnMarkAll onClick={() => handleMarkAllUsers()}>Marcar Todos</BtnMarkAll>
                                <BtnMarkNone onClick={() => handleMarkNoneUsers()}>Desmarcar Todos</BtnMarkNone>
                            </BoxButtonsContainer>
                        </BoxContainer>
                    </ClickAwayListener>
                ) : <></>}
            </FilterBarLeft>
            <FilterBarRight>

                <ButtonLink type="button"
                    onClick={() => {
                        downloadCSV();
                    }}
                >
                    <BsDownload style={{ fontSize: '15px', marginRight: '6px' }} />
                    <div>Exportar</div>
                </ButtonLink>

                <ButtonLink type="button"
                    onClick={() => {
                        setOpenFilterOptions(!openFilterOptions)
                        setOpenFilterUsers(false)
                        setOpenFilterFlows(false);
                        setOpenFilterDate(false);
                    }}
                >
                    <BsGearFill />
                    <div>Configurações</div>
                </ButtonLink>
                {openFilterOptions ? (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <BoxContainer style={{ top: '35px', left: 'auto', right: '0px' }}>
                            <BoxItemsContainer>
                                <BoxItem key={1} onClick={() => setOptArchived(!optArchived)}>
                                    <BoxMultiple>
                                        {optArchived ?
                                            <BsToggleOn color="green" /> :
                                            <BsToggleOff />
                                        }
                                    </BoxMultiple>
                                    <BoxSpan style={{ backgroundColor: 'transparent', paddingLeft: '0px' }}>Considerar cartões arquivados</BoxSpan>
                                </BoxItem>
                            </BoxItemsContainer>
                        </BoxContainer>
                    </ClickAwayListener>
                ) : <></>}
            </FilterBarRight>
        </Container>
    );

}

export default TimeSheetFilterBar;