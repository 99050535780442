import 'react-pro-sidebar/dist/css/styles.css';

import './sidebar.scss'

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaCheck, FaStore, FaTable } from 'react-icons/fa';
import { FiHome } from 'react-icons/fi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineMenu, MdOutlineMenuOpen } from 'react-icons/md';
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import { useHistory } from 'react-router-dom';

import MobileMenu from './MobileMenu';
import UserMenu from './UserMenu';
import cangeLogo from '../../assets/wendata-logo-w.png';
import cangeMiniLogo from '../../assets/wendata-mini-logo-w.png';
import {
    AvatarName,
    Container,
    DividerContainer,
    FooterContainer,
    LogoContainer,
    MenuAction,
    MenuMobile,
    WelcomeContainer,
    NotificationActive,
    IconContainer
} from './styles';
import { useAuth } from '../../hooks/auth';
import CangeIcon from '../CangeIcon';
import WorkspaceMenu from './WorkspaceMenu';
import { BsBoxes } from 'react-icons/bs';
import { Workspace } from '../../interfaces/Workspace';
import { IconList, IconPickerItem } from 'react-fa-icon-picker';
import AvatarCange from '../AvatarCange';
import getAccessControl from '../../middlewares/AccessControl';
import { getFeatureControlByPlan } from '../../middlewares/FeatureControl';

const Sidebar: React.FC = () => {

    const history = useHistory();
    const { user } = useAuth();

    const sbStatus = localStorage.getItem('@wendata:sbIsCollapsed');
    const sbStatusB = (sbStatus === 'true');
    const [collapsed, setCollapsed] = useState(sbStatusB);
    const [openUserMenu, setOpenUserMenu] = useState(false);
    const [openMobileMenu, setOpenMobileMenu] = useState(false);
    const [openWorkspaceMenu, setOpenWorkspaceMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState<{ [key: number]: { top: number; left: number } }>({});
    const menuActionRefs = useRef<React.RefObject<HTMLDivElement>[]>([]);

    const [logoMini, setLogoMini] = useState<string>(cangeMiniLogo);
    const [logo, setLogo] = useState<string>(cangeLogo);
    const [isLogoCustom, setIsLogoCustom] = useState<boolean>(false);

    const [workspaces, setWorkspaces] = useState<Workspace[]>();

    const handleCollapsedChange = useCallback(() => {
        if (collapsed) {
            setCollapsed(false);
            localStorage.setItem('@wendata:sbIsCollapsed', "false");
        } else {
            setCollapsed(true);
            localStorage.setItem('@wendata:sbIsCollapsed', "true");
        }
    }, [collapsed]);

    const setWorkspacesFixed = useCallback((workspaces: Workspace[]) => {
        setWorkspaces(workspaces);
    }, []);

    const goToDashboard = useCallback(() => {
        history.push('/dashboard');
    }, [history]);

    const goToFlowList = useCallback(() => {
        history.push('/flow-list');
    }, [history]);

    const goToRegisterList = useCallback(() => {
        history.push('/register-list');
    }, [history]);

    const goToTaskList = useCallback(() => {
        history.push('/task-list');
    }, [history]);

    const goToTemplateStore = useCallback(() => {
        history.push('/template-store');
    }, [history]);

    const goToWorkspace = useCallback((hash: string) => {
        history.push('/workspace/' + hash);
    }, [history]);

    const handleMouseEnter = useCallback((index: number) => {
        const targetElementRef = menuActionRefs.current[index];

        if (targetElementRef !== undefined && targetElementRef.current !== null && targetElementRef.current !== undefined) {
            if (targetElementRef.current) {
                const referenceElementRect = targetElementRef.current.getBoundingClientRect();

                const newTop = (referenceElementRect.top + window.scrollY) + 9; // Leva em consideração a rotação da página
                const newLeft = referenceElementRect.left;

                setMenuPosition((prevMenuPosition) => ({
                    ...prevMenuPosition,
                    [index]: { top: newTop, left: newLeft },
                }));
            }
        }
    }, [setMenuPosition]);

    useEffect(() => {
        // Inicializa os refs

        if (workspaces !== undefined) {
            menuActionRefs.current = Array.from({ length: workspaces.length }, (_, index) => React.createRef());
        }
    }, [workspaces]);

    useEffect(() => {

        if (user.company !== undefined && user.company !== undefined && getFeatureControlByPlan(5, user.company)) {

            let logoCustom = false;

            if (user.company.logo_url !== undefined && user.company.logo_url !== null) {
                setLogo(user.company.logo_url);
                logoCustom = true;
            }

            if (user.company.logo_mini_url !== undefined && user.company.logo_mini_url !== null) {
                setLogoMini(user.company.logo_mini_url);
                logoCustom = true;
            }

            if (logoCustom) {
                setIsLogoCustom(true);
            } else {
                setIsLogoCustom(false);
            }

        }

    }, [user.company]);

    return (
        <Container>
            <ProSidebar collapsed={collapsed}>
                {user.company !== undefined}
                <LogoContainer className="mobile-lay">
                    <img src={logo} onClick={goToDashboard} alt="Logo Cange" />

                    <MenuMobile onClick={() => setOpenMobileMenu(true)}>
                        <GiHamburgerMenu />
                    </MenuMobile>
                    <MobileMenu open={openMobileMenu} closeMenu={() => setOpenMobileMenu(false)} />
                </LogoContainer>

                <LogoContainer onClick={goToDashboard} className="sidebar-lay" isLogoCustom={isLogoCustom}>
                    {collapsed ?
                        <img className='img-logo-mini' src={logoMini} alt="Logo Cange" /> :
                        <img className='img-logo' src={logo} alt="Logo Cange" />
                    }
                </LogoContainer>
                <DividerContainer className="sidebar-lay" />

                <SidebarContent className="sidebar-lay">
                    <Menu iconShape="circle">
                        <MenuAction onClick={goToDashboard}>
                            <MenuItem icon={<FiHome />}>
                                Página Inicial
                            </MenuItem>
                        </MenuAction>
                        {getAccessControl(89, user.type) && (
                            <MenuAction onClick={goToTaskList}>
                                <MenuItem
                                    icon={<FaCheck style={{ height: "15px", marginTop: '2px' }} />}
                                    suffix={user.notifications !== undefined && user.notifications.length > 0 ? <NotificationActive /> : <></>}
                                >
                                    Minhas Tarefas
                                </MenuItem>
                            </MenuAction>
                        )}
                        <MenuAction onClick={goToFlowList}>
                            <MenuItem icon={<CangeIcon />}>
                                Meus Fluxos
                            </MenuItem>
                        </MenuAction>
                        {getAccessControl(86, user.type) && (
                            <MenuAction onClick={goToRegisterList}>
                                <MenuItem icon={<FaTable style={{ height: "16px" }} />}>
                                    Meus Cadastros
                                </MenuItem>
                            </MenuAction>
                        )}
                    </Menu>

                    {getAccessControl(85, user.type) && (
                        <>
                            <DividerContainer className="sidebar-lay" />

                            <Menu iconShape="round">
                                <MenuAction onClick={() => setOpenWorkspaceMenu(true)}>
                                    <MenuItem icon={<BsBoxes />}>
                                        Workspaces
                                    </MenuItem>
                                </MenuAction>

                                {workspaces !== undefined && workspaces.length > 0 ?
                                    workspaces.map((wp, index) => {
                                        return (
                                            <MenuAction
                                                key={wp.id_workspace}
                                                onClick={() => goToWorkspace(wp.hash)}
                                                color={wp.color}
                                                ref={menuActionRefs.current[index]}
                                                onMouseEnter={() => handleMouseEnter(index)}
                                                hoverTop={menuPosition[index] !== undefined ? menuPosition[index].top : undefined}
                                                hoverLeft={menuPosition[index] !== undefined ? menuPosition[index].left : undefined}
                                            >
                                                <MenuItem icon={
                                                    <IconContainer className='icon-container-cl' color={wp.color}>
                                                        <IconPickerItem
                                                            containerStyles={{
                                                                color: 'white'
                                                            }}
                                                            icon={wp.icon as IconList}
                                                            color={wp.color}
                                                        />
                                                    </IconContainer>
                                                }>
                                                    {wp.name}
                                                </MenuItem>
                                            </MenuAction>
                                        )
                                    })
                                    :
                                    <></>
                                }
                            </Menu>
                            <WorkspaceMenu
                                open={openWorkspaceMenu}
                                isColapsed={collapsed}
                                closeMenu={() => setOpenWorkspaceMenu(false)}
                                workspacesFixed={workspaces}
                                setWorkspacesFixed={setWorkspacesFixed}
                            />
                        </>
                    )}
                </SidebarContent>

                <SidebarFooter className="collapse-control sidebar-lay">

                    <Menu iconShape="circle" style={{ paddingBottom: '0px' }}>
                        {getAccessControl(84, user.type) && (
                            <MenuAction onClick={goToTemplateStore}>
                                <MenuItem icon={<FaStore />}>
                                    Central de Templates
                                </MenuItem>
                            </MenuAction>
                        )}
                        <MenuAction onClick={handleCollapsedChange}>
                            <MenuItem icon={collapsed ? <MdOutlineMenu /> : <MdOutlineMenuOpen />}>
                                {collapsed ? "Abrir Aba" : "Recolher Aba"}
                            </MenuItem>
                        </MenuAction>
                    </Menu>

                </SidebarFooter>

                <SidebarFooter className="sidebar-lay">
                    <FooterContainer onClick={() => setOpenUserMenu(true)}>
                        <WelcomeContainer>
                            <AvatarCange user={user} />
                            {collapsed ? "" :
                                <AvatarName>{user.name}</AvatarName>
                            }
                        </WelcomeContainer>
                    </FooterContainer>
                    <UserMenu open={openUserMenu} closeMenu={() => setOpenUserMenu(false)} />
                </SidebarFooter>

            </ProSidebar>
        </Container>
    );
};

export default Sidebar;
