import React from "react";

import {
    Container, ContainerBackground, ContainerDays, Days, DaysText
} from "./style";

interface TrialCounterProps {
    days: number;
    size: 'max' | 'min' | 'mid';
    color: string;
}

const TrialCounter: React.FC<TrialCounterProps> = ({ days, size, color }) => {

    return (
        <Container size={size} color={color}>
            <ContainerBackground>
                <ContainerDays>
                    <Days size={size}>{days}</Days>
                    {size === 'max' && <DaysText>dias</DaysText>}
                </ContainerDays>
            </ContainerBackground>
        </Container>
    );

}

export default TrialCounter;