import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    AutomationConfigGroup,
    AutomationConfigItem,
    AutomationConfigItemDescription,
    AutomationConfigItemIcon,
    AutomationConfigItems,
    AutomationConfigTitle,
    ContainerCloseButton,
    ButtonClose,
    Container,
    ContainerConfigRightHeader,
    ContainerConfigRightHeaderSearch,
    ContainerConfigRightHeaderTitle,
    ContainerLoader,
    Animation,
    ContainerNavigationBar,
    ContainerActions,
    AutomationConfigItemImage,
    ContainerIntegrationSelected,
    BtnBackToIntegrations
} from "./style";
import { BsArrowBarRight } from "react-icons/bs";
import api from "../../../../services/api";
import Trigger from "../../../../interfaces/Trigger";
import Action from "../../../../interfaces/Action";
import lottie from "lottie-web";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import NavigationBar, { NavigationBarProps } from "../../../../components/NavigationBar";
import Integration from "../../../../interfaces/Integration";
import { FaArrowLeft } from "react-icons/fa";

const itemsNavBar: NavigationBarProps = {
    items: [
        {
            label: 'Ações',
            id: 1,
            segment: 'actions',
            active: true
        },
        {
            label: 'Integrações',
            id: 2,
            segment: 'integrations',
            active: false
        }
    ]
}

function stringToNumberArray(str: string): number[] {
    return str
        .replace(/[\]]/g, '')
        .split(',')
        .map(num => parseFloat(num.trim()))
        .filter(num => !isNaN(num));
}

interface SelectMenuProps {
    openConfig: boolean;
    type: 'trigger' | 'action' | 'condition';
    isActionConditional: boolean;
    isEnableAdvancedLogic: boolean;
    parentItem?: Trigger;
    handleAddAdvancedLogic: (id_logic: number) => void;
    handleCloseMenuConfig: () => void;
    handleSelectItem: (type: 'trigger' | 'action', item: Trigger | Action) => void;
}

const SelectMenu: React.FC<SelectMenuProps> = ({ openConfig, type, isActionConditional, isEnableAdvancedLogic, parentItem, handleAddAdvancedLogic, handleCloseMenuConfig, handleSelectItem }) => {

    const [loading, setLoading] = useState<boolean>();
    const lottieContainer = useRef<HTMLDivElement>(null);

    const [triggers, setTriggers] = useState<Trigger[]>([]);
    const [actions, setActions] = useState<Action[]>([]);

    const [integrations, setIntegrations] = useState<Integration[]>([]);
    const [selectedIntegration, setSelectedIntegration] = useState<Integration>();
    const [integrationActions, setIntegrationActions] = useState<Action[]>([]);

    const [navBarSelected, setNavBarSelected] = useState(1); //1 -> Cange Actions / 2 -> Integrations
    const [navBarItems] = useState(itemsNavBar.items);

    //Function to reorder the triggers and actions by group A - Z
    const reorderTriggers = (a: Trigger, b: Trigger) => {
        if (a.group !== undefined && b.group !== undefined) {
            if (a.group < b.group) {
                return -1;
            }
            if (a.group > b.group) {
                return 1;
            }
            return 0;
        }
        return 0;
    }

    //Function to reorder the actions by group A - Z
    const reorderActions = (a: Action, b: Action) => {
        if (a.group !== undefined && b.group !== undefined) {
            if (a.group < b.group) {
                return -1;
            }
            if (a.group > b.group) {
                return 1;
            }
            return 0;
        }
        return 0;
    }

    //Function to reorder the integrations and actions by group A - Z
    const reorderIntegrations = (a: Integration, b: Integration) => {
        if (a.name !== undefined && b.name !== undefined) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        }
        return 0;
    }

    const getTriggersApi = useCallback(async () => {

        setLoading(true);

        await api.get(`/automation/triggers`, {
        }).then(response => {
            if (response.data !== null) {
                let resp: Trigger[] = response.data;

                resp = resp.sort(reorderTriggers);

                setTriggers(resp);

            }
            setLoading(false);
        });

    }, []);

    const getActionsApi = useCallback(async () => {

        setLoading(true);

        await api.get(`/automation/actions`, {
        }).then(response => {
            if (response.data !== null) {
                let resp: Action[] = response.data;

                if (selectedIntegration !== undefined && selectedIntegration.id_integration > 0) {
                    const newActions = resp.filter(action => action.integration_id === selectedIntegration.id_integration);
                    setIntegrationActions(newActions);
                }

                //check if has parent item
                if (parentItem !== undefined && parentItem.actions !== undefined && parentItem.actions !== null) {

                    const actionsId: number[] = stringToNumberArray(parentItem.actions);

                    resp = resp.filter(action => actionsId.includes(action.id_action));

                }

                resp = resp.sort(reorderActions);

                setActions(resp);

            }
            setLoading(false);
        });

    }, [parentItem, selectedIntegration]);

    const getIntegrationsApi = useCallback(async () => {

        setLoading(true);

        await api.get(`/automation/integrations`, {
        }).then(response => {
            if (response.data !== null) {
                let resp: Integration[] = response.data;

                resp = resp.sort(reorderIntegrations);

                setIntegrations(resp);

            }
            setLoading(false);
        });

    }, []);

    const handleSelectIntegrationAction = useCallback((action: Action) => {

        let newAction = action;
        newAction.integration = selectedIntegration;

        handleSelectItem('action', newAction); 

    }, [selectedIntegration, handleSelectItem]);

    const groupTriggers: { [groupName: string]: Trigger[] } = triggers.reduce(
        (acc, item) => {

            if (item.group !== undefined) {
                if (acc[item.group]) {
                    acc[item.group].push(item);
                } else {
                    acc[item.group] = [item];
                }
            }

            return acc;
        },
        {} as { [groupName: string]: Trigger[] }
    );

    const groupActions: { [groupName: string]: Action[] } = actions.reduce(
        (acc, item) => {

            if (item.group !== undefined) {
                if (acc[item.group]) {
                    acc[item.group].push(item);
                } else {
                    acc[item.group] = [item];
                }
            }

            return acc;
        },
        {} as { [groupName: string]: Action[] }
    );

    const groupIntegrations: { [groupName: string]: Integration[] } = integrations.reduce(
        (acc, item) => {

            if (item.group !== undefined) {
                if (acc[item.group]) {
                    acc[item.group].push(item);
                } else {
                    acc[item.group] = [item];
                }
            }

            return acc;
        },
        {} as { [groupName: string]: Integration[] }
    );

    useEffect(() => {

        if (openConfig) {

            if (type === 'trigger') {
                getTriggersApi();
            } else {
                getActionsApi();
                getIntegrationsApi();
            }

        }

    }, [openConfig, type, getTriggersApi, getActionsApi, getIntegrationsApi]);

    useEffect(() => {

        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../../assets/lottie/loader.json')
            });
        }

    }, [loading]);

    return (
        <Container>

            <ContainerConfigRightHeader>

                <ContainerCloseButton>
                    <ButtonClose type="button" onClick={handleCloseMenuConfig}>
                        <BsArrowBarRight />
                    </ButtonClose>
                </ContainerCloseButton>

                {type === 'trigger' ?
                    <ContainerConfigRightHeaderTitle>
                        <h3>Quando isso acontecer...</h3>
                        <span>Escolha um gatilho/desencadeador para executar a automação</span>
                    </ContainerConfigRightHeaderTitle> :
                    <ContainerConfigRightHeaderTitle>
                        <h3>Faça isso...</h3>
                        <span>Escolha uma ação para executar após o gatilho</span>
                    </ContainerConfigRightHeaderTitle>
                }

                <ContainerConfigRightHeaderSearch>

                </ContainerConfigRightHeaderSearch>
            </ContainerConfigRightHeader>

            {loading ?
                <ContainerLoader>
                    <Animation className="lottieContainer" ref={lottieContainer} />
                </ContainerLoader > :
                <>

                    {type === 'trigger' ?
                        <>
                            {Object.entries(groupTriggers).map(([group, triggerItems]) => {
                                return (
                                    <AutomationConfigGroup key={group}>
                                        <AutomationConfigTitle>
                                            {group}
                                        </AutomationConfigTitle>

                                        <AutomationConfigItems>
                                            {triggerItems.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0)).map((trigger, index) => (
                                                <AutomationConfigItem key={index} color={trigger.color} onClick={() => handleSelectItem('trigger', trigger)}>
                                                    <AutomationConfigItemIcon color={trigger.color}>
                                                        <IconPickerItem
                                                            icon={trigger.icon as IconList}
                                                            color={"white"}
                                                        />
                                                    </AutomationConfigItemIcon>
                                                    <AutomationConfigItemDescription>
                                                        <h3>{trigger.name}</h3>
                                                        <span>{trigger.description}</span>
                                                    </AutomationConfigItemDescription>
                                                </AutomationConfigItem>
                                            ))}
                                        </AutomationConfigItems>
                                    </AutomationConfigGroup>
                                )
                            })}
                        </> :
                        <>

                            {selectedIntegration === undefined && (
                                <ContainerNavigationBar>
                                    <NavigationBar
                                        items={navBarItems}
                                        navBarSelected={navBarSelected}
                                        setNavBarSelected={setNavBarSelected}
                                    />
                                </ContainerNavigationBar>
                            )}

                            {navBarSelected === 1 && (
                                <ContainerActions>
                                    {!isActionConditional && isEnableAdvancedLogic && parentItem !== undefined && parentItem.actions !== undefined && parentItem.actions.includes("-1") && (
                                        <AutomationConfigGroup key={"advanced"}>
                                            <AutomationConfigTitle>
                                                {"Lógica avançada"}
                                            </AutomationConfigTitle>

                                            <AutomationConfigItems>
                                                <AutomationConfigItem key={-1} color={"#a1a0a0"} onClick={() => handleAddAdvancedLogic(-1)}>
                                                    <AutomationConfigItemIcon color={"#a1a0a0"}>
                                                        <IconPickerItem
                                                            icon={"FaProjectDiagram" as IconList}
                                                            color={"white"}
                                                        />
                                                    </AutomationConfigItemIcon>
                                                    <AutomationConfigItemDescription>
                                                        <h3>{"Lógica condicional"}</h3>
                                                        <span>{"Execute diferentes ações de acordo com condições específicas"}</span>
                                                    </AutomationConfigItemDescription>
                                                </AutomationConfigItem>
                                            </AutomationConfigItems>
                                        </AutomationConfigGroup>
                                    )}
                                    {Object.entries(groupActions).map(([group, actionItems]) => {

                                        return (
                                            <AutomationConfigGroup key={group}>
                                                <AutomationConfigTitle>
                                                    {group}
                                                </AutomationConfigTitle>

                                                <AutomationConfigItems>
                                                    {actionItems.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0)).map((action, index) => (
                                                        <AutomationConfigItem key={index} color={action.color} onClick={() => handleSelectItem('action', action)}>
                                                            <AutomationConfigItemIcon color={action.color}>
                                                                <IconPickerItem
                                                                    icon={action.icon as IconList}
                                                                    color={"white"}
                                                                />
                                                            </AutomationConfigItemIcon>
                                                            <AutomationConfigItemDescription>
                                                                <h3>{action.name}</h3>
                                                                <span>{action.description}</span>
                                                            </AutomationConfigItemDescription>
                                                        </AutomationConfigItem>
                                                    ))}
                                                </AutomationConfigItems>
                                            </AutomationConfigGroup>
                                        )
                                    })}
                                </ContainerActions>
                            )}

                            {navBarSelected === 2 && (
                                <ContainerActions>

                                    {selectedIntegration !== undefined && selectedIntegration.id_integration > 0 && (
                                        <ContainerIntegrationSelected>

                                            <BtnBackToIntegrations onClick={() => setSelectedIntegration(undefined)}>
                                                <FaArrowLeft />
                                                Voltar para integrações
                                            </BtnBackToIntegrations>

                                            <AutomationConfigItems>
                                                <AutomationConfigItem color="#808080">
                                                    <AutomationConfigItemImage size={60}>
                                                        <img src={selectedIntegration.url_logo} alt={selectedIntegration.name} />
                                                    </AutomationConfigItemImage>
                                                    <AutomationConfigItemDescription>
                                                        <h3 style={{ fontSize: '16px' }}>{selectedIntegration.name}</h3>
                                                        <span>{selectedIntegration.pre_desc}</span>
                                                    </AutomationConfigItemDescription>
                                                </AutomationConfigItem>
                                            </AutomationConfigItems>

                                            <AutomationConfigGroup>
                                                <AutomationConfigTitle>
                                                    Ações da integração
                                                </AutomationConfigTitle>
                                                <AutomationConfigItems>
                                                    {integrationActions.map((action, index) => (
                                                        <AutomationConfigItem
                                                            key={index}
                                                            color="#808080"
                                                            style={{ minHeight: '35px', padding: '5px' }}
                                                            onClick={() => handleSelectIntegrationAction(action)}
                                                        >
                                                            <AutomationConfigItemImage size={35}>
                                                                <img src={selectedIntegration.url_logo} alt={selectedIntegration.name} />
                                                            </AutomationConfigItemImage>
                                                            <AutomationConfigItemDescription>
                                                                <h3>{action.name}</h3>
                                                                <span>{action.description}</span>
                                                            </AutomationConfigItemDescription>
                                                        </AutomationConfigItem>
                                                    ))}
                                                </AutomationConfigItems>
                                            </AutomationConfigGroup>

                                        </ContainerIntegrationSelected>
                                    )}

                                    {selectedIntegration === undefined && Object.entries(groupIntegrations).map(([group, integrationItems]) => {
                                        return (
                                            <AutomationConfigGroup key={group}>
                                                <AutomationConfigTitle>
                                                    {group}
                                                </AutomationConfigTitle>

                                                <AutomationConfigItems>
                                                    {integrationItems.map((integration, index) => (
                                                        <AutomationConfigItem key={index} color="#808080" onClick={() => setSelectedIntegration(integration)}>
                                                            <AutomationConfigItemImage>
                                                                <img src={integration.url_logo} alt={integration.name} />
                                                            </AutomationConfigItemImage>
                                                            <AutomationConfigItemDescription>
                                                                <h3>{integration.name}</h3>
                                                                <span>{integration.pre_desc}</span>
                                                            </AutomationConfigItemDescription>
                                                        </AutomationConfigItem>
                                                    ))}
                                                </AutomationConfigItems>
                                            </AutomationConfigGroup>
                                        )
                                    })}
                                </ContainerActions>
                            )}
                        </>
                    }

                </>
            }

        </Container>
    );

}

export default SelectMenu;
