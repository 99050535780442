import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import styled, { css } from "styled-components";
import Button from '../../components/Button';
import DataTable from 'react-data-table-component';

interface IconHeaderProps {
    color: string;
}

interface BtnInsertNewAnswerProps {
    color: string;
}

interface UploadMessageProps {
    type: string;
}

interface RelationshipItemProps {
    active: boolean;
}

interface HelpContainerProps {
    color?: string;
}

interface RegisterDataProps {
    color?: string;
}

interface BtnTypeImportProps {
    active: boolean;
}

export const Content = styled(DialogContent)`
    background-color: white;   
    overflow-y: auto;
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: center;
    height: auto;
    width: auto;
    margin-bottom: 15px;
    min-height: 400px;
`;

export const DialogTop = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #eef2f8;
    margin-top: 15px;
    width: 100%;
    
`;

export const DialogTopLeft = styled.div`
    width: 65%;
    height: 100%;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: start;
    margin-left: 15px;

    h1 {
        margin-left: 20px;
        font-size: 20px;
        font-weight: 500;
        color: black;
    }
`;

export const DialogTopCenter = styled.div`
    width: 25%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
`;

export const DialogTopRight = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 30px;
        border: none;
        background-color: transparent;
    }
`;

export const IconHeader = styled.span<IconHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 100%;
    background-color: transparent;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 45px;
        height: 45px;
        background-color: #f4f4f4;
        border-radius: 50%;

        > svg {
            width: 25px;
            height: 25px;

            ${props => props.color && css`
                color: ${props.color} !important;
            `}
        }

    }
`;

export const DialogFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eef2f8;
    padding: 15px !important;
    width: 100%;
`;

export const DialogFooterCenter = styled.div`
    display: flex;
    justify-content: flex-end;
    align-content: center;
    width: 50%;
`;

export const BtnInsertNewAnswer = styled(Button) <BtnInsertNewAnswerProps>`
    height: 40px;
    width: 160px;
    background-color: #f23b5c;
    color: white;
    border-radius: 5px;
    border: 0;
    font-weight: 500;

    ${props => props.color && css`
        background-color: ${props.color} !important;
    `}
`;

export const CleanBox = styled.div`
    display: grid;
    justify-items: center;
    align-content: center;
    padding: 10px;
    border-radius: 10px;
    border: 1px dashed gray;
    min-height: 400px;
    font-size: 15px;
    color: gray;
    height: calc(100% - 5px);
    width: 100%;
    text-align: center;
    cursor: pointer;

    svg {
        font-size: 35px;
        margin-bottom: 15px;
        color: #f23b5c;
    }

    :hover {
        border: 1px dashed #f23b5c;
        color: #f23b5c;
    }
`;

export const CleanBoxText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 13px;
    margin-top: 2px;
    max-width: 500px;
    color: gray;
`;

export const CleanBoxTitle = styled.h2`
    font-size: 17px;
    margin-bottom: 10px;
    text-align: center;    
    color: black;

    span {
        margin-left: 5px;
        font-size: 20px;
    }
`;

export const UploadMessage = styled.p<UploadMessageProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 15px 0;    
    font-size: 14px;
    font-weight: 400;
    color: #4d4d4d;

    ${props => props.type === "default" && css`
        color: "#999";
    `}

    ${props => props.type === "error" && css`
        color: "#e57878";
    `}

    ${props => props.type === "sucess" && css`
        color: "#78e5d5";
    `}

    svg {
        height: 15px;
        width: 15px;
        color: #4d4d4d;
        margin-right: 5px;
        margin-left: 5px;
        min-width: 15px;
    }
`;

export const ContainerRelationship = styled.div`
    width: 100%;
`;

export const RelationshipItem = styled.div<RelationshipItemProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;

    ${props => !props.active && css`
        opacity: 0.2;
    `}
`;

export const RelationshipItemLeft = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    max-width: 48%;
`;

export const RelationshipItemLeftAtive = styled.input`
  z-index: 1;
  cursor: pointer;
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin-right: 10px;

`;

export const RelationshipItemTitle = styled.div`
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 5px;
        color: gray;
        font-size: 12px;
    }
`;

export const RelationshipItemCenter = styled.div`
    font-size: 20px;
    color: gray;
`;

export const RelationshipItemRight = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
`;

export const SelectInput = styled.select`
    height: 35px;
    border-radius: 5px;
    border-color: #808080bf;
    font-size: 14px;
    padding: 5px;
    color: black;
    margin-left: 15px;
    width: 100%;
`;

export const OptionItem = styled.option`

    svg {
        display: flex;
        font-size: 25px;
    }

`;

export const HelpContainer = styled.div<HelpContainerProps>`
    margin-bottom: 5px;
    border-radius: 10px;
    background-color: #4680B81e;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ${props => props.color && css`
        background-color: ${props.color}; 
    `}
`;

export const HelpItems = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
`;
export const HelpItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
`;
export const HelpIcon = styled.div<HelpContainerProps>`
    width: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    svg {
        font-size: 25px;
        color: #4680B8;

        ${props => props.color && css`
            color: ${props.color}; 
        `}
    }
`;
export const HelpText = styled.div`
    width: 85%;
    font-size: 13px;
`;

export const RelationshipHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
`;

export const RelationshipHeaderLeft = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    flex-direction: column;
    margin-left: -10px;
    padding-left: 0px;
    max-width: 48%;
`;

export const RelationshipHeaderTitle = styled.div`
    font-size: 18px;
    margin-left: 20px;
    font-weight: 500;
`;

export const RelationshipHeaderCenter = styled.div`
    font-size: 20px;
    color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
`;

export const RelationshipHeaderRight = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    flex-direction: column;
`;

export const RelationshipHeaderSubTitle = styled.div`
    font-size: 13px;
    font-weight: 400;
    margin-left: 20px;
    color: gray;
    margin-top: 5px;
`;

export const ErrorContainer = styled.div`
    margin-left: 10px;
`;

export const TableContainer = styled.div`
    
`;

export const DataTableCange = styled(DataTable)`

    .rdt_TableCol_Sortable {
        div {
            background-color: #f4f5f7;
            padding: 5px 10px;
            border-radius: 5px;
            font-weight: 500;
        }
    }

    .rdt_TableCell {
        //min-width: 175px;
        overflow: hidden; 
    }

    .rdt_TableCol {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .rdt_TableCol_Sortable {
        display: flex;
        justify-content: center;
        width: 100%;
        //margin-left: 18px;
        //overflow: hidden;
        align-items: center;
        text-align: center;
    }
`;

export const RegisterData = styled.span<RegisterDataProps>`
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 5px;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 5px;
    color: rgba(51,51,51,0.9);
    background-color: #4681b81e;

    ${(props) => props.color && css`
      background-color: ${props.color + "1e"};
    `}
`
export const NoRegister = styled.span`
    color: gray;
    font-style: italic;
`;

export const DialogFooterLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    color: black;
    opacity: 0.8;
    margin-top: 2px;
    letter-spacing: 0.3px;
    margin-left: 25px;
`;

export const ContainerLoader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;

    svg {
        max-width: 200px;
    }
    
`;

export const LoaderTitle = styled.div`
    font-size: 15px;
    color: black;
    font-weight: 400;
    text-align: center;
`;

export const LoaderIcon = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 175px;
    margin-bottom: 10px;
`;

export const ContainerTypeImportFlow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const BtnTypeImportFlowLeft = styled.button<BtnTypeImportProps>`
    padding: 7px 10px;
    background-color: #f23b5d;
    border-radius: 5px 0px 0px 5px;
    color: white;
    font-size: 13px;
    font-weight: 500;
    margin-top: 15px;
    border: none;
    border-right: 1px solid white;

    ${props => !props.active && css`
        background-color: #f23b5d1e !important;
        color: gray !important;
    `}
`;

export const BtnTypeImportFlowRight = styled.button<BtnTypeImportProps>`
    padding: 7px 10px;
    background-color: #f23b5d;
    border-radius: 0px 5px 5px 0px;
    color: white;
    font-size: 13px;
    font-weight: 500;
    margin-top: 15px;
    border: none;

    ${props => !props.active && css`
        background-color: #f23b5d1e !important;
        color: gray !important;
    `}
`;