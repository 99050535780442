import React from "react";

import {
    ContainerCloseButton,
    ButtonClose,
    Container,
    ContainerConfigRightHeader,
    ContainerConfigRightHeaderSearch,
    ContainerConfigRightHeaderTitle,
    ContainerConfigRightBody
} from "./style";
import { BsArrowBarRight } from "react-icons/bs";
import { Flow } from "../../../../interfaces/Flow";
import RunHistory from "../../RunHistory";

interface RunHistoryMenuProps {
    openConfig: boolean;
    flow: Flow;
    automation_id: number;
    days: number;
    handleCloseMenuConfig: () => void;
}

const RunHistoryMenu: React.FC<RunHistoryMenuProps> = ({ openConfig, flow, automation_id, days, handleCloseMenuConfig }) => {

    return (
        <Container>

            <ContainerConfigRightHeader>

                <ContainerCloseButton>
                    <ButtonClose type="button" onClick={handleCloseMenuConfig}>
                        <BsArrowBarRight />
                    </ButtonClose>
                </ContainerCloseButton>

                <ContainerConfigRightHeaderTitle>
                    <h3>Histórico de execução</h3>
                    <span>Veja todos os logs de execução desta automação</span>
                </ContainerConfigRightHeaderTitle>

                <ContainerConfigRightHeaderSearch>

                </ContainerConfigRightHeaderSearch>
            </ContainerConfigRightHeader>

            <ContainerConfigRightBody>

                <RunHistory open={openConfig} flow={flow} automation_id={automation_id} days={days} />

            </ContainerConfigRightBody>


        </Container>
    );

}

export default RunHistoryMenu;