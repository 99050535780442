import React from "react";

import emptyTask from '../../../assets/empty-state/empty-task.png';
import {
    Container,
    ContainerImage,
    ContainerSubTitle,
    ContainerTitle
} from "./style";

interface emptyStateProps {
    type: 'task' | 'request';
}

const EmptyTask: React.FC<emptyStateProps> = ({ type }) => {

    return (
        <Container>
            <ContainerImage>
                <img src={emptyTask} alt="Sem Tarefas" />
            </ContainerImage>
            <ContainerTitle>
                {type === 'task' ?
                    <h1>Você não possui tarefas</h1> :
                    <h1>Você ainda não possui solicitações</h1>
                }
            </ContainerTitle>
            <ContainerSubTitle>
                {type === 'task' ?
                    <span>Por enquanto você não tem nenhuma tarefa para realizar</span> :
                    <span>Crie sua primeira solicitação em um fluxo para acompanhar por aqui</span>
                }                
            </ContainerSubTitle>
        </Container>
    );

}

export default EmptyTask;