import React from "react";

import {
    Container,
    EmbedContent
} from "./style";

interface EmbedProps {
    embed?: string;
}

const Embed: React.FC<EmbedProps> = ({ embed }) => {

    return (
        <Container>
            {embed !== undefined ?
                <EmbedContent dangerouslySetInnerHTML={{ __html: embed }} /> :
                <div>
                    Insira o código HTML para visualizar aqui o seu elemento incorporado
                </div>
            }
        </Container>
    );

}

export default Embed;