import styled from "styled-components";

export const Container = styled.div`

    height: 100%;

    #public-form {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 15px;

        @media (max-width: 768px) {
            display: block;
        }
    }

    #public-logo {
        @media (max-width: 768px) {
            margin-top: 0px !important;
        }
    }

    #public-spacing {
        @media (max-width: 768px) {
            margin-top: 0px !important;
        }
    }

`;

export const ContainerError = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    background-color: #f4f5f7;
`;

export const ContainerErrorBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

export const ContainerErrorIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    svg {
        font-size: 85px;
        color: #f23b5d;
    }
`;

export const ContainerErrorTitle = styled.h1`        
    margin-bottom: 10px;
    margin-top: 15px;
    font-size: 22px;
    color: black;
`;

export const ContainerErrorDescription = styled.p`
    font-weight: 400;
    color: gray;
    overflow-wrap: anywhere;
    font-size: 15px;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 30px;
`;

export const ContainerErrorButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f5f7;
    border-radius: 5px;
    width: 200px;
    color: gray;
    border: 0;
    padding: 10px 20px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;    
`;

export const ContainerBottomText = styled.a`
    margin-top: 50px;
    margin-bottom: 30px;
    max-width: 700px;    
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 10px 30px;
    border-radius: 10px;
    color: black;
    opacity: 0.9;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;

    img {
        width: 80px;
    }
`;

export const ContainerLoading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
`;

export const ContainerLoader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 200px;
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 100% !important;
        max-width: 250px;
    }
    
`;