import React, { useState } from "react";
import { BsGearFill } from "react-icons/bs";
import { FaKey, FaUserAlt } from "react-icons/fa";

import LayoutContainer from '../../components/LayoutContainer';
import ListContainer from '../../components/ListContainer';
import Password from "./Password";
import Profile from "./Profile";
import AppBarHeader, { NavigationBarItemType } from "../../components/AppBarHeader";
import {
    Container,
    Content
} from "./style";
import Notifications from "./Notifications";
import { IoNotificationsSharp } from "react-icons/io5";
import { IoIosApps } from "react-icons/io";
import AppUserConfig from "./AppUserConfig";
import { useAuth } from "../../hooks/auth";

interface UserConfigsProps {

}

const UserConfigs: React.FC<UserConfigsProps> = () => {

    const { user } = useAuth();
    const [navBarSelected, setNavBarSelected] = useState(1);

    const navBarItems: NavigationBarItemType[] = [
        {
            index: 1,
            description: "Meu Perfil",
            icon: BsGearFill,
            active: navBarSelected === 1 ? true : false,
            onClick: () => setNavBarSelected(1)
        },
        {
            index: 2,
            description: "Notificações",
            icon: IoNotificationsSharp,
            active: navBarSelected === 2 ? true : false,
            onClick: () => setNavBarSelected(2)
        },
        {
            index: 3,
            description: "Credenciais",
            icon: FaKey,
            active: navBarSelected === 3 ? true : false,
            onClick: () => setNavBarSelected(3)
        },
        {
            index: 4,
            description: "Aplicativos",
            icon: IoIosApps,
            iconSize: '17',
            active: navBarSelected === 4 ? true : false,
            onClick: () => setNavBarSelected(4),
            typeUserAllowed: ["A"]
        },
    ]

    return (
        <LayoutContainer>
            <title>{"Minhas Configurações"} | Cange</title>
            <ListContainer>

                {/* Header Page */}
                <AppBarHeader
                    icon={FaUserAlt}
                    title={"Minhas Configurações"}
                    navBarItems={navBarItems}
                    isRoundedCorners={true}
                    typeUser={user.type}
                >

                </AppBarHeader>

                {/* Inputs Form */}
                <Container>
                    <Content container>

                        {navBarSelected === 1 ?
                            <Profile /> :
                            navBarSelected === 2 ?
                                <Notifications /> :
                                navBarSelected === 3 ?
                                    <Password /> :
                                    navBarSelected === 4 ?
                                        <AppUserConfig /> :
                                        <></>
                        }

                    </Content>
                </Container>

            </ListContainer>
        </LayoutContainer>

    );

}

export default UserConfigs;