import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    right: 0;
    bottom: 15px;
    z-index: 10;

    @media (max-width: 600px) {
      bottom: 170px;
    }
`;

export const MiniContainer = styled.div`    
    background-color: #242425;
    width: 75px;
    height: 60px;
    
    border-radius: 15px 0px 0px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const BigContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 11;
    background-color: #242425;
    width: 280px;
    padding-top: 15px;
    padding-bottom: 10px;
    border-radius: 15px 0px 0px 15px;
    display: flex;
    justify-content: center;
    align-items: center;    
`;

export const CounterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    user-select: none;
    cursor: default;

    h3 {
        color: white;
        font-size: 18px
    }
`;

export const Divider = styled.div`
    margin: 10px;
    border-bottom: 1px solid #818181;
    width: 100%;
`;

export const ActionArea = styled.div`
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const UpgradeButton = styled.button`    
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: white;
    font-size: 13px;
    border: none;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    background: linear-gradient(90deg, rgb(255, 140, 47) 25%, rgb(251, 50, 92) 50%, rgb(147, 55, 237) 75%);
    width: 90%;
    padding: 8px 10px;
    box-shadow: 0 4px 34px #4b048133;

    svg {
        margin-right: 10px;
        font-size: 15px;
    }
`;

export const HelpButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: white !important;
    font-size: 13px;
    border: none;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    background-color: #818181;
    width: 90%;
    padding: 8px 10px;

    svg {
        margin-right: 10px;
        font-size: 15px;
    }
`;

export const BadgeTrial = styled.div`
    margin-bottom: 10px;
    margin-top: 5px;
    background-color: gray;
    border-radius: 15px;
    padding: 3px 15px;
    font-size: 14px;
    font-weight: 500;
    color: white;
`;

export const CloseButton = styled.div`
    position: absolute;
    left: 15px;
    top: 14px;
    display: none;
    color: white;
    cursor: pointer;

    @media (max-width: 600px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
`;