import { format, isToday, isThisYear, isYesterday } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

export function formatDateToDateRead(date: Date, withHours?: boolean): string {

    if (!withHours) {
        if (isToday(date)) {
            return "Hoje"
        } else if (isYesterday(date)) {
            return "Ontem"
        } else if (isThisYear(date)) {
            return format(date, 'dd \'de\' MMM.', { locale: ptBR });
        } else {
            return format(date, 'dd/MM/yyyy', { locale: ptBR });
        }
    } else {
        if (isToday(date)) {
            return "Hoje às " + format(date, 'hh:mm', { locale: ptBR });
        } else if (isYesterday(date)) {
            return "Ontem às " + format(date, 'hh:mm', { locale: ptBR });
        } else if (isThisYear(date)) {
            return format(date, 'dd \'de\' MMM.', { locale: ptBR }) + " às " + format(date, 'hh:mm', { locale: ptBR });
        } else {
            return format(date, 'dd/MM/yyyy', { locale: ptBR }) + " às " + format(date, 'hh:mm', { locale: ptBR });
        }
    }

}