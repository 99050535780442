import styled, { css } from "styled-components";

interface ButtonLinkProps {
    active?: boolean;
}

export const Container = styled.div`
    //display: block;
    display: flex;
    width: 100%;
    background-color: white;
    height: 40px;
    border-radius: 0px 0px 10px 10px;
    //box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
    padding-left: 15px;
    padding-right: 5px;
    border-top: solid 0.5px #f4f5f7;
    position: relative;
`;

export const LeftSide = styled.div`
    float: left;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;

    #filter-view-config {
        @media(max-width: 800px) {

            display: none !important;

        }
    }
`;

export const RigthSide = styled.div`
    position: absolute;
    right: 0px;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

export const SearchTemplate = styled.div`
    border: 0.5px solid #808080;
    margin-right: 10px;
    height: 30px;
    max-height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;

    svg {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 20px;
        color: #808080;
    }

`;

export const SearchTemplateInput = styled.input`
    height: 100%;
    width: 100%;
    border: none;
    font-size: 15px;
    border-radius: 5px;
`;

export const ButtonLink = styled.button<ButtonLinkProps>`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;

    svg {
        font-size: 13px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }

    ${props => props.active && css`
        background-color: #ff8c2e;
        color: white;
        border-radius: 5px;   
        font-weight: 500;
        
        :hover {
            background-color: #ff8c2e;
        }
    `}

    @media(max-width: 800px){ 
        svg {
            margin-right: 0px;
        }

        div {
            display: none;            
        }
    }
    
`;

export const ButtonLinkSync = styled.button`
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    color: #4d4d4d;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;
    width: 145px;
    justify-content: flex-end;
    font-weight: 500;

    svg {
        font-size: 16px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
    
`;

export const ButtonAdd = styled.button`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;
    width: 155px;
    background-color: #f23b5c;
    color: white;
    border-radius: 5px;

    svg {
        font-size: 10px;
        margin-right: 5px;
        margin-top: 1px;
    }
    
`;

export const ButtonSync = styled.button`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 5px;

    width: 35px;
    height: 25px;
    background-color: #f4f5f7;
    color: #5a5a5a;

    svg {
        margin: 0px;
        font-size: 12px;
    }
    
`;
