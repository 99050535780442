
/* Cores Bases
#f23b5c
#4680B8
#9e37ed
#ff8c2f
#61bd4f
*/

const colors = [
    "#f23b5c",
    "#4680B8",
    "#9e37ed",
    "#ff8c2f",
    "#61bd4f"
]

export default function getRandomColor(): string {

    const max = Math.floor(colors.length);
    const min = Math.ceil(0);

    const random = Math.floor(Math.random() * (max - min)) + min;
    
    return colors[random];

}