import React, { useCallback } from "react";
import { FaEye, FaEyeSlash, FaTrash } from "react-icons/fa";

import { Container, ElementButtons, ElementHeaderDrag } from './styles';
import { useAutofocus } from "../../hooks/useAutoFocus";
import { MdOutlineDragIndicator } from "react-icons/md";

export interface inputItemLabelProps {
    id_field_option?: number;
    field_id?: number;
    value: string;
    label: string;
    order: string;
    hide?: string;
    old_id_field_option?: number;
}

export interface InputItemProps {
    item: inputItemLabelProps;
    index: number;
    setItem: React.Dispatch<React.SetStateAction<inputItemLabelProps>>;
    handleRemoveItem(value: string): Promise<void>;
    inputRefs: React.MutableRefObject<HTMLInputElement[]>;
    max_length?: number;
}

const InputItem: React.FC<InputItemProps> = ({ item, index, setItem, handleRemoveItem, inputRefs, max_length }) => {

    const changeItemText = useCallback(async (evt: React.ChangeEvent<HTMLInputElement>) => {
        const name = evt.target.value;
        setItem({ ...item, label: name });
    }, [item, setItem]);

    const changeItemHide = useCallback(async (currentValue?: string) => {

        const newValue = currentValue === "S" ? "N" : "S";

        setItem({ ...item, hide: newValue });

    }, [item, setItem]);

    return (
        <Container>
            <ElementHeaderDrag className="drag-handle">
                <MdOutlineDragIndicator />
            </ElementHeaderDrag>
            <input
                type="text"
                value={item.label}
                onChange={changeItemText}
                ref={useAutofocus()}
                //ref={(elRef) => (inputRefs.current[index] = elRef as HTMLInputElement)}
                placeholder="Digite aqui..."
                maxLength={max_length}
            />
            {item.hide === "S" ?
                <FaEyeSlash className="hide-item-active" color="gray" /> :
                <></>
            }
            <ElementButtons className="buttons-label">
                <button type="button" title="Ocultar a opção" onClick={() => changeItemHide(item.hide)}>
                    {item.hide === "S" ?
                        <FaEyeSlash color="gray" /> :
                        <FaEye color="gray" />
                    }
                </button>
                <button type="button" title="Excluir a opção" onClick={() => handleRemoveItem(item.value)}>
                    <FaTrash />
                </button>
            </ElementButtons>
        </Container>
    );

}

export default InputItem;