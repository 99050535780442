
interface IGetNamesFromFullEmail {
    Email: string;
    Name?: string;
    MailboxHash?: string;
}

export default function getNamesFromFullEmail(fullEmail?: IGetNamesFromFullEmail[], flowAddress?: string, flowName?: string): string {

    if (fullEmail) {

        let names: string = "";

        fullEmail.forEach((email, index) => {
            if (email.Name) {
                names += email.Name;
            } else if (flowAddress && flowName && flowAddress === email.Email) {
                names += flowName;
            } else {
                names += email.Email;
            }
            if (index < fullEmail.length - 1) {
                names += ", ";
            }
        });

        return names;

    } else {
        return "(Sem registro)";
    }

}