import React, { useEffect, useState } from "react";

import { FormAnswerField } from "../../../../../../interfaces/FormAnswerField";
import { CheckListItem } from "../../../../../../interfaces/CheckListItem";
import { inputItemLabelProps } from "..";
import { CLCheckbox, CLDescription, CLDescriptionContainer, CLItem, ChecklistBody, ProgressBarTask, ProgressBarTaskLine, ProgressBarTaskLineInside, ProgressBarTaskPercent } from "../style";
import { BsCheckCircleFill, BsCircle } from "react-icons/bs";

interface CheckListViewProps {
    value: FormAnswerField[];
    onlyPercent?: boolean;
    colorBar?: string;
}

const CheckListView: React.FC<CheckListViewProps> = ({ value, onlyPercent, colorBar }) => {

    const [items, setItems] = useState<CheckListItem[]>([]);
    const [percentage, setPercentage] = React.useState<number | undefined>(0);

    useEffect(() => {

        //Calculate the percentage of the checklist
        let percentage = 0;
        if (items !== undefined && items.length > 0) {
            const totalItemsChecked = items.filter((item) => item.checked === 'S').length;
            percentage = totalItemsChecked / items.length;
        }

        setPercentage(percentage);

    }, [items]);

    useEffect(() => {

        if (value !== undefined && value.length > 0) {

            //Get all the items from the value variable from the form
            const arrValues: string[] = [];

            for (let index = 0; index < value.length; index++) {
                const item = value[index];

                if (item.value !== undefined) {
                    arrValues.push(item.value);
                }

            }

            if (typeof value === "object") {

                //R

                const arrDefault = arrValues as unknown as inputItemLabelProps[];

                let newArr: inputItemLabelProps[] = [];

                for (let index = 0; index < arrDefault.length; index++) {
                    const element = arrDefault[index];

                    if (element.label !== undefined) {

                        newArr.push({
                            id_field_option: element.id_field_option,
                            field_id: element.field_id,
                            value: element.value,
                            label: element.label,
                            order: element.order,
                            hide: element.hide,
                            old_id_field_option: element.old_id_field_option
                        });

                    } else {

                        const element = arrDefault[index] as unknown as string;

                        newArr.push({
                            value: String(index + 1),
                            label: element,
                            order: "0"
                        });

                    }

                }

                //Order the array by order
                newArr.sort((a, b) => {
                    if (a.order !== undefined && b.order !== undefined) {
                        return Number(a.order) - Number(b.order);
                    } else {
                        return 0;
                    }
                });

                //Transform the array to the correct format CheckListItem
                const newItems: CheckListItem[] = newArr.map((item, index) => {

                    //Get the item.value and parse it to a CheckListItem
                    const itemValue: CheckListItem = JSON.parse(item.label);

                    return {
                        id_check_list_item: item.id_field_option !== undefined ? item.id_field_option : 0,
                        hash: itemValue.hash,
                        description: itemValue.description,
                        index: index,
                        checked: itemValue.checked !== undefined ? itemValue.checked : "N"
                    };
                });

                setItems(newItems);

            }

        }

    }, [value]);

    return (
        <>
            <ChecklistBody style={onlyPercent ? { width: '100%' } : {}}>

                <ProgressBarTask style={onlyPercent ? {} : { marginBottom: '10px' }}>
                    <ProgressBarTaskLine>
                        <ProgressBarTaskLineInside colorBar={colorBar} width={percentage !== undefined ? percentage * 100 : 0} />
                    </ProgressBarTaskLine>
                    <ProgressBarTaskPercent style={onlyPercent ? { minWidth: '35px', textAlign: 'center', marginRight: '0px' } : {}}>{String(Math.round((Number(percentage) * 100)))}%</ProgressBarTaskPercent>
                </ProgressBarTask>

                {!onlyPercent && items && items.length > 0 ? items.sort((a, b) => a.index - b.index).map((item, index) => {
                    return (
                        <CLItem
                            key={index}
                            style={{ cursor: 'default' }}
                        >

                            <CLCheckbox style={{ opacity: '0.8', cursor: 'default' }}>
                                {item.checked && item.checked === "S" ?
                                    <BsCheckCircleFill color="#61bd4f" size={'20px'} /> :
                                    <BsCircle color="#9e37ed" size={'20px'} />
                                }
                            </CLCheckbox>

                            <CLDescriptionContainer>
                                <CLDescription
                                    id={item.hash}
                                    className="input-description-cl-item"
                                    key={index}
                                    placeholder="Digite aqui uma descrição para o novo item..."
                                    type="text"
                                    disabled={true}
                                    value={item.description}
                                    checked={item.checked === undefined ? false : item.checked === "S" ? true : false}
                                />
                            </CLDescriptionContainer>

                        </CLItem>
                    )
                }) :
                    <></>
                }

            </ChecklistBody>
        </>
    );

}

export default CheckListView;