import React from "react";

import {
    Container
} from "./style";

interface ListItemProps {
    children?: React.ReactNode;
    onClick?: () => void;
}

const ListItem: React.FC<ListItemProps> = ({ children, onClick }) => {

    return (
        <Container onClick={onClick} style={onClick !== undefined ? { cursor: 'pointer' } : {}}>
            {children}
        </Container>
    );

}

export default ListItem;