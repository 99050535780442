import { Card } from "../../../interfaces/Card";
import { TimeTracking } from "../../../interfaces/TimeTracking";
import { User } from "../../../interfaces/User";

export interface TimeSheetDataTableProps {
    [key: string]: any;
    title?: string;
    items?: TimeSheetDataTableProps[];
    duration?: number;
    user?: User;
}

function aggregateTimeSheetData(timeSheetData: TimeSheetDataTableProps[]): TimeSheetDataTableProps[] {
    const aggregatedData: Record<string, { duration: number, user: User }> = {};

    timeSheetData.forEach((row: TimeSheetDataTableProps) => {

        //Create a unique key for the row
        const key = Object.keys(row)
            .filter((field) => field !== 'duration' && field !== 'user' && field !== 'title' && field !== 'items')
            .map((field) => `${field}:${row[field]}`)
            .join(',');

        aggregatedData[key] = aggregatedData[key] || { duration: undefined, user: undefined };

        //Add the duration to the aggregated data
        if (aggregatedData[key].duration === undefined) {
            aggregatedData[key].duration = row.duration || 0;
        } else {
            aggregatedData[key].duration += row.duration || 0;
        }

        //Add the user to the aggregated data
        if (aggregatedData[key].user === undefined) {
            aggregatedData[key].user = row.user as User;
        }

    });

    //Convert the aggregated data to an array of TimeSheetDataTableProps
    const resultAgreggated: TimeSheetDataTableProps[] = Object.keys(aggregatedData).map((key) => {
        const fieldsToSplit = key.split(',');
        const resultRow: TimeSheetDataTableProps = { duration: aggregatedData[key].duration, user: aggregatedData[key].user as User };

        //Create the TimeSheetDataTableProps with the fields
        fieldsToSplit
            .filter((field) => field !== 'duration' && field !== 'user' && field !== 'title' && field !== 'items')
            .forEach((field) => {
                const [nameColumn, value] = field.split(':');
                resultRow[nameColumn] = value;
            });

        return resultRow;
    });

    return resultAgreggated;
}

export default function getTimeSheetByDay(cards: Card[]): TimeSheetDataTableProps[] {

    let dataTable: TimeSheetDataTableProps[] = [];

    if (cards !== undefined && cards.length > 0) { //If there are cards        

        for (let index = 0; index < cards.length; index++) { //Run through the cards
            const card = cards[index];

            //Get the values
            if (card.time_trackings !== undefined && card.time_trackings.length > 0) {

                //Get all unique days from time trackings
                function extractUniqueDay(timeTrackingItems: TimeTracking[]) {
                    const uniqueDays = timeTrackingItems.reduce((userDaySet, currentItem) => {

                        //Get the timeTracking date without the time
                        const date = new Date(currentItem.dt_start);
                        date.setHours(0, 0, 0, 0);

                        //Just get the if the card is parent
                        if (date !== undefined && date !== null) {
                            userDaySet.add(date.toLocaleDateString());
                        }
                        return userDaySet;
                    }, new Set<string>());

                    return Array.from(uniqueDays);
                }

                const uniqueDays: string[] = extractUniqueDay(card.time_trackings);

                for (let idxC = 0; idxC < uniqueDays.length; idxC++) {
                    const day = uniqueDays[idxC];

                    //Create the row
                    let row: TimeSheetDataTableProps = {};
                    let sumDuration = 0

                    //Get the time trackings from this day
                    const timeTrackings = card.time_trackings.filter(timeTracking => {
                        const date = new Date(timeTracking.dt_start);
                        date.setHours(0, 0, 0, 0);
                        return date.toLocaleDateString() === day;
                    });

                    //Get the sum of the duration time trackings from this card
                    sumDuration = timeTrackings.reduce((acc, timeTracking) => {

                        const duration = timeTracking.duration !== undefined ? timeTracking.duration : 0;

                        return acc + duration

                    }, 0);

                    row.duration = sumDuration;

                    //Set user informations
                    if (timeTrackings !== undefined && timeTrackings.length > 0) {
                        row['date'] = day;
                    }

                    //Add the row on dataTable - If there are more than one user, add the more than one row                        
                    dataTable.push(row);

                }

            }


        }

    }

    //Aggregate the data
    let newDataTable = aggregateTimeSheetData(dataTable);

    //Reorder the items by the date, higher to lower
    newDataTable = newDataTable.sort((a, b) => {
        if (a.date && b.date) {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
        }
        return 0;
    });

    return newDataTable;

}