import React from "react";

import {
    Container, ContainerImage, ContainerSubTitle, ContainerTitle
} from "./style";

import emptyActivities from '../../../assets/empty-state/empty-activities.png';

interface EmptyActivitiesProps {

}

const EmptyActivities: React.FC<EmptyActivitiesProps> = () => {

    return (
        <Container>
            <ContainerImage>
                <img src={emptyActivities} alt="Sem Atividades" />
            </ContainerImage>
            <ContainerTitle>
                <h1>Entenda o que está acontecendo no seu fluxo, saiba no que cada um está trabalhando!</h1>
            </ContainerTitle>
            <ContainerSubTitle>
                <span>Aqui você poderá ver tudo que está sendo atualizado no fluxo. Visualize uma timeline de atualização, bem como um ranking com os maiores colaboradores do fluxo e o volumes de atualizações da semana. Insira um cartão agora mesmo e começe!</span>
            </ContainerSubTitle>
        </Container>
    );

}

export default EmptyActivities;