import { Box } from "@material-ui/core";
import styled, { css, keyframes } from "styled-components";

interface DetailContainerTypeProps {
    active?: boolean;
}

interface ContainerProps {
    status?: number;
    open?: boolean;
}

interface DetailComplementItemProps {
    active?: boolean;
}

const pulse = keyframes`
    0% {
        transform: scale(1); 
    }
    50% {
        transform: scale(1.03); 
    }
    100% {
        transform: scale(1); 
    }
`;

export const Container = styled.div<ContainerProps>`
    background-color: #4d4d4d1e;
    border-radius: 5px;
    height: 30px;
    min-width: 30px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    cursor: pointer;
    padding-right: 5px;

    ${(props) =>
        props.status === 1 && //Running
        css`
            background-color: #60bd4e;
            color: white;
            animation: ${pulse} 1.5s infinite ease-in-out;
    `}

    ${(props) =>
        props.open && //Running
        css`
            animation: none;
    `}
    
    :hover {
        animation: none;
    }

    svg {
        transition: transform 0.3s ease;

        :hover {
            transform: scale(1.1);
        }
    }
`;

export const TimeValue = styled.div`
    margin-left: 10px;
    font-size: 14px;
`;

export const ButtonDetailsContainer = styled.div`
    margin-left: 10px;
    border-left: 1px solid gray;
    padding-left: 5px;
    justify-content: center;
    display: flex;
    align-items: center;
`;

export const BoxContainer = styled(Box)`
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
    min-width: 250px;
    border-radius: 5px;
    padding: 10px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 35px;
    color: #4d4d4d;
`;

export const BoxItemsContainer = styled.div`
    //overflow-y: auto;
`;

export const DetailContainerHead = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;    
`;

export const DetailContainerType = styled.div<DetailContainerTypeProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 10px 10px;
    margin: 5px;
    margin-top: 0px;
    margin-bottom: 10px;
    min-width: 110px;

    :hover {
        background-color: #893ce8;
        color: white;
        border-radius: 10px;
        font-weight: 500;
    }

    svg {
        margin-right: 10px;
    }

    ${(props) =>
        props.active && //Caso tenha data settada
        css`
            color: #893ce8;
            font-weight: 500;
  `}
`;

export const DetailContainerSeparator = styled.div`
    border-right: 1px solid #eef2f8 !important;
    height: 25px;
    width: 1px;
`;

export const DetailContainerTracking = styled.div`
    border-top: 1px solid #eef2f8 !important;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const DetailComplementsContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;

export const DetailComplementItem = styled.div<DetailComplementItemProps>`
    padding: 10px 15px;
    background-color: #893ce81e;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #893ce8;
    margin-right: 10px;
    border: 1px dashed #893ce8;

    svg {
        font-size: 12px;
    }


    ${(props) =>
        props.active &&
        css`
            border: 1px solid #893ce8;
            color: white;
            background-color: #893ce8;            
    `}
`;

export const DetailTimerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    font-weight: 500;

    svg {
        font-size: 38px;
        margin-right: 15px;
        color: #893ce8;
    }
`;

export const DetailManualContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
`;

export const ManualInputDuration = styled.input`
    border: none;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 80%;

    ::placeholder {
        padding: 5px;
        padding-left: 0px;
        color: #4d4d4d;
        font-size: 13px;
    }
`;

export const ManualInputDurationContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 34px;
    border-radius: 5px;
    border: solid 1px #808080bf;
    width: 100%;
    max-width: 400px;
    color: #4d4d4d;
    font-weight: 600;
    margin-bottom: 10px;

    svg {
        font-size: 17px;
        margin-top: 1px;
        margin-left: 9px;
        margin-right: 5px;
    }
`;

export const ManualInputDurationAutoComplete = styled.div`
    background: white;
    z-index: 999;
    position: absolute;
    top: 40px;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    border-radius: 5px;
    padding: 9px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    left: 27px;
    color: black;

    label {
        cursor: pointer;
    }

    :hover {
        color: #893ce8;
    }
`;

export const DateContainer = styled.div`
    margin-top: 5px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 34px;
    border-radius: 5px;
    border: solid 1px #808080bf;
    width: 100%;
    max-width: 400px;
    color: #4d4d4d;
    font-weight: 600;

    svg {
        font-size: 17px;
        margin-top: 1px;
        margin-left: 9px;
        margin-right: 5px;
    }

    .react-datepicker__input-container {
        width: 100%;
    
        input {
            border: none;
            font-size: 14px;
            padding: 5px;
            color: black;
            width: 100%;

            ::placeholder {
                padding: 5px;
                padding-left: 0px;
                color: #4d4d4d;
                font-size: 13px;
            }
        }
    }

    .react-datepicker-popper {
        box-shadow: 0 0 6px rgb(0 0 0 / 20%);
        border-radius: 5px;
        padding: 10px;
        background-color: white;
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker__header {
        border: none;
    }
    
    .react-datepicker__time-container {
        border-left: 1px solid #eef2f8;
    }

    .react-datepicker__day-name {
        font-weight: 600;
    }

    .react-datepicker__day--outside-month {
        color: gray;
    }

    .react-datepicker__day--today {
        border-radius: 0.3rem;
        background-color: #ffecef;
        color: black;
    }

    .react-datepicker-time__caption {
        display: none;
    }

    .react-datepicker-time__input-container {
        border: 1px solid gray;
        border-radius: 5px;
        padding: 5px;
    }

    .react-datepicker__input-time-container {
        margin-left: 5px;
    }

    .react-datepicker__day--selected {
        background-color: #f23b5d;
    }

    .react-datepicker__time-list-item--selected {
        background-color: #f23b5d !important;
    }

    .react-datepicker__time-list-item--selected:hover {
        background-color: #f23b5d !important;
    }

    .react-datepicker__day--selected {
        background-color: #ffecef;
        color: #000;
    }

    .react-datepicker__time-list-item {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .react-datepicker-time__header {
        font-weight: 500;
        font-size: 12.8px;
    }

    .react-datepicker__day-names {
        margin-top: 10px;
    }

    .react-datepicker__current-month::first-letter {
        text-transform: uppercase;
    }
`;

export const ManualIntervalContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const ManualInputStartIntervalContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 34px;
    border-radius: 5px;
    border: solid 1px #808080bf;
    width: 45%;
    max-width: 400px;
    color: #4d4d4d;
    font-weight: 600;
    margin-bottom: 10px;

    svg {
        font-size: 17px;
        margin-top: 1px;
        margin-left: 9px;
        margin-right: 5px;
    }
`;

export const ManualIntervalDuration = styled.input`
    border: none;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 80%;

    ::placeholder {
        padding: 5px;
        padding-left: 0px;
        color: #4d4d4d;
        font-size: 13px;
    }
`;

export const ManualIntervalDivider = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 10%;
    height: 30px;
    margin-bottom: 10px;
`;

export const ManualInputEndIntervalContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 34px;
    border-radius: 5px;
    border: solid 1px #808080bf;
    width: 45%;
    max-width: 400px;
    color: #4d4d4d;
    font-weight: 600;
    margin-bottom: 10px;

    svg {
        font-size: 17px;
        margin-top: 1px;
        margin-left: 9px;
        margin-right: 5px;
    }
`;

export const DetailIntervalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
`;

export const DetailContainerTrackingBottom = styled.div`
    border-top: 1px solid #eef2f8 !important;
    padding: 10px;
    padding-bottom: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
`;

export const BtnTrackingSave = styled.button`
    border: none;
    background: transparent;
    font-size: 14px;
    color: #893de8;
    transition: transform 0.3s ease;

    :hover {
        transform: scale(1.1);
        font-weight: 500;
    }
`;

export const TracksByUserContainer = styled.div`
    margin-top: 10px;
    border-top: 1px solid #eef2f8;
`;

export const TrackByUserItem = styled.div`
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    :hover {
        background-color: #f5f5f5;
        border-radius: 10px;
    }
`;

export const TrackByUserItemTitle = styled.div`
    margin-left: 10px;
    font-size: 14px;
    width: calc(70% - 15px);
`;

export const TrackByUserItemDescription = styled.div`
    width: calc(30% - 15px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
`;

export const TotalTimeInfo = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    padding: 10px;
    margin-top: 10px;

    span {
        font-style: normal;
        margin-left: 10px;
        color: gray;
    }
`;

export const TrackByUserDetailContainer = styled.div`
    border-top: 1px solid #eef2f8;
    margin: 5px;
`;

export const TrackByUserDetailItem = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    padding: 5px;
    height: 35px;
    border-bottom: 1px solid #eef2f8;
`;

export const TrackByUserDetailItemTime = styled.div`
    color: black;
    margin-right: 5px;
`;

export const TrackByUserDetailItemDate = styled.div`
    font-size: 13px;
    margin-right: 10px;
`;

export const TrackByUserDetailItemStep = styled.div`
    font-size: 12px;
    font-style: italic;
    color: gray;
`;

export const TrackByUserDetailItemAction = styled.div`
    position: absolute;
`;






