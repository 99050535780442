import React, { useCallback, useEffect, useRef } from "react";

import {
    Content,
    ContentBody,
    DialogTop,
    DialogTopLeft,
    DialogTopCenter,
    DialogTopRight,
    IconHeader
} from "./style";

import { Dialog } from '@material-ui/core';
import { AiOutlineClose, AiOutlineSetting } from "react-icons/ai";
import { FieldProps, Fields } from "../../components/Forms/Fields/FieldBuilder";
import FormBuilder from "../../components/Forms/FormBuilder";
import { FormHandles } from "@unform/core";
import api from "../../services/api";
import { useToast } from "../../hooks/toast";

const fieldsForm: FieldProps[] = [
    {
        name: "email_type",
        type: Fields.CHECK_BOX_ONE_FIELD,
        index: 0,
        title: "Criação de cartões automáticos",
        description: "Quando ativo, ao receber um e-mail no endereço do fluxo, o Cange irá criar um novo cartão automaticamente.",
        placeholder: "Criar cartões automaticamente",
    }
]

interface ConfigEmailFormProps {
    email_type: string;
}

interface ConfigEmailProps {
    open: boolean;
    onClose: () => void;
    flow_id: number;
}

const ConfigEmail: React.FC<ConfigEmailProps> = ({ onClose, open, flow_id }) => {

    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    const handleSavePage = useCallback(async () => {

        if (formRef.current !== null) {

            //Normaliza data and call the api
            const dataNormalized = formRef.current.getData() as unknown as ConfigEmailFormProps;

            await api
                .post('/flow', {
                    id_flow: flow_id,
                    email_type: dataNormalized.email_type,
                })
                .then(response => {
                    addToast({
                        type: 'success',
                        title: 'Configurações atualizadas!',
                        description: 'As configurações foram atualizadas com sucesso!',
                    });
                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar as configurações!',
                        description: 'Ocorreu um erro ao salvar as configurações do email!',
                    });
                });

        }

    }, [addToast, flow_id]);

    const handleSubmitForm = useCallback(async (data: object[]) => {

        await handleSavePage();

    }, [handleSavePage]);

    const handleSubmitPage = useCallback(async () => {
        if (formRef.current !== undefined) {
            formRef.current?.submitForm();
        }
    }, []);

    useEffect(() => {

        if (open) {

            api.get(`/flow`, {
                params: {
                    id_flow: flow_id
                }
            }).then(response => {

                const apiReturn: ConfigEmailFormProps = {
                    email_type: response.data.email_type !== null ? response.data.email_type : "0"
                }

                if (formRef.current !== null) {
                    formRef.current.setData(apiReturn);
                }

            }).catch(error => {
            });

        }

    }, [addToast, flow_id, open]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={"gray"}>
                        <div>
                            <AiOutlineSetting />
                        </div>
                    </IconHeader>
                    <h1>
                        {"Configuração de Email"}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>

            <Content>
                <ContentBody container>

                    <FormBuilder
                        id="form"
                        formRef={formRef}
                        fields={fieldsForm}
                        handleSubmit={handleSubmitForm}
                        handleBlur={() => handleSubmitPage()}
                    />

                </ContentBody>
            </Content>
        </Dialog >
    );

}

export default ConfigEmail;