import styled from "styled-components";

export const Container = styled.div`
    padding: 0px !important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    align-content: flex-start;
    overflow-x: auto;
`;

export const NewColumn = styled.div`
    display: flex;
    width: 300px;
    min-width: 300px;
    justify-content: start;
    align-content: center;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    background-color: white;
    margin-bottom: 10px;
    height: 70px;
    max-height: 70px;
    padding-right: 15px;
    padding-left: 15px;
    border: 2px dashed #f23c5d;
    background-color: transparent;
`;

export const StepContainerEmpty = styled.button`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 10px;
    border-color: transparent;
    background-color: transparent;
    min-width: 300px;
    height: 50px;

    :hover {
        div {
            background-color: #f23c5d;
            border-color: white;

            h2 {
                color: #f23c5d;
            }
            
            svg {
                color: white;
            }
        }
    }
`;

export const StepIconContainerEmpty = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    margin-right: 10px;
    border: 1px dashed #4681b8;
    border-radius: 15px;

    svg {
        color: #4681b8;
        font-size: 18px;
        width: 18px;
        height: 18px;
    }

`;

export const StepDataEmpty = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: transparent !important;

    h2 {
        color: #4681b8;
        font-size: 15px;
        text-align: center;
        height: auto;
        text-align: start;
    }
`;

export const ContainerEmpty = styled.div`
    height: 100%;
    width: 100%;
`;