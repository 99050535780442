import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;    
    justify-content: center;
    background-color: #f6f8f9;

    background: linear-gradient( 45deg ,rgba(255,140,47,1) 25%,rgba(251,50,92,1) 50%,rgba(147,55,237,1) 75%);

    img {
        max-height: 50px;
        margin-bottom: 50px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);

    width: 100%;
    max-width: 550px;
    background-color: white;


    img {
        margin-bottom: 35px;        
        height: 72px;
        width: 370px;
    }

`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    } 
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const troubleAnimation = keyframes` 
    0% {
        transform: rotate(1deg);
        transform: rotate(3deg);
    }
    25% {
        transform: rotate(-1deg);
        transform: rotate(-3deg);
    }
    50% {
        transform: rotate(1deg);
        transform: rotate(3deg);
    }
    75% {
        transform: rotate(-1deg);
        transform: rotate(-3deg);
    }
    100% {
        transform: rotate(1deg);
        transform: rotate(3deg);
    }
`

export const AnimationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: ${appearFromLeft} 1s;

    form {
        margin: 80px 0;
        min-width: 400px;
        max-width: 450px;
        text-align: center;

        h1 {
            color: black;
            margin-bottom: 35px;
        }    

        h3 {
            margin-bottom: 30px;
            font-weight: 400;
            font-size: 15px;
        }

        button {
            margin-top: 40px;
            background-color: rgba(251,50,92,1);

            :hover {
                background: linear-gradient( 90deg ,rgba(255,140,47,1) 25%,rgba(251,50,92,1) 50%,rgba(147,55,237,1) 75%);
            }
        }

        img {
            width: auto;
        }
    }
`;

export const NoAccount = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 550px;

    padding-top: 35px;
    color: #6f7782;
    font-size: 17px;

`;

export const BackGround = styled.div`
    flex: 1;
    background-color: #f6f8f9;
    background-size: cover;
`;

export const ContainerSucess = styled.div`
    margin: 80px 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 400px;
    max-width: 450px;

    animation: ${appearFromLeft} 1s;

    h1 {
        color: black;
        margin-bottom: 30px;
    }

    img {
        margin-bottom: 30px;
        max-height: 150px;
        height: auto;
        width: auto;

        animation: ${troubleAnimation} 2s;
        animation-iteration-count: infinite;
    }

    h3 {
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 15px;
    }
`;