import React from "react";

import {
    Container,
    UpgradeButton,
    UpgradeButtonContainer,
    UpgradeMessageContainer
} from "./style";
import ChoosePlan from "../../../dialogs/Billing/ChoosePlan";
import { useAuth } from "../../../hooks/auth";

interface BadgeUpgradeProps {
    title: string;
    description?: string;
    buttonText: string;
}

const BadgeUpgrade: React.FC<BadgeUpgradeProps> = ({ title, description, buttonText }) => {

    const { renewUser } = useAuth();

    const [openBilling, setOpenBilling] = React.useState(false);

    return (
        <Container>

            <UpgradeMessageContainer>
                <h1>
                    {title}
                </h1>
                <span>
                    {description}
                </span>
            </UpgradeMessageContainer>

            <UpgradeButtonContainer>
                <UpgradeButton type="button" onClick={() => setOpenBilling(true)}>
                    {buttonText}
                </UpgradeButton>
            </UpgradeButtonContainer>

            <ChoosePlan
                open={openBilling}
                onClose={() => {
                    renewUser();
                    setOpenBilling(false);
                }}
                isEndTrial={false}
            />

        </Container >
    );

}

export default BadgeUpgrade;