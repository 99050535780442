import React, { ReactElement } from "react";
import { StepType } from "@reactour/tour";

import { Container, Description, HighlightTip, Title } from "./styles";

export const stepsOnboardingFlow: StepType[] = [
  {
    selector: '#root',
    content: (): ReactElement => (
      <Container>
        <Title>Entenda o seu fluxo 💡</Title>
        <Description>Este é o <b>template</b> que você escolheu.</Description>
        <Description>O trabalho e as demandas são controlados por fluxos aqui no <b>Cange</b> e rapidamente iremos lhe apresentar como eles funcionam.</Description>
        <Description>Depois de entender, fique a vontade para customizar ele da forma que <b>atenda melhor o seu negócio</b> ou criar um novo fluxo do zero.</Description>
      </Container>
    )
  },
  {
    selector: '.column-container',
    content: (): ReactElement => (
      <Container>
        <Title>Suas etapas &#x1F389;</Title>
        <Description>Esta é a <b>primeira etapa do seu fluxo</b>. Defina as etapas necessárias para atender o seu processo, elas podem ser criadas de acordo com a necessidade de alguém executar alguma tarefa ou inserir alguma informação no fluxo. Você pode a qualquer momento editar e inserir novas etapas nos seus fluxos.</Description>
        <HighlightTip>Para editar e criar novas etapas clique no ícone ⚙ no canto superior direito do fluxo</HighlightTip>
        <HighlightTip>Exemplo de etapas: A fazer, Executando, Concluído, Arquivado</HighlightTip>
      </Container>
    )
  },
  {
    selector: '.card-task',
    bypassElem: true,
    disableActions: false,
    content: (): ReactElement => (
      <Container>
        <Title>Seus cartões, suas informações &#x1F609;</Title>
        <Description>Com seus cartões, requisições, pedidos - ou como preferir chamar - você <b>pode administrar da forma que quiser</b>, adicionando campos customizados, inserindo responsáveis, datas de vencimento, etiquetas, colaborando com o seu time e transferindo eles de etapa. <b>Tudo isso a um clique de distância</b>.</Description>
        <HighlightTip>Você pode escolher o que será exibido em cada cartão, escolher o títutlo, destacar campos e criar agregadores. Clicando no ícone ⚙ e depois em "Configurar exibição"</HighlightTip>
      </Container>
    )
  },
  {
    selector: '#root',
    bypassElem: true,
    disableActions: false,
    content: (): ReactElement => (
      <Container>
        <Title>Este é seu cartão &#x1F918;</Title>
        <Description>Aqui estão todas a informações do cartão, histórico com tudo que aconteceu por aqui, próximos passos e muito mais.</Description>
      </Container>
    ),
    action: async () => {

      const elmts = document.getElementsByClassName("card-task");

      if (elmts !== null && elmts !== undefined) {
        const elm = elmts[0] as HTMLElement;

        if (elm !== null && elm !== undefined) {
          elm.click();
        }
      }

    }
  },
  {
    selector: '#tour-card-hist',
    bypassElem: true,
    disableActions: false,
    content: (): ReactElement => (
      <Container>
        <Title>Histórico do cartão &#x1F9F5;</Title>
        <Description>Aqui está o histórico de <b>tudo que já aconteceu com o seu cartão dentro do seu fluxo</b>. Você pode alterar informações e rastrear o que está acontecendo em cada item.</Description>
        <HighlightTip>Para alterar os campos do "Formulário inicial" clique no botão "+ para customizar o formulário inicial"</HighlightTip>
      </Container>
    ),
  },
  {
    selector: '.current-step-container',
    bypassElem: true,
    disableActions: false,
    content: (): ReactElement => (
      <Container>
        <Title>Etapa atual &#x26A1;</Title>
        <Description>Na etapa atual, você entenderá <b>onde o cartão está e o que deve ser feito</b> para que o fluxo siga em frente. Você pode inserir novos campos customizáveis para armazenar as informações essenciais para o seu negócio, ou ainda, introduzir campos que permitam manter o histórico do que aconteceu, e <b>muito mais</b>.</Description>
        <HighlightTip>Você pode criar novos campos para esta etapa clicando no botão "+ para customizar as etapas do fluxo"</HighlightTip>
        <HighlightTip>Campos obrigatórios na etapa atual serão um requisito para transferir o cartão de etapa</HighlightTip>
      </Container>
    ),
  },
  {
    selector: '.move-to-container',
    bypassElem: true,
    disableActions: false,
    content: (): ReactElement => (
      <Container>
        <Title>Próxima Etapa &#x1F449;</Title>
        <Description>Nesta seção estarão as <b>etapas disponíveis</b> para você mover o seu cartão. Você pode definir campos obrigatórios antes da transferência, e também, configurar o relacionamento entre etapas, estabelecendo o <b>fluxo ideal para o seu processo.</b></Description>
        <HighlightTip>Para configurar o relacionamento entre etapas você deve clicar no botão abaixo "para editar as etapas"</HighlightTip>
      </Container>
    ),
    actionAfter: async () => {

      const elmts = document.getElementsByClassName("btn-card-close");

      if (elmts !== null && elmts !== undefined) {
        const elm = elmts[0] as HTMLElement;
        elm.click();
      }

    }
  },
  {
    selector: '.new-item-btn',
    content: (): ReactElement => (
      <Container>
        <Title>Inserir um novo item &#x1F195;</Title>
        <Description><b>Não perca nenhuma informação</b>, pois este botão é responsável por inserir um novo cartão dentro do fluxo. Clique nele e será aberto o formulário para inserção dos dados, <b>são mais de 20 tipos de campos</b> para você criar tudo do seu jeito! A qualquer momento você pode inserir ou remover campos do seu formulário.</Description>
        <HighlightTip>Você pode customizar o nome deste botão clicando em ⚙, e depois em "Configurações Gerais"</HighlightTip>
        <HighlightTip>Para customizar os campos do formulário clique em "Editar Formulário"</HighlightTip>
      </Container>
    )
  },
  {
    selector: '#root',
    content: (): ReactElement => (
      <Container>
        <Title>Wow!! Finalizamos &#x1F389;</Title>
        <Description>Agora você já conhece o Cange, em poucos passos tentamos lhe explicar como as coisas aqui funcionam.</Description>
        <Description>O nosso propósito é tornar o seu trabalho mais simples. Queremos muito ajudar você e sua empresa. Caso você ainda tenha ficado com dúvidas, entre em contato com a gente no link abaixo, será um prazer lhe atender! &#x1F970;</Description>
      </Container>
    )
  }
]
