import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    DockPanel,
    DockTimer,
    DockTaskTitle,
    DockTaskButton,
} from "./style";
import { TrackingItem, useTracking } from "../../hooks/tracking";
import { FaStopCircle } from "react-icons/fa";
import api from "../../services/api";
import { Link } from "react-router-dom";

interface TrackingDockProps {
    tracking?: TrackingItem;
}

const TrackingDock: React.FC<TrackingDockProps> = ({ tracking }) => {

    const [elapsedTime, setElapsedTime] = useState(0);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const { removeTracking } = useTracking();

    const initCounter = useCallback(() => {

        intervalRef.current = setInterval(() => {
            setElapsedTime((prevTime) => prevTime + 1);
        }, 1000);

    }, []);

    const formatDuration = (timeInSeconds: number): string => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    const stopTimerApi = useCallback(async () => {

        if (tracking !== undefined && tracking.id_time_tracking !== undefined) {

            await api
                .post('/time-tracking/stop', {
                    id_time_tracking: tracking.id_time_tracking
                })
                .then(response => {

                    if (intervalRef.current) {
                        clearInterval(intervalRef.current);
                    }

                    intervalRef.current = null;
                    setElapsedTime(0);

                    removeTracking();

                }).catch(error => {
                    console.log(error);
                });

        } 

    }, [tracking, removeTracking]);

    const stopTimer = useCallback(async () => {

        await stopTimerApi();

    }, [stopTimerApi]);

    useEffect(() => {

        //If there is a running track, set the elapsed time and start the counter
        if (tracking !== undefined && tracking.id_time_tracking !== undefined) {

            const datetimeString = tracking.dt_start;
            const targetDate = new Date(datetimeString).getTime();
            const now = new Date().getTime();
            const differenceInSeconds = Math.floor((now - targetDate) / 1000);

            setElapsedTime(differenceInSeconds);

            if (intervalRef.current === undefined || intervalRef.current === null) {
                initCounter();
            }

        }

    }, [tracking, initCounter]);

    return (
        tracking !== undefined ? (
            <DockPanel>
                <FaStopCircle color="#f23c5d" onClick={() => stopTimer()} />
                <DockTimer>
                    {formatDuration(elapsedTime)}
                </DockTimer>
                <DockTaskTitle>
                    {tracking.title}
                </DockTaskTitle>
                <Link to={"/flow/" + tracking.flow_id + "/card/" + tracking.card_id} style={{ textDecoration: 'none' }}>
                    <DockTaskButton>
                        Ir para o cartão
                    </DockTaskButton>
                </Link>
            </DockPanel >
        ) :
            <></>
    );

}

export default TrackingDock;