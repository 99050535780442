import styled, { css } from "styled-components";

interface ContainerProps {
    color?: string;
    fontSize?: string;
    fontWeight?: string;
    header?: boolean;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.color};
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};

    ${props => props.header && css`
        background-color: #f4f5f7;
        padding: 5px 10px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
    `}
`;
