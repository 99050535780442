import styled, { css, keyframes } from "styled-components";

import Button from "../../components/Button";

interface FlowItemProps {
    color?: string;
}

interface FlowStarProps {
    isStarred?: string;
}

const troubleAnimation = keyframes` 
    0% {
        transform: rotate(1deg);
        transform: rotate(3deg);
    }
    25% {
        transform: rotate(-1deg);
        transform: rotate(-3deg);
    }
    50% {
        transform: rotate(1deg);
        transform: rotate(3deg);
    }
    75% {
        transform: rotate(-1deg);
        transform: rotate(-3deg);
    }
    100% {
        transform: rotate(1deg);
        transform: rotate(3deg);
    }
`

export const ContainerBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    width: 100%;
`;

export const ContainerContent = styled.div`
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 20px; 
`;

/* Container Steps */
export const FlowContainer = styled.div`
    //display: flex;
    align-items: center;
    justify-items: center;

    height: auto;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    margin-top: 15px;
    padding-left: 15px;

    overflow-x: auto;

    display: grid;
    --min-col-width: var(--grid-min-column-width,160px);
    --max-col-width: var(--grid-max-column-width,1fr);
    grid-template-columns: repeat(auto-fill,minmax(var(--min-col-width),var(--max-col-width)));
    grid-gap: var(--gap);
    
`;

export const FlowItem = styled.div<FlowItemProps>`
    display: inline-flex;
    align-items: left;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    grid-column: span 1 / auto;
    cursor: pointer;

    margin: 15px;
    margin-left: 0px;
    height: 190px;
    width: 150px;
    min-width: 145px;
    background-color: #f23b5c1a;
    border-radius: 10px;
    border: 1px dashed transparent;
    padding: 15px;

    &:hover {
        background: ${'#f23b5c'};
        
        h2 {
            color: ${"white"};
        }

        span {
            color: ${"white"};
        }

        svg {
            color: white;
        }

        .flow-star {
            display: flex;
        }

        .flow-config {
            display: flex;
        }
    } 

    h2 {
        font-size: 14px;
        color: black;
    }

    ${props => props.color && css`
        background-color: ${props.color+"1a"};
        
        &:hover {
            background: ${props.color};
        }
    `}
`;

export const FlowItemContainer = styled.div<FlowItemProps>`
    display: inline-flex;
    -webkit-box-align: center;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;

    width: 70px;
    height: 70px;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 10%;

    border-radius: 10px;

    svg {
        color: #f23b5c;
        height: 40px;
        width: 40px;
    }

    ${props => props.color && css`
        svg {
            color: ${props.color};
            
        }
    `}

`;

export const FlowContainerBottom = styled.div`
    height: 100%;
    width: 100%;

    display: contents;
    justify-content: center;
    align-items: center;

    white-space: normal;
    overflow-wrap: anywhere;

    text-align: center;

    span {
        margin-top: 10px;
        color: grey;
        font-size: 12px;
    }

`;

export const FlowNewItem = styled.button`
    display: inline-flex;
    align-items: left;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-column: span 1 / auto;
    cursor: pointer;

    margin: 15px;
    margin-left: 0px;
    height: 190px;
    width: 150px;
    min-width: 145px;
    background-color: white;
    border: 1px dashed #f23b5c;
    border-radius: 10px;
    padding: 15px;

    &:hover {
        background: ${'#f23b5c1a'};
        transform: translateY(-10px);
        
        h2 {
            color: ${"black"};
        }
    } 

    h2 {
        font-size: 14px;
        color: #4d4d4d;
    }
`;

export const FlowNewItemContainer = styled.div`
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;

    width: 70px;
    height: 60px;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 20%;

    background-color: #f23b5c;
    border-radius: 10px;

    svg {
        color: white;
        height: 40px;
        width: 40px;
        padding: 5px;
        margin-top: 5px;
    }

`;

export const FlowNewContainerBottom = styled.div`
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    white-space: normal;
    overflow-wrap: anywhere;

    text-align: center;

`;

export const ButtonUserAdd = styled(Button)`
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 35px;
    margin-bottom: 10px;
    margin-right: 25px !important;
    font-size: 14px;
    font-weight: 400;
    color: #4681b8;
    background-color: white;
    border: 1px dashed #4681b8;
    overflow: hidden;
    width: 120px !important;

    animation: ${troubleAnimation} 2s;
    animation-iteration-count: infinite;
    animation-delay: 3s;

    div {
        margin-top: 2px !important;

        svg {
            width: 18px;
            height: 18px;
            color: #4681b8;
        }
    }

    :hover {

        border: 1px dashed #f23b5c;
        background-color: white;
        color: #f23b5c;

        svg {
            color: #f23b5c;
        }

    }
`;

export const FlowHeader = styled.div`
    display: flex;
    width: 100%;
    min-height: 15px;
`;

export const FlowHeaderLeft = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

`;

export const FlowHeaderCenter = styled.div`
    
    display: none;
    justify-content: flex-start;
    align-items: center;

    svg {
        font-size: 17px;
    }

`;

export const FlowHeaderRigth = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const FlowStar = styled.button<FlowStarProps>`
    display: none;
    border: none;
    background-color: transparent;
    padding: 5px;

    svg {
        color: white;
        font-size: 17px;
    }

    ${props => props.isStarred === "S" && css`
        display: flex;

        svg {
            color: #fe8c2f !important;   
        }
    `}

    :hover {
        svg {
            color: #fe8c2f !important;   
        }
    }
`;

export const FlowConfig = styled.button`
    display: none;
    border: none;
    background-color: transparent;
    padding: 5px;

    svg {
        color: white;
        font-size: 17px;
    }

    :hover {
        svg {
            color: gray;   
        }
    }
`;


export const ContainerHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;

    div {
        margin-bottom: 0px;
    }
`;

export const ContainerHeaderRight = styled.div`
    width: calc(100% - 115px);
    display: flex;
    justify-content: end;
    align-items: center;    
`;

export const BtnGoToPage = styled.button`
    border: none;
    background: transparent;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    color: #f23b5c;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 15px;

    :hover {
        background-color: #f23b5c;
        color: white; 
    }
`;

