import React, { useCallback, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { HiCog, HiCreditCard, HiLogout, HiOutlineOfficeBuilding, HiOutlineUserCircle, HiOutlineUserGroup } from "react-icons/hi";
import { useHistory } from 'react-router-dom';

import InviteUser from "../../../dialogs/InviteUser";
import cangeMiniLogo from '../../../assets/wendata-mini-logo-w.png';
import getAccessControl from "../../../middlewares/AccessControl";
import {
    Body,
    BoxContainer,
    Divider,
    Header,
    HeaderLeft,
    HeaderRight,
    Item,
    ItemIcon,
    TitleSection
} from "./styles";
import { useAuth } from '../../../hooks/auth';
import { MdInsights } from "react-icons/md";

interface UserMenuProps {
    open: boolean;
    closeMenu: () => void;
}

const linkPortalStripe = "https://billing.cange.me/p/login/aEU2bFg814rB67C9AA";

const UserMenu: React.FC<UserMenuProps> = ({ open, closeMenu }) => {

    const { signOut, user } = useAuth();
    const [openInviteUser, setOpenInviteUser] = useState(false);
    const history = useHistory();

    const handleClickAway = () => {
        closeMenu();
    };

    const goToUserConfig = useCallback(() => {
        history.push('/my-configs');
    }, [history]);

    const goToCompanyConfig = useCallback(() => {
        history.push('/company-config');
    }, [history]);

    const goToTimeSheet = useCallback(() => {
        history.push('/insight-panel');
    }, [history]);

    return (
        open ?
            <ClickAwayListener onClickAway={handleClickAway}>
                <BoxContainer>
                    <Header>
                        <HeaderLeft>
                            <img src={cangeMiniLogo} alt="Logo wendata" />
                        </HeaderLeft>
                        <HeaderRight>
                            <h1>{user.name}</h1>
                            <span>{user.email}</span>
                        </HeaderRight>
                    </Header>
                    <Body>
                        <TitleSection>
                            Empresa
                        </TitleSection>
                        <Item active={true} color={"#9e37ed"}>
                            <ItemIcon>
                                <HiOutlineOfficeBuilding />
                            </ItemIcon>
                            <span>{user.company?.name}</span>
                        </Item>

                        {getAccessControl(90, user.type) && (
                            <Item color={"#9e37ed"} onClick={() => getAccessControl(90, user.type) ? goToCompanyConfig() : {}}>
                                <ItemIcon>
                                    <HiCog />
                                </ItemIcon>
                                <span>Painel do Administrador</span>
                            </Item>
                        )}

                        {user.company !== undefined && user.company.stripe_id !== undefined && user.company.stripe_id !== null && user.id_user === user.company.user_master_id ?
                            <a href={linkPortalStripe + "?prefilled_email=" + user.email} rel="noreferrer" target="_blank" style={{ textDecoration: 'none' }}>
                                <Item color={"#9e37ed"}>
                                    <ItemIcon>
                                        <HiCreditCard />
                                    </ItemIcon>
                                    <span>Gerenciar Plano</span>
                                </Item>
                            </a> :
                            <></>
                        }

                        <Divider />

                        <TitleSection>
                            Conta
                        </TitleSection>
                        <Item color={"#fe8c2f"} onClick={() => goToUserConfig()}>
                            <ItemIcon>
                                <HiOutlineUserCircle />
                            </ItemIcon>
                            <span>Perfil</span>
                        </Item>

                        {getAccessControl(1, user.type) ?
                            <>
                                <Item color={"#fe8c2f"} onClick={() => setOpenInviteUser(true)}>
                                    <ItemIcon>
                                        <HiOutlineUserGroup />
                                    </ItemIcon>
                                    <span>Membros</span>
                                </Item>
                                {openInviteUser && (
                                    <InviteUser
                                        key="invite-user-user-menu"
                                        open={openInviteUser}
                                        onClose={() => setOpenInviteUser(false)}
                                        typeUser={user.type}
                                        fullScreen={true}
                                        title="Convide membros para colaborar"
                                    />
                                )}
                            </> : <></>
                        }

                        {getAccessControl(78, user.type) ?
                            <>
                                <Divider />

                                <TitleSection>
                                    Ferramentas Avançadas
                                </TitleSection>
                                <Item color={"#fe8c2f"} onClick={() => goToTimeSheet()}>
                                    <ItemIcon>
                                        <MdInsights />
                                    </ItemIcon>
                                    <span>Painel de Insights</span>
                                </Item>
                            </> :
                            <></>
                        }

                        {/*
                        <Item color={"#fe8c2f"}>
                            <ItemIcon>
                                <FaChartBar />                               
                            </ItemIcon>
                            <span>Insights</span>
                        </Item>
                        <Item color={"#fe8c2f"}>
                            <ItemIcon>
                                <BsGearFill />                               
                            </ItemIcon>
                            <span>Painel do Administrador</span>
                        </Item>
                        */}

                        <Divider />

                        <Item color={"#f23c5d"} onClick={() => signOut()}>
                            <ItemIcon>
                                <HiLogout />
                            </ItemIcon>
                            <span>Sair</span>
                        </Item>
                    </Body>
                </BoxContainer>
            </ClickAwayListener> :
            <></>
    );

}

export default UserMenu;