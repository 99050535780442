import { Container, InputCB, ItemCB, LabelCB, MarginTop, SpanCB } from "./styles";
import React, { InputHTMLAttributes, useEffect, useRef } from "react";

import ErrorDescription from "../../../../ErrorDescription";
import { IconBaseProps } from "react-icons";
import { useField } from "@unform/core";

interface FieldOptionsProps {
  id_field_option?: number;
  field_id?: number;
  value: string;
  label: string;
  order?: string;
  hide?: string;
  old_id_field_option?: number;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  description?: string;
  options?: FieldOptionsProps[];
  onForceBlur?: () => void;
}

const RadioBox: React.FC<InputProps> = ({ name, options, onForceBlur }) => {

  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  if (options === undefined) {
    options = [
      {
        value: "1",
        label: "Item 1"
      }
    ];
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue(refs) {
        const checked = refs.find((ref: HTMLInputElement) => ref.checked);

        return checked ? checked.value : "";
      },
      setValue(refs, value) {
        const item = refs.find((ref: HTMLInputElement) => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
      clearValue: (refs) => {
        return refs.map((i: any) => {
          i.checked = false;
          return i;
        });
      }
    });
  }, [fieldName, registerField]);

  return (
    <MarginTop>
      <Container>
        {options
          .filter((opt) => opt.hide !== "S")
          .sort((a, b) => {
            if (a.order !== undefined && b.order !== undefined) {
              return Number(a.order) - Number(b.order);
            } else {
              return 0;
            }
          })
          .map((option, index) => {

            const random = Math.random() * (100000000 - 1) + 1;

            return (
              <ItemCB key={option.value} onBlur={onForceBlur} onChange={onForceBlur}>
                <InputCB
                  id={option.value + random + option.id_field_option}
                  ref={(elRef) => (inputRefs.current[index] = elRef as HTMLInputElement)}
                  type="radio"
                  name={fieldName}
                  value={option.value}
                  defaultChecked={defaultValue === option.value}
                />
                <LabelCB />
                <SpanCB htmlFor={option.value + random + option.id_field_option}>{option.label}</SpanCB>
              </ItemCB>
            )
          })}
      </Container>
      {error && (<ErrorDescription title={error} />)}
    </MarginTop>
  );
};

export default RadioBox;
