import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import styled, { keyframes } from "styled-components";
import Button from '../../components/Button';
import Input from '../../components/Forms/Fields/Components/Input';
import { Dialog } from '@material-ui/core';

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    } 
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const ExitAnimation = keyframes`
  to {
    transform: translate(100%, 100%);
  }
`;

export const MainDialog = styled(Dialog) <{ isVisible: boolean }>`
    animation: ${({ isVisible }) => (isVisible ? ExitAnimation : 'none')} 2s forwards;

    .MuiBackdrop-root {        
        ${({ isVisible }) => (isVisible ? 'background-color: transparent;' : '')}
    }
`;

export const Content = styled(DialogContent)`
    background-color: white;   
    padding: 30px 40px !important;    
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: flex-start;
    height: auto;
    width: auto;
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const InviteTitle = styled.h1`
    font-weight: 500;
    color: black;
    font-size: 25px;
    margin-bottom: 25px;

    animation: ${appearFromLeft} 1s;
`;

export const InviteDescription = styled.div`
    font-size: 16px;
    line-height: 1.75;    
    letter-spacing: 0.05px;
    color: #515358;

    animation: ${appearFromLeft} 1.5s;
`;

export const InviteContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    form {
        width: 100%;

        .container-input {
           max-width: 430px !important;
           margin-bottom: 10px;
           margin-top: 0px;

           input {
            font-size: 14px;
           }

           .icon-input {
            width: 18px;
           }
        }
    }

    animation: ${appearFromLeft} 3s;
`;

export const InviteContainerTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #000000ba;
`;

export const InviteInput = styled(Input)`

    
`;

export const InviteContainerButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 80px !important;
`;

export const InviteButtonPlusEmail = styled(Button)`
    background-color: transparent !important;
    color: #f23b5c;
    justify-content: flex-start;
    padding-left: 0px;
    font-size: 13px;
    font-weight: 400;
    margin-top: 10px;

    :hover {
        
    }
`;

export const InviteButton = styled(Button)`
    height: 36px;
    width: 250px;
    background: linear-gradient(90deg,rgba(255,140,47,1) 25%,rgba(251,50,92,1) 50%,rgba(147,55,237,1) 75%);

    :hover {
        background: linear-gradient(90deg,rgba(255,140,47,0.7) 25%,rgba(251,50,92,0.7) 50%,rgba(147,55,237,0.7) 75%);
    }
`;

export const StartButton = styled(Button)`
    height: 45px;
    width: 250px;
    background: linear-gradient(90deg, rgb(255, 140, 47) 25%, rgb(251, 50, 92) 50%, rgb(147, 55, 237) 75%);
    box-shadow: 0 4px 34px #4b048133;

    :hover {
        opacity: 0.9;
        background: linear-gradient(90deg, rgb(255, 140, 47) 25%, rgb(251, 50, 92) 50%, rgb(147, 55, 237) 75%);
    }

    @media (max-width: 600px) {
      position: relative;
      width: 100%;
    }
`;

export const ContainerAnimation = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;    
`;

export const ConfettiArea = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        animation: ${appearFromLeft} 1s;        
        color: black;
        font-weight: 500;
        font-family: Roboto, helvetica;    
        text-align: center;
        font-size: 32px;
        margin-bottom: 30px !important;

        @media (max-width: 600px) {
            font-size: 30px;
        }
    }   

    h3 {
        animation: ${appearFromLeft} 1s; 
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        margin-bottom: 40px !important;
    }
`;

export const ButtonsArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;

    @media (max-width: 600px) {
        margin-top: 30px !important;
    }
`;