import lottie from 'lottie-web';
import React, { useEffect, useRef } from "react";

import logo from '../../../assets/wendata-logo.png';
import {
    Animation,
    BtnRefresh,
    Container,
    ContainerDescription,
    ContainerLogo
} from "./style";

const LoaderCange: React.FC = () => {

    const lottieContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../assets/lottie/loader.json')
            })
        }
    })

    return (
        <Container>
            <Animation className="lottieContainer" ref={lottieContainer} />
            <ContainerDescription>
                <div>
                    <h1>Espera aí! Estamos preparando tudo para <b>você ;)</b></h1>
                    <h3>Se estiver demorando muito, <BtnRefresh onClick={() => window.location.reload()}>clique aqui</BtnRefresh>.</h3>
                    <ContainerLogo>
                        <img src={logo} alt="Logo wendata" />
                    </ContainerLogo>
                </div>
            </ContainerDescription>
        </Container>
    );

}

export default LoaderCange;