import React, { useCallback, useEffect, useState } from "react";

import {
    Content,
    ContentBody,
    DialogTop,
    DialogTopLeft,
    DialogTopCenter,
    DialogTopRight,
    IconHeader,
    ContainerButton,
    ContainerButtonIcon,
    ContainerButtonTitle,
    ContainerButtonDescription,
    ContainerButtons,
    FlowItem,
    FlowHeader,
    FlowHeaderLeft,
    FlowHeaderCenter,
    FlowHeaderRigth,
    FlowItemContainer,
    FlowContainerBottom,
    FlowContainer
} from "./style";

import { Dialog } from '@material-ui/core';
import { FaPlus, FaStore } from "react-icons/fa";
import { AiFillLock, AiOutlineClose, AiOutlinePlusSquare } from "react-icons/ai";
import CangeIcon from "../../../components/CangeIcon";
import api from "../../../services/api";
import { Flow } from "../../../interfaces/Flow";
import { useToast } from "../../../hooks/toast";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import { useHistory } from "react-router-dom";
import NewFlow from "../../../dialogs/NewFlow";

function compare(a: Flow, b: Flow) {
    if (a.isStared !== undefined && b.isStared !== undefined) {
        if (a.isStared > b.isStared) {
            return -1;
        }
        if (a.isStared < b.isStared) {
            return 1;
        }
    }
    return 0;
}

interface AddFlowProps {
    open: boolean;
    workspace_id?: number;
    currentFlows?: Flow[];
    onClose: () => void;
}

const AddFlow: React.FC<AddFlowProps> = ({ onClose, workspace_id, open, currentFlows }) => {

    const { addToast } = useToast();
    const history = useHistory();

    const [optionSelected, setOptionSelected] = useState<number>();
    const [openNewFlow, setOpenNewFlow] = useState<boolean>(false);
    const [flows, setFlows] = useState<Flow[]>([]);

    const getMyFlowsApi = useCallback(async () => {

        api.get(`/flow/my-flows`, {}
        ).then(response => {
            if (response.data !== null) {

                let retFlows: Flow[] = response.data;

                //filter to dont bring the current flows
                if (currentFlows !== undefined) {
                    retFlows = retFlows.filter((flow) => {
                        return !currentFlows.some((currentFlow) => currentFlow.id_flow === flow.id_flow);
                    });
                }

                //Order by Favorite
                retFlows.sort(compare);

                setFlows(retFlows);

            }
        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Erro ao carregar os seus Fluxos :(',
                description: 'Ocorreu ao tentar carregar os seus fluxos!',
            });
        });

    }, [addToast, currentFlows]);

    const postFlowWorkspaceApi = useCallback(async (flow_id: number) => {

        if (workspace_id !== undefined) {

            api.post(`/workspace/flow`, {
                workspace_id: workspace_id,
                flow_id: flow_id
            }
            ).then(response => {
                if (response.data !== null) {

                    onClose();
                    addToast({
                        type: 'success',
                        title: 'Woow! Você adicionou um novo fluxo no seu Workspace!',
                        description: 'O Fluxo foi inserido no Workspace com sucesso!',
                    });

                }
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao carregar os seus Fluxos :(',
                    description: 'Ocorreu ao tentar carregar os seus fluxos!',
                });
            });

        }

    }, [addToast, onClose, workspace_id]);

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    const goToTemplateStore = useCallback(() => {
        history.push('/template-store?workspace=' + workspace_id);
    }, [history, workspace_id]);

    useEffect(() => {

        getMyFlowsApi();

        if (open) {
            setOptionSelected(undefined);
        }

    }, [getMyFlowsApi, open])

    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={handleClose}
        >

            {optionSelected === 1 ?
                <DialogTop>
                    <DialogTopLeft>
                        <IconHeader color={"gray"}>
                            <div>
                                <FaPlus />
                            </div>
                        </IconHeader>
                        <h1>
                            {"Adicionar Fluxo"}
                        </h1>
                    </DialogTopLeft>

                    <DialogTopCenter />

                    <DialogTopRight>
                        <button onClick={handleClose}><AiOutlineClose /></button>
                    </DialogTopRight>
                </DialogTop> :
                <></>
            }


            <Content>
                <ContentBody container>

                    {optionSelected === undefined ?
                        <ContainerButtons>

                            <ContainerButton style={{ marginRight: '25px' }} onClick={() => setOptionSelected(1)}>
                                <ContainerButtonIcon>
                                    <CangeIcon />
                                </ContainerButtonIcon>
                                <ContainerButtonTitle>
                                    Fluxo Existente
                                </ContainerButtonTitle>
                                <ContainerButtonDescription>
                                    Adicionar um fluxo já existente
                                </ContainerButtonDescription>
                            </ContainerButton>

                            <ContainerButton style={{ marginRight: '25px' }} onClick={() => goToTemplateStore()}>
                                <ContainerButtonIcon>
                                    <FaStore />
                                </ContainerButtonIcon>
                                <ContainerButtonTitle>
                                    Usar um template
                                </ContainerButtonTitle>
                                <ContainerButtonDescription>
                                    Escolher um template da loja
                                </ContainerButtonDescription>
                            </ContainerButton>

                            <ContainerButton style={{ marginRight: '25px' }} onClick={() => setOpenNewFlow(true)}>
                                <ContainerButtonIcon>
                                    <AiOutlinePlusSquare />
                                </ContainerButtonIcon>
                                <ContainerButtonTitle>
                                    Criar um novo fluxo
                                </ContainerButtonTitle>
                                <ContainerButtonDescription>
                                    Começar com um fluxo novo
                                </ContainerButtonDescription>
                            </ContainerButton>

                        </ContainerButtons> :

                        optionSelected === 1 ?
                            <FlowContainer>
                                {flows !== undefined && flows.map((flow) => {
                                    return (
                                        <FlowItem key={flow.hash} color={flow.color} onClick={() => postFlowWorkspaceApi(flow.id_flow)}>
                                            <FlowHeader>
                                                <FlowHeaderLeft>

                                                </FlowHeaderLeft>
                                                <FlowHeaderCenter title="Fluxo Privado" className="flow-config">
                                                    {flow.isPrivate === "1" ?
                                                        <AiFillLock />
                                                        : <></>}
                                                </FlowHeaderCenter>
                                                <FlowHeaderRigth>

                                                </FlowHeaderRigth>
                                            </FlowHeader>
                                            <div onClick={() => { }} style={{ textAlign: "center", display: "contents" }}>
                                                <FlowItemContainer color={flow.color}>
                                                    <IconPickerItem
                                                        icon={flow.icon ? flow.icon as IconList : 'FaCodeBranch'}
                                                        color={flow.color ? flow.color : "#f23b5c"}
                                                    />
                                                </FlowItemContainer>
                                                <FlowContainerBottom>
                                                    <h2>
                                                        {flow.name}
                                                    </h2>
                                                    {flow?.total_cards !== undefined && flow?.total_cards > 0 ?
                                                        <span>{flow?.total_cards} {flow?.total_cards > 1 ? "Cards" : "Card"}</span> :
                                                        <></>
                                                    }
                                                </FlowContainerBottom>
                                            </div>
                                        </FlowItem>
                                    )
                                })}
                            </FlowContainer> :
                            <></>
                    }

                    {openNewFlow && (
                        <NewFlow
                            open={openNewFlow}
                            workspaceId={workspace_id}
                            onClose={() => setOpenNewFlow(false)}
                        />
                    )}

                </ContentBody>

            </Content>
        </Dialog >
    );

}

export default AddFlow;