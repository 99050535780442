import React from "react";

import { Container } from "./styles";

const DividerField: React.FC = () => {

    return (
        <Container/>
    );
    
}

export default DividerField;