import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, ClickAwayListener } from "@material-ui/core";
import { FaUserAlt } from "react-icons/fa";
import { Props as reacSelectProps } from "react-select";

import api from '../../services/api';
import {
    BoxContainer,
    BoxItemsContainer,
    BoxSearchContainer,
    BoxUserItem,
    BoxUserLeft,
    BoxUserMail,
    BoxUserName,
    BoxUserRigth,
    BoxUserSelected,
    BtnRemoveUser,
    ButtonActionAdd,
    SearchInput
} from "./styles";
import { User } from '../../interfaces/User';
import { useToast } from '../../hooks/toast';
import getAccessControl from '../../middlewares/AccessControl';
import AvatarCange from '../AvatarCange';

interface Props extends reacSelectProps {
    user?: User;
    flow_id?: number;
    id_card?: number;
    typeUser: string;
    forceUpdate: () => void;
}

const ButtonUserAssigned: React.FC<Props> = ({
    user, flow_id, id_card, forceUpdate, typeUser
}) => {

    const { addToast } = useToast();
    const [open, setOpen] = React.useState(false);
    const [searchValue] = React.useState<string>();
    const [users, setUsers] = useState<User[]>();
    const [userSelected, setUserSelected] = useState<User | undefined>(user);
    const [menuPosition, setMenuPosition] = useState({ top: 100, left: 100 });
    const targetElementRef = useRef<HTMLDivElement>(null);
    const [usersFiltered, setUsersFiltered] = useState<User[]>([]);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const handleClick = () => {

        if (users !== undefined) {

            setOpen((prev) => !prev);
            setUsersFiltered(users);

        }

    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const handleSelectUser = useCallback(async (user: User) => {

        setUserSelected(user);

        //Insere no banco o usuário novo
        if (flow_id !== undefined && id_card !== undefined) {
            await api
                .put('/card', {
                    flow_id: flow_id,
                    id_card: id_card,
                    user_id: user.id_user
                })
                .then(response => {

                    setOpen(false);
                    forceUpdate();

                });
        }

    }, [flow_id, id_card, forceUpdate]);

    const handleRemoveUser = useCallback(async () => {

        //Insere no banco o usuário novo
        if (flow_id !== undefined && id_card !== undefined) {
            await api
                .put('/card', {
                    flow_id: flow_id,
                    id_card: id_card,
                    user_id: null
                })
                .then(response => {

                    setUserSelected(undefined);
                    setOpen(false);
                    forceUpdate();

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao remover o usuário',
                        description: 'Ocorreu um erro ao remover o usuário base de dados!',
                    });
                });
        }

    }, [addToast, flow_id, id_card, forceUpdate]);

    const handleFilterUsers = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {

        if (users !== undefined) {

            const searchText = e.currentTarget.value.toLowerCase();

            let usersNew = users;

            const usersName = usersNew.filter((user) => user.name.toLowerCase().includes(searchText));

            setUsersFiltered(usersName);

        }

    }, [users]);

    useEffect(() => {

        if (flow_id !== undefined) {

            api.get(`/user/by-flow`, {
                params: {
                    id_flow: flow_id
                }
            }).then(response => {
                if (response.data !== null) {
                    const respStep: User[] = response.data;

                    setUsers(respStep);

                }
            });

        }

    }, [flow_id]);

    useEffect(() => {

        if (users !== undefined) {

            setUsersFiltered(users);

        }

    }, [users]);

    useEffect(() => {

        setUserSelected(user);

    }, [user])

    useEffect(() => {
        const updateMenuPosition = () => {
            if (targetElementRef.current) {
                const { top, left } = targetElementRef.current.getBoundingClientRect();

                const newTop: number = userSelected !== undefined ? top + 60 : top + 40;
                const newLeft: number = left + -162;

                setMenuPosition({ top: newTop, left: newLeft });
            }
        };

        updateMenuPosition();

        window.addEventListener('resize', updateMenuPosition);

        return () => {
            window.removeEventListener('resize', updateMenuPosition);
        };
    }, [userSelected]);

    useEffect(() => {

        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }

    }, [open]);

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div ref={targetElementRef}>
                    <Box>

                        <ButtonActionAdd
                            type="button"
                            onClick={handleClick}
                            icon={FaUserAlt}
                            color={"#9e37ed1a"}
                            isFilled={userSelected}
                        > Responsável
                        </ButtonActionAdd>


                        {userSelected !== undefined ?
                            <BoxUserSelected>
                                {userSelected.name}
                            </BoxUserSelected> :
                            <></>
                        }
                        {open && getAccessControl(56, typeUser) ? (
                            <BoxContainer style={{ ...menuPosition }}>

                                <BoxSearchContainer>
                                    <SearchInput
                                        placeholder="Buscar usuários"
                                        defaultValue={searchValue}
                                        onChange={handleFilterUsers}
                                        ref={searchInputRef}
                                    >

                                    </SearchInput>
                                </BoxSearchContainer>

                                <BoxItemsContainer>
                                    {usersFiltered.map((user) => {
                                        return (
                                            <BoxUserItem key={user.email} onClick={() => handleSelectUser(user)}>
                                                <BoxUserLeft>
                                                    <AvatarCange user={user} size="30" />
                                                </BoxUserLeft>
                                                <BoxUserRigth>
                                                    <BoxUserName>{user.name}</BoxUserName>
                                                    <BoxUserMail>{user.email}</BoxUserMail>
                                                </BoxUserRigth>
                                            </BoxUserItem>
                                        )
                                    })}
                                </BoxItemsContainer>
                                {userSelected !== undefined ?
                                    <BtnRemoveUser onClick={() => handleRemoveUser()}>
                                        Remover Usuário
                                    </BtnRemoveUser> :
                                    <></>
                                }
                            </BoxContainer>
                        ) : null}
                    </Box>
                </div>
            </ClickAwayListener>
        </>
    );
};
export default ButtonUserAssigned;
