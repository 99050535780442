import { FormAnswerField } from "../interfaces/FormAnswerField";
import { CardItem } from "../interfaces/CardItem";
import { KanbanConfigProps } from "../components/Kanban";
import { getItemComponentIcon } from "../components/Forms/Fields/FieldBuilder";

export default function getCardItems(
    type: number, //1 - HighLigth Fields / 2 - Form Init Fields / 3 - Form All Fields
    arrFieldsAnswer: FormAnswerField[],
    kanban_config?: KanbanConfigProps,
    form_id?: number
): CardItem[] {

    ///GetItems
    let itemsArr: CardItem[] = [];
    if (kanban_config !== undefined && kanban_config.fieldHighlight?.length > 0 && type === 1) { //1 - HighLigth Fields
        for (let index = 0; index < kanban_config.fieldHighlight.length; index++) {
            const fieldHighlightArr = arrFieldsAnswer.filter((fieldAnswer) => fieldAnswer.field_id === kanban_config.fieldHighlight[index].id_field);

            if (fieldHighlightArr !== undefined && fieldHighlightArr.length > 0 && fieldHighlightArr[0].value !== "") {
                let titleItems = "";
                if (fieldHighlightArr.length > 1) {

                    fieldHighlightArr.map((fieldHL, index) => {
                        if (index + 1 === fieldHighlightArr.length) {
                            titleItems += fieldHL.valueString;
                        } else {
                            titleItems += fieldHL.valueString + ", ";
                        }
                        return fieldHL;
                    });
                }

                const newItem: CardItem = {
                    fieldName: kanban_config.fieldHighlight[index].title,
                    Icon: getItemComponentIcon(kanban_config.fieldHighlight[index].type),
                    fieldValue: titleItems !== "" ? titleItems : fieldHighlightArr[0].valueString
                }
                itemsArr.push(newItem);
            }
        }
    } else if (kanban_config !== undefined && kanban_config.fieldFormInit?.length > 0 && type === 2) { // 2 - Form Init Fields
        for (let index = 0; index < kanban_config.fieldFormInit.length; index++) {
            const fieldFormInitArr = arrFieldsAnswer.filter((fieldAnswer) => fieldAnswer.field_id === kanban_config.fieldFormInit[index].id_field);

            if (fieldFormInitArr !== undefined && fieldFormInitArr.length > 0 && fieldFormInitArr[0].value !== "") {
                let titleItems = "";
                if (fieldFormInitArr.length > 1) {

                    fieldFormInitArr.map((fieldHL, index) => {
                        if (index + 1 === fieldFormInitArr.length) {
                            titleItems += fieldHL.valueString;
                        } else {
                            titleItems += fieldHL.valueString + ", ";
                        }
                        return fieldHL;
                    });
                }

                const newItem: CardItem = {
                    fieldName: kanban_config.fieldFormInit[index].title,
                    Icon: getItemComponentIcon(kanban_config.fieldFormInit[index].type),
                    fieldValue: titleItems !== "" ? titleItems : fieldFormInitArr[0].valueString
                }
                itemsArr.push(newItem);
            }
        }
    } else if (kanban_config !== undefined && kanban_config.fieldFormAll?.length > 0 && type === 3 && form_id !== undefined) { // 3 - Form answer normal
        const fieldFormAllAux = kanban_config.fieldFormAll.filter((field_props) => field_props.form_id === form_id);
        
        for (let index = 0; index < fieldFormAllAux.length; index++) {
            const fieldFormAllArr = arrFieldsAnswer.filter((fieldAnswer) => fieldAnswer.field_id === fieldFormAllAux[index].id_field);

            if (fieldFormAllArr !== undefined && fieldFormAllArr.length > 0 && fieldFormAllArr[0].value !== "") {
                let titleItems = "";
                if (fieldFormAllArr.length > 1) {

                    fieldFormAllArr.map((fieldHL, index) => {
                        if (index + 1 === fieldFormAllArr.length) {
                            titleItems += fieldHL.valueString;
                        } else {
                            titleItems += fieldHL.valueString + ", ";
                        }
                        return fieldHL;
                    });
                }

                const newItem: CardItem = {
                    fieldName: fieldFormAllAux[index].title,
                    Icon: getItemComponentIcon(fieldFormAllAux[index].type),
                    fieldValue: titleItems !== "" ? titleItems : fieldFormAllArr[0].valueString
                }
                itemsArr.push(newItem);
            }
        }
    }

    return itemsArr;
}