import 'react-quill/dist/quill.snow.css';
import filesize from "filesize";

import {
    HeaderButtonOpt,
    HeaderFrom,
    HeaderInfo,
    HeaderInput,
    HeaderInputSubject,
    HeaderSubject,
    HeaderTo,
    HeaderSender,
    EmailInfoContainer,
    HeaderSenderAvatar,
    HeaderSenderDate,
    HeaderSenderInformations,
    HeaderSenderName,
    HeaderSenderTo,
    EmailIcon,
    HeaderSenderDetails,
    HeaderIconHelp,
    BodyContainer,
    ContainerEmail,
    ContainerEmailView,
    AttachmentContainer,
    AttachmentTitle,
    HeaderInfoContainer,
    FooterContainer,
    FooterButton,
    ContainerEmailBody,
    Container,
    EmailInfoContainerDialog,
    EmailInfoContainerDialogItem
} from "./style";
import React, { useCallback, useEffect, useState, useRef } from "react";

import { ClickAwayListener } from "@material-ui/core";
import { Email as EmailInterface } from "../../../interfaces/Email";
import ReactQuill from "react-quill";
import Avatar from 'react-avatar';
import getNamesFromFullEmail from '../Util/getNamesFromFullEmail';
import { Flow } from '../../../interfaces/Flow';
import { Attachment } from '../../../interfaces/Attachment';
import Attachments from '../../../components/Colaboration/Attachments';
import { MdArrowDropDownCircle, MdAttachFile } from 'react-icons/md';
import { useAuth } from '../../../hooks/auth';
import { FiAlertCircle, FiHelpCircle, FiMinusCircle } from 'react-icons/fi';
import { Tooltip, TooltipProps } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import api from '../../../services/api';
import * as Yup from 'yup';
import { useToast } from '../../../hooks/toast';
import getNamesFromEmail from '../Util/getNamesFromEmail';
import getFullEmail from '../Util/getFullEmail';
import getAccessControl from '../../../middlewares/AccessControl';
import { FaPaperPlane, FaReply, FaReplyAll, FaShare, FaTrash } from 'react-icons/fa';
import HtmlContent from '../../../components/HtmlContent';
import getBodyEmailToReply from '../Util/getBodyEmailToReply';

interface EmailProps {
    typeUser: string;
    id_card?: number;
    flow?: Flow;
    flowAddress?: string;
    onClose?: () => void;
    email?: EmailInterface;
}

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    "& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom": {
        backgroundColor: "#1F2D32",
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}));

const Email: React.FC<EmailProps> = ({ onClose, email, flow, flowAddress, typeUser, id_card }) => {

    const { user } = useAuth();
    const { addToast } = useToast();

    const inputRefTo = useRef<HTMLInputElement>(null);

    const [onEdit, setOnEdit] = useState<boolean>();
    const [onMailDetail, setOnMailDetail] = useState<boolean>(false);
    const [openCc, setOpenCc] = useState<boolean>(false);
    const [openCco, setOpenCco] = useState<boolean>(false);
    const [uploadedFiles, setUploadedFiles] = useState<Attachment[]>([]);
    const [newEmail, setNewEmail] = useState<EmailInterface>();

    const [bodyNewEmail, setBodyNewEmail] = useState<string>();
    const [fromField, setFromField] = useState<string>('');
    const [fromNameField, setFromNameField] = useState<string>('');
    const [toField, setToField] = useState<string>('');
    const [ccField, setCcField] = useState<string>('');
    const [ccoField, setCcoField] = useState<string>('');
    const [subjectField, setSubjectField] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);

    const handleClose = useCallback(async () => {

        if (onClose !== undefined) {
            onClose();
        }

    }, [onClose]);

    const handleBodyChange = (value: string) => {
        setBodyNewEmail(value);
    };

    const handleSendMail = useCallback(async () => {

        setLoading(true);

        const emailRequiredSchema = Yup.string().email().required();
        const emailOptionalSchema = Yup.string().email().optional();
        const inputRequiredSchema = Yup.string().required();

        let toFullEmail, ccFullEmail, ccoFullEmail;

        try {
            emailRequiredSchema.validateSync(fromField);
        } catch (error) {
            setLoading(false);
            addToast({
                type: 'warning',
                title: 'Verifique o campo "De"! Ele é obrigatório e precisa de um e-mail válido.',
                description: 'Ajuste o campo "De" e tente novamente! :)',
            });
            return
        }

        if (toField.trim() !== "") {
            toFullEmail = getFullEmail(toField);
        } else {
            setLoading(false);
            addToast({
                type: 'warning',
                title: 'Verifique o campo "Para"! Ele é obrigatório e precisa de um e-mail válido.',
                description: 'Ajuste o campo "Para" e tente novamente! :)',
            });
            return;
        }

        //Validate emails
        try {
            if (toFullEmail !== undefined) {
                toFullEmail.forEach((email) => {
                    emailRequiredSchema.validateSync(email.Email);
                });
            }
        } catch (error) {
            setLoading(false);
            addToast({
                type: 'warning',
                title: 'Verifique o campo "Para"! Ele é obrigatório e precisa de um e-mail válido.',
                description: 'Ajuste o campo "Para" e tente novamente! :)',
            });
            return
        }

        if (ccField !== undefined) {
            ccFullEmail = getFullEmail(ccField);
        }

        if (ccoField !== undefined) {
            ccoFullEmail = getFullEmail(ccoField);
        }

        //Validate emails
        try {
            if (ccFullEmail !== undefined) {
                ccFullEmail.forEach((email) => {
                    emailOptionalSchema.validateSync(email.Email);
                });
            }
        } catch (error) {
            setLoading(false);
            addToast({
                type: 'warning',
                title: 'Verifique o campo "Cc"! É necessário que o e-mail seja válido.',
                description: 'Ajuste o campo "Cc" e tente novamente! :)',
            });
            return
        }

        //Validate emails
        try {
            if (ccoFullEmail !== undefined) {
                ccoFullEmail.forEach((email) => {
                    emailOptionalSchema.validateSync(email.Email);
                });
            }
        } catch (error) {
            setLoading(false);
            addToast({
                type: 'warning',
                title: 'Verifique o campo "Cco"! É necessário que o e-mail seja válido.',
                description: 'Ajuste o campo "Cco" e tente novamente! :)',
            });
            return
        }

        try {
            inputRequiredSchema.validateSync(subjectField);
        } catch (error) {
            setLoading(false);
            addToast({
                type: 'warning',
                title: 'Verifique o campo assunto! Ele é obrigatório.',
                description: 'Ajuste o campo assunto e tente novamente! :)',
            });
            return
        }

        try {
            inputRequiredSchema.validateSync(bodyNewEmail);
        } catch (error) {
            setLoading(false);
            addToast({
                type: 'warning',
                title: 'Verifique o conteúdo do seu e-mail, está em branco.',
                description: 'Digite alguma informação no corpo do seu e-mail e tente novamente! :)',
            });
            return
        }

        if (flow !== undefined && flowAddress !== undefined && newEmail !== undefined) {

            api
                .post('/email', {
                    flow_id: flow?.id_flow,
                    card_id: id_card !== undefined && id_card !== null ? id_card : undefined,
                    message_stream: 'outbound',
                    from: flowAddress,
                    from_name: fromNameField !== undefined ? fromNameField : 'Equipe Cange',
                    to: toField,
                    subject: subjectField !== undefined ? subjectField : 'Sem assunto',
                    cc: ccField !== "" && ccField !== null ? ccField : undefined,
                    cco: ccoField !== "" && ccoField !== null ? ccoField : undefined,
                    reply_to: fromField !== flowAddress ? fromField + ";" + flowAddress : flowAddress,
                    body: bodyNewEmail !== undefined ? bodyNewEmail : '',
                })
                .then(response => {
                    addToast({
                        type: 'success',
                        title: 'E-mail enviado!',
                        description: 'Wow! Seu e-mail foi enviado com sucesso! :)',
                    });
                    setLoading(false);
                    setOnEdit(false);
                    handleClose();
                }).catch(error => {
                    console.log(error.response.data);
                    setLoading(false);
                    addToast({
                        type: 'error',
                        title: 'Erro ao enviar o e-mail!',
                        description: 'Ocorreu um erro ao enviar e salvar o e-mail! :(',
                    });
                });

        }


    }, [newEmail, flow, flowAddress, id_card, addToast, handleClose, bodyNewEmail, ccField, ccoField, fromField, fromNameField, subjectField, toField]);

    const handleReplyMail = useCallback(async (action: 'reply' | 'reply-all' | 'foreward') => {

        setOnEdit(true);

        if (flow !== undefined && flowAddress !== undefined) {
            //Set new email
            setNewEmail({
                company_id: flow?.company_id,
                flow_id: flow?.id_flow,
                card_id: id_card,
                message_stream: 'outbound',
                from: flowAddress,
                from_name: user?.name,
                to: action === 'reply-all' || action === 'reply' ? email?.from : "",
                cc: action === 'reply-all' ? email?.cc : undefined,
                cco: action === 'reply-all' ? email?.cco : undefined,
                subject: action === 'foreward' ? "Fwd: " + email?.subject : "Re: " + email?.subject
            })

            //Encapsulate the email to answer
            if (email !== undefined) {
                const newBody = getBodyEmailToReply(email, action);
                setBodyNewEmail(newBody);
            } else {
                setBodyNewEmail("");
            }


            setFromField(flowAddress);
            setFromNameField(user?.name);
            setToField((action === 'reply-all' || action === 'reply') && email && email.from !== undefined ? email?.from : "")
            setCcField(action === 'reply-all' && email && email.cc !== undefined ? email?.cc : "");
            setCcoField(action === 'reply-all' && email && email.cco !== undefined ? email?.cco : "");
            setSubjectField(action === 'foreward' ? "Fwd: " + email?.subject : "Re: " + email?.subject);

            //If reply-all and cc is undefined, open cc
            if (action === 'reply-all' && email && email.cc !== undefined && email.cc !== null && email.cc !== "") {
                setOpenCc(true);
            }

            //If reply-all and cco is undefined, open cco
            if (action === 'reply-all' && email && email.cco !== undefined && email.cc !== null && email.cco !== "") {
                setOpenCco(true);
            }
        }

    }, [email, flow, flowAddress, id_card, user?.name]);

    const handleClickAway = () => {
        setOnMailDetail(false);
    };

    useEffect(() => {

        //Set focus on input
        if (inputRefTo.current) {
            inputRefTo?.current?.focus();
        }

        if (email !== undefined) {
            setOnEdit(false);

            if (email.email_attachments !== undefined) {
                let newAttachments: Attachment[] = [];
                email.email_attachments.forEach((attachment) => {
                    if (attachment.attachment !== undefined) {

                        attachment.attachment.uploaded = true;
                        if (attachment.attachment.blob_size !== undefined) {
                            attachment.attachment.readableSize = filesize(attachment.attachment.blob_size * 1024);
                        }
                        newAttachments.push(attachment.attachment);
                    }
                });

                setUploadedFiles(newAttachments);
            }
        } else {
            setOnEdit(true);

            if (flow !== undefined && flowAddress !== undefined) {

                let newEmail: EmailInterface = {
                    company_id: flow?.company_id,
                    flow_id: flow?.id_flow,
                    message_stream: 'outbound',
                    from: flowAddress,
                    from_name: user?.name,
                }

                setFromNameField(user?.name)
                setFromField(flowAddress);

                setNewEmail(newEmail);

            }
        }

        setOpenCc(false);
        setOpenCco(false);

    }, [email, flow, flowAddress, user?.name]);

    useEffect(() => {

        //When start a new blank email
        if (flow !== undefined && flowAddress !== undefined) {

            let newEmail: EmailInterface = {
                company_id: flow?.company_id,
                flow_id: flow?.id_flow,
                message_stream: 'outbound',
                from: flowAddress,
                from_name: user?.name,
            }

            setFromNameField(user?.name)
            setFromField(flowAddress);

            setNewEmail(newEmail);

        }

    }, [onEdit, flow, flowAddress, user?.name]);

    return (
        <Container>
            <HeaderInfoContainer>

                {onEdit ?
                    <>
                        <HeaderFrom>
                            <HeaderInfo>Nome</HeaderInfo>
                            <HeaderInput value={fromNameField} onChange={(e) => setFromNameField(e.target.value)} maxLength={150} />
                            <DarkTooltip title={
                                <React.Fragment>
                                    <div>Este será o nome apresentado como o remetente do e-mail que você está criando.</div>
                                </React.Fragment>
                            }>
                                <HeaderIconHelp className='from-name-help' style={{ color: 'gray' }}>
                                    <FiHelpCircle />
                                </HeaderIconHelp>
                            </DarkTooltip>
                        </HeaderFrom>
                        <HeaderFrom>
                            <HeaderInfo>De</HeaderInfo>
                            <HeaderInput value={fromField} onChange={(e) => setFromField(e.target.value)} />
                            <DarkTooltip title={
                                <React.Fragment>
                                    <div>Este será somente o email apresentado como o remetente e adicionado no "reply-to". O email utilizado para o envio será o email do fluxo.</div>
                                </React.Fragment>
                            }>
                                <HeaderIconHelp className='from-help' style={{ color: flowAddress !== newEmail?.from ? 'orange' : 'gray' }}>
                                    {flowAddress !== newEmail?.from ? <FiAlertCircle /> : <FiHelpCircle />}
                                </HeaderIconHelp>
                            </DarkTooltip>
                        </HeaderFrom>
                        <HeaderTo>
                            <HeaderInfo>Para</HeaderInfo>
                            <HeaderInput ref={inputRefTo} tabIndex={1} value={toField} onChange={(e) => setToField(e.target.value)} />
                            {!openCc && (<HeaderButtonOpt onClick={() => setOpenCc(true)}>Cc</HeaderButtonOpt>)}
                            {!openCco && (<HeaderButtonOpt onClick={() => setOpenCco(true)}>Cco</HeaderButtonOpt>)}
                        </HeaderTo>
                        {openCc && (
                            <HeaderTo>
                                <HeaderInfo>Cc</HeaderInfo>
                                <HeaderInput value={ccField} onChange={(e) => setCcField(e.target.value)} />
                                <FiMinusCircle title='Remover' style={{ color: 'gray' }} onClick={() => {
                                    setOpenCc(false);
                                    setCcField("");
                                }} />
                            </HeaderTo>
                        )}
                        {openCco && (
                            <HeaderTo>
                                <HeaderInfo>Cco</HeaderInfo>
                                <HeaderInput value={ccoField} onChange={(e) => setCcoField(e.target.value)}></HeaderInput>
                                <FiMinusCircle title='Remover' style={{ color: 'gray' }} onClick={() => {
                                    setOpenCco(false);
                                    setCcoField("");
                                }} />
                            </HeaderTo>
                        )}
                        <HeaderSubject>
                            <HeaderInputSubject tabIndex={2} placeholder="Assunto" value={subjectField} onChange={(e) => setSubjectField(e.target.value)} />
                        </HeaderSubject>
                    </> :
                    <>
                        <HeaderSender>
                            <HeaderSenderAvatar>
                                <Avatar color={'red'} size="50" name={email?.from_name} round="25px" />
                            </HeaderSenderAvatar>
                            <HeaderSenderInformations>
                                <HeaderSenderName>
                                    {email?.emailPostmark?.FromName}
                                    <span>
                                        {email?.from && ("<" + getNamesFromEmail(email.from, flowAddress, flow?.name) + ">")}
                                    </span>
                                </HeaderSenderName>
                                <HeaderSenderTo>
                                    <span>Para</span>
                                    {getNamesFromFullEmail(email?.emailPostmark?.ToFull, flowAddress, flow?.name)}
                                    <EmailInfoContainer onClick={() => setOnMailDetail(true)}>
                                        <MdArrowDropDownCircle />
                                    </EmailInfoContainer>
                                    {onMailDetail && (
                                        <ClickAwayListener onClickAway={handleClickAway}>
                                            <EmailInfoContainerDialog>
                                                <EmailInfoContainerDialogItem>
                                                    <span>De</span>
                                                    {email?.from}
                                                </EmailInfoContainerDialogItem>
                                                <EmailInfoContainerDialogItem>
                                                    <span>Para</span>
                                                    {email?.to}
                                                </EmailInfoContainerDialogItem>
                                                <EmailInfoContainerDialogItem>
                                                    <span>Data</span>
                                                    {email?.date_string_completed}
                                                </EmailInfoContainerDialogItem>
                                                <EmailInfoContainerDialogItem>
                                                    <span>Assunto</span>
                                                    {email?.subject}
                                                </EmailInfoContainerDialogItem>
                                                {email && email.cc !== undefined && email.cc !== null && email.cc !== "" ?
                                                    <EmailInfoContainerDialogItem>
                                                        <span>Cc</span>
                                                        {email?.cc}
                                                    </EmailInfoContainerDialogItem> :
                                                    <></>}
                                                {email && email.cco !== undefined && email.cco !== null && email.cco !== "" ?
                                                    <EmailInfoContainerDialogItem>
                                                        <span>Cco</span>
                                                        {email?.cco}
                                                    </EmailInfoContainerDialogItem> :
                                                    <></>}
                                            </EmailInfoContainerDialog>
                                        </ClickAwayListener>
                                    )}
                                </HeaderSenderTo>
                                {email && email.cc !== undefined && email.cc !== null && email.cc !== "" ?
                                    <HeaderSenderTo>
                                        <span>Cc</span>
                                        {email?.cc}
                                    </HeaderSenderTo> :
                                    <></>
                                }
                                {email && email.cco !== undefined && email.cco !== null && email.cco !== "" ?
                                    <HeaderSenderTo>
                                        <span>Cco</span>
                                        {email?.cco}
                                    </HeaderSenderTo> :
                                    <></>
                                }
                            </HeaderSenderInformations>
                            <HeaderSenderDetails>
                                {email && email.email_attachments !== undefined && email.email_attachments.length > 0 ?
                                    <EmailIcon>
                                        <MdAttachFile />
                                    </EmailIcon> :
                                    <></>
                                }
                                <HeaderSenderDate>
                                    {email?.date_string}
                                </HeaderSenderDate>
                            </HeaderSenderDetails>
                        </HeaderSender>
                    </>
                }
            </HeaderInfoContainer>
            <BodyContainer onEdit={onEdit !== undefined ? onEdit : false}>
                {onEdit ?
                    <ContainerEmail>
                        <ReactQuill
                            theme="snow"
                            value={bodyNewEmail}
                            onChange={handleBodyChange}
                            tabIndex={3}
                        />
                    </ContainerEmail> :
                    <ContainerEmailView hasAttachment={uploadedFiles.length > 0}>
                        <ContainerEmailBody>
                            {email && email.emailPostmark && email.emailPostmark.HtmlBody && (
                                <HtmlContent htmlContent={email?.emailPostmark?.HtmlBody} />
                            )}
                        </ContainerEmailBody>


                        {!!uploadedFiles.length && (
                            <AttachmentContainer>
                                <AttachmentTitle>Anexos</AttachmentTitle>
                                <Attachments typeUser={typeUser} uploadedFiles={uploadedFiles} hideInput={true} />
                            </AttachmentContainer>
                        )}

                    </ContainerEmailView>
                }

            </BodyContainer>

            {getAccessControl(49, typeUser) ?
                <FooterContainer>
                    {onEdit ?
                        <>
                            <FooterButton style={{ marginRight: '10px', color: 'white', backgroundColor: '#9236ed', borderColor: '#9236ed' }} onClick={handleSendMail} isLoading={loading}>
                                <FaPaperPlane />
                                Enviar
                            </FooterButton>
                            <FooterButton isLoading={loading} style={{ maxWidth: '180px' }} onClick={() => {
                                setOnEdit(false);

                                if (email === undefined) {
                                    handleClose();
                                }
                            }}>
                                <FaTrash />
                                Descartar
                            </FooterButton>
                        </> :
                        <>
                            <FooterButton isLoading={loading} style={{ marginRight: '10px' }} onClick={() => handleReplyMail('reply')}>
                                <FaReply />
                                Responder
                            </FooterButton>
                            <FooterButton isLoading={loading} style={{ marginRight: '10px' }} onClick={() => handleReplyMail('reply-all')}>
                                <FaReplyAll />
                                Responder à todos
                            </FooterButton>
                            <FooterButton isLoading={loading} onClick={() => handleReplyMail('foreward')}>
                                <FaShare />
                                Encaminhar
                            </FooterButton>
                        </>
                    }
                </FooterContainer> :
                <></>
            }
        </Container>
    );

}

export default Email;