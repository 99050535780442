import styled from "styled-components";

export const Container = styled.div`

    span {
        color: white;
        font-size: 12px;
    }

`;
