import React, { useCallback, useEffect, useState } from "react";
import { FiMail } from "react-icons/fi";

import api from '../../services/api';
import {
    BadgeInvalidMail,
    BoxContainer,
    BoxSearchContainer,
    BoxUserItem,
    BoxUserLeft,
    BoxUserMail,
    BoxUserName,
    BoxUserRigth,
    Container,
    ContainerNewUser,
    DisclaimerLicense,
    LeftNewUser,
    RightNewUser,
    SearchInput
} from "./styles"
import { User } from "../../interfaces/User";
import { useToast } from '../../hooks/toast';
import { FlowUser } from '../../interfaces/FlowUser';
import { RegisterUser } from '../../interfaces/RegisterUser';
import { WorkspaceUser } from '../../interfaces/WorkspaceUser';
import AvatarCange from '../AvatarCange';
import { getFeatureControlByPlan } from "../../middlewares/FeatureControl";
import { useAuth } from "../../hooks/auth";

interface ComboBoxInviteUserProps {
    handleSelectUser: (user: User) => void;
    handleInviteUser: (email: string) => void;
    removeUsersEnvironment?: User[]
    removeUsersFlow?: FlowUser[];
    removeUsersRegister?: RegisterUser[];
    removeUsersWorkspace?: WorkspaceUser[];
    nameLocal: 'fluxo' | 'cadastro' | 'workspace' | 'ambiente';
}

const ComboBoxInviteUser: React.FC<ComboBoxInviteUserProps> = ({ handleSelectUser, handleInviteUser, nameLocal, removeUsersEnvironment, removeUsersFlow, removeUsersRegister, removeUsersWorkspace }) => {

    const { user } = useAuth();
    const { addToast } = useToast();

    const [open, setOpen] = React.useState(false);
    const [users, setUsers] = React.useState<User[]>();
    const [serachText, setSerachText] = React.useState("");
    const [usersFiltered, setUsersFiltered] = React.useState<User[]>();
    const [inviteNewUser, setInviteNewUser] = useState(false);
    const [invalidMail, setInvalidMail] = useState(false);
    const [alreadyInvited, setAlreadyInvited] = useState(false);    

    const updateData = useCallback(async () => {

        api.get(`/user/by-company`, {
        }).then(response => {
            if (response.data !== null) {
                const respStep: User[] = response.data;

                //Remove the users that are already in the project                
                if (nameLocal === 'ambiente' && removeUsersEnvironment !== undefined) {
                    removeUsersEnvironment.forEach((user: User) => {
                        const index = respStep.findIndex((userApi) => userApi.email === user.email);
                        if (index >= 0) {
                            respStep.splice(index, 1);
                        }
                    });
                } else if (nameLocal === 'fluxo' && removeUsersFlow !== undefined) {
                    removeUsersFlow.forEach((user: FlowUser) => {
                        const index = respStep.findIndex((userApi) => userApi.id_user === user.user_id);
                        if (index >= 0) {
                            respStep.splice(index, 1);
                        }
                    });
                } else if (nameLocal === 'cadastro' && removeUsersRegister !== undefined) {
                    removeUsersRegister.forEach((user: RegisterUser) => {
                        const index = respStep.findIndex((userApi) => userApi.id_user === user.user_id);
                        if (index >= 0) {
                            respStep.splice(index, 1);
                        }
                    });
                } else if (nameLocal === 'workspace' && removeUsersWorkspace !== undefined) {
                    removeUsersWorkspace.forEach((user: WorkspaceUser) => {
                        const index = respStep.findIndex((userApi) => userApi.id_user === user.user_id);
                        if (index >= 0) {
                            respStep.splice(index, 1);
                        }
                    });
                }

                setUsers(respStep);

            }
        });

    }, [nameLocal, removeUsersEnvironment, removeUsersFlow, removeUsersRegister, removeUsersWorkspace]);

    const handleInviteNewUser = useCallback(async (email: string) => {

        if (invalidMail) {
            addToast({
                type: 'error',
                title: 'Erro ao convidar o Usuário',
                description: 'O e-mail do usuário convidado está inválido!',
            });
        } else {
            handleInviteUser(email);
            setSerachText("");
            setOpen(false);
            updateData();
        }



    }, [addToast, handleInviteUser, invalidMail, updateData]);

    const handleAddNewUser = useCallback(async (user: User) => {

        if (user !== undefined && user.type === "S" && (nameLocal === 'cadastro' || nameLocal === 'workspace')) {
            addToast({
                type: 'warning',
                title: 'Este é um usuário solicitante, não é possível inserir em um ' + nameLocal,
                description: 'Usuários solicitantes não podem participar do ' + nameLocal,
            });
        } else if (user !== undefined && user.type === "I" && (nameLocal === 'cadastro')) {
            addToast({
                type: 'warning',
                title: 'Este é um usuário membro individual, não é possível inserir em um ' + nameLocal,
                description: 'Usuários membro individual não podem participar do ' + nameLocal,
            });
        } else {
            setSerachText("");
            setOpen(false);
            handleSelectUser(user);
            updateData();
        }

    }, [handleSelectUser, updateData, addToast, nameLocal]);


    const handleFilterUsers = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {

        const searchText = e.currentTarget.value.toLowerCase().trimStart().trimEnd();
        setSerachText(searchText);
        const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

        if (searchText.trim() !== "") {
            setOpen(true);

            let usersApi = undefined;

            usersApi = users?.filter((user) => user.name.toLowerCase().includes(searchText) || user.email.toLowerCase().includes(searchText));
            setUsersFiltered(usersApi);

            if (emailRegex.test(searchText)) {
                setInvalidMail(false);
            } else {
                setInvalidMail(true);
            }

            //Valid user already is invited
            const findUserEnvironment = removeUsersEnvironment?.find((user) => user.email === searchText);
            const findUserFlow = removeUsersFlow?.find((user) => user.user.email === searchText);
            const findUserRegister = removeUsersRegister?.find((user) => user.user.email === searchText);
            const findUserWorkspace = removeUsersWorkspace?.find((user) => user.user.email === searchText);

            if (findUserEnvironment !== undefined || findUserFlow !== undefined || findUserRegister !== undefined || findUserWorkspace !== undefined) {
                setAlreadyInvited(true);
                setInviteNewUser(false);
            } else {
                setAlreadyInvited(false);

                if (usersApi !== undefined && usersApi.length >= 1) {
                    setInviteNewUser(false);
                } else {
                    setInviteNewUser(true);
                }
            }

        } else {
            setOpen(false);
        }

    }, [users, removeUsersEnvironment, removeUsersFlow, removeUsersRegister, removeUsersWorkspace,]);

    useEffect(() => {

        updateData();

    }, [updateData]);

    return (
        <>
            <Container isErrored={true}>
                <BoxSearchContainer>
                    <SearchInput
                        open={open}
                        placeholder="Insira o nome ou e-mail"
                        onChange={handleFilterUsers}
                        value={serachText}
                    >
                    </SearchInput>
                </BoxSearchContainer>
            </Container>

            {open ? (
                <BoxContainer>

                    {usersFiltered?.map((user) => {
                        return (
                            <BoxUserItem key={user.email} onClick={() => handleAddNewUser(user)}>
                                <BoxUserLeft>
                                    <AvatarCange user={user} size="30" />
                                </BoxUserLeft>
                                <BoxUserRigth>
                                    <BoxUserName>{user.name}</BoxUserName>
                                    <BoxUserMail>{user.email}</BoxUserMail>
                                </BoxUserRigth>
                            </BoxUserItem>
                        );
                    })}

                    {inviteNewUser ? (
                        <ContainerNewUser onClick={() => handleInviteNewUser(serachText)}>
                            <LeftNewUser>
                                <FiMail />
                            </LeftNewUser>
                            <RightNewUser>
                                Convidar o e-mail <b>{serachText}</b> para este {nameLocal}
                                {nameLocal === 'ambiente' && getFeatureControlByPlan(8, user.company) && (
                                    <DisclaimerLicense>Será adicionado automaticamente uma licença para este usuário no seu plano</DisclaimerLicense>
                                )}
                            </RightNewUser>
                            {invalidMail ? (
                                <RightNewUser>
                                    <BadgeInvalidMail>E-mail Inválido</BadgeInvalidMail>
                                </RightNewUser>
                            ) : null}
                        </ContainerNewUser>

                    ) : null}

                    {alreadyInvited ? (
                        <ContainerNewUser>
                            <LeftNewUser>
                                <FiMail />
                            </LeftNewUser>
                            <RightNewUser>
                                Este endereço email já está ativo neste {nameLocal}
                            </RightNewUser>
                        </ContainerNewUser>
                    ) : null}

                </BoxContainer>
            ) : null}
        </>
    );

};
export default ComboBoxInviteUser;
