import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
     * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }

    body {
        background: white;
        color: gray;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
    }

    body, input, button, textarea {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 500;
    }

    button {
        cursor: pointer;
    }

    ::-webkit-scrollbar-track {
        --webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #F5F5F5;
    }

    :hover::-webkit-scrollbar {
        width: 8px;
        height: 8px;   
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        --webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgba(128,128, 128,.3);
    }

`;