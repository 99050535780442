import styled from "styled-components";
import { Dialog as DialogUi } from '@material-ui/core';

export const Dialog = styled(DialogUi)`

    .MuiDialog-paper {
        height: 100%;
    }

`;

export const Content = styled.div`
    height: calc(100% - 80px);
`;

export const DialogTop = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #eef2f8;
    margin-top: 15px;
    width: 100%;
    
`;

export const DialogTopLeft = styled.div`
    width: 65%;
    height: 100%;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: start;
    margin-left: 15px;

    h1 {
        margin-left: 20px;
        font-size: 20px;
        font-weight: 500;
        color: black;
    }
`;

export const DialogTopCenter = styled.div`
    width: 25%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
`;

export const DialogTopRight = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 30px;
        border: none;
        background-color: transparent;
    }
`;

export const IconHeader = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 100%;
    background-color: transparent;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 45px;
        height: 45px;
        background-color: #f4f4f4;
        border-radius: 50%;

        > svg {
            width: 25px;
            height: 25px;
        }

    }
`;

export const BtnEditForm = styled.button`

    display: flex;
    margin-left: 0px !important;
    width: 100% !important;
    background-color: #f23b5c1a !important;
    border-radius: 10px;
    padding: 5px;
    justify-content: center;
    align-content: center;
    border: none;
    justify-items: center;
    align-items: center;

    svg {
      font-size: 11px;
      margin-right: 10px;
      margin-left: 5px;
    }

    h3 {
      font-size: 12px;
      font-weight: 500;
      margin-right: 5px;
    }

`;
