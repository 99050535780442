import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

interface BodyContainerProps {
    alignCenter?: boolean;
}

interface ColorProps {
    color?: string;
    colorBackground?: string;
    colorIcon?: string;
}

interface RightContainerProps {
    color?: string;
}

interface ColumnsHeaderConfigProps {
    color: string;
    isOpen: boolean;
}

export const Container = styled.div`
    display: flex;
    width: 100%;    
    z-index: 1;
`;

export const ContainerBox = styled.div<ColorProps>`
    display: flex;
    width: 100%;
    min-width: 220px;
    min-height: 50px; 
    height: 55px;
    border: 1px solid #f23b5ca8;
    border-radius: 5px;

    ${props => props.colorBackground && props.color && css`
        border: 1px solid ${props.color};

        :hover {
            background-color: ${props.color}1a;
        }
    `}


`;

export const LeftContainer = styled.div<ColorProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    min-width: 55px;
    max-width: 120px !important;
    background-color: #f23b5c1a;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;  
    border-right: 1px solid #f23b5ca8;  

    svg {
        color: #f23b5c;
        width: 20px;
        height: 20px;
    }

    ${props => props.colorBackground && props.color && css`
        background-color: ${props.colorBackground};
        border-right: 1px solid ${props.color}; 

        svg {
            color: ${props.color};
        }
    `}

    ${props => props.colorIcon && css`

        svg {
            color: ${props.colorIcon};
        }
        
    `}
`;

export const BodyContainer = styled.div<BodyContainerProps>`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-top: -2px;

    h1 {
        font-size: 15px;
        color: black; //#f23b5c;
        font-weight: 500; 
        margin-bottom: 2px;

        span {
            margin-left: 10px;
            background-color: #f23b5d;
            color: white;
            font-size: 10px;
            text-transform: uppercase;
            padding: 2px 5px;
            border-radius: 5px;
            font-weight: 500;
        }
    }

    h2 {
        font-size: 12px;
        color: gray;
    }

    ${props => props.alignCenter && css`
        text-align: center !important;
        margin-left: 0px !important;

        h1 {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    `}

`;

export const PositionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    width: auto;
    min-width: 25px;
    left: 0;

    p {
        font-size: 12px;
        font-weight: 400px;
        color: gray;
    }
`;

export const RightContainer = styled.div<RightContainerProps>`
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    position: relative;
    width: auto;
    min-width: 50px;
    z-index: 1;
    left: 0;

    p {
        font-size: 12px;
        font-weight: 400px;
        color: gray;
    }

    .col-hed-conf {
        display: flex;
        flex-direction: column;
        justify-items: center;
        border-radius: 5px;
        margin-right: 10px;
        width: 35px;
        align-items: center;
        height: 30px;
        justify-content: center;
        color: gray;
        cursor: pointer;
        background-color: transparent;        
        border: 1px solid transparent;

        :hover {
            border: 1px solid ${props => props.color};
            background-color: ${props => props.color}1a;
            color: black;
        }
    }

    .col-hed-con-conf {
        min-width: 200px;
        max-width: 200px;
        
    }
`;

export const BoxOver = styled(Box)`
    position: relative;
    background-color: white;
    z-index: 99999999;
    position: absolute;
    top: 0;
    right: 0;
`;

export const DropDownButton = styled.div<ColumnsHeaderConfigProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 15px;
  background-color: transparent;
  z-index: 0;
  position: relative;
  filter: brightness(90%);

  ${(props) =>
        props.color &&
        css`
     color: ${props.color};
    `}

  :hover {
    ${(props) =>
        props.color &&
        css`
     color: white;
     background-color: ${props.color};
      filter: brightness(100%);
    `}
  }

  ${(props) => props.isOpen && props.color && css`
        display: flex;
        background-color: white;
        color: ${props.color};
        filter: brightness(100%);
    `}

  .hover-menu {
      display: flex;
  }
`;

export const DropDownItems = styled.div`
    position: absolute;
    top: -7px;
    left: 3px;
    z-index: 99999999;
    min-width: 300px;
    max-width: 300px;
    width: auto;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 5px;
    margin-right: 10px;
    padding-bottom: 10px;
    border: 1px solid #f4f4f4;
    padding-right: 10px;
    padding-left: 10px;
`;

export const BtnOptions = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
    border-radius: 5px;
    margin-right: 10px;
    width: 35px;
    align-items: center;
    height: 30px;
    justify-content: center;
    color: gray;
    cursor: pointer;

    :hover {
        border: 1px solid gray;
    }
`;

export const ContainerOrder = styled.div`
    display: table-cell;
    height: 50px;
    margin-top: 7px;
    margin-left: 5px;
`;

export const ButtonUp = styled.button`
    display: flex;
    border: none;
    background-color: transparent;
    height: 20px;
    width: 20px;
    margin-bottom: 3px;

    svg {
        height: 20px;
        width: 20px;
    }
`;

export const ButtonDown = styled.button`
    display: flex;
    border: none;
    background-color: transparent;
    height: 20px;
    width: 20px;

    svg {
        height: 20px;
        width: 20px;
    }
`;

