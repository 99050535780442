import styled, { css } from "styled-components";

interface ContainerProps {
    color: string;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    user-select: none;
    font-size: 14px;

    svg {
        font-size: 15px;
        color: #101820;
        margin-right: 10px;
    }

    h3 {
        color: #101820;
        font-weight: 400;        
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px !important;

        span {
            background-color: #f23b5c;
            color: white;
            border-radius: 10px;
            margin-left: 5px;
            padding: 3px 7px;
            font-size: 10px;
            cursor: pointer;
        }
        
    }

    :hover {
        ${(props) => props.color && css`
            background-color: ${props.color+"1e"};
        `}
        
        h3 {
            font-weight: 500;

            ${(props) => props.color && css`
                color: ${props.color};
            `}
        }

        svg {
            ${(props) => props.color && css`
                color: ${props.color};
            `}            
        }
    }
`;
