import React, { useEffect, useRef } from "react";
import DOMPurify from 'dompurify';

import {
    IframeComponent
} from './style';

interface HtmlContentProps {
    htmlContent?: string;
}

const HtmlContent: React.FC<HtmlContentProps> = ({ htmlContent }) => {

    const IFRAME_ID = 'iframe-html-content';
    const IFRAME_CONTAINER_ID = 'iframe-container';

    const [iframeContent, setIframeContent] = React.useState('');
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const sanitizedHTML = DOMPurify.sanitize(htmlContent !== undefined ? htmlContent : '');

    useEffect(() => {

        if (iframeRef.current !== null) {

            const iframeDoc = iframeRef.current.contentDocument;

            if (iframeDoc) {
                iframeDoc.open();
                iframeDoc.write(sanitizedHTML);

                const htmlElement = iframeDoc.querySelector('html');

                if (htmlElement) {
                    htmlElement.style.fontFamily = 'Roboto, sans-serif';
                    htmlElement.style.fontSize = '13px';
                }

                iframeDoc.close();
            }

        }

        setIframeContent(sanitizedHTML);

    }, [sanitizedHTML]);

    return (
        <IframeComponent
            title="email-content"
            id={IFRAME_ID}
            name={IFRAME_CONTAINER_ID}
            ref={iframeRef}
            sandbox="allow-same-origin"
            srcDoc={iframeContent}
            style={{
                border: 'none',
                width: '100%',
                height: '100%',
            }}
        />
    );

}

export default HtmlContent;