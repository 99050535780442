import React, { useCallback } from "react";

import {
    Content,
    ContentBody,
    DialogTop,
    DialogTopLeft,
    DialogTopCenter,
    DialogTopRight,
    IconHeader
} from "./style";

import { Dialog } from '@material-ui/core';
import { AiOutlineClose, AiOutlineHistory } from "react-icons/ai";
import RunHistory from "../RunHistory";
import { Flow } from "../../../interfaces/Flow";


interface RunHistoryDialogProps {
    open: boolean;
    flow: Flow;
    days: number;
    onClose: () => void;
}

const RunHistoryDialog: React.FC<RunHistoryDialogProps> = ({ onClose, open, flow, days }) => {

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);


    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={handleClose}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={"gray"}>
                        <div>
                            <AiOutlineHistory />
                        </div>
                    </IconHeader>
                    <h1>
                        {"Histórico de execução"}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>

            <Content style={{ padding: '0px' }}>
                <ContentBody container>

                    <RunHistory open={open} flow={flow} days={days} />

                </ContentBody>
            </Content>
        </Dialog >
    );

}

export default RunHistoryDialog;