import styled, { css } from "styled-components";
import { Box } from "@material-ui/core";

interface ColumnsHeaderConfigProps {
  color: string;
  isOpen: boolean;
}

export const BoxOver = styled(Box)`

`;

export const Container = styled(Box)`
    //position: absolute;
    position: fixed;
    top: auto;
    left: auto;
    z-index: 999;
    min-width: 300px;
    max-width: 300px;
    width: auto;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 5px;
    margin-right: 10px;
    padding-bottom: 10px;
    border: 1px solid #f4f4f4;
    padding-right: 10px;
    padding-left: 10px;

    @media (max-width: 600px) {
      right: 0px;
    }
`;

export const ColumnsHeaderConfig = styled.div<ColumnsHeaderConfigProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 15px;
  background-color: transparent;
  filter: brightness(90%);

  ${(props) =>
    props.color &&
    css`
     color: ${props.color};
    `}

  :hover {
    ${(props) =>
    props.color &&
    css`
     color: white;
     background-color: ${props.color};
      filter: brightness(100%);
    `}
  }

  ${(props) => props.isOpen && props.color && css`
        display: flex;
        background-color: white;
        color: ${props.color};
        filter: brightness(100%);
    `}

  .hover-menu {
      display: flex;
  }
`;