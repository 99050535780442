import React from "react";

import { Container } from "./styles";

interface TitleFieldProps {
    title: string;
}

const TitleField: React.FC<TitleFieldProps> = ({ title }) => {

    return (
        <Container>
            <h1>{title}</h1>
        </Container>
    );
    
}

export default TitleField;