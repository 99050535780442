import styled, { css } from "styled-components";

interface ElementProps {
    zIndex?: number;
}

export const Container = styled.div`

    .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
    }
    .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
    }
    .react-grid-item img {
    pointer-events: none;
    user-select: none;  
    }
    .react-grid-item.cssTransforms {
    transition-property: transform;
    }
    .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
    }

    .react-grid-item.react-draggable-dragging {
        transition: none;
        z-index: 3;
        will-change: transform;
    }

    .react-grid-item.dropping {
        visibility: hidden;
    }

    .react-grid-item.react-grid-placeholder {
        background: #f23b5c1e;
        border: 1px dashed #f23b5c;
        border-radius: 10px;
        transition-duration: 100ms;
        z-index: 2;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }

    .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    }

    .react-grid-item > .react-resizable-handle::after {
        content: "";
        position: absolute;
        right: 5px;
        bottom: 5px;
        width: 5px;
        height: 5px;
        border-right: 3px solid rgba(0,0,0,0.4);
        border-bottom: 3px solid rgba(0,0,0,0.4);
        border-color: #eef2f6;
        border-radius: 0px;
    }

    .react-resizable-hide > .react-resizable-handle {
    display: none;
    }

    .react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-w,
    .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-n,
    .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
    }
    .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
    }

    margin-top: -10px;
    margin-left: -10px;
    width: calc(100% + 25px);

`;

export const Element = styled.div<ElementProps>`
    border-radius: 8px;
    border: 0.5px solid rgb(238, 242, 246);    
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  
    ${(props) => props.zIndex && css`
        z-index: ${props.zIndex};
    `}

`;

export const ElementContainer = styled.div`
    height: 100%;
    width: 100%;

    :hover {
        .col-hed-conf {
            display: flex;
        }
    }
`;

export const ElementHeader = styled.div`
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(238, 242, 246);
    padding: 7px;
    color: black;
    font-size: 15px;
    font-weight: 500;
    //background-color: #9337ed;
    color: white;
    border-radius: 10px 10px 0px 0px;
`;

export const ElementBody = styled.div`
    height: calc(100% - 40px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`;

export const ElementHeaderDrag = styled.div`
    display: flex;
    margin-right: 5px;
    font-size: 17px;
    color: #4d4d4d;
    cursor: grab;

    :hover {
        color: #f23b5c;
    }
`;

export const ElementHeaderOptions = styled.div`
    position: absolute; 
    right: 15px;
    display: flex;
    font-size: 20px;
    color: gray;
    padding: 3px;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 5px;
    color: #4d4d4d;
    cursor: pointer;

    :hover {
        background-color: #eef2f6;
        color: #f23b5c;
    }
`;

export const ElementHeaderFilter = styled.div`
    position: absolute;    
    display: flex;
    font-size: 20px;
    color: gray;
    padding: 3px;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 5px;
    color: #4d4d4d;

    height: 26px;
    width: 30px;
    right: 45px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    cursor: pointer;

    svg {
        font-size: 11px;
    }
`;

export const InputTitle = styled.input`
    color: black;
    background-color: white;
    font-size: 15px;
    width: calc(100% - 90px);
    font-weight: 500;
    padding: 3px;
    padding-left: 6px;
    border: none;

    :focus {
        border: 1px solid #4d4d4d;
        border-radius: 5px;
        padding: 2px;
        padding-left: 5px;
    }
`;

export const ContainerLoader = styled.div`
    display: block;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 400;
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 50% !important;
    }
    
`;