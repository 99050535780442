import ReactDatePicker from "react-datepicker";
import styled, { css } from "styled-components";

interface ContainerProps {
  isFocused: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background-color: transparent;
  border: 0px;
  padding: 16px;
  width: 100%;
  border-bottom: 1px solid gray;
  color: black;
  display: flex;
  align-items: center;

  & + div {
    margin-top: 30px;
    width: 100%;
  }

  input {
    flex: 1;
    background: transparent;
    border: 0;
    width: 100%;

    ::placeholder {
      color: gray;
    }
  }
  svg {
    margin-right: 16px;
    color: gray;
  }

  ${(props) =>
    props.isErrored &&
    css`
      color: red;
      border-bottom-color: red;
      svg {
        //color: red;
      }
    `}
  ${(props) =>
    props.isFocused &&
    css`
      color: #f23b5c;
      border-bottom-color: #f23b5c;
      svg {
        color: #f23b5c;
      }
    `}   

  .react-datepicker-popper {
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    border-radius: 5px;
    padding: 10px;
    background-color: white;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    border: none;
  }
  
  .react-datepicker__time-container {
    border-left: 1px solid #eef2f8;
  }

  .react-datepicker__day-name {
    font-weight: 600;
  }

  .react-datepicker__day--outside-month {
    color: gray;
  }

  .react-datepicker__day--today {
    border-radius: 0.3rem;
    background-color: #ffecef;
    color: black;
  }

  .react-datepicker-time__caption {
    display: none;
  }

  .react-datepicker-time__input-container {
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px;
  }

  .react-datepicker__input-time-container {
    margin-left: 5px;
  }

  .react-datepicker__day--selected {
    background-color: #f23b5d;
  }

  .react-datepicker__time-list-item--selected {
    background-color: #f23b5d !important;
  }

  .react-datepicker__time-list-item--selected:hover {
    background-color: #f23b5d !important;
  }

  .react-datepicker__day--selected {
    background-color: #ffecef;
    color: #000;
  }

  .react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker-time__header {
    font-weight: 500;
    font-size: 12.8px;
  }

  .react-datepicker__day-names {
    margin-top: 10px;
  }

  .react-datepicker__current-month::first-letter {
    text-transform: uppercase;
  }
`;

export const DatePickerInput = styled(ReactDatePicker)`

`;

export const DatePickerInputInLine = styled(ReactDatePicker)`

`;

export const ContainerInLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .react-datepicker__day--keyboard-selected {
    background-color: white;
    color: black;
  }

  .react-datepicker-popper {
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    border-radius: 5px;
    padding: 10px;
    background-color: white;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    border: none;
  }
  
  .react-datepicker__time-container {
    border-left: 1px solid #eef2f8;
  }

  .react-datepicker__day-name {
    font-weight: 600;
  }

  .react-datepicker__day--outside-month {
    color: gray;
  }

  .react-datepicker__day--today {
    border-radius: 0.3rem;
    background-color: #ffecef;
    color: black;
  }

  .react-datepicker-time__caption {
    display: none;
  }

  .react-datepicker-time__input-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: none;
    border-radius: 5px;
    padding: 5px;
  }

  .react-datepicker__input-time-container {
    margin-left: 0px;
  }

  .react-datepicker__day--selected {
    background-color: #f23b5d;
  }

  .react-datepicker__time-list-item--selected {
    background-color: #f23b5d !important;
  }

  .react-datepicker__time-list-item--selected:hover {
    background-color: #f23b5d !important;
  }

  .react-datepicker__day--selected {
    background-color: #f23b5d;
    color: white;
  }

  .react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker-time__header {
    font-weight: 500;
    font-size: 12.8px;
  }

  .react-datepicker__day-names {
    margin-top: 10px;
  }

  .react-datepicker__current-month::first-letter {
    text-transform: uppercase;
  }

  .react-datepicker-time__input {
      width: 100%;
      margin-left: 0px !important;
  }

  .react-datepicker-time__input input {
    border: 1px solid #eef2f8;
    border-radius: 5px;
    padding: 10px;
    width: 100% !important;
    text-align: center;
  }
`;

export const ContainerCalendarInLine = styled.div`
`;

export const ContainerFastButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px !important;
`;

export const BtnShortCut = styled.div`
  background-color: #ffecef;
  color: gray;
  font-size: 13px;
  padding: 3px 8px;
  border-radius: 5px;
  margin-right: 10px;

  :hover {
    background-color: #f23b5d;
    color: white;
    cursor: pointer;
  }
`;