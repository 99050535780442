import styled, { css } from "styled-components";
import Button from "../../components/Button";
import { Box } from "@material-ui/core";

interface ColumnsHeaderSpanProps {
  color: string;
}

interface ColumnHeaderProps {
  color: string;
}

interface FlowIconProps {
  color?: string;
}

interface TaskResumeItemProps {
  color: string;
}

interface FilterBarTasksButtonLinkProps {
  active: boolean;
}

interface FilterBarTasksButtonClickProps {
  active: boolean;
}

interface ButtonLinkProps {
  active?: boolean;
}

export const ContainerBody = styled.div`
    display: flex;
    height: calc(100% - 105px);
    align-items: baseline;
    justify-content: left;
    margin-top: 10px;
    width: 100%;
`;

export const ContainerContent = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px;
`;

export const Container = styled.div`
    padding: 0px !important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    align-content: flex-start;
    overflow-x: auto;
`;

export const ColumnContainer = styled.div`
  height: 100%;
  margin-right: 10px;
  min-width: 300px;
  max-width: 300px;
`;

export const ColumnsHeader = styled.div<ColumnHeaderProps>`
  display: flex;
  width: 100%;
  justify-content: start;
  align-content: center;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  background-color: white;

  height: 50px;
  max-height: 60px;
  padding-right: 15px;
  padding-left: 15px;

  ${(props) =>
    props.color &&
    css`
      border: 2px solid ${props.color};
      background-color: ${props.color};
    `}
`;

export const ColumnsHeaderIcon = styled.div`
 width: 40px;
  display: flex;
  justify-items: center;
  align-items: center;

  svg {
    display: flex;
    color: #f23b5c;
    margin-right: 20px;
    font-size: 20px;
    color: white;
  }
`;

export const ColumnsHeaderTitle = styled.div`
  width: 160px;
  display: flex;
  justify-items: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: white;
`;

export const ColumnsHeaderSpan = styled.div<ColumnsHeaderSpanProps>`
  //width: 10%;
  display: flex;
  justify-items: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: white;
  font-size: 15px;

  span {
    background-color: #8917e3;
    padding: 7px;
    padding-right: 7px;
    padding-left: 7px;
    border-radius: 15px;

    ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
      filter: brightness(90%);
    `}
  }
`;

export const ColumnsItems = styled.div`
  overflow-y: auto;
  background-color: white;
  height: calc(100% - 55px);
  padding: 15px;
  border-radius: 0px 0px 10px 10px;
`;

export const ColumnDescription = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10px;

  p {
    font-size: 14px;
    color: gray;
    margin-bottom: 5px;
    margin-left: 5px;
    font-weight: 400;
  }

`;

export const FlowContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    margin-bottom: 3px;
    padding: 3px;
    border-radius: 5px;

    span {
        font-size: 14px;
        font-weight: 500;
        color: gray;
    }
`;

export const FlowIcon = styled.div<FlowIconProps>`
    height: 25px;
    width: 25px;
    background-color: #9d37ed1e;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    margin-right: 10px;

    ${(props) =>
    props.color &&
    css`
      background-color: ${props.color + "1e"};
    `}

    div {
        font-size: 15px !important;
        color: rgb(158, 55, 237);
        padding: 2px;
    }

`;

export const ContainerLoader = styled.div`
    display: block;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    float: right;
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 30% !important;
    }
    
`;

export const ColumnsHeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 25%;
`;

export const WidgetTasksResume = styled.div`
  display: flex;
  background-color: #f4f4f4;
  margin-right: 20px;
  padding: 10px 20px;
  justify-content: center;
  border-radius: 15px;
  align-items: center;

  @media(max-width: 800px){
    display: none !important;
  }
`;

export const TaskLabel = styled.div`
  font-size: 14px;
  padding-right: 10px;
  border-right: 1px solid gray;
  border-color: #858585 !important;
`;

export const TaskResumeItem = styled.div<TaskResumeItemProps>`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;

  svg {
    margin-right: 5px;
  }

  ${(props) =>
    props.color &&
    css`

      svg {
        color: ${props.color};
      }
  `}
`;

export const FilterBarTasks = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  background-color: white;
  height: 40px;
  border-radius: 0px 0px 10px 10px;
  padding-left: 15px;
  padding-right: 5px;
  border-top: solid 0.5px grey;
`;

export const FilterBarTasksLeft = styled.div`
  float: left;
  display: flex;
  height: 100%;
  justify-content: left;
  align-items: center;
  width: 100%;  
`;

export const FilterBarTasksRight = styled.div`
  position: absolute;
  right: 0px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const FilterBarTasksButtonLink = styled.button<FilterBarTasksButtonLinkProps>`
    position: relative;
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;

    svg {
        font-size: 16px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
    
    ${props => props.active && css`
        background-color: #60bd4e;
        color: white;
        border-radius: 5px;   
        font-weight: 500;
        
        :hover {
            background-color: #57aa47;
        }
    `}
`;

export const FilterBarTasksButtonClick = styled.button<FilterBarTasksButtonClickProps>`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;

    svg {
        font-size: 13px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
    
    ${props => props.active && css`
        background-color: #60bd4e;
        color: white;
        border-radius: 5px;   
        font-weight: 500;
        
        :hover {
            background-color: #57aa47;
        }
    `}
`;

export const BoxContainer = styled(Box)`
    position: absolute;
    top: 30px;
    left: 0px;
    z-index: 1;
    min-width: 250px;
    border-radius: 5px;
    padding: 10px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 0px;
    margin-right: 10px;
`;

export const BoxItemsContainer = styled.div`
    max-height: 250px;
    overflow-y: auto;
`;

export const BoxItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    cursor: pointer;

    :hover {
        background-color: #f4f5f7;
        border-radius: 5px;
    }
`;

export const BoxSpan = styled.span`
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 5px;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 5px;
    color: rgba(51,51,51,0.9);
    font-weight: 400;
    background-color: #4681b81e;
    margin-left: 5px;
`;

export const BoxSpanDetail = styled.span`
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 5px;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 5px;
    color: rgba(51,51,51,0.9);
    background-color: #4681b81e;
`;

export const NoResults = styled.span`
    color: gray;
    font-style: italic;
    font-size: 11px;
    margin-top: 5px;
    margin-left: 5px;
`;

export const BoxMultiple = styled.div`
    margin-left: 5px;
    display: flex;
    margin-right: 5px;
    color: #808080;
    font-weight: 400;
`;

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  @media(max-width: 800px){
    display: none;
  }

`;

export const ButtonLink = styled.button<ButtonLinkProps>`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;

    svg {
        font-size: 16px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }

    ${props => props.active && css`
        background-color: #60bd4e;
        color: white;
        border-radius: 5px;   
        font-weight: 500;
        
        :hover {
            background-color: #57aa47;
        }
    `}

    @media(max-width: 800px){ 
        svg {
            margin-right: 0px;
        }

        div {
            display: none;            
        }
    }
    
`;

export const SearchInput = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    border-radius: 5px;
    border: solid 1px #808080bf;
    width: 100%;
    max-width: 400px;
    color: #4d4d4d;
    font-weight: 600;

    svg {
        font-size: 17px;
        margin-top: 1px;
        margin-left: 9px;
    }
`;

export const Input = styled.input`
    border: none;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 100%;
    max-width: 400px;
    margin-right: 5px;

    ::placeholder {
        padding: 5px;
        padding-left: 0px;
        color: #4d4d4d;
        font-size: 13px;
    }
`;

export const ContainerEmpty = styled.div`
    height: calc(100% - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    margin-top: 20px;

    @media(max-width: 800px){
      display: flex;
      flex-direction: column-reverse;
    }
`;

export const ContainerInfo = styled.div`
  
  display: flex;
  text-align: center;
  padding: 30px;
  height: 100%;
  max-width: 500px;
  margin-top: 30px;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: black;
    font-size: 28px;
    margin-bottom: 20px;

    @media (max-width: 800px){
      font-size: 23px;
    }
  }

  p {
    font-size: 15px;
    color: gray;
    letter-spacing: 0.5px;
  }

  p.tip {
    background: rgba(69, 129, 183, 0.118);
    border-radius: 10px;
    padding: 7px;
    color: rgb(69, 129, 183);
    font-weight: 400;
    margin-top: 30px;

    @media(max-width: 800px){
      display: none !important;
    }
  }

  @media(max-width: 800px){
    margin-top: 0px;
    padding-top: 0px;
  }
`;

export const ContainerIlustration = styled.div`
  
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  margin: 20px;

  @media(max-width: 800px){
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  img {
    width: 80%;

    @media(max-width: 800px){
      width: 100%;
    }
  }
`;

export const ButtonFirstNewTask = styled(Button)`
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    background: rgba(251,50,92,1);
    background: linear-gradient(90deg,rgba(255,140,47,1) 25%,rgba(251,50,92,1) 50%,rgba(147,55,237,1) 75%);
    margin-top: 25px !important;
    width: 250px !important;

    :hover {
        background: linear-gradient(90deg, rgba(255,140,47,0.75) 25%, rgba(251,50,92,0.75) 50%, rgba(147,55,237,0.75) 75%);
    }

    div {
        margin-top: 3px !important;

        svg {
            width: 18px;
            height: 18px;
        }
    }
`;