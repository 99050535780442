import React, { useState } from "react";
import { BiDotsHorizontal } from "react-icons/bi";
import { ClickAwayListener } from "@material-ui/core";

import {
    BoxOver,
    ContainerMenu,
    ContainerSelect
} from "./style";

interface ElementMenuProps {
    children?: React.ReactNode;
}

const ElementMenu: React.FC<ElementMenuProps> = ({ children }) => {

    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const handleClickAway = () => {
        setMenuOpen(false);
    };

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <BoxOver onClick={() => setMenuOpen(!menuOpen)}>
                    <ContainerSelect>
                        <BiDotsHorizontal />
                    </ContainerSelect>
                    {menuOpen ? (
                        <ContainerMenu>
                            {children}
                        </ContainerMenu>
                    ) : (<></>)}
                </BoxOver>
            </ClickAwayListener>
        </>
    );

}

export default ElementMenu;