import styled, { css } from 'styled-components';

export interface NavigationBarItemProps {
    active?: boolean;
}

export interface NavigationBarItemIconProps {
    sizeIcon?: string;
}

interface ContainerProps {
    isRounded?: boolean;
}

interface IconHeaderProps {
    color?: string;
}

interface NavigationBarTitleProps {
    isFullSize?: boolean;
}

export const LeftHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    height: 100%;
    overflow: hidden;

    h1, h2, h3, h4 {
        font-size: 25px;
        font-weight: 700;
    }
    
`;

export const RightHeader = styled.div`
    display: flex;
    align-items: unset;
    justify-content: right;

    margin-left: auto;
`;

export const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;

    height: 60px;
    width: 100%;
    background-color: white;
    //border-radius: 10px; 
    //border-radius: 0px 0px 10px 10px;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);

    > svg {
        color: black;

    }

    h1, h2, h3, h4 {
        color: #4d4d4d;
        font-weight: 500;
    }

    ${props => props.isRounded && css`
        border-radius: 0px 0px 10px 10px;
    `}

`;

export const IconHeader = styled.span<IconHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    margin: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width: 60px;
    height: 100%;
    background-color: transparent;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 50px;
        height: 50px;
        background-color: #f4f4f4;
        border-radius: 15px;

        ${props => props.color && css`
        background-color: ${props.color + '1e'};
    `}

        > svg {
            width: 20px;
            height: 20px;
        }

    }
`;

export const ButtonsHeader = styled.span`

    display: flex;
    align-items: center;
    justify-content: right;

`;

export const NavigationBar = styled.div`
    height: 100%;
`;

export const IconQuickContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
    margin-left: 7px;

    svg {
        font-size: 20px;
    }
`;


export const DropDownMenuContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 5px;
    font-size: 12px;
`;

export const NavigationBarTitle = styled.div<NavigationBarTitleProps>`
    //height: 50%;
    //margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-left: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    width: 100%;
    margin-top: 4px;

    h1 {
        color: black;
        font-size: 19px;
    }

    ${props => props.isFullSize && css`
        height: 100%;
    `}
`;

export const InputTitle = styled.input`
    border: none;
    background-color: transparent;
    font-size: 18px;
    font-weight: 500;
    color: black;
    outline: none;
    padding: 2px 10px;
    min-width: 300px;
    border: 1px solid transparent;
    margin-right: 10px;
    margin-left: -10px;

    :hover {
        border: 1px solid #e0dfdf;
        border-radius: 10px;
    }

    :focus {
        border: 1px solid #e0dfdf;
        border-radius: 10px;
    }
`;

export const NavigationBarPages = styled.div`
    //height: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
`;

export const NavigationBarItem = styled.span<NavigationBarItemProps>`
    margin-right: 5px;
    color: gray;
    cursor: default;
    display: flex;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    min-width: 1px;
    transition-duration: .2s;
    transition-property: box-shadow,color;
    white-space: nowrap;
    padding-bottom: 5px;
    padding-right: 7px;
    padding-left: 7px;
    padding-top: 3px;
    border-bottom: 2px solid transparent;

    :hover {
        color: #4d4d4d;
        font-weight: 500;
        border-bottom-color: #4d4d4d;
    }

    ${props => props.active && css`
        color: #4d4d4d;
        font-weight: 500;
        border-bottom-color: #4d4d4d;   
    `}
`;

export const NavigationBarItemIcon = styled.div<NavigationBarItemIconProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    //margin-right: 7px;

    svg {
        margin-top: 3px;

        ${props => props.sizeIcon && css`
            width: ${props.sizeIcon + "px"};
            height: ${props.sizeIcon + "px"};
        `}
    }
`;

export const NavigationBarTitleBadge = styled.span`
    background-color: #f23b5d;
    color: white;
    padding: 6px;
    padding-bottom: 3px;
    font-size: 10px;
    border-radius: 5px;
    padding-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
`;

export const BadgeInformation = styled.span`
    margin-left: 10px;
    font-size: 12px;
    background-color: #f23b5d;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
    font-weight: 500;
`;