import React, { useCallback } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { FaCheck, FaSignOutAlt, FaStore, FaTable, FaUser } from "react-icons/fa";
import { FiHome } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { TiFlowMerge } from "react-icons/ti";
import { useHistory } from 'react-router-dom';

import wendataLogo from '../../../assets/wendata-logo-w.png';
import {
    Body,
    BoxContainer,
    DividerContainer,
    Header,
    HeaderLeft,
    HeaderRight,
    MenuItem
} from "./styles";
import { useAuth } from '../../../hooks/auth';
import getAccessControl from "../../../middlewares/AccessControl";

interface UserMenuProps {
    open: boolean;
    closeMenu: () => void;
}

const MobileMenu: React.FC<UserMenuProps> = ({ open, closeMenu }) => {

    const { user, signOut } = useAuth();
    const history = useHistory();

    const handleClickAway = () => {
        closeMenu();
    };

    const goToUserConfig = useCallback(() => {
        history.push('/my-configs');
    }, [history]);

    const goToDashboard = useCallback(() => {
        history.push('/dashboard');
    }, [history]);

    const goToFlowList = useCallback(() => {
        history.push('/flow-list');
    }, [history]);

    const goToRegisterList = useCallback(() => {
        history.push('/register-list');
    }, [history]);

    const goToTaskList = useCallback(() => {
        history.push('/task-list');
    }, [history]);

    const goToTemplateStore = useCallback(() => {
        history.push('/template-store');
    }, [history]);

    return (
        open ?
            <ClickAwayListener onClickAway={handleClickAway}>
                <BoxContainer>
                    <Header>
                        <HeaderLeft>
                            <img src={wendataLogo} alt="Logo Cange" />
                        </HeaderLeft>
                        <HeaderRight onClick={() => closeMenu()}>
                            <IoMdClose />
                        </HeaderRight>
                    </Header>
                    <Body>
                        <MenuItem onClick={goToDashboard} style={{ paddingTop: '18px' }}>
                            <div>
                                <FiHome />
                            </div>
                            <h2>
                                Página Inicial
                            </h2>
                        </MenuItem>

                        {getAccessControl(89, user.type) && (
                            <MenuItem onClick={goToTaskList}>
                                <div style={{ fontSize: '18px' }}>
                                    <FaCheck />
                                </div>
                                <h2>
                                    Minhas Tarefas
                                </h2>
                            </MenuItem>
                        )}

                        <MenuItem onClick={goToFlowList}>
                            <div>
                                <TiFlowMerge />
                            </div>
                            <h2>
                                Meus Fluxos
                            </h2>
                        </MenuItem>

                        {getAccessControl(86, user.type) && (
                            <MenuItem onClick={goToRegisterList}>
                                <div>
                                    <FaTable style={{ fontSize: '18px' }} />
                                </div>
                                <h2>
                                    Meus Cadastros
                                </h2>
                            </MenuItem>
                        )}



                        {getAccessControl(84, user.type) && (
                            <>
                                <DividerContainer />
                                <MenuItem onClick={goToTemplateStore}>
                                    <div>
                                        <FaStore />
                                    </div>
                                    <h2>
                                        Central de Templates
                                    </h2>
                                </MenuItem>
                            </>
                        )}

                        <DividerContainer />

                        <MenuItem onClick={goToUserConfig}>
                            <div>
                                <FaUser style={{ fontSize: '18px' }} />
                            </div>
                            <h2>
                                Perfil
                            </h2>
                        </MenuItem>

                        <MenuItem onClick={signOut}>
                            <div>
                                <FaSignOutAlt style={{ fontSize: '18px' }} />
                            </div>
                            <h2>
                                Sair
                            </h2>
                        </MenuItem>


                    </Body>
                </BoxContainer>
            </ClickAwayListener> :
            <></>
    );

}

export default MobileMenu;