import React from "react";

import {
    Container
} from "./style";

const DropDownDivider: React.FC = () => {

    return (
        <Container>
           
        </Container>
    );

}

export default DropDownDivider;