import { AiOutlineCheckCircle, AiOutlineClose, AiOutlineCloudUpload, AiOutlineSync } from "react-icons/ai";
import {
    ButtonAdd,
    ButtonLink,
    ButtonLinkSync,
    ButtonSync,
    Container,
    LeftSide,
    RigthSide
} from "./style";
import FilterDialog, { FilterConditionItem, FilterFlowProps } from "./FilterDialog";
import ViewFlow from "./ViewFlow";

import { FaPlus, FaSyncAlt } from "react-icons/fa";
import { Flow } from "../../interfaces/Flow";
import React, { useCallback, useEffect, useState } from "react";
import getAccessControl from "../../middlewares/AccessControl";
import { MdDownloading } from "react-icons/md";

interface FilterBarProps {
    flow?: Flow;
    typeUser: string;
    itemNavBar: number;
    statusSaved?: number;
    selectedView?: number;
    initFilterFlow?: FilterFlowProps;
    hasViewUpdates?: boolean;
    panelId?: number;
    isArchived: boolean;
    loadingPanel?: boolean;
    onFilterFlow: (filterFlow: FilterFlowProps, openedDialog: boolean) => void;
    onClickField: () => void;
    onAddElement: () => void;
    onSyncPanel: (idPanel?: number, conditions?: FilterConditionItem[]) => void;
    onRemoveArchived: () => void;
}

const FilterBar: React.FC<FilterBarProps> = ({ flow, onFilterFlow, onClickField, onAddElement, onSyncPanel, typeUser, itemNavBar, statusSaved, selectedView, initFilterFlow, hasViewUpdates, panelId, isArchived, loadingPanel, onRemoveArchived }) => {

    const [lastFilterFlow, setLastFilterFlow] = useState<FilterFlowProps>();
    const [newView, setNewView] = useState<FilterFlowProps>();

    const onFilterFlowTwo = useCallback((filterFlow: FilterFlowProps, openedDialog: boolean) => {
        onFilterFlow(filterFlow, openedDialog);
        setLastFilterFlow(filterFlow);
    }, [onFilterFlow]);

    const handleNewView = useCallback((filterFlow: FilterFlowProps, openedDialog: boolean) => {

        setNewView(filterFlow);

    }, []);

    useEffect(() => {

        if (initFilterFlow) {
            setNewView(initFilterFlow);
        }

    }, [initFilterFlow]);

    return (

        itemNavBar !== 3 && itemNavBar !== 5 ?
            <Container>
                <LeftSide>
                    {isArchived ?
                        <ButtonLink type="button" onClick={onRemoveArchived} active={isArchived}>
                            <AiOutlineClose />
                            Arquivados
                        </ButtonLink> :
                        <></>
                    }

                    {flow !== undefined && itemNavBar !== 3 && itemNavBar !== 5 ?
                        <FilterDialog
                            flow={flow}
                            onFilterFlow={onFilterFlowTwo}
                            selectedValue={newView}
                            typeUser={typeUser}
                            itemNavBar={itemNavBar}
                        /> :
                        <></>
                    }
                </LeftSide>
                <RigthSide>
                    {(itemNavBar === 0 || itemNavBar === 1 || itemNavBar === 4) && flow?.id_flow ?
                        <ViewFlow
                            schema={lastFilterFlow}
                            flow_id={flow.id_flow}
                            onClickView={handleNewView}
                            selectedViewId={selectedView}
                            initFilterFlow={initFilterFlow}
                            hasUpdates={hasViewUpdates}
                            typeUser={typeUser}
                        /> :
                        <></>
                    }
                    {itemNavBar === 2 ?
                        <>
                            {loadingPanel ?
                                <ButtonLinkSync>
                                    <MdDownloading color={"gray"} />
                                    Carregando...
                                </ButtonLinkSync> :
                                statusSaved === 1 ?
                                    <ButtonLinkSync>
                                        <AiOutlineCheckCircle color={"green"} />
                                        Alterações salvas
                                    </ButtonLinkSync> :
                                    statusSaved === 2 ?
                                        <ButtonLinkSync>
                                            <AiOutlineCloudUpload />
                                            Salvando
                                        </ButtonLinkSync> :
                                        statusSaved === 3 ?
                                            <ButtonLinkSync>
                                                <AiOutlineSync />
                                                Salvando
                                            </ButtonLinkSync> :
                                            <></>}

                            {onSyncPanel && (
                                <ButtonSync type="button" onClick={() => onSyncPanel(panelId, newView !== undefined && newView.conditions !== undefined ? newView.conditions : undefined)}>
                                    <FaSyncAlt />
                                </ButtonSync>
                            )}

                            {getAccessControl(51, typeUser) && onAddElement ?
                                <ButtonAdd type="button" onClick={onAddElement}>
                                    <FaPlus />
                                    Adicionar elemento
                                </ButtonAdd>
                                : <></>}
                        </> : <></>
                    }
                </RigthSide>
            </Container> :
            <></>
    );

}

export default FilterBar;