import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import styled, { css } from "styled-components";
import Button from '../../components/Button';

interface AutomationsItemIconTriggerProps {
    color?: string;
}

interface AutomationsItemIconActionProps {
    color?: string;
}

interface AutomationsItemProps {
    color?: string;
}

export const Content = styled(DialogContent)`
    background-color: white;   
    padding: 0 !important;
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: center;
    height: 100%;
    width: auto;
`;

export const Container = styled.div`
    height: 100%;
    max-height: calc(100vh - 55px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

export const ContainerLeft = styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-height: 100% !important;
`;

export const ContainerRight = styled(Grid)`
    @media(max-width: 959px){
        display: none !important;
    }

    border-left: 2px solid #f4f5f7;
    max-height: 100% !important;
    height: 100%;
    
`;

export const ContainerNewAutomation = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
`;

export const BtnNewAutomation = styled.button`
    height: 120px;
    width: 170px;
    border-radius: 10px;
    border: 1px dashed gray;
    background-color: white;
    color: gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    transition: transform 0.5s ease; 

    svg {
        font-size: 22px;
        margin-bottom: 7px;
    }

    span {
        font-size: 15px;
        letter-spacing: 0.2px;
        line-height: 20px;
    }

    :hover {
        border-color: #00bcd4;
        color: #00bcd4;

        transform: scale(1.02);

    }
`;

export const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const ChartItem = styled.div`
    width: 100%;
    background: white;
    padding: 15px;
    padding-bottom: 0px;
    border-radius: 10px;
`;

export const ChartItemTitle = styled.div`
    color: black;
    font-weight: 500;
    font-size: 15px;
    border-bottom: 1px solid #f4f5f7;
    padding-bottom: 10px;
`;

export const ChartItemBody = styled.div`
    padding: 10px;
    padding-bottom: 15px;
`;

export const HelpText = styled.button`
  margin-left: 5px;
  display: inline-block;
  background-color: transparent;
  border: none;

  svg {
    margin: 0;
    font-size: 13px;
  }
`;

export const ContainerLeftBody = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ContainerAutomations = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;    
`;

export const ContainerLoader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;        

    svg {
        height: 50px !important;        
    }
    
`;

export const DividerMenu = styled.div`
    width: 100%;
    border-bottom: 1px solid #f4f4f4;
    margin: 0;
`;

export const ButtonClose = styled.button`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    width: 45px !important;
    height: 35px !important;
    margin-right: 15px !important;

    svg {
        font-size: 16px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
    
`;

export const AutomationsGroup = styled.div`       
    width: 100%;
`;

export const AutomationsGroupHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 15px;
    margin-bottom: 0px;
`;

export const AutomationsGroupName = styled.div`
    font-weight: 500;
    color: black;
    margin-left: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 17px;

    svg {
        font-size: 20px;
        margin-right: 10px;
        margin-top: 1px;
    }
`;

export const AutomationsGroupItems = styled.div`
    margin-left: 10px;
    font-size: 10px;
    margin-right: 5px;
    background-color: #f6ebfd;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 500;
    color: white;
`;

export const AutomationsGroupBody = styled.div`
    padding: 10px;
    overflow: auto;
`;

export const AutomationsItem = styled.div<AutomationsItemProps>`
    border: 1px solid #eef2f8;
    border-radius: 10px;
    padding: 10px 15px;
    padding-right: 20px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;

    :hover {
        border-color: gray;
    }

    ${props => props.color && css` 
         border-color: ${props.color + '70'};
    
        :hover {
            border-color: ${props.color};
        }
    `}
`;

export const AutomationsItemIconTrigger = styled.div<AutomationsItemIconTriggerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    background-color: #f23b5d;
    color: white;
    border-radius: 5px;
    font-size: 13px;

    div {
        display: flex;
        font-size: 16px !important;
    }

    ${props => props.color && css`
        background-color: ${props.color};
    `}
`;

export const AutomationsItemIconName = styled.div`
    margin-left: 10px;

    h3 {
        font-size: 15px;
        color: black;
    }

    span {
        color: gray;
        font-size: 12px;
    }
`;

export const AutomationsItemIconDivider = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
`;

export const AutomationsItemIconAction = styled.div<AutomationsItemIconActionProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    background-color: #f23b5d;
    color: white;
    border-radius: 5px;
    font-size: 13px;
    border-radius: 50%;
    font-weight: 600;

    div {
        display: flex;
        font-size: 16px !important;
    }

    ${props => props.color && css`
        background-color: ${props.color};
    `}
`;

export const AutomationConfigItemImage = styled.div<{ size?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;    
    margin-right: -5px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    ${props => props.size && css`
        width: ${props.size}px;
        height: ${props.size}px;
        min-width: ${props.size}px;
        min-height: ${props.size}px;
        
        svg {
            width: ${props.size}px;
            height: ${props.size}px
        }
    `}
`;

export const AutomationsItemGroupActions = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const AutomationsItemLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
`;

export const AutomationsItemRight = styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const ContainerEmpty = styled.div`    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const ContainerInfo = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    color: black;
    margin-bottom: 5px;
    font-size: 20px;
  }

  p {
    font-size: 15px;
    color: gray;
    letter-spacing: 0.5px;
  }

  p.tip {
    background: rgba(69, 129, 183, 0.118);
    border-radius: 10px;
    padding: 7px;
    color: rgb(69, 129, 183);
    font-weight: 400;
    margin-top: 30px;
  }
`;

export const ContainerIlustration = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;

    img {
        width: 45%;
    }
`;

export const ButtonFirstNewAutomation = styled(Button)`
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    background: rgba(251,50,92,1);
    background: linear-gradient(90deg,rgba(255,140,47,1) 25%,rgba(251,50,92,1) 50%,rgba(147,55,237,1) 75%);
    margin-top: 25px !important;
    width: 250px !important;

    :hover {
        background: linear-gradient(90deg, rgba(255,140,47,0.75) 25%, rgba(251,50,92,0.75) 50%, rgba(147,55,237,0.75) 75%);
    }

    div {
        margin-top: 3px !important;

        svg {
            width: 18px;
            height: 18px;
        }
    }
`;

export const ButtonLink = styled.button`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;

    margin-right: 10px !important;
    height: 35px !important;
    width: 170px !important;

    svg {
        font-size: 13px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }

    @media(max-width: 800px){ 
        svg {
            margin-right: 0px;
        }

        div {
            display: none;            
        }
    }
    
`;