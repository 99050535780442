import styled from "styled-components";

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const DialogContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
  width: 100%;  
  padding: 30px;
  background-color: white;
  min-height: 300px;
  overflow-y: auto;

  //Colocar parametros
  max-width: 500px;
  height: auto;
  //max-height: 300px;
`;

export const DialogHeader = styled.div`
  text-align: left;
  width: 100%;
  padding-bottom: 20px;

  border-bottom: 1px solid #eef2f8;

  h1 {
    font-size: 23px;
    font-weight: 600;
    color: black;
  }

  svg {
    top: 32px;
    right: 0;
    width: 20px;
    height: 20px;
  }
`;

export const DialogBody = styled.div`
  height: calc(100% + 44px);
  max-height: 80%;
  margin-bottom: 70px;
  text-align: center;
  overflow: auto;

  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  margin-top: 20px;

  p {
    color: #263238;
  }
`;

export const DialogFooter = styled.div`
  position: absolute;
  bottom: 25px;
  margin-left: 40px;
  text-align: left;
  //width: 100%;
  padding-top: 20px;
  border-top: 1px solid #eef2f8;

  width: 100%;
  margin-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    height: 36px;
    width: 50px;
    background-color: #2390eb; 
    color: white;
    margin: 0px;
  }

`;

export const DialogBtnClose = styled.button`
  border: none;
  background-color: transparent;
`;
