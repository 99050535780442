import styled from "styled-components";

export const Container = styled.div`
    div {
        width: 100%;
        padding: 15px;
        margin-top: 15px;
        margin-left: 5px;
        border: 1px solid hsl(0,0%,80%);
        border-radius: 4px;

        & div {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        input {
            padding: 5px;
            margin: 10px;
            margin-left: 5px;
            margin-right: 5px;
            border: 1px solid hsl(0,0%,80%);
            border-radius: 4px;
        }

        ::-webkit-input-placeholder::before {
            content: "teste"
        }
    
        div {
            width:auto;
            border: none;
            margin: 0px;
            padding: none;
        }

        & + svg {
            font-size: 30px;
        }

        svg {
            color: gray;
        }
    }
`;