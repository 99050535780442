import styled, { css } from "styled-components";

import Grid from '@material-ui/core/Grid';
import Button from "../../components/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

interface ContainerColorProps {
  type: number;
}

export const Container = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 500px;  
  padding: 25px;

`;

export const DialogMain = styled(Dialog)`
  z-index: 999999 !important;
`;

export const Content = styled(DialogContent)`
  padding: 0px !important;
`;

export const ContentBody = styled(Grid)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: auto;
    width: auto;
      
    /*background-color: white;*/
`;

export const ContainerImage = styled.div`    
    margin-bottom: 20px;

    img {
      height: 40px;
      width: 40px;
    }
`;

export const ContainerColor = styled.div<ContainerColorProps>`
  height: 15px;
  width: 100%;

  //Warning
  ${props => props.type === 1 && css`
    background-color: #e24d4b;
  `}

  //Sucess
  ${props => props.type === 2 && css`
    background-color: #34ba7c;
  `}

  //Error
  ${props => props.type === 3 && css`
    background-color: #e24d4b;
  `}

  //Attention
  ${props => props.type === 4 && css`
    background-color: #ff8c2f;
  `}
`;


export const DialogFooter = styled(DialogActions)`
  text-align: left;
  border-top: 1px solid #eef2f8;
  padding: 15px !important;
`;

export const ButtonConfirm = styled(Button)`
  height: 36px;
  width: 120px;
  margin: 0px 0px 0px 0px;
`;

export const ButtonCancel = styled(Button)`
  height: 36px;
  width: 100px;
  margin: 0px 0px 0px 0px;
  color: gray;
`;

export const ContainerTitle = styled.div`
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
    color: black;
`;

export const ContainerActions = styled.div`
    margin-bottom: 20px;

    span {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
      color: gray;
    }

    ul {
      margin-left: 30px;
      margin-top: 10px;
      font-size: 15px;

      li {
        margin-bottom: 5px;
      }
    }
`;

export const ContainerDisclaimer = styled.div`
    font-size: 13px;
    color: red;
`;