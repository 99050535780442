import "react-responsive-carousel/lib/styles/carousel.min.css";

import {
    Animation,
    AnimationContainer,
    Body,
    BtnStart,
    BtnUseTemplate,
    ContainerLoader,
    ContainerTemplates,
    Content,
    DetailTemplateBack,
    DetailTemplateBackMobile,
    DetailTemplateButtonUse,
    DetailTemplateButtonUseBottom,
    DetailTemplateCarousel,
    DetailTemplateImage,
    DetailTemplateLeft,
    DetailTemplateLogo,
    DetailTemplateName,
    DetailTemplatePreReady,
    DetailTemplateRelated,
    DetailTemplateRelatedItem,
    DetailTemplateRelatedItemLabel,
    DetailTemplateRelatedItemLogo,
    DetailTemplateRelatedItems,
    DetailTemplateResource,
    DetailTemplateResourceItem,
    DetailTemplateResourceItems,
    DetailTemplateRight,
    DetailTemplateTags,
    GridTemplates,
    Header,
    HeaderLeft,
    LeftContainer,
    MenuBottom,
    MenuItemTemplate,
    MenuItemTemplateIcon,
    MenuItemTemplateStar,
    MenuLabel,
    MenuTemplateItems,
    RightContainer,
    SearchTemplate,
    SearchTemplateInput,
    SelectMenu,
    TemplateTag
} from './styles';
import { FaArrowLeft, FaIdCardAlt, FaSearch } from 'react-icons/fa';
import { IconList, IconPickerItem } from 'react-fa-icon-picker'
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Carousel } from 'react-responsive-carousel';
import DegradeLine from '../../components/DegradeLine';
import Interweave from 'interweave';
import { IoMdListBox } from "react-icons/io";
import { Onboarding } from '../../interfaces/Onboarding';
import { Template as TemplateApi } from '../../interfaces/Template';
import TemplateCard from '../../components/Template/TemplateCard';
import TemplateCardBlank from "../../components/Template/TemplateCardBlank";
import TemplateCardRequest from "../../components/Template/TemplateCardRequest";
import { TemplateGroup } from '../../interfaces/TemplateGroup';
import api from '../../services/api';
import getAccessControl from "../../middlewares/AccessControl";
import lottie from 'lottie-web';
import { useToast } from '../../hooks/toast';

interface TemplateCentralProps {
    onboarding: Onboarding | undefined;
    typeUser: string;
    handleTemplate: (template_id: number) => Promise<void>;
    handleFromScratch: () => Promise<void>;
    params?: RouteTemplateCentralParams;
    isOnboarding?: boolean;
}

export interface RouteTemplateCentralParams {
    id?: string;
}

const TemplateCentral: React.FC<TemplateCentralProps> = ({ onboarding, handleTemplate, handleFromScratch, params, typeUser, isOnboarding }) => {

    const { addToast } = useToast();

    const selectRef = useRef(null);
    const lottieContainer = useRef<HTMLDivElement>(null);
    
    const [templateGroups, setTemplateGroups] = useState<TemplateGroup[]>();
    const [templates, setTemplates] = useState<TemplateApi[]>();
    const [selectedGroupTemplate, setSelectedGroupTemplate] = useState<TemplateGroup>();
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateApi | undefined>(undefined);
    const [searchText, setSearchText] = useState<string>("");
    const [loadingTemplates, setLoadingTemplates] = useState<boolean>(true);    

    const handleUseTemplate = useCallback(async (template: TemplateApi) => {
        handleTemplate(template.id_template);
    }, [handleTemplate]);

    const handleFilterUsers = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {

        const searchText = e.currentTarget.value;
        setSearchText(searchText);

        if (searchText.trim() !== "" && searchText.length > 2) {

            setLoadingTemplates(true);

            api.get(`/template/by-name?search_text=` + searchText.trim()).then(response => {

                const apiTemplate: TemplateApi[] = response.data;

                if (apiTemplate !== undefined && apiTemplate.length > 0) {
                    setTemplates(apiTemplate);
                }
                setLoadingTemplates(false);
            }).catch(error => {
                setLoadingTemplates(false);
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar dados do template',
                    description: 'Desculpe, tente novamente...!',
                });
            });

        }

    }, [addToast]);

    const handleSelectTemplate = useCallback(async (template: TemplateApi, onDetailOpen: boolean) => {

        setSelectedTemplate(template);

        if (onDetailOpen && template !== undefined) {
            api.get(`/template?id_template=` + template.id_template).then(response => {

                const apiTemplate: TemplateApi = response.data;

                setSelectedTemplate(apiTemplate);

                api.post('/template/view-count', {
                    id_template: template.id_template
                })

            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar dados do template',
                    description: 'Desculpe, tente novamente...!',
                });
            });
        }

    }, [addToast]);

    useEffect(() => {
        let idGroup: number = selectedGroupTemplate !== undefined ? selectedGroupTemplate?.id_template_group : 0;

        if (selectedGroupTemplate !== undefined) { //Get from choice

            setLoadingTemplates(true);

            api.get(`/template/all?template_group_id=` + idGroup).then(response => {

                const apiTemplate: TemplateApi[] = response.data;

                setTemplates(apiTemplate);

                setLoadingTemplates(false);

            }).catch(error => {
                setLoadingTemplates(false);
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar dados do template',
                    description: 'Desculpe, tente novamente...!',
                });
            });

        } else if (onboarding !== undefined) { //Get from onboarding

            idGroup = onboarding.template_group_id !== undefined ? onboarding.template_group_id : 0;

            setLoadingTemplates(true);

            api.get(`/template/all?template_group_id=` + idGroup).then(response => {

                const apiTemplate: TemplateApi[] = response.data;

                setTemplates(apiTemplate);
                setLoadingTemplates(false);

            }).catch(error => {
                setLoadingTemplates(false);

                addToast({
                    type: 'error',
                    title: 'Erro ao buscar dados do template',
                    description: 'Desculpe, tente novamente...!',
                });
            });

        } else {

            setLoadingTemplates(true);

            api.get(`/template/all?template_group_id=` + 0).then(response => {

                const apiTemplate: TemplateApi[] = response.data;

                setTemplates(apiTemplate);
                setLoadingTemplates(false);

            }).catch(error => {
                setLoadingTemplates(false);
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar dados do template',
                    description: 'Desculpe, tente novamente...!',
                });
            });

        }

    }, [selectedGroupTemplate, addToast, onboarding]);

    useEffect(() => {

        api.get(`/template/group`, {

        }).then(response => {

            const apiTemplateGroup: TemplateGroup[] = response.data;

            setTemplateGroups(apiTemplateGroup);
            setSelectedGroupTemplate(undefined);

            //If the url contains the ID 
            if (params !== undefined) {
                if (params.id !== undefined && Number(params.id) > 0) {
                    api.get(`/template?id_template=` + params.id).then(response => {

                        const apiTemplate: TemplateApi = response.data;

                        handleSelectTemplate(apiTemplate, true);

                    }).catch(error => {
                        addToast({
                            type: 'error',
                            title: 'Erro ao buscar dados do template',
                            description: 'Desculpe, tente novamente...!',
                        });
                    });
                }
            }


        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Erro ao buscar dados do template',
                description: 'Desculpe, tente novamente...!',
            });
        });

    }, [addToast, handleSelectTemplate, params]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../assets/lottie/loader.json')
            })
        }
    }, [loadingTemplates]);

    return (
        <Content>
            <AnimationContainer>
                <Header>
                    <HeaderLeft>
                        <h1>Escolha um template abaixo para seguir</h1>
                        <h3>Selecione um fluxo que melhor se <b>encaixa com o seu negócio</b> para iniciar. É possível também começar um do zero e <b>criar do seu jeito</b> :)</h3>
                    </HeaderLeft>
                </Header>
                <DegradeLine />
                {selectedTemplate === undefined ? (
                    <Body>
                        <LeftContainer>
                            <MenuItemTemplateStar
                                color={'#2096f3'}
                                selected={selectedGroupTemplate === undefined}
                                onClick={() => setSelectedGroupTemplate(undefined)}
                            >
                                <MenuItemTemplateIcon color={'#2096f3'}>
                                    <IconPickerItem
                                        icon={"FaStar" as IconList}
                                        color={"#2096f3"}
                                    />
                                </MenuItemTemplateIcon>
                                Recomendado para você
                            </MenuItemTemplateStar>

                            <SearchTemplate>
                                <FaSearch />
                                <SearchTemplateInput
                                    placeholder='Pesquise aqui...'
                                    onChange={handleFilterUsers}
                                    value={searchText}
                                />
                            </SearchTemplate>

                            <MenuLabel>
                                <h2>Navegue por categoria</h2>
                            </MenuLabel>

                            <MenuTemplateItems>
                                {templateGroups?.map((template_group) => (
                                    <MenuItemTemplate
                                        key={template_group.id_template_group}
                                        color={template_group.color}
                                        selectedStyle={selectedGroupTemplate?.id_template_group === template_group.id_template_group}
                                        onClick={() => setSelectedGroupTemplate(template_group)}
                                    >
                                        <MenuItemTemplateIcon color={template_group.color}>
                                            {template_group !== undefined && template_group.icon !== undefined ?
                                                <IconPickerItem
                                                    icon={template_group.icon as IconList}
                                                    color={template_group.color}
                                                /> :
                                                <IoMdListBox />
                                            }
                                        </MenuItemTemplateIcon>

                                        {template_group.name}

                                    </MenuItemTemplate>
                                ))}
                            </MenuTemplateItems>

                            <SelectMenu
                                options={templateGroups}
                                ref={selectRef}
                                getOptionLabel={(option: TemplateGroup) => `${option.name}`}
                                classNamePrefix="react-select"
                                placeholder={"Selecione uma categoria..."}
                                value={selectedGroupTemplate}
                                className="dropdown-group"
                                onChange={(e: TemplateGroup) => setSelectedGroupTemplate(e)}
                            />

                            {getAccessControl(31, typeUser) ?
                                <MenuBottom>
                                    <BtnStart type="button" onClick={() => handleFromScratch()}>Criar novo fluxo</BtnStart>
                                </MenuBottom> : <></>
                            }
                        </LeftContainer>
                        <RightContainer>
                            {loadingTemplates ?
                                <ContainerLoader>
                                    <Animation className="lottieContainer" ref={lottieContainer} />
                                </ContainerLoader > :
                                <ContainerTemplates>
                                    <GridTemplates>
                                        {getAccessControl(31, typeUser) && !isOnboarding ?
                                            selectedGroupTemplate === undefined ?
                                                <TemplateCardBlank onClick={() => handleFromScratch()} /> :
                                                <></>
                                            : <></>
                                        }
                                        {templates?.map((template) => {
                                            return (
                                                <TemplateCard key={template.id_template} template={template} handleSelectTemplate={() => handleSelectTemplate(template, true)} />
                                            )
                                        })}
                                        <TemplateCardRequest />
                                    </GridTemplates>
                                </ContainerTemplates>
                            }
                        </RightContainer>
                    </Body>

                ) : (

                    <Body>
                        <DetailTemplateLeft>
                            <DetailTemplateBack>
                                <button onClick={() => { setSelectedTemplate(undefined); }}><FaArrowLeft /></button>
                            </DetailTemplateBack>
                            <DetailTemplateLogo color={selectedTemplate.color}>
                                {selectedTemplate !== undefined && selectedTemplate.icon !== undefined ?
                                    <IconPickerItem
                                        icon={selectedTemplate.icon as IconList}
                                        color={"white"}
                                    /> :
                                    <FaIdCardAlt />
                                }
                            </DetailTemplateLogo>
                            {
                                selectedTemplate.template_resources !== undefined && selectedTemplate.template_resources.length > 0 ?
                                    <DetailTemplateResource>
                                        <h2>Recursos Disponíveis</h2>
                                        <DetailTemplateResourceItems>
                                            {selectedTemplate.template_resources.map((resource) => (
                                                <DetailTemplateResourceItem key={resource.id_template_resource}>
                                                    {resource !== undefined && resource.icon !== undefined ?
                                                        <IconPickerItem
                                                            icon={resource.icon as IconList}
                                                            color={"gray"}
                                                        /> :
                                                        <FaIdCardAlt />
                                                    }
                                                    <h4>{resource.description}</h4>
                                                </DetailTemplateResourceItem>
                                            ))}
                                        </DetailTemplateResourceItems>
                                    </DetailTemplateResource> :
                                    <></>
                            }
                            {selectedTemplate.related_templates !== undefined && selectedTemplate.related_templates.length > 0 ?
                                <DetailTemplateRelated>
                                    <h2>Templates relacionados</h2>
                                    <DetailTemplateRelatedItems>
                                        {selectedTemplate.related_templates.map((template) => (
                                            <DetailTemplateRelatedItem
                                                key={template.id_template}
                                                onClick={() => handleSelectTemplate(template, true)}
                                                color={template.color}
                                            >
                                                <DetailTemplateRelatedItemLogo color={template.color}>
                                                    {template !== undefined && template.icon !== undefined ?
                                                        <IconPickerItem
                                                            icon={template.icon as IconList}
                                                            color={"white"}
                                                        /> :
                                                        <FaIdCardAlt />
                                                    }
                                                </DetailTemplateRelatedItemLogo>
                                                <DetailTemplateRelatedItemLabel>
                                                    <h4>{template.name}</h4>
                                                    <span>{template.template_group?.name}</span>
                                                </DetailTemplateRelatedItemLabel>
                                            </DetailTemplateRelatedItem>
                                        ))}
                                    </DetailTemplateRelatedItems>
                                </DetailTemplateRelated> :
                                <></>
                            }
                        </DetailTemplateLeft>
                        <DetailTemplateRight>
                            <DetailTemplateBackMobile>
                                <button onClick={() => { setSelectedTemplate(undefined); }}><FaArrowLeft /></button>
                            </DetailTemplateBackMobile>
                            <DetailTemplateImage url_image={selectedTemplate.image_url} />
                            <DetailTemplateName>
                                <h2>{selectedTemplate.name}</h2>
                                <span>{selectedTemplate.template_group.name}</span>
                            </DetailTemplateName>
                            {getAccessControl(31, typeUser) ?
                                <DetailTemplateButtonUse>
                                    <BtnUseTemplate onClick={() => handleUseTemplate(selectedTemplate)}>Usar template</BtnUseTemplate>
                                </DetailTemplateButtonUse> : <></>
                            }
                            <DetailTemplatePreReady>
                                {selectedTemplate.template_tags !== undefined && selectedTemplate.template_tags.length > 0 ? (
                                    <DetailTemplateTags>
                                        {selectedTemplate.template_tags.map((tag) => {
                                            return (
                                                <TemplateTag key={tag.id_template_tag} color={tag.color}>
                                                    {tag.name}
                                                </TemplateTag>
                                            )
                                        })}
                                    </DetailTemplateTags>) : (
                                    <></>
                                )}
                                <Interweave content={selectedTemplate.pre_read} />
                                {
                                    selectedTemplate.template_images !== undefined && selectedTemplate.template_images.length > 0 ?
                                        <DetailTemplateCarousel>
                                            <Carousel showArrows={true} autoPlay={true} interval={5000} infiniteLoop={true}>
                                                {selectedTemplate.template_images.map((image) => (
                                                    <div key={image.id_template_image}>
                                                        <img alt={image.alt_description} src={image.url} />
                                                    </div>
                                                ))}
                                            </Carousel>
                                        </DetailTemplateCarousel> :
                                        <></>
                                }
                                <Interweave content={selectedTemplate.description} />
                                {getAccessControl(31, typeUser) ?
                                    <DetailTemplateButtonUseBottom>
                                        <BtnUseTemplate onClick={() => handleUseTemplate(selectedTemplate)}>Usar template</BtnUseTemplate>
                                    </DetailTemplateButtonUseBottom> : <></>
                                }
                            </DetailTemplatePreReady>
                        </DetailTemplateRight>
                    </Body>

                )}
            </AnimationContainer>
        </Content>
    );
}

export default TemplateCentral;
