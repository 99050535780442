
import {
    Container,
    LeftSide
} from "./style";

import React, { useCallback, useEffect, useState } from "react";
import { Register } from "../../interfaces/Register";
import FilterDialogRegister, { FilterRegisterProps } from "./FilterDialogRegister";

interface FilterBarRegisterProps {
    register?: Register;
    typeUser: string;
    initFilterRegister?: FilterRegisterProps;
    itemNavBar: number;
    onFilterRegister: (filterRegister: FilterRegisterProps, openedDialog: boolean) => void;
}

const FilterBarRegister: React.FC<FilterBarRegisterProps> = ({ register, typeUser, initFilterRegister, onFilterRegister }) => {

    const [newView, setNewView] = useState<FilterRegisterProps>();

    const onFilterRegisterTwo = useCallback((filterRegister: FilterRegisterProps, openedDialog: boolean) => {
        onFilterRegister(filterRegister, openedDialog);
    }, [onFilterRegister]);

    useEffect(() => {

        if (initFilterRegister) {
            setNewView(initFilterRegister);
        }

    }, [initFilterRegister]);

    return (
        <Container>
            <LeftSide>
                <FilterDialogRegister
                    register={register}
                    onFilterRegister={onFilterRegisterTwo}
                    selectedValue={newView}
                />
            </LeftSide>
        </Container>
    );

}

export default FilterBarRegister;