import { FieldProps } from "../components/Forms/Fields/FieldBuilder";
import { FormAnswer } from "../interfaces/FormAnswer";

export interface AggregateItemsProps {
    columnName: string;
    typeField: string;
    typeAggregate: string;
    variation?: string;
    value: number;
}

export default function getFormAnswerAggregations(
    fields_aggregate: FieldProps[],
    formAnswers: FormAnswer[]
): AggregateItemsProps[] {

    let count: number = 0;
    let sum: number = 0;
    let type: string = "count";
    let newArr: AggregateItemsProps[] = [];

    const fieldsAgregate = fields_aggregate;

    //If have any aggregate in the column
    if (fieldsAgregate !== undefined && fieldsAgregate.length > 0) {
        for (let index = 0; index < fieldsAgregate.length; index++) {
            const fieldId: FieldProps = fieldsAgregate[index];

            type = fieldId.type === "CURRENCY_FIELD" || fieldId.type === "NUMBER_FIELD" || fieldId.type === "FORMULA_FIELD" ? "sum" : "count";

            //If have any card in the column
            if (formAnswers !== undefined && formAnswers.length > 0) {
                for (let index = 0; index < formAnswers.length; index++) {
                    const formAnswer = formAnswers[index];

                    const fieldSelected = formAnswer.form_answer_fields.filter((answerField) => answerField.field_id === fieldId.id_field);

                    if (fieldSelected !== undefined && fieldSelected.length > 0) {
                        for (let index = 0; index < fieldSelected.length; index++) {
                            if (fieldSelected[index] !== undefined) {
                                if (type === "sum") { //Sum
                                    sum = sum + Number(fieldSelected[0].value);
                                } else { //Count
                                    count = count + 1;
                                }
                            }

                        }

                    }
                }

            }

            const aggregateItem: AggregateItemsProps = {
                columnName: fieldId.title,
                typeField: fieldId.type,
                typeAggregate: type,
                variation: fieldId.variation,
                value: type === "sum" ? sum : count
            }

            newArr.push(aggregateItem);

            sum = 0;
            count = 0;

        }
    }

    return newArr;

}
