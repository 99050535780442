import {
    Content,
    ContentBody,
    DialogContainer,
    DialogTop,
    DialogTopLeft,
    DialogTopRight,
    IconHeader,
    SpanField,
} from "./style";
import React, { useCallback } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsLightningChargeFill } from "react-icons/bs";

import { Register } from "../../../../../interfaces/Register";
import { Flow } from "../../../../../interfaces/Flow";
import AddConditionalFilter from "./AddConditionalFilter";


interface ConditionalComboBoxProps {
    open: boolean;
    register?: Register;
    flow?: Flow;
    filterSchema?: string;
    setFilterSchema: React.Dispatch<React.SetStateAction<string | undefined>>
    onClose: (open: boolean) => void;
}

const ConditionalComboBox: React.FC<ConditionalComboBoxProps> = ({ open, register, flow, filterSchema, setFilterSchema, onClose }) => {

    const handleClose = useCallback(async () => {
        onClose(false);
    }, [onClose]);

    return (
        <DialogContainer
            id="auto-complete-dialog"
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            style={{ zIndex: '999999' }}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={"#f23b5d"}>
                        <div>
                            <BsLightningChargeFill />
                        </div>
                    </IconHeader>
                    <h1>
                        {"Filtro Condicional"}
                    </h1>
                    <SpanField>
                        {register !== undefined ? register.name : flow !== undefined ? flow.name : "Carregando..."}
                    </SpanField>
                </DialogTopLeft>
                <DialogTopRight>
                    <button onClick={(e) => {
                        e.stopPropagation()
                        handleClose()
                    }}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>

            <Content style={{ padding: '0px' }}>
                <ContentBody>

                    <AddConditionalFilter
                        register={register}
                        flow={flow}
                        filterSchema={filterSchema}
                        setFilterSchema={setFilterSchema}
                        onClose={onClose}
                    />

                </ContentBody>
            </Content>

        </DialogContainer >
    );

}

export default ConditionalComboBox;