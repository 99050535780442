import DialogContent from '@material-ui/core/DialogContent';
import styled, { keyframes, css } from "styled-components";
import Button from '../../components/Button';
import Input from '../../components/Forms/Fields/Components/Input';
import TrialCounter from '../../components/TrialCounter';
import { Dialog } from '@material-ui/core';

interface ContentButtonProps {
    color?: string;
}

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    } 
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const ExitAnimation = keyframes`
  to {
    transform: translate(100%, 100%);
  }
`;

export const MainDialog = styled(Dialog) <{ isVisible: boolean, isMobile: boolean }>`
    animation: ${({ isVisible }) => (isVisible ? ExitAnimation : 'none')} 2s forwards;

    .MuiBackdrop-root {        
        ${({ isVisible }) => (isVisible ? 'background-color: transparent;' : '')}
    }

    .MuiDialog-paperFullWidth {
        ${({ isMobile }) => (isMobile ? 'border-radius: 0px;' : 'border-radius: 10px;')} 
        ${({ isMobile }) => (isMobile ? 'background-color: #f5f5f7;' : '')}                
    }
`;

export const MainContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f7;  
    padding: 15px; 
    border-radius: 10px;

    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

export const Content = styled(DialogContent)`      
    padding: 0px !important;  
`;

export const ContentBody = styled.div`
    display: flex;
    justify-content: flex-start;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
`;

export const InviteTitle = styled.h1`
    font-weight: 500;
    color: black;
    font-size: 25px;
    margin-bottom: 25px;

    animation: ${appearFromLeft} 1s;
`;

export const InviteDescription = styled.div`
    font-size: 16px;
    line-height: 1.75;    
    letter-spacing: 0.05px;
    color: #515358;

    animation: ${appearFromLeft} 1.5s;
`;

export const InviteContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    form {
        width: 100%;

        .container-input {
           max-width: 430px !important;
           margin-bottom: 10px;
           margin-top: 0px;

           input {
            font-size: 14px;
           }

           .icon-input {
            width: 18px;
           }
        }
    }

    animation: ${appearFromLeft} 3s;
`;

export const InviteContainerTitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #000000ba;
`;

export const InviteInput = styled(Input)`

    
`;

export const InviteContainerButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 80px !important;
`;

export const InviteButtonSkip = styled.a`
    background-color: transparent !important;
    color: gray;
    justify-content: flex-start;
    padding-left: 0px;
    font-size: 15px;
    font-weight: 400;

    :hover {
        color: #f23b5c;
    }

    @media (max-width: 600px) {
      display: none;
    }
`;

export const InviteButtonPlusEmail = styled(Button)`
    background-color: transparent !important;
    color: #f23b5c;
    justify-content: flex-start;
    padding-left: 0px;
    font-size: 13px;
    font-weight: 400;
    margin-top: 10px;

    :hover {
        
    }
`;

export const InviteButton = styled(Button)`
    height: 36px;
    width: 250px;
    background: linear-gradient(90deg,rgba(255,140,47,1) 25%,rgba(251,50,92,1) 50%,rgba(147,55,237,1) 75%);

    :hover {
        background: linear-gradient(90deg,rgba(255,140,47,0.7) 25%,rgba(251,50,92,0.7) 50%,rgba(147,55,237,0.7) 75%);
    }
`;

export const StartButton = styled(Button)`
    height: 36px;
    width: 200px;
    background-color: #f23b5d;
    position: absolute;
    box-shadow: 0 4px 34px #f23b5d96;
    right: 15px;
    bottom: 15px;

    :hover {
        opacity: 0.9;
    }

    @media (max-width: 600px) {
      position: relative;
      width: 100%;
      right: auto;
    }
`;

export const Animation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;    
    position: absolute;
    height: 100%;
    width: 100%;
`;

export const ContainerAnimation = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;        
`;

export const ConfettiArea = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    h1 {
        animation: ${appearFromLeft} 1s;        
        color: #1d1d1f;
        font-weight: 500;
        font-family: Roboto, helvetica;            
        font-size: 25px;
        margin-bottom: 0px !important;
        padding: 20px;
        padding-top: 0px;
        text-align: center;

        b {
            font-weight: 500;
        }

        @media (max-width: 600px) {
            font-size: 30px;
        }
    }   

    h3 {
        animation: ${appearFromLeft} 1s; 
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 15px !important;
    }
`;

export const ActionArea = styled.div`

    margin-top: 40px;

    h3 {
        animation: ${appearFromLeft} 1s; 
        font-weight: 400;
        font-size: 16px;
        text-align: center;
    }

    h2 {        
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        margin-bottom: 15px !important;
    }

`;

export const TrialCounterContainer = styled(TrialCounter)`
    animation: ${appearFromLeft} 1s;
    margin-top: 20px;
`;

export const ButtonsArea = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 80px !important;

    @media (max-width: 600px) {
        margin-top: 30px !important;
    }
`;

export const ContentRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;    
    height: 535px;

    @media (max-width: 600px) {
        margin-top: 15px;
        width: 100%;
        height: 400px;
    }
`;

export const ContentTop = styled.div`
    height: 50%;
    width: 300px;    
    margin: 15px;
    margin-top: 0px;
    margin-right: 0px;
    border-radius: 10px;
    background-color: #03bcd41e;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 15px;

    @media (max-width: 600px) {
        margin-left: 0px;
        width: 100%;
    }
`;

export const ContentBottom = styled.div`
    height: 50%;
    width: 300px;
    margin: 15px;   
    margin-top: 0px;
    margin-right: 0px;
    border-radius: 10px;
    background-color: #673ab71e;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 15px;
    margin-bottom: 0px;

    @media (max-width: 600px) {
        margin-right: 0px;
        margin-left: 0px;
        width: 100%;
    }
`;

export const ContentIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
`;

export const ContentCTA = styled.div`
    font-size: 18px;
    font-weight: 500;
    color: black;
    margin-top: 10px;
`;

export const ContentButton = styled.a<ContentButtonProps>`
    height: 30px;
    width: 130px;
    font-size: 13px !important;
    margin-top: 20px;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    
    ${({ color }) => color ? css`
        background-color: ${color};
        color: white;
    ` : css`
        background-color: #03bcd4;
        color: white;
    `}
`;

export const ContentPreDescription = styled.div`
    font-size: 10px;
    text-transform: uppercase;
    margin-top: 15px;
`;

export const ContentDescription = styled.div`
    font-size: 13px;
    color: #000000ba;
    margin-top: 15px;
`;