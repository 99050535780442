import styled, { css } from "styled-components";
import { TextField } from "unform-material-ui";
import Button from "../../Button";

interface CommentBottomProps {
    enabled?: boolean;
}

interface CommentTextProps {
    enabled?: boolean;
}

export const Container = styled.div`
    display: flex;
    min-height: 50px;
    margin-left: 5px;
    margin-bottom: 10px;
    margin-top: 5px;
`;

export const UserComment = styled.div`
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

export const InputComment = styled(TextField)`
    height: 100%;
    width: 100%;
    margin-left: 5px;

    p {
        display: none;
    }

    div {
        font-size: 15px;
        padding: 10px;

        &::before {
            flex: 1;
            background: transparent;
            border-bottom: none !important;
        }

        &::after {
            flex: 1;
            background: transparent;
            border-bottom: none !important;
        }

        textarea {
            flex: 1;
            background: transparent;
            border: 0;

            &::placeholder {
            opacity: 1;
            color: gray;
            font-size: 14px;
            }

            :focus {
                .box-comment {
                    border: 1px solid #f23b5c;
                }
            }
        }
    }
`;

export const BoxComment = styled.div`
    width: 100%;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    background-color: white !important;

    :hover {
        border: 1px solid #f23b5c;
    }

    :active {
        border: 1px solid #f23b5c;
    }
`;

export const CommentText = styled.div<CommentTextProps>`
    margin-top: 5px;    

    form {
        padding-bottom: 0px !important;        
    }

    textarea {
        border: none;
        font-size: 15px !important;
        overflow: auto !important;        

        ${props => props.enabled === true && css`            
            min-height: 60px;
        `}
    }

    div.mentions-input__highlighter {
        ${props => props.enabled === true && css`            
            min-height: 60px;
        `}
    }

    textarea::placeholder {
        font-size: 14px !important;
    }

    .mentions-input__suggestions {
        margin-top: 20px;
        box-shadow: 0 0 6px rgb(0 0 0 / 20%);
        border-radius: 10px;
        padding: 10px;
    }

    li.mentions-input__suggestions__item.mentions-input__suggestions__item--focused {
        background-color: #84939f1a;
    }

    li.mentions-input__suggestions__item {
        padding: 5px;
        border-radius: 10px;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 5px;
    }

`;

export const CommentBottom = styled.div<CommentBottomProps>`
    display: flex;
    height: 30px;
    margin-bottom: 5px;
    margin-top: 5px;

    ${props => props.enabled === false && css`
        display: none;
    `}
`;

export const CommentActions = styled.div`
    display: flex;
    width: 70%;
    justify-content: flex-start;
    align-items: center;
    margin-left: 5px;
`;

export const CommentButton = styled.div`
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const Action = styled.div`
    padding: 5px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
`;

export const BtnPublish = styled(Button)`
    margin-right: 10px;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 5px;
    font-weight: 500;
    background-color: #f23c5d;
    height: 26px;
`;

export const BoxUserItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px dashed transparent;

    :hover {
        background-color: #f4f5f7;
        border-radius: 15px;
        border: 1px dashed #e8f2ff;
    }
`;

export const BoxUserLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BoxUserRigth = styled.div`
    margin-left: 10px;
    width: 100%;
`;

export const BoxUserName = styled.div`
    font-size: 14px;
    color: black;
    font-weight: 400;
    overflow: hidden;
`;

export const BoxUserMail = styled.div`
    font-size: 11px;
    color: gray;
    overflow: hidden;
`;