import React from 'react';
import { useTransition } from 'react-spring';

import Toast from './Toast';
import { Container } from "./styles";
import { ToastMessage } from '../../hooks/toast';

interface ToastContainerProps {
    messages: ToastMessage[];
}

const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {

    const messagesWithTransitions = useTransition(
        messages,
        message => message.id,
        {
            from: { bottom: '-120%', opacity: 0 },
            enter: { bottom: '0%', opacity: 1 },
            leave: { bottom: '-120%', opacity: 0 },
        }
    );

    return (
        <Container isActived={messagesWithTransitions.length > 0}>
            {messagesWithTransitions.map(({ item, key, props }) => (
                <Toast
                    key={key}
                    style={props}
                    message={item}
                />
            ))}
        </Container>

    );
};

export default ToastContainer;

