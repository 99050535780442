import styled from "styled-components";
import { Box } from "@material-ui/core";

export const Container = styled.div`

`;

export const ContainerSelect = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BoxOver = styled(Box)`

`;

export const ContainerMenu = styled(Box)`
    position: absolute;
    top: auto;
    right: 0;
    z-index: 1;
    min-width: 200px;
    max-width: 300px;
    width: auto;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 5px;
    margin-right: 10px;
    padding-bottom: 10px;
    border: 1px solid #f4f4f4;
    padding-right: 10px;
    padding-left: 10px;

    h3 {
        font-size: 14px !important;
    }
`;