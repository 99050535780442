import * as Yup from 'yup';

import {
  Container,
  Content,
  FormUnform,
  FormUnformB
} from "./styles";
import React, { useCallback, useEffect } from "react";

import FieldContainer from "../../FieldContainer";
import { FieldProps } from "../Fields/FieldBuilder";
import { FormHandles } from "@unform/core";
import createYupSchema from '../../../utils/createYupSchema';
import getValidationErrors from '../../../utils/getValidationErrors';
import parseFormula from '../../../utils/Formula/parseFormula';
import { Card } from '../../../interfaces/Card';
import getCheckConditionalField from './getCheckConditionalField';
import api from '../../../services/api';
import { CheckListItem } from '../../../interfaces/CheckListItem';

interface FormProps {
  id: string;
  fields: FieldProps[];
  formRef: React.RefObject<FormHandles>;
  hideContainer?: boolean;
  hideAutoComplete?: boolean;
  initialValue?: object;
  children?: React.ReactNode;
  flow_id?: number;
  register_id?: number;
  flow_parent_id?: number; //If there is a parent sync
  register_parent_id?: number; //If there is a parent sync
  card_current_id?: number; //If there is a parent sync
  card_current?: Card;
  typeUser?: string;
  isNewAnswer?: boolean;
  focusOnForm?: boolean;
  isPublicForm?: boolean;
  public_hash?: string;
  activeHiddenFields?: boolean; //Active the show_on_form field
  handleSubmit?(data: object[]): Promise<void>;
  handleBlur?(): Promise<void>;
  updateFields?(): Promise<void>;
  setFields?: React.Dispatch<React.SetStateAction<FieldProps[] | undefined>>;
}

const FormBuilder: React.FC<FormProps> = ({ id, formRef, fields, hideContainer, handleSubmit, handleBlur, children, initialValue, hideAutoComplete, flow_id, register_id, typeUser, flow_parent_id, register_parent_id, card_current_id, card_current, isNewAnswer, focusOnForm, isPublicForm, public_hash, activeHiddenFields, updateFields, setFields }) => {

  //If the activeHiddenFields is true, the fields that have the show_on_form = "S" will be hidden
  if (activeHiddenFields && fields !== undefined && fields.length > 0) {
    fields.map((field) => {

      if (field.show_on_form !== undefined && field.show_on_form !== null && field.show_on_form === "S") {
        field.required = false;
        field.validations = [];
      }

      return field;
    });
  }

  const handleSubDefault = useCallback(async (data: object[]) => {

    try {

      //Clear the validation errors from the form
      formRef.current?.setErrors({});

      //Create the yup schema
      const yepSchema = fields.reduce(createYupSchema, {}) as object;
      const schema = Yup.object().shape(yepSchema);

      //Validate the form
      await schema.validate(data, {
        abortEarly: false,
      });

      //Check if any field has a external validation
      const fieldsUnique = fields.filter((f) => f.unique !== undefined && f.unique !== null && f.unique === "S");

      const endpoint = isPublicForm ? '/form-public/check-unique/by-field' : '/form/check-unique/by-field';

      if (fieldsUnique !== undefined && fieldsUnique.length > 0) {
        for (let index = 0; index < fieldsUnique.length; index++) {
          const f = fieldsUnique[index];

          const arr = Object.entries(data).filter(function (key) { return key.includes(f.name) });

          if (arr[0] !== undefined && arr[0][1] !== undefined && arr[0][1] !== null) {
            const value = arr[0][1];

            await api.get(endpoint, {
              params: {
                company_id: isPublicForm ? f.company_id : undefined,
                public_hash: isPublicForm ? public_hash : undefined,
                form_id: f.form_id,
                field_id: f.id_field,
                value: String(value)
              }
            }).then(response => {

              if (response.data.unique !== null && response.data.unique !== undefined) {

                const ret: boolean = response.data.unique;

                if (!ret) {
                  formRef.current?.setFieldError(f.name, "This field must be unique.");
                  throw new Error("This field must be unique. [1]");
                }

              } else {
                formRef.current?.setFieldError(f.name, "This field must be unique. [2]");
                throw new Error("This field must be unique. [2]");
              }

            }).catch(error => {
              formRef.current?.setFieldError(f.name, "Valor duplicado para o campo " + f.title + ". Você deve inserir um valor que já não esteja cadastrado!");
              throw new Error("Valor duplicado para o campo " + f.title + ". Você deve inserir um valor que já não esteja cadastrado!");
            });

          }

        }
      }

      //Check if has any ChecklistField
      const fieldCheckListDone = fields.filter((f) => f.type === "CHECK_LIST_FIELD" && f.formula !== undefined && f.formula !== null && f.formula === "1");

      if (fieldCheckListDone !== undefined && fieldCheckListDone.length > 0) {
        for (let index = 0; index < fieldCheckListDone.length; index++) {
          const f = fieldCheckListDone[index];

          const arr = Object.entries(data).filter(function (key) { return key.includes(f.name) });

          if (arr[0] !== undefined && arr[0][1] !== undefined && arr[0][1] !== null) {
            const value = arr[0][1];

            console.log(value);

            const checkListItem = value as unknown as CheckListItem[];

            if (checkListItem !== undefined && checkListItem.length > 0) {

              //Valid if all items are checked
              const check = checkListItem.filter((c) => c.checked === "S");

              if (check.length !== checkListItem.length) {
                formRef.current?.setFieldError(f.name, "No campo " + f.title + " existem itens a concluir na sua lista!");
                throw new Error("No campo " + f.title + " existem itens a concluir na sua lista!");
              }

            }

          }

        }
      }

      //Submitt the form
      if (handleSubmit !== undefined) {
        handleSubmit(data);
      }

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      }
    }

  }, [fields, formRef, handleSubmit, isPublicForm, public_hash]);

  const renderFields = () => {
    function compare(a: FieldProps, b: FieldProps) {

      if (a.index !== undefined && b.index !== undefined) {
        if (a.index < b.index) {
          return -1;
        }
        if (a.index > b.index) {
          return 1;
        }
      }
      return 0;

    }

    if (fields.length > 1) {
      fields.sort(compare);
      fields.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));
    }

    return fields.map((field) => {

      let newField = field;

      //Valida se existe valor inicial
      let haveInit = false
      if (initialValue !== undefined) {
        const arrInit = Object.entries(initialValue).filter(function (key) { return key.includes(field.name) });
        if (arrInit[0] !== undefined && arrInit[0][1] !== undefined && arrInit[0][1] !== null) {

          if (typeof arrInit[0][1] === "string") {
            if (arrInit[0][1].trim() !== "") {
              haveInit = true;
            }
          } else if (typeof arrInit[0][1] === "object") {
            haveInit = true;
          }

        }
      }

      //Valid the conditional check
      if (field.conditionals !== undefined && field.conditionals !== null && field.conditionals.length > 0) {

        //Get the actual data from form
        let currData: object[] = [];
        if (formRef.current !== null) {
          currData = formRef.current.getData() as object[];
        }

        newField = getCheckConditionalField(field, initialValue, currData, card_current);
      }

      return (
        <FieldContainer
          key={field.name + field.type + field.id_field}
          field={newField}
          onInitValue={haveInit}
          hideAutoComplete={hideAutoComplete}
          flow_id={flow_id}
          register_id={register_id}
          typeUser={typeUser !== undefined ? typeUser : "V"}
          flow_parent_id={flow_parent_id}
          register_parent_id={register_parent_id}
          card_current_id={card_current_id}
          isNewAnswer={isNewAnswer}
          isPublicForm={isPublicForm}
          public_hash={public_hash}
          activeHiddenFields={activeHiddenFields}
          card_current={card_current}
          updateFields={updateFields}
          onForceBlur={handlePreBlur}
        />
      );
    })
  };

  const handlePreBlur = useCallback(async () => {

    //Get the actual data from form
    let currData: object[] = [];
    if (formRef.current !== null) {
      currData = formRef.current.getData() as object[];
    }

    //Filter the fields that are formula fields
    const formulaFields = fields.filter((f) => f.type === "FORMULA_FIELD");

    if (formulaFields.length > 0) {

      for (let index = 0; index < formulaFields.length; index++) {
        const fField = formulaFields[index];

        const calcValue = parseFormula(fField, fields, currData);

        if (formRef.current !== null) {
          formRef.current.setFieldValue(fField.name, calcValue);
        }

      }

    }

    //Valid the conditional check
    if (setFields !== undefined && fields !== undefined && fields.length > 0) {

      for (let index = 0; index < fields.length; index++) {
        const f = fields[index];

        if (f.conditionals !== undefined && f.conditionals !== null && f.conditionals.length > 0) {
          const newField = getCheckConditionalField(f, initialValue, currData, card_current);

          setFields(fields.map((field) => {
            if (field.name === f.name) {
              return newField;
            }
            return field;
          }));
        }
      }
    }

    //Default for anothers customizations that calls the blur function   
    if (handleBlur !== undefined) {
      handleBlur();
    }

  }, [formRef, handleBlur, fields, setFields, card_current, initialValue]);

  useEffect(() => {

    /*
    //Set the cursor in the first field
    if (formRef.current && (isNewAnswer || focusOnForm)) {
      if (fields !== undefined && fields.length > 0) {
        if (formRef.current.getFieldRef(fields[0].name) !== undefined) {
          const input: HTMLInputElement = formRef.current.getFieldRef(fields[0].name);
          try {
            input?.focus();
          } catch (error) {
            console.log(error);
          }
        }
      }

    }
    */

  }, [fields, formRef, isNewAnswer, focusOnForm]);

  return (
    <>
      {!hideContainer ? (
        <Container>
          <Content>
            <FormUnform className='form-unform' id={id} ref={formRef} onSubmit={handleSubDefault} onBlur={handlePreBlur} initialData={initialValue}>
              {renderFields()}
              {children}
            </FormUnform>
          </Content>
        </Container>
      ) : (
        <FormUnformB className='form-unform' id={id} ref={formRef} onSubmit={handleSubDefault} onBlur={handlePreBlur} initialData={initialValue}>
          {renderFields()}
          {children}
        </FormUnformB>
      )}
    </>

  );
};

export default FormBuilder;
