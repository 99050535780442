import styled, { css } from "styled-components";

interface ContainerCustomColorProps {
    left?: number;
}

interface ButtonCustomColorProps {
    color?: string;
}

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin-top: 15px;
    margin-left: 5px;
    border: 1px solid hsl(0,0%,80%) !important;
    border-radius: 4px;
    overflow-x: auto;
`;

export const ButtonCustomColor = styled.button<ButtonCustomColorProps>`
    position: relative;
    margin-left: 15px;
    margin-right: 0px !important;
    width: 30px;
    height: 30px;
    min-width: 30px !important;
    min-height: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #cccccc;
    cursor: pointer;
    color: gray;
    background-color: transparent;

    transition: transform 0.3s ease;

    :hover {
        transform: scale(1.1);
    }

    ${props => props.color && props.color !== 'transparent' && css`
        background-color: ${props.color};
        color: white;
        border-color: white;
        width: 31px !important;
        height: 31px !important;
    `}
`;

export const ContainerCustomColor = styled.div<ContainerCustomColorProps>`
    position: absolute;
    top: auto;
    z-index: 1;

    ${props => props.left && css`
        left: ${props.left};
    `}
`;