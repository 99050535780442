import React, { useCallback, useRef, useState } from "react";

import {
    Content,
    ContentBody,
    DialogContainer,
    DialogTop,
    DialogTopLeft,
    DialogTopCenter,
    DialogTopRight,
    DialogFooter,
    DialogFooterCenter,
    BtnInsertNewAnswer,
    ContainerForm,
    BoxItemsContainer,
    BoxItem,
    BoxMultiple,
    BoxSpan,
    BoxSpanSoon
} from "./style";

import { AiOutlineClose } from "react-icons/ai";
import { FieldProps, Fields } from "../../components/Forms/Fields/FieldBuilder";
import { FormHandles } from "@unform/core";
import FormBuilder from "../../components/Forms/FormBuilder";
import api from "../../services/api";
import { Flow } from "../../interfaces/Flow";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";

const fieldsFlowForm: FieldProps[] = [
    {
        name: "name",
        type: Fields.CUSTOM_SELECT_FIELD,
        required: true,
        variation: "1",
        index: 0,
        title: "Nome do fluxo",
        description: "Digite o nome do fluxo",
        placeholder: "Digite aqui...",
        help_text: "Neste campo é ideal que você escolha um nome claro para o seu fluxo, pois este nome que será exibido dentro do sistema para identifica-lo",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome do Fluxo é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
]

interface flowProps {
    name: string;
}


interface CopyFlowProps {
    open: boolean;
    id_flow: number;
    name_flow: string;
    onClose: () => void;
}

const CopyFlow: React.FC<CopyFlowProps> = ({ onClose, open, id_flow, name_flow }) => {

    const { addToast } = useToast();

    const history = useHistory();
    const formRefFlow = useRef<FormHandles>(null);
    const [defaultValue] = useState<object[]>({ "name": 'Cópia de ' + name_flow } as unknown as object[]);

    const [copyFlowSteps, setCopyFlowSteps] = useState<boolean>(true);
    const [copyFlowTags, setCopyFlowTags] = useState<boolean>(true);
    const [copyFlowUsers, setCopyFlowUsers] = useState<boolean>(true);

    const [loadingInsert, setLoadingInsert] = useState<boolean>(false);

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    const handleSubmitForm = useCallback(async (data: object[]) => {

        let flowHash = "";

        if (data !== undefined) {

            const dataNormalized = data as unknown as flowProps;

            if (dataNormalized !== undefined && dataNormalized.name !== "") {

                setLoadingInsert(true);

                await api.post(`/flow/copy`, {
                    id_flow: id_flow,
                    name: dataNormalized.name,
                    copyFlowSteps: copyFlowSteps,
                    copyFlowTags: copyFlowTags,
                    copyFlowUsers: copyFlowUsers,
                }).then(response => {
                    if (response.data !== null) {

                        const respFlow: Flow = response.data;

                        if (respFlow !== undefined) {

                            flowHash = respFlow.hash;

                            onClose();

                            addToast({
                                type: 'success',
                                title: 'Yeaah! Bem vindo ao seu novo fluxo! ✌️',
                                description: 'O fluxo foi copiado com sucesso!',
                            });

                            history.push(`/flow/${flowHash}`);
                            window.location.reload();

                        }

                    }
                    setLoadingInsert(false);
                }).catch(error => {
                    setLoadingInsert(false);
                    addToast({
                        type: 'error',
                        title: 'Erro ao carregar os dados do Painel',
                        description: 'Ocorreu ao tentar carregar os dados do Painel!',
                    });
                });



            } else {
                addToast({
                    type: 'error',
                    title: 'Você deve inserir um nome para o fluxo!',
                    description: 'O nome do fluxo é obrigatório!',
                });
            }

        }

    }, [addToast, history, id_flow, copyFlowSteps, copyFlowTags, copyFlowUsers, onClose]);

    const handleSubmitPage = useCallback(async () => {
        if (formRefFlow.current !== undefined) {
            formRefFlow.current?.submitForm();
        }
    }, []);

    return (
        <DialogContainer
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
        >
            <DialogTop>
                <DialogTopLeft>
                    <h1>
                        {"Duplicar o fluxo"}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>
            <Content>
                <ContentBody container>

                    <ContainerForm>
                        <FormBuilder
                            id="formFirstStep"
                            formRef={formRefFlow}
                            fields={fieldsFlowForm}
                            initialValue={defaultValue}
                            handleSubmit={handleSubmitForm}
                            hideContainer={true}
                        />

                        <BoxItemsContainer>
                            <BoxItem key={1} onClick={() => setCopyFlowSteps(!copyFlowSteps)}>
                                <BoxMultiple>
                                    {copyFlowSteps ?
                                        <BsToggleOn color="green" /> :
                                        <BsToggleOff />
                                    }
                                </BoxMultiple>
                                <BoxSpan style={{ backgroundColor: 'transparent', paddingLeft: '0px' }}>Copiar etapas do fluxo</BoxSpan>
                            </BoxItem>

                            <BoxItem key={2} onClick={() => setCopyFlowUsers(!copyFlowUsers)}>
                                <BoxMultiple>
                                    {copyFlowUsers ?
                                        <BsToggleOn color="green" /> :
                                        <BsToggleOff />
                                    }
                                </BoxMultiple>
                                <BoxSpan style={{ backgroundColor: 'transparent', paddingLeft: '0px' }}>Copiar permissões de usuários</BoxSpan>
                            </BoxItem>

                            <BoxItem key={3} onClick={() => setCopyFlowTags(!copyFlowTags)}>
                                <BoxMultiple>
                                    {copyFlowTags ?
                                        <BsToggleOn color="green" /> :
                                        <BsToggleOff />
                                    }
                                </BoxMultiple>
                                <BoxSpan style={{ backgroundColor: 'transparent', paddingLeft: '0px' }}>Copiar etiquetas do fluxo</BoxSpan>
                            </BoxItem>

                            <BoxItem key={4}>
                                <BoxMultiple>
                                    <BsToggleOff />
                                </BoxMultiple>
                                <BoxSpan style={{ backgroundColor: 'transparent', paddingLeft: '0px' }}>Copiar automações</BoxSpan>
                                <BoxSpanSoon>Em breve</BoxSpanSoon>
                            </BoxItem>

                            <BoxItem key={5}>
                                <BoxMultiple>
                                    <BsToggleOff />
                                </BoxMultiple>
                                <BoxSpan style={{ backgroundColor: 'transparent', paddingLeft: '0px' }}>Copiar visualizações</BoxSpan>
                                <BoxSpanSoon>Em breve</BoxSpanSoon>
                            </BoxItem>

                            <BoxItem key={6}>
                                <BoxMultiple>
                                    <BsToggleOff />
                                </BoxMultiple>
                                <BoxSpan style={{ backgroundColor: 'transparent', paddingLeft: '0px' }}>Copiar cartões</BoxSpan>
                                <BoxSpanSoon>Em breve</BoxSpanSoon>
                            </BoxItem>
                        </BoxItemsContainer>
                    </ContainerForm>

                </ContentBody>
            </Content>
            <DialogFooter>
                <DialogFooterCenter>
                    <BtnInsertNewAnswer color={"#9337ed"} style={{ width: '130px', height: '36px' }} onClick={() => handleSubmitPage()} isLoading={loadingInsert}>
                        Copiar
                    </BtnInsertNewAnswer>
                </DialogFooterCenter>
            </DialogFooter>
        </DialogContainer >
    );

}

export default CopyFlow;