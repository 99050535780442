import styled, { css } from "styled-components";

interface ButtonGroupProps {
    active: boolean;
}

export const Container = styled.div`
    margin-top: -10px;
`;

export const ContainerGroup = styled.div`
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #eef2f8;
    padding-bottom: 0px;
`;

export const GroupHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
`;

export const GroupName = styled.h3`
    font-weight: 500;
    color: black;
    margin-left: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;

    svg {
        margin-right: 10px;
    }
`;

export const GroupItems = styled.span`
    position: absolute;
    right: 0px;
    font-size: 10px;
    margin-right: 5px;
    background-color: #f6ebfd;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 500;
`;

export const GroupBody = styled.div`

`;

export const GroupLoading = styled.div`
    padding: 10px;
`;

export const DividerGroup = styled.div`
    border: 1px solid #eef2f8;
    width: 100%;
`;

export const TagOrigin = styled.span`
    font-size: 10px;
    margin-right: 5px;
    background-color: #f6ebfd;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 400;
    text-transform: uppercase;
    color: black;
    margin-left: 5px;
`;

export const ContainerOptions = styled.div`    
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;

    @media(max-width: 800px){
        display: none;
    }
`;


export const ButtonGroupLeft = styled.button<ButtonGroupProps>`
    border: none;
    font-size: 12px;
    background-color: #f4f4f4;
    padding: 5px 10px;
    border-radius: 10px 0px 0px 10px;
    border-right: 1px solid #858585;
    min-width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;

    ${(props) =>
        props.active &&
        css`
        background-color: gray;
        color: white;
        font-weight: 500;
    `}
`;

export const ButtonGroupRight = styled.button<ButtonGroupProps>`
    border: none;
    font-size: 12px;
    background-color: #f4f4f4;
    padding: 5px 10px;
    border-radius: 0px 10px 10px 0px;
    min-width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;

    ${(props) =>
        props.active &&
        css`
        background-color: gray;
        color: white;
        font-weight: 500;
    `}
`;
