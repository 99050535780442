import {
    ContainerFields,
    ContentList
} from "./style";
import { FieldProps, Fields } from "../../../components/Forms/Fields/FieldBuilder";
import React, { useCallback, useEffect, useRef } from "react";

import FormBuilder from "../../../components/Forms/FormBuilder";
import { FormHandles } from "@unform/core";
import { User } from "../../../interfaces/User";
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

const fieldsForm: FieldProps[] = [
    {
        name: "nt_daily_update",
        type: Fields.CHECK_BOX_ONE_FIELD,
        required: false,
        index: 0,
        title: "Atualização diária",
        description: "Envio diário pela manhã com o resumo das tarefas atribuídas ao seu usuário",
        placeholder: "Quero receber a atualização diária das minhas tarefas"
    },
    {
        name: "nt_task_update",
        type: Fields.CHECK_BOX_ONE_FIELD,
        required: false,
        index: 1,
        title: "Atribuído a mim",
        description: "Envio das tarefas no momento em que forem atribuídas ao seu usuário",
        placeholder: "Quero receber as tarefas que forem atribuídas a mim"
    },
    {
        name: "nt_comment_update",
        type: Fields.CHECK_BOX_ONE_FIELD,
        required: false,
        index: 2,
        title: "Comentários",
        description: "Envio das notificações de comentários quando sou mencionado em uma tarefa",
        placeholder: "Quero receber notificações de comentários"
    }
]

const Notifications: React.FC = () => {

    const formRef = useRef<FormHandles>(null);
    const { user, renewUser } = useAuth();
    const { addToast } = useToast();

    const handleSavePage = useCallback(async () => {

        if (formRef.current !== null) {

            const dataNormalized = formRef.current.getData() as unknown as User;

            api
                .post('/user', {
                    id_user: user.id_user,
                    nt_daily_update: dataNormalized.nt_daily_update,
                    nt_task_update: dataNormalized.nt_task_update,
                    nt_comment_update: dataNormalized.nt_comment_update
                })
                .then(response => {

                    renewUser();

                    addToast({
                        type: 'success',
                        title: 'Perfil do usuário salvo com sucesso!',
                        description: 'O perfil do usuário foi salvo com sucesso!'
                    });

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar o perfil do usuário',
                        description: 'Ocorreu um erro ao salvar o perfil do usuário!',
                    });
                });

        }

    }, [addToast, user.id_user, renewUser]);

    const handleSubmitForm = useCallback(async (data: object[]) => {

        await handleSavePage();

    }, [handleSavePage]);

    const handleSubmitPage = useCallback(async () => {
        if (formRef.current !== undefined) {
            formRef.current?.submitForm();
        }
    }, []);

    useEffect(() => {

        if (formRef.current !== null && user !== null) {
            formRef.current.setData(user);
        }

    }, [user]);

    return (
        <ContentList>

            {/* Forms */}
            <ContainerFields item xs={12} md={6}>

                <FormBuilder
                    id="form"
                    formRef={formRef}
                    fields={fieldsForm}
                    handleSubmit={handleSubmitForm}
                    handleBlur={() => handleSubmitPage()}
                    hideContainer={true}
                />

            </ContainerFields>

        </ContentList>
    );

}

export default Notifications;