import React from "react";

import {
    ButtonActive,
    ButtonLink,
    ButtonLinkExternal,
    ButtonLinkSync,
    Container,
    FilterBarLeft,
    FilterBarRight
} from "./style";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { IoMdLink } from "react-icons/io";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { TbAdjustmentsHorizontal, TbCheck, TbExternalLink } from "react-icons/tb";

interface FormPubliConfigBarProps {
    isFromStep?: boolean;
    isLoading?: boolean;
    copiedLink?: boolean;
    activePublicForm: boolean;
    formLink?: string;
    setActivePublicForm: (status: boolean) => void;
    handleOpenConfig: () => void;
    handleCopyLink: () => void;
}

const FormPubliConfigBar: React.FC<FormPubliConfigBarProps> = ({ isFromStep, activePublicForm, setActivePublicForm, handleOpenConfig, handleCopyLink, isLoading, copiedLink, formLink }) => {

    return (
        <Container>
            <FilterBarLeft>
                <ButtonActive type="button"
                    onClick={() => setActivePublicForm(!activePublicForm)}
                >
                    {activePublicForm ? <BsToggleOn color="#61bd4f" /> : <BsToggleOff />}
                    {activePublicForm ? <div>Público</div> : <div>Não Público</div>}
                </ButtonActive>
            </FilterBarLeft>
            <FilterBarRight>

                {isLoading !== undefined && isLoading && (
                    <ButtonLinkSync>
                        <AiOutlineCloudUpload color={"gray"} />
                        Salvando
                    </ButtonLinkSync>
                )}

                {!isFromStep && activePublicForm && (
                    <ButtonLink type="button"
                        onClick={() => {
                            handleCopyLink();
                        }}
                    >
                        {copiedLink ?
                            <>
                                <TbCheck />
                                Copiado
                            </> :
                            <>
                                <IoMdLink />
                                Copiar Link
                            </>
                        }
                    </ButtonLink>
                )}

                {!isFromStep && activePublicForm && formLink && (
                    <ButtonLinkExternal type="button"
                        href={formLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <>
                            <TbExternalLink />
                            Visualizar
                        </>
                    </ButtonLinkExternal>
                )}

                <ButtonLink type="button"
                    onClick={() => {
                        handleOpenConfig();
                    }}
                >
                    <TbAdjustmentsHorizontal />
                    <div>Configurações</div>
                </ButtonLink>
            </FilterBarRight>
        </Container>
    );

}

export default FormPubliConfigBar;