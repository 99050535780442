import React, { useCallback, useEffect, useState } from "react";

import AppBarHeader, { NavigationBarItemType } from "../../components/AppBarHeader";
import { MdInsights, MdTimer } from "react-icons/md";

import LayoutContainer from '../../components/LayoutContainer';
import ListContainer from '../../components/ListContainer';
import { useAuth } from "../../hooks/auth";
import TimeSheet from "./TimeSheet";
import { FaThList } from "react-icons/fa";
import TasksManager from "./TasksManager";

const navBarItemsDefault: NavigationBarItemType[] = [
    {
        index: 0,
        description: "Tarefas",
        icon: FaThList,
        iconSize: '12',
        active: true,
        onClick: () => { }
    },
    {
        index: 1,
        description: "Time Sheet",
        icon: MdTimer,
        active: false,
        onClick: () => { }
    }
]

interface InsightPanelProps {

}

const InsightPanel: React.FC<InsightPanelProps> = () => {

    const [navBarItems, setNavBarItems] = useState(navBarItemsDefault);
    const [itemNavBarSelected, setItemNavBarSelected] = useState<number>(0);

    const { user } = useAuth();

    const changeNavBarItem = useCallback(async (index: number) => {

        setItemNavBarSelected(index);

        setNavBarItems(oldArr =>
            oldArr.map((item, idx) => {

                if (item.index === index) {
                    item.active = true;
                } else {
                    item.active = false;
                }

                return item;
            })
        );

    }, []);

    useEffect(() => {

        setNavBarItems(oldArr =>
            oldArr.map((item) => {

                if (item.index === 0) {

                    item.onClick = () => changeNavBarItem(0);

                } else if (item.index === 1) {

                    item.onClick = () => changeNavBarItem(1);

                }

                return item;
            })
        )

    }, [changeNavBarItem]);

    return (
        <LayoutContainer>
            <title>{"Painel de Insights"} | Cange</title>
            <ListContainer>

                {/* Header Page */}
                <AppBarHeader
                    key={"app-header-time-sheet"}
                    title={"Painel de Insights"}
                    icon={MdInsights}
                    navBarItems={navBarItems}
                    itemNavBar={itemNavBarSelected}
                    typeUser={user !== undefined ? user.type : ""}
                    iconDynamicColor="#f23b5d"
                />

                {itemNavBarSelected === 0 && (
                    <TasksManager />
                )}

                {itemNavBarSelected === 1 && (
                    <TimeSheet />
                )}

            </ListContainer>
        </LayoutContainer >
    );

}

export default InsightPanel;