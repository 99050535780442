import styled, { css } from "styled-components";

interface ContainerRecordProps {
    hideContainer: boolean;
}

export const ContainerRecord = styled.div<ContainerRecordProps>`
    background-color: white;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
    width: 100%;
    max-width: 700px;
    padding-right: 8%;
    padding-left: 8%;
    padding-top: 15px;
    padding-bottom: 30px;

    ${props => !!props.hideContainer && css`
        background-color: transparent !important;
        padding: 0px !important;
        box-shadow: none !important;
    `}

    :hover {
        .text-container {
            color: black;
        }
    }
`;


export const FieldBodyItem = styled.div`
  margin-top: 20px;
  
  :hover {
    .edit-field {
        svg {
            display: flex;
        }
    }
  }
`;

export const FieldBodyItemHead = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 7px;

  svg {
    min-width: 15px;
    font-size: 15px;
    font-weight: 600;
    color: #84939f;
  }

  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    margin-top: 2px;
    font-size: 12px;
    color: #84939f;
    font-weight: 500;
    text-transform: uppercase;
  }
`;

export const FieldBodyItemBody = styled.div`
  margin-left: 26px;
  margin-top: 5px;
  font-weight: 500;
  font-size: 15px;
  color: black;
`;

export const NoRegister = styled.span`
    color: gray;
    font-style: italic;
    font-weight: 400;
`;


export const EditField = styled.button`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 5px;
    background-color: transparent;
    border: none;
    min-width: 100px;

    svg {
        display: none;
        font-size: 14px;
        color: #f23c5d;
        margin-right: 5px;
        margin-left: 5px;
    }

    span {
        display: none;
        font-size: 14px;
        color: #f23c5d;
    }

    :hover {
        span {
            display: flex;
        }
    } 
`;

export const ContainerEditField = styled.div`
    border: 1px solid #f23c5d;
    padding-bottom: 20px;
    border-radius: 15px;

    form {
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        //margin-left: 20px;
        //max-width: 390px;
    }
`;

export const ContainerDynamicTextView = styled.div`

    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;

    strong {
        font-weight: 600;
    }

    ol, ul {
        padding-left: 2.5em;
    }

`;

export const ContainerEditFieldButtons = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 5px;
    
    button {
        height: 36px !important;
        width: 120px !important;
        margin-right: 15px;
    }
`;

export const ContainerEditFieldTitle = styled.div`
    background-color: #f23c5d;
    color: white;
    padding: 5px;
    font-size: 13px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    font-weight: 600;
`;

export const AutomaticData = styled.span`
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 3px;
    font-size: 13px;
    border: 1px solid rgb(238, 238, 238);
    color: rgba(51, 51, 51, 0.9);
    background-color: rgb(248, 248, 248);
`;

export const TextContainer = styled.div`

    color: #000000a1;

`;