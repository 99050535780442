import React, { createContext, useCallback, useContext, useState } from 'react';
import { Omit } from 'react-router';

import ToastContainer from '../components/ToastContainer';

export interface ToastMessage {
    id: string;
    type?: 'success' | 'error' | 'info' | 'warning';
    title: string;
    description?: string;
}

interface ToastContextData {
    addToast(message: Omit<ToastMessage, 'id'>): void;
    removeToast(id: string): void;
}

interface ToastProviderProps {
    children?: React.ReactNode;
}

const ToastContext = createContext({} as ToastContextData);

const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {

    const [ messages, setMessages] = useState<ToastMessage[]>([]);

    const addToast = useCallback(({ type, title, description }: Omit<ToastMessage, 'id'>) => {
    
        var hashLib = require('object-hash');
        const dateNowTime = new Date().getTime();

        const id = hashLib(dateNowTime + title);

        const toast = {
            id,
            type, 
            title,
            description
        }

        setMessages((state) => [...state, toast]);
    }, []);

    const removeToast = useCallback((id: string) => {
        setMessages(state => state.filter(message => message.id !== id));
    }, []);

    return (
        <ToastContext.Provider value={{ addToast, removeToast }}>
            {children}
            <ToastContainer messages={messages}/>
        </ToastContext.Provider>
    );
}

function useToast(): ToastContextData {
    const context = useContext(ToastContext);

    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }

    return context;
}

export { ToastProvider, useToast };