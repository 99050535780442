import styled, { css } from "styled-components";

interface LogStatusIconProps {
    color?: string;
}

export const LogItem = styled.div`
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #eef2f8;    
    background-color: white;
    user-select: none;
`;

export const LogItemChild = styled.div`            
    background-color: white;
    user-select: none;
    padding: 10px;
    width: 100%;
`;

export const LogBody = styled.div`
   
`;

export const LogBodyChild = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    margin-left: 30px;
    margin-top: 10px;
    width: 100%;
    max-width: 100%;
`;

export const DividerGroup = styled.div`
    border: 1px solid #eef2f8;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const LogHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center; 
    margin-right: 5px;
    margin-left: 5px;
`;

export const LogExpandIcon = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const LogStatusIcon = styled.div<LogStatusIconProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    background-color: #f23b5d;
    color: white;
    border-radius: 50%;
    font-size: 13px;
    margin-left: 10px;

    div {
        display: flex;
        font-size: 16px !important;
    }

    ${props => props.color && css`
        background-color: ${props.color};
    `}
`;

export const LogDescription = styled.div`
    margin-left: 10px;
    font-size: 13px;    
    color: #616670;
`;

export const LogItemChildTitle = styled.div`
    margin-left: 10px;
    font-size: 13px;    
    color: black;
    font-weight: 400;
`;

export const LogItemChildType = styled.div`
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 3px;
    font-size: 10px;
    font-weight: 500;
    border: 1px solid rgb(238, 238, 238);
    color: rgba(51, 51, 51, 0.9);
    background-color: rgb(248, 248, 248);
    margin-left: 10px;
`;

export const LogItemChildStatus = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;

    svg {
        font-size: 13px;
    }

    span {
        font-size: 13px;
        font-weight: 400;
        margin-left: 5px;
    }
`;

export const LogDateExec = styled.div`
    margin-left: auto;
    font-size: 12px;
    color: gray;
`;

export const LogName = styled.div`
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
`;

export const Animation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 15px;
    }
    
`;

export const ContainerLoader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 75px;
`;

export const LogItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const LogFieldContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
    margin-right: 10px;
    max-width: 100%;
`;

export const LogFieldContainerGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 10px;
`;

export const LogFieldTitle = styled.div`    
    margin-bottom: 5px;    
    margin-left: 5px;
    line-height: 1;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;    
    color: rgba(51, 51, 51, 0.9);
`;

export const LogFieldValue = styled.div`    
    color: black;
    border-radius: 5px;
    background-color: #f8f8f8;
    padding: 10px;
    font-size: 13px;    
    min-width: 150px;
    user-select: all;
    max-height: 130px;
    overflow-x: auto;
    text-overflow: ellipsis;
    margin-right: 10px;
`;