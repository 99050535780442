import styled, { css } from "styled-components";

interface ContainerProps {
    color: string;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
    border-radius: 10px;
    padding: 10px;

    svg {
        font-size: 12px;
        color: #101820;
        margin-right: 10px;
    }

    h3 {
        color: #101820;
        font-weight: 400;
        font-size: 14px;
    }

    :hover {
        ${(props) => props.color && css`
            background-color: ${props.color+"1e"};
        `}
        
        h3 {
            font-weight: 500;

            ${(props) => props.color && css`
                color: ${props.color};
            `}
        }

        svg {
            ${(props) => props.color && css`
                color: ${props.color};
            `}            
        }
    }
`;
