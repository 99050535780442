import styled from "styled-components";

import { Box } from "@material-ui/core";
import Button from "../../../../../../components/Button";

export const Container = styled.div`
    padding: 8px 24px;
`;

export const BoxContainer = styled(Box)`        
    margin-top: 0px;

    @media(max-width: 800px){
        left: 0px;
    }
`;

export const FilterContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 5px;
`;

export const SelectInput = styled.select`
    height: 35px;
    border-radius: 5px 0px 0px 5px;
    border-color: #808080bf;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 200px;
`;

export const SelectComparator = styled.select`
    height: 35px;
    border-radius: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: #a0a0a0;
    font-size: 14px;
    padding: 5px;
    color: black;
`;

export const DeleteButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #a0a0a0;
    border-radius: 5px;
    margin-left: 5px;

    height: 35px;
    width: 35px !important;
    margin-top: 0px !important;
    
    svg {
        width: 13px;
        height: 13px;
    }

    :hover {
        border: 1px solid #f23b5c;
        background-color: white;
        color: #f23b5c;
    }
`;

export const FilterBottom = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`;

export const AddNewFilter = styled.button`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: #f4f4f4;   
    border-radius: 5px;     

    svg {
        font-size: 13px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        opacity: 0.8;
    }
`;

export const CleanFilter = styled.button`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    margin-top: 5px;

    border-radius: 5px;
    padding: 5px 15px;

    background-color: transparent;
    color: gray;

    svg {
        font-size: 11px;
        margin-right: 5px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
`;

export const GoFilter = styled.button`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    margin-top: 5px;

    position: absolute;
    right: 5px;
    background: #e9f0f7;
    border-radius: 5px;
    padding: 5px 15px;

    svg {
        font-size: 13px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
`;

export const DynamicFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
    max-width: 700px;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
`;

export const DynamicFieldForm = styled.div`
    width: 100%;
    min-width: 100px;
    padding: 15px;
    padding-top: 15px;    
`;

export const LabelInput = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: black;
    margin-bottom: 5px;
`;

export const DescriptionInput = styled.div`
    font-size: 15px;
    font-weight: 500;
    color: gray;
    margin-bottom: 15px;
`;

export const SelectInputAction = styled.select`
    height: 35px;
    border-radius: 5px;
    border-color: #808080bf;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 250px;
`;

export const OptionItem = styled.option`

    svg {
        display: flex;
        font-size: 25px;
    }

`;

export const DialogFooter = styled.div`
    position: absolute;
    bottom: 0px;
    border-top: 1px solid #eef2f8;
    padding: 15px !important;
    width: 100%;
    margin-top: 25px;
`;

export const DialogFooterLeft = styled.div`
  text-align: left;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: flex-start;
  float: left;
`;

export const DialogFooterRight = styled.div`
  text-align: left;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: flex-start;
  float: right;
`;

export const ButtonEmptyState = styled(Button)`
  height: 35px;
  width: 120px;
`;