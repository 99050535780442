import styled, { css } from "styled-components";

interface NotificationHeaderIconProps {
    color?: string;
}

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;  
`;

export const ContainerNotifications = styled.div`
    width: 100%;
    max-width: 800px;
`;

export const NotificationContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    width: 100%;    
`;

export const NotificationArea = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;

    :hover {

        .notification-container {
            box-shadow: 0 0 6px rgb(0 0 0 / 20%);
        }

        .notification-action {
            box-shadow: 0 0 6px rgb(0 0 0 / 20%);
            background-color: #9e37ed;

            svg {
                color: white;
            }
        }
        
    }

`;

export const NotificationAction = styled.div`
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        color: #9e37ed;
        font-size: 15px;
    }

    transition: transform 0.3s ease;

    :hover {
        transform: scale(1.1);
    }

`;

export const NotificationHeader = styled.div`
    border-bottom: 1px solid #f4f5f7;
    padding: 5px;
    margin-bottom: 10px;
`;

export const NotificationHeaderIcon = styled.div<NotificationHeaderIconProps>`
    height: 13px;
    width: 13px;
    border-radius: 3px;
    background-color: #9e37ed;

    ${props => props.color && css`
        background-color: ${props.color};
    `}
`;

export const NotificationHeaderInfo = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
`;

export const NotificationHeaderTitle = styled.div`
    color: #252525;
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 500;
`;

export const NotificationHeaderOrigin = styled.div`
    margin-left: 5px;
    font-size: 12px;
    color: #4d4d4d;
`;

export const NotificationBody = styled.div`
    color: black;
`;

export const ContainerEmpty = styled.div`
    height: 100%;
    width: 100%;
    justify-content: center;
    display: grid;
    align-items: center;
    align-content: center;
    justify-items: center;
`;

export const ContainerLoader = styled.div`
    display: block;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    float: right;
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 30% !important;
    }
    
`;