import React from "react";
import { IconBaseProps } from "react-icons";
import { IconList, IconPickerItem } from 'react-fa-icon-picker'

import {
    ButtonsHeader,
    Container,
    IconHeader,
    IconQuickContainer,
    LeftHeader,
    NavigationBar,
    NavigationBarItem,
    NavigationBarItemIcon,
    NavigationBarPages,
    NavigationBarTitle,
    NavigationBarTitleBadge,
    RightHeader,
    DropDownMenuContainer,
    InputTitle,
    BadgeInformation
} from "./styles";
import DropDownList from "../DropDownList";
import { IoIosArrowDown } from "react-icons/io";

interface AppBarHeaderProps {
    icon: React.ComponentType<IconBaseProps>;
    title: string;
    iconDynamic?: IconList;
    iconDynamicColor?: string;
    iconQuick?: React.ComponentType<IconBaseProps>;
    navBarItems?: NavigationBarItemType[];
    children?: React.ReactNode;
    itemNavBar?: number;
    isActiveDropDownMenu?: boolean;
    isRoundedCorners?: boolean;
    dropDownMenuChildren?: React.ReactNode;
    typeUser?: string;
    titleDynamic?: string;
    badgeInformation?: string;
    maxLenTitle?: number;
    disabledTitleDynamic?: boolean;
    onClickQuick?: () => Promise<void>;
    onClickIconColorPicker?: () => void;
    setTitleDynamic?: React.Dispatch<React.SetStateAction<string>>
    onBlueTitleDynamic?: () => void;
}

export interface NavigationBarItemType {
    index: number;
    description: string;
    icon: React.ComponentType<IconBaseProps>;
    active?: boolean;
    iconSize?: string;
    badgeItems?: number;
    hideItem?: boolean;
    typeUserAllowed?: string[];
    onClick: () => void;
}

const AppBarHeader: React.FC<AppBarHeaderProps> = ({ icon: Icon, title, children, iconDynamic, badgeInformation, iconDynamicColor, iconQuick: IconQuick, onClickQuick, navBarItems, itemNavBar, isActiveDropDownMenu, dropDownMenuChildren, isRoundedCorners, typeUser, titleDynamic, maxLenTitle, disabledTitleDynamic, setTitleDynamic, onClickIconColorPicker, onBlueTitleDynamic }) => {

    const titlePage = title;

    return (
        <Container isRounded={itemNavBar === 3 || isRoundedCorners}>
            <LeftHeader className="app-bar-header-left">
                <IconHeader color={iconDynamicColor} onClick={() => onClickIconColorPicker !== undefined ? onClickIconColorPicker() : undefined}>
                    <div>
                        {iconDynamic === undefined ?
                            <Icon color={iconDynamicColor} /> :
                            <IconPickerItem
                                icon={iconDynamic}
                                color={iconDynamicColor}
                            />
                        }
                    </div>
                </IconHeader>
                <NavigationBar className="app-bar-header-left-nevigation">
                    <NavigationBarTitle className="app-bar-header-left-nevigation-title" style={setTitleDynamic !== undefined ? {} : { marginBottom: '4px' }} isFullSize={navBarItems === undefined || navBarItems.length === 0}>
                        {setTitleDynamic !== undefined ?
                            <InputTitle
                                type="input"
                                maxLength={maxLenTitle}
                                value={titleDynamic}
                                disabled={disabledTitleDynamic !== undefined ? disabledTitleDynamic : false}
                                onChange={(e) => setTitleDynamic(e.currentTarget.value)}
                                onBlur={() => onBlueTitleDynamic !== undefined ? onBlueTitleDynamic() : undefined}
                                style={navBarItems === undefined || navBarItems.length === 0 ? { marginBottom: '10px' } : {}}
                            /> :
                            <h1>{titlePage}</h1>
                        }
                        {IconQuick !== undefined && onClickQuick !== undefined ?
                            <IconQuickContainer onClick={() => onClickQuick()}>
                                <IconQuick />
                            </IconQuickContainer> :
                            <></>
                        }
                        {isActiveDropDownMenu !== undefined && isActiveDropDownMenu === true ?
                            <DropDownMenuContainer>
                                <DropDownList color={"#4d4d4d"} icon={IoIosArrowDown}>
                                    {dropDownMenuChildren}
                                </DropDownList>
                            </DropDownMenuContainer> :
                            <></>
                        }
                        {badgeInformation !== undefined ?
                            <BadgeInformation>
                                {badgeInformation}
                            </BadgeInformation> :
                            <></>
                        }
                    </NavigationBarTitle>
                    {navBarItems !== undefined && navBarItems.length > 0 ?
                        <NavigationBarPages>
                            {navBarItems?.map((item, idx) => {

                                // Acess Control
                                if (item.typeUserAllowed !== undefined) {

                                    if (typeUser === undefined) {
                                        return <div key={idx}></div>;
                                    }

                                    if (item.typeUserAllowed.indexOf(typeUser) === -1) {
                                        return <div key={idx}></div>;
                                    }
                                }

                                return (
                                    <NavigationBarItem key={idx} active={item.active} onClick={() => item.onClick()}>
                                        <NavigationBarItemIcon sizeIcon={item.iconSize}>
                                            <item.icon />
                                        </NavigationBarItemIcon>
                                        <NavigationBarTitle>
                                            {item.description}
                                            {item.badgeItems !== undefined && item.badgeItems > 0 ?
                                                <NavigationBarTitleBadge>
                                                    {item.badgeItems}
                                                </NavigationBarTitleBadge> :
                                                <></>
                                            }
                                        </NavigationBarTitle>
                                    </NavigationBarItem>
                                )
                            })}
                        </NavigationBarPages> : <></>
                    }
                </NavigationBar>
            </LeftHeader>
            <RightHeader>
                <ButtonsHeader>
                    {children}
                </ButtonsHeader>
            </RightHeader>
        </Container>
    );
};

export default AppBarHeader;