import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    margin-bottom: 10px;
    margin-top: 15px;

    :hover {
        .comment-actions {
            display: flex;
        }
    }
`;

export const User = styled.div`
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

export const BoxDetail = styled.div`
    margin-left: 3px;
    width: 100%;
`;

export const BoxDescription = styled.div`
    position: relative;
    margin-top: 5px;

    span {
        font-size: 12px;
        color: gray;
    }
`;

export const Description = styled.div`
    font-size: 14px;
    
    b {
        font-weight: 500;
    }
`;

export const BoxComment = styled.div`
    margin-top: 5px;
    overflow-wrap: anywhere;
    font-size: 14px;
    border: 1px solid #f6f8fb;
    background-color: #f6f8fb;
    border-radius: 5px;
    padding: 5px;
    padding-left: 10px;
    width: 100%;
`;

export const BoxDescriptionOptions = styled.div`
    position: absolute;
    top: 10px;
    right: 5px;
    display: none;
`;

export const BtnAction = styled.button`
    border: none;
    background-color: transparent;
    margin-left: 10px;
    color: #4d4d4d;
    font-size: 15px;

    :hover {
        color: #f23b5c;
    }
`;

export const FixedSpan = styled.span`
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 3px;
    font-size: 12px;
    border: 1px solid rgb(238,238,238);
    color: rgba(51,51,51,0.9);
    background-color: rgb(248,248,248);
    margin-left: 5px;
    font-weight: 500;

    svg {
        font-size: 10px;
        margin-right: 3px;
        padding-top: 2px;
    }
`;





