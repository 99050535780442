import React, { useCallback, useEffect, useRef, useState } from "react";
import { ResponsiveLine, Serie } from '@nivo/line';
import { Animation, ContainerLoader } from "./style";
import lottie from "lottie-web";
import { formatDateToDateRead } from "../../../utils/formatDateToDateRead";
import api from "../../../services/api";

interface AutomationsByDayChartProps {
    flow_id: number;
}

interface LineChartDataProps {
    date_update: string;
    date_formated?: string;
    total_executions: number;
}

const AutomationsByDayChart: React.FC<AutomationsByDayChartProps> = ({ flow_id }) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<Serie[]>();
    const lottieContainer = useRef<HTMLDivElement>(null);

    const getApiData = useCallback(async (id_flow: number) => {

        setLoading(true);
        api.get(`/automation/stats/executions`, {
            params: {
                flow_id: id_flow,
            }
        }).then(response => {
            if (response.data !== null) {

                const newData = response.data as LineChartDataProps[];

                if (newData !== undefined && newData !== null && newData.length > 0) {

                    //Format the date to date_formated 
                    newData.map((d) => {
                        d.date_formated = formatDateToDateRead(new Date(d.date_update));
                        return d;
                    });

                    //Transform Line Chart Data To Nivo Data
                    const lineData = [
                        {
                            id: "updates",
                            color: 'blue',
                            data: newData.map((d) => {
                                return {
                                    x: d.date_formated,
                                    y: d.total_executions
                                }
                            })
                        }
                    ] as Serie[];

                    setData(lineData);

                } else {
                    setData(undefined);
                }

            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            console.log(error);
        });

    }, []);

    useEffect(() => {

        getApiData(flow_id);

    }, [getApiData, flow_id]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../assets/lottie/loader.json')
            })
        }
    }, []);

    return (
        <>
            {loading ?
                <ContainerLoader>
                    <Animation className="lottieContainer" ref={lottieContainer} />
                </ContainerLoader > :
                <div style={{ height: '300px' }}>
                    {data !== undefined ?
                        <ResponsiveLine
                            data={data}
                            margin={{ top: 35, right: 15, bottom: 60, left: 50 }}
                            xScale={{ type: 'point' }}
                            yScale={{
                                type: 'linear',
                                min: 'auto',
                                max: 'auto',
                                stacked: true,
                                reverse: false
                            }}
                            yFormat=" >-.2f"
                            curve="catmullRom"
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Dia',
                                legendOffset: 36,
                                legendPosition: 'middle'
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Execuções',
                                legendOffset: -40,
                                legendPosition: 'middle'
                            }}
                            colors={['#9e37ed']}
                            lineWidth={3}
                            pointSize={10}
                            pointColor={{ theme: 'background' }}
                            pointBorderWidth={2}
                            pointBorderColor={{ from: 'serieColor' }}
                            enablePointLabel={true}
                            pointLabel="y"
                            pointLabelYOffset={-12}
                            enableArea={true}
                            areaOpacity={0.05}
                            useMesh={true}

                        />
                        :
                        <></>
                    }
                </div>
            }
        </>
    );

}

export default AutomationsByDayChart;