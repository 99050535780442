import styled from 'styled-components';

export const Container = styled.main`
    display: block;
    align-items: center;
    justify-content: center;
    background-color: #f4f5f7;
`;

export const ConfirmationMail = styled.div`
    width: 100%;
    //margin-top: 20px;
    background-color: #4680B8;
    color: white;
    padding: 10px;
    //padding-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-size: 14px;

    @media(max-width: 1050px){
        display: block;
        text-align: center;
    }
`;

export const BtnRefresh = styled.button`
    border: none;
    background: transparent;
    font-weight: 500;
    text-decoration: underline;
    margin-left: 5px;
    font-size: 14px;
    color: white;
`
