import styled from "styled-components";

import background from '../../assets/background-start-store.png';

export const MainContainer = styled.div`
    main {
        padding: 0px !important;
    }
`;

export const Container = styled.div`
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;    
    justify-content: center;
    background-color: #f6f8f9;
    padding: 35px;

    flex: 1;
    background: url(${background}) no-repeat center;
    background-size: cover;

    @media(max-width: 1200px) {
        padding: 0px;
    }

    @media(max-height: 800px) {
        padding: 0px;
    }

`;