import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    Content,
    ContentBody,
    DialogTop,
    DialogTopLeft,
    DialogTopCenter,
    DialogTopRight,
    IconHeader,
    DialogFooter,
    DialogFooterCenter,
    BtnInsertNewAnswer,
    BtnInsertDelete
} from "./style";

import { Dialog } from '@material-ui/core';
import { AiOutlineClose } from "react-icons/ai";
import { BiPlus, BiSave, BiTrash } from "react-icons/bi";
import { FieldProps, Fields } from "../../components/Forms/Fields/FieldBuilder";
import { FormHandles } from "@unform/core";
import FormBuilder from "../../components/Forms/FormBuilder";
import { MdOutlineViewList } from "react-icons/md";
import { FilterFlowProps } from "../../components/FilterBar/FilterDialog";
import api from "../../services/api";
import { useToast } from "../../hooks/toast";
import { FlowView } from "../../interfaces/FlowView";
import ConfirmDialog from "../ConfirmDialog";

const fieldsForm: FieldProps[] = [
    {
        name: "name_view",
        type: Fields.TEXT_SHORT_FIELD,
        index: 0,
        title: "Nome da visualização",
        description: "Digite um nome para a sua visualização",
        placeholder: "Digite aqui...",
        required: true,
        max_length: 35
    },
    {
        name: "color_view",
        type: Fields.COLOR_PICKER_FIELD,
        index: 1,
        title: "Cor",
        description: "Escolha uma cor para personalizar sua visualização"
    },
    {
        name: "icon_view",
        type: Fields.ICON_PICKER_FIELD,
        index: 2,
        title: "Ícone",
        description: "Escolha um ícone para personalizar sua visualização"
    },
    {
        name: "public_view",
        type: Fields.CHECK_BOX_ONE_FIELD,
        index: 3,
        title: "Compartilhamento",
        description: "Compartilhe esta visualização com outros membros do fluxo",
        placeholder: "Tornar público para participantes do fluxo",
    }
]

interface SaveViewFormProps {
    name_view: string;
    color_view?: string;
    icon_view?: string;
    public_view?: boolean;

}

interface AddNewViewProps {
    open: boolean;
    flow_id: number;
    schema?: FilterFlowProps;
    selectedView?: FlowView;
    onClose: (newView?: FlowView) => void;
    dialogDeleteSubmmit: () => Promise<void>;
}

const defaultValue: SaveViewFormProps = {
    name_view: "",
    color_view: "#9e37ed",
    icon_view: "FaFire"
}

const AddNewView: React.FC<AddNewViewProps> = ({ onClose, open, schema, flow_id, selectedView, dialogDeleteSubmmit }) => {

    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();

    const [initValue] = useState<SaveViewFormProps>(defaultValue);

    const [openDialogDelete, setOpenDialogDelete] = React.useState(false);    

    const handleClose = useCallback(async (newView?: FlowView) => {
        onClose(newView);
    }, [onClose]);

    const handleEditPage = useCallback(async () => {

        if (formRef.current !== null && selectedView !== undefined) {

            //Normaliza data and call the api
            const dataNormalized = formRef.current.getData() as unknown as SaveViewFormProps;

            await api
                .post('/flow-view', {
                    id_flow_view: selectedView.id_flow_view,
                    flow_id: flow_id,
                    name: dataNormalized.name_view,
                    color: dataNormalized.color_view,
                    icon: dataNormalized.icon_view,
                    isPublic: dataNormalized.public_view ? "S" : "N",
                    schema: JSON.stringify(schema)
                })
                .then(response => {
                    const newView = response.data as FlowView;

                    onClose(newView);

                    addToast({
                        type: 'success',
                        title: 'Visualização salva com sucesso!',
                        description: 'Wow! A sua visualização foi salva com sucesso! :)',
                    });
                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar a visualização!',
                        description: 'Ocorreu um erro ao salvar a visualização!',
                    });
                });

        }

    }, [addToast, flow_id, onClose, schema, selectedView]);

    const handleSavePage = useCallback(async () => {

        if (formRef.current !== null) {

            //Normaliza data and call the api
            const dataNormalized = formRef.current.getData() as unknown as SaveViewFormProps;

            await api
                .post('/flow-view', {
                    flow_id: flow_id,
                    name: dataNormalized.name_view,
                    color: dataNormalized.color_view,
                    icon: dataNormalized.icon_view,
                    isPublic: dataNormalized.public_view ? "S" : "N",
                    schema: JSON.stringify(schema)
                })
                .then(response => {
                    const newView = response.data as FlowView;

                    onClose(newView);

                    addToast({
                        type: 'success',
                        title: 'Visualização nova criada com sucesso!',
                        description: 'Wow! A sua visualização foi criada com sucesso! :)',
                    });
                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao criar a visualização!',
                        description: 'Ocorreu um erro ao criar a visualização!',
                    });
                });

        }

    }, [schema, addToast, flow_id, onClose]);

    const handleSubmitForm = useCallback(async (data: object[]) => {

        await handleSavePage();

    }, [handleSavePage]);

    useEffect(() => {

        if (open && selectedView !== undefined) {

            //Get the all flow views
            api.get(`/flow-view/`, {
                params: {
                    id_flow_view: selectedView.id_flow_view,
                    flow_id: flow_id
                }
            }).then(response => {
                if (response.data !== null) {

                    const respFlowView: FlowView = response.data;

                    const newDataForm: SaveViewFormProps = {
                        "name_view": respFlowView.name,
                        "color_view": respFlowView.color,
                        "icon_view": respFlowView.icon,
                        "public_view": respFlowView.isPublic === "S" ? true : false
                    }

                    if (formRef.current !== null) {
                        formRef.current.setData(newDataForm);
                    }

                }
            });

        }

    }, [selectedView, open, flow_id]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={() => handleClose()}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={"gray"}>
                        <div>
                            <MdOutlineViewList />
                        </div>
                    </IconHeader>
                    <h1>
                        {"Salvar visualização"}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={() => handleClose()}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>

            <Content>
                <ContentBody container>

                    {initValue !== undefined ?
                        <FormBuilder
                            id="form"
                            formRef={formRef}
                            fields={fieldsForm}
                            initialValue={initValue}
                            handleSubmit={handleSubmitForm}
                        /> :
                        <></>
                    }

                </ContentBody>
                <DialogFooter>
                    <DialogFooterCenter>
                        {selectedView === undefined ?
                            <BtnInsertNewAnswer color={"#23cd78"} icon={BiPlus} onClick={() => handleSavePage()}>
                                Criar novo
                            </BtnInsertNewAnswer> :
                            <>
                                <BtnInsertNewAnswer color={"#23cd78"} icon={BiSave} onClick={() => handleEditPage()}>
                                    Salvar
                                </BtnInsertNewAnswer>
                                <BtnInsertDelete color={"red"} icon={BiTrash} style={{ marginLeft: '15px', width: '70px' }} onClick={() => setOpenDialogDelete(true)} />
                                <ConfirmDialog
                                    open={openDialogDelete}
                                    onSubmmit={() => dialogDeleteSubmmit()}
                                    onClose={() => setOpenDialogDelete(false)}
                                    type={1}
                                    message="Você tem certeza que deseja excluir esta visualização?"
                                />
                            </>
                        }
                    </DialogFooterCenter>
                </DialogFooter>

            </Content>
        </Dialog >
    );

}

export default AddNewView;