import * as Yup from 'yup';

import { AnimationContainer, Container, Content, HelpLogin, NoAccount } from './styles';
import { FiLock, FiMail } from "react-icons/fi";
import { Link, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Button from '../../components/Button';
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import Input from '../../components/Forms/Fields/Components/Input';
import LoaderCange from '../Loader/LoaderCange';
import getValidationErrors from '../../utils/getValidationErrors';
import logoWendata from '../../assets/wendata-logo.png';
import { useAuth } from '../../hooks/auth';
import { useRouteMatch } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import { CompanyPlan } from '../../interfaces/CompanyPlan';

interface SignInFormData {
    email: string;
    password: string;
}

interface RouteParams {
    email?: string;
}

const SignIn: React.FC = () => {

    const formRef = useRef<FormHandles>(null);
    const { params } = useRouteMatch<RouteParams>();
    const { signIn } = useAuth();
    const { addToast } = useToast();
    const [loading, setLoading] = useState<boolean>(false);

    const history = useHistory();

    const handleSubmit = useCallback(async (data: SignInFormData) => {

        try {

            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                email: Yup.string()
                    .required('O campo de e-mail é obrigatório!')
                    .email('O e-mail inserido é inválido!'),
                password: Yup.string()
                    .required('O campo de senha é obrigatório!')
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            setLoading(true);

            const user = await signIn({
                email: data.email,
                password: data.password
            });

            setLoading(false);

            //Valid the company plan and redirect to the correct page
            let companyPlan: CompanyPlan | undefined = undefined;
            if (user.company !== undefined && user.company.company_plan !== undefined && user.company.company_plan.length > 0) {
                companyPlan = user.company.company_plan[0];
            }

            if (!user.isStartedTrial && companyPlan?.plan_id === 6) { // if user is not started trial
                history.push('/dashboard?action=start-trial');
            } else if (user.isEndedTrial && companyPlan?.plan_id === 6) { // if user is ended trial
                history.push('/dashboard?action=trial-ended');
            } else {
                history.push('/dashboard');
            }

        } catch (err) {
            setLoading(false);
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);
                formRef.current?.setErrors(errors);
            }

            console.log(err);

            addToast({
                type: 'error',
                title: 'Erro na autenticação',
                description: 'Ocorreu um erro ao fazer login, cheque as credenciais!',
            });
        }

    }, [signIn, addToast, history]);

    useEffect(() => {

        let isMounted = true;

        if (isMounted && formRef !== undefined) {
            if (formRef.current !== null) {
                formRef.current.setData({
                    email: params.email
                });
            }
        }

        return () => {
            isMounted = false;
        };

    }, [params.email]);

    return (
        <>
            <title>Entrar | Cange</title>
            {loading ? <LoaderCange /> :
                <Container>
                    <Content>
                        <AnimationContainer>
                            <Form ref={formRef} onSubmit={handleSubmit}>

                                <div>
                                    <img src={logoWendata} style={{ width: '95%' }} alt="wendata" />
                                </div>
                                <h1>Iniciar sessão</h1>

                                <Input tabIndex={0} name="email" icon={FiMail} placeholder="Sua conta de e-mail" />
                                <Input tabIndex={0} name="password" icon={FiLock} placeholder="Insira a sua senha" type="password" />
                                <HelpLogin tabIndex={-1}>
                                    <Link tabIndex={-1} to="/recovery" style={{ color: "#9d37ee", textDecoration: "none" }}>
                                        Problemas com o login?
                                    </Link>
                                </HelpLogin>
                                <Button tabIndex={0} type="submit" height="56px">Entrar</Button>

                                <NoAccount tabIndex={-1}>Não tem uma conta?
                                    <Link to="/onboarding/app-login" style={{ color: '#9d37ee', paddingLeft: '5px', fontWeight: 500 }}>
                                        Crie agora aqui!
                                    </Link>
                                </NoAccount>

                            </Form>
                        </AnimationContainer>
                    </Content>
                </Container>
            }
        </>
    );
}

export default SignIn;