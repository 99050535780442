import Button from "../../components/Button";
import Card from "../../dialogs/Card";
import FieldBoxOrg from "../../components/FieldBox";
import List from '@material-ui/core/List';
import styled, { css } from "styled-components";

interface ContainerBodyProps {
    hideFilterBar?: boolean;
}

export const ContainerBody = styled.div<ContainerBodyProps>`
    display: flex;
    //height: calc(100% - 115px);
    height: calc(100% - 95px);
    align-items: baseline;
    justify-content: left;
    margin-top: 10px;
    width: 100%;

    ${props => props.hideFilterBar && css`
        height: calc(100% - 60px);
    `}
`;

export const ContainerContent = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px;
`;

export const RightPanelList = styled(List)`
    width: 100%;
`;

export const CardDialogContainer = styled(Card)`

`;


export const RightPanelItems = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
`;

export const FieldBox = styled(FieldBoxOrg)`
    min-height: 30px !important;
    height: 80px !important;
    margin-bottom: 15px;
`;

export const FieldContainer = styled.div`
    margin-bottom: 15px;
`;

export const ButtonNewItemMobile = styled.button`

    display: none;

    @media(max-width: 800px){
        display: flex;
        position: absolute;
        //bottom: 15px;
        bottom: 100px;
        right: 5px;
        background: linear-gradient(145deg,rgb(255 140 47) 25%,rgb(251 50 92) 50%,rgb(147 55 237) 75%);
        font-size: 25px;
        margin-right: 0px;
        height: 60px !important;
        width: 60px !important;
        border-radius: 50%;
        z-index: 99998;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
    }

`;

export const ButtonNewItem = styled(Button)`
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    max-width: 185px !important;
    margin-right: 10px !important;
    width: auto !important;
    background: rgba(251,50,92,1);
    background: linear-gradient(90deg,rgba(255,140,47,1) 25%,rgba(251,50,92,1) 50%,rgba(147,55,237,1) 75%);

    :hover {
        background: linear-gradient(90deg, rgba(255,140,47,0.75) 25%, rgba(251,50,92,0.75) 50%, rgba(147,55,237,0.75) 75%);
    }

    div {
        margin-top: 3px !important;

        svg {
            width: 18px;
            height: 18px;
        }
    }

    @media(max-width: 800px){
        display: none;
    }
`;

export const ButtonConfig = styled(Button)`
    width: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        margin-right: 0px !important; 
        margin-top: 3px;
    }

    @media(max-width: 800px){
        display: none;
    }
`;

export const ButtonFilter = styled(Button)`
    width: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        margin-right: 0px !important; 
        margin-top: 3px;
    }
`;

export const ButtonUserAdd = styled(Button)`
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    height: 35px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #4681b8;
    background-color: white;
    border: 1px dashed #4681b8;
    overflow: hidden;
    width: 120px !important;
    margin-right: 10px !important;

    div {
        margin-top: 2px !important;

        svg {
            width: 18px;
            height: 18px;
            color: #4681b8;
        }
    }

    :hover {

        border: 1px dashed #f23b5c;
        background-color: white;
        color: #f23b5c;

        svg {
            color: #f23b5c;
        }

    }

    @media(max-width: 800px){
        display: none;
    }

`;

export const ButtonLink = styled.button`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px !important;
    height: 30px !important;
    width: 120px !important;

    svg {
        font-size: 16px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }

    @media(max-width: 800px){ 
        display: none;
    }
    
`;

export const ButtonTestMode = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-radius: 5px;
    height: 36px;
    background-color: #f23b5c;
    padding: 5px;
    border: 1px dotted red;
    margin-right: 10px !important;

    label {
        margin-left: 10px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 15px;
        color: white;
    }

    .MuiSwitch-colorSecondary.Mui-checked {
        color: white;
    }

    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: white;
    }

`;

export const MenuTestMode = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border-radius: 5px;
    padding: 5px;
    background: transparent;
    border: 1px solid #f23b5c;
    margin: 5px;
    margin-bottom: 10px;
    height: 45px;

    label {
        margin-left: 10px;
        color: black;
        font-weight: 500;
        font-size: 14px;
    }

    .MuiSwitch-colorSecondary.Mui-checked {
        color: #f23b5c;
    }

    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: #f23b5c;
    }

`;

export const DropDownInfo = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #101820;
    font-weight: 400;
    font-size: 13px;
    margin-top: 0px;
    border-radius: 10px;
    padding: 10px;

    span { 
        margin-right: 5px;
        color: grey;
    }
`;

export const AutomaticData = styled.span`
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 3px;
    font-size: 13px;
    border: 1px solid rgb(238, 238, 238);
    color: rgba(51, 51, 51, 0.9);
    background-color: rgb(248, 248, 248);
`;