import React, { useState, useCallback, useEffect, useRef } from "react";

import {
    Container,
    ContainerCenter,
    ContainerLeft,
    BoxItemsContainer,
    HeaderInboxListButton,
    ContentBody,
    ContainerBox,
    BoxHeader,
    NewEmailButton,
    BoxMenuContainer,
    BoxMenuItem,
    BoxMenuItemText,
    BoxMenuItemDetail,
    ContainerAdrress,
    TitleAddress,
    AddressValue,
    Address,
    CopyBtn,
    ContainerGrid,
    ContainerInbox,
    HeaderInboxList,
    HeaderInboxMail,
    BodyInboxList,
    CleanBox,
    CleanBoxText,
    CardContainer,
    CardContainerBlank,
    CardContainerBlankTitle,
    EmailContainerTop,
    HeaderInboxListButtonRight,
    SearchContainer,
    SearchInput,
    BoxContainer,
    BoxSearchContainer,
    SearchInputCard,
    NoResults,
    BoxContainerBody,
    CleanBoxTitle
} from "./style";
import { ClickAwayListener } from "@material-ui/core";
import { Flow } from "../../interfaces/Flow";
import CardDialog from "../../dialogs/Card";
import { FaPaperPlane } from "react-icons/fa";
import { AiFillPlusCircle, AiOutlineDelete, AiOutlineInbox, AiOutlineMail, AiOutlineSend, AiOutlineSetting, AiOutlineStar, AiOutlineUser, AiOutlineUsergroupAdd } from "react-icons/ai";
import { TbArrowsDiagonal2, TbArrowsDiagonalMinimize, TbBrandMailgun, TbCheck, TbCopy, TbReload, TbSearch } from "react-icons/tb";
import { INBOUND_DOMAIN } from "../../config/app";
import { Email } from "../../interfaces/Email";
import api from "../../services/api";
import { EmailPostmark } from "../../interfaces/EmailPostmark";
import EmailList from "./EmailList";
import { BsMailbox2 } from "react-icons/bs";
import EmailContainer from "../../dialogs/EmailDialog/Email";
import { Card } from "../../interfaces/Card";
import ItemRegister, { ComboItem } from "../ItemRegister";
import formAnswerToComboItemFromFlow from "../../utils/formAnswerToComboItemFromFlow";
import getKanbanConfig from "../../utils/KanbanConfig/getKanbanConfig";
import { useAuth } from "../../hooks/auth";
import { IoIosCloseCircle } from "react-icons/io";
import EmailDialog from "../../dialogs/EmailDialog";
import ContentLoader from "react-content-loader";
import { IoMailOpenOutline } from "react-icons/io5";
import ConfigEmail from "../../dialogs/ConfigEmail";
import getAccessControl from "../../middlewares/AccessControl";
import { useMediaQuery } from "react-responsive";

const LoaderContainer = () => (
    <ContentLoader
        speed={2}
        width={'99%'}
        height={65}
        viewBox="0 0 500 65"
        backgroundColor="#ffffff"
        foregroundColor="#f3f3f3"
    >
        <rect x="0" y="0" rx="3" ry="3" width="99%" height="50" />
    </ContentLoader>
)

interface EmailBoxProps {
    flow: Flow;
    typeUser: string;
}

const EmailBox: React.FC<EmailBoxProps> = ({ flow, typeUser }) => {

    const { user } = useAuth();
    const [copied, setCopied] = useState<boolean>(false);
    const [emails, setEmails] = useState<Email[]>();
    const [emailsFiltered, setEmailsFiltered] = useState<Email[]>();
    const [emailSelected, setEmailSelected] = useState<Email>();
    const [cardSelected, setCardSelected] = useState<Card>();
    const [isExpanded, setIsExpanded] = useState<boolean>();
    const [comboItemSelected, setComboItemSelected] = useState<ComboItem>();
    const [openMail, setOpenMail] = useState(false);
    const [menuIndex, setMenuIndex] = useState<number>(1);
    const [openCard, setOpenCard] = useState<boolean>(false);
    const [openConfig, setOpenConfig] = useState<boolean>(false);

    const [openSelectCard, setOpenSelectCard] = useState<boolean>(false);
    const [searchValue] = useState<string>();
    const [loadingCards, setLoadingCards] = useState<boolean>(false);

    const [items, setItems] = useState<ComboItem[]>();
    const [itemsFiltered, setItemsFiltered] = useState<ComboItem[]>();

    const [searchText, setSearchText] = useState<string>("");
    const [isSearchingEmail, setIsSearchingEmail] = useState<boolean>();
    const searchInputRef = useRef<HTMLInputElement>(null);

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const flowAddress = flow?.hash + "@" + INBOUND_DOMAIN;

    const handleCopyClick = () => {
        navigator.clipboard.writeText(flowAddress);

        setCopied(true);
    };

    const handleClickAway = () => {
        setOpenSelectCard(false);
    };

    const getApiEmails = useCallback(async (withDeleted?: boolean) => {

        if (flow !== undefined && flow.id_flow !== undefined) {

            await api.get(`/email/by-flow`, {
                params: {
                    flow_id: flow.id_flow,
                    withDeleted: withDeleted
                }
            }).then(response => {
                if (response.data !== null) {

                    let apiRetEmail: Email[] = response.data;

                    apiRetEmail = apiRetEmail.map((e) => {

                        if (e.json_recived !== undefined) {
                            const emailParsed: EmailPostmark = JSON.parse(e.json_recived);
                            e.emailPostmark = emailParsed;
                        }

                        return e;
                    })

                    setEmails(apiRetEmail);

                }
            }).catch(error => {
            });

        }

    }, [flow]);

    const getApiCard = useCallback(async (id_card: number) => {

        if (flow !== undefined && flow.id_flow !== undefined) {

            await api.get(`/card`, {
                params: {
                    id_card: id_card,
                    flow_id: flow.id_flow
                }
            }).then(response => {
                if (response.data !== null) {

                    let apiRetCard: Card = response.data;

                    const newComboItem: ComboItem = formAnswerToComboItemFromFlow(apiRetCard, flow);

                    setComboItemSelected(newComboItem);
                    setCardSelected(apiRetCard);

                }
            }).catch(error => {
            });

        }

    }, [flow]);

    const cardOpen = useCallback(async () => {
        setOpenCard(true);
    }, []);

    const cardClose = useCallback(async () => {
        setOpenCard(false);

        getApiEmails(true);

        if (emailSelected !== undefined && emailSelected.card_id !== undefined) {
            await getApiCard(emailSelected.card_id);
        }
    }, [getApiEmails, emailSelected, getApiCard]);

    const selectEmail = useCallback(async (email: Email) => {

        setEmailSelected(email);

        if (email.card_id !== undefined && email.card_id !== null) {
            await getApiCard(email.card_id);
        } else {
            setCardSelected(undefined);
            setComboItemSelected(undefined);
        }

    }, [getApiCard]);

    const markReadStatus = useCallback(async (email: Email, status: string) => {

        if (flow !== undefined) {
            await api.post(`/email/preferences`, {
                id_email: email.id_email,
                flow_id: flow?.id_flow,
                isRead: status
            })

            getApiEmails(true);
        }

    }, [flow, getApiEmails]);

    const markStarredtatus = useCallback(async (email: Email, status: string) => {

        if (flow !== undefined) {
            await api.post(`/email/preferences`, {
                id_email: email.id_email,
                flow_id: flow?.id_flow,
                isStarred: status
            })

            getApiEmails(true);
        }

    }, [flow, getApiEmails]);

    const moveToTrash = useCallback(async (email: Email, status: string) => {

        if (flow !== undefined) {
            await api.post(`/email/preferences`, {
                id_email: email.id_email,
                flow_id: flow?.id_flow,
                isDeleted: status
            })

            getApiEmails(true);
        }

    }, [flow, getApiEmails]);

    const openEmailDialog = useCallback(async (email: Email) => {
        if (email.isRead !== "S" && flow !== undefined) {
            await api.post(`/email/preferences`, {
                id_email: email.id_email,
                flow_id: flow?.id_flow,
                isRead: "S"
            })

            getApiEmails(true);
        }

        setEmailSelected(email);
    }, [flow, getApiEmails]);

    const closeEmailDialog = useCallback(async () => {

        setOpenMail(false);
        getApiEmails(true);

    }, [getApiEmails]);

    const handleFilterEmails = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {

        setSearchText(e?.currentTarget.value);

        if (emails !== undefined) {

            const searchText = e.currentTarget.value.toLowerCase();

            let itemsNew = emails;

            //Create logic to dynamic content
            const itemsTitle = itemsNew.filter((item) => {

                //Filter by subject
                if (item.subject !== undefined && item.subject !== null) {
                    if (item.subject.toLowerCase().includes(searchText)) {
                        return true;
                    }
                }

                //Filter by from
                if (item.from !== undefined && item.from !== null) {
                    if (item.from.toLowerCase().includes(searchText)) {
                        return true;
                    }
                }

                //Filter by from_name
                if (item.from_name !== undefined && item.from_name !== null) {
                    if (item.from_name.toLowerCase().includes(searchText)) {
                        return true;
                    }
                }

                //Filter by to
                if (item.to !== undefined && item.to !== null) {
                    if (item.to.toLowerCase().includes(searchText)) {
                        return true;
                    }
                }

                //Filter by cc
                if (item.cc !== undefined && item.cc !== null) {
                    if (item.cc.toLowerCase().includes(searchText)) {
                        return true;
                    }
                }

                //Filter by cco
                if (item.cco !== undefined && item.cco !== null) {
                    if (item.cco.toLowerCase().includes(searchText)) {
                        return true;
                    }
                }

                return false;

            });

            setEmailsFiltered(itemsTitle);

        }

    }, [emails]);

    const handleFilterCards = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {

        if (items !== undefined) {

            const searchText = e.currentTarget.value.toLowerCase();

            let itemsNew = items;

            //Create logic to dynamic content
            const itemsTitle = itemsNew.filter((item) => {

                //Filter by title
                if (item.title.toLowerCase().includes(searchText)) {
                    return true;
                }

                //Filter by description
                if (item.origin !== undefined) {
                    if (item.origin.toLowerCase().includes(searchText)) {
                        return true;
                    }
                }

                //Filter by details
                if (item.details !== undefined && item.details.length > 0) {
                    for (let index = 0; index < item.details.length; index++) {
                        const detail = item.details[index];

                        if (detail.value.toLowerCase().includes(searchText)) {
                            return true;
                        }

                    }
                }

                return false;

            });

            setItemsFiltered(itemsTitle);

        }

    }, [items]);

    const handleSelectCard = useCallback(async (id_register?: number) => {

        if (id_register !== undefined) {
            await getApiCard(id_register);

            //Add the card_id into the email
            if (emailSelected !== undefined) {
                await api.post(`/email/preferences`, {
                    id_email: emailSelected.id_email,
                    flow_id: flow?.id_flow,
                    card_id: id_register
                })

                getApiEmails(true);
            }

            setOpenSelectCard(false);
        }

    }, [getApiCard, emailSelected, getApiEmails, flow?.id_flow]);

    const handleRemoveCard = useCallback(async (item: ComboItem) => {

        if (item.id_register !== undefined) {
            setComboItemSelected(undefined);
            setCardSelected(undefined);

            //Add the card_id into the email
            if (emailSelected !== undefined) {
                await api.post(`/email/preferences`, {
                    id_email: emailSelected.id_email,
                    flow_id: flow?.id_flow,
                    card_id: 0
                })

                getApiEmails(true);
            }

            setOpenSelectCard(false);
        }

    }, [emailSelected, getApiEmails, flow?.id_flow]);

    const getApiCards = useCallback(async () => {

        setLoadingCards(true);

        await api.get(`/flow`, {
            params: {
                id_flow: flow.id_flow,
                withSteps: true,
                withCards: true
            }
        }).then(response => {
            if (response.data !== null) {
                const flowApi: Flow = response.data;

                let itemsNew: ComboItem[] = [];

                if (flowApi.flow_steps !== undefined) {
                    for (let index = 0; index < flowApi.flow_steps.length; index++) {
                        const element = flowApi.flow_steps[index];

                        if (element.cards !== undefined) {
                            for (let idxB = 0; idxB < element.cards.length; idxB++) {

                                const newComboItem: ComboItem = formAnswerToComboItemFromFlow(element.cards[idxB], flowApi);

                                itemsNew.push(newComboItem);

                            }
                        }
                    }

                    setItems(itemsNew);
                    setItemsFiltered(itemsNew);

                }
            }
            setLoadingCards(false);
        }).catch(error => {
            setLoadingCards(false);
            console.log(error);
        });

    }, [flow.id_flow]);

    useEffect(() => {

        getApiEmails(true);

    }, [getApiEmails]);

    useEffect(() => {

        if (menuIndex === 1) { //Caixa de entrada

            setEmailsFiltered(
                emails?.filter((e) => e.isDeleted !== "S" && e.message_stream === "inbound")
            );

        } else if (menuIndex === 2) { //Não lidas

            setEmailsFiltered(
                emails?.filter((e) => (e.isRead === undefined || e.isRead === null || e.isRead === "N" || e.id_email === emailSelected?.id_email) && e.isDeleted !== "S")
            )

        } else if (menuIndex === 3) { //Sem responsável

            setEmailsFiltered(
                emails?.filter((e) => e.isDeleted !== "S" && (e.card === undefined || e.card === null || e.card.user_id === undefined || e.card.user_id === null) && e.message_stream === "inbound")
            )

        } else if (menuIndex === 4) { //Atribuído a mim

            setEmailsFiltered(
                emails?.filter((e) => e.isDeleted !== "S" && e.card !== undefined && e.card !== null && e.card.user_id !== undefined && e.card.user_id !== null && e.card.user_id === user.id_user && e.message_stream === "inbound")
            )

        } else if (menuIndex === 5) { //Enviados

            setEmailsFiltered(
                emails?.filter((e) => e.from.includes(flow.hash) && e.isDeleted !== "S")
            )

        } else if (menuIndex === 6) { //Lixeira            

            setEmailsFiltered(
                emails?.filter((e) => e.isDeleted === "S")
            )

        } else if (menuIndex === 7) { //Favoritos            

            setEmailsFiltered(
                emails?.filter((e) => e.isStarred === "S" && e.isDeleted !== "S")
            )

        }

    }, [menuIndex, emails, flow.hash, getApiEmails, emailSelected?.id_email, user.id_user])

    useEffect(() => {

        //When change the menu, reset the selected email
        setEmailSelected(undefined);
        setCardSelected(undefined);
        setComboItemSelected(undefined);

        getApiEmails(true);

    }, [menuIndex, getApiEmails]);

    useEffect(() => {

        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }

    }, [isSearchingEmail]);

    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setSearchText('');
                setIsSearchingEmail(false);
            }
        };

        window.addEventListener('keydown', handleEscape);

        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, []);

    useEffect(() => {

        if (openSelectCard) {
            getApiCards();
        }

    }, [openSelectCard, getApiCards])

    return (
        <Container>
            <ContentBody>
                <ContainerLeft
                    item
                    xs={4}
                    sm={3}
                    md={3}
                    lg={2}
                    style={{ marginBottom: '10px' }}
                >
                    <ContainerBox style={{ marginRight: '10px', padding: '10px', position: 'relative' }}>
                        <BoxHeader>
                            Caixa de Entrada
                        </BoxHeader>

                        {getAccessControl(59, typeUser) ?
                            <NewEmailButton onClick={() => {
                                setOpenMail(true);
                                setEmailSelected(undefined);
                            }}>
                                <FaPaperPlane />
                                Nova Mensagem
                            </NewEmailButton>
                            :<></>}

                        <EmailDialog
                            open={openMail}
                            typeUser={typeUser}
                            flow={flow}
                            flowAddress={flowAddress}
                            onClose={() => closeEmailDialog()}
                            email={emailSelected}
                        />

                        <BoxMenuContainer>

                            <BoxMenuItem onClick={() => setMenuIndex(1)} isSelect={menuIndex === 1}>
                                <AiOutlineInbox className="box-menu-item-icon" />
                                <BoxMenuItemText className="box-menu-item-text">
                                    Caixa de Entrada
                                </BoxMenuItemText>
                            </BoxMenuItem>

                            <BoxMenuItem onClick={() => setMenuIndex(2)} isSelect={menuIndex === 2}>
                                <AiOutlineMail className="box-menu-item-icon" />
                                <BoxMenuItemText className="box-menu-item-text">
                                    Não lidas
                                </BoxMenuItemText>
                                {emails && emails?.filter((e) =>
                                    e.isDeleted !== "S" &&
                                    (e.isRead === undefined || e.isRead === null || e.isRead === "N")).length > 0 ?
                                    <BoxMenuItemDetail className="box-menu-item-detail">
                                        {emails?.filter((e) =>
                                            e.isDeleted !== "S" &&
                                            (e.isRead === undefined || e.isRead === null || e.isRead === "N")).length}
                                    </BoxMenuItemDetail> :
                                    <></>
                                }
                            </BoxMenuItem>

                            <BoxMenuItem onClick={() => setMenuIndex(3)} isSelect={menuIndex === 3}>
                                <AiOutlineUsergroupAdd className="box-menu-item-icon" />
                                <BoxMenuItemText className="box-menu-item-text">
                                    Sem responsável
                                </BoxMenuItemText>
                                {emails && emails?.filter((e) =>
                                    e.isDeleted !== "S" &&
                                    (e.card === undefined || e.card === null || e.card.user_id === undefined || e.card.user_id === null) &&
                                    (e.isRead === undefined || e.isRead === null || e.isRead === "N")
                                ).length > 0 ?
                                    <BoxMenuItemDetail className="box-menu-item-detail">
                                        {emails?.filter((e) =>
                                            e.isDeleted !== "S" &&
                                            (e.card === undefined || e.card === null || e.card.user_id === undefined || e.card.user_id === null) &&
                                            (e.isRead === undefined || e.isRead === null || e.isRead === "N")
                                        ).length}
                                    </BoxMenuItemDetail> :
                                    <></>
                                }
                            </BoxMenuItem>

                            <BoxMenuItem onClick={() => setMenuIndex(4)} isSelect={menuIndex === 4}>
                                <AiOutlineUser className="box-menu-item-icon" />
                                <BoxMenuItemText className="box-menu-item-text">
                                    Atribuído a mim
                                </BoxMenuItemText>
                                {emails && emails?.filter((e) =>
                                    e.isDeleted !== "S" &&
                                    (e.card !== undefined && e.card !== null && e.card.user_id !== undefined && e.card.user_id === user.id_user) &&
                                    (e.isRead === undefined || e.isRead === null || e.isRead === "N")
                                ).length ?
                                    <BoxMenuItemDetail className="box-menu-item-detail">
                                        {emails?.filter((e) =>
                                            e.isDeleted !== "S" &&
                                            (e.card !== undefined && e.card !== null && e.card.user_id !== undefined && e.card.user_id === user.id_user) &&
                                            (e.isRead === undefined || e.isRead === null || e.isRead === "N")
                                        ).length}
                                    </BoxMenuItemDetail> :
                                    <></>
                                }
                            </BoxMenuItem>

                            <BoxMenuItem onClick={() => setMenuIndex(7)} isSelect={menuIndex === 7}>
                                <AiOutlineStar className="box-menu-item-icon" />
                                <BoxMenuItemText className="box-menu-item-text">
                                    Favoritos
                                </BoxMenuItemText>
                            </BoxMenuItem>

                            <BoxMenuItem onClick={() => setMenuIndex(5)} isSelect={menuIndex === 5}>
                                <AiOutlineSend className="box-menu-item-icon" />
                                <BoxMenuItemText className="box-menu-item-text">
                                    Enviados
                                </BoxMenuItemText>
                            </BoxMenuItem>

                            <BoxMenuItem onClick={() => setMenuIndex(6)} isSelect={menuIndex === 6}>
                                <AiOutlineDelete className="box-menu-item-icon" />
                                <BoxMenuItemText className="box-menu-item-text">
                                    Lixeira
                                </BoxMenuItemText>
                            </BoxMenuItem>

                        </BoxMenuContainer>

                        {getAccessControl(60, typeUser) ?
                            <BoxMenuItem onClick={() => setOpenConfig(true)} style={{ position: 'absolute', bottom: '105px', width: 'calc(100% - 20px)' }}>
                                <AiOutlineSetting className="box-menu-item-icon" />
                                <BoxMenuItemText className="box-menu-item-text">
                                    Configurar
                                </BoxMenuItemText>
                            </BoxMenuItem>
                            : <></>}


                        <ConfigEmail
                            open={openConfig}
                            onClose={() => setOpenConfig(false)}
                            flow_id={flow.id_flow}
                        />

                        <ContainerAdrress>
                            <TitleAddress>
                                <TbBrandMailgun />
                                <h3>Endereço de e-mail</h3>
                            </TitleAddress>
                            <AddressValue>
                                <Address>{flowAddress}</Address>
                                <CopyBtn onClick={handleCopyClick}>
                                    {copied ?
                                        <>
                                            <TbCheck />
                                            Copiado
                                        </> :
                                        <>
                                            <TbCopy />
                                            Copiar
                                        </>
                                    }
                                </CopyBtn>
                            </AddressValue>
                        </ContainerAdrress>
                    </ContainerBox>
                </ContainerLeft>
                <ContainerCenter
                    item
                    xs={8}
                    sm={9}
                    md={9}
                    lg={10}
                    style={{ marginBottom: '10px' }}
                >
                    <ContainerInbox>

                        {emails?.length === 0 ?
                            <CleanBox>
                                <BsMailbox2 />
                                <CleanBoxTitle>
                                    Esta é a caixa de entrada do seu fluxo, aqui você pode enviar e receber mensagens.
                                </CleanBoxTitle>
                                <CleanBoxText>
                                    Você ainda não possui mensagens mas você pode começar enviando uma nova mensagem ou utilizando o endereço do seu fluxo para receber mensagens.
                                </CleanBoxText>
                            </CleanBox> :
                            <>
                                <ContainerGrid
                                    item
                                    xs={5}
                                    sm={5}
                                    md={5}
                                    lg={5}
                                    style={{ borderRight: '1px solid #f4f5f7', height: '100%' }}
                                >
                                    <HeaderInboxList>
                                        {isSearchingEmail ?
                                            <SearchContainer>
                                                <TbSearch />
                                                <SearchInput
                                                    id="search-input"
                                                    ref={searchInputRef}
                                                    onChange={(e) => handleFilterEmails(e)}
                                                    placeholder="Busque aqui..."
                                                    value={searchText}
                                                />
                                                <IoIosCloseCircle
                                                    style={{ marginRight: "9px", position: 'absolute', right: '10px' }}
                                                    onClick={() => {
                                                        setSearchText("");
                                                        setIsSearchingEmail(false);
                                                        getApiEmails(true);
                                                    }}
                                                />
                                            </SearchContainer> :
                                            <>
                                                <HeaderInboxListButton title="Atualizar a lista" onClick={() => getApiEmails(true)}>
                                                    <TbReload />
                                                </HeaderInboxListButton>

                                                <HeaderInboxListButton
                                                    id="button-search"
                                                    title="Pesquisar por uma mensagem"
                                                    onClick={() => {
                                                        setIsSearchingEmail(true);
                                                    }}>
                                                    <TbSearch />
                                                </HeaderInboxListButton>

                                                <HeaderInboxListButtonRight title="Expandir a visualização" onClick={() => setIsExpanded(!isExpanded)}>
                                                    {isExpanded ?
                                                        <TbArrowsDiagonalMinimize /> :
                                                        <TbArrowsDiagonal2 />
                                                    }
                                                </HeaderInboxListButtonRight>
                                            </>
                                        }
                                    </HeaderInboxList>
                                    <BodyInboxList>
                                        {emailsFiltered?.length === 0 ?
                                            menuIndex === 1 ?
                                                <CleanBox style={{ width: 'auto', textAlign: 'center', border: 'none', alignContent: 'baseline' }}>
                                                    <CleanBoxTitle style={{ fontSize: '17px', color: 'black' }}>
                                                        Você ainda não possui mensagens recebidas! <span>&#128513;</span>
                                                    </CleanBoxTitle>
                                                    <CleanBoxText style={{ fontSize: '13px' }}>
                                                        Compartilhe o endereço de e-mail do seu fluxo ou de algum cartão para receber mensagens.
                                                    </CleanBoxText>
                                                </CleanBox> :
                                                menuIndex === 2 ?
                                                    <CleanBox style={{ width: 'auto', textAlign: 'center', border: 'none', alignContent: 'baseline' }}>
                                                        <CleanBoxTitle style={{ fontSize: '17px', color: 'black' }}>
                                                            Todas as mensagens foram lidas, relaxe! <span>&#x1F64C;</span>
                                                        </CleanBoxTitle>
                                                        <CleanBoxText style={{ fontSize: '13px' }}>
                                                            Fique tranquilo, quando chegar uma mensagem nova, iremos exibir aqui para você.
                                                        </CleanBoxText>
                                                    </CleanBox> :
                                                    menuIndex === 3 ?
                                                        <CleanBox style={{ width: 'auto', textAlign: 'center', border: 'none', alignContent: 'baseline' }}>
                                                            <CleanBoxTitle style={{ fontSize: '17px', color: 'black' }}>
                                                                Todas as mensagens foram endereçadas para alguém! <span>&#x1F609;</span>
                                                            </CleanBoxTitle>
                                                            <CleanBoxText style={{ fontSize: '13px' }}>
                                                                Fique tranquilo, quando chegar uma mensagem sem responsável, iremos exibir aqui para você.
                                                            </CleanBoxText>
                                                        </CleanBox> :
                                                        menuIndex === 4 ?
                                                            <CleanBox style={{ width: 'auto', textAlign: 'center', border: 'none', alignContent: 'baseline' }}>
                                                                <CleanBoxTitle style={{ fontSize: '17px', color: 'black' }}>
                                                                    Você não tem mensagens atribuídas para você! <span>&#x1F60A;</span>
                                                                </CleanBoxTitle>
                                                                <CleanBoxText style={{ fontSize: '13px' }}>
                                                                    Fique tranquilo, quando alguma mensagem for atribuída para você, iremos exibir aqui para você.
                                                                </CleanBoxText>
                                                            </CleanBox> :
                                                            menuIndex === 5 ?
                                                                <CleanBox style={{ width: 'auto', textAlign: 'center', border: 'none', alignContent: 'baseline' }}>
                                                                    <CleanBoxTitle style={{ fontSize: '17px', color: 'black' }}>
                                                                        Você não tem mensagens enviadas ainda! <span>&#128513;</span>
                                                                    </CleanBoxTitle>
                                                                    <CleanBoxText style={{ fontSize: '13px' }}>
                                                                        Envie mensagens para comunicar as pessoas sobre os feitos do seu fluxo.
                                                                    </CleanBoxText>
                                                                </CleanBox> :
                                                                menuIndex === 6 ?
                                                                    <CleanBox style={{ width: 'auto', textAlign: 'center', border: 'none', alignContent: 'baseline' }}>
                                                                        <CleanBoxTitle style={{ fontSize: '17px', color: 'black' }}>
                                                                            Você não tem mensagens na lixeira! <span>&#128465;</span>
                                                                        </CleanBoxTitle>
                                                                        <CleanBoxText style={{ fontSize: '13px' }}>
                                                                            Quando uma mensagem não for mais importante para você, você pode excluir ela.
                                                                        </CleanBoxText>
                                                                    </CleanBox> :
                                                                    menuIndex === 7 ?
                                                                        <CleanBox style={{ width: 'auto', textAlign: 'center', border: 'none', alignContent: 'baseline' }}>
                                                                            <CleanBoxTitle style={{ fontSize: '17px', color: 'black' }}>
                                                                                Você não tem mensagens favoritadas ainda! <span>&#x2B50;</span>
                                                                            </CleanBoxTitle>
                                                                            <CleanBoxText style={{ fontSize: '13px' }}>
                                                                                Utilize este recurso para deixar marcadas as mensagens que são importantes para você.
                                                                            </CleanBoxText>
                                                                        </CleanBox> :
                                                                        <></> :
                                            <EmailList
                                                emails={emailsFiltered}
                                                typeUser={typeUser}
                                                selectedEmail={emailSelected}
                                                selectEmail={selectEmail}
                                                markReadStatus={markReadStatus}
                                                markStarredtatus={markStarredtatus}
                                                moveToTrash={moveToTrash}
                                                openEmailDialog={openEmailDialog}
                                            />
                                        }
                                    </BodyInboxList>
                                </ContainerGrid>
                                <ContainerGrid
                                    item
                                    xs={7}
                                    sm={7}
                                    md={7}
                                    lg={7}
                                    style={{ height: 'calc(100vh - 85px)', position: 'relative', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}
                                >
                                    <HeaderInboxMail>

                                        {comboItemSelected !== undefined ?
                                            <CardContainer>
                                                <ItemRegister
                                                    key={comboItemSelected.id_register}
                                                    type={'Flow'}
                                                    item={comboItemSelected}
                                                    handleClick={cardOpen}
                                                    hideDetails={!isExpanded}
                                                    handleRemove={getAccessControl(61, typeUser) ? handleRemoveCard : undefined}
                                                />
                                            </CardContainer> : emailSelected !== undefined && getAccessControl(61, typeUser) ?
                                                <>
                                                    <CardContainerBlank onClick={() => {
                                                        setOpenSelectCard(true)
                                                    }}>
                                                        <AiFillPlusCircle />
                                                        <CardContainerBlankTitle>
                                                            Vincule esta mensagem a um cartão existente
                                                        </CardContainerBlankTitle>
                                                        {openSelectCard ? (

                                                            <BoxContainer>
                                                                <ClickAwayListener onClickAway={handleClickAway}>
                                                                    <BoxContainerBody>
                                                                        <BoxSearchContainer>
                                                                            <SearchInputCard
                                                                                placeholder="Buscar Registros"
                                                                                defaultValue={searchValue}
                                                                                onChange={handleFilterCards}
                                                                            />
                                                                        </BoxSearchContainer>

                                                                        <BoxItemsContainer>

                                                                            {loadingCards ?
                                                                                <>
                                                                                    <LoaderContainer />
                                                                                    <LoaderContainer />
                                                                                    <LoaderContainer />
                                                                                </> :
                                                                                <></>
                                                                            }

                                                                            {!loadingCards && itemsFiltered !== undefined && itemsFiltered?.length > 0 ?
                                                                                itemsFiltered?.map((item) => {
                                                                                    return (
                                                                                        <ItemRegister
                                                                                            key={item.id_register}
                                                                                            type={'Flow'}
                                                                                            item={item}
                                                                                            hideDetails={!isExpanded}
                                                                                            handleClick={handleSelectCard}
                                                                                        />
                                                                                    )
                                                                                }) :
                                                                                !loadingCards && (
                                                                                    <NoResults>Sem Registros</NoResults>
                                                                                )}
                                                                        </BoxItemsContainer>
                                                                    </BoxContainerBody>
                                                                </ClickAwayListener>
                                                            </BoxContainer>

                                                        ) : <></>}
                                                    </CardContainerBlank>
                                                </> :
                                                <></>
                                        }

                                        {emailSelected !== undefined ?
                                            <EmailContainerTop>
                                                <EmailContainer
                                                    typeUser={typeUser}
                                                    id_card={emailSelected?.card_id}
                                                    flow={flow}
                                                    flowAddress={flowAddress}
                                                    email={emailSelected}
                                                />
                                            </EmailContainerTop> :
                                            <CleanBox style={{ width: 'auto', textAlign: 'center' }}>
                                                <IoMailOpenOutline />
                                                <CleanBoxTitle>
                                                    Nenhuma mensagem selecionada
                                                </CleanBoxTitle>
                                                <CleanBoxText>
                                                    Aqui você poderá visualizar o conteúdo da mensagem selecionada, bem como vincular a um cartão existente.
                                                </CleanBoxText>
                                            </CleanBox>
                                        }

                                        {openCard && cardSelected !== undefined && flow !== undefined && (
                                            <CardDialog
                                                open={openCard}
                                                isMobile={isMobile}
                                                flow={flow}
                                                kanban_config={getKanbanConfig(flow)}
                                                selectedValue={cardSelected}
                                                onClose={cardClose}
                                                typeUser={typeUser}
                                            />
                                        )}
                                    </HeaderInboxMail>
                                </ContainerGrid>
                            </>
                        }
                    </ContainerInbox>
                </ContainerCenter>
            </ContentBody>
        </Container>
    );

}

export default EmailBox;