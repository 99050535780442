import styled, { css } from "styled-components";
import Button from "../../../components/Button";

interface ConditionalsItemProps {
    color?: string;
}

interface ConditionalsItemIconTriggerProps {
    color?: string;
}

export const Container = styled.div`

`;

export const ContainerConditionals = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;

export const ConditionalsItem = styled.div<ConditionalsItemProps>`
    width: 100%;
    border: 1px solid #eef2f8;
    border-radius: 10px;
    padding: 10px 15px;
    padding-right: 20px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    cursor: grab;

    :hover {
        border-color: gray;
    }

    ${props => props.color && css` 
         border-color: ${props.color + '70'};
    
        :hover {
            border-color: ${props.color};
        }
    `}
`;

export const ConditionalsItemIconTrigger = styled.div<ConditionalsItemIconTriggerProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    background-color: #f23b5d;
    color: white;
    border-radius: 5px;
    font-size: 13px;

    div {
        display: flex;
        font-size: 16px !important;
    }

    ${props => props.color && css`
        background-color: ${props.color};
    `}
`;

export const ConditionalsItemIconName = styled.div`
    margin-left: 10px;

    h3 {
        font-size: 15px;
        color: black;
        white-space: nowrap;
        overflow: hidden;
        max-width: 55ch;
        text-overflow: ellipsis;
    }

    span {
        color: gray;
        font-size: 12px;
    }
`;

export const ConditionalsItemLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
`;

export const ConditionalsItemRight = styled.div`
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const ContainerConditionalsHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    padding-bottom: 0px;
    padding-top: 10px;
`;

export const AddNewConditionalButton = styled(Button)`
    width: 200px;
    font-size: 15px;
    height: 35px;
`;

export const ContainerConditionalsTip = styled.div`
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 15px;
`;

export const ButtonEdit = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 10px;
    border: none;
    background-color: transparent;

    :hover {
        background-color: #f23b5c;
        color: white;
    }
`;

export const DropDownMenuContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 5px;
    font-size: 12px;

    .col-hed-conf {
        padding: 10px;
        border-radius: 50%;

        :hover {
            background-color: #f23b5d;
            color: white;
        }
    }

    .col-hed-con-conf {
        min-width: 250px;
        max-width: 250px;
    }
`;