import {
    BottomInfo,
    Container,
    Content,
    ElementsItems,
    InfoDescription,
    InfoImage,
    InfoTitle,
    LeftSide,
    MenuItem,
    MenuItemIcon,
    MenuItems,
    RightSide,
    Section,
    SectionTitle
} from "./style";
import { CardElement, Elements } from "../../../components/ElementBuilder";
import { IconList, IconPickerItem } from "react-fa-icon-picker";

import React from "react";
import imgInsights from '../../../assets/premium-features/element-insights.png';

interface ElementStoreProps {
    onSelectElement: (element: string) => void;
}

const ElementStore: React.FC<ElementStoreProps> = ({ onSelectElement }) => {

    return (
        <Container>
            <LeftSide>

                <MenuItems>
                    <MenuItem
                        color={"#2096f3"}
                        selectedStyle={true}
                    >
                        <MenuItemIcon color={"#2096f3"}>
                            <IconPickerItem
                                icon={"FaStar" as IconList}
                                color={"#2096f3"}
                            />
                        </MenuItemIcon>
                        Recomendados
                    </MenuItem>

                    <MenuItem
                        selectedStyle={false}
                        color={"#9d37ed"}
                    >
                        <MenuItemIcon color={"#9d37ed"}>
                            <IconPickerItem
                                icon={"FaChartBar" as IconList}
                                color={"#9d37ed"}
                            />
                        </MenuItemIcon>
                        Gráficos
                    </MenuItem>

                    <MenuItem
                        selectedStyle={false}
                        color={"#f23b5c"}
                    >
                        <MenuItemIcon color={"#f23b5c"}>
                            <IconPickerItem
                                icon={"FaTachometerAlt" as IconList}
                                color={"#f23b5c"}
                            />
                        </MenuItemIcon>
                        Números
                    </MenuItem>

                    {/* 
                    <MenuItem
                        href={"#sec4"}
                        selectedStyle={false}
                        color={"#fe8c2f"}
                    >
                        <MenuItemIcon color={"#fe8c2f"}>
                            <IconPickerItem
                                icon={"FaTable" as IconList}
                                color={"#fe8c2f"}
                            />
                        </MenuItemIcon>
                        Tabelas
                    </MenuItem>
                    */}

                    <MenuItem
                        selectedStyle={false}
                        color={"#795548"}
                    >
                        <MenuItemIcon color={"#795548"}>
                            <IconPickerItem
                                icon={"FaCode" as IconList}
                                color={"#795548"}
                            />
                        </MenuItemIcon>
                        Outros
                    </MenuItem>
                </MenuItems>


                <BottomInfo>
                    <InfoImage>
                        <img src={imgInsights} alt={"Adicione novos elementos e gere grandes insights do seu negócio"} />
                    </InfoImage>
                    <InfoTitle>
                        Adicione novos elementos e gere grandes insights do seu negócio
                    </InfoTitle>
                    <InfoDescription>
                        Insira elementos no seu fluxo para criar novas maneiras de ver suas informações e tomar as melhores decisões
                    </InfoDescription>
                </BottomInfo>
            </LeftSide >
            <RightSide>
                <Content>

                    <Section id={"sec1"}>
                        <SectionTitle>
                            Recomendados
                        </SectionTitle>
                        <ElementsItems>

                            {/* NUMBER CHART*/}
                            <CardElement type={Elements.NUMBER_CHART} onSelectElement={() => onSelectElement(Elements.NUMBER_CHART)} />

                            {/* COLUMN CHART*/}
                            <CardElement type={Elements.COLUMN_CHART} onSelectElement={() => onSelectElement(Elements.COLUMN_CHART)} />

                            {/* PIE CHART*/}
                            <CardElement type={Elements.PIE_CHART} onSelectElement={() => onSelectElement(Elements.PIE_CHART)} />

                            {/* LINE CHART */}
                            <CardElement type={Elements.LINE_CHART} onSelectElement={() => onSelectElement(Elements.LINE_CHART)} />

                        </ElementsItems>
                    </Section>

                    <Section id={"sec2"}>
                        <SectionTitle>
                            Gráficos de barras
                        </SectionTitle>
                        <ElementsItems>

                            {/* COLUMN CHART*/}
                            <CardElement type={Elements.COLUMN_CHART} onSelectElement={() => onSelectElement(Elements.COLUMN_CHART)} />

                            {/* BAR CHART*/}
                            <CardElement type={Elements.BAR_CHART} onSelectElement={() => onSelectElement(Elements.BAR_CHART)} />

                        </ElementsItems>
                    </Section>

                    <Section id={"sec3"}>
                        <SectionTitle>
                            Gráficos de linhas
                        </SectionTitle>
                        <ElementsItems>

                            {/* LINE CHART */}
                            <CardElement type={Elements.LINE_CHART} onSelectElement={() => onSelectElement(Elements.LINE_CHART)} />

                            {/* LINE CHART STACKED */}
                            <CardElement type={Elements.LINE_CHART_STACKED} onSelectElement={() => onSelectElement(Elements.LINE_CHART_STACKED)} />

                        </ElementsItems>
                    </Section>


                    <Section id={"sec2"}>
                        <SectionTitle>
                            Gráficos de pizza
                        </SectionTitle>
                        <ElementsItems>

                            {/* PIE CHART*/}
                            <CardElement type={Elements.PIE_CHART} onSelectElement={() => onSelectElement(Elements.PIE_CHART)} />

                            {/* DONUT CHART*/}
                            <CardElement type={Elements.DONUT_CHART} onSelectElement={() => onSelectElement(Elements.DONUT_CHART)} />

                            {/* QUARTER CHART*/}
                            <CardElement type={Elements.QUARTER_CHART} onSelectElement={() => onSelectElement(Elements.QUARTER_CHART)} />

                        </ElementsItems>
                    </Section>


                    <Section id={"sec2"}>
                        <SectionTitle>
                            Gráficos avançados
                        </SectionTitle>
                        <ElementsItems>

                            {/* FUNNEL CHART*/}
                            <CardElement type={Elements.FUNNEL_CHART} onSelectElement={() => onSelectElement(Elements.FUNNEL_CHART)} rotationIcon={270} />

                            {/* FUNNEL CHART VERTICAL*/}
                            <CardElement type={Elements.FUNNEL_CHART_VERTICAL} onSelectElement={() => onSelectElement(Elements.FUNNEL_CHART_VERTICAL)} />

                            {/* HEAT MAP */}
                            <CardElement type={Elements.HEAT_MAP_CHART} onSelectElement={() => onSelectElement(Elements.HEAT_MAP_CHART)} />

                        </ElementsItems>
                    </Section>


                    <Section id={"sec3"}>
                        <SectionTitle>
                            Números
                        </SectionTitle>
                        <ElementsItems>

                            {/* NUMBER CHART*/}
                            <CardElement type={Elements.NUMBER_CHART} onSelectElement={() => onSelectElement(Elements.NUMBER_CHART)} />

                        </ElementsItems>
                    </Section>

                    {/*
                    <Section id={"localSEO"}>
                        <SectionTitle>
                            Tabelas
                        </SectionTitle>
                        <ElementsItems>

                            {/* SIMPLE TABLE 
                            <CardElement type={Elements.SIMPLE_TABLE} onSelectElement={() => onSelectElement(Elements.SIMPLE_TABLE)} />

                        </ElementsItems>
                    </Section>
                    */}

                    <Section id={"sec5"}>
                        <SectionTitle>
                            Outros
                        </SectionTitle>
                        <ElementsItems>

                            {/* EMBED */}
                            <CardElement type={Elements.EMBED} onSelectElement={() => onSelectElement(Elements.EMBED)} />

                        </ElementsItems>
                    </Section>

                </Content>
            </RightSide>
        </Container >
    );

}

export default ElementStore;