import React, { useCallback, useEffect, useState } from "react";
import { format } from 'date-fns';

import convertDMYtoDate from "../../../../utils/convertDMYtoDate";
import { BoxContainer, BoxItem, BoxItemsContainer, BoxSpan, BoxSpanDynamic, BtnCloseDate, Container, DatePickerInput, Input, NoResults, SelectInput, SelectInputValue } from "./style";
import { FilterComponentProps } from "../../FilterDialog";
import { Box, ClickAwayListener } from "@material-ui/core";
import { IoIosCloseCircle } from "react-icons/io";

interface OptionFilter {
    key: string;
    label: string;
    selected?: boolean;
}

const FilterDate: React.FC<FilterComponentProps> = ({ comparator, onEvent, valueOptions, options }) => {

    const [date, setDate] = useState<Date>(new Date());
    const [isBlank, setIsBlank] = useState<boolean>(false);
    const [onPicker, setOnPicker] = useState<boolean>(false);
    const [onlyPicker, setOnlyPicker] = useState<boolean>(false);

    const [open, setOpen] = useState(false);
    const [optionsList, setOptionsList] = useState<OptionFilter[]>();
    const [optionsFiltered, setOptionsFiltered] = useState<OptionFilter[]>();

    const handleClickAway = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setOpen(true);
    };

    const onChange = (d: Date) => {

        const stringDate: string = format(
            d,
            "dd/MM/yyyy"
        );

        setDate(d);
        onEvent(undefined, [stringDate]);

    };

    const handleCloseOnPicker = () => {

        setOnPicker(false);

        const newOptions = optionsList?.map((opt) => {
            opt.selected = false;
            return opt;
        });

        setOptionsList(newOptions);

        onEvent(undefined, []);

    }

    const handleFilter = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {

        if (optionsList !== undefined) {

            const searchText = e.currentTarget.value.toLowerCase();

            let optNew = optionsList;

            const optFiltered = optNew.filter((opt) => opt.label.toLowerCase().includes(searchText));

            setOptionsFiltered(optFiltered);

        }


    }, [optionsList]);

    const handleSelect = useCallback((option: OptionFilter) => {

        const newCondition: string[] = [];

        //Update the Options, set selected or no
        setOptionsList(optionsList?.map((opt) => {
            if (opt.key === option.key) {
                opt.selected = true;
                newCondition.push(opt.key);
            } else {
                opt.selected = false;
            }
            return opt;
        }));

        if (option.key === "-4") {
            /*
            const stringDate: string = format(
                new Date(),
                "dd/MM/yyyy"
            );
            */

            setOnPicker(true);
            setDate(new Date());
            //onEvent(undefined, [stringDate]);
        } else {
            onEvent(undefined, newCondition);
        }

        setOpen(false);

    }, [optionsList, onEvent]);

    useEffect(() => {

        const optionsDefault: OptionFilter[] = [
            {
                key: '-1',
                label: "Hoje",
                selected: false
            },
            {
                key: '-2',
                label: "Ontem",
                selected: false
            },
            {
                key: '-3',
                label: "Amanhã",
                selected: false
            },

            {
                key: '-4',
                label: "Data Exata",
                selected: false
            },

            {
                key: '-5',
                label: "Próximos 7 dias",
                selected: false
            },
            {
                key: '-6',
                label: "Últimos 7 dias",
                selected: false
            },

            {
                key: '-7',
                label: "Esta Semana",
                selected: false
            },
            {
                key: '-8',
                label: "Semana passada",
                selected: false
            },
            {
                key: '-9',
                label: "Próxima semana",
                selected: false
            },

            {
                key: '-10',
                label: "Este mês",
                selected: false
            },
            {
                key: '-11',
                label: "Mês passado",
                selected: false
            },
            {
                key: '-12',
                label: "Próximo mês",
                selected: false
            },

            {
                key: '-13',
                label: "Este trimestre",
                selected: false
            },
            {
                key: '-14',
                label: "Trimestre passado",
                selected: false
            },
            {
                key: '-15',
                label: "Próximo trimestre",
                selected: false
            },

            {
                key: '-16',
                label: "Este ano",
                selected: false
            },
            {
                key: '-17',
                label: "Ano passado",
                selected: false
            },
            {
                key: '-18',
                label: "Próximo ano",
                selected: false
            },

            //Últimos X 
            //Próximos X

        ]

        const newValueOptions = valueOptions !== undefined ? valueOptions : [];
        const newOptions = [];

        newOptions.push(...optionsDefault);

        if (newValueOptions.length > 0) {

            //Valid if the value is a date or a key from the options
            if (newValueOptions[0].includes("/")) {

                const nopt = newOptions.map((opt) => {

                    if (opt.key === "-4") {
                        opt.selected = true;
                    }

                    return opt;
                });

                const date = convertDMYtoDate(newValueOptions[0]);

                setDate(date);
                setOnPicker(true);
                setOptionsList(nopt);
                setOptionsFiltered(nopt);

            } else {

                const nopt = newOptions.map((opt) => {

                    opt.selected = newValueOptions.filter((cond) => cond === opt.key).length >= 1;

                    return opt;
                });

                setOptionsList(nopt);
                setOptionsFiltered(nopt);
            }

        } else {

            const nopt = newOptions.map((opt) => {

                opt.selected = false;

                return opt;
            });

            setOptionsList(nopt);
            setOptionsFiltered(nopt);

        }

        if (comparator === "Está em branco" || comparator === "Não está em branco") {
            setIsBlank(true);
        } else {
            setIsBlank(false);
        }

        if (comparator !== "Igual a") {
            setOnlyPicker(true);
            setOnPicker(true);
        } else {
            setOnlyPicker(false);
        }

    }, [comparator, valueOptions, options]);

    return (

        <>
            <ClickAwayListener onClickAway={handleClickAway} >
                <Box style={{ position: "relative" }}>
                    {isBlank ?
                        <Input disabled={true} /> :
                        <>
                            {open || optionsList === undefined || optionsList?.filter((opt) => opt.selected === true).length <= 0 ?
                                <Input
                                    placeholder="Clique aqui..."
                                    onClick={handleClick}
                                    onChange={handleFilter}
                                /> :
                                !onPicker ?
                                    <SelectInput onClick={handleClick}>
                                        <SelectInputValue>
                                            {optionsList?.filter((opt) => opt.selected === true).map((opt, idx) => {

                                                if (idx === 0 && opt.key !== "-4") {
                                                    return (
                                                        <BoxSpan key={opt.key}>{opt.label}</BoxSpan>
                                                    )
                                                }
                                                return <></>;
                                            })}
                                        </SelectInputValue>
                                    </SelectInput> :

                                    <Container>
                                        <DatePickerInput
                                            selected={date}
                                            onChange={(d: Date) => onChange(d)}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Escolha aqui..."
                                        />
                                        {!onlyPicker && (
                                            <BtnCloseDate onClick={() => handleCloseOnPicker()}>
                                                <IoIosCloseCircle />
                                            </BtnCloseDate>
                                        )}
                                    </Container>
                            }

                            {open ? (
                                <BoxContainer>
                                    <BoxItemsContainer>
                                        {optionsFiltered !== undefined && optionsFiltered?.length > 0 ?
                                            optionsFiltered?.map((option) => {
                                                return (
                                                    <BoxItem key={option.key} onClick={() => handleSelect(option)}>
                                                        <BoxSpan color={option.key === "-4" ? "grey" : undefined}>{option.label}</BoxSpan>
                                                        {option.key === "-4" && (
                                                            <BoxSpanDynamic title="Com esta opção você pode definir um valor fixo para o seu filtro">Valor fixo</BoxSpanDynamic>
                                                        )}
                                                    </BoxItem>
                                                )
                                            }) :
                                            <NoResults>Sem registro</NoResults>
                                        }
                                    </BoxItemsContainer>
                                </BoxContainer>
                            ) : null}
                        </>
                    }
                </Box>
            </ClickAwayListener>
        </>

    );
}

export default FilterDate;