import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import styled, { css } from "styled-components";
import Button from '../../../../components/Button';

interface IconHeaderProps {
    color: string;
}

interface BtnInsertNewAnswerProps {
    color: string;
}

export const Content = styled(DialogContent)`
    background-color: white;   
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: center;
    height: auto;
    width: auto;
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const DialogTop = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #eef2f8;
    margin-top: 15px;
    width: 100%;
    
`;

export const DialogTopLeft = styled.div`
    width: 65%;
    height: 100%;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: start;
    margin-left: 15px;

    h1 {
        margin-left: 20px;
        font-size: 20px;
        font-weight: 500;
        color: black;
    }
`;

export const DialogTopCenter = styled.div`
    width: 25%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
`;

export const DialogTopRight = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 30px;
        border: none;
        background-color: transparent;
    }
`;

export const IconHeader = styled.span<IconHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 100%;
    background-color: transparent;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 45px;
        height: 45px;
        background-color: #f4f4f4;
        border-radius: 50%;

        > svg {
            width: 25px;
            height: 25px;

            ${props => props.color && css`
                color: ${props.color} !important;
            `}
        }

    }
`;

export const DialogFooter = styled.div`
  border-top: 1px solid #eef2f8;
  padding: 15px !important;
  width: 100%;
`;

export const DialogFooterCenter = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
`;

export const BtnInsertNewAnswer = styled(Button)<BtnInsertNewAnswerProps>`
    height: 50px;
    width: 95%;
    background-color: #f23b5c;
    color: white;
    border-radius: 5px;
    border: 0;
    font-weight: 500;

    ${props => props.color && css`
        background-color: ${props.color} !important;
    `}
`;

export const ContainerAdrress = styled.div`
    border-radius: 8px;
    padding: 10px;
    border: 1px solid #84939f;
    bottom: 20px;
    width: 100%;
`;

export const TitleAddress = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 18px;

    h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;
        font-size: 12px;
        color: #84939f;
        font-weight: 500;
        text-transform: uppercase;
    }

    svg {
        min-width: 15px;
        font-size: 15px;
        font-weight: 600;
        color: #84939f;
    }

`;

export const AddressValue = styled.div`
    padding: 10px;
    background-color: #f4f5f7;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`;

export const Address = styled.div`
    margin-right: 10px;
    font-size: 12px;
    overflow: hidden;
`;

export const CopyBtn = styled.button`
    position: absolute;
    right: 5px;
    font-weight: 500;
    padding: 3px 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #9e37ed;
    border: none;
    background-color: white;
    border-radius: 10px;

    svg {
        margin-right: 5px;
        color: #9e37ed;
    }

    :hover {
        background-color: #9e37ed;
        color: white;

        svg {
            color: white;
        }
    }
`;

export const HelpContainer = styled.div`
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 25px;
    border-radius: 10px;
    background-color: #fff2e8;
`;

export const HelpItems = styled.div`
    padding: 15px;
    padding-bottom: 5px;
    width: 100%;
    height: 100%;
`;

export const HelpItem = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    align-items: stretch;
`;

export const HelpIcon = styled.div`
    width: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;

    svg {
        font-size: 25px;
        color: #ff8c2e;
    }
`;

export const HelpText = styled.div`
    width: 85%;
    font-size: 13px;
    font-weight: 500;
`;