import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    svg {   
        height: 20px;
        width: 20px;
    }
    
    margin-bottom: 2px;
    margin-right: 0.015px;
`;
