import React, { ButtonHTMLAttributes } from 'react';
import { Container, ContentContainer } from './styles';

export interface DataListProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    color?: string;
}

const DataList: React.FC<DataListProps> = ({ children, style, ...rest }) => {
    return (
        <Container style={style} >   
            <ContentContainer>        
                {children}
            </ContentContainer> 
        </Container>
    );
}

export default DataList;