import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast'
import { TrackingProvider } from './tracking';

interface AppProviderProps {
    children?: React.ReactNode;
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => (
    <TrackingProvider>
        <AuthProvider>
            <ToastProvider>
                {children}
            </ToastProvider>
        </AuthProvider>
    </TrackingProvider>
);

export default AppProvider;
