import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    Content,
    ContentBody,
    Animation,
    ContainerAnimation,
    ConfettiArea,
    TrialCounterContainer,
    ActionArea,
    ButtonsArea,
    StartButton,
    MainDialog,
    ContentRight,
    ContentTop,
    ContentBottom,
    ContentIcon,
    ContentCTA,
    ContentButton,
    ContentPreDescription,
    MainContent
} from "./style";

import { useMediaQuery } from "react-responsive";
import Lottie from "lottie-web";
import api from "../../services/api";
import { useToast } from "../../hooks/toast";
import { FaYoutube } from "react-icons/fa";
import { useAuth } from "../../hooks/auth";

interface StartTrialProps {
    open: boolean;
    onClose: () => void;
}

const linkWhatsapp = "https://api.whatsapp.com/send?phone=551151989897&text=Fiz%20meu%20cadastro%20no%20Cange%20e%20preciso%20de%20suporte";
const linkYoutube = "https://www.youtube.com/@wecange";

const StartTrial: React.FC<StartTrialProps> = ({ onClose, open }) => {

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const lottieContainer = useRef<HTMLDivElement>(null);
    const [activeExit, setActiveExit] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>('');

    const { addToast } = useToast();
    const { user } = useAuth();

    const handleClose = useCallback(() => {
        setActiveExit(true);

        setTimeout(() => {
            onClose();
        }, 1000);
    }, [onClose]);

    const startTrial = useCallback(async () => {

        await api
            .post('/company/start-trial', {})
            .then(response => {

                handleClose();

            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Não foi possível iniciar o trial!',
                    description: error.response.data.message,
                });

                handleClose();
            });

    }, [addToast, handleClose]);

    useEffect(() => {

        const delayedFunction = () => {
            if (lottieContainer.current) {
                Lottie.loadAnimation({
                    container: lottieContainer.current,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    animationData: require('../../assets/lottie/confetti.json')
                })
            }
        };

        const timeoutId = setTimeout(delayedFunction, 2000);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {

        if (user.name) {
            const name = user.name.split(' ');
            setFirstName(name[0]);
        }

    }, [user.name]);

    return (
        !isMobile ?
            <MainDialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                isMobile={false}
                fullScreen={isMobile}
                style={{ zIndex: '99999', borderRadius: '10px' }}
                isVisible={activeExit}
            >
                <Content>
                    <MainContent>
                        <ContentBody>
                            <ContainerAnimation>
                                <ConfettiArea style={{ position: 'relative' }}>
                                    <Animation className="lottieContainer" ref={lottieContainer} />
                                    <h3>Bem-vindo(a){firstName !== "" ? ", " + firstName : ""} 🎉</h3>
                                    <h1 style={{ marginBottom: '20px !important' }}>Comece com o pé direito, são <b style={{ color: "#f23b5d" }}>15 dias</b> de teste grátis</h1>
                                    <TrialCounterContainer days={15} size={'max'} color="#2ee31e" />
                                </ConfettiArea>
                                <ActionArea>
                                    <h2> Liberamos todas as funcionalidades para você testar tudo!</h2>
                                    <h3 style={{ color: '#000000ad' }}>Após este período você pode escolher o plano que melhor funciona para você. Você está pronto para turbinar a gestão de processos e tarefas?</h3>
                                </ActionArea>
                                <ButtonsArea>
                                    <StartButton onClick={() => {
                                        startTrial();
                                    }}>
                                        Começar
                                    </StartButton>
                                </ButtonsArea>
                            </ContainerAnimation>

                        </ContentBody>
                        <ContentRight>
                            <ContentTop>
                                <ContentIcon>
                                    <FaYoutube color="#f23b5d" />
                                </ContentIcon>
                                <ContentPreDescription>
                                    Para você aproveitar mais
                                </ContentPreDescription>
                                <ContentCTA>
                                    Quer aprender a usar o melhor do Cange?
                                </ContentCTA>
                                <ContentButton color="#03bcd4" href={linkYoutube} target="_blank" >
                                    Assistir
                                </ContentButton>
                            </ContentTop>
                            <ContentBottom>
                                <ContentIcon>
                                    🙋
                                </ContentIcon>
                                <ContentPreDescription>
                                    Suporte em português
                                </ContentPreDescription>
                                <ContentCTA>
                                    Prefere falar com alguém para começar?
                                </ContentCTA>
                                <ContentButton color="#673ab7" href={linkWhatsapp} target="_blank">
                                    Fale com a gente
                                </ContentButton>
                            </ContentBottom>
                        </ContentRight>
                    </MainContent>
                </Content>
            </MainDialog > :
            <MainDialog
                fullWidth={true}
                maxWidth="sm"
                open={open}
                isMobile={isMobile}
                fullScreen={isMobile}
                style={{ zIndex: '99999' }}
                isVisible={activeExit}
            >
                <Content>
                    <MainContent>
                        <ContentBody>
                            <ContainerAnimation>
                                <ConfettiArea style={{ position: 'relative' }}>
                                    <Animation className="lottieContainer" ref={lottieContainer} />
                                    <h3>Bem-vindo(a){firstName !== "" ? ", " + firstName : ""} 🎉</h3>
                                    <h1 style={{ marginBottom: '20px !important' }}>Comece com o pé direito, são <b style={{ color: "#f23b5d" }}>15 dias</b> de teste grátis</h1>
                                    <TrialCounterContainer days={15} size={'max'} color="#2ee31e" />
                                </ConfettiArea>
                                <ActionArea>
                                    <h2> Liberamos todas as funcionalidades para você testar tudo!</h2>
                                    <h3 style={{ color: '#000000ad' }}>Após este período você pode escolher o plano que melhor funciona para você. Você está pronto para turbinar a gestão de processos e tarefas?</h3>
                                </ActionArea>
                                <ButtonsArea>
                                    <StartButton onClick={() => {
                                        startTrial();
                                    }}>
                                        Começar
                                    </StartButton>
                                </ButtonsArea>
                            </ContainerAnimation>

                        </ContentBody>
                        <ContentRight>
                            <ContentTop>
                                <ContentIcon>
                                    <FaYoutube color="#f23b5d" />
                                </ContentIcon>
                                <ContentPreDescription>
                                    Para você aproveitar mais
                                </ContentPreDescription>
                                <ContentCTA>
                                    Quer aprender a usar o melhor do Cange?
                                </ContentCTA>
                                <ContentButton color="#03bcd4" href={linkYoutube} target="_blank" >
                                    Assistir
                                </ContentButton>
                            </ContentTop>
                            <ContentBottom>
                                <ContentIcon>
                                    🙋
                                </ContentIcon>
                                <ContentPreDescription>
                                    Suporte em português
                                </ContentPreDescription>
                                <ContentCTA>
                                    Prefere falar com alguém para começar?
                                </ContentCTA>
                                <ContentButton color="#673ab7" href={linkWhatsapp} target="_blank">
                                    Fale com a gente
                                </ContentButton>
                            </ContentBottom>
                        </ContentRight>
                    </MainContent>
                </Content>
            </MainDialog >
    );

}

export default StartTrial;