import styled from "styled-components";

export const Container = styled.div`
    grid-column: span 1 / auto;
    
    button {
        background: var(--light-base);
        box-shadow: inset 0 0 0 1px var(--gray-400);
        border-radius: var(--radius-medium);
        height: 100%;
        width: 100%;
        max-width: 400px;
        margin: auto;
        display: flex;
        flex-direction: column;
        -webkit-box-align: stretch;
        align-items: stretch;
        transition: box-shadow var(--productive) var(--ease-out);
        border-radius: 15px;
        position: relative;
        box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
    }
`;

export const BtnTemplate = styled.button`
    background: var(--light-base);
    box-shadow: inset 0 0 0 1px var(--gray-400);
    border-radius: var(--radius-medium);
    height: 100%;
    width: 100%;
    max-width: 400px;
    min-height: 275px;
    margin: auto;
    display: flex;
    flex-direction: column;
    -webkit-box-align: stretch;
    align-items: stretch;
    transition: box-shadow var(--productive) var(--ease-out);
    border-radius: 15px;
    border: 1px dashed #9337ed;
    position: relative;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);

    :hover {
            background-color: #9337ed0d;
            border: 1px dashed #9337ed;
    }
`;

export const FlowNewItemContainer = styled.div`
    width: 100%;
    height: 50%;
`

export const FlowNewItemButton = styled.div`
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;

    width: 70px;
    height: 60px;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 20%;

    background-color: #9337ed;
    border-radius: 10px;

    svg {
        color: white;
        height: 40px;
        width: 40px;
        padding: 5px;
        margin-top: 5px;
    }

`;

export const FlowNewContainerBottom = styled.div`
    height: 100%;

    margin-top: 5%;

    display: block;
    justify-content: center;
    align-items: center;

    white-space: normal;
    overflow-wrap: anywhere;

    text-align: center;

    margin-right: 10px;
    margin-left: 10px;

    h2 {
        color: black;
        text-align: center;
        margin-top: 8px;
        font-weight: 500;
        font-size: 18px;
        margin-block-end: unset;
        margin-bottom: 10px;
    }

`;

export const TemplateDescription = styled.div`
    margin-top: 5px;
    margin-bottom: 5px;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    color: #979797;
    max-height: 65px;
    overflow: hidden;
`;