import React from "react";
import { IconBaseProps } from "react-icons";
import { IconList, IconPickerItem } from 'react-fa-icon-picker'

import { ButtonsHeader, Container, IconHeader, LeftHeader, RightHeader } from "./styles";

interface PageHeaderProps {
    icon: React.ComponentType<IconBaseProps>;
    title: string;
    iconDynamic?: IconList;
    iconDynamicColor?: string;
    iconQuick?: React.ComponentType<IconBaseProps>;
    children?: React.ReactNode;
    onClickQuick?: () => Promise<void>;
}

const PageHeaderWorkspace: React.FC<PageHeaderProps> = ({ icon: Icon, title, children, iconDynamic, iconDynamicColor, iconQuick: IconQuick, onClickQuick }) => {

    const titlePage = title;

    return (
        <Container color={iconDynamicColor}>
            <LeftHeader>
                <IconHeader color={iconDynamicColor}>
                    <div>
                        {iconDynamic === undefined ?
                            <Icon color={iconDynamicColor} /> :
                            <IconPickerItem
                                icon={iconDynamic}
                                color={iconDynamicColor}
                            />
                        }
                    </div>
                </IconHeader>
                <h1>{titlePage}</h1>
                {IconQuick !== undefined && onClickQuick !== undefined ?
                    <div onClick={() => onClickQuick()}>
                        <IconQuick />
                    </div> :
                    <></>
                }
            </LeftHeader>
            <RightHeader>
                <ButtonsHeader>
                    {children}
                </ButtonsHeader>
            </RightHeader>
        </Container>
    );
};

export default PageHeaderWorkspace;