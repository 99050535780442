import styled from "styled-components";

export const Container = styled.div`
    background-color: white;
    border-radius: 10px;
    height: calc(90vh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
`;

export const ContainerImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    img {
        width: 80%;
        max-width: 800px;
    }
`;

export const ContainerTitle = styled.div`
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;    
    color: black;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 15px;
`;

export const ContainerSubTitle = styled.div`    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    color: gray;
    text-align: center;
    width: 80%;
    font-size: 15px;
    color: #000000ab;
    margin-bottom: 15px;
`;

