import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    Content,
    ContentBody,
    DialogTop,
    DialogTopLeft,
    DialogTopCenter,
    DialogTopRight,
    IconHeader,
    DialogFooter,
    DialogFooterCenter,
    BtnInsertNewAnswer,
    BtnInsertDelete
} from "./style";

import { Dialog } from '@material-ui/core';
import { useMediaQuery } from "react-responsive";
import { AiOutlineClose } from "react-icons/ai";
import { BiPlus, BiSave, BiTrash } from "react-icons/bi";
import { FieldProps, Fields } from "../../components/Forms/Fields/FieldBuilder";
import { FormHandles } from "@unform/core";
import FormBuilder from "../../components/Forms/FormBuilder";
import api from "../../services/api";
import { useToast } from "../../hooks/toast";
import ConfirmDialog from "../ConfirmDialog";
import { Workspace } from "../../interfaces/Workspace";
import { BsBoxes } from "react-icons/bs";
import { useHistory } from "react-router-dom";

const fieldsForm: FieldProps[] = [
    {
        name: "name_ws",
        type: Fields.TEXT_SHORT_FIELD,
        index: 0,
        title: "Nome do workspace",
        description: "Digite um nome para o seu workspace",
        placeholder: "Digite aqui...",
        required: true,
        max_length: 25
    },
    {
        name: "color_ws",
        type: Fields.COLOR_PICKER_FIELD,
        index: 1,
        title: "Cor",
        description: "Escolha uma cor para personalizar seu workspace"
    },
    {
        name: "icon_ws",
        type: Fields.ICON_PICKER_FIELD,
        index: 2,
        title: "Ícone",
        description: "Escolha um ícone para personalizar seu workspace"
    },
    {
        name: "public_ws",
        type: Fields.CHECK_BOX_ONE_FIELD,
        index: 3,
        title: "Compartilhamento",
        description: "Compartilhe este workspace com outros membros da empresa",
        placeholder: "Tornar público para participantes da empresa",
    }
]

interface SaveWorkspaceFormProps {
    name_ws: string;
    color_ws?: string;
    icon_ws?: string;
    public_ws?: boolean;

}

interface AddNewWorkspaceProps {
    open: boolean;
    selectedWorkspace?: Workspace;
    onClose: (newWorkspace?: Workspace) => void;
    dialogDeleteSubmmit?: () => Promise<void>;
}

const defaultValue: SaveWorkspaceFormProps = {
    name_ws: "",
    color_ws: "#9e37ed",
    icon_ws: "FaThLarge"
}

const AddNewWorkspace: React.FC<AddNewWorkspaceProps> = ({ onClose, open, selectedWorkspace, dialogDeleteSubmmit }) => {

    const formRef = useRef<FormHandles>(null);
    const history = useHistory();
    const { addToast } = useToast();
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    const [initValue] = useState<SaveWorkspaceFormProps>(defaultValue);

    const [openDialogDelete, setOpenDialogDelete] = React.useState(false);

    const handleClose = useCallback(async (newWorkspace?: Workspace) => {
        onClose(newWorkspace);
    }, [onClose]);

    const handleEditPage = useCallback(async () => {

        if (formRef.current !== null && selectedWorkspace !== undefined) {

            //Normaliza data and call the api
            const dataNormalized = formRef.current.getData() as unknown as SaveWorkspaceFormProps;

            await api
                .post('/workspace', {
                    id_workspace: selectedWorkspace.id_workspace,
                    name: dataNormalized.name_ws,
                    icon: dataNormalized.icon_ws,
                    color: dataNormalized.color_ws,
                    isPrivate: dataNormalized.public_ws !== undefined && dataNormalized.public_ws ? "S" : "N",
                })
                .then(response => {
                    const newView = response.data as Workspace;

                    onClose(newView);

                    addToast({
                        type: 'success',
                        title: 'Workspace salvo com sucesso!',
                        description: 'Wow! O seu workspace foi salvo com sucesso! :)',
                    });
                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar o workspace!',
                        description: 'Ocorreu um erro ao salvar o workspace!',
                    });
                });

        }

    }, [selectedWorkspace, addToast, onClose]);

    const handleSavePage = useCallback(async () => {

        if (formRef.current !== null) {

            //Normaliza data and call the api
            const dataNormalized = formRef.current.getData() as unknown as SaveWorkspaceFormProps;

            await api
                .post('/workspace', {
                    name: dataNormalized.name_ws,
                    icon: dataNormalized.icon_ws,
                    color: dataNormalized.color_ws,
                    isPrivate: dataNormalized.public_ws !== undefined && dataNormalized.public_ws ? "S" : "N",
                })
                .then(response => {
                    const newView = response.data as Workspace;

                    onClose(newView);
                    history.push('/workspace/' + newView.hash);
                    addToast({
                        type: 'success',
                        title: 'Workspace novo criado com sucesso!',
                        description: 'Wow! O seu workspace foi criado com sucesso! :)',
                    });
                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao criar o workspace!',
                        description: 'Ocorreu um erro ao criar o workspace!',
                    });
                });

        }

    }, [addToast, onClose, history]);

    const handleSubmitForm = useCallback(async (data: object[]) => {

        if (selectedWorkspace === undefined) {
            await handleSavePage();
        } else {
            await handleEditPage();
        }

    }, [handleSavePage, handleEditPage, selectedWorkspace]);

    useEffect(() => {

        if (open && selectedWorkspace !== undefined) {

            //Get the all flow views
            api.get(`/workspace/`, {
                params: {
                    id_workspace: selectedWorkspace.id_workspace
                }
            }).then(response => {
                if (response.data !== null) {

                    const respWorkspace: Workspace = response.data;

                    const newDataForm: SaveWorkspaceFormProps = {
                        "name_ws": respWorkspace.name,
                        "color_ws": respWorkspace.color,
                        "icon_ws": respWorkspace.icon,
                        "public_ws": respWorkspace.isPrivate === "S" ? true : false
                    }

                    if (formRef.current !== null) {
                        formRef.current.setData(newDataForm);
                    }

                }
            });

        }

    }, [open, selectedWorkspace]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            fullScreen={isMobile}
            open={open}
            onClose={() => handleClose()}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={"gray"}>
                        <div>
                            <BsBoxes />
                        </div>
                    </IconHeader>
                    <h1>
                        {selectedWorkspace !== undefined ?
                            "Editar workspace" :
                            "Criar novo workspace"
                        }
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={() => handleClose()}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>

            <Content>
                <ContentBody container>

                    {initValue !== undefined ?
                        <FormBuilder
                            id="form"
                            formRef={formRef}
                            fields={fieldsForm}
                            initialValue={initValue}
                            handleSubmit={handleSubmitForm}
                            focusOnForm={true}
                        /> :
                        <></>
                    }

                </ContentBody>
                <DialogFooter>
                    <DialogFooterCenter>
                        {selectedWorkspace === undefined ?
                            <BtnInsertNewAnswer color={"#23cd78"} icon={BiPlus} onClick={() => handleSavePage()}>
                                Criar novo
                            </BtnInsertNewAnswer> :
                            <>
                                <BtnInsertNewAnswer color={"#23cd78"} icon={BiSave} onClick={() => handleEditPage()}>
                                    Salvar
                                </BtnInsertNewAnswer>
                                {dialogDeleteSubmmit !== undefined && (
                                    <>
                                        <BtnInsertDelete color={"red"} icon={BiTrash} style={{ marginLeft: '15px', width: '70px' }} onClick={() => setOpenDialogDelete(true)} />
                                        <ConfirmDialog
                                            open={openDialogDelete}
                                            onSubmmit={() => dialogDeleteSubmmit()}
                                            onClose={() => setOpenDialogDelete(false)}
                                            type={1}
                                            message="Você tem certeza que deseja excluir este workspace?"
                                        />
                                    </>
                                )}
                            </>
                        }
                    </DialogFooterCenter>
                </DialogFooter>

            </Content>
        </Dialog >
    );

}

export default AddNewWorkspace;