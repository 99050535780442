
export interface IGetNamesFromFullEmail {
    Email: string;
    Name?: string;
    MailboxHash?: string;
}

export default function getFullEmail(email: string): IGetNamesFromFullEmail[] | undefined {

    if (email) {

        const newEmails: IGetNamesFromFullEmail[] = [];

        const emails = email.split(";");

        emails.forEach((email) => {
                
                const newEmail: IGetNamesFromFullEmail = {
                    Email: email
                };
    
                newEmails.push(newEmail);
    
            });

        return newEmails;

    }

    return undefined;
}