import styled, { css } from 'styled-components';

export interface NavigationBarItemProps {
    active?: boolean;
}

export interface NavigationBarItemIconProps {
    sizeIcon?: string;
}

export const LeftHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    height: 100%;

    h1, h2, h3, h4 {
        font-size: 25px;
        font-weight: 700;
    }
    
`;

export const RightHeader = styled.div`
    display: flex;
    align-items: unset;
    justify-content: right;

    margin-left: auto;

    button {
        margin-right: 10px;
        height: 45px;
        width: 150px;
    }
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    background-color: white;
    overflow: auto;

    padding: 10px;
    padding-bottom: 0px;

    > svg {
        color: black;

    }

    h1, h2, h3, h4 {
        color: #4d4d4d;
        font-weight: 500;
    }

`;

export const ButtonsHeader = styled.span`

    display: flex;
    align-items: center;
    justify-content: right;

`;

export const NavigationBar = styled.div`
    height: 100%;
`;

export const NavigationBarTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 15px;
`;

export const NavigationBarPages = styled.div`
    height: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
`;

export const NavigationBarItem = styled.span<NavigationBarItemProps>`
    margin-right: 10px;
    color: gray;
    cursor: default;
    display: flex;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    min-width: 1px;
    transition-duration: .2s;
    transition-property: box-shadow,color;
    white-space: nowrap;
    padding: 5px;
    padding-right: 7px;
    padding-left: 7px;
    border-bottom: 2px solid transparent;

    :hover {
        color: #4d4d4d;
        font-weight: 500;
        border-bottom-color: #4d4d4d;
    }

    ${props => props.active && css`
        color: black;
        font-weight: 500;
        border-bottom-color: #4d4d4d;   
    `}
`;

export const NavigationBarItemIcon = styled.div<NavigationBarItemIconProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px;

    svg {
        ${props => props.sizeIcon && css`
            width: ${props.sizeIcon + "px"};
            height: ${props.sizeIcon + "px"};
        `}
    }
`;

export const NavigationBarTitleBadge = styled.span`
    background-color: #f6ebfd;
    color: black;
    padding: 6px;
    padding-bottom: 3px;
    font-size: 10px;
    border-radius: 10%;
    padding-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
`;

