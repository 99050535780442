import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";


interface FilterBarUsersButtonLinkProps {
    active: boolean;
}

interface ButtonLinkProps {
    active?: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: white;
  height: 40px;
  border-radius: 0px 0px 10px 10px;
  padding-left: 15px;
  padding-right: 5px;
  border-top: solid 0.5px #f4f5f7;
  margin-top: -10px;
  margin-bottom: 10px;
`;

export const FilterBarTasksLeft = styled.div`
  float: left;
  display: flex;
  height: 100%;
  justify-content: left;
  align-items: center;
  width: 100%;  
`;

export const FilterBarDaysButtonClick = styled.button`
    border: none;
    background-color: #f4f4f4;    
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;

    svg {
        font-size: 13px;
        margin-right: 5px;
        margin-bottom: 1px;
    }
`;

export const FilterBarUsersButtonLink = styled.button<FilterBarUsersButtonLinkProps>`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;

    svg {
        font-size: 16px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
    
    ${props => props.active && css`
        background-color: #60bd4e;
        color: white;
        border-radius: 5px;   
        font-weight: 500;
        
        :hover {
            background-color: #57aa47;
        }
    `}
`;


export const ButtonLink = styled.button<ButtonLinkProps>`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 10px;

    svg {
        font-size: 16px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }

    ${props => props.active && css`
        background-color: #60bd4e;
        color: white;
        border-radius: 5px;   
        font-weight: 500;
        
        :hover {
            background-color: #57aa47;
        }
    `}

    @media(max-width: 800px){ 
        svg {
            margin-right: 0px;
        }

        div {
            display: none;            
        }
    }
    
`;

export const SearchInput = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    border-radius: 5px;
    border: solid 1px #808080bf;
    width: 100%;
    max-width: 400px;
    color: #4d4d4d;
    font-weight: 600;

    svg {
        font-size: 17px;
        margin-top: 1px;
        margin-left: 9px;
    }
`;

export const Input = styled.input`
    border: none;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 100%;
    max-width: 400px;
    margin-right: 5px;

    ::placeholder {
        padding: 5px;
        padding-left: 0px;
        color: #4d4d4d;
        font-size: 13px;
    }
`;


export const BoxContainer = styled(Box)`
    position: absolute;
    top: 95px;
    left: 245px;
    z-index: 9;
    min-width: 250px;
    border-radius: 5px;
    padding: 10px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 0px;
    margin-right: 10px;
`;

export const BoxItemsContainer = styled.div`
    max-height: 175px;
    overflow-y: auto;
`;

export const BoxItemsContainerDays = styled.div`
    max-height: 175px;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const BoxItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    cursor: pointer;

    :hover {
        background-color: #f4f5f7;
        border-radius: 5px;
    }
`;

export const BoxSpan = styled.span`
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 5px;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 5px;
    color: rgba(51,51,51,0.9);
    background-color: #4681b81e;
    margin-left: 5px;
`;

export const BoxAvatar = styled.div`
    margin-left: 10px;
`;

export const BoxSpanDetail = styled.span`
    line-height: 1;
    margin: 0px 2px;
    padding: 3px 5px;
    white-space: nowrap;
    border-radius: 5px;
    font-size: 13px;
    padding: 5px 10px;
    margin-right: 5px;
    color: rgba(51,51,51,0.9);
    background-color: #4681b81e;
`;

export const NoResults = styled.span`
    color: gray;
    font-style: italic;
    font-size: 11px;
    margin-top: 5px;
    margin-left: 5px;
`;

export const BoxMultiple = styled.div`
    margin-left: 5px;
    display: flex;
    margin-right: 5px;
`;

export const FilterDaysLabel = styled.label`
    color: black;
    font-size: 14px;
    margin: 10px;
`;

export const FilterDaysInput = styled.input`
    border: none;
    border-bottom: 1px solid gray;
    width: 45px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #9e37ee;
`;

export const FilterDaysConfirm = styled.button`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 10px;
    margin-left: 10px;

    background: #e9f0f7;
    border-radius: 5px;
    padding: 5px 15px;

    svg {
        font-size: 13px;
        margin-right: 5px;
        margin-top: 1px;
    }

    :hover {
        background-color: #f4f4f4;        
        border-radius: 5px;
    }
`;





