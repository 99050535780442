import {
    BarItem,
    BarValue,
    Container,
    HelpText,
    NameItem,
    TargetItem,
    ValueContainer,
    ValueItem
} from "./style";

import Dialog from "../../../dialogs/Dialog";
import { FiHelpCircle } from "react-icons/fi";
import React from "react";

interface StatUserItemProps {
    title: string;
    help_text?: string;
    value: string | number;
    target?: string | number;
    bar_value?: string | number;
    hideTarget?: boolean;
    fullWidth?: boolean;
}

const StatUserItem: React.FC<StatUserItemProps> = ({ help_text, title, value, target, bar_value, hideTarget, fullWidth }) => {

    /* Dialog controller */
    const [open, setOpen] = React.useState(false);
    const handleDialog = () => {
        setOpen(!open);
    };

    return (
        <Container style={fullWidth ? { width: '100%' } : {}}>
            <NameItem>
                <div>{title}</div>
                {(help_text === undefined || help_text === null) ? (
                    <b></b>
                ) : (
                    <HelpText type="button" onClick={handleDialog}>
                        <FiHelpCircle />
                        <Dialog
                            title={title}
                            value={help_text}
                            status={open}
                            handleDialog={handleDialog}
                        />
                    </HelpText>
                )}
            </NameItem>
            <ValueContainer>
                <ValueItem hideTarget={hideTarget}>
                    {value}
                </ValueItem>
                {!!target && !hideTarget && (
                    <TargetItem>
                        /{target}
                    </TargetItem>
                )}
            </ValueContainer>
            {!!bar_value && !hideTarget && (
                <BarItem>
                    <BarValue barValue={bar_value}></BarValue>
                </BarItem>
            )}
        </Container>
    );

}

export default StatUserItem;