import React from "react";

import {
    EmailContainer,
    EmailIconContainer,
    EmailIcon,
    EmailHeaderContainer,
    EmailFrom,
    EmailSubject,
    EmailDetails,
    EmailDate,
    IconNotRead,
} from "./style";
import { Email } from "../../../interfaces/Email";
import getAccessControl from "../../../middlewares/AccessControl";
import { TbMail, TbMailOpened, TbStar, TbStarFilled, TbTrash, TbTrashOff } from "react-icons/tb";
import { FaCircle } from "react-icons/fa";
import { MdAttachFile } from "react-icons/md";

interface EmailListProps {
    emails?: Email[];
    typeUser: string;
    selectedEmail?: Email;
    selectEmail?: (email: Email) => void;
    markReadStatus: (email: Email, status: string) => void;
    markStarredtatus: (email: Email, status: string) => void;
    moveToTrash: (email: Email, status: string) => void;
    openEmailDialog: (email: Email) => void;
}

const EmailList: React.FC<EmailListProps> = ({ emails, typeUser, markReadStatus, markStarredtatus, moveToTrash, openEmailDialog, selectEmail, selectedEmail }) => {

    return (
        <>
            {emails?.sort((a, b) => {
                if (a.dt_created !== undefined && b.dt_created !== undefined) {
                    const dateA = new Date(a.dt_created);
                    const dateB = new Date(b.dt_created);
                    return dateB.getTime() - dateA.getTime();
                }

                return 0;
            }).map((e) => {

                return (
                    <EmailContainer key={e.id_email} isRead={e.isRead === "S" ? true : false} onClick={() => selectEmail !== undefined ? selectEmail(e) : {}} isSelected={selectedEmail?.id_email === e.id_email}>
                        {getAccessControl(48, typeUser) ?
                            <EmailIconContainer className="email-icon-container" isRead={e.isRead === "S" ? true : false}>
                                <EmailIcon
                                    onClick={() => markReadStatus(e, e.isRead === "S" ? "N" : "S")}
                                    title={e.isRead === "S" ? "Marcar como não lido" : "Marcar como lido"}
                                    isRead={e.isRead === "S" ? true : false}
                                >
                                    {e.isRead === "S" ? <TbMail /> : <TbMailOpened />}
                                </EmailIcon>
                                <EmailIcon
                                    onClick={() => markStarredtatus(e, e.isStarred === "S" ? "N" : "S")}
                                    title={e.isStarred === "S" ? "Remover a marcação" : "Marcar com estrela"}
                                    isRead={e.isRead === "S" ? true : false}
                                >
                                    {e.isStarred === "S" ? <TbStarFilled /> : <TbStar />}
                                </EmailIcon>
                                <EmailIcon
                                    onClick={() => moveToTrash(e, e.isDeleted === "S" ? "N" : "S")}
                                    title={e.isDeleted === "S" ? "Remover da Lixeira" : "Mover para a lixeira"}
                                    isRead={e.isRead === "S" ? true : false}
                                >
                                    {e.isDeleted === "S" ? <TbTrashOff /> : <TbTrash />}

                                </EmailIcon>
                            </EmailIconContainer> :
                            <></>
                        }
                        <EmailHeaderContainer onClick={() => openEmailDialog(e)}>
                            <EmailFrom>
                                {e.message_stream === "outbound" && e.to !== undefined ? "Para: " + e.to : e.emailPostmark?.FromFull?.Name}
                                {e.isRead !== "S" ? <IconNotRead><FaCircle /></IconNotRead> : <></>}
                            </EmailFrom>
                            <EmailSubject>
                                {e.subject}
                                {e.emailPostmark && e.emailPostmark.TextBody !== undefined ?
                                    <span>{" - " + e.emailPostmark.TextBody.substring(0, 50)}</span>
                                    : <></>
                                }
                            </EmailSubject>
                        </EmailHeaderContainer>
                        <EmailDetails className="email-details" onClick={() => openEmailDialog(e)}>
                            {e.email_attachments !== undefined && e.email_attachments.length > 0 ?
                                <EmailIcon style={{ fontSize: '12px' }}>
                                    <MdAttachFile />
                                </EmailIcon> :
                                <></>
                            }
                            {e.isStarred === "S" ?
                                <EmailIcon style={{ fontSize: '12px' }}>
                                    <TbStarFilled />
                                </EmailIcon> :
                                <></>
                            }
                            <EmailDate>
                                {e.date_string}
                            </EmailDate>
                        </EmailDetails>
                    </EmailContainer>
                )

            })}
        </>
    );

}

export default EmailList;