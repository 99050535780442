import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";

import {
    BoxContainer,
    BoxItemsContainer,
    ButtonDetailsContainer,
    Container,
    DetailComplementItem,
    DetailComplementsContainer,
    DetailContainerHead,
    DetailContainerSeparator,
    DetailContainerTracking,
    DetailContainerType,
    DetailTimerContainer,
    TimeValue,
    DetailManualContainer,
    ManualInputDuration,
    ManualInputDurationContainer,
    DateContainer,
    ManualIntervalContainer,
    ManualInputStartIntervalContainer,
    ManualIntervalDivider,
    ManualInputEndIntervalContainer,
    ManualIntervalDuration,
    DetailIntervalContainer,
    DetailContainerTrackingBottom,
    BtnTrackingSave,
    ManualInputDurationAutoComplete,
    TracksByUserContainer,
    TrackByUserItem,
    TrackByUserItemTitle,
    TrackByUserItemDescription,
    TotalTimeInfo,
    TrackByUserDetailContainer,
    TrackByUserDetailItem,
    TrackByUserDetailItemTime,
    TrackByUserDetailItemDate,
    TrackByUserDetailItemStep,
    TrackByUserDetailItemAction
} from "./style";
import { FaArrowsAltH, FaCalendar, FaClock, FaDollarSign, FaEdit, FaPlayCircle, FaStopCircle, FaTrash } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { ClickAwayListener } from "@material-ui/core";
import api from "../../services/api";
import { useToast } from "../../hooks/toast";
import { TimeTracking as TimeTrackingInterface } from "../../interfaces/TimeTracking";
import { useAuth } from "../../hooks/auth";
import ReactDatePicker from "react-datepicker";
import { User } from "../../interfaces/User";
import { formatDateToDateRead } from "../../utils/formatDateToDateRead";
import { useTracking } from "../../hooks/tracking";
import AvatarCange from "../AvatarCange";

interface TimeTrackingProps {
    type: 'pocket' | 'complete'; //1 - Pocket / 2 - Complete
    card_id?: number;
    flow_id?: number;
    flow_step_id?: number;
    title?: string;
}

interface TimeManualTracking {
    hours: number;
    minutes: number;
    date: Date;
}

interface TrackByUser {
    user: User;
    duration: number;
}

const TimeTracking: React.FC<TimeTrackingProps> = ({ type, card_id, flow_id, flow_step_id, title }) => {

    const [status, setStatus] = useState<number>(0); //0 - Stopped / 1 - Running
    const { addTracking, removeTracking } = useTracking();
    const [openDetail, setOpenDetail] = useState<boolean>(false);
    const [openAutoComplete, setOpenAutoComplete] = useState<boolean>(false);
    const [manualInputDurationText, setManualInputDurationText] = useState<string>("");
    const [manualInputDurationSugestion, setManualInputDurationSugestion] = useState<string>("");
    const [typeTracking, setTypeTracking] = useState<number>(1); //1 - Timer / 2 - Manual / 3 - Interval
    const [elapsedTime, setElapsedTime] = useState(0);
    const [totalTime, setTotalTime] = useState(0);
    const [isBillable, setIsBillable] = useState<string>("S"); //S - Sim / N - Não
    const [track, setTrack] = useState<TimeTrackingInterface>();
    const [tracks, setTracks] = useState<TimeTrackingInterface[]>();
    const [tracksByUser, setTracksByUser] = useState<TrackByUser[]>([]);
    const [manualTracking, setManualTracking] = useState<TimeManualTracking>();
    const [manualDate, setManualDate] = useState<Date>(new Date());
    const [startHour, setStartHour] = useState<string>("");
    const [endHour, setEndHour] = useState<string>("");
    const [openTrackByUserDetail, setOpenTrackByUserDetail] = useState<boolean>(false);
    const [userSelected, setUserSelected] = useState<number>();

    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const { user } = useAuth();
    const { addToast } = useToast();

    function sortByDate(a: TimeTrackingInterface, b: TimeTrackingInterface) {

        const dtA = new Date(a.dt_start);
        const dtB = new Date(b.dt_start);

        return (dtB.getTime() - dtA.getTime());
    }

    function getCurrentHour() {
        const dataAtual = new Date();
        const hours = dataAtual.getHours();
        const minutes = dataAtual.getMinutes();

        const hoursFormated = hours < 10 ? `0${hours}` : hours;
        const minutesFormated = minutes < 10 ? `0${minutes}` : minutes;

        return `${hoursFormated}:${minutesFormated}`;
    }

    function validDuration(input: string): boolean {

        if (input === undefined || input === "") {
            return false;
        }

        if (input.length < 4) {
            return false;
        }

        const regex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

        return regex.test(input);
    }

    const initCounter = useCallback(() => {

        intervalRef.current = setInterval(() => {
            setElapsedTime((prevTime) => prevTime + 1);
        }, 1000);

    }, []);

    const getTimeTrackingApi = useCallback(async () => {

        await api
            .get('/time-tracking/by-card', {
                params: {
                    flow_id: flow_id,
                    card_id: card_id
                }
            })
            .then(response => {

                const timeTrackings = response.data as TimeTrackingInterface[];

                let totalDuration = 0;
                if (timeTrackings !== undefined && timeTrackings.length > 0) {
                    timeTrackings.forEach(track => {
                        if (track.duration !== undefined) {
                            totalDuration += track.duration;
                        }
                    });
                }
                setTotalTime(totalDuration);

                const filterRunningTrack = timeTrackings.filter(track => track.isRunning === "S" && track.user_id === user.id_user);

                //If there is a running track, set the elapsed time and start the counter
                if (filterRunningTrack !== undefined && filterRunningTrack.length > 0) {

                    const datetimeString = filterRunningTrack[0].dt_start;
                    const targetDate = new Date(datetimeString).getTime();
                    const now = new Date().getTime();
                    const differenceInSeconds = Math.floor((now - targetDate) / 1000);

                    setTrack(filterRunningTrack[0]);
                    setElapsedTime(differenceInSeconds);
                    setStatus(1);
                    setOpenDetail(false);

                    if (intervalRef.current === undefined || intervalRef.current === null) {
                        initCounter();
                    }

                } else if (timeTrackings !== undefined && timeTrackings.length > 0) { //If there is no running track, set the elapsed time

                    setElapsedTime(totalDuration);

                }

                //Summarize the time tracking by user
                const tracksByUser: TrackByUser[] = [];
                timeTrackings.forEach(track => {

                    const index = tracksByUser.findIndex(item => item.user.id_user === track.user_id);
                    if (index === -1 && track.user !== undefined) {
                        tracksByUser.push({
                            user: track.user,
                            duration: track.duration !== undefined ? track.duration : 0
                        });
                    } else {
                        tracksByUser[index].duration += track.duration !== undefined ? track.duration : 0;
                    }

                });

                setTracksByUser(tracksByUser);
                setTracks(timeTrackings);

            }).catch(error => {

            });

    }, [card_id, flow_id, user, initCounter]);

    const deleteTrackApi = useCallback(async (id_time_tracking: number) => {

        await api
            .delete('/time-tracking', {
                params: {
                    id_time_tracking: id_time_tracking
                }
            })
            .then(response => {

                const result = response.data as boolean;

                if (result) {
                    getTimeTrackingApi();
                    addToast({
                        type: 'success',
                        title: 'Apontamento excluído com sucesso!',
                        description: 'Feito! O Apontamento foi excluído com sucesso!',
                    });
                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro ao deletar o apontamento',
                        description: 'Ocorreu um erro ao deletar o apontamento!',
                    });
                }

            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao deletar o apontamento',
                    description: 'Ocorreu um erro ao deletar o apontamento!',
                });
            });

    }, [addToast, getTimeTrackingApi]);

    const setBillable = useCallback(async (newBillable: string) => {

        setIsBillable(newBillable)

        if (track !== undefined && track.id_time_tracking !== undefined) {

            await api
                .put('/time-tracking', {
                    id_time_tracking: track?.id_time_tracking,
                    flow_id: flow_id,
                    card_id: card_id,
                    billable: newBillable
                })
                .then(response => {

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao editar o timer',
                        description: 'Ocorreu um erro ao editar o timer!',
                    });
                });

        }

    }, [addToast, card_id, flow_id, track]);

    const playTimerApi = useCallback(async () => {

        await api
            .post('/time-tracking/play', {
                flow_id: flow_id,
                card_id: card_id,
                flow_step_id: flow_step_id,
                billable: isBillable,
                title: title,
                description: ''
            })
            .then(response => {

                const newTimeTracking = response.data as TimeTrackingInterface;

                setTrack(newTimeTracking);

                addTracking(newTimeTracking);

                setStatus(1);
                setOpenDetail(false);
                setElapsedTime(0);

                initCounter();

                addToast({
                    type: 'success',
                    title: 'Timer iniciado com sucesso!',
                    description: 'Bora! O timer foi iniciado com sucesso! 🤘',
                });

            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao iniciar o timer',
                    description: 'Ocorreu um erro ao inicar o timer!',
                });
            });

    }, [addToast, card_id, flow_id, flow_step_id, isBillable, title, initCounter, addTracking]);

    const stopTimerApi = useCallback(async () => {

        await api
            .post('/time-tracking/stop', {
                id_time_tracking: track?.id_time_tracking
            })
            .then(response => {

                removeTracking();

                setStatus(0);
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
                setElapsedTime(0);
                setOpenDetail(false);
                setTrack(undefined);

                getTimeTrackingApi();

                addToast({
                    type: 'success',
                    title: 'Timer pausado com sucesso!',
                    description: 'Belo trabalho! 🤘',
                });

            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao parar o timer',
                    description: 'Ocorreu um erro ao pausar o timer!',
                });
            });

    }, [addToast, track?.id_time_tracking, removeTracking, getTimeTrackingApi]);

    const handleClickAway = () => {
        setOpenDetail(false);
    };

    const startTimer = useCallback(async () => {

        await playTimerApi();

    }, [playTimerApi]);

    const stopTimer = useCallback(async () => {

        await stopTimerApi();

    }, [stopTimerApi]);

    const formatDuration = (timeInSeconds: number): string => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    const getManualInputSugestion = useCallback((text: string, output: 'string' | 'object'): string | TimeManualTracking => {

        const textInput = text.trimStart().trimEnd();

        const regexes = [
            /^(\d+)\s*(\d*)$/, //1 2 
            /^(\d+)\s*h\s*(\d*)\s*m?$/, // 1h 2m
            /^(\d+)\s*hora?s?(?:\s*e\s*(\d+)\s*minuto?s?)?$|^\s*(\d+)\s*minuto?s?$|^\s*(\d+)\s*hora?s?$/, // 1 hora 2 minutos
            /^([0-9]{1,2}):([0-5][0-9]|)$/, // 1:2
            /^(\d+)\s*m$/, // 1 m
        ];

        let hours = 0;
        let minutes = 0;
        let foundSugestion = false;

        //Try the first regex
        let match = textInput.match(regexes[0]);
        if (match) {
            foundSugestion = true;
            hours = parseInt(match[1], 10) || 0;
            minutes = parseInt(match[2], 10) || 0;
        }

        //Try the second regex
        match = textInput.match(regexes[1]);
        if (match && !foundSugestion) {
            foundSugestion = true;
            hours = parseInt(match[1], 10) || 0;
            minutes = parseInt(match[2], 10) || 0;
        }

        //Try the third regex
        match = textInput.match(regexes[2]);
        if (match && !foundSugestion) {
            foundSugestion = true;
            hours = parseInt(match[1], 10) || 0;
            minutes = parseInt(match[2], 10) || 0;
        }

        //Try the fourth regex
        match = textInput.match(regexes[3]);
        if (match && !foundSugestion) {
            foundSugestion = true;
            hours = parseInt(match[1], 10) || 0;
            minutes = parseInt(match[2], 10) || 0;
        }

        //Try the fifth regex
        match = textInput.match(regexes[4]);
        if (match && !foundSugestion) {
            foundSugestion = true;
            minutes = parseInt(match[1], 10) || 0;
        }

        if (hours > 23 || minutes > 59) {
            hours = 0;
            minutes = 0;
        }

        if (output === 'string') {
            if (hours === 0 && minutes === 0) {
                return 'Duração inválida';
            }

            if (hours > 0 && minutes > 0) {
                return `${hours} hora${hours > 1 ? 's' : ''} e ${minutes} minuto${minutes > 1 ? 's' : ''}`;
            }

            if (hours > 0) {
                return `${hours} hora${hours > 1 ? 's' : ''}`;
            }

            if (minutes > 0) {
                return `${minutes} minuto${minutes > 1 ? 's' : ''}`;
            }

            return 'Duração inválida';
        } else {
            return {
                hours,
                minutes,
                date: manualTracking !== undefined ? manualTracking.date : new Date()
            };
        }

    }, [manualTracking]);

    const handleSelectManualTime = useCallback(async () => {

        const newSugestion = getManualInputSugestion(manualInputDurationText, 'object');

        if (typeof newSugestion === 'object') {
            const newManualTracking: TimeManualTracking = {
                hours: newSugestion.hours,
                minutes: newSugestion.minutes,
                date: new Date()
            };

            setManualTracking(newManualTracking);

            let descSugestion = undefined;
            if (newSugestion.hours > 0 && newSugestion.minutes > 0) {
                descSugestion = `${newSugestion.hours}h ${newSugestion.minutes}m`;
            } else if (newSugestion.hours > 0) {
                descSugestion = `${newSugestion.hours}h`;
            } else if (newSugestion.minutes > 0) {
                descSugestion = `${newSugestion.minutes}m`;
            }

            if (descSugestion !== undefined) {
                setManualInputDurationText(descSugestion);
                setOpenAutoComplete(false);
            }

        }

    }, [getManualInputSugestion, manualInputDurationText]);

    const onTypeManualInputDuration = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        // Use the regex to valid the input
        const isValidInput = /^(\d{0,2}h\s*(\d{0,2}m?)?|\d{0,2}m?)$/.test(newValue);

        if (isValidInput || newValue === '') {
            setManualInputDurationText(newValue);
        }
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSelectManualTime();
        }
    };

    const onTypeStartHour = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        const isValidInput = /^(?:[01]?\d|2[0-3]?)?(?::[0-5]?\d?)?$/.test(newValue);

        if (isValidInput || newValue === '') {

            if (newValue.length === 2 && newValue.length > startHour.length && newValue.search(':') === -1) {
                setStartHour(newValue + ":");
            } else {
                setStartHour(newValue);
            }

            const dtStart = manualDate;
            if (validDuration(newValue)) {

                dtStart.setHours(parseInt(newValue.split(':')[0]));
                dtStart.setMinutes(parseInt(newValue.split(':')[1]));

                const dtEnd = manualDate;
                let endHourCompleted = false;
                if (validDuration(endHour)) {
                    dtEnd.setHours(parseInt(endHour.split(':')[0]));
                    dtEnd.setMinutes(parseInt(endHour.split(':')[1]));
                    endHourCompleted = true;
                }

                if (dtStart > dtEnd || !endHourCompleted) {
                    setEndHour(newValue);
                }
            }
        }
    };

    const onTypeEndHour = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        // Utiliza um regex para verificar se a entrada está no formato desejado (hh:mm)
        const isValidInput = /^(?:[01]?\d|2[0-3]?)?(?::[0-5]?\d?)?$/.test(newValue);

        if (isValidInput || newValue === '') {

            if (newValue.length === 2 && newValue.length > endHour.length) {
                setEndHour(newValue + ":");
            } else {
                setEndHour(newValue);
            }
        }
    };

    const handleSaveManualTrack = useCallback(async () => {

        if (typeTracking === 2) { //Manual Duration

            const newSugestion = getManualInputSugestion(manualInputDurationText, 'object');

            if (typeof newSugestion === 'object') {

                const newManualTracking: TimeManualTracking = {
                    hours: newSugestion.hours,
                    minutes: newSugestion.minutes,
                    date: manualDate
                };

                //Calc the duration in seconds total
                const duration = (newManualTracking.hours * 60 * 60) + (newManualTracking.minutes * 60);
                const date = new Date(newManualTracking.date);
                date.setUTCHours(0, 0, 0, 0);

                await api
                    .post('/time-tracking', {
                        flow_id: flow_id,
                        card_id: card_id,
                        flow_step_id: flow_step_id,
                        source: 'manual',
                        dt_start: date.toISOString().slice(0, 19).replace('T', ' '),
                        dt_end: date.toISOString().slice(0, 19).replace('T', ' '),
                        duration: duration,
                        billable: isBillable,
                        title: title,
                        description: ''
                    })
                    .then(response => {

                        setOpenDetail(false);

                        getTimeTrackingApi();

                        addToast({
                            type: 'success',
                            title: 'Apontamento de horas manual salvo com sucesso!',
                            description: 'Belo trabalho! 🤘',
                        });

                    }).catch(error => {
                        addToast({
                            type: 'error',
                            title: 'Erro ao salvar apontamento manual',
                            description: 'Ocorreu um erro tentar salvar o apontamento manual!',
                        });
                    });


            }

        } else if (typeTracking === 3) {

            if (!validDuration(startHour) || !validDuration(endHour)) {
                addToast({
                    type: 'error',
                    title: 'Erro ao salvar o apontamento',
                    description: 'Verifique os campos de hora inicial e final!',
                });
                return;
            } else if (startHour === endHour) {
                addToast({
                    type: 'error',
                    title: 'Erro ao salvar o apontamento',
                    description: 'A hora inicial e final não podem ser iguais!',
                });
                return;
            }

            let dtStart = new Date(manualDate);
            if (startHour !== undefined && startHour !== "" && validDuration(startHour)) {
                const [hours, minutes] = startHour.split(':').map(Number);
                dtStart.setUTCHours(hours);
                dtStart.setUTCMinutes(minutes);
            }

            let dtEnd = new Date(manualDate);
            if (endHour !== undefined && endHour !== "" && validDuration(endHour)) {
                const [hours, minutes] = endHour.split(':').map(Number);
                dtEnd.setUTCHours(hours);
                dtEnd.setUTCMinutes(minutes);
            }

            //Valid if the start hour is less than end hour
            if (dtStart >= dtEnd) {
                addToast({
                    type: 'error',
                    title: 'Erro ao salvar o apontamento',
                    description: 'A hora inicial deve ser menor que a hora final!',
                });
                return;
            }

            const duration = (dtEnd.getTime() - dtStart.getTime()) / 1000;

            await api
                .post('/time-tracking', {
                    flow_id: flow_id,
                    card_id: card_id,
                    flow_step_id: flow_step_id,
                    source: 'interval',
                    dt_start: dtStart.toISOString().slice(0, 19).replace('T', ' '),
                    dt_end: dtEnd.toISOString().slice(0, 19).replace('T', ' '),
                    duration: duration,
                    billable: isBillable,
                    title: title,
                    description: ''
                })
                .then(response => {

                    setOpenDetail(false);

                    getTimeTrackingApi();

                    addToast({
                        type: 'success',
                        title: 'Apontamento de horas manual salvo com sucesso!',
                        description: 'Belo trabalho! 🤘',
                    });

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar apontamento manual',
                        description: 'Ocorreu um erro tentar salvar o apontamento manual!',
                    });
                });

        }

    }, [endHour, startHour, getManualInputSugestion, manualDate, manualInputDurationText, typeTracking, addToast, card_id, flow_id, flow_step_id, getTimeTrackingApi, isBillable, title]);

    useEffect(() => {

        if (manualInputDurationText !== undefined && manualInputDurationText !== "") {

            const sugestion = getManualInputSugestion(manualInputDurationText, 'string');
            const sugestionObject = getManualInputSugestion(manualInputDurationText, 'object');

            if (typeof sugestion === 'string') {
                setManualInputDurationSugestion(sugestion);
            }

            if (typeof sugestionObject === 'object') {

                let descSugestion = undefined;
                if (sugestionObject.hours > 0 && sugestionObject.minutes > 0) {
                    descSugestion = `${sugestionObject.hours}h ${sugestionObject.minutes}m`;
                } else if (sugestionObject.hours > 0) {
                    descSugestion = `${sugestionObject.hours}h`;
                } else if (sugestionObject.minutes > 0) {
                    descSugestion = `${sugestionObject.minutes}m`;
                }

                if (descSugestion === manualInputDurationText) {
                    setOpenAutoComplete(false);
                } else {
                    setOpenAutoComplete(true);
                }

            } else {
                setOpenAutoComplete(true);
            }

        } else {
            setOpenAutoComplete(false);
        }

    }, [manualInputDurationText, getManualInputSugestion]);

    useEffect(() => {

        getTimeTrackingApi();

        setStartHour(getCurrentHour());
        setEndHour(getCurrentHour());

    }, [getTimeTrackingApi]);

    return (
        type === 'pocket' ?
            <Container status={status} open={openDetail}>

                {status === 0 ?
                    <>
                        <FaPlayCircle onClick={() => startTimer()} />
                        {elapsedTime !== 0 &&
                            <TimeValue>
                                {formatDuration(elapsedTime)}
                            </TimeValue>
                        }
                    </> :
                    <>
                        <FaStopCircle onClick={() => stopTimer()} />
                        <TimeValue>
                            {formatDuration(elapsedTime)}
                        </TimeValue>
                    </>
                }

                <ButtonDetailsContainer onClick={() => setOpenDetail(true)}>
                    <IoIosArrowDown />

                    {openDetail &&
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <BoxContainer>
                                <BoxItemsContainer>

                                    <DetailContainerHead>
                                        <DetailContainerType onClick={() => setTypeTracking(1)} active={typeTracking === 1}>
                                            <FaPlayCircle />
                                            Timer
                                        </DetailContainerType>
                                        <DetailContainerSeparator />
                                        <DetailContainerType onClick={() => setTypeTracking(2)} active={typeTracking === 2}>
                                            <FaEdit />
                                            Manual
                                        </DetailContainerType>
                                        <DetailContainerSeparator />
                                        <DetailContainerType onClick={() => setTypeTracking(3)} active={typeTracking === 3}>
                                            <FaArrowsAltH />
                                            Intervalo
                                        </DetailContainerType>
                                    </DetailContainerHead>

                                    <DetailContainerTracking>

                                        {typeTracking === 1 ?
                                            <DetailTimerContainer>

                                                {status === 0 ?
                                                    <>
                                                        <FaPlayCircle onClick={() => startTimer()} />
                                                        <>
                                                            {"00:00:00"}
                                                        </>
                                                    </> :
                                                    <>
                                                        <FaStopCircle color="#f23b5c" onClick={() => stopTimer()} />
                                                        <TimeValue style={{ marginLeft: '0px', fontSize: '16px' }}>
                                                            {formatDuration(elapsedTime)}
                                                        </TimeValue>
                                                    </>
                                                }
                                            </DetailTimerContainer> :

                                            typeTracking === 2 ?
                                                <DetailManualContainer>

                                                    <ManualInputDurationContainer>
                                                        <FaClock />
                                                        <ManualInputDuration
                                                            placeholder="Insira a duração, por exemplo: 15m, 1h, 3h45m"
                                                            onChange={(e) => onTypeManualInputDuration(e)}
                                                            onKeyDown={(e) => handleKeyPress(e)}
                                                            value={manualInputDurationText}
                                                        />
                                                    </ManualInputDurationContainer>
                                                    {openAutoComplete && manualInputDurationSugestion !== undefined && manualInputDurationSugestion !== "" &&
                                                        <ManualInputDurationAutoComplete onClick={() => handleSelectManualTime()}>
                                                            <label onClick={() => handleSelectManualTime()}>{manualInputDurationSugestion}</label>
                                                        </ManualInputDurationAutoComplete>
                                                    }
                                                    <DateContainer>
                                                        <FaCalendar />
                                                        <ReactDatePicker
                                                            selected={manualDate}
                                                            onChange={date => setManualDate(date as Date)}
                                                            dateFormat="dd/MM/yyyy"
                                                            locale="pt"
                                                            showTimeSelect={false}
                                                            placeholderText="Selecione a data..."

                                                        /*
                                                            ref={datepickerRef}
                                                            selected={date}
                                                            onChange={setDate}
                                                            placeholderText={placeholderTextVariation}
                                                            onFocus={handleInputFocused}
                                                            onBlur={handleInputBlur}
                                                            onCalendarClose={handleInputBlur}
                                                            dateFormat={dateFormatVariation}
                                                            locale={pt}
                                                            showTimeSelect={isDateTime}
                                                            timeInputLabel="Hora"
                                                            timeCaption="Hora"
                                                            {...rest}
                                                            */
                                                        />
                                                    </DateContainer>

                                                </DetailManualContainer> :

                                                typeTracking === 3 ?
                                                    <DetailIntervalContainer>

                                                        <ManualIntervalContainer>
                                                            <ManualInputStartIntervalContainer>
                                                                <FaClock />
                                                                <ManualIntervalDuration
                                                                    placeholder="10:00"
                                                                    onChange={(e) => onTypeStartHour(e)}
                                                                    value={startHour}
                                                                />
                                                            </ManualInputStartIntervalContainer>
                                                            <ManualIntervalDivider>-</ManualIntervalDivider>
                                                            <ManualInputEndIntervalContainer>
                                                                <FaClock />
                                                                <ManualIntervalDuration
                                                                    placeholder="11:00"
                                                                    onChange={(e) => onTypeEndHour(e)}
                                                                    value={endHour}
                                                                />
                                                            </ManualInputEndIntervalContainer>
                                                        </ManualIntervalContainer>

                                                        <DateContainer>
                                                            <FaCalendar />
                                                            <ReactDatePicker
                                                                selected={manualDate}
                                                                onChange={date => setManualDate(date as Date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                locale="pt"
                                                                showTimeSelect={false}
                                                                placeholderText="Selecione a data..."

                                                            /*
                                                                ref={datepickerRef}
                                                                selected={date}
                                                                onChange={setDate}
                                                                placeholderText={placeholderTextVariation}
                                                                onFocus={handleInputFocused}
                                                                onBlur={handleInputBlur}
                                                                onCalendarClose={handleInputBlur}
                                                                dateFormat={dateFormatVariation}
                                                                locale={pt}
                                                                showTimeSelect={isDateTime}
                                                                timeInputLabel="Hora"
                                                                timeCaption="Hora"
                                                                {...rest}
                                                                */
                                                            />
                                                        </DateContainer>

                                                    </DetailIntervalContainer> :
                                                    <></>
                                        }

                                    </DetailContainerTracking>

                                    <DetailContainerTrackingBottom>

                                        <DetailComplementsContainer>
                                            {/*
                                            <DetailComplementItem>
                                                <FaBars />
                                            </DetailComplementItem>
                                            */}
                                            <DetailComplementItem active={isBillable === "S"} onClick={() => setBillable(isBillable === "S" ? "N" : "S")}>
                                                <FaDollarSign />
                                            </DetailComplementItem>
                                        </DetailComplementsContainer>

                                        {typeTracking !== 1 ?
                                            <BtnTrackingSave onClick={() => handleSaveManualTrack()}>
                                                Salvar
                                            </BtnTrackingSave> :
                                            <></>
                                        }
                                    </DetailContainerTrackingBottom>

                                    {elapsedTime > 0 &&
                                        <TracksByUserContainer>

                                            <TotalTimeInfo>
                                                <span>{"Tempo total: " + formatDuration(totalTime)}</span>
                                            </TotalTimeInfo>

                                            {tracksByUser.map(trackByUser => (
                                                <>
                                                    <TrackByUserItem key={trackByUser.user.id_user} onClick={() => {

                                                        let newOpen = false;
                                                        if (userSelected !== trackByUser.user.id_user) {
                                                            newOpen = true;
                                                            setUserSelected(trackByUser.user.id_user);
                                                        } else {
                                                            setUserSelected(undefined);
                                                        }

                                                        setOpenTrackByUserDetail(newOpen);

                                                    }}>
                                                        <AvatarCange user={trackByUser.user} size="30" />
                                                        <TrackByUserItemTitle>
                                                            {trackByUser.user.name === user.name ? "Você" : trackByUser.user.name}
                                                        </TrackByUserItemTitle>
                                                        <TrackByUserItemDescription>
                                                            {formatDuration(trackByUser.duration)}
                                                        </TrackByUserItemDescription>
                                                    </TrackByUserItem>

                                                    {openTrackByUserDetail && userSelected === trackByUser.user.id_user && tracks !== undefined && tracks.length > 0 ?
                                                        <TrackByUserDetailContainer>
                                                            {openTrackByUserDetail && userSelected === trackByUser.user.id_user && tracks !== undefined && tracks.length > 0 &&
                                                                tracks.filter((t) => t.user_id === trackByUser.user.id_user && t.isRunning === "N").sort(sortByDate).map(track => (

                                                                    <TrackByUserDetailItem>
                                                                        <TrackByUserDetailItemTime>
                                                                            {track.duration !== undefined ? formatDuration(track.duration) : "00:00:00"}
                                                                        </TrackByUserDetailItemTime>
                                                                        <TrackByUserDetailItemDate>
                                                                            {formatDateToDateRead(new Date(track.dt_start))}
                                                                        </TrackByUserDetailItemDate>
                                                                        <TrackByUserDetailItemStep>
                                                                            {track.flow_step?.name}
                                                                        </TrackByUserDetailItemStep>

                                                                        {trackByUser.user.name === user.name ?
                                                                            <>
                                                                                {/*
                                                                                <TrackByUserDetailItemAction style={{ right: '35px' }}>
                                                                                    <FaPen color={"gray"} />
                                                                                </TrackByUserDetailItemAction>
                                                                                */}
                                                                                <TrackByUserDetailItemAction style={{ right: '10px' }} onClick={() => track.id_time_tracking !== undefined ? deleteTrackApi(track.id_time_tracking) : {}}>
                                                                                    <FaTrash color={"#f23b5d"} />
                                                                                </TrackByUserDetailItemAction>
                                                                            </> :
                                                                            <></>
                                                                        }

                                                                    </TrackByUserDetailItem>

                                                                ))}
                                                        </TrackByUserDetailContainer> :
                                                        <></>
                                                    }
                                                </>
                                            ))}

                                        </TracksByUserContainer>
                                    }

                                    {/* Lista dos últimos apontamentos (somente fora do cartão) */}

                                </BoxItemsContainer>
                            </BoxContainer>
                        </ClickAwayListener>
                    }
                </ButtonDetailsContainer>

            </Container> :
            <></>
    );

}

export default TimeTracking;