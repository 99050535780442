import styled from "styled-components";

export const Container = styled.div`
    display: grid;
`;

export const ButtonNew = styled.button`
    margin-top: 15px;
    background-color: #e9f1ff;
    border: dashed 1px blue;
    border-radius: 10px;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    float: right;
    font-size: 12px;
`;