import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

interface ContainerProps {
    index?: number;
    top?: number;
    left?: number;
}

export const Container = styled.div<ContainerProps>`
    position: absolute;
    top: 40px;
    right: auto;    
    min-width: 300px;
    width: 300px;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 5px;
    margin-right: 10px;
    padding-bottom: 10px;
    border: 1px solid #f4f4f4;
    padding-right: 10px;
    padding-left: 10px;
    z-index: 999999999;    

    ${(props) => props.index !== undefined && String(props.index) === "0" && css`
        left: -3px !important;
        right: auto;
    `}

    ${(props) => props.top !== undefined && props.left !== undefined && css`
        top: ${props.top}px !important;
        left: ${props.left}px !important;        
    `}

    .color-picker-btn-custom {
        top: auto !important;
        left: 63px !important;
    }

    .color-picker-container {
        margin-top: 15px;
    }

    .icon-picker-container {
        div & {            
            width: calc(100% - 5px);

            div & {
                height: 62px;
                margin-top: 7px;
            }
        }
    }

    form {
        padding: 10px 0px;
        padding-bottom: 0px;

        .cange-form-title {
            font-size: 14px;
        }

        .cange-form-title-required {
            font-size: 14px;
        }

        .cange-form-description {
            font-size: 12px;
            color: gray;
            font-weight: 400;
            margin-bottom: -7px;
        }

        .cange-form-error {
            font-size: 12px;
        }

        .cange-form-error-icon {
            font-size: 12px;
            height: 12px;
            width: 12px;
        }

        .cange-form-help-text {
            margin-top: 0px;
            font-size: 12px;
        }
    }
`;

export const BoxOver = styled(Box)`
    height: 40px;    
    display: flex;
    justify-content: center;
    align-items: center;    
`;