import styled, { css } from "styled-components";

interface StepItemProps {
    isEnded?: boolean;
    color?: string;
}

interface StepItemInsideProps {
    isEnded?: boolean;
    color?: string;
}

interface StepItemInsideEndProps {    
    color?: string;
}

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StepItem = styled.div<StepItemProps>`
    width: 100%;
    border-radius: 0px;
    border: 1px solid #ededed;    
    margin-top: 5px;
    margin-bottom: 5px;
`;

export const StepItemInside = styled.div<StepItemInsideProps>`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 20px;
    min-height: 20px;
    width: 100%;
    border-radius: 0px;

    color: black;
    font-weight: 500;

    font-size: 12px;
    text-align: center;
    overflow: hidden;

    ${(props) => props.isEnded && css`
        background: rgb(255,140,47);
        height: 22px;        
        background: linear-gradient(90deg, rgba(255,140,47,1) 25%, rgba(251,50,92,1) 50%, rgba(147,55,237,1) 75%);        
    `}

    ${(props) => props.isEnded && props.color && css`
        background: ${props.color};        
    `}

`;

export const StepItemInsideStart = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 22px;
    min-height: 22px;
    width: 30px;    
    border-radius: 5px 0px 0px 5px;    

    background-color: #f23b5c;
    color: white;

`;

export const StepItemInsideEnd = styled.div<StepItemInsideEndProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    
    height: 22px;
    min-height: 22px;
    width: 30px;
    border-radius: 0px 5px 5px 0px;

    background-color: #ff8c2e;
    color: white;

    ${(props) => props.color && css`        
        background-color: ${props.color};
    `}

`;