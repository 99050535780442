import styled, { css } from 'styled-components';

import ToolTip from "../../../../ToolTip";

interface ContainerProps {
    isFocused: boolean;
    isErrored: boolean;
    isDisabled: boolean;
}

export const Container = styled.div<ContainerProps>`

    background-color: transparent;
    border: 0px;
    padding: 16px;
    width: 100%;

    border-bottom: 1px solid gray;
    color: black;

    display: flex;
    align-items: center;

    & + div {
        margin-top: 30px;
    }

    input {
        flex: 1;
        background: transparent;
        border: 0;
            
        ::placeholder {
            color: gray;
        }

        
    }

    svg {
        margin-right: 16px;
        color: gray;
    }

    ${ props => props.isErrored && css`
        //color: red;        
        border-bottom-color: red;

        /*
        svg {
            color: red;
        }
        */
    `}
  

    ${ props => props.isFocused && css`
        color: #f23b5c;
        border-bottom-color: #f23b5c;

        svg {
            color: #f23b5c;
        }
    `}

    ${ props => props.isDisabled && css`     
        background-color: rgba(188, 188, 188, 0.1);
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    `}  

    title {
        color: red;
        text-size-adjust: 10px;
    }
`;

export const Error = styled(ToolTip)`
    height: 20px;
    margin-left: 16px;

    svg {
        margin: 0;
    }

    span {
        background: red;

        &::before {
            border-color: red transparent;
        }
    }
`;

export const Title = styled.div`
    color: gray;
`;