import React, { useCallback, useEffect, useRef, useState } from "react";
import { CirclePicker, ColorResult, BlockPicker, ChromePicker } from 'react-color'
import { useField } from "@unform/core";
import { ClickAwayListener } from "@material-ui/core";

import ErrorDescription from "../../../../ErrorDescription";
import { ButtonCustomColor, Container, ContainerCustomColor } from "./style";

const colors: string[] = [
  "#f23b5c",
  "#9e37ed",
  "#4680b8",
  "#61bd4f",
  "#ff8c2f",
  "#795548"
];

const colorsPocket: string[] = [
  "#f23b5c",
  "#9e37ed",
  "#4680b8",
  "#61bd4f",
  "#ff8c2f",
];

const colorsCustom: string[] = [
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
  "transparent"
]

interface PickerProps {
  name: string,
  variation?: string
  onForceBlur?: () => void;
}

const ColorPicker: React.FC<PickerProps> = ({ name, variation, onForceBlur }) => {

  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [lastColor, setLastColor] = useState<string | undefined>(defaultValue || "");
  const [color, setColor] = useState(defaultValue || "");
  const [openCustomColor, setOpenCustomColor] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 });

  const handleClickAway = () => {
    setOpenCustomColor(false);
  };

  const handleChangeComplete = useCallback((color: ColorResult) => {
    setColor(color.hex);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: color,
      getValue: color => {
        return color
      },
      setValue: (ref, value) => {
        if (value !== null && value !== undefined) {
          if (value !== color) {
            setColor(value);
          }
        }
      },
      clearValue: ref => {
        setColor("");
      },
    });
  }, [fieldName, registerField, color]);

  useEffect(() => {

    if (buttonRef.current) {

      let plusLeft = -69;
      let plusTop = 45;

      if (variation !== undefined && variation === "2") {
        plusLeft = -200;
        plusTop = 45;
      }

      const buttonRect = buttonRef.current.getBoundingClientRect();
      setButtonPosition({ x: buttonRect.left + plusLeft, y: buttonRect.top + plusTop });
    }

  }, [openCustomColor, variation]);

  useEffect(() => {

    if (onForceBlur && color !== undefined && color !== null && color !== "" && lastColor !== color) {
      onForceBlur();
      setLastColor(color);
    }

  }, [color, onForceBlur, lastColor]);

  return (
    <>
      <Container className="color-picker-container">
        <CirclePicker
          color={color}
          colors={variation !== undefined && variation === "3" ? colorsPocket : colors}
          onChangeComplete={handleChangeComplete}
        />
        <ButtonCustomColor color={colors.includes(color) ? undefined : color} type="button" ref={buttonRef} onClick={() => setOpenCustomColor(true)}>
          +
        </ButtonCustomColor>
      </Container>
      {openCustomColor && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <ContainerCustomColor className="color-picker-btn-custom" style={{ top: buttonPosition.y, left: buttonPosition.x }}>
            {variation !== undefined && variation === "2" ? (
              <ChromePicker
                color={color}
                onChangeComplete={handleChangeComplete}
              />
            ) : (
              <BlockPicker
                color={color}
                colors={colorsCustom}
                onChangeComplete={handleChangeComplete}
              />
            )}
          </ContainerCustomColor>
        </ClickAwayListener>
      )}
      {error && (<ErrorDescription title={error} />)}
    </>
  );

}

export default ColorPicker;