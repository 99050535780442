import styled, { keyframes } from 'styled-components';

import background from '../../assets/background-start.png';

export const Container = styled.div`
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;    
    justify-content: center;
    background-color: #f6f8f9;

    flex: 1;
    background: url(${background}) no-repeat center;
    background-size: cover;

`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);

    width: 100%;
    height: auto;
    //min-width: 550px;
    //min-height: 500px;
    max-width: 700px;
    max-height: 650px;
    background-color: white;
    margin-right: 10px;
    margin-left: 10px;

    img {
        margin-bottom: 35px;        
        height: 72px;
        width: 370px;
    }

`;

const appearFromLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50px);
    } 
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const AnimationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    form {
        margin: 80px 0;
        min-width: 400px;
        text-align: center;

        h1 {
            color: black;
            margin-bottom: 35px;
        }    

        button {
            margin-top: 60px;
        }
    }
`;

export const BackGround = styled.div`
    flex: 1;
    background-color: #f6f8f9;
    background-size: cover;
`;

export const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    padding-top: 30px;
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 30px;

    border-bottom: 1px solid #80808040;
`;

export const HeaderLeft = styled.div`
    width: 100%;

    h1 {
        font-size: 24px;
        color: black;
        font-weight: bold;
        font-family: Roboto, helvetica;
        letter-spacing: 0.8px;
    }

    h3 {
        color: #676879;
        text-align: left;
        margin-top: 8px;
        font-weight: 400;
        font-size: 16px;
        margin-block-end: unset; 
    }
    
`;

export const HeaderRight = styled.div`
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 150px;

    img {
        margin-bottom: 0px !important;
        height: auto;
        width: 50%;
    }
`;

export const Body = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start; 
    width: auto;
    height: 100%;

    form {
        position: sticky;
        z-index: 10;
        margin: 30px;

        img {
            height: 110px;
            width: auto;
            margin: 0px;
        }
    }
`;

export const ContainerStep = styled.div`

    margin-top: 30px;

    h1 {
        animation: ${appearFromLeft} 1s;
        font-size: 24px;
        color: black;
        font-weight: 500;
        font-family: Roboto, helvetica;
        margin-bottom: 20px !important;
    }   

    h3 {
        font-weight: 400;
    }

`;

export const NoAccount = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    padding-top: 20px;
    color: #6f7782;
    font-size: 17px;

`;

export const Animation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    position: absolute;
`;