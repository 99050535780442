import { TimeSheetFieldProps } from ".";
import { Card } from "../../../interfaces/Card";
import { TimeTracking } from "../../../interfaces/TimeTracking";
import { User } from "../../../interfaces/User";

export interface TimeSheetDataTableProps {
    [key: string]: any;
    title?: string;
    items?: TimeSheetDataTableProps[];
    duration?: number;
    user?: User;
}

function aggregateTimeSheetData(timeSheetData: TimeSheetDataTableProps[]): TimeSheetDataTableProps[] {
    const aggregatedData: Record<string, { duration: number, user: User }> = {};

    timeSheetData.forEach((row: TimeSheetDataTableProps) => {

        //Create a unique key for the row
        const key = Object.keys(row)
            .filter((field) => field !== 'duration' && field !== 'user' && field !== 'title' && field !== 'items')
            .map((field) => `${field}:${row[field]}`)
            .join(',');

        aggregatedData[key] = aggregatedData[key] || { duration: undefined, user: undefined };

        //Add the duration to the aggregated data
        if (aggregatedData[key].duration === undefined) {
            aggregatedData[key].duration = row.duration || 0;
        } else {
            aggregatedData[key].duration += row.duration || 0;
        }

        //Add the user to the aggregated data
        if (aggregatedData[key].user === undefined) {
            aggregatedData[key].user = row.user as User;
        }

    });

    //Convert the aggregated data to an array of TimeSheetDataTableProps
    const resultAgreggated: TimeSheetDataTableProps[] = Object.keys(aggregatedData).map((key) => {
        const fieldsToSplit = key.split(',');
        const resultRow: TimeSheetDataTableProps = { duration: aggregatedData[key].duration, user: aggregatedData[key].user as User };

        //Create the TimeSheetDataTableProps with the fields
        fieldsToSplit
            .filter((field) => field !== 'duration' && field !== 'user' && field !== 'title' && field !== 'items')
            .forEach((field) => {
                const [nameColumn, value] = field.split(':');
                resultRow[nameColumn] = value;
            });

        return resultRow;
    });

    return resultAgreggated;
}

export default function getAggregateObject(cards: Card[], fields?: TimeSheetFieldProps[]): TimeSheetDataTableProps[] {

    let dataTable: TimeSheetDataTableProps[] = [];

    //Put fields in order by indexAggregation from the highest to the lowest
    let newFields = fields !== undefined ? fields.sort((a, b) => a.index - b.index) : [];    

    if (cards !== undefined && cards.length > 0) { //If there are cards        

        for (let index = 0; index < cards.length; index++) { //Run through the cards
            const card = cards[index];

            //Get the values
            if (card.time_trackings !== undefined && card.time_trackings.length > 0) {

                //Get all unique users from time trackings
                function extractUniqueUserIds(timeTrackingItems: TimeTracking[]) {
                    const uniqueCardIds = timeTrackingItems.reduce((userIdSet, currentItem) => {
                        //Just get the if the card is parent
                        if (currentItem.user_id !== undefined && currentItem.user_id !== null) {
                            userIdSet.add(currentItem.user_id);
                        }
                        return userIdSet;
                    }, new Set<number>());

                    return Array.from(uniqueCardIds);
                }

                const uniqueUserIds: number[] = extractUniqueUserIds(card.time_trackings);

                for (let idxC = 0; idxC < uniqueUserIds.length; idxC++) {
                    const usrId = uniqueUserIds[idxC];

                    //Create the row
                    let row: TimeSheetDataTableProps = {};
                    let sumDuration = 0

                    //Get user informations
                    const userTrakcings = card.time_trackings.filter(timeTracking => timeTracking.user_id === usrId);

                    //Get the sum of the duration time trackings from this card
                    sumDuration = userTrakcings.reduce((acc, timeTracking) => {

                        const duration = timeTracking.duration !== undefined ? timeTracking.duration : 0;

                        return acc + duration

                    }, 0);

                    row.duration = sumDuration;

                    //Set user informations
                    if (userTrakcings !== undefined && userTrakcings.length > 0) {
                        if (userTrakcings[0].user !== undefined && userTrakcings[0].user !== null) {
                            row['user_id'] = userTrakcings[0].user_id;
                            row.user = userTrakcings[0].user as User;
                        }
                    }

                    if (newFields !== undefined && newFields.length > 0) { //If there are fields

                        //Get the Columns from the fields
                        for (let idxB = 0; idxB < newFields.length; idxB++) { //Run through the fields
                            const field = newFields[idxB];

                            //Add the column on row
                            let valueColumn = "Sem Registro";

                            if (field.typeOrigin === "FormAnswer") {

                                //Get the columns
                                if (card.form_answers !== undefined && card.form_answers.length > 0) {

                                    if (field.type === "COMBO_BOX_REGISTER_FIELD") {

                                        //Get all from answers that have the field
                                        let formAnswers = card.form_answers?.filter(formAnswer => {

                                            //Valid if the field is in the formAnswer or in the fieldsGroup
                                            let findField = formAnswer.form_answer_fields.filter(faf => faf.field_id === field.id_field);

                                            if (field.fieldsGroup !== undefined && field.fieldsGroup.length > 0) {
                                                field.fieldsGroup.forEach(fg => {

                                                    const findFieldGroup = formAnswer.form_answer_fields.filter(faf => faf.field_id === fg.id_field);

                                                    if (findFieldGroup.length > 0) {
                                                        findField.push(findFieldGroup[0]);
                                                    }

                                                });
                                            }

                                            return findField.length > 0;

                                        });

                                        //Order formAnswers from the highest to the lowest id_form_answer
                                        formAnswers = formAnswers.sort((a, b) => b.id_form_answer - a.id_form_answer);

                                        //Get the value from the formAnswer
                                        if (formAnswers !== undefined && formAnswers.length > 0) {

                                            const fafs = formAnswers[0].form_answer_fields.filter(faf => faf.field_id === field.id_field);

                                            if (fafs !== undefined && fafs.length > 0) {
                                                valueColumn = fafs[0].valueString;
                                            } else {

                                                //Find the value in the fieldsGroup
                                                if (field.fieldsGroup !== undefined && field.fieldsGroup.length > 0) {
                                                    field.fieldsGroup.forEach(fg => {

                                                        const fafs = formAnswers[0].form_answer_fields.filter(faf => faf.field_id === fg.id_field);

                                                        if (fafs !== undefined && fafs.length > 0) {
                                                            valueColumn = fafs[0].valueString;
                                                        }

                                                    });

                                                }

                                            }

                                        }

                                    } else {

                                        //Get all from answers that have the field
                                        let formAnswers = card.form_answers?.filter(formAnswer => {

                                            const findField = formAnswer.form_answer_fields.filter(faf => faf.field_id === field.id_field);

                                            return findField.length > 0;

                                        });

                                        //Order formAnswers from the highest to the lowest id_form_answer
                                        formAnswers = formAnswers.sort((a, b) => b.id_form_answer - a.id_form_answer);

                                        //Get the value from the formAnswer
                                        if (formAnswers !== undefined && formAnswers.length > 0) {
                                            valueColumn = formAnswers[0].form_answer_fields.filter(faf => faf.field_id === field.id_field)[0].valueString;
                                        }

                                    }

                                }

                            } else if (field.typeOrigin === "TimeTracking") {

                            } else if (field.typeOrigin === "Card") {

                            }

                            //Add the value on row
                            if (valueColumn !== undefined && valueColumn !== null && valueColumn !== '') {
                                row[field.title] = valueColumn;
                            }

                        }

                    }

                    //Add the row on dataTable - If there are more than one user, add the more than one row                        
                    dataTable.push(row);

                }

            }


        }

    }

    //Aggregate the data
    let newDataTable = aggregateTimeSheetData(dataTable);

    //Reorder the items by the duration, higher to lower
    newDataTable = newDataTable.sort((a, b) => {
        if (a.duration && b.duration) {
            return b.duration - a.duration;
        }
        return 0;
    });

    return newDataTable;

}