import * as yup from "yup";

//Documentation: https://www.npmjs.com/package/yup#api

interface YupValidations {
    type: string;
    params: object;
}

export default function createYupSchema(schema: any, config: any): unknown {
    const { name, validation_type, validations = [], type, title, formula } = config;

    let newValidations = [];
    let useNewValidations = false;

    //Valida se o Validations vem do banco
    if (validations[0] !== undefined) {
        if (validations[0]["field_id"] > 0) {

            for (let index = 0; index < validations.length; index++) {

                let paramsItens = [];

                //Exception to Combo Box User Field
                if (type === "COMBO_BOX_USER_FIELD" && index === 0) {
                    if (validations[index]["type"] === "required") {
                        const newItemValidation2: YupValidations = {
                            type: 'matches',
                            params: [/^[0-9]+$/, "Deve ser selecionada ao menos uma opção válida no campo " + title + " (obrigatório)!"]
                        };
                        newValidations.push(newItemValidation2);
                    }
                } else if (type === "PHONE_FIELD" && index === 0) {
                    if (validations[index]["type"] === "required") {
                        const newItemValidation2: YupValidations = {
                            type: 'matches',
                            params: [/(\(?\d{2}\)?\s?)?\d{4,5}-?\d{4}/, "O formato do campo " + title + " não é válido! Favor verificar!"]
                        };
                        newValidations.push(newItemValidation2);
                    }                
                } else if (type === "DOC_FIELD" && index === 0) {
                    if (validations[index]["type"] === "required") {
                        const newItemValidation2: YupValidations = {
                            type: 'matches',
                            params: [/^(\d{3}\.\d{3}\.\d{3}-\d{2}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/, "O formato do campo " + title + " não é válido! Favor verificar!"]
                        };
                        newValidations.push(newItemValidation2);
                    }
                }

                paramsItens.push(validations[index]["params"]);

                const newItemValidation: YupValidations = {
                    type: validations[index]["type"] as string,
                    params: paramsItens
                };

                newValidations.push(newItemValidation);
                useNewValidations = true;

            }
        }
    }

    //Valid if has any regex validation on text_short
    if (type === "TEXT_SHORT_FIELD" && formula !== undefined && formula !== null && formula !== "") {
        const newItemValidation: YupValidations = {
            type: 'matches',
            params: [new RegExp(formula), "O valor informado não é válido para o campo " + title + "!"]
        };
        newValidations.push(newItemValidation);
        useNewValidations = true;
    }

    if (!(yup as any)[validation_type]) {
        return schema;
    }

    let validator = (yup as any)[validation_type]();

    if (useNewValidations) {
        newValidations.forEach((val: any) => {
            const { params, type } = val;
            if (!validator[type]) {
                return;
            }
            validator = validator[type](...params);
        });
    } else {
        validations.forEach((validation: any) => {
            const { params, type } = validation;
            if (!validator[type]) {
                return;
            }
            validator = validator[type](...params);
        });
    }

    schema[name] = validator;

    return schema;
}