import React from "react";

import {
    Container,
    ItemAutomation,
    ItemAutomationBadgeStatus,
    ItemAutomationDescription,
    ItemAutomationIcon,
    ItemAutomationLeft,
    ItemAutomationRight,
    ItemAutomationTitle,
} from "./style";
import { RiFlowChart, RiLightbulbFlashFill } from "react-icons/ri";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";

interface WelcomePageStepAutomationProps {
    setTypeAutomation?: (type: number) => void;
    totConditionalItems?: number;
    isConditionalActive?: boolean;
}

const WelcomePageStepAutomation: React.FC<WelcomePageStepAutomationProps> = ({ setTypeAutomation, isConditionalActive, totConditionalItems }) => {

    return (
        <Container>

            <ItemAutomation onClick={setTypeAutomation !== undefined ? () => setTypeAutomation(2) : () => { }}>

                <ItemAutomationLeft>
                    <ItemAutomationTitle>
                        <ItemAutomationIcon>
                            <RiFlowChart />
                        </ItemAutomationIcon>
                        Condicional
                    </ItemAutomationTitle>

                    <ItemAutomationDescription>
                        Defina regras para movimentar os cartões entre etapas. Com o campo condicional, você pode criar fluxos ainda mais dinâmicos e interativos
                    </ItemAutomationDescription>
                </ItemAutomationLeft>

                <ItemAutomationRight>
                    {!isConditionalActive ?
                        <ItemAutomationBadgeStatus>
                            <BsToggleOff />
                            <span>Inativo</span>
                        </ItemAutomationBadgeStatus> :
                        <ItemAutomationBadgeStatus style={{ backgroundColor: '#61bd4f' }}>
                            <BsToggleOn />
                            <span>{totConditionalItems !== undefined ? `${totConditionalItems} regras ativas` : 'Ativo'}</span>
                        </ItemAutomationBadgeStatus>
                    }
                </ItemAutomationRight>

            </ItemAutomation>

            <a href="https://www.loom.com/share/0832f0cc64864be8a9d32432e4ae3e72?sid=25086b3a-f018-4c97-9834-b8963ca5e041" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: "black" }}>
                <ItemAutomation style={{ borderColor: '#00bcd4' }}>

                    <ItemAutomationLeft>
                        <ItemAutomationTitle>
                            <ItemAutomationIcon style={{ backgroundColor: '#00bcd4' }}>
                                <RiLightbulbFlashFill />
                            </ItemAutomationIcon>
                            Assista e aprenda
                        </ItemAutomationTitle>

                        <ItemAutomationDescription>
                            Veja como criar etapas condicionais de forma simples e intuitiva. Torne seus fluxos mais inteligentes e automatizados.
                        </ItemAutomationDescription>
                    </ItemAutomationLeft>

                    <ItemAutomationRight>
                        <ItemAutomationBadgeStatus style={{ minWidth: '115px', backgroundColor: '#00bcd41e', color: '#212121' }}>
                            <span style={{ marginRight: '10px' }}>Ver o vídeo</span>
                            <FaArrowRight style={{ marginRight: '0px' }} />
                        </ItemAutomationBadgeStatus>
                    </ItemAutomationRight>

                </ItemAutomation>
            </a>

            <div style={{ marginBottom: '20px' }} />

        </Container>
    );

}

export default WelcomePageStepAutomation;