import styled from "styled-components";

import { Dialog } from '@material-ui/core';

export const DialogContainer = styled(Dialog)`

    position: relative;

    .MuiDialog-paperWidthSm {        
        max-width: 700px;
        min-height: calc(100vh - 10%);
        max-height: 700px;
    }

    .MuiDialogContent-root {
        padding: 10px !important;
    }
    
`;

export const DialogTopRight = styled.div`
    position: absolute;
    margin-top: 5px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 30px;
        border: none;
        background-color: transparent;
    }
`;

export const HeaderEmail = styled.div`
    background-color: #f4f5f7;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 10px;
    font-weight: 500;
    padding: 10px;
`;

export const HeaderEmailSubject = styled.div`
    width: calc(100% - 35px);
    margin-left: 5px;
`;
