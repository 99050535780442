import Grid from '@material-ui/core/Grid';
import styled from "styled-components";
import Button from "../../../components/Button";

export const ContentList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
`;

export const BreakArea = styled.div`
    margin-bottom: 30px !important;
`;

export const ContainerFields = styled(Grid)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
    min-width: 600px;
    border-radius: 10px 10px 10px 10px;
    padding-right: 4%;
    padding-left: 4%;
    padding-top: 1%;
    padding-bottom: 2%;    
    height: auto;
    width: 100%;
    padding: 40px;
    padding-top: 30px;
`;

export const ContainerApiHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: auto;
    margin-bottom: 20px;

    h3 {
        font-size: 16px;
        font-weight: 500;
        color: black;
        margin-bottom: 5px;
        margin-left: 5px;
    }

    span {
        font-size: 15px;
        font-weight: 500;
        color: gray;
        margin-bottom: 5px;
        margin-left: 5px;
    }
`;

export const ContainerApi = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
`;

export const ApiBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
    background-color: #f5f5f5;
`;

export const ApiBoxIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;    
    height: auto;
    font-size: 20px;
    font-size: 20px;
    background-color: white;
    margin-left: 15px;
    margin-right: 15px;
    padding: 5px;
    border-radius: 5px;
`;

export const ApiBoxDescription = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    height: auto;
    color: black;
    font-weight: 500;
`;

export const ApiBoxActions = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: auto;
`;

export const ButtonDelete = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    padding: 5px;
    border-radius: 5px;
    color: grey;

    :hover {
        opacity: 0.6;
    }
`; 

export const ButtonNewToken = styled(Button)`
    
    height: 40px;
    width: 180px;
    font-size: 15px;
    margin-top: 20px;

    svg {
        font-size: 18px;
        margin-right: 10px;
    }

    :hover {
        opacity: 0.6;
    }
`;