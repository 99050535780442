import { AiFillTag, AiOutlineNumber } from "react-icons/ai";
import {
    Divider,
    LabelInput,
    OptionItem,
    SelectInput,
    TextAreaInput,
    ColorPickerContainer
} from "../style";
import { ElementProps, Elements } from "../../../../components/ElementBuilder";
import { TbAxisX, TbAxisY, TbSquarePlus } from "react-icons/tb";

import ConfigSection from "../ConfigSection";
import { FiCode } from "react-icons/fi";
import { FieldProps } from "../../../../components/Forms/Fields/FieldBuilder";
import React from "react";
import { CirclePicker } from "react-color";
import { MdOutlineLineAxis } from "react-icons/md";

interface ConfigSectionElementProps {
    elementSelected: ElementProps;
    fieldsAxisX: FieldProps[];
    fieldsAxisY: FieldProps[];
    onSelectAxisX: (id_field: number, title?: string | null) => void;
    onSelectAxisY: (id_field: number) => void;
    onSelectGroupBy: (id_field: number) => void;
    onSelectGroupByDate: (group_date: string) => void;
    onSelectCalcFunction: (calc_function: string) => void;
    onSelectShowTime: (show_time: string) => void;
    onSelectGroupDate: (group_date: string) => void;
    onSelectSortBy: (sort_by: string) => void;
    onSelectColor: (color: string) => void;
    onChangeEmbed: (embed?: string) => void;
}

const colors: string[] = [
    "#f23b5c",
    "#9e37ed",
    "#4680B8",
    "#61bd4f",
    "#ff8c2f",
    "#795548",
    "#000000",
];

/* Components */
const ColumnChartConfig: React.FC<ConfigSectionElementProps> = ({ elementSelected, fieldsAxisX, fieldsAxisY, onSelectAxisX, onSelectAxisY, onSelectCalcFunction, onSelectShowTime, onSelectSortBy, onSelectColor, onSelectGroupDate }) => {

    return (
        <>
            <ConfigSection name={"Eixo X"} icon={TbAxisX}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_x_field_id} onChange={(e) => onSelectAxisX(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Etapas</OptionItem>
                        <OptionItem key={-2} value={-2}>Responsáveis</OptionItem>
                        <OptionItem key={-3} value={-3}>Etiquetas</OptionItem>
                        <OptionItem key={-4} value={-4}>Data de vencimento</OptionItem>
                        <OptionItem key={-5} value={-5}>Data de criação</OptionItem>
                        <OptionItem key={-6} value={-6}>Criado por</OptionItem>
                    </optgroup>
                    {fieldsAxisX.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisX.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {elementSelected.axis_x_field_id === -4 || elementSelected.axis_x_field_id === -5 ||
                    (fieldsAxisX !== undefined && fieldsAxisX.filter(f => (f.type === "DATE_PICKER_FIELD" || f.type === "DUE_DATE_FIELD") && f.id_field === elementSelected.axis_x_field_id).length > 0) ?
                    <>

                        <Divider />

                        <LabelInput>
                            Agrupar por
                        </LabelInput>
                        <SelectInput value={elementSelected.group_date} onChange={(e) => onSelectGroupDate(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dia</OptionItem>
                            <OptionItem key={3} value={"month"}>Mês</OptionItem>
                            <OptionItem key={4} value={"quarter"}>Trimestre</OptionItem>
                            <OptionItem key={4} value={"year"}>Ano</OptionItem>
                        </SelectInput>

                    </> :
                    <></>
                }

            </ConfigSection>

            <ConfigSection name={"Eixo Y"} icon={TbAxisY}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_y_field_id} onChange={(e) => onSelectAxisY(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Contar cartões</OptionItem>
                        {elementSelected.axis_x_field_id === -1 ?
                            <OptionItem key={-2} value={-2}>Tempo para Conclusão </OptionItem> :
                            <></>
                        }
                    </optgroup>
                    {fieldsAxisY.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisY.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {(elementSelected?.axis_y_field_id !== undefined && elementSelected.axis_y_field_id > 0) || elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Formato de cálculo
                        </LabelInput>
                        <SelectInput value={elementSelected.calc_function} onChange={(e) => onSelectCalcFunction(e.currentTarget.value)}>
                            <OptionItem key={1} value={"sum"}>Soma</OptionItem>
                            {elementSelected.axis_y_field_id !== -2 ?
                                <OptionItem key={2} value={"count"}>Contagem</OptionItem> :
                                <></>}
                            <OptionItem key={3} value={"avg"}>Média</OptionItem>
                            <OptionItem key={4} value={"min"}>Mín</OptionItem>
                            <OptionItem key={5} value={"max"}>Máx</OptionItem>
                        </SelectInput>
                    </> :
                    <></>
                }

                {elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Exibição do tempo
                        </LabelInput>
                        <SelectInput value={elementSelected.show_time} onChange={(e) => onSelectShowTime(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dias</OptionItem>
                            <OptionItem key={2} value={"hour"}>Horas</OptionItem>
                            <OptionItem key={3} value={"min"}>Minutos</OptionItem>
                            <OptionItem key={4} value={"sec"}>Segundos</OptionItem>
                        </SelectInput>
                    </> :
                    <></>
                }

            </ConfigSection>

            <ConfigSection name={"Mais configurações"} icon={TbSquarePlus}>
                <LabelInput>
                    Ordenarção
                </LabelInput>
                <SelectInput value={elementSelected.sort_by} onChange={(e) => onSelectSortBy(e.currentTarget.value)}>
                    <OptionItem key={1} value={"axis_y_desc"}>Eixo Y decrescente</OptionItem>
                    <OptionItem key={2} value={"axis_y_asc"}>Eixo Y crescente</OptionItem>
                    <OptionItem key={3} value={"axis_x_desc"}>Eixo X decrescente</OptionItem>
                    <OptionItem key={4} value={"axis_x_asc"}>Eixo X crescente</OptionItem>
                </SelectInput>

                <Divider />

                <LabelInput>
                    Cor
                </LabelInput>
                <ColorPickerContainer>
                    <CirclePicker
                        color={elementSelected.color}
                        colors={colors}
                        onChangeComplete={(c) => onSelectColor(c.hex)}
                    />
                </ColorPickerContainer>
            </ConfigSection>
        </>
    );
};

const ColumnStackedChartConfig: React.FC<ConfigSectionElementProps> = ({ elementSelected, fieldsAxisX, fieldsAxisY, onSelectAxisX, onSelectAxisY, onSelectGroupBy, onSelectGroupByDate, onSelectCalcFunction, onSelectShowTime, onSelectColor, onSelectSortBy, onSelectGroupDate }) => {
    return (
        <>
            <ConfigSection name={"Eixo X"} icon={TbAxisX}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_x_field_id} onChange={(e) => onSelectAxisX(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Etapas</OptionItem>
                        <OptionItem key={-2} value={-2}>Responsáveis</OptionItem>
                        <OptionItem key={-3} value={-3}>Etiquetas</OptionItem>
                        <OptionItem key={-4} value={-4}>Data de vencimento</OptionItem>
                        <OptionItem key={-5} value={-5}>Data de criação</OptionItem>
                        <OptionItem key={-6} value={-6}>Criado por</OptionItem>
                    </optgroup>
                    {fieldsAxisX.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisX.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {elementSelected.axis_x_field_id === -4 || elementSelected.axis_x_field_id === -5 ||
                    (fieldsAxisX !== undefined && fieldsAxisX.filter(f => (f.type === "DATE_PICKER_FIELD" || f.type === "DUE_DATE_FIELD") && f.id_field === elementSelected.axis_x_field_id).length > 0) ?
                    <>

                        <Divider />

                        <LabelInput>
                            Agrupar por
                        </LabelInput>
                        <SelectInput value={elementSelected.group_date} onChange={(e) => onSelectGroupDate(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dia</OptionItem>
                            <OptionItem key={3} value={"month"}>Mês</OptionItem>
                            <OptionItem key={4} value={"quarter"}>Trimestre</OptionItem>
                            <OptionItem key={4} value={"year"}>Ano</OptionItem>
                        </SelectInput>

                    </> :
                    <></>
                }
            </ConfigSection>

            <ConfigSection name={"Eixo Y"} icon={TbAxisY}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_y_field_id} onChange={(e) => onSelectAxisY(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Contar cartões</OptionItem>
                    </optgroup>
                    {fieldsAxisY.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisY.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {(elementSelected?.axis_y_field_id !== undefined && elementSelected.axis_y_field_id > 0) || elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Formato de cálculo
                        </LabelInput>
                        <SelectInput value={elementSelected.calc_function} onChange={(e) => onSelectCalcFunction(e.currentTarget.value)}>
                            <OptionItem key={1} value={"sum"}>Soma</OptionItem>
                            {elementSelected.axis_y_field_id !== -2 ?
                                <OptionItem key={2} value={"count"}>Contagem</OptionItem> :
                                <></>}
                            <OptionItem key={3} value={"avg"}>Média</OptionItem>
                            <OptionItem key={4} value={"min"}>Mín</OptionItem>
                            <OptionItem key={5} value={"max"}>Máx</OptionItem>
                        </SelectInput>
                    </> :
                    <></>
                }

                {elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Exibição do tempo
                        </LabelInput>
                        <SelectInput value={elementSelected.show_time} onChange={(e) => onSelectShowTime(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dias</OptionItem>
                            <OptionItem key={2} value={"hour"}>Horas</OptionItem>
                            <OptionItem key={3} value={"min"}>Minutos</OptionItem>
                            <OptionItem key={4} value={"sec"}>Segundos</OptionItem>
                        </SelectInput>
                    </> :
                    <></>
                }

            </ConfigSection>

            <ConfigSection name={"Empilhar por"} icon={MdOutlineLineAxis}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.group_by_field_id} onChange={(e) => onSelectGroupBy(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Etapas</OptionItem>
                        <OptionItem key={-2} value={-2}>Responsáveis</OptionItem>
                        <OptionItem key={-3} value={-3}>Etiquetas</OptionItem>
                        <OptionItem key={-4} value={-4}>Data de vencimento</OptionItem>
                        <OptionItem key={-5} value={-5}>Data de criação</OptionItem>
                        <OptionItem key={-6} value={-6}>Criado por</OptionItem>
                    </optgroup>
                    {fieldsAxisX.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisX.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {elementSelected.group_by_field_id === -4 || elementSelected.group_by_field_id === -5 ||
                    (fieldsAxisX !== undefined && fieldsAxisX.filter(f => (f.type === "DATE_PICKER_FIELD" || f.type === "DUE_DATE_FIELD") && f.id_field === elementSelected.group_by_field_id).length > 0) ?
                    <>

                        <Divider />

                        <LabelInput>
                            Agrupar por
                        </LabelInput>
                        <SelectInput value={elementSelected.group_by_field_date} onChange={(e) => onSelectGroupByDate(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dia</OptionItem>
                            <OptionItem key={3} value={"month"}>Mês</OptionItem>
                            <OptionItem key={4} value={"quarter"}>Trimestre</OptionItem>
                            <OptionItem key={4} value={"year"}>Ano</OptionItem>
                        </SelectInput>

                    </> :
                    <></>
                }
            </ConfigSection>

            <ConfigSection name={"Mais configurações"} icon={TbSquarePlus}>
                <LabelInput>
                    Ordenarção
                </LabelInput>
                <SelectInput value={elementSelected.sort_by} onChange={(e) => onSelectSortBy(e.currentTarget.value)}>
                    <OptionItem key={3} value={"axis_x_desc"}>Eixo X decrescente</OptionItem>
                    <OptionItem key={4} value={"axis_x_asc"}>Eixo X crescente</OptionItem>
                </SelectInput>
            </ConfigSection>
        </>
    );
};

const BarChartConfig: React.FC<ConfigSectionElementProps> = ({ elementSelected, fieldsAxisX, fieldsAxisY, onSelectAxisX, onSelectAxisY, onSelectCalcFunction, onSelectShowTime, onSelectColor, onSelectSortBy, onSelectGroupDate }) => {

    return (
        <>
            <ConfigSection name={"Eixo X"} icon={TbAxisX}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_x_field_id} onChange={(e) => onSelectAxisX(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Etapas</OptionItem>
                        <OptionItem key={-2} value={-2}>Responsáveis</OptionItem>
                        <OptionItem key={-3} value={-3}>Etiquetas</OptionItem>
                        <OptionItem key={-4} value={-4}>Data de vencimento</OptionItem>
                        <OptionItem key={-5} value={-5}>Data de criação</OptionItem>
                        <OptionItem key={-6} value={-6}>Criado por</OptionItem>
                    </optgroup>
                    {fieldsAxisX.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisX.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {elementSelected.axis_x_field_id === -4 || elementSelected.axis_x_field_id === -5 ||
                    (fieldsAxisX !== undefined && fieldsAxisX.filter(f => (f.type === "DATE_PICKER_FIELD" || f.type === "DUE_DATE_FIELD") && f.id_field === elementSelected.axis_x_field_id).length > 0) ?
                    <>

                        <Divider />

                        <LabelInput>
                            Agrupar por
                        </LabelInput>
                        <SelectInput value={elementSelected.group_date} onChange={(e) => onSelectGroupDate(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dia</OptionItem>
                            <OptionItem key={3} value={"month"}>Mês</OptionItem>
                            <OptionItem key={4} value={"quarter"}>Trimestre</OptionItem>
                            <OptionItem key={4} value={"year"}>Ano</OptionItem>
                        </SelectInput>

                    </> :
                    <></>
                }
            </ConfigSection>

            <ConfigSection name={"Eixo Y"} icon={TbAxisY}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_y_field_id} onChange={(e) => onSelectAxisY(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Contar cartões</OptionItem>
                        {elementSelected.axis_x_field_id === -1 ?
                            <OptionItem key={-2} value={-2}>Tempo para Conclusão </OptionItem> :
                            <></>
                        }
                    </optgroup>
                    {fieldsAxisY.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisY.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {(elementSelected?.axis_y_field_id !== undefined && elementSelected.axis_y_field_id > 0) || elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Formato de cálculo
                        </LabelInput>
                        <SelectInput value={elementSelected.calc_function} onChange={(e) => onSelectCalcFunction(e.currentTarget.value)}>
                            <OptionItem key={1} value={"sum"}>Soma</OptionItem>
                            {elementSelected.axis_y_field_id !== -2 ?
                                <OptionItem key={2} value={"count"}>Contagem</OptionItem> :
                                <></>}
                            <OptionItem key={3} value={"avg"}>Média</OptionItem>
                            <OptionItem key={4} value={"min"}>Mín</OptionItem>
                            <OptionItem key={5} value={"max"}>Máx</OptionItem>
                        </SelectInput>
                    </> :
                    <></>
                }

                {elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Exibição do tempo
                        </LabelInput>
                        <SelectInput value={elementSelected.show_time} onChange={(e) => onSelectShowTime(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dias</OptionItem>
                            <OptionItem key={2} value={"hour"}>Horas</OptionItem>
                            <OptionItem key={3} value={"min"}>Minutos</OptionItem>
                            <OptionItem key={4} value={"sec"}>Segundos</OptionItem>
                        </SelectInput>
                    </> :
                    <></>
                }

            </ConfigSection>

            <ConfigSection name={"Mais configurações"} icon={TbSquarePlus}>
                <LabelInput>
                    Ordenarção
                </LabelInput>
                <SelectInput value={elementSelected.sort_by} onChange={(e) => onSelectSortBy(e.currentTarget.value)}>
                    <OptionItem key={1} value={"axis_y_desc"}>Eixo Y decrescente</OptionItem>
                    <OptionItem key={2} value={"axis_y_asc"}>Eixo Y crescente</OptionItem>
                    <OptionItem key={3} value={"axis_x_desc"}>Eixo X decrescente</OptionItem>
                    <OptionItem key={4} value={"axis_x_asc"}>Eixo X crescente</OptionItem>
                </SelectInput>

                <Divider />

                <LabelInput>
                    Cor
                </LabelInput>
                <ColorPickerContainer>
                    <CirclePicker
                        color={elementSelected.color}
                        colors={colors}
                        onChangeComplete={(c) => onSelectColor(c.hex)}
                    />
                </ColorPickerContainer>
            </ConfigSection>
        </>
    );
};

const LineChartConfig: React.FC<ConfigSectionElementProps> = ({ elementSelected, fieldsAxisX, fieldsAxisY, onSelectAxisX, onSelectAxisY, onSelectCalcFunction, onSelectShowTime, onSelectColor, onSelectSortBy, onSelectGroupDate }) => {
    return (
        <>
            <ConfigSection name={"Eixo X"} icon={TbAxisX}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_x_field_id} onChange={(e) => onSelectAxisX(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Etapas</OptionItem>
                        <OptionItem key={-2} value={-2}>Responsáveis</OptionItem>
                        <OptionItem key={-3} value={-3}>Etiquetas</OptionItem>
                        <OptionItem key={-4} value={-4}>Data de vencimento</OptionItem>
                        <OptionItem key={-5} value={-5}>Data de criação</OptionItem>
                        <OptionItem key={-6} value={-6}>Criado por</OptionItem>
                    </optgroup>
                    {fieldsAxisX.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisX.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {elementSelected.axis_x_field_id === -4 || elementSelected.axis_x_field_id === -5 ||
                    (fieldsAxisX !== undefined && fieldsAxisX.filter(f => (f.type === "DATE_PICKER_FIELD" || f.type === "DUE_DATE_FIELD") && f.id_field === elementSelected.axis_x_field_id).length > 0) ?
                    <>

                        <Divider />

                        <LabelInput>
                            Agrupar por
                        </LabelInput>
                        <SelectInput value={elementSelected.group_date} onChange={(e) => onSelectGroupDate(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dia</OptionItem>
                            <OptionItem key={3} value={"month"}>Mês</OptionItem>
                            <OptionItem key={4} value={"quarter"}>Trimestre</OptionItem>
                            <OptionItem key={4} value={"year"}>Ano</OptionItem>
                        </SelectInput>

                    </> :
                    <></>
                }
            </ConfigSection>

            <ConfigSection name={"Eixo Y"} icon={TbAxisY}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_y_field_id} onChange={(e) => onSelectAxisY(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Contar cartões</OptionItem>
                        {elementSelected.axis_x_field_id === -1 ?
                            <OptionItem key={-2} value={-2}>Tempo para Conclusão </OptionItem> :
                            <></>
                        }
                    </optgroup>
                    {fieldsAxisY.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisY.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {(elementSelected?.axis_y_field_id !== undefined && elementSelected.axis_y_field_id > 0) || elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Formato de cálculo
                        </LabelInput>
                        <SelectInput value={elementSelected.calc_function} onChange={(e) => onSelectCalcFunction(e.currentTarget.value)}>
                            <OptionItem key={1} value={"sum"}>Soma</OptionItem>
                            {elementSelected.axis_y_field_id !== -2 ?
                                <OptionItem key={2} value={"count"}>Contagem</OptionItem> :
                                <></>}
                            <OptionItem key={3} value={"avg"}>Média</OptionItem>
                            <OptionItem key={4} value={"min"}>Mín</OptionItem>
                            <OptionItem key={5} value={"max"}>Máx</OptionItem>
                        </SelectInput>
                    </> :
                    <></>
                }

                {elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Exibição do tempo
                        </LabelInput>
                        <SelectInput value={elementSelected.show_time} onChange={(e) => onSelectShowTime(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dias</OptionItem>
                            <OptionItem key={2} value={"hour"}>Horas</OptionItem>
                            <OptionItem key={3} value={"min"}>Minutos</OptionItem>
                            <OptionItem key={4} value={"sec"}>Segundos</OptionItem>
                        </SelectInput>
                    </> :
                    <></>
                }

            </ConfigSection>

            {/*
            <ConfigSection name={"Agrupar por"} icon={MdOutlineLineAxis}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_x_field_id} onChange={(e) => onSelectAxisX(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Etapas</OptionItem>
                        <OptionItem key={-2} value={-2}>Responsáveis</OptionItem>
                        <OptionItem key={-3} value={-3}>Etiquetas</OptionItem>
                        <OptionItem key={-4} value={-4}>Data de vencimento</OptionItem>
                        <OptionItem key={-5} value={-5}>Data de criação</OptionItem>
                    </optgroup>
                    {fieldsAxisX.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisX.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {elementSelected.axis_x_field_id === -4 || elementSelected.axis_x_field_id === -5 ||
                    (fieldsAxisX !== undefined && fieldsAxisX.filter(f => (f.type === "DATE_PICKER_FIELD" || f.type === "DUE_DATE_FIELD") && f.id_field === elementSelected.axis_x_field_id).length > 0) ?
                    <>

                        <Divider />

                        <LabelInput>
                            Agrupar por
                        </LabelInput>
                        <SelectInput value={elementSelected.group_date} onChange={(e) => onSelectGroupDate(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dia</OptionItem>
                            <OptionItem key={3} value={"month"}>Mês</OptionItem>
                            <OptionItem key={4} value={"quarter"}>Trimestre</OptionItem>
                            <OptionItem key={4} value={"year"}>Ano</OptionItem>
                        </SelectInput>

                    </> :
                    <></>
                }
            </ConfigSection>
            */}

            <ConfigSection name={"Mais configurações"} icon={TbSquarePlus}>
                <LabelInput>
                    Ordenarção
                </LabelInput>
                <SelectInput value={elementSelected.sort_by} onChange={(e) => onSelectSortBy(e.currentTarget.value)}>
                    <OptionItem key={1} value={"axis_y_desc"}>Eixo Y decrescente</OptionItem>
                    <OptionItem key={2} value={"axis_y_asc"}>Eixo Y crescente</OptionItem>
                    <OptionItem key={3} value={"axis_x_desc"}>Eixo X decrescente</OptionItem>
                    <OptionItem key={4} value={"axis_x_asc"}>Eixo X crescente</OptionItem>
                </SelectInput>
            </ConfigSection>
        </>
    );
};

const LineChartStackedConfig: React.FC<ConfigSectionElementProps> = ({ elementSelected, fieldsAxisX, fieldsAxisY, onSelectAxisX, onSelectAxisY, onSelectGroupBy, onSelectGroupByDate, onSelectCalcFunction, onSelectShowTime, onSelectColor, onSelectSortBy, onSelectGroupDate }) => {
    return (
        <>
            <ConfigSection name={"Eixo X"} icon={TbAxisX}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_x_field_id} onChange={(e) => onSelectAxisX(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Etapas</OptionItem>
                        <OptionItem key={-2} value={-2}>Responsáveis</OptionItem>
                        <OptionItem key={-3} value={-3}>Etiquetas</OptionItem>
                        <OptionItem key={-4} value={-4}>Data de vencimento</OptionItem>
                        <OptionItem key={-5} value={-5}>Data de criação</OptionItem>
                        <OptionItem key={-6} value={-6}>Criado por</OptionItem>
                    </optgroup>
                    {fieldsAxisX.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisX.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {elementSelected.axis_x_field_id === -4 || elementSelected.axis_x_field_id === -5 ||
                    (fieldsAxisX !== undefined && fieldsAxisX.filter(f => (f.type === "DATE_PICKER_FIELD" || f.type === "DUE_DATE_FIELD") && f.id_field === elementSelected.axis_x_field_id).length > 0) ?
                    <>

                        <Divider />

                        <LabelInput>
                            Agrupar por
                        </LabelInput>
                        <SelectInput value={elementSelected.group_date} onChange={(e) => onSelectGroupDate(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dia</OptionItem>
                            <OptionItem key={3} value={"month"}>Mês</OptionItem>
                            <OptionItem key={4} value={"quarter"}>Trimestre</OptionItem>
                            <OptionItem key={4} value={"year"}>Ano</OptionItem>
                        </SelectInput>

                    </> :
                    <></>
                }
            </ConfigSection>

            <ConfigSection name={"Eixo Y"} icon={TbAxisY}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_y_field_id} onChange={(e) => onSelectAxisY(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Contar cartões</OptionItem>
                    </optgroup>
                    {fieldsAxisY.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisY.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {(elementSelected?.axis_y_field_id !== undefined && elementSelected.axis_y_field_id > 0) || elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Formato de cálculo
                        </LabelInput>
                        <SelectInput value={elementSelected.calc_function} onChange={(e) => onSelectCalcFunction(e.currentTarget.value)}>
                            <OptionItem key={1} value={"sum"}>Soma</OptionItem>
                            {elementSelected.axis_y_field_id !== -2 ?
                                <OptionItem key={2} value={"count"}>Contagem</OptionItem> :
                                <></>}
                            <OptionItem key={3} value={"avg"}>Média</OptionItem>
                            <OptionItem key={4} value={"min"}>Mín</OptionItem>
                            <OptionItem key={5} value={"max"}>Máx</OptionItem>
                        </SelectInput>
                    </> :
                    <></>
                }

                {elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Exibição do tempo
                        </LabelInput>
                        <SelectInput value={elementSelected.show_time} onChange={(e) => onSelectShowTime(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dias</OptionItem>
                            <OptionItem key={2} value={"hour"}>Horas</OptionItem>
                            <OptionItem key={3} value={"min"}>Minutos</OptionItem>
                            <OptionItem key={4} value={"sec"}>Segundos</OptionItem>
                        </SelectInput>
                    </> :
                    <></>
                }

            </ConfigSection>

            <ConfigSection name={"Empilhar por"} icon={MdOutlineLineAxis}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.group_by_field_id} onChange={(e) => onSelectGroupBy(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Etapas</OptionItem>
                        <OptionItem key={-2} value={-2}>Responsáveis</OptionItem>
                        <OptionItem key={-3} value={-3}>Etiquetas</OptionItem>
                        <OptionItem key={-4} value={-4}>Data de vencimento</OptionItem>
                        <OptionItem key={-5} value={-5}>Data de criação</OptionItem>
                        <OptionItem key={-6} value={-6}>Criado por</OptionItem>
                    </optgroup>
                    {fieldsAxisX.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisX.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {elementSelected.group_by_field_id === -4 || elementSelected.group_by_field_id === -5 ||
                    (fieldsAxisX !== undefined && fieldsAxisX.filter(f => (f.type === "DATE_PICKER_FIELD" || f.type === "DUE_DATE_FIELD") && f.id_field === elementSelected.group_by_field_id).length > 0) ?
                    <>

                        <Divider />

                        <LabelInput>
                            Agrupar por
                        </LabelInput>
                        <SelectInput value={elementSelected.group_by_field_date} onChange={(e) => onSelectGroupByDate(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dia</OptionItem>
                            <OptionItem key={3} value={"month"}>Mês</OptionItem>
                            <OptionItem key={4} value={"quarter"}>Trimestre</OptionItem>
                            <OptionItem key={4} value={"year"}>Ano</OptionItem>
                        </SelectInput>

                    </> :
                    <></>
                }
            </ConfigSection>

            <ConfigSection name={"Mais configurações"} icon={TbSquarePlus}>
                <LabelInput>
                    Ordenarção
                </LabelInput>
                <SelectInput value={elementSelected.sort_by} onChange={(e) => onSelectSortBy(e.currentTarget.value)}>
                    <OptionItem key={3} value={"axis_x_desc"}>Eixo X decrescente</OptionItem>
                    <OptionItem key={4} value={"axis_x_asc"}>Eixo X crescente</OptionItem>
                </SelectInput>
            </ConfigSection>
        </>
    );
};

const HeatMapChartConfig: React.FC<ConfigSectionElementProps> = ({ elementSelected, fieldsAxisX, fieldsAxisY, onSelectAxisX, onSelectAxisY, onSelectGroupBy, onSelectGroupByDate, onSelectCalcFunction, onSelectShowTime, onSelectColor, onSelectSortBy, onSelectGroupDate }) => {
    return (
        <>
            <ConfigSection name={"Eixo X"} icon={TbAxisX}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_x_field_id} onChange={(e) => onSelectAxisX(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Etapas</OptionItem>
                        <OptionItem key={-2} value={-2}>Responsáveis</OptionItem>
                        <OptionItem key={-3} value={-3}>Etiquetas</OptionItem>
                        <OptionItem key={-4} value={-4}>Data de vencimento</OptionItem>
                        <OptionItem key={-5} value={-5}>Data de criação</OptionItem>
                        <OptionItem key={-6} value={-6}>Criado por</OptionItem>
                    </optgroup>
                    {fieldsAxisX.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisX.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {elementSelected.axis_x_field_id === -4 || elementSelected.axis_x_field_id === -5 ||
                    (fieldsAxisX !== undefined && fieldsAxisX.filter(f => (f.type === "DATE_PICKER_FIELD" || f.type === "DUE_DATE_FIELD") && f.id_field === elementSelected.axis_x_field_id).length > 0) ?
                    <>

                        <Divider />

                        <LabelInput>
                            Agrupar por
                        </LabelInput>
                        <SelectInput value={elementSelected.group_date} onChange={(e) => onSelectGroupDate(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dia</OptionItem>
                            <OptionItem key={3} value={"month"}>Mês</OptionItem>
                            <OptionItem key={4} value={"quarter"}>Trimestre</OptionItem>
                            <OptionItem key={4} value={"year"}>Ano</OptionItem>
                        </SelectInput>

                    </> :
                    <></>
                }
            </ConfigSection>

            <ConfigSection name={"Eixo Y"} icon={TbAxisY}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_y_field_id} onChange={(e) => onSelectAxisY(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Contar cartões</OptionItem>
                    </optgroup>
                    {fieldsAxisY.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisY.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {(elementSelected?.axis_y_field_id !== undefined && elementSelected.axis_y_field_id > 0) || elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Formato de cálculo
                        </LabelInput>
                        <SelectInput value={elementSelected.calc_function} onChange={(e) => onSelectCalcFunction(e.currentTarget.value)}>
                            <OptionItem key={1} value={"sum"}>Soma</OptionItem>
                            {elementSelected.axis_y_field_id !== -2 ?
                                <OptionItem key={2} value={"count"}>Contagem</OptionItem> :
                                <></>}
                            <OptionItem key={3} value={"avg"}>Média</OptionItem>
                            <OptionItem key={4} value={"min"}>Mín</OptionItem>
                            <OptionItem key={5} value={"max"}>Máx</OptionItem>
                        </SelectInput>
                    </> :
                    <></>
                }

                {elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Exibição do tempo
                        </LabelInput>
                        <SelectInput value={elementSelected.show_time} onChange={(e) => onSelectShowTime(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dias</OptionItem>
                            <OptionItem key={2} value={"hour"}>Horas</OptionItem>
                            <OptionItem key={3} value={"min"}>Minutos</OptionItem>
                            <OptionItem key={4} value={"sec"}>Segundos</OptionItem>
                        </SelectInput>
                    </> :
                    <></>
                }

            </ConfigSection>

            <ConfigSection name={"Empilhar por"} icon={MdOutlineLineAxis}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.group_by_field_id} onChange={(e) => onSelectGroupBy(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Etapas</OptionItem>
                        <OptionItem key={-2} value={-2}>Responsáveis</OptionItem>
                        <OptionItem key={-3} value={-3}>Etiquetas</OptionItem>
                        <OptionItem key={-4} value={-4}>Data de vencimento</OptionItem>
                        <OptionItem key={-5} value={-5}>Data de criação</OptionItem>
                        <OptionItem key={-6} value={-6}>Criado por</OptionItem>
                    </optgroup>
                    {fieldsAxisX.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisX.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {elementSelected.group_by_field_id === -4 || elementSelected.group_by_field_id === -5 ||
                    (fieldsAxisX !== undefined && fieldsAxisX.filter(f => (f.type === "DATE_PICKER_FIELD" || f.type === "DUE_DATE_FIELD") && f.id_field === elementSelected.group_by_field_id).length > 0) ?
                    <>

                        <Divider />

                        <LabelInput>
                            Agrupar por
                        </LabelInput>
                        <SelectInput value={elementSelected.group_by_field_date} onChange={(e) => onSelectGroupByDate(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dia</OptionItem>
                            <OptionItem key={3} value={"month"}>Mês</OptionItem>
                            <OptionItem key={4} value={"quarter"}>Trimestre</OptionItem>
                            <OptionItem key={4} value={"year"}>Ano</OptionItem>
                        </SelectInput>

                    </> :
                    <></>
                }
            </ConfigSection>

            <ConfigSection name={"Mais configurações"} icon={TbSquarePlus}>
                <LabelInput>
                    Ordenarção
                </LabelInput>
                <SelectInput value={elementSelected.sort_by} onChange={(e) => onSelectSortBy(e.currentTarget.value)}>
                    <OptionItem key={3} value={"axis_x_desc"}>Eixo X decrescente</OptionItem>
                    <OptionItem key={4} value={"axis_x_asc"}>Eixo X crescente</OptionItem>
                </SelectInput>
            </ConfigSection>
        </>
    );
};

const PieChartConfig: React.FC<ConfigSectionElementProps> = ({ elementSelected, fieldsAxisX, fieldsAxisY, onSelectAxisX, onSelectAxisY, onSelectCalcFunction, onSelectShowTime, onSelectSortBy, onSelectGroupDate }) => {
    return (
        <>
            <ConfigSection name={"Etiquetas"} icon={AiFillTag}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_x_field_id} onChange={(e) => onSelectAxisX(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Etapas</OptionItem>
                        <OptionItem key={-2} value={-2}>Responsáveis</OptionItem>
                        <OptionItem key={-3} value={-3}>Etiquetas</OptionItem>
                        <OptionItem key={-4} value={-4}>Data de vencimento</OptionItem>
                        <OptionItem key={-5} value={-5}>Data de criação</OptionItem>
                        <OptionItem key={-6} value={-6}>Criado por</OptionItem>
                    </optgroup>
                    {fieldsAxisX.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisX.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {elementSelected.axis_x_field_id === -4 || elementSelected.axis_x_field_id === -5 ||
                    (fieldsAxisX !== undefined && fieldsAxisX.filter(f => (f.type === "DATE_PICKER_FIELD" || f.type === "DUE_DATE_FIELD") && f.id_field === elementSelected.axis_x_field_id).length > 0) ?
                    <>

                        <Divider />

                        <LabelInput>
                            Agrupar por
                        </LabelInput>
                        <SelectInput value={elementSelected.group_date} onChange={(e) => onSelectGroupDate(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dia</OptionItem>
                            <OptionItem key={3} value={"month"}>Mês</OptionItem>
                            <OptionItem key={4} value={"quarter"}>Trimestre</OptionItem>
                            <OptionItem key={4} value={"year"}>Ano</OptionItem>
                        </SelectInput>

                    </> :
                    <></>
                }
            </ConfigSection>

            <ConfigSection name={"Valores"} icon={AiOutlineNumber}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_y_field_id} onChange={(e) => onSelectAxisY(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1}>Contar cartões</OptionItem>
                        {elementSelected.axis_x_field_id === -1 ?
                            <OptionItem key={-2} value={-2}>Tempo para Conclusão </OptionItem> :
                            <></>
                        }
                    </optgroup>
                    {fieldsAxisY.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisY.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {(elementSelected?.axis_y_field_id !== undefined && elementSelected.axis_y_field_id > 0) || elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Formato de cálculo
                        </LabelInput>
                        <SelectInput value={elementSelected.calc_function} onChange={(e) => onSelectCalcFunction(e.currentTarget.value)}>
                            <OptionItem key={1} value={"sum"}>Soma</OptionItem>
                            {elementSelected.axis_y_field_id !== -2 ?
                                <OptionItem key={2} value={"count"}>Contagem</OptionItem> :
                                <></>}
                            <OptionItem key={3} value={"avg"}>Média</OptionItem>
                            <OptionItem key={4} value={"min"}>Mín</OptionItem>
                            <OptionItem key={5} value={"max"}>Máx</OptionItem>
                        </SelectInput>

                    </> :
                    <></>
                }

                {elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Exibição do tempo
                        </LabelInput>
                        <SelectInput value={elementSelected.show_time} onChange={(e) => onSelectShowTime(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dias</OptionItem>
                            <OptionItem key={2} value={"hour"}>Horas</OptionItem>
                            <OptionItem key={3} value={"min"}>Minutos</OptionItem>
                            <OptionItem key={4} value={"sec"}>Segundos</OptionItem>
                        </SelectInput>
                    </> :
                    <></>
                }

            </ConfigSection>

            <ConfigSection name={"Mais configurações"} icon={TbSquarePlus}>
                <LabelInput>
                    Ordenarção
                </LabelInput>
                <SelectInput value={elementSelected.sort_by} onChange={(e) => onSelectSortBy(e.currentTarget.value)}>
                    <OptionItem key={1} value={"axis_y_desc"}>Valor decrescente</OptionItem>
                    <OptionItem key={2} value={"axis_y_asc"}>Valor crescente</OptionItem>
                    <OptionItem key={3} value={"axis_x_desc"}>Etiqueta decrescente</OptionItem>
                    <OptionItem key={4} value={"axis_x_asc"}>Etiqueta crescente</OptionItem>
                </SelectInput>
            </ConfigSection>
        </>
    );
};

const FunnelChartConfig: React.FC<ConfigSectionElementProps> = ({ elementSelected, fieldsAxisX, fieldsAxisY, onSelectAxisX, onSelectAxisY, onSelectCalcFunction, onSelectShowTime, onSelectSortBy, onSelectGroupDate }) => {
    return (
        <>
            <ConfigSection name={"Etiquetas"} icon={AiFillTag}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_x_field_id} onChange={(e) => onSelectAxisX(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Etapas</OptionItem>
                        <OptionItem key={-2} value={-2}>Responsáveis</OptionItem>
                        <OptionItem key={-3} value={-3}>Etiquetas</OptionItem>
                        <OptionItem key={-4} value={-4}>Data de vencimento</OptionItem>
                        <OptionItem key={-5} value={-5}>Data de criação</OptionItem>
                        <OptionItem key={-6} value={-6}>Criado por</OptionItem>
                    </optgroup>
                    {fieldsAxisX.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisX.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {elementSelected.axis_x_field_id === -4 || elementSelected.axis_x_field_id === -5 ||
                    (fieldsAxisX !== undefined && fieldsAxisX.filter(f => (f.type === "DATE_PICKER_FIELD" || f.type === "DUE_DATE_FIELD") && f.id_field === elementSelected.axis_x_field_id).length > 0) ?
                    <>

                        <Divider />

                        <LabelInput>
                            Agrupar por
                        </LabelInput>
                        <SelectInput value={elementSelected.group_date} onChange={(e) => onSelectGroupDate(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dia</OptionItem>
                            <OptionItem key={3} value={"month"}>Mês</OptionItem>
                            <OptionItem key={4} value={"quarter"}>Trimestre</OptionItem>
                            <OptionItem key={4} value={"year"}>Ano</OptionItem>
                        </SelectInput>

                    </> :
                    <></>
                }
            </ConfigSection>

            <ConfigSection name={"Valores"} icon={AiOutlineNumber}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_y_field_id} onChange={(e) => onSelectAxisY(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1}>Contar cartões</OptionItem>
                        {elementSelected.axis_x_field_id === -1 ?
                            <OptionItem key={-2} value={-2}>Tempo para Conclusão </OptionItem> :
                            <></>
                        }
                    </optgroup>
                    {fieldsAxisY.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisY.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {(elementSelected?.axis_y_field_id !== undefined && elementSelected.axis_y_field_id > 0) || elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Formato de cálculo
                        </LabelInput>
                        <SelectInput value={elementSelected.calc_function} onChange={(e) => onSelectCalcFunction(e.currentTarget.value)}>
                            <OptionItem key={1} value={"sum"}>Soma</OptionItem>
                            {elementSelected.axis_y_field_id !== -2 ?
                                <OptionItem key={2} value={"count"}>Contagem</OptionItem> :
                                <></>}
                            <OptionItem key={3} value={"avg"}>Média</OptionItem>
                            <OptionItem key={4} value={"min"}>Mín</OptionItem>
                            <OptionItem key={5} value={"max"}>Máx</OptionItem>
                        </SelectInput>

                    </> :
                    <></>
                }

                {elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Exibição do tempo
                        </LabelInput>
                        <SelectInput value={elementSelected.show_time} onChange={(e) => onSelectShowTime(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dias</OptionItem>
                            <OptionItem key={2} value={"hour"}>Horas</OptionItem>
                            <OptionItem key={3} value={"min"}>Minutos</OptionItem>
                            <OptionItem key={4} value={"sec"}>Segundos</OptionItem>
                        </SelectInput>
                    </> :
                    <></>
                }

            </ConfigSection>

            <ConfigSection name={"Mais configurações"} icon={TbSquarePlus}>
                <LabelInput>
                    Ordenarção
                </LabelInput>
                <SelectInput value={elementSelected.sort_by} onChange={(e) => onSelectSortBy(e.currentTarget.value)}>
                    <OptionItem key={1} value={"axis_y_desc"}>Valor decrescente</OptionItem>
                    <OptionItem key={2} value={"axis_y_asc"}>Valor crescente</OptionItem>
                    <OptionItem key={3} value={"axis_x_desc"}>Etiqueta decrescente</OptionItem>
                    <OptionItem key={4} value={"axis_x_asc"}>Etiqueta crescente</OptionItem>
                </SelectInput>
            </ConfigSection>
        </>
    );
};

const NumberChartConfig: React.FC<ConfigSectionElementProps> = ({ elementSelected, fieldsAxisY, onSelectAxisY, onSelectCalcFunction, onSelectShowTime, onSelectColor }) => {
    return (
        <>
            <ConfigSection name={"Valores"} icon={AiOutlineNumber}>
                <LabelInput>
                    Campos do fluxo
                </LabelInput>
                <SelectInput value={elementSelected.axis_y_field_id} onChange={(e) => onSelectAxisY(Number(e.currentTarget.value))}>
                    <optgroup label="Campos Padrões">
                        <OptionItem key={-1} value={-1}>Contar cartões</OptionItem>
                        <OptionItem key={-2} value={-2}>Tempo para Conclusão </OptionItem>
                    </optgroup>
                    {fieldsAxisY.length > 0 && (
                        <optgroup label="Campos Customizáveis">
                            {fieldsAxisY.map((field) => {
                                return (
                                    <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                )
                            })}
                        </optgroup>
                    )}
                </SelectInput>

                {(elementSelected?.axis_y_field_id !== undefined && elementSelected.axis_y_field_id > 0) || elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Formato de cálculo
                        </LabelInput>
                        <SelectInput value={elementSelected.calc_function} onChange={(e) => onSelectCalcFunction(e.currentTarget.value)}>
                            <OptionItem key={1} value={"sum"}>Soma</OptionItem>
                            {elementSelected.axis_y_field_id !== -2 ?
                                <OptionItem key={2} value={"count"}>Contagem</OptionItem> :
                                <></>}
                            <OptionItem key={3} value={"avg"}>Média</OptionItem>
                            <OptionItem key={4} value={"min"}>Mín</OptionItem>
                            <OptionItem key={5} value={"max"}>Máx</OptionItem>
                        </SelectInput>

                    </> :
                    <></>
                }

                {elementSelected.axis_y_field_id === -2 ?
                    <>
                        <Divider />

                        <LabelInput>
                            Exibição do tempo
                        </LabelInput>
                        <SelectInput value={elementSelected.show_time} onChange={(e) => onSelectShowTime(e.currentTarget.value)}>
                            <OptionItem key={1} value={"day"}>Dias</OptionItem>
                            <OptionItem key={2} value={"hour"}>Horas</OptionItem>
                            <OptionItem key={3} value={"min"}>Minutos</OptionItem>
                            <OptionItem key={4} value={"sec"}>Segundos</OptionItem>
                        </SelectInput>
                    </> :
                    <></>
                }

            </ConfigSection>

            <ConfigSection name={"Mais configurações"} icon={TbSquarePlus}>

                <LabelInput>
                    Cor
                </LabelInput>
                <ColorPickerContainer>
                    <CirclePicker
                        color={elementSelected.color}
                        colors={colors}
                        onChangeComplete={(c) => onSelectColor(c.hex)}
                    />
                </ColorPickerContainer>
            </ConfigSection>
        </>
    );
};

const EmbedChartConfig: React.FC<ConfigSectionElementProps> = ({ elementSelected, onChangeEmbed }) => {
    return (
        <>
            <ConfigSection name={"Incorporação"} icon={FiCode}>
                <LabelInput>
                    Insira o HTML
                </LabelInput>
                <TextAreaInput
                    placeholder="Cole aqui o seu código HTML que deseja embedar"
                    rows={20}
                    value={elementSelected?.embed}
                    onChange={(e) => onChangeEmbed(e.currentTarget.value)}
                />

            </ConfigSection>
        </>
    );
};

export const getElementComponentConfig = (type: string) => {
    const relationElement = {
        [Elements.COLUMN_CHART]: ColumnChartConfig,
        [Elements.COLUMN_STACKED_CHART]: ColumnStackedChartConfig,
        [Elements.BAR_CHART]: BarChartConfig,
        [Elements.LINE_CHART]: LineChartConfig,
        [Elements.LINE_CHART_STACKED]: LineChartStackedConfig,
        [Elements.PIE_CHART]: PieChartConfig,
        [Elements.QUARTER_CHART]: PieChartConfig,
        [Elements.DONUT_CHART]: PieChartConfig,
        [Elements.FUNNEL_CHART]: FunnelChartConfig,
        [Elements.FUNNEL_CHART_VERTICAL]: FunnelChartConfig,
        [Elements.NUMBER_CHART]: NumberChartConfig,
        [Elements.SIMPLE_TABLE]: ColumnChartConfig, //Precisa criar um
        [Elements.EMBED]: EmbedChartConfig,
        [Elements.HEAT_MAP_CHART]: HeatMapChartConfig,
    };

    return relationElement[type];
};