import {
    Container,
    ContainerImage,
    ContainerSubTitle,
    ContainerTitle
} from "./style";

import React from "react";
import emptyElement from '../../../assets/empty-state/panel_empty_state.png';

interface emptyStateProps {

}

const EmptyElement: React.FC<emptyStateProps> = () => {

    return (
        <Container>
            <ContainerImage>
                <img src={emptyElement} alt="Sem Elementos" />
            </ContainerImage>
            <ContainerTitle>
                <h1>Adicione novos elementos para consolidar suas informações com gráficos e muito mais!</h1>
            </ContainerTitle>
            <ContainerSubTitle>
                <span>Clique em adicionar elemento para iniciar</span>
            </ContainerSubTitle>
        </Container>
    );

}

export default EmptyElement;