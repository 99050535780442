import { FieldProps } from "../components/Forms/Fields/FieldBuilder";
import { Card } from "../interfaces/Card";

export interface AggregateItemsProps {
    columnName: string;
    typeField: string;
    typeAggregate: string;
    variation?: string;
    value: number;
}

export default function getCardAggregations(
    fields_aggregate: FieldProps[],
    cards: Card[]
): AggregateItemsProps[] {

    let count: number = 0;
        let sum: number = 0;
        let type: string = "count";
        let newArr: AggregateItemsProps[] = [];

        const fieldsAgregate = fields_aggregate;

        //If have any aggregate in the column
        if (fieldsAgregate !== undefined && fieldsAgregate.length > 0) {
            for (let index = 0; index < fieldsAgregate.length; index++) {
                const fieldId: FieldProps = fieldsAgregate[index];

                type = fieldId.type === "CURRENCY_FIELD" || fieldId.type === "NUMBER_FIELD" || fieldId.type === "FORMULA_FIELD" ? "sum" : "count";

                //If have any card in the column
                if (cards !== undefined && cards.length > 0) {
                    for (let index = 0; index < cards.length; index++) {
                        const card = cards[index];

                        //If have any card in the column
                        if (card.form_answers !== undefined && card.form_answers.length > 0) {

                            for (let index = 0; index < card.form_answers.length; index++) {
                                const answer = card.form_answers[index];

                                //Valid if has more than one answer with the same form_id
                                let idAnswerToAggregate: number | undefined = undefined;
                                const answerWithSameFormId = card.form_answers
                                    .sort((a, b) => {
                                        const idA = a.id_form_answer;
                                        const idB = b.id_form_answer;
                                        return idA - idB;
                                    })
                                    .filter((a) => a.form_id === answer.form_id);
                                if (answerWithSameFormId.length > 1) {
                                    //Get the last one
                                    idAnswerToAggregate = answerWithSameFormId[answerWithSameFormId.length - 1].id_form_answer;
                                }

                                if (idAnswerToAggregate === undefined || idAnswerToAggregate === answer.id_form_answer) {
                                    const fieldSelected = answer.form_answer_fields.filter((answerField) => answerField.field_id === fieldId.id_field);

                                    if (fieldSelected !== undefined && fieldSelected.length > 0) {
                                        for (let index = 0; index < fieldSelected.length; index++) {
                                            if (fieldSelected[index] !== undefined) {
                                                if (type === "sum") { //Sum
                                                    sum = sum + Number(fieldSelected[0].value);
                                                } else { //Count
                                                    count = count + 1;
                                                }
                                            }
                                        }
                                    }
                                }

                            }
                        }

                    }
                }

                const aggregateItem: AggregateItemsProps = {
                    columnName: fieldId.title,
                    typeField: fieldId.type,
                    typeAggregate: type,
                    variation: fieldId.variation,
                    value: type === "sum" ? sum : count
                }

                newArr.push(aggregateItem);

                sum = 0;
                count = 0;

            }
        }

        return newArr;

}