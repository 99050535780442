import { Container, Title } from "./styles";
import React, { useEffect, useRef, useState } from "react";
import Select, { OptionTypeBase, Props as reacSelectProps } from "react-select";

import ErrorDescription from "../../../../ErrorDescription";
import { useField } from "@unform/core";

interface ComboOption {
  id_field_option?: number;
  field_id?: number;
  value: string;
  label: string;
  order?: string;
  hide?: string;
  old_id_field_option?: number;
}

interface Props extends reacSelectProps {
  name: string;
  options?: ComboOption[];
  description?: string;
  placeholder?: string;
  onForceBlur?: () => void;
}

const ComboBox: React.FC<Props> = ({
  name,
  options,
  description,
  placeholder,
  onForceBlur,
  ...rest
}) => {

  const reorderOptions = (options: ComboOption[]): ComboOption[] => {
    return options.sort((a, b) => {
      if (a.order !== undefined && b.order !== undefined) {
        return Number(a.order) - Number(b.order);
      } else {
        return 0;
      }
    });
  };

  const selectRef = useRef(null);
  const [opt, setOpt] = React.useState(options !== undefined && options.length > 0 ? reorderOptions(options) : options);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const optNewDefault = options?.filter((opt) => opt.value === defaultValue)[0];

  const [valueOption, setValueOption] = useState<ComboOption>(optNewDefault !== undefined ? optNewDefault : {
    value: "none",
    label: "Selecione aqui..."
  });

  placeholder = placeholder !== undefined && placeholder !== "" && placeholder !== null ? placeholder : "Selecione...";

  useEffect(() => {

    if (opt === undefined) {
      setOpt([
        {
          value: "none",
          label: "Selecione aqui..."
        }
      ]);
    }

  }, [opt]);

  useEffect(() => {

    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (valueOption === undefined || valueOption.value === "none") {
          return undefined;
        } else if (valueOption !== undefined && valueOption.value !== "none") {
          return valueOption.value;
        } else if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        } else if (!ref.state.value) {
          return "";
        } else if (ref.state.value.value !== "none") {
          return ref.state.value.value;
        }
      },
      setValue: (ref, value) => {
        if (options !== undefined) {
          setValueOption(options?.filter((opt) => opt.value === value)[0]);
        }
      },
      clearValue: (ref) => {
        return ref.select.clearValue();
      }
    });

  }, [fieldName, registerField, rest.isMulti, options, valueOption]);

  return (
    <>
      {description && <Title>{description}</Title>}
      <Container isErrored={!!error}>
        <Select
          name={name}
          className="combo-box-field"
          options={options === undefined ? opt : options.filter((opt) => opt.hide !== "S")}
          ref={selectRef}
          value={valueOption}
          onChange={(e) => {
            if (e !== undefined && e !== null) {
              setValueOption(e);
              if (onForceBlur !== undefined) {
                onForceBlur();
              }
            }
          }}
          //defaultValue={optNewDefault}
          classNamePrefix="react-select"
          placeholder={placeholder}
          {...rest}
        />
      </Container>
      {error && (<ErrorDescription title={error} />)}
    </>
  );
};
export default ComboBox;
