import styled, { css } from "styled-components";

interface ItemRegisterIconProps {
    color?: string;
}

interface ItemRegisterProps {
    color?: string;
}

interface ItemRegisterHeadProps {
    color?: string;
    selected: boolean;
}

interface ItemRegisterDueDateProps {
    color?: string;
    completed?: string;
}


export const ItemRegisterContainer = styled.div<ItemRegisterProps>`
    margin: 15px;
    border: 2px solid #9e37ed1e;
    border-radius: 12px;

    ${(props) => props.color && css`
        border: 2px solid ${props.color + "1e"};
    `}

    :hover {
        box-shadow: 0 0 6px rgb(0 0 0 / 20%);

        #remove-item {
            display: grid;
        }
    }
`;

export const ItemRegisterHead = styled.li<ItemRegisterHeadProps>`
    position: relative;
    width: 100%;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    min-height: 45px;
    
    ${(props) => props.color && css`
        color: black;
        font-weight: 500;
        background-color: ${props.color + "1e"};
        border-radius: 10px;
    `}
    
    ${(props) => props.selected && css`
            color: black;
            font-weight: 500;
            background-color: ${props.color + "1e"};
            border-radius: 10px;
    `}
`;


export const ItemRegisterIcon = styled.div<ItemRegisterIconProps>`
    border: 1px solid #f23b5c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    border-radius: 10px;
    background-color: #f23b5c;
    //margin-right: 10px;    
    min-height: 45px;
    min-width: 45px;

    ${(props) => props.color && css`
        border: 1px solid ${props.color};
        background-color: ${props.color};
    `}

    svg {
        color: white;
    }

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        font-size: 18px !important;
        
        svg {
            color: white;
        }
    }

    @media(max-width: 800px){
        display: none;
    }
`;

export const ItemRegisterDetailContainer = styled.div`
    margin-bottom: 5px;
`;

export const ItemRegisterDetail = styled.div`
    margin-left: 10px;
`;

export const ItemRegisterDetailHead = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;

    svg {
        min-width: 12px;
        font-size: 12px;
        font-weight: 600;
        color: #84939f;
    }

    h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 5px;
        font-size: 11px;
        color: #84939f;
        font-weight: 500;
        margin-top: 2px;
        text-transform: uppercase;
    }
`;

export const ItemRegisterDetailBody = styled.div`
  margin-left: 17px;
  font-weight: 500;
  font-size: 13px;
  color: #4d4d4d;
  overflow: hidden;
`;


export const ItemRegisterTitle = styled.div`
    margin-left: 15px;
    width: calc(100% - 85px);
    
    h1 {
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #292d34;
        width: calc(100% - 90px);
    }

    span {
        font-size: 12px;
        color: gray;
    }

    @media(max-width: 800px){
        h1 {
            width: calc(100% - 130px);
        }
    }

`;

export const ItemRegisterAvatar = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    font-size: 12px;
    font-weight: 500;

    margin-left: 5px;
    margin-right: 5px;

    position: absolute;
    right: 35px;

    span {
        color: white;
    }
`;

export const ItemRegisterDueDate = styled.div<ItemRegisterDueDateProps>`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    font-size: 12px;
    font-weight: 400;

    margin-left: 5px;
    margin-right: 5px;

    position: absolute;
    right: 75px;
    color: #292d34;

    span {
        color: red;
    }

    ${(props) => props.color && css`
        span {
            color: ${props.color};
        }
    `}

    ${(props) => props.completed === "S" && css`
        span {
            text-decoration: line-through;
            color: green;
        }
    `}
`;


export const ItemRegisterDueDatePocket = styled.div<ItemRegisterDueDateProps>`
    position: absolute;
    right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #292d34;
    
    font-size: 12px;
    font-weight: 400;

    margin-left: 5px;
    margin-right: 5px;

    span {
        color: red;
    }

    ${(props) => props.color && css`
        span {
            color: ${props.color};
        }
    `}

    ${(props) => props.completed === "S" && css`
        span {
            text-decoration: line-through;
            color: green;
        }
    `}
`;

export const ItemRegisterLink = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    font-size: 12px;
    font-weight: 400;

    margin-left: 5px;
    margin-right: 5px;

    position: absolute;
    right: 10px;

    span {
        color: red;
    }

    a {
        svg {
            font-size: 16px !important;
            margin-top: 3px;
        }
    }
`;


export const RegisterRemoveContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 50px;
    padding: 5px;
    right: -24px;
    top: -19px;
    position: absolute;
    margin-right: 5px;

    display: none;
`;

export const RegisterRemoveBox = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 50px;
    padding: 5px;

    svg {
        height: 15px;
        width: 15px;
        color: black;
    }

    background-color: #bcbcbc80;

    :hover {
        background-color: gray;
        
        svg {
            color: white;
        }
    }
`;
