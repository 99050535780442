import styled from "styled-components";

interface ContainerProps {
    width?: number;
    color?: string;
}

export const Container = styled.button<ContainerProps>`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 13px;
    height: 30px;

    ${props => props.width && `
        width: ${props.width}px;
    `}

    ${props => props.color && `
        background-color: ${props.color};      
    `}

    svg {
        margin-right: 5px;
        font-size: 15px;
    }

`;
