import styled, { css } from "styled-components";

interface TitleProps {
  font_size?: string;
  font_weight?: string;
  color?: string;
  justify_content?: string;
}

interface GroupItemsProps {
  color: string;
}

export const TitleContainer = styled.div<TitleProps>`
  font-size: 16px;
  font-weight: 500;
  color: black;
  margin-bottom: 5px;
  margin-left: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-left: 10px;
  }

  ${props => props.justify_content && css`
    justify-content: ${props.justify_content} !important;
  `}

  ${props => props.font_size && css`
    font-size: ${props.font_size} !important;
  `}

  ${props => props.font_weight && css`
    font-font-weight: ${props.font_weight} !important;
  `}

  ${props => props.color && css`
    color: ${props.color} !important;
  `}
`;

export const HelpText = styled.button`
  margin-left: 5px;
  display: inline-block;
  background-color: transparent;
  border: none;
  margin-top: 4px;
  font-size: 15px;

  svg {
    margin: 0;
  }
`;

export const TitleBox = styled.div`
  
`;

export const RequiredBox = styled.div`
  margin-right: 5px;
`;

export const GroupItems = styled.span<GroupItemsProps>`
    margin-left: 10px;
    font-size: 10px;
    margin-right: 5px;
    background-color: #f6ebfd;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 500;
    color: white;

    ${(props) =>
        props.color &&
        css`
      background-color: ${props.color};
    `}
    
`;
