import {
    Content,
    ContentBody,
    DialogContainer,
    DialogTop,
    DialogTopLeft,
    DialogTopRight,
    IconHeader,
    SpanField,
} from "./style";
import React, { useCallback, useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsLightningChargeFill } from "react-icons/bs";
import { useToast } from '../../hooks/toast';
import api from "../../services/api";
import { Flow } from "../../interfaces/Flow";
import { TiArrowLeftThick } from "react-icons/ti";
import { Conditional } from "../../interfaces/Conditional";
import WelcomePageStepAutomation from "./WelcomePageStepAutomation";
import AddConditionalStep from "./AddConditionalStep";
import ConditionalStep from "./ConditionalStep";
import { Step } from "../../interfaces/Step";
import { FaArrowRight } from "react-icons/fa";

interface AutomationStepProps {
    open: boolean;
    flow_id: number;
    flowStep: Step;
    flowStepParent: Step;
    onClose: (open: boolean) => void;
}

const AutomationStep: React.FC<AutomationStepProps> = ({ open, flow_id, flowStep, flowStepParent, onClose }) => {

    const { addToast } = useToast();

    const [typeAutomation, setTypeAutomation] = useState<number | undefined>(); // 1 - Conditional Step / 2 - Conditional List        

    const [flow, setFlow] = useState<Flow>();

    const [conditionals, setConditionals] = useState<Conditional[]>([]);
    const [selectedConditional, setSelectedConditional] = useState<Conditional>();

    const getConditionalObject = useCallback(async () => {

        api.get(`/conditional/by-step`, {
            params: {
                flow_step_id: flowStep.id_step,
                flow_step_parent_id: flowStepParent.id_step,
                flow_id: flow_id,
            }
        }).then(response => {
            if (response.data !== null) {
                let retConditionals: Conditional[] = response.data;
                setConditionals(retConditionals);

                if (retConditionals.length <= 0) {
                    setTypeAutomation(1);
                } else {
                    setTypeAutomation(2);
                }

            }
        }).catch(error => {
            console.log(error);
            addToast({
                type: 'error',
                title: 'Não foi possível carregar as regras condicionais',
                description: 'Ocorreu um erro ao carregar as regras condicionais!',
            });
        });

    }, [addToast, flow_id, flowStep, flowStepParent]);

    const getFlowObject = useCallback(async (flow_id?: number) => {

        //Get the main flow
        if (flow_id !== undefined) {
            api.get(`/flow/`, {
                params: {
                    id_flow: flow_id,
                }
            }).then(response => {
                if (response.data !== null) {
                    let retFlow: Flow = response.data;

                    setFlow(retFlow);
                }
            }).catch(error => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Não foi possível carregar o fluxo',
                    description: 'Ocorreu um erro ao carregar o registro!',
                });
            });
        }

    }, [addToast]);

    const handleClose = useCallback(async () => {
        onClose(false);
    }, [onClose]);

    const handleSelectTypeAutomation = useCallback(async (type: number) => {

        setSelectedConditional(undefined);

        if (type === 2 && (conditionals === undefined || conditionals.length <= 0)) {

            setTypeAutomation(1);

        } else if (type === 2) {

            setTypeAutomation(2);

        } else {
            setTypeAutomation(2);
        }

    }, [conditionals]);

    const handleBackToConditionals = useCallback(async () => {

        setTypeAutomation(2);
        getConditionalObject();

    }, [getConditionalObject]);

    const handleOpenConditional = useCallback(async (conditional: Conditional) => {

        setTypeAutomation(1);
        setSelectedConditional(conditional);

    }, []);

    const handleAddNewConditional = useCallback(async () => {

        setTypeAutomation(1);
        setSelectedConditional(undefined);

    }, []);

    useEffect(() => {

        getFlowObject(flow_id);

        getConditionalObject();

    }, [getConditionalObject, getFlowObject, flow_id]);

    return (
        <DialogContainer
            id="auto-complete-dialog"
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            style={{ zIndex: '999999' }}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={"#f23b5d"}>
                        {typeAutomation === undefined ?
                            <div>
                                <BsLightningChargeFill />
                            </div> :
                            <div
                                onClick={() => {

                                    if (typeAutomation === 1) { //Conditional

                                        setTypeAutomation(2);

                                    } else if (typeAutomation === 2) { //Conditional List

                                        setTypeAutomation(undefined);

                                    }
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <TiArrowLeftThick />
                            </div>
                        }
                    </IconHeader>
                    <h1>
                        {typeAutomation === 1 ? "Regra Condicional" : typeAutomation === 2 ? "Regras Condicionais" : "Automações de Etapa"}
                    </h1>
                    <SpanField>
                        {flowStepParent.name} <FaArrowRight /> {flowStep.name}
                    </SpanField>
                </DialogTopLeft>
                <DialogTopRight>
                    <button onClick={(e) => {
                        e.stopPropagation()
                        handleClose()
                    }}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>

            <Content style={{ padding: '0px' }}>
                <ContentBody>

                    {typeAutomation === undefined ?
                        <WelcomePageStepAutomation
                            setTypeAutomation={handleSelectTypeAutomation}
                            isConditionalActive={conditionals !== undefined && conditionals.length > 0}
                        /> : typeAutomation === 1 ?
                            <AddConditionalStep
                                flow={flow}
                                flowStep={flowStep}
                                flowStepParent={flowStepParent}
                                handleBackToConditionals={handleBackToConditionals}
                                handleCloseDialog={handleClose}
                                selectedConditional={selectedConditional}
                            /> : typeAutomation === 2 ?
                                <ConditionalStep
                                    conditionals={conditionals}
                                    handleAddNewConditional={handleAddNewConditional}
                                    handleOpenConditional={handleOpenConditional}
                                    flowStep={flowStep}
                                    flowStepParent={flowStepParent}
                                    flow_id={flow_id}
                                /> :
                                <></>
                    }

                </ContentBody>
            </Content>

        </DialogContainer >
    );

}

export default AutomationStep;