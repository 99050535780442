import React from "react";
import { IconBaseProps } from "react-icons";

import {
    Container
} from "./style";

interface MenuItemProps {
    title: string;
    icon: React.ComponentType<IconBaseProps>;
    color: string;
    onClick: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ title, icon: Icon, color, onClick }) => {

    return (
        <Container onClick={() => onClick()} color={color}>
            <Icon/>
            <h3>{title}</h3>
        </Container>
    );

}

export default MenuItem;