import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    Content,
    ContentBody,
    DialogContainer,
    DialogTop,
    DialogTopLeft,
    DialogTopCenter,
    DialogTopRight,
    DialogFooter,
    DialogFooterCenter,
    BtnInsertNewAnswer,
    ContainerForm,
    AutomationConfigItemImage,
    ContainerTypeAuth,
    ButtonTypeAuth,
    StatusContainer,
    StatusBox,
    IntegrationComboContainerTitle,
    IntegrationComboContainerDescription
} from "./style";

import { AiFillApi, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { FieldProps, Fields } from "../../components/Forms/Fields/FieldBuilder";
import { FormHandles } from "@unform/core";
import FormBuilder from "../../components/Forms/FormBuilder";
import Integration from "../../interfaces/Integration";
import IntegrationAuth from "../../interfaces/IntegrationAuth";
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from "react-icons/io";
import getConfigFields, { ConfigFieldAutomation } from "../Automations/NewAutomation/ConfigMenu/getConfigFields";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import IntegrationAuthCompany from "../../interfaces/IntegrationAuthCompany";
import { API_END_POINT } from "../../config/app";

const fieldsFormName: FieldProps[] = [
    {
        name: "name_integration",
        type: Fields.CUSTOM_SELECT_FIELD,
        variation: "1",
        index: 0,
        title: "Nome da integração",
        description: "Digite um nome para a integração",
        placeholder: "Digite aqui...",
        max_length: 70,
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome da integração é obrigatória!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    }
]

interface IntegrationAuthConfigProps {
    open: boolean;
    integration: Integration;
    onClose: (integration?: IntegrationAuthCompany) => void;
}

const IntegrationAuthConfig: React.FC<IntegrationAuthConfigProps> = ({ onClose, open, integration }) => {

    const { addToast } = useToast();

    const [integrationAuthCompany, setIntegrationAuthCompany] = useState<IntegrationAuthCompany>();

    //Form Name
    const formRefName = useRef<FormHandles>(null);
    const [defaultValueName, setDefaultValueName] = useState<object[]>();

    //Form
    const formRef = useRef<FormHandles>(null);
    const [defaultValue, setDefaultValue] = useState<object[]>();
    const [fieldsConfig, setFieldsConfig] = useState<FieldProps[]>([]);

    const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
    const [typeAuthSelected, setTypeAuthSelected] = useState<IntegrationAuth | undefined>(integration.integrations_auth !== undefined && integration.integrations_auth.length > 0 ? integration.integrations_auth[0] : undefined);

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [statusConnection, setStatusConnection] = useState<number>(1); //1 - not connected, 2 - autenticating on the third party, 3 - connected, 4 - error

    const loadAuthFields = useCallback(async (configFields: ConfigFieldAutomation) => {

        setLoading(true);

        let newFields = getConfigFields(configFields);

        setFieldsConfig(newFields);
        setLoading(false);

    }, []);

    const handleClose = useCallback(async (integrationAuthCompany?: IntegrationAuthCompany) => {
        if (integrationAuthCompany === undefined) {
            onClose();
        } else {
            onClose(integrationAuthCompany);
        }
    }, [onClose]);

    const handleValidateConnection = useCallback(async () => {
        if (typeAuthSelected && integrationAuthCompany !== undefined) {
            setLoadingStatus(true);

            try {
                await api
                    .get(`/integration/auth/${typeAuthSelected.provider}/validate`, {
                        params: {
                            id_integration_auth_company: integrationAuthCompany.id_integration_auth_company,
                        }
                    })
                    .then(response => {
                        if (response.data.connected) {
                            setStatusConnection(3);
                        } else {
                            setStatusConnection(4);
                            addToast({
                                type: 'error',
                                title: 'Conexão inválida',
                                description: response.data.message,
                            });
                        }

                    }).catch(error => {
                        setStatusConnection(4);
                        addToast({
                            type: 'error',
                            title: 'Erro ao iniciar processo de autenticação com o aplicativo',
                            description: 'Ocorreu um erro ao iniciar o processo de autenticação com o aplicativo!',
                        });
                    });

            } catch (error) {
                setStatusConnection(4);
                addToast({
                    type: 'error',
                    title: 'Erro',
                    description: error.response?.data?.message || 'Erro ao verificar a conexão.',
                });
            } finally {
                setLoadingStatus(false);
            }
        } else {
            setStatusConnection(1);
        }
    }, [typeAuthSelected, addToast, integrationAuthCompany]);

    const handleSubmitForm = useCallback(async (data: object[]) => {

        if (typeAuthSelected !== undefined) {

            // Open the auth window
            let authWindow: Window | null = null;

            if (typeAuthSelected.type === 'oAuth2') {
                authWindow = window.open('', '_blank');
            }

            try {
                setLoadingInsert(true);

                const integrationName = formRefName.current?.getFieldValue('name_integration');

                // Config the auth data
                const newData = {
                    name: integrationName !== undefined ? integrationName : "Integração com o " + integration.name,
                    auth_config: JSON.stringify(data)
                }

                let integrationAuthCompany: IntegrationAuthCompany;

                // Send the data to the endpoint
                await api
                    .post(`/integration/auth/${typeAuthSelected.provider}/configure`, newData)
                    .then(response => {

                        const respInt = response.data as IntegrationAuthCompany;

                        if (respInt !== undefined) {

                            setIntegrationAuthCompany(respInt);

                            integrationAuthCompany = respInt;

                            if (typeAuthSelected.type === 'oAuth2') {

                                // Start the auth process
                                api
                                    .get(`/integration/auth/${typeAuthSelected.provider}`, {
                                        params: {
                                            id_integration_auth_company: integrationAuthCompany.id_integration_auth_company,
                                            redirect_uri: window.location.href,
                                        }
                                    })
                                    .then(response => {

                                        // Redirect the user to the auth URL
                                        if (response.data && response.data.authorization_url) {
                                            if (authWindow) {
                                                authWindow.location.href = response.data.authorization_url;
                                            } else {
                                                window.location.href = response.data.authorization_url;
                                            }

                                            setStatusConnection(2);
                                        } else {
                                            addToast({
                                                type: 'error',
                                                title: 'Erro na autenticação',
                                                description: 'URL de autorização não fornecida pelo servidor.',
                                            });
                                        }

                                    }).catch(error => {
                                        addToast({
                                            type: 'error',
                                            title: 'Erro ao iniciar processo de autenticação com o aplicativo',
                                            description: 'Ocorreu um erro ao iniciar o processo de autenticação com o aplicativo!',
                                        });
                                    });

                            } else if (typeAuthSelected !== undefined) {

                                setStatusConnection(2);

                            }
                        }
                    }).catch(error => {
                        addToast({
                            type: 'error',
                            title: 'Erro ao salvar as preferências da integração',
                            description: 'Ocorreu um erro ao salvar as preferências da integração!',
                        });
                    });

            } catch (error) {
                addToast({
                    type: 'error',
                    title: 'Erro na configuração',
                    description: error.response?.data?.message || 'Ocorreu um erro ao configurar a integração.'
                });
            } finally {
                setLoadingInsert(false);
            }
        }
    }, [typeAuthSelected, addToast, integration.name]);

    const handleTypeAuthSelect = useCallback(
        (auth: IntegrationAuth) => {
            setTypeAuthSelected(auth);
            if (auth.config_fields) {
                loadAuthFields(JSON.parse(auth.config_fields));
            } else {
                setFieldsConfig([]);
            }
        },
        [loadAuthFields]
    );

    useEffect(() => {
        if (typeAuthSelected) {
            if (typeAuthSelected.config_fields) {
                loadAuthFields(JSON.parse(typeAuthSelected.config_fields));
            } else {
                setFieldsConfig([]);
            }
        }
    }, [typeAuthSelected, loadAuthFields]);

    useEffect(() => {

        if (typeAuthSelected !== undefined && !loading && fieldsConfig.length > 0) {
            if (typeAuthSelected.type !== undefined && typeAuthSelected.type === 'oAuth2') {
                if (typeAuthSelected.provider !== undefined) {
                    const callbackAddress = API_END_POINT + "/integration-callback/auth/" + typeAuthSelected.provider;

                    const newDefaultValue = {
                        "oauth_redirect": callbackAddress,
                    }

                    setDefaultValue(newDefaultValue as unknown as object[]);
                }
            }
        }

    }, [typeAuthSelected, loading, fieldsConfig]);

    useEffect(() => {

        if (integration !== undefined) {
            const newDefaultValueName = {
                "name_integration": "Integração com o " + integration.name
            }

            setDefaultValueName(newDefaultValueName as unknown as object[]);
        }

    }, [integration]);

    return (
        <DialogContainer
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={() => handleClose()}
        >
            <DialogTop>
                <DialogTopLeft>
                    <AutomationConfigItemImage size={50}>
                        <img src={integration.url_logo} alt={integration.name} />
                    </AutomationConfigItemImage>
                    <h1>
                        {"Configurar a integração com o " + integration.name}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={() => handleClose()}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>
            <Content>
                <ContentBody container>

                    <ContainerForm>
                        {!loading && fieldsConfig.length > 0 && (
                            <FormBuilder
                                id="formName"
                                formRef={formRefName}
                                fields={fieldsFormName}
                                initialValue={defaultValueName}
                                hideContainer={true}
                            />
                        )}
                    </ContainerForm>

                    <ContainerForm>
                        <IntegrationComboContainerTitle>
                            Formato de autenticação com o aplicativo
                        </IntegrationComboContainerTitle>
                        <IntegrationComboContainerDescription>
                            Configure a autenticação da integração com o aplicativo
                        </IntegrationComboContainerDescription>
                        <ContainerTypeAuth>
                            {integration.integrations_auth.map((auth, index) => {
                                return (
                                    <ButtonTypeAuth
                                        key={index}
                                        onClick={() => handleTypeAuthSelect(auth)}
                                        selected={typeAuthSelected?.id_integration_auth === auth.id_integration_auth}
                                    >
                                        {typeAuthSelected?.id_integration_auth === auth.id_integration_auth ?
                                            <IoMdRadioButtonOn color="green" /> :
                                            <IoMdRadioButtonOff color="gray" />
                                        }

                                        {auth.name}
                                    </ButtonTypeAuth>
                                )
                            })}
                        </ContainerTypeAuth>
                    </ContainerForm>

                    <ContainerForm>
                        {!loading && fieldsConfig.length > 0 && (
                            <FormBuilder
                                id="form"
                                formRef={formRef}
                                fields={fieldsConfig}
                                initialValue={defaultValue}
                                handleSubmit={handleSubmitForm}
                                hideContainer={true}
                            />
                        )}
                    </ContainerForm>

                </ContentBody>
            </Content>
            <DialogFooter>
                <DialogFooterCenter>
                    <StatusContainer>
                        {statusConnection === 1 && (
                            <StatusBox color="#808080">
                                <AiOutlineClose />
                                <span>Não conectado</span>
                            </StatusBox>
                        )}
                        {statusConnection === 2 && (
                            <StatusBox color="#ffa500">
                                <AiFillApi />
                                <span>Aguardando autorização</span>
                            </StatusBox>
                        )}
                        {statusConnection === 3 && (
                            <StatusBox color="#008000">
                                <AiOutlineCheck />
                                <span>Conectado com sucesso</span>
                            </StatusBox>
                        )}
                        {statusConnection === 4 && (
                            <StatusBox color="#ff0000">
                                <AiOutlineClose />
                                <span>Erro na conexão</span>
                            </StatusBox>
                        )}
                    </StatusContainer>
                    {statusConnection === 1 && (
                        <BtnInsertNewAnswer
                            color={"#9337ed"}
                            style={{ width: '170px', height: '36px' }}
                            onClick={() => formRef.current?.submitForm()}
                            isLoading={loadingInsert}
                        >
                            {loadingInsert ? 'Conectando...' : 'Conectar'}
                        </BtnInsertNewAnswer>
                    )}

                    {(statusConnection === 2 || statusConnection === 4) && (
                        <BtnInsertNewAnswer
                            color={"#9337ed"}
                            style={{ width: '170px', height: '36px' }}
                            onClick={handleValidateConnection}
                            isLoading={loadingStatus}
                        >
                            Verificar conexão
                        </BtnInsertNewAnswer>
                    )}

                    {statusConnection === 3 && (
                        <BtnInsertNewAnswer
                            color={"gray"}
                            style={{ width: '170px', height: '36px' }}
                            onClick={() => handleClose(integrationAuthCompany)}
                            isLoading={loadingStatus}
                        >
                            Fechar
                        </BtnInsertNewAnswer>
                    )}
                </DialogFooterCenter>
            </DialogFooter>
        </DialogContainer >
    );

}

export default IntegrationAuthConfig;