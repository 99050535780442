import { Container, TextInput } from "./styles";
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";

import ErrorDescription from "../../../../ErrorDescription";
import { IconBaseProps } from "react-icons";
import { useField } from "@unform/core";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  description?: string;
  placeholder?: string;
  rowMax?: number;
}

const TextAreaInput: React.FC<InputProps> = ({
  name,
  description,
  icon: Icon,
  disabled,
  placeholder,
  rowMax,
  ...rest
}) => {

  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const [valueText, setValueText] = useState<string>(defaultValue !== undefined ? defaultValue : "");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputFocused = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const maxRow = !!rowMax ? rowMax : 8;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => {
        return valueText;
      },
      setValue: (refs, value) => { //Não remover o ref                
        if (value !== null) {
          setValueText(value);
        } else {
          setValueText("");
        }
      },
      clearValue: () => {
        setValueText("");
      }
    });

  }, [fieldName, registerField, valueText]);

  return (
    <>
      <Container
        isErrored={!!error}
        isFocused={isFocused}
        isDisabled={!!disabled}
      >
        {Icon && <Icon size="22" />}
        <TextInput
          onFocus={handleInputFocused}
          onBlur={handleInputBlur}
          inputRef={inputRef}
          onChange={(e) => setValueText(e.currentTarget.value)}
          //value={valueText}
          name={name}
          placeholder={placeholder}
          multiline
          maxRows={maxRow}
          error={false}
          defaultValue={defaultValue}
        ></TextInput>
      </Container>

      {error && (<ErrorDescription title={error} />)}
    </>
  );
};

export default TextAreaInput;
