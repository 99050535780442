export default function getOS(window: Window) {

    let currentSO = 'Unknown OS';

    if (window.navigator.userAgent.indexOf("Win") !== -1) { currentSO = "Windows OS"; }
    else if (window.navigator.userAgent.indexOf("Mac") !== -1) { currentSO = "Macintosh"; }
    else if (window.navigator.userAgent.indexOf("Linux") !== -1) { currentSO = "Linux OS"; }
    else if (window.navigator.userAgent.indexOf("Android") !== -1) { currentSO = "Android OS"; }
    else if (window.navigator.userAgent.indexOf("like Mac") !== -1) { currentSO = "iOS"; }
    else { console.log('Others'); }

    return currentSO;
}