import { ButtonNew, Container } from "./styles";
import InputItem, { InputItemProps, inputItemLabelProps } from "../../../../InputItem";
import React, { useCallback, useEffect, useRef, useState } from "react";

import ErrorDescription from "../../../../ErrorDescription";
import { useField } from "@unform/core";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import DialogAction from "../../../../../dialogs/DialogAction";

interface ComboOption {
    id_field_option?: number;
    field_id?: number;
    value: string;
    label: string;
    order: string;
    hide?: string;
    old_id_field_option?: number;
}

interface InputItemListProps {
    name: string;
    options?: ComboOption[];
    description?: string;
    placeholder?: string;
    max_length?: number;
}

const DraggableInputItem: React.FC<InputItemProps & { index: number }> = ({ item, index, setItem, handleRemoveItem, max_length, inputRefs }) => {
    return (
        <Draggable draggableId={index.toString()} index={index}>
            {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <InputItem
                        item={item}
                        index={index}
                        setItem={setItem}
                        handleRemoveItem={handleRemoveItem}
                        inputRefs={inputRefs}
                        max_length={max_length}
                    />
                </div>
            )}
        </Draggable>
    );
};

const InputItemList: React.FC<InputItemListProps> = ({ name, max_length }) => {

    const inputRefs = useRef<HTMLInputElement[]>([]);
    const { fieldName, registerField, error, defaultValue } = useField(name);
    const [startItem] = useState({ value: "1", label: "", order: "0" });

    const [items, setItems] = useState<inputItemLabelProps[]>([startItem]);
    const [stateDelItem, setStateDelItem] = useState<{ open: boolean, index: string | undefined }>({ open: false, index: undefined });

    const getNextValue = (items: inputItemLabelProps[]): string => {

        //Create an array with all existing values
        const existingValues = items.map((item) => item.value);

        //Generate a new value
        let newValue = 1;
        while (existingValues.includes(newValue.toString())) {
            newValue++;
        }

        return newValue.toString();
    };

    const setItem = useCallback(async (idx: number, newItem: inputItemLabelProps) => {
        setItems((items) => {
            const newItems = [...items];
            newItems[idx] = newItem;
            return newItems;
        });
    }, []);

    const addNew = useCallback(async () => {

        //Update values
        const itemsBase = items !== undefined ? [...items] : [];
        setItems([]);
        itemsBase.map((item, index) => {
            setItems((items) => {
                return [...items, {
                    id_field_option: item.id_field_option,
                    field_id: item.field_id,
                    value: item.value,
                    label: item.label,
                    order: item.order,
                    hide: item.hide,
                    old_id_field_option: item.old_id_field_option
                }]
            })
            return "";
        });

        // Somente insere se o último não estiver em branco
        if (items !== undefined && items.length > 0) {
            if (items[items.length - 1].label !== "") {

                const nextOrder = getNextValue(items);

                setItems((items) => {
                    return [...items, { value: nextOrder + "", label: "", order: nextOrder }]
                });
            }
        } else {
            setItems([{ value: (1) + "", label: "", order: "0" }])
        }

    }, [items]);

    const handleRemoveItem = useCallback(async (index?: string) => {

        if (index !== undefined) {
            //Remove o index do parâmetro
            const itemsFiltered = items.filter(item => item.value !== index);

            //Se for deletado todos os items
            if (itemsFiltered.length === 0) {
                setItems([startItem]);
            } else {
                setItems(itemsFiltered);

                //Update values
                const itemsBase = itemsFiltered;
                setItems([]);
                itemsBase.map((item, index) => {
                    setItems((items) => {
                        return [...items, {
                            id_field_option: item.id_field_option,
                            field_id: item.field_id,
                            value: item.value,
                            label: item.label,
                            order: item.order,
                            hide: item.hide,
                            old_id_field_option: item.old_id_field_option
                        }]
                    });
                    return "";
                });
            }
        }

    }, [startItem, items]);

    const onDragEnd = (result: DropResult) => {
        //If was dragged to outside the list
        if (!result.destination) {
            return;
        }

        const reorderedItems = Array.from(items);
        const [draggedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, draggedItem);

        //Update values of items
        const updatedItems = reorderedItems.map((item, index) => ({
            ...item,
            order: (index).toString(),
        }));

        setItems(updatedItems);
    };

    const setDeleteItem = useCallback(async (index: string, id?: number) => {

        if (id !== undefined && id > 0) {
            setStateDelItem({ open: true, index: index });
        } else {
            handleRemoveItem(index);
        }

    }, [handleRemoveItem]);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRefs.current,
            getValue: () => {
                if (items !== undefined) {
                    let retItems = items.filter(item => item.label !== "");

                    if (retItems.length <= 0) {
                        return [];
                    } else {

                        //Order the array by order
                        retItems.sort((a, b) => {
                            if (a.order !== undefined && b.order !== undefined) {
                                return Number(a.order) - Number(b.order);
                            } else {
                                return 0;
                            }
                        });

                        return retItems;
                    }
                }
            },
            setValue: (refs, value) => {
                if (value !== undefined && value !== null) {

                    let newValue: inputItemLabelProps[] = value;

                    //Order the array by order
                    newValue.sort((a, b) => {
                        if (a.order !== undefined && b.order !== undefined) {
                            return Number(a.order) - Number(b.order);
                        } else {
                            return 0;
                        }
                    });

                    setItems(newValue);
                }
            },
            clearValue: () => {
                setItems([startItem]);
            }
        });
    }, [items, fieldName, registerField, setItems, startItem, defaultValue]);

    useEffect(() => {

        if (typeof defaultValue === "string") {

            const element = defaultValue as unknown as string;

            setItems([{ value: String(1), label: element, order: "0" }]);

        } else if (typeof defaultValue === "object") {

            const arrDefault = defaultValue as unknown as inputItemLabelProps[];

            let newArr: inputItemLabelProps[] = [];

            for (let index = 0; index < arrDefault.length; index++) {
                const element = arrDefault[index];

                if (element.label !== undefined) {

                    newArr.push({
                        id_field_option: element.id_field_option,
                        field_id: element.field_id,
                        value: element.value,
                        label: element.label,
                        order: element.order,
                        hide: element.hide,
                        old_id_field_option: element.old_id_field_option
                    });

                } else {

                    const element = arrDefault[index] as unknown as string;

                    newArr.push({
                        value: String(index + 1),
                        label: element,
                        order: "0"
                    });

                }

            }

            //Order the array by order
            newArr.sort((a, b) => {
                if (a.order !== undefined && b.order !== undefined) {
                    return Number(a.order) - Number(b.order);
                } else {
                    return 0;
                }
            });

            setItems(newArr);

        }

    }, [defaultValue]);

    useEffect(() => {

        if (items === undefined) {
            setItems([startItem]);
        }

    }, [items, startItem])

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="inputItemList">
                {(provided) => (
                    <Container {...provided.droppableProps} ref={provided.innerRef}>
                        <div>
                            {items !== undefined ? items.map((item, i) => (
                                <DraggableInputItem
                                    key={i}
                                    item={item}
                                    index={i}
                                    setItem={setItem.bind(0, i)}
                                    handleRemoveItem={() => setDeleteItem(item.value, item.id_field_option)}
                                    inputRefs={inputRefs}
                                    max_length={max_length}
                                />
                            )) :
                                <></>
                            }

                            {error && (<ErrorDescription title={error} />)}
                            <ButtonNew type="button" onClick={addNew}>Adicionar item</ButtonNew>
                        </div>
                        {provided.placeholder}
                    </Container>
                )}
            </Droppable>
            <DialogAction
                open={stateDelItem.open}
                message={"Você tem certeza que deseja excluir este item?"}
                actions_items={[
                    "Exclusão do item selecionado;",
                    "Exclusão de todas as respostas de formulário que este item possui;"
                ]}
                disclaimer={"Lembrando que esta ação não poderá ser revertida e você perderá todos os dados deste item"}
                onClose={() => setStateDelItem({ open: false, index: undefined })}
                onSubmmit={() => handleRemoveItem(stateDelItem.index)}
                type={1}
            />
        </DragDropContext>
    );

}

export default InputItemList;