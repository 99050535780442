import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import { useField } from "@unform/core";
import 'react-quill/dist/quill.snow.css';
import ErrorDescription from "../../../../ErrorDescription";

import {
    Container
} from "./style";

interface InputRichTextProps {
    name: string;
    placeholder?: string;
}

const InputRichText: React.FC<InputRichTextProps> = ({ name, placeholder }) => {

    const { fieldName, defaultValue, error, registerField } = useField(name);
    const inputRef = useRef<HTMLInputElement>(null);

    const [valueText, setValueText] = useState(defaultValue !== undefined && defaultValue !== null ? defaultValue : '');

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: () => {
                return valueText;
            },
            setValue: (refs, value) => { //Não remover o ref
                if (value !== undefined && value !== null) {
                    setValueText(value);
                } else {
                    setValueText("");
                }
            },
            clearValue: () => {
                setValueText("");
            }
        });

    }, [fieldName, registerField, valueText]);

    return (
        <>
            <Container>
                <ReactQuill
                    theme="snow"
                    value={valueText}
                    onChange={setValueText}
                    placeholder={placeholder !== undefined ? placeholder : "Digite aqui..."}
                />
            </Container>
            {error && (<ErrorDescription title={error} />)}
        </>
    );

}

export default InputRichText;