import styled from "styled-components";

export const OverlayContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
`;

export const OverlayIcon = styled.div`
    position: absolute;
    top: -20px;
    right: calc(50% + -13px);
    background-color: #ff8c2e;
    color: white;
    border-radius: 50%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;    
    z-index: 1;
`;

export const OverlayContent = styled.div<{ maxWidth?: number }>`
    position: relative;
    text-align: center;
    background-color: rgb(36 36 37 / 0%);
    padding: 20px;
    border-radius: 5px;
    color: black;
    border: 1px solid #ff8c2e;
    max-width: 370px;

    h3 {
        margin-bottom: 15px;
        font-size: 20px;
        color: #000000c4;
        margin-top: 5px;
    }

    p {
        margin-bottom: 15px;
        font-size: 13px;
        color: #000000c4;
    }

    button {
        background-color: #ff8c2e;
        border: none;
        padding: 7px 15px;
        color: white;
        font-size: 13px;
        cursor: pointer;
        border-radius: 5px;
        font-weight: 500;
    }

    ${({ maxWidth }) => maxWidth && `
        max-width: ${maxWidth}px;
    `}
`;