import React, { useState } from "react";
import { BiDotsVertical } from "react-icons/bi";
import { ClickAwayListener } from "@material-ui/core";

import {
    BoxOver,
    ColumnsHeaderConfig,
    Container
} from "./style";

interface MenuProps {
    color?: string;
    children?: React.ReactNode;
}

const Menu: React.FC<MenuProps> = ({ color, children }) => {

    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const handleClickAway = () => {
        setMenuOpen(false);
    };

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <BoxOver>
                    <ColumnsHeaderConfig
                        className="col-hed-conf"
                        color={color !== undefined ? color : "#9e37ed"}
                        onClick={() => setMenuOpen(!menuOpen)}
                        isOpen={menuOpen}
                    >
                        <BiDotsVertical />
                    </ColumnsHeaderConfig>
                    {menuOpen ? (
                        <Container className="col-cont-conf">
                            {children}
                        </Container>
                    ) : (<></>)}
                </BoxOver>
            </ClickAwayListener>
        </>
    );

}

export default Menu;