import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-left: 5px;
`;

export const ComboContainer = styled.div`
    width: 50%;
    margin-right: 10px;
`;

export const ComboTypeField = styled.select`
    height: 40px;
    border-radius: 5px;
    border-color: #808080bf;
    font-size: 14px;
    padding: 5px;
    color: black;
    width: 100%;
`;

export const OptionField = styled.option`

`;

export const NumberDaysContainer = styled.div`
    background-color: transparent;
    border: 0px;
    padding: 16px;
    width: 50%;
    margin-left: 10px;

    border-bottom: 1px solid gray;
    color: black;

    display: flex;
    align-items: center;

    & + div {
        margin-top: 30px;
    }

    input {
        flex: 1;
        background: transparent;
        border: 0;
            
        ::placeholder {
            color: gray;
        }

        
    }

    svg {
        margin-right: 16px;
        color: gray;
    }

    title {
        color: red;
        text-size-adjust: 10px;
    }
`;

export const NumberDaysInput = styled.input`

`;

export const ContainerBusinessDays = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: black;
    margin-top: 10px;

    .MuiSwitch-root {
        margin-top: 2px;
    }
`;

export const ItemCB = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  position: relative;
  box-sizing: border-box;
`;

export const LabelCB = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  background: #eef2f8;
`;

export const SpanCB = styled.label`
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: black;
  width: 100%;
  padding-left: 10px;
  padding-top: 5px;
`;

export const InputCB = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 10px;

  &:hover ~ ${LabelCB} {
    background: #e5e7ea;
    &::after {
      content: "";
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
  &:checked + ${ItemCB} {
    background: #eef2f8;
    border: 2px solid transparent;
  }

  &:checked + ${LabelCB} {
    background: #eef2f8;
    padding: 5px;
    border: 2px solid #f23b5c;
    &::after {
      content: "";
      display: block;
      background-color: #f23b5c;
      margin-left: -1px;
      margin-top: -1px;
      width: 12px;
      height: 12px;
    }
  }
`;


