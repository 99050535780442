import React from 'react';
import { IconBaseProps } from "react-icons";

import { Content, DisclaimerPreview } from "./style";

interface EmptyContainerProps {
    message: string;
    Icon: React.ComponentType<IconBaseProps>
    children?: React.ReactNode;
}

const EmptyContainer: React.FC<EmptyContainerProps> = ({ children, message, Icon }) => {

    return (
        <Content>
            <DisclaimerPreview>
                <Icon />
                <h1>{message}</h1>
            </DisclaimerPreview>
            { children }
        </Content>
    );
};

export default EmptyContainer;