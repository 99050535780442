import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import styled, { css } from "styled-components";

import Button from '../../components/Button';

interface IconHeaderProps {
    color: string;
}

interface CardHeaderLeftProps {
    color: string;
}

interface CardProps {
    color: string;
}

export const Content = styled(DialogContent)`
    background-color: #f4f5f7;   
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: center;
    height: auto;
    width: auto;
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const DialogTop = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #eef2f8;
    margin-top: 15px;
    width: 100%;
    
`;

export const DialogTopLeft = styled.div`
    width: 65%;
    height: 100%;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: start;
    margin-left: 15px;

    h1 {
        margin-left: 20px;
        font-size: 20px;
        font-weight: 500;
        color: black;
    }
`;

export const DialogTopCenter = styled.div`
    width: 25%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
`;

export const DialogTopRight = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 30px;
        border: none;
        background-color: transparent;
    }
`;

export const IconHeader = styled.span<IconHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 100%;
    background-color: transparent;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 45px;
        height: 45px;
        background-color: #f4f4f4;
        border-radius: 50%;

        > svg {
            width: 25px;
            height: 25px;

            ${props => props.color && css`
                color: ${props.color} !important;
            `}
        }

    }
`;

export const BtnEditForm = styled.button`

    display: flex;
    margin-left: 0px !important;
    width: 100% !important;
    background-color: #f23b5c1a !important;
    border-radius: 10px;
    padding: 5px;
    justify-content: center;
    align-content: center;
    border: none;
    justify-items: center;
    align-items: center;

    svg {
      font-size: 11px;
      margin-right: 10px;
      margin-left: 5px;
    }

    h3 {
      font-size: 12px;
      font-weight: 500;
      margin-right: 5px;
    }

`;

export const ContainerItems = styled.div`
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    background-color: white;
    margin-left: 15px;
    margin-right: 15px;

    form {
        padding: 0px;

        div {
            margin-bottom: 0px;
        }
    }
`;

export const DialogFooter = styled.div`
  border-top: 1px solid #eef2f8;
  padding: 15px !important;
  width: 100%;
`;

export const DialogFooterCenter = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
`;

export const BtnInsertNewAnswer = styled(Button)`
    height: 50px;
    width: 95%;
    background-color: #f23b5c;
    color: white;
    border-radius: 5px;
    border: 0;
    font-weight: 500;
`;

export const HelpContainer = styled.div`
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #4680B81e;
    width: 100%;
`;

export const HelpItems = styled.div`
    padding: 15px;
    padding-bottom: 5px;
    width: 100%;
    height: 100%;
`;
export const HelpItem = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    align-items: stretch;
`;
export const HelpIcon = styled.div`
    width: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    svg {
        font-size: 25px;
        color: #4680B8;
    }
`;
export const HelpText = styled.div`
    width: 85%;
    font-size: 13px;
`;

export const Card = styled.div<CardProps>`
    border: 1px solid #eef2f8;
    border-radius: 10px;
    background-color: white;
    padding: 10px;
    margin-top: 15px;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 5px;

    ${props => props.color && css`
        border: 1px solid ${props.color}; 
    `}
`;

export const CardHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;


export const CardHeaderLeft = styled.div<CardHeaderLeftProps>`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    min-width: 70px;
    max-width: 120px !important;
    min-height: 50px;
    border: 1px solid #4d4d4d; 
    background-color: #4d4d4d1a;
    border-radius: 5px;

    div {
        
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            color: #4d4d4d;
            width: 23px;
            height: 23px;
        }
    }

    ${props => props.color && css`
        border: 1px solid ${props.color}; 
        background-color:${props.color + "1a"};

        div {
            svg {
                color: ${props.color};
            }
        }
    `}
`;

export const FormContainerButtons = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-items: flex-end;
    margin: 25px 15px 0px 0px;
    
    div {
        button {
            width: 150px;
        }
    }
`;



