import * as Yup from 'yup';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiMail } from "react-icons/fi";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

import Button from '../../components/Button';
import Input from '../../components/Forms/Fields/Components/Input';
import LoaderCange from '../Loader/LoaderCange';
import api from '../../services/api';
import emailCheck from '../../assets/email-check.png';
import getValidationErrors from '../../utils/getValidationErrors';
import logoWendata from '../../assets/wendata-logo-white.png';
import { AnimationContainer, Container, ContainerSucess, Content, NoAccount } from './styles';
import { useToast } from '../../hooks/toast';

interface RecoveryFormData {
    email: string;
}

interface RouteParams {
    email?: string;
}

const RecoveryPassword: React.FC = () => {

    const formRef = useRef<FormHandles>(null);
    const { params } = useRouteMatch<RouteParams>();
    const { addToast } = useToast();
    const [loading, setLoading] = useState<boolean>(false);
    const [sucess, setSucess] = useState<boolean>(false);
    const [email, setEmail] = useState<string>();

    const handleSubmit = useCallback(async (data: RecoveryFormData) => {

        try {

            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                email: Yup.string()
                    .required('O campo de e-mail é obrigatório!')
                    .email('O e-mail inserido é inválido!')
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            setLoading(true);

            //Recovery api
            await api
                .post('/session/recovery', {
                    email: data.email
                })
                .then(response => {

                    setSucess(true);
                    setEmail(data.email);

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao recuperar a senha!',
                        description: 'Verifique o e-mail digitado, caso não tenha sucesso, entre em contato com o suporte!',
                    });
                });

            setLoading(false);
        } catch (err) {
            setLoading(false);
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);
                formRef.current?.setErrors(errors);
            }

            console.log(err);

            addToast({
                type: 'error',
                title: 'Erro no reconhecimento do e-mail',
                description: 'Ocorreu um erro ao encontrar o seu e-mail, verifique o e-mail digitado!',
            });
        }

    }, [addToast]);

    useEffect(() => {

        if (formRef.current !== null) {
            formRef.current.setData({
                email: params.email
            });
        }

    }, [params.email]);

    return (
        <>
            <title>Recuperar Senha | Cange</title>
            {loading ? <LoaderCange /> :
                <Container>

                    <img src={logoWendata} alt="wendata" />
                    <Content>

                        <AnimationContainer>
                            {!sucess ?
                                <Form ref={formRef} onSubmit={handleSubmit}>

                                    <h1>Esqueceu a sua senha?</h1>
                                    <h3>Fique tranquilo! Digite seu e-mail abaixo que enviaremos instruções para você recuperar sua senha &#x1F600;</h3>

                                    <Input tabIndex={0} name="email" icon={FiMail} placeholder="Sua conta de e-mail" />

                                    <Button tabIndex={0} type="submit" height="56px">Receber instruções</Button>

                                    <NoAccount tabIndex={-1}>Quer voltar para o Login?
                                        <Link to="/" style={{ color: '#14aaf5', paddingLeft: '5px' }}>
                                            Clique aqui!
                                        </Link>
                                    </NoAccount>

                                </Form> :
                                <ContainerSucess>

                                    <h1>Instruções enviadas! &#x270C;</h1>

                                    <img src={emailCheck} alt="wendata" />

                                    <h3>As suas instruções para redefinir a senha foram enviadas para o e-mail <b>{email}</b>.</h3>

                                    <h3>Este e-mail será enviado em até 5 minutos. Não se esqueça de checar a sua pasta spam. &#x1F609;</h3>

                                    <NoAccount tabIndex={-1}>Quer voltar para o Login?
                                        <Link to="/" style={{ color: '#14aaf5', paddingLeft: '5px' }}>
                                            Clique aqui!
                                        </Link>
                                    </NoAccount>

                                </ContainerSucess>
                            }
                        </AnimationContainer>
                    </Content>
                </Container>
            }
        </>
    );
}

export default RecoveryPassword;