import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //margin: 5px;
    //margin-top: 20px;
    overflow-x: auto;
    padding: 5px;
    height: 350px;
`;

export const ContainerTemplate = styled.div`
    margin-right: 15px;
    max-width: 370px;
    min-width: 370px;
    max-height: 290px;
    min-height: 270px;
`;
