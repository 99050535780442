import styled from "styled-components";
import Button from "../Button";
import FieldBoxOrg from "../FieldBox";

export const Container = styled.div`

`;

export const ContainerButtonNewFormField = styled.div`
    display: flex;
    //margin-top: 15px;
    margin-bottom: 15px;
`;

export const ButtonNewFormField = styled(Button)`
    float: left;
    width: 180px;
    font-size: 14px;
`;

export const ContainerFieldBox = styled.div`
    margin-bottom: 12px;
    height: 55px;
`;

export const FieldBox = styled(FieldBoxOrg)`
    min-height: 50px !important;
    height: 50px !important;
    z-index: 1;
`;