import React from "react";

import emptyTask from '../../../assets/empty-state/empty-step.png';
import {
    Container,
    ContainerImage,
    ContainerSubTitle,
    ContainerTitle
} from "./style";

interface emptyStateProps {

}

const EmptyStep: React.FC<emptyStateProps> = () => {

    return (
        <Container>
            <ContainerImage>
                <img src={emptyTask} alt="Sem Tarefas" />
            </ContainerImage>
            <ContainerTitle>
                <h1>Este fluxo ainda não possui etapas</h1>
            </ContainerTitle>
            <ContainerSubTitle>
                <span>Clique aqui, para adicionar uma nova etapa ou vá nas configurações do fluxo</span>
            </ContainerSubTitle>
        </Container>
    );

}

export default EmptyStep;