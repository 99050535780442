import React, { useCallback, useEffect, useState } from 'react';
import { IconList, IconPickerItem } from 'react-fa-icon-picker'
import { IoMdListBox } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

import DegradeLine from '../../../components/DegradeLine';
import api from '../../../services/api';
import logo from '../../../assets/wendata-logo.png';
import {
    AnimationContainer,
    Body,
    Bottom,
    BottomLeft,
    BottomRight,
    BtnStart,
    Container,
    ContainerGroup,
    Content,
    ContentList,
    GroupTag,
    GroupTagContainer,
    GroupTagIcon,
    GroupTagName,
    Header,
    HeaderLeft,
    HeaderRight,
    ProgressBar,
    ProgressBarPercent
} from './styles';
import { Onboarding } from '../../../interfaces/Onboarding';
import { TemplateGroup } from '../../../interfaces/TemplateGroup';
import { useToast } from '../../../hooks/toast';
import ContentLoader from 'react-content-loader';
import { useMediaQuery } from 'react-responsive';

interface RouteParams {
    hash?: string;
}

const LoaderCard = () => (
    <ContentLoader
        speed={2}
        width={140}
        height={51}
        viewBox="0 0 140 51"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ margin: '3px' }}
    >
        <rect x="0" y="0" rx="15" ry="15" width="140" height="51" />
    </ContentLoader>


)

const UseDataGroup: React.FC = () => {

    const { params } = useRouteMatch<RouteParams>();
    const { addToast } = useToast();
    const history = useHistory();
    const [onboarding, setOnboarding] = useState<Onboarding>();
    const [templateGroups, setTemplateGroups] = useState<TemplateGroup[]>();
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateGroup>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingItems, setLoadingItems] = useState<boolean>(false);
    const [loadingItemsCount, setLoadingItemsCount] = useState<number[]>();
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    const getRandomLoadingItems = useCallback(async () => {

        const random = 17;
        let loadingItemsNew: number[] = [];
        for (let index = 0; index < random; index++) {
            loadingItemsNew.push(index);
        }

        setLoadingItemsCount(loadingItemsNew);

    }, []);

    const handleSubmitPage = useCallback(async () => {

        if (selectedTemplate !== undefined && selectedTemplate.id_template_group > 0) {

            setLoading(true);
            await api
                .post('/onboarding/', {
                    status: "4",
                    email: onboarding?.email,
                    hash: params.hash,
                    template_group_id: selectedTemplate.id_template_group
                })
                .then(response => {

                    setLoading(false);
                    history.push('/onboarding-step-5/' + params.hash);

                }).catch(error => {
                    setLoading(false);
                    console.log(error);
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar os dados!',
                        description: 'Ocorreu um erro ao tentar salvar os dados na base!',
                    });
                });

        } else {
            addToast({
                type: 'error',
                title: 'Não foi selecionada nenhuma resposta :(',
                description: 'Selecione alguma das respostas acima!',
            });
        }

    }, [addToast, selectedTemplate, onboarding, params.hash, history]);

    useEffect(() => {

        getRandomLoadingItems();
        setLoadingItems(true);

        api.get(`/onboarding`, {
            params: {
                hash: params.hash
            }
        }).then(response => {

            const apiOnboarding: Onboarding = response.data;

            setOnboarding(apiOnboarding);

            api.get(`/template/group`, {

            }).then(response => {

                const apiTemplateGroup: TemplateGroup[] = response.data;

                setTemplateGroups(apiTemplateGroup);

                if (apiOnboarding.status === "E") { //Se já está finalizado o onboarding
                    history.push('/');
                    addToast({
                        type: 'error',
                        title: 'Este onboarding já foi finalizado!',
                        description: 'Este onboarding já foi encerrado, inicie um novo ou faça o login!',
                    });
                }
                setLoadingItems(false);
            }).catch(error => {
                setLoadingItems(false);
                history.push('/onboarding/');
                addToast({
                    type: 'error',
                    title: 'Não foi encontrado o seu onboarding',
                    description: 'Não foi possível localizar o seu onboarding!',
                });

            });
        }).catch(error => {
            setLoadingItems(false);
            history.push('/onboarding/');
            addToast({
                type: 'error',
                title: 'Não foi encontrado o seu onboarding',
                description: 'Não foi possível localizar o seu onboarding!',
            });

        });


    }, [params.hash, addToast, history, getRandomLoadingItems]);

    useEffect(() => {

        if (selectedTemplate !== undefined) {
            handleSubmitPage();
        }

    }, [selectedTemplate, handleSubmitPage]);

    return (
        <Container>
            <title>Onboarding | Cange</title>
            <Content>
                <AnimationContainer>
                    <Header>
                        {!isMobile && (
                            <HeaderLeft>
                                <h1>Conte um pouco sobre você</h1>
                            </HeaderLeft>
                        )}
                        <HeaderRight>
                            <a href='https://www.cange.me'>
                                <img className='logo-cange' src={logo} alt="Logo wendata" />
                                <img className='logo-mini-cange' src={logo} alt="Logo wendata" />
                            </a>
                        </HeaderRight>
                    </Header>
                    <DegradeLine />
                    <Body>
                        <ContentList>
                            <h2>Qual área você trabalha?</h2>
                            <ContainerGroup>
                                {!loadingItems && templateGroups !== undefined ? templateGroups?.map((group) => (
                                    <GroupTag
                                        onClick={() => {
                                            setSelectedTemplate(group);
                                        }}
                                        isSelected={group === selectedTemplate}
                                        key={group.id_template_group}
                                        color={group.color}
                                    >
                                        <GroupTagContainer isSelected={group === selectedTemplate}>
                                            <GroupTagIcon>
                                                {group !== undefined && group.icon !== undefined ?
                                                    <IconPickerItem
                                                        icon={group.icon as IconList}
                                                        color={group.color}
                                                    /> :
                                                    <IoMdListBox />
                                                }
                                            </GroupTagIcon>
                                            <GroupTagName>
                                                {group.name}
                                            </GroupTagName>
                                        </GroupTagContainer>
                                    </GroupTag>
                                )) :
                                    loadingItemsCount !== undefined && loadingItemsCount.map((item) => {
                                        return (
                                            <LoaderCard key={item} />
                                        )
                                    })
                                }
                            </ContainerGroup>
                        </ContentList>

                    </Body>
                    <Bottom>
                        <BottomLeft>
                            <ProgressBar>
                                <ProgressBarPercent percent={80} />
                            </ProgressBar>
                        </BottomLeft>
                        <BottomRight>
                            <BtnStart type="submit" onClick={handleSubmitPage} isLoading={loading}>Continuar</BtnStart>
                        </BottomRight>
                    </Bottom>
                </AnimationContainer>
            </Content>
        </Container>
    );
}

export default UseDataGroup;
