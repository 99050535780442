import lottie from 'lottie-web';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

import DegradeLine from '../../components/DegradeLine';
import LoaderCange from '../Loader/LoaderCange';
import api from '../../services/api';
import confetti from '../../assets/confetti.png';
import logo from '../../assets/wendata-mini-logo.png';
import {
    Animation,
    AnimationContainer,
    Body,
    Container,
    ContainerStep,
    Content,
    Header,
    HeaderLeft,
    HeaderRight,
    NoAccount
} from './styles';
import { useToast } from '../../hooks/toast';

interface RouteParams {
    hash?: string;
}

const EmailConfirmation: React.FC = () => {

    const { params } = useRouteMatch<RouteParams>();
    const lottieContainer = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();
    const { addToast } = useToast();

    useEffect(() => {

        try {

            if (params.hash !== undefined) {

                api.post('/onboarding/mail-confirmation', {
                    hash: params.hash
                }).then(response => {

                    setLoading(false);

                    if (lottieContainer.current) {
                        lottie.loadAnimation({
                            container: lottieContainer.current,
                            renderer: 'svg',
                            loop: true,
                            autoplay: true,
                            animationData: require('../../assets/lottie/confetti.json')
                        })
                    }

                }).catch(error => {
                    history.push('/');
                    addToast({
                        type: 'error',
                        title: 'Erro ao confirmar o seu e-mail!',
                        description: 'Ocorreu um erro ao tentar confirmar o seu e-mail na base de dados!',
                    });
                });

            } else {
                history.push('/');
            }

        } catch (err) {
            console.log(err);
            addToast({
                type: 'error',
                title: 'Erro ao confirmar o seu e-mail!',
                description: 'Ocorreu um erro ao tentar confirmar o seu e-mail na base de dados!',
            });
        }

    }, [addToast, history, params.hash]);

    return (
        <>
            <title>Confirmação de E-mail | Cange</title>
            {loading ? <LoaderCange /> :
                <Container>
                    <Content>
                        <AnimationContainer>
                            <Header>
                                <HeaderLeft>
                                    <h1>Oi! &#x1F600;</h1>
                                    <h3>Sua conta está totalmente pronta!</h3>
                                </HeaderLeft>
                                <HeaderRight>
                                    <img src={logo} alt="Logo wendata" />
                                </HeaderRight>

                            </Header>
                            <DegradeLine />
                            <Body>
                                <form>
                                    <img src={confetti} alt="Confetti" />
                                    <ContainerStep>
                                        <h1>Parabéns! Seu e-mail está confirmado!</h1>
                                        <h3>Agora você já pode utilizar todos os recursos do <b>Cange</b>. Inicie um fluxo a partir dos nossos templates e turbine a sua produtividade e do seu time.</h3>
                                        <NoAccount>Quer fazer o login?
                                            <Link to="/" style={{ color: '#14aaf5', paddingLeft: '5px' }}>
                                                Entre aqui!
                                            </Link>
                                        </NoAccount>
                                    </ContainerStep>
                                </form>
                                <Animation className="lottieContainer" ref={lottieContainer} />
                            </Body>
                        </AnimationContainer>
                    </Content>
                </Container>
            }
        </>
    );
}

export default EmailConfirmation;