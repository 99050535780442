import { Card } from "../interfaces/Card";
import { FormAnswer } from "../interfaces/FormAnswer";

//IF CHANGE HERE...
//Need to change in Front End + Back End too!

export default function getDynamicField(
    type_object_reference: string,
    objectReference: Card | FormAnswer[],
    field_id: string
): string {

    let retValue = "";
    let hasChieldField = false;

    let fieldId: number;
    let fieldChieldId: number;

    //Valid if has a chield field
    if (field_id.includes('->')) {

        hasChieldField = true;

        const fieldParts = field_id.split('->');

        if (fieldParts.length === 2) {

            const firstPart = fieldParts[0];
            const secondPart = fieldParts[1];

            if (!isNaN(Number(firstPart))) {
                fieldId = Number(firstPart);
            }

            if (!isNaN(Number(secondPart))) {
                fieldChieldId = Number(secondPart);
            }

        }
    } else {

        if (!isNaN(Number(field_id))) {
            fieldId = Number(field_id);
        }

    }

    if (objectReference !== undefined && objectReference !== null) {
        if (type_object_reference === 'card') {

            const card = objectReference as Card;

            //Get field and valid if is from register TO BE CODED...

            //Get the columns
            if (card.form_answers !== undefined && card.form_answers.length > 0) {

                //Get all from answers that have the field
                let formAnswers = card.form_answers?.filter(formAnswer => {
                    const findField = formAnswer.form_answer_fields.filter(faf => faf.field_id === fieldId);
                    return findField.length > 0;
                });

                //Order formAnswers from the highest to the lowest id_form_answer
                formAnswers = formAnswers.sort((a, b) => b.id_form_answer - a.id_form_answer);

                if (formAnswers !== undefined && formAnswers.length > 0 && formAnswers[0].form_answer_fields !== undefined && formAnswers[0].form_answer_fields.length > 0) {

                    if (!hasChieldField) { //Get directly from the form_answer_fields

                        //Get the value from the formAnswer                        
                        retValue = formAnswers[0].form_answer_fields.filter(faf => faf.field_id === fieldId)[0].valueString;

                    } else { //Get from the chield field

                        const formAnswerField = formAnswers[0].form_answer_fields.filter(faf => faf.field_id === fieldId)[0];

                        if (formAnswerField !== undefined && formAnswerField !== null) {

                            //Check if has valueRegister
                            if (formAnswerField.valueRegister !== undefined && formAnswerField.valueRegister !== null) {

                                //Check if has the form_answer_fields
                                if (formAnswerField.valueRegister.form_answer_fields !== undefined && formAnswerField.valueRegister.form_answer_fields !== null && formAnswerField.valueRegister.form_answer_fields.length > 0) {

                                    //Get the value from the formAnswer
                                    retValue = formAnswerField.valueRegister.form_answer_fields.filter(faf => faf.field_id === fieldChieldId)[0].valueString;

                                }

                            }

                        }

                    }

                }

            }

        } else if (type_object_reference === 'form_answer') {

            let formAnswers = objectReference as FormAnswer[];

            //Order formAnswers from the highest to the lowest id_form_answer
            formAnswers = formAnswers.sort((a, b) => b.id_form_answer - a.id_form_answer);

            if (formAnswers !== undefined && formAnswers.length > 0 && formAnswers[0].form_answer_fields !== undefined && formAnswers[0].form_answer_fields.length > 0) {

                if (!hasChieldField) { //Get directly from the form_answer_fields

                    //Get the value from the formAnswer                        
                    retValue = formAnswers[0].form_answer_fields.filter(faf => faf.field_id === fieldId)[0].valueString;

                } else { //Get from the chield field

                    const formAnswerField = formAnswers[0].form_answer_fields.filter(faf => faf.field_id === fieldId)[0];

                    if (formAnswerField !== undefined && formAnswerField !== null) {

                        //Check if has valueRegister
                        if (formAnswerField.valueRegister !== undefined && formAnswerField.valueRegister !== null) {

                            //Check if has the form_answer_fields
                            if (formAnswerField.valueRegister.form_answer_fields !== undefined && formAnswerField.valueRegister.form_answer_fields !== null && formAnswerField.valueRegister.form_answer_fields.length > 0) {

                                //Get the value from the formAnswer
                                retValue = formAnswerField.valueRegister.form_answer_fields.filter(faf => faf.field_id === fieldChieldId)[0].valueString;

                            }

                        }

                    }

                }

            }


        }
    }

    return retValue === undefined || retValue === null || retValue === "" ? "{Sem registro}" : retValue;

}