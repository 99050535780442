import React from "react";

import {
    Container,
    UpgradeButton,
} from "./style";
import ChoosePlan from "../../../dialogs/Billing/ChoosePlan";
import { useAuth } from "../../../hooks/auth";

interface ButtonUpgradeProps {
    buttonText: string;
}

const ButtonUpgrade: React.FC<ButtonUpgradeProps> = ({ buttonText }) => {

    const { renewUser } = useAuth();

    const [openBilling, setOpenBilling] = React.useState(false);

    return (
        <Container>

            <UpgradeButton type="button" onClick={() => setOpenBilling(true)}>
                {buttonText}
            </UpgradeButton>

            <ChoosePlan
                open={openBilling}
                onClose={() => {
                    renewUser();
                    setOpenBilling(false);
                }}
                isEndTrial={false}
            />

        </Container >
    );

}

export default ButtonUpgrade;