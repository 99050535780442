import styled from "styled-components";

export const Container = styled.div`    
    background-color: white;
    border-radius: 10px;
    margin-right: 15px;
    margin-left: 10px;
    height: calc(90vh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
`;

export const ContainerImage = styled.div`
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    img {
        width: 100%;
        height: 100%;
        max-width: 800px;
    }
`;

export const ContainerTitle = styled.div`
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    color: black;
`;

export const ContainerSubTitle = styled.div`
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    color: gray;
    text-align: center;
`;
