import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    Content,
    ContentBody,
    InviteButtonSkip,
    ContainerAnimation,
    ActionArea,
    ButtonsArea,
    StartButton,
    MainDialog,
    AnimationLoading,
    ContainerLoader,
    ContainerPlanPaid,
    PlanPaidColumn,
    InvoiceButton,
    ContainerPlanPaidAction,
    ObservationArea,
    HeaderArea
} from "./style";

import { useMediaQuery } from "react-responsive";
import lottie from "lottie-web";
import api from "../../services/api";
import { Invoice } from "../../interfaces/Invoice";
import { maskFormulaCurrency } from "../../components/Forms/Fields/Components/Formula";

interface WelcomePaidProps {
    open: boolean;
    onClose: () => void;
}

const linkWhatsapp = "https://api.whatsapp.com/send?phone=551151989897&text=Ol%C3%A1!%20Estou%20fazendo%20o%20Trial%20e%20estou%20com%20d%C3%BAvida,%20pode%20me%20ajudar?";

const WelcomePaid: React.FC<WelcomePaidProps> = ({ onClose, open }) => {

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const lottieContainer = useRef<HTMLDivElement>(null);

    const [loading, setLoading] = useState<boolean>();
    const [invoice, setInvoice] = useState<Invoice>();
    const [isErrored, setIsErrored] = useState<boolean>(false);

    const [activeExit] = useState<boolean>(false);

    const getInvoiceApi = useCallback(async (session_id?: string) => {

        let triedTimes = 0;
        let maxTries = 3;

        if (session_id !== undefined) {

            setLoading(true);

            const processInvoice = async () => {
                try {
                    const response = await api.get(`/plan/invoice/by-session`, {
                        params: {
                            session_id: session_id
                        }
                    });
                    if (response.data !== null) {
                        const respInvoice: Invoice = response.data;
                        if (respInvoice !== undefined) {
                            setInvoice(respInvoice);
                            setLoading(false);
                        } else {
                            setIsErrored(true);
                        }
                    } else {
                        setIsErrored(true);
                    }
                } catch (error) {
                    triedTimes++;
                    if (triedTimes < maxTries) {
                        setTimeout(processInvoice, 7000); // Retry after 5 seconds
                    } else {
                        setIsErrored(true);
                        setLoading(false);
                    }
                }
            };

            processInvoice(); // Start the initial processing
        } else {
            setIsErrored(true);
            setLoading(false);
        }

    }, []);

    useEffect(() => {

        //Get session_id from the url parameters
        const session_id = new URLSearchParams(window.location.search).get('session_id');

        if (session_id !== null && session_id !== undefined && session_id !== "") {
            getInvoiceApi(session_id);
        } else {
            getInvoiceApi();
        }

    }, [getInvoiceApi]);

    useEffect(() => {
        if (lottieContainer.current !== null) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../assets/lottie/loader.json')
            })
        }
    }, [loading]);

    return (
        <MainDialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            fullScreen={isMobile}
            style={{ zIndex: '99999' }}
            isVisible={activeExit}
        >
            <Content>
                <ContentBody container>

                    <div>
                        {loading === true ?
                            <ContainerLoader>
                                Processando o pagamento...
                                <AnimationLoading className="lottieContainer" ref={lottieContainer} />
                            </ContainerLoader > :

                            !isErrored ?
                                <ContainerAnimation>
                                    <HeaderArea style={{ position: 'relative' }}>
                                        <h1>Pagamento realizado com <b style={{ color: "#f23b5d" }}>Sucesso</b> 🎉</h1>
                                        <h3>Agora você pode aproveitar de todas as vantagens do plano <b style={{ color: "#f23b5d" }}>{invoice?.plan.name}</b></h3>
                                    </HeaderArea>
                                    <ContainerPlanPaid>
                                        <PlanPaidColumn style={{ borderRight: '1px solid gray' }}>
                                            <span>Plano</span>
                                            <strong style={{ color: '#f23b5d' }}>{invoice?.plan.name}</strong>
                                        </PlanPaidColumn>
                                        <PlanPaidColumn style={{ borderRight: '1px solid gray' }}>
                                            <span>Recorrência</span>
                                            <strong>{invoice?.plan.frequency === "M" ? "Mensal" : "Anual"}</strong>
                                        </PlanPaidColumn>
                                        <PlanPaidColumn style={{ borderRight: '1px solid gray' }}>
                                            <span>Usuários</span>
                                            <strong>{invoice?.quantity}</strong>
                                        </PlanPaidColumn>
                                        <PlanPaidColumn>
                                            <span>Valor Pago</span>
                                            <strong>{maskFormulaCurrency(String(invoice?.amount_paid))}</strong>
                                        </PlanPaidColumn>
                                    </ContainerPlanPaid>
                                    <ContainerPlanPaidAction>
                                        <InvoiceButton href={invoice?.hosted_invoice_url} target="_blank" rel="noopener noreferrer">
                                            Ver meu recibo
                                        </InvoiceButton>
                                    </ContainerPlanPaidAction>

                                    <ObservationArea>
                                        <span style={{ color: '#000000ad' }}>A sua nota fiscal será enviada para o seu e-mail em até 3 dias úteis</span>
                                    </ObservationArea>
                                    <ActionArea>
                                        <h3 style={{ color: '#000000ad' }}>Todas as funcionalidades já estão disponíveis para começar a usar</h3>
                                    </ActionArea>
                                    <ButtonsArea>
                                        <InviteButtonSkip href={linkWhatsapp} target="_blank" style={{ textDecoration: 'none', color: "#f23b5d" }} rel="noopener noreferrer">
                                            Preciso de ajuda
                                        </InviteButtonSkip>
                                        <StartButton onClick={() => {
                                            onClose();
                                        }}>
                                            Começar a usar
                                        </StartButton>
                                    </ButtonsArea>
                                </ContainerAnimation> :
                                <ContainerAnimation>
                                    <HeaderArea style={{ position: 'relative' }}>
                                        <h1>Ops! Ainda não conseguimos identificar o seu pagamento 😓</h1>
                                        <h3>Estamos trabalhando para que em seguida tudo seja liberado para você. Parece que não foi possível realizar o processamento da compra</h3>
                                    </HeaderArea>
                                    <ActionArea style={{ marginTop: '0px' }}>
                                        <h3 style={{ color: 'black' }}>Por favor, aguarde alguns minutos e atualize sua página. Caso não funcione, chame nosso time que iremos lhe ajudar</h3>
                                    </ActionArea>
                                    <ButtonsArea>
                                        <InviteButtonSkip href={linkWhatsapp} target="_blank" style={{ textDecoration: 'none', color: "#f23b5d" }} rel="noopener noreferrer">
                                            Falar com o suporte
                                        </InviteButtonSkip>
                                        <StartButton onClick={() => {
                                            onClose();
                                        }}>
                                            Voltar para o sistema
                                        </StartButton>
                                    </ButtonsArea>
                                </ContainerAnimation>
                        }
                    </div>

                </ContentBody>
            </Content>

        </MainDialog >
    );

}

export default WelcomePaid;