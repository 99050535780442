import styled, { keyframes } from "styled-components";

export const Container = styled.div`
    display: block;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #f4f5f7;
    padding: 50px;
`;

export const Animation = styled.div`
    height: 70%;
`;

const breathAnimation = keyframes` 
    from {
        opacity: 1;
        transform: translateY(-10px);
    } 
    to {
        opacity: 1;
        transform: translateY(10px);
    }
`

export const ContainerDescription = styled.div`
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    height: 30%;
    text-align: center;

    h1 {
        font-size: 24px;
        color: black;
        font-family: Roboto, helvetica;
        letter-spacing: 0.8px;
        text-align: center;
        margin-top: 50px;

        animation: ${breathAnimation} 2s;
        animation-iteration-count: infinite;
    }

    h3 {
        color: #676879;
        text-align: left;
        margin-top: 8px;
        font-weight: 400;
        font-size: 16px;
        margin-block-end: unset; 
        text-align: center;
        margin-top: 30px;
    }
`;

export const BtnRefresh = styled.button`
    border: none;
    background: transparent;
    color: #2a87d0;
    font-weight: 500;
    text-decoration: underline;
`