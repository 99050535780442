import styled, { css } from 'styled-components';

import { shade } from "polished";

interface ContainerProps {
    color?: string;
    height?: string;
    margin?: string;
    color_text?: string;
}

export const Container = styled.button<ContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;    

    background: #f23b5c;
    height: '56px';
    border-radius: 5px;
    border: 0;
    padding: 0 16px;
    color: white;
    width: 100%;
    font-weight: 500;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        background: ${shade(0.2, '#f23b5c')}
    } 

    ${props => props.height && css`
        height: ${props.height} !important;
    `}
    
    ${props => props.margin && css`
        margin: ${props.margin} !important;
    `}
    
    ${props => props.color && css`
        background: ${props.color};

        &:hover {
            background: ${shade(0.2, props.color)}
        } 
    `}

    ${props => props.color_text && css`
        color: ${props.color_text} !important;
    `}

`;

export const IconContainerLeft = styled.div`
    margin-right: 10px;
    margin-top: 5px;
`;

export const IconContainerRight = styled.div`
    margin-left: 10px;
    margin-top: 5px;
`;

export const ContainerLoader = styled.div`
    display: block;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;


export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;   

    svg {
        height: 100% !important;
    }
`;