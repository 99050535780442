import ContentLoader from "react-content-loader"
import filesize from "filesize";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { uniqueId } from "lodash";
import { useField } from "@unform/core";

import Attachments from "../../../../Colaboration/Attachments";
import ErrorDescription from "../../../../ErrorDescription";
import UploadButton from "../../../../UploadButton";
import api from '../../../../../services/api';
import { Attachment } from "../../../../../interfaces/Attachment";
import {
    Container
} from "./style";
import { useToast } from '../../../../../hooks/toast';

const LoaderAttach = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={'50px'}
        viewBox="0 0 700 60"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="60" />
    </ContentLoader>
)

interface InputAttachProps {
    name: string;
    placeholder?: string;
    max_length?: number;
    isPublicForm?: boolean;
    public_hash?: string;
    formula?: string;
    variation?: string;
    card_current_id?: number;
    flow_id?: number;
}

const InputAttach: React.FC<InputAttachProps> = ({ name, placeholder, max_length, isPublicForm, public_hash, formula, variation, card_current_id, flow_id }) => {

    const { addToast } = useToast();
    const selectRef = useRef(null);

    const { fieldName, error, registerField, defaultValue } = useField(name);

    const [maxLength] = useState<string>(max_length === undefined ? "1" : String(max_length));
    const [uploadedFiles, setUploadedFiles] = useState<Attachment[]>([]);
    const [loadingAttach, setLoadingAttach] = React.useState<boolean>(false);

    const updateFile = useCallback((id: any, data: any) => {
        setUploadedFiles((state) =>
            state.map((file) => (file.id_attachment === id ? { ...file, ...data } : file))
        );
    }, []);

    const processUpload = useCallback((uploadedFile: Attachment) => {

        let data = new FormData();

        if (uploadedFile.file !== undefined) {

            const endpoint = isPublicForm && public_hash !== undefined ? '/form-public/attachment?public_hash=' + public_hash : '/attachment';

            data.append('file', uploadedFile.file, uploadedFile.original_name);

            api.post(endpoint, data, {
                onUploadProgress: (event) => {
                    let progress: number = Math.round((event.loaded * 100) / event.total);

                    updateFile(uploadedFile.id_attachment, { progress });

                }
            }).then(response => {

                const retApi: Attachment = response.data

                if (retApi.id_attachment !== undefined) {

                    //Update the control variables
                    retApi.uploaded = true;
                    if (retApi.blob_size !== undefined) {
                        retApi.readableSize = filesize(retApi.blob_size * 1024);
                    }

                    updateFile(uploadedFile.id_attachment, retApi);

                }

            }).catch(error => {

                console.log(error);

                updateFile(uploadedFile.id_attachment, { error: true });

            });

        }

    }, [updateFile, isPublicForm, public_hash]);

    const handleUploadFile = useCallback((files: File[]) => {

        const uploadedFilesNew: Attachment[] = files.map(file => ({
            file,
            id_attachment: uniqueId(),
            original_name: formula !== undefined && formula !== null ? formula + file.name : file.name,
            readableSize: filesize(file.size * 1024),
            progress: 0,
            uploaded: false,
            error: false,
            url: undefined
        }))

        setUploadedFiles(uploadedFiles.concat(uploadedFilesNew));

        uploadedFilesNew.forEach(processUpload);

    }, [uploadedFiles, processUpload, formula]);

    const handleDeleteAttachment = useCallback(async (attach: Attachment) => {

        await api.delete('/attachment', {
            params: {
                id_attachment: attach.id_attachment,
                //card_id: card.id_card
            }
        }).then(response => {

            //Delete the object from the uploadedFiles
            const newArr = uploadedFiles.filter((file) => file.id_attachment !== attach.id_attachment);

            setUploadedFiles(newArr);


        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Erro ao deletar o anexo',
                description: 'Ocorreu um erro ao tentar deletar o anexo!',
            });
        });


    }, [addToast, uploadedFiles]);

    const getApiAttachments = useCallback(async () => {

        let newArr: Attachment[] = []

        if (defaultValue !== undefined) {
            if (typeof defaultValue === "object") {

                for (let index = 0; index < defaultValue.length; index++) {
                    const idAtt = defaultValue[index];

                    await api.get(`/attachment`, {
                        params: {
                            id_attachment: idAtt
                        }
                    }).then(response => {
                        if (response.data !== null) {

                            let apiRet: Attachment = response.data;

                            if (apiRet !== undefined) {
                                newArr.push(apiRet);
                            }

                        }
                    })

                }

            } else {

                await api.get(`/attachment`, {
                    params: {
                        id_attachment: defaultValue
                    }
                }).then(response => {
                    if (response.data !== null) {

                        let apiRet: Attachment = response.data;

                        if (apiRet !== undefined) {
                            newArr.push(apiRet);
                        }

                    }
                })

            }

            setUploadedFiles(newArr);
        }

    }, [defaultValue]);

    useEffect(() => {

        //Ajustar para exibir a possibilidade de fazer download quando salvo no Card
        //Ajustar a edição com o default value
        //Testar all

        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref: any) => {

                const ret = uploadedFiles.filter((file) => file.uploaded === true).map(({ id_attachment }) => id_attachment);

                if (ret.length <= 0) {
                    return undefined;
                } else {
                    return ret;
                }

            },
            setValue(refs, value) {

            },
            clearValue: ref => {
                setUploadedFiles([]);
            },
        });

    }, [fieldName, registerField, uploadedFiles]);

    useEffect(() => {

        setLoadingAttach(true);

        getApiAttachments();

        setLoadingAttach(false);

    }, [defaultValue, getApiAttachments]);

    return (
        <>
            <Container>
                {loadingAttach ? (
                    <LoaderAttach />
                ) : (
                    <>
                        {!!!uploadedFiles.length && (
                            <UploadButton onUploadFile={handleUploadFile} placeholder={placeholder} />
                        )}

                        {!!uploadedFiles.length && (
                            <Attachments
                                isPublicForm={isPublicForm}
                                maxLength={maxLength}
                                uploadedFiles={uploadedFiles}
                                onUploadFile={handleUploadFile}
                                onDeleteFile={handleDeleteAttachment}
                                typeUser="A"
                                variation={variation}
                                card_current_id={card_current_id}
                                flow_id={flow_id}
                            />
                        )}
                    </>
                )}
            </Container>
            {error && (<ErrorDescription title={error} />)}
        </>
    );

}

export default InputAttach;