import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    ActionContainer,
    BoxItemsContainer,
    BoxOptionsItem,
    BoxSearchContainer,
    BoxShortCutsContainer,
    BoxTagDivider,
    BoxTagItem,
    BoxUserItem,
    BoxUserLeft,
    BoxUserMail,
    BoxUserName,
    BoxUserRigth,
    BtnCancel,
    BtnConfirm,
    Container,
    DatePickerContainer,
    SearchInput,
    ShortCutBtn
} from "./style";
import { User } from "../../interfaces/User";
import api from "../../services/api";
import AvatarCange from "../AvatarCange";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { addDays, format } from "date-fns";
import pt from "date-fns/locale/pt-BR";
import { FlowTag } from "../../interfaces/FlowTag";
import { useToast } from "../../hooks/toast";
import { FaArchive, FaCopy, FaEraser, FaTrash } from "react-icons/fa";

registerLocale("pt", pt);

interface QuickActionBoxProps {
    type: 'user' | 'due_date' | 'tag' | 'options';
    id_card_items: number[];
    flow_id: number;
    totalSelected: number;
    isInLine?: boolean;
    posTop?: number;
    posLeft?: number;
    removeConfirm?: boolean;
    isAbove?: boolean;
    onForceUpdate?: (id_card_items?: number[], isUpdateAll?: boolean) => void;
    onClose?: () => void;
}

const QuickActionBox: React.FC<QuickActionBoxProps> = ({ type, flow_id, totalSelected, id_card_items, isInLine, posLeft, posTop, removeConfirm, isAbove, onForceUpdate, onClose }) => {

    const { addToast } = useToast();

    const [users, setUsers] = useState<User[]>();
    const [userSelected, setUserSelected] = useState<User | undefined>();
    const [usersFiltered, setUsersFiltered] = useState<User[]>([]);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const datepickerRef = useRef(null);
    const [dueDate, setDueDate] = useState<any>(new Date(new Date().setHours(0, 0, 0, 0)));
    const [dueDateSelected, setDueDateSelected] = useState<Date | undefined>(undefined);

    const dateFormatVariation: string = 'dd/MM/yyyy HH:mm';
    const isDateTime: boolean = isInLine ? false : true;

    const [tags, setTags] = useState<FlowTag[]>();
    const [tagSelected, setTagSelected] = useState<FlowTag | undefined>();

    const [optionSelected, setOptionSelected] = useState<number | undefined>();

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingChange, setLoadingChange] = useState<boolean>(false);

    const getTagsApi = useCallback(async () => {

        if (flow_id !== undefined) {

            setLoading(true);

            api.get(`/flow-tag/by-flow`, {
                params: {
                    flow_id: flow_id
                }
            }).then(response => {
                if (response.data !== null) {

                    let respStep: FlowTag[] = response.data;

                    if (respStep !== undefined) {
                        setTags(respStep);
                    }
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar as Etiquetas',
                    description: 'Ocorreu um erro ao tentar buscar as Etiquetas!',
                });
            });

        }

    }, [flow_id, addToast]);

    const getUsersApi = useCallback(async () => {

        if (flow_id !== undefined) {

            setLoading(true);

            api.get(`/user/by-flow`, {
                params: {
                    id_flow: flow_id
                }
            }).then(response => {
                if (response.data !== null) {
                    const respStep: User[] = response.data;

                    setUsers(respStep);

                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar os Usuários',
                    description: 'Ocorreu um erro ao tentar buscar os Usuários!',
                });
            });

        }

    }, [flow_id, addToast]);

    const handleFilterUsers = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {

        if (users !== undefined) {

            const searchText = e.currentTarget.value.toLowerCase();

            let usersNew = users;

            const usersName = usersNew.filter((user) => user.name.toLowerCase().includes(searchText));

            setUsersFiltered(usersName);

        }

    }, [users]);

    const handleSelectUser = useCallback(async (user: User) => {

        setUserSelected(user);

    }, []);

    const handleCancelAction = useCallback(async () => {

        if (type === 'user') {
            setUserSelected(undefined);
        } else if (type === 'due_date') {
            setDueDateSelected(undefined)
            setDueDate(new Date(new Date().setHours(0, 0, 0, 0)));
        } else if (type === 'tag') {
            setTagSelected(undefined);
        } else if (type === 'options') {
            setOptionSelected(undefined);
        }

    }, [type]);

    const handleSubmmit = useCallback(async () => {

        setLoadingChange(true);

        //Formata a data        
        let newDueDate;
        if (dueDateSelected !== undefined) {
            const dateDueType = new Date(dueDateSelected);
            newDueDate = format(
                dateDueType,
                "yyyy-MM-dd HH:mm"
            );
        }

        api.post(`/card/bulk`, {
            type: type,
            flow_id: flow_id,
            id_card_items: id_card_items,
            user_id: type === 'user' && userSelected !== undefined ? userSelected.id_user : undefined,
            due_date: type === 'due_date' && newDueDate !== undefined ? newDueDate : undefined,
            tag_flow_id: type === 'tag' && tagSelected !== undefined ? tagSelected.id_flow_tag : undefined,
            option_id: ((type === 'options' && optionSelected !== undefined) || optionSelected !== undefined) ? optionSelected : undefined,
        }).then(response => {
            if (response.data !== null) {

                const respData: boolean = response.data;

                if (respData) {
                    addToast({
                        type: 'success',
                        title: 'Alteração realizada com sucesso!',
                        description: 'A alteração foi realizada com sucesso :)',
                    });
                } else {
                    addToast({
                        type: 'error',
                        title: 'Erro ao realizar a alteração',
                        description: 'Ocorreu um erro ao tentar realizar a alteração!',
                    });
                }

                if (onForceUpdate !== undefined) {
                    if (type === 'options' && optionSelected === 3) {
                        onForceUpdate(id_card_items, true);
                    } else {
                        onForceUpdate(id_card_items);
                    }
                }

                if (onClose !== undefined) {
                    onClose();
                }

                setTagSelected(undefined);
                setUserSelected(undefined);
                setDueDateSelected(undefined);
                setOptionSelected(undefined);

            }
            setLoadingChange(false);
        }).catch(error => {
            setLoadingChange(false);
            addToast({
                type: 'error',
                title: 'Erro ao buscar as Etiquetas',
                description: 'Ocorreu um erro ao tentar buscar as Etiquetas!',
            });
        });

    }, [type, addToast, dueDateSelected, flow_id, id_card_items, optionSelected, tagSelected, userSelected, onForceUpdate, onClose]);

    useEffect(() => {

        if (type === 'user') {
            getUsersApi();

            if (searchInputRef.current) {
                searchInputRef.current.focus();
            }
        } else if (type === 'tag') {
            getTagsApi();
        }

    }, [type, getUsersApi, getTagsApi]);

    useEffect(() => {
        if (users !== undefined) {
            setUsersFiltered(users);
        }
    }, [users]);

    useEffect(() => {
        if (userSelected !== undefined) {
            if (removeConfirm !== undefined && removeConfirm) {
                handleSubmmit();
            }
        }
    }, [userSelected, removeConfirm, handleSubmmit]);

    useEffect(() => {
        if (dueDateSelected !== undefined) {
            if (removeConfirm) {
                handleSubmmit();
            }
        }
    }, [dueDateSelected, removeConfirm, handleSubmmit]);

    useEffect(() => {
        if (tagSelected !== undefined) {
            if (removeConfirm) {
                handleSubmmit();
            }
        }
    }, [tagSelected, removeConfirm, handleSubmmit]);

    useEffect(() => {
        if (optionSelected !== undefined) {
            if (removeConfirm) {
                handleSubmmit();
            }
        }
    }, [optionSelected, removeConfirm, handleSubmmit]);

    return (
        <Container isAbove={isAbove} isInLine={isInLine} left={posLeft} top={posTop} style={type === 'user' ? { minWidth: '280px' } : type === 'options' ? { left: '115px' } : {}}>

            {type === 'user' && (
                <>
                    <BoxItemsContainer style={{ maxHeight: '250px' }}>
                        {loading && (
                            <BoxTagItem style={{ textAlign: 'center', maxWidth: '100%' }}>
                                Carregando...
                            </BoxTagItem>
                        )}

                        {usersFiltered.map((user) => {
                            return (
                                <BoxUserItem key={user.email} onClick={() => handleSelectUser(user)} selected={userSelected !== undefined && userSelected.id_user === user.id_user}>
                                    <BoxUserLeft>
                                        <AvatarCange user={user} size="30" />
                                    </BoxUserLeft>
                                    <BoxUserRigth>
                                        <BoxUserName>{user.name}</BoxUserName>
                                        <BoxUserMail>{user.email}</BoxUserMail>
                                    </BoxUserRigth>
                                </BoxUserItem>
                            )
                        })}

                        {usersFiltered !== undefined && usersFiltered.length === 0 && !loading && (
                            <BoxTagItem style={{ textAlign: 'center', maxWidth: '100%' }}>
                                Nenhum usuário encontrado
                            </BoxTagItem>
                        )}
                    </BoxItemsContainer>

                    <BoxSearchContainer>
                        <SearchInput
                            placeholder="Buscar usuários"
                            onChange={handleFilterUsers}
                            ref={searchInputRef}
                        >

                        </SearchInput>
                    </BoxSearchContainer>
                </>
            )}

            {type === 'due_date' && (
                <>
                    <BoxItemsContainer>
                        <DatePickerContainer>
                            <ReactDatePicker
                                ref={datepickerRef}
                                onChange={(d) => {
                                    if (d !== null) {
                                        setDueDate(d);
                                        setDueDateSelected(d);
                                    }
                                }}
                                selected={dueDate}
                                dateFormat={dateFormatVariation}
                                timeFormat={isDateTime ? "HH:mm" : undefined}
                                locale={pt}
                                showTimeInput={isDateTime}
                                timeInputLabel="Hora"
                                timeCaption="Hora"
                                inline
                            />
                        </DatePickerContainer>
                    </BoxItemsContainer>
                    <BoxShortCutsContainer>
                        <ShortCutBtn onClick={() => {
                            setDueDate(addDays(new Date(new Date().setHours(0, 0, 0, 0)), -1))
                            setDueDateSelected(addDays(new Date(new Date().setHours(0, 0, 0, 0)), -1))
                        }}>
                            Ontem
                        </ShortCutBtn>
                        <ShortCutBtn onClick={() => {
                            setDueDate(new Date(new Date().setHours(0, 0, 0, 0)))
                            setDueDateSelected(new Date(new Date().setHours(0, 0, 0, 0)))
                        }}>
                            Hoje
                        </ShortCutBtn>
                        <ShortCutBtn onClick={() => {
                            setDueDate(addDays(new Date(new Date().setHours(0, 0, 0, 0)), 1))
                            setDueDateSelected(addDays(new Date(new Date().setHours(0, 0, 0, 0)), 1))
                        }}
                            style={{ marginRight: '0px' }}>
                            Amanhã
                        </ShortCutBtn>
                    </BoxShortCutsContainer>
                </>
            )}

            {type === 'tag' && (
                <>
                    <BoxItemsContainer style={{ maxWidth: '200px', marginBottom: '5px' }}>
                        {loading && (
                            <BoxTagItem style={{ textAlign: 'center', maxWidth: '100%' }}>
                                Carregando...
                            </BoxTagItem>
                        )}

                        {tags?.map((tag) => {
                            return (
                                <BoxTagItem key={tag.id_flow_tag} color={tag.color} onClick={() => setTagSelected(tag)} selected={tagSelected !== undefined && tagSelected.id_flow_tag === tag.id_flow_tag}>
                                    {tag.description}
                                </BoxTagItem>
                            )
                        })}

                        {tags !== undefined && tags.length === 0 && !loading && (
                            <BoxTagItem style={{ textAlign: 'center' }}>
                                Nenhuma etiqueta encontrada
                            </BoxTagItem>
                        )}

                        {isInLine && (
                            <BoxTagItem color="#81818145" key={1} onClick={() => setOptionSelected(6)} selected={optionSelected === 6}>
                                <FaEraser />
                                Remover etiquetas
                            </BoxTagItem>
                        )}
                    </BoxItemsContainer>
                </>
            )}

            {type === 'options' && (
                <>
                    <BoxItemsContainer style={{ maxWidth: '300px', marginBottom: '5px' }}>
                        <BoxOptionsItem color="#81818145" key={1} onClick={() => setOptionSelected(4)} selected={optionSelected === 4}>
                            <FaEraser />
                            Limpar o responsável
                        </BoxOptionsItem>
                        <BoxOptionsItem color="#81818145" key={1} onClick={() => setOptionSelected(5)} selected={optionSelected === 5}>
                            <FaEraser />
                            Limpar o vencimento
                        </BoxOptionsItem>
                        <BoxOptionsItem color="#81818145" key={1} onClick={() => setOptionSelected(6)} selected={optionSelected === 6}>
                            <FaEraser />
                            Limpar as etiquetas
                        </BoxOptionsItem>
                        <BoxTagDivider />
                        <BoxOptionsItem color="#81818145" key={1} onClick={() => setOptionSelected(1)} selected={optionSelected === 1}>
                            <FaTrash />
                            Excluir
                        </BoxOptionsItem>
                        <BoxOptionsItem color="#81818145" key={2} onClick={() => setOptionSelected(2)} selected={optionSelected === 2}>
                            <FaArchive />
                            Arquivar
                        </BoxOptionsItem>
                        <BoxOptionsItem color="#81818145" key={3} onClick={() => setOptionSelected(3)} selected={optionSelected === 3}>
                            <FaCopy />
                            Duplicar
                        </BoxOptionsItem>
                    </BoxItemsContainer>
                </>
            )}

            {(userSelected !== undefined || tagSelected !== undefined || dueDateSelected !== undefined || optionSelected !== undefined) && (removeConfirm === undefined || removeConfirm === false) && (
                <>
                    <ActionContainer style={{ borderTop: '1px solid #404040', textAlign: 'center', fontSize: '14px', paddingBottom: '0px', fontWeight: '500' }}>
                        Aplicar esta alteração em {totalSelected} {totalSelected > 1 ? 'cartões' : 'cartão'}?
                    </ActionContainer>
                    <ActionContainer>
                        <BtnCancel onClick={() => handleCancelAction()}>
                            Cancelar
                        </BtnCancel>
                        <BtnConfirm isLoading={loadingChange} onClick={() => handleSubmmit()}>
                            Aplicar
                        </BtnConfirm>
                    </ActionContainer>
                </>
            )}

        </Container>
    );

}

export default QuickActionBox;