import styled from "styled-components";
import { Form } from "@unform/web";
import Switch from "@material-ui/core/Switch";

export const Container = styled.div`
  /*height: 100%*/
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*margin-bottom: 25px;*/
  /*background-color: #f6f8f9;*/
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
  width: 100%;
  max-width: 700px;
  background-color: white;
  /*min-height: 300px;*/
  overflow-y: auto;
`;

export const FormUnform = styled(Form)`
  width: 90%;
  min-width: 100px;
  padding: 25px 10px 25px 10px;

`;

export const FormUnformB = styled(Form)`
  width: 100%;
  min-width: 100px;
  padding: 25px 10px 25px 10px;

`;

export const FormContainer = styled.div`
  margin-bottom: 20px;
`;

export const Title = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: black;
  margin-bottom: 5px;
  margin-left: 5px;

  svg {
    margin-left: 10px;
  }
`;

export const Description = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: gray;
  margin-bottom: 5px;
  margin-left: 5px;
`;

export const HelpText = styled.button`
  margin-left: 15px;
  display: inline-block;
  background-color: transparent;
  border: none;

  svg {
    margin: 0;
  }
`;

export const SwitchBase = styled(Switch)`
  float: right;
  margin-top: -23px;
`;