import React, { useEffect, useState } from "react";

import {
    Container,
    ImageViewer
} from "./style";

import PdfViewer from "../PdfViewer";

interface FilePreviewProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    file: { url: string, fileType: string, name: string, base64?: string };
}

const FilePreview: React.FC<FilePreviewProps> = ({ file, open, setOpen }) => {

    const [visible, setVisible] = useState(true);
    const [openPdfViewer, setOpenPdfViewer] = useState(false);

    const getViewerByFileType = (fileExtension: string) => {
        switch (fileExtension) {
            case "application/pdf":
                return 'pdfViewer';
            case "image/jpg":
            case "image/jpeg":
            case "image/png":
            case "image/gif":
                return 'imageViewer';
            default:
                return null;
        }
    };

    const renderFilePreview = () => {
        const viewerName = getViewerByFileType(file.fileType);

        if (!viewerName) {
            return <div>Formato de arquivo não suportado</div>;
        }

        if (viewerName === 'pdfViewer' && file.base64 !== undefined) {
            return (
                <div>
                    {openPdfViewer && <PdfViewer open={openPdfViewer} file={file} onClose={() => {
                        setOpen(false);
                        setOpenPdfViewer(false);
                        
                    }} />}
                </div>
            );
        }

        if (viewerName === 'imageViewer') {
            return (
                <div>
                    <ImageViewer
                        visible={visible}
                        onClose={() => { 
                            setOpen(false);
                            setVisible(false); 
                        }}
                        images={[{ src: file.url, alt: file.name }]}
                        zIndex={99999999999999999999999999}
                        className="image-viewer"
                        rotatable={false}
                        scalable={false}
                        noImgDetails={false}
                        showTotal={false}
                        maxScale={3}
                        minScale={0.5}
                        noNavbar={true}
                        noFooter={true}
                    />
                </div>
            );
        }

        return <div>Formato de arquivo não suportado</div>;
    };

    useEffect(() => {
        if (file.fileType === "application/pdf" && open) {
            setOpenPdfViewer(true);
        } else if (file.fileType !== "application/pdf" && open) {
            setVisible(true);
        }
    }, [file.fileType, open]);

    return (
        <Container className="preview-attach-cange">
            {renderFilePreview()}
        </Container>
    );
};

export default FilePreview;