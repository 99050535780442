import styled, { css } from "styled-components";
import { Box } from "@material-ui/core";

interface ColumnsHeaderConfigProps {
    color: string;
    isOpen: boolean;
}

export const BoxOver = styled(Box)`
    height: 40px;
    //width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Container = styled(Box)`
    //position: fixed;
    //position: relative;
    position: absolute;
    top: 40px;
    //left: 0px;
    right: -27px;
    z-index: 1;
    min-width: 300px;
    max-width: 300px;
    width: auto;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 5px;
    margin-right: 10px;
    padding-bottom: 10px;
    border: 1px solid #f4f4f4;
    padding-right: 10px;
    padding-left: 10px;
    z-index: 10;
`;

export const ColumnsHeaderConfig = styled.div<ColumnsHeaderConfigProps>`
  margin-right: 15px;
  background-color: #4073a5;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: none;
  justify-content: center;
  align-items: center;
  color: white;
  max-height: 40px;
  max-width: 35px;

  ${(props) =>
        props.color &&
        css`
      background-color: ${props.color};
      filter: brightness(90%);
    `}

  svg {
    font-size: 20px;
  }

  :hover {
    ${(props) =>
        props.color &&
        css`
      background-color: white;
      color: ${props.color};
      filter: brightness(100%);
    `}
  }

  ${(props) => props.isOpen && props.color && css`
        display: flex;
        background-color: white;
        color: ${props.color};
        filter: brightness(100%);
    `}

  .hover-menu {
      display: flex;
  }
`;