import React from 'react';
import { BrowserRouter as Router } from "react-router-dom"

import GlobalStyle from './styles/global';
import AppProvider from './hooks/';
import Routes from './routes';

import * as Sentry from "@sentry/react";
import { SENTRY_ENVIRONMENT } from './config/app';

const App: React.FC = () => {

  Sentry.init({
    dsn: "https://f39bda69aee2708e4ad566092395f540@o4507939393437696.ingest.us.sentry.io/4507939396321280",
    environment: SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  return (
    <Router>
      <AppProvider>
        <Routes />
      </AppProvider>

      <GlobalStyle />
    </Router>
  );
}

export default App;

