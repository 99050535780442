import React from "react";
import { IconBaseProps } from "react-icons";

import {
    Container
} from "./style";

interface DropDownItemProps {
    title: string;
    icon: React.ComponentType<IconBaseProps>;
    color: string;
    isPaidFeature?: boolean;
    idealPlan?: string;
    onClick: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const DropDownItem: React.FC<DropDownItemProps> = ({ title, icon: Icon, color, onClick, isPaidFeature, idealPlan }) => {

    return (
        <Container onClick={(e) => onClick(e)} color={color}>
            <Icon />
            <h3>{title}{isPaidFeature && idealPlan !== undefined && (<span>{idealPlan}</span>)}</h3>
        </Container>
    );

}

export default DropDownItem;