import React, { useEffect, useRef, useState } from "react";
import { ResponsiveLine, Serie } from '@nivo/line';
import { formatDateToDateRead } from "../../../../utils/formatDateToDateRead";
import { Animation, ContainerLoader } from "./style";
import lottie from "lottie-web";
import { TimeSheetDataTableProps } from "../getAggregateObject";

interface TimeSheetLineChartProps {
    timeSheetByDate?: TimeSheetDataTableProps[];
    isLoading?: boolean;
}

const TimeSheetLineChart: React.FC<TimeSheetLineChartProps> = ({ timeSheetByDate, isLoading }) => {

    const [loading, setLoading] = useState<boolean>(false);
    const lottieContainer = useRef<HTMLDivElement>(null);
    const [data, setData] = useState<Serie[]>();

    const formatDuration = (timeInSeconds: number): string => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;

        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    useEffect(() => {

        const newData = timeSheetByDate as TimeSheetDataTableProps[];

        if (newData !== undefined) {            

            //Function to valid if the date is valid
            const isValidDate = (date: Date) => {
                return date instanceof Date && !isNaN(date.getTime());
            }

            //Format the date to date_formated 
            newData.map((d) => {
                d['date_formated'] = isValidDate(new Date(d.date)) ? formatDateToDateRead(new Date(d.date)) : d.date;
                return d;
            });

            //Transform Line Chart Data To Nivo Data
            const lineData = [
                {
                    id: "Horas",
                    color: 'blue',
                    data: newData.map((d) => {
                        return {
                            x: d.date_formated,
                            y: d.duration,
                            duration: formatDuration(d.duration !== undefined ? d.duration : 0)
                        }
                    })
                }
            ] as Serie[];

            setData(lineData);
        }

    }, [timeSheetByDate]);

    useEffect(() => {

        if (isLoading !== undefined) {
            setLoading(isLoading);
        }

    }, [isLoading]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../../assets/lottie/loader.json')
            })
        }
    }, [loading]);

    return (
        <>
            {loading ?
                <ContainerLoader>
                    <Animation className="lottieContainer" ref={lottieContainer} />
                </ContainerLoader > :
                <div style={{ height: '300px' }}>
                    {data !== undefined ?
                        <ResponsiveLine
                            data={data}
                            margin={{ top: 35, right: 15, bottom: 60, left: 15 }}
                            xScale={{ type: 'point' }}
                            yScale={{
                                type: 'linear',
                                min: 'auto',
                                max: 'auto',
                                stacked: true,
                                reverse: false,
                            }}
                            yFormat=" >-.2f"
                            curve="catmullRom"
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Dia',
                                legendOffset: 36,
                                legendPosition: 'middle',
                                format: (d) => d.substring(0, 2),
                            }}
                            axisLeft={null}
                            colors={['#9e37ed']}
                            lineWidth={3}
                            pointSize={10}
                            pointColor={{ theme: 'background' }}
                            pointBorderWidth={2}
                            pointBorderColor={{ from: 'serieColor' }}
                            enablePointLabel={false}
                            pointLabel="y"
                            pointLabelYOffset={-12}
                            enableArea={true}
                            areaOpacity={0.05}
                            useMesh={true}
                        />
                        :
                        <>no data</>
                    }
                </div>
            }
        </>
    );

}

export default TimeSheetLineChart;