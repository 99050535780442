import { Container, Title } from "./styles";
import React, { InputHTMLAttributes, useCallback, useEffect, useRef, useState } from "react";

import { IconBaseProps } from "react-icons";
import { useField } from "@unform/core";
import ErrorDescription from "../../../../ErrorDescription";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon?: React.ComponentType<IconBaseProps>;
    description?: string;
    max_length?: number;
    formula?: string;
    variation?: string;
}

const Input: React.FC<InputProps> = ({ name, description, icon: Icon, disabled, max_length, formula, variation, ...rest }) => {

    const maskFormula = (value: string) => {

        let newValue = value;

        if (variation !== undefined) {
            if (variation === "2") { //uppercase
                newValue = newValue.toUpperCase();
            } else if (variation === "3") { //lowercase
                newValue = newValue.toLowerCase();
            }
        }

        return newValue;

    };

    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const { fieldName, defaultValue, error, registerField } = useField(name);
    const [valueText, setValueText] = useState<string>(defaultValue !== undefined ? defaultValue : "");

    const handleInputFocused = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: () => {
                return valueText;
            },
            setValue: (refs, value) => { //Não remover o ref

                if (value !== null) {
                    setValueText(value);
                } else {
                    setValueText("");
                }
            },
            clearValue: () => {
                setValueText("");
            }
        });

    }, [fieldName, registerField, valueText]);

    return (
        <>
            {description && <Title>{description}</Title>}
            <Container className="container-input" isErrored={!!error} isFocused={isFocused} isDisabled={!!disabled} >
                {Icon && <Icon className="icon-input" size="20" />}
                <input
                    onFocus={handleInputFocused}
                    onBlur={handleInputBlur}
                    onChange={(e) => {
                        if (formula !== undefined || variation !== undefined) {
                            setValueText(maskFormula(e.currentTarget.value));
                        } else {
                            setValueText(e.currentTarget.value)
                        }
                    }}
                    value={valueText}
                    defaultValue={defaultValue}
                    ref={inputRef}
                    name={name}
                    disabled={disabled}
                    maxLength={max_length}
                    {...rest}
                />

            </Container>

            {error && (<ErrorDescription title={error} />)}
        </>
    );
}

export default Input;