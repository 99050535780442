import React from "react";
import { FiAlertCircle } from "react-icons/fi";

import { Container } from "./styles";

interface ErrorDescriptionProps {
  title: string;
  children?: React.ReactNode;
}

const ErrorDescription: React.FC<ErrorDescriptionProps> = ({
  title,
  children
}) => {
  return (
    <Container>
        <FiAlertCircle className="cange-form-error-icon" color="red" size="20"/>
        <div className="cange-form-error">
            {title}
        </div>
    </Container>
  );
};

export default ErrorDescription;
