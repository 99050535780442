import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import React, { useCallback } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaFileAlt } from "react-icons/fa";
import { MdAttachFile } from "react-icons/md";

import getAccessControl from "../../../middlewares/AccessControl";
import getIconAttachment, { MAX_SIZE_ATTACH, allowedMimes } from "../../../utils/getIconAttachment";
import {
    AttachDescription,
    AttachDropOptions,
    AttachOptions,
    Attachment,
    AttachmentContainer,
    AttachmentDetail,
    AttachmentNew,
    Container,
    DownloadBtn,
    LoaderAttachment
} from "./style";
import { Attachment as Attch } from "../../../interfaces/Attachment";
import { useToast } from '../../../hooks/toast';
import api from "../../../services/api";
import FilePreview from "./FilePreview";

interface AttachmentsProps {
    uploadedFiles: Attch[];
    typeUser: string;
    maxLength?: string;
    hideInput?: boolean;
    isPublicForm?: boolean;
    variation?: string;
    card_current_id?: number;
    flow_id?: number;
    onUploadFile?: (files: File[]) => void;
    onDeleteFile?: (file: Attch) => void;
}

const Attachments: React.FC<AttachmentsProps> = ({ uploadedFiles, onUploadFile, onDeleteFile, maxLength, typeUser, hideInput, isPublicForm, variation, card_current_id, flow_id }) => {

    const { addToast } = useToast();
    const [fileSelected, setFileSelected] = React.useState<{ url: string, fileType: string, name: string, base64?: string }>();
    const [openFilePreview, setOpenFilePreview] = React.useState<boolean>(false);

    const onUploadReject = useCallback((fileRejections: FileRejection[], event: DropEvent) => {

        if (fileRejections !== undefined && fileRejections.length > 0) {
            for (let index = 0; index < fileRejections.length; index++) {
                const rejection = fileRejections[index];

                for (let idxB = 0; idxB < rejection.errors.length; idxB++) {
                    const error = rejection.errors[idxB];

                    addToast({
                        type: 'error',
                        title: 'Erro ao carregar o arquivo',
                        description: error.message + " (" + error.code + ")",
                    });

                }
            }
        }
    }, [addToast]);

    const previewDocument = useCallback(async (file: Attch) => {

        await api.get(`/attachment/url-download`, {
            params: {
                id_attachment: file.id_attachment,
                withBase64: true,
            }
        }).then(response => {

            const urlFile: { url: string, base64?: string } = response.data;

            if (urlFile !== undefined && urlFile.url !== undefined && file.mime_type !== undefined) {
                setFileSelected({ url: urlFile.url, fileType: file.mime_type, name: file.original_name, base64: urlFile.base64 });
                setOpenFilePreview(true);
            }

        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Erro ao solicitar permissão para visualizar o arquivo',
                description: 'Ocorreu um erro ao solicitar permissão para visualizar o arquivo!',
            });
        });

    }, [addToast]);

    const downloadFile = useCallback((id_attachment: number | string) => {

        api.get(`/attachment/url-download`, {
            params: {
                id_attachment: id_attachment,
                variation: variation !== undefined && variation !== null ? variation : undefined,
                card_current_id: card_current_id,
                flow_id: flow_id
            }
        }).then(response => {

            const urlFile: { url: string, base64?: string } = response.data;

            const link = document.createElement('a');
            link.download = 'true';
            link.href = urlFile.url;
            link.click();

        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Erro ao fazer o download do arquivo',
                description: 'Ocorreu um erro ao fazer o download do arquivo!',
            });
        });


    }, [addToast, card_current_id, variation, flow_id]);

    return (
        <Container>
            {hideInput ? <></> :
                maxLength === "1" && uploadedFiles.length >= 1 ?
                    <></> :
                    getAccessControl(30, typeUser) ?
                        <Dropzone accept={allowedMimes} onDropAccepted={onUploadFile} maxSize={MAX_SIZE_ATTACH} onDropRejected={onUploadReject}>
                            {({ getRootProps, getInputProps }) => (
                                <AttachmentNew
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <MdAttachFile />
                                </AttachmentNew>
                            )}
                        </Dropzone> : <></>

            }
            {uploadedFiles.map(file => {

                const Icon = file.file !== undefined ? getIconAttachment(file.file.type) : FaFileAlt;

                //Ajustar o carregamento e vínculo dos anexos
                return (
                    <AttachmentContainer key={file.id_attachment} onClick={() => previewDocument(file)}>
                        <Attachment>
                            <Icon />
                            <AttachmentDetail>
                                <AttachDescription>
                                    <span>{file.original_name}</span>
                                </AttachDescription>
                                <AttachOptions>
                                    <span>{file.readableSize}</span>
                                    {file.uploaded && !isPublicForm && !!file.url && (
                                        <DownloadBtn
                                            type="button"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                downloadFile(file.id_attachment);
                                            }}
                                        >
                                            · Fazer download
                                        </DownloadBtn>
                                    )}
                                    {file.error && (
                                        <span style={{ color: "red", marginLeft: "5px" }}>Erro no upload</span>
                                    )}
                                </AttachOptions>
                                {file.progress !== undefined && file.progress > 0 && file.progress < 100 ?
                                    <LoaderAttachment progress={file.progress}>
                                        <div />
                                    </LoaderAttachment> :
                                    <></>
                                }
                            </AttachmentDetail>
                            {getAccessControl(99, typeUser) ?
                                <AttachDropOptions className="drop-options">
                                    {file.uploaded && onDeleteFile && (
                                        <AiFillCloseCircle onClick={() => onDeleteFile(file)} />
                                    )}
                                </AttachDropOptions> : <></>
                            }
                        </Attachment>
                    </AttachmentContainer>
                )
            })}
            {fileSelected !== undefined && (
                <FilePreview file={fileSelected} open={openFilePreview} setOpen={setOpenFilePreview}/>
            )}
        </Container>
    );

}

export default Attachments;