import { IconList, IconPickerItem } from 'react-fa-icon-picker'
import {
    ItemRegisterAvatar,
    ItemRegisterContainer,
    ItemRegisterDetail,
    ItemRegisterDetailBody,
    ItemRegisterDetailContainer,
    ItemRegisterDetailHead,
    ItemRegisterDueDate,
    ItemRegisterHead,
    ItemRegisterIcon,
    ItemRegisterLink,
    ItemRegisterTitle,
    RegisterRemoveBox,
    RegisterRemoveContainer,
    ItemRegisterDueDatePocket
} from "./style";

import { FaExternalLinkAlt } from "react-icons/fa";
import { IconBaseProps } from "react-icons";
import { IoClose } from 'react-icons/io5';
import { IoMdListBox } from "react-icons/io";
import { Link } from "react-router-dom";
import { MdArrowForwardIos } from 'react-icons/md';
import React, { useState, useCallback } from "react";
import { Step } from '../../interfaces/Step';
import { User } from "../../interfaces/User";
import DialogAction from '../../dialogs/DialogAction';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import AvatarCange from '../AvatarCange';
import { FormAnswer } from '../../interfaces/FormAnswer';

export interface ComboItem {
    id_register?: number;
    title: string;
    hash?: string;
    icon?: string;
    color?: string;
    details?: ComboItemDetail[];
    origin?: string;
    originGroup?: string;
    user?: User;
    step?: Step;
    due_date?: string;
    due_date_color?: string;
    due_date_completed?: string;
    due_date_status_string?: string;
    complete?: string;
    focus?: boolean;
    formAnswer?: FormAnswer;
}

export interface ComboItemDetail {
    title: string;
    Icon?: React.ComponentType<IconBaseProps>;
    value: string;
}

interface ItemRegisterProps {
    item: ComboItem;
    type: 'Register' | 'Flow';
    hideDetails?: boolean,
    hideUser?: boolean,
    isPocket?: boolean,
    handleRemove?: (item: ComboItem) => Promise<void>;
    handleClick?: (id_register?: number) => Promise<void>;
}

const ItemRegister: React.FC<ItemRegisterProps> = ({ item, handleRemove, type, hideDetails, handleClick, isPocket, hideUser }) => {

    const [stateDelRegister, setStateDelRegister] = useState(false);
    const [selectedItem, setSelectedItem] = useState<ComboItem>();

    let colorItem: string | undefined = item?.color;
    let iconItem: string | undefined = item.icon;
    let timeDue: string = item.due_date_completed !== undefined ? format(
        new Date(item.due_date_completed),
        "HH:mm",
        { locale: pt }
    ) : "";

    if (type === "Flow") {
        if (item.step !== undefined) {
            colorItem = item.step.color;
            iconItem = item.step.icon;
        }

    }

    const dialogDelRegisterSubmmit = useCallback(async () => {

        if (handleRemove !== undefined && selectedItem !== undefined) {
            handleRemove(selectedItem);
        }

    }, [handleRemove, selectedItem]);

    return (
        <>
            <ItemRegisterContainer key={item.id_register} style={{ marginLeft: '0px', marginRight: '0px' }} color={colorItem} onClick={handleClick !== undefined ? () => handleClick(item.id_register) : () => { }}>
                <ItemRegisterHead
                    key={item.id_register}
                    color={colorItem}
                    selected={true}
                >
                    {type === "Register" && !isPocket ?
                        <Link to={"/register/" + item.hash + "/register/" + item.id_register} style={{ textDecoration: 'none' }} target="_blank">
                            <ItemRegisterIcon color={colorItem}>
                                {item !== undefined && item.icon !== undefined ?
                                    <IconPickerItem
                                        icon={iconItem as IconList}
                                        color={colorItem}
                                    /> :
                                    <IoMdListBox />
                                }
                            </ItemRegisterIcon>
                        </Link> : !isPocket ?
                            <Link to={"/flow/" + item.hash + "/card/" + item.id_register} style={{ textDecoration: 'none' }} target="_blank">
                                <ItemRegisterIcon color={colorItem}>
                                    {item !== undefined && iconItem !== undefined ?
                                        <IconPickerItem
                                            icon={iconItem as IconList}
                                            color={colorItem}
                                        /> :
                                        <IoMdListBox />
                                    }
                                </ItemRegisterIcon>
                            </Link> : <></>
                    }

                    <ItemRegisterTitle>
                        <h1>{item.title}</h1>
                        <span>{item.origin}</span>
                    </ItemRegisterTitle>

                    <ItemRegisterLink>
                        {type === "Register" && !isPocket ?
                            <Link to={"/register/" + item.hash + "/register/" + item.id_register} style={{ textDecoration: 'none' }} target="_blank">
                                <FaExternalLinkAlt style={{ marginLeft: '10px', fontSize: '11px', color: item.color }} />
                            </Link> : !isPocket ?
                                <Link to={"/flow/" + item.hash + "/card/" + item.id_register} style={{ textDecoration: 'none' }} target="_blank">
                                    <MdArrowForwardIos style={{ marginLeft: '10px', fontSize: '11px', color: item.color }} />
                                </Link> : <></>
                        }
                    </ItemRegisterLink>

                    {timeDue !== undefined && timeDue !== "" && timeDue !== "00:00" && item.due_date !== undefined && isPocket ?
                        <ItemRegisterDueDatePocket color={"black"} completed={item.complete}>
                            <span>{timeDue}</span>
                        </ItemRegisterDueDatePocket> : timeDue !== undefined && timeDue !== "" && item.due_date !== undefined && isPocket ?
                            <ItemRegisterDueDatePocket color={"gray"} completed={item.complete}>
                                <span>O dia todo</span>
                            </ItemRegisterDueDatePocket> : <></>
                    }

                    {item.due_date !== undefined && !isPocket ?
                        <ItemRegisterDueDate color={item.due_date_color} completed={item.complete}>
                            <span>{item.due_date}</span>
                        </ItemRegisterDueDate> :
                        <></>
                    }

                    {item.user !== undefined && !isPocket ?
                        <ItemRegisterAvatar>
                            <AvatarCange user={item.user} size="30" />
                        </ItemRegisterAvatar> :
                        <></>
                    }

                    {handleRemove !== undefined ?
                        <RegisterRemoveContainer id="remove-item" onClick={() => {
                            setSelectedItem(item);
                            setStateDelRegister(true);
                        }}>
                            <RegisterRemoveBox>
                                <IoClose />
                            </RegisterRemoveBox>
                        </RegisterRemoveContainer> :
                        <></>
                    }
                </ItemRegisterHead>
                {item.details !== undefined && item.details?.length > 0 && !hideDetails ?
                    <ItemRegisterDetailContainer>
                        {item.details?.map((detail) => {
                            return (
                                <ItemRegisterDetail key={detail.title}>
                                    <ItemRegisterDetailHead>
                                        {detail !== undefined && detail.Icon !== undefined ?
                                            <detail.Icon /> :
                                            <IoMdListBox />
                                        }
                                        <h3>{detail.title}</h3>
                                    </ItemRegisterDetailHead>
                                    <ItemRegisterDetailBody>{detail.value}</ItemRegisterDetailBody>
                                </ItemRegisterDetail>
                            )
                        })}
                    </ItemRegisterDetailContainer> :
                    <></>}
            </ItemRegisterContainer>
            <DialogAction
                open={stateDelRegister}
                message={"Você deseja excluir este relacionamento?"}
                actions_items={[
                    "Exclusão do relacionamento entre os dois registros;"
                ]}
                disclaimer={"Esta exclusão somente irá excluir o relacionamento entre os dois registros, não irá excluir os registros em si."}
                onClose={() => setStateDelRegister(false)}
                onSubmmit={dialogDelRegisterSubmmit}
                type={1}
            />
        </>
    );

}

export default ItemRegister;