import React from "react";

import {
    Item,
    ItemBadgeStatus,
    ItemDescription,
    ItemIcon,
    ItemLeft,
    ItemRight,
    ItemTitle
} from "./style";
import { RiLightbulbFlashFill } from "react-icons/ri";
import { FaArrowRight } from "react-icons/fa";

interface LearningBasicVideoProps {
    link: string;
    title: string;
    description?: string;
    buttonTitle: string;
    color: string;
}

const LearningBasicVideo: React.FC<LearningBasicVideoProps> = ({ link, title, description, buttonTitle, color }) => {

    return (
        <a href={link} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: "black", width: "100%" }}>
            <Item style={{ borderColor: color }}>

                <ItemLeft>
                    <ItemTitle>
                        <ItemIcon style={{ backgroundColor: color }}>
                            <RiLightbulbFlashFill />
                        </ItemIcon>
                        {title}
                    </ItemTitle>
                    {description && (
                        <ItemDescription>
                            {description}
                        </ItemDescription>
                    )}
                </ItemLeft>

                <ItemRight style={description !== undefined ? { top: '25px' } : {}}>
                    <ItemBadgeStatus style={{ minWidth: '115px', backgroundColor: color + '1e', color: '#212121' }}>
                        <span style={{ marginRight: '10px' }}>{buttonTitle}</span>
                        <FaArrowRight style={{ marginRight: '0px' }} />
                    </ItemBadgeStatus>
                </ItemRight>

            </Item>
        </a>
    );

}

export default LearningBasicVideo;