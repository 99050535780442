import styled, { css } from "styled-components";
import FormBuilder from "../../components/Forms/FormBuilder";
import Button from "../../components/Button";
import ContentPreview from "../../components/ContentPreview";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FieldBox from "../../components/FieldBox";
import { Form } from "@unform/web";
import Switch from "@material-ui/core/Switch";

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

interface FTypeItemProps {
  color?: string;
  selected?: boolean;
}

interface FTypeItemHeaderProps {
  color?: string;
}

interface FilterComboContainerButtonProps {
  active?: boolean;
}

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: white;
`;

export const Content = styled(DialogContent)`
  /*
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 5px 20px 0 rgb(21 27 38 / 8%);
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: auto;

  max-width: 1100px;
  max-height: 600px;
  */
`;

export const DialogBody = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 70px;
`;

export const ContentBody = styled(Grid)`
    display: flex;
    justify-content: center;
    height: auto;
    width: auto;
    /*background-color: white;*/
`;

export const PickField = styled(Grid)`
  width: 100%;
  height: 100%;
  max-height: 520px;
  padding-right: 15px;
  border-right: 1px solid #eef2f8;
  overflow: auto;
`;

export const DetailsField = styled(Grid)`
  position: relative;
  width: 100%;
  height: 100%;  
  max-height: 520px;
  padding-left: 15px;
  padding-right: 15px;
  border-right: 1px solid #eef2f8;
  overflow: auto;

  form {
    padding: 15px 10px 25px 10px;
  }
`;

export const PreviewField = styled(Grid)`
  width: 100%;
  height: 100%;  
  max-height: 520px;
  padding-left: 15px;
  padding-right: 15px;
  overflow: auto;
`;

export const DialogFooter = styled(DialogActions)`
  border-top: 1px solid #eef2f8;
  padding: 15px !important;
  width: 100%;
`;

export const DialogFooterLeft = styled.div`
  text-align: left;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: flex-start;
  float: left;
`;

export const DialogFooterRight = styled.div`
  text-align: right;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
    float: right;
`;

export const ButtonCancel = styled(Button)`
  height: 36px;
  width: 100px;
  margin: 0px 0px 0px 0px;
  color: gray;
`;

export const ButtonConfirm = styled(Button)`
  height: 36px;
  width: 120px;
  margin: 0px 0px 0px 20px;
`;

export const ButtonDelete = styled(Button)`
  height: 36px;
  width: 120px;
  margin: 0px 0px 0px 0px;
`;

export const ListTypeField = styled(List)`

  /*
  display: grid;
  --min-col-width: var(--grid-min-column-width,90px);
  --max-col-width: var(--grid-max-column-width,1fr);
  grid-template-columns: repeat(auto-fill,minmax(var(--min-col-width),var(--max-col-width)));
  grid-gap: var(--gap);
  */

`;

export const ItemTypeField = styled(ListItem)`
  padding: 0px !important;
  width: 100% !important;
  justify-content: center !important;
  height: 50px;

  & + div {
    width: 100% !important;
    margin-top: 0px !important;
  }
`;

export const FieldBoxField = styled(FieldBox)`

`;

export const FormUnForm = styled(Form)`
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
`;

export const BreakArea = styled.div`
    margin-bottom: 30px !important;
`;

export const ContainerFieldBox = styled.div`
    margin-bottom: 15px !important;
`;

export const FormField = styled(FormBuilder)`

    padding-bottom: 0px !important;

  & + div {
    height: 100% !important;
  }
`;

export const ContentPreviewStd = styled(ContentPreview)`
  height: 100%;
`;

export const FTypeItem = styled.div<FTypeItemProps>`
  display: flex;
  height: 35px;
  width: auto;
  border-radius: 5px;
  margin: 8px 5px;

  ${(props) => props.color && css`
    border: 1px solid ${props.color + "1e"};

    :hover {
      background-color: ${props.color + "1e"};

      div {
        font-weight: 500;
      }
    }
  `}

  ${(props) => props.selected && props.color && css`
    background-color: ${props.color + "1e"};

    div {
      font-weight: 500;
    }
  `}

  
`;
export const FTypeItemHeader = styled.div<FTypeItemHeaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  height: 100%;
  padding: 10px;
  border-radius: 5px 0px 0px 5px;
  min-width: 35px;

  svg {
    font-size: 17px;
    color: #2290eb;
  }

  ${(props) => props.color && css`

    background-color: ${props.color + "1e"};

    svg {
      color: ${props.color};
    }

  `}
`;

export const FTypeItemDescription = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  height: 100%;
  width: 100%;
`;

export const BetaSpan = styled.div`
  margin-left: 10px;
  background-color: #f23b5d;
  color: white;
  font-size: 10px;
  text-transform: uppercase;
  padding: 2px 5px;
  border-radius: 5px;
  font-weight: 500;
`;

export const HelpContainer = styled.div`
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 15px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #fff2e8;
`;

export const HelpItems = styled.div`
    padding: 15px;
    padding-bottom: 5px;
    width: 100%;
    height: 100%;
`;

export const HelpItem = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    align-items: stretch;
`;

export const HelpIcon = styled.div`
    width: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;

    svg {
        font-size: 25px;
        color: #ff8c2e;
    }
`;

export const HelpText = styled.div`
    width: 85%;
    font-size: 13px;
`;

export const ContainerAdrress = styled.div`
    border-radius: 5px;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #eef2f8;
    bottom: 20px;
    max-height: 95px;
`;

export const TitleAddress = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;

    h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;
        font-size: 12px;
        color: #84939f;
        font-weight: 500;
        text-transform: uppercase;
    }

    svg {
        min-width: 15px;
        font-size: 15px;
        font-weight: 600;
        color: #84939f;
    }

`;

export const AddressValue = styled.div`
    padding: 10px;
    background-color: #f4f5f7;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`;

export const Address = styled.div`
    margin-right: 10px;
    font-size: 12px;
    overflow: hidden;
`;

export const CopyBtn = styled.button`
    position: absolute;
    right: 10px;
    font-weight: 500;
    padding: 3px 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #9e37ed;
    border: none;
    background-color: #f4f5f7;
    border-radius: 10px;

    svg {
        margin-right: 5px;
    }

    :hover {
        background-color: #9e37ed;
        color: white;
    }
`;

export const BoxSearchContainer = styled.div`
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 15px;
`;

export const SearchInput = styled.input`
    width: 100%;
    height: 35px;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
    border: 1px solid #f4f5f7;
    background-color: #f4f5f7;
    font-size: 14px;
`;

export const FilterComboContainer = styled.div`
    margin-top: -30px;
    padding: 10px;
    margin-bottom: 10px;
`;

export const FilterComboContainerTitle = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: black;
    margin-bottom: 5px;
    margin-left: 5px;
`;

export const FilterComboContainerDescription = styled.div`
    font-size: 15px;
    font-weight: 500;
    color: gray;
    margin-bottom: 5px;
    margin-left: 5px;
`;

export const SwitchBase = styled(Switch)`
  float: right;
  margin-top: -23px;
`;

export const FilterComboContainerButton = styled.button<FilterComboContainerButtonProps>`
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    margin-right: 10px;
    color: #f23b5c;
    font-size: 15px;
    font-weight: 400;
    background-color: transparent;
    margin-top: 10px;
    margin-left: 0px;

    svg {
        font-size: 15px; 
        margin-top: 1px;
        margin-right: 10px;
    }

    :hover {
      opacity: 0.8;
    }    
    

    ${props => props.active && css`
        background-color: #60bd4e;
        color: white;
        border-radius: 5px;   
        font-weight: 500;
        
        :hover {
            background-color: #57aa47;
        }
    `}

    @media(max-width: 800px){ 
        svg {
            margin-right: 0px;
        }

        div {
            display: none;            
        }
    }
    
`;