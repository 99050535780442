import styled, { css } from "styled-components";

interface MenuItemProps {
    color: string;
    selectedStyle?: boolean;
}

interface MenuItemIconProps {
    color: string;
}

export const Container = styled.div`
    display: flex;
    height: 100%;
`;

export const LeftSide = styled.div`
    width: 25%;
    min-width: 250px;
    border-right: 1px solid #eef2f7;
    position: relative;
`;

export const MenuItems = styled.div`

`;

export const MenuItem = styled.a<MenuItemProps>`
    margin: 15px;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    margin-right: 20px;
    min-height: 35px;

    text-decoration: none;
    color: #808080;
    font-weight: 400;
    font-size: 15px;
    
    :hover {
        ${(props) => props.color && css`
            color: ${props.color};
            font-weight: 500;
            background-color: ${props.color + "1e"};
            border-radius: 10px;
        `}
    }

    ${(props) => props.selectedStyle && css`
            color: ${props.color};
            font-weight: 500;
            background-color: ${props.color + "1e"};
            border-radius: 10px;
    `}
`;


export const MenuItemIcon = styled.div<MenuItemIconProps>`
    border: 1px solid #f23b5c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    border-radius: 10px;
    background-color: #f23b5c;
    margin-right: 10px;    
    min-height: 35px;
    min-width: 35px;

    ${(props) => props.color && css`
        border: 1px solid ${props.color};
        background-color: ${props.color};
    `}

    svg {
        color: white;
    }

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        font-size: 18px !important;
        
        svg {
            color: white;
        }
    }
`;

export const BottomInfo = styled.div`
    position: absolute;
    bottom: 0px;
    padding: 15px;
    font-size: 15px;
    text-align: center;
`;

export const RightSide = styled.div`
    width: 80%;
    overflow: auto;
`;

export const Content = styled.div`
    height: 100%;
`;

export const Section = styled.div`
    padding: 20px;
    padding-bottom: 0px;
    padding-top: 10px;
    border-top: 1px solid #eef2f7;
`;

export const InfoImage = styled.div`
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 10px;

    img {
        width: 80%;
        max-width: 600px;
    }
`;

export const InfoTitle = styled.h3`
    font-size: 16px;
    text-align: left;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
`;

export const InfoDescription = styled.h4`
    color: gray;
    font-weight: 400;
    font-size: 13px;
    text-align: left;
    margin-bottom: 10px;
`;

export const SectionTitle = styled.h2`
    color: black;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
`;

export const ElementsItems = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    padding-left: 0px;
    overflow-x: auto;
    display: grid;
    --min-col-width: var(--grid-min-column-width,150px);
    --max-col-width: var(--grid-max-column-width,1fr);
    grid-template-columns: repeat(auto-fill,minmax(var(--min-col-width),var(--max-col-width)));
    grid-gap: var(--gap);
`;
