import styled, { css } from "styled-components";

import { Dialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';

interface IconHeaderProps {
    color: string;
}

export const DialogContainer = styled(Dialog)`

    z-index: 9999999;

    .MuiDialog-paperWidthSm {
        max-width: 700px;
        min-height: 90vh;
    }
    
`;

export const Content = styled(DialogContent)`
    background: white;
`;

export const ContentBody = styled(Grid)`
    display: block;
    justify-content: center;
    height: auto;
    width: auto;
    //min-height: 450px;
    margin-bottom: 25px;
`;

export const DialogTop = styled.div`
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #eef2f8;
    margin-top: 15px;
    width: 100%;
    
`;

export const DialogTopLeft = styled.div`
    width: 90%;
    height: 100%;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: start;
    margin-left: 15px;

    h1 {
        margin-left: 10px;
        font-size: 20px;
        font-weight: 500;
        color: black;
    }
`;

export const DialogTopRight = styled.div`
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 30px;
        border: none;
        background-color: transparent;
    }
`;

export const IconHeader = styled.span<IconHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 100%;
    background-color: transparent;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 45px;
        height: 45px;
        background-color: #f4f4f4;
        border-radius: 50%;

        > svg {
            width: 25px;
            height: 25px;

            ${props => props.color && css`
                color: ${props.color} !important;
            `}
        }

    }
`;

export const SpanField = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    margin: 0px 2px;
    white-space: nowrap;
    border-radius: 3px;
    border: 1px solid rgb(238,238,238);
    background-color: rgb(248,248,248);
    font-weight: 500;
    margin-left: 10px;
    padding: 7px 10px;
    font-size: 15px;
    color: gray;
    margin-top: 2px;

    svg {
        margin-left: 5px;
        margin-right: 5px;
        font-size: 13px;
    }
`;


export const HelpContainer = styled.div`
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 15px;
    margin-bottom: 25px;
    border-radius: 10px;
    background-color: #fff2e8;
`;

export const HelpItems = styled.div`
    padding: 15px;
    padding-bottom: 5px;
    width: 100%;
    height: 100%;
`;

export const HelpItem = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    align-items: stretch;
`;

export const HelpIcon = styled.div`
    width: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;

    svg {
        font-size: 25px;
        color: #ff8c2e;
    }
`;

export const HelpText = styled.div`
    width: 85%;
    font-size: 13px;
`;