import { maskFormulaCurrency, maskFormulaNumber, maskFormulaPercent } from "../../components/Forms/Fields/Components/Formula";

import { FieldProps } from "../../components/Forms/Fields/FieldBuilder";
import { FormAnswer } from "../../interfaces/FormAnswer";
import { FormAnswerField } from "../../interfaces/FormAnswerField";
import parseFormula from "./parseFormula";

interface objectInit {
    [x: string]: string | object
}

export default function updateFormulaFromFormAnswer(formAnswer: FormAnswer, fields: FieldProps[]): FormAnswer {

    let newFormAnswer = formAnswer;

    //Filter the fields that are formula fields
    const formulaFields = fields.filter((f) => f.type === "FORMULA_FIELD");
    if (formulaFields.length > 0) {

        //Run all formula fields
        for (let index = 0; index < formulaFields.length; index++) {
            const fField = formulaFields[index];

            const newObjData: objectInit = {};
            let maskedValue = "";
            let foundAnswer = false;
            let maxFormAnswer = 0;

            //Nomalize the data from form_answer_fields to object[]
            for (let idxC = 0; idxC < newFormAnswer.form_answer_fields.length; idxC++) {
                const formField = newFormAnswer.form_answer_fields[idxC];

                if (formField.field.type !== "FORMULA_FIELD") { //Remove the form field                        
                    newObjData[formField.field.name] = formField.value;
                }
            }

            //Calcule formula value - Filter the fields to get just the fields from a form
            const calcValue = parseFormula(fField, fields.filter((f) => f.form_id === newFormAnswer.form_id), newObjData as unknown as object[]);

            //Mask the value
            if (fField.variation !== undefined) {
                if (fField.variation === "1") { //Number
                    maskedValue = maskFormulaNumber(calcValue);
                } else if (fField.variation === "2") { //Currency
                    maskedValue = maskFormulaCurrency(calcValue);
                } else if (fField.variation === "3") { //Percent
                    maskedValue = maskFormulaPercent(calcValue);
                } else {
                    maskedValue = maskFormulaNumber(calcValue);
                }

            }

            if (newFormAnswer.form_answer_fields !== undefined) {
                for (let idxE = 0; idxE < newFormAnswer.form_answer_fields.length; idxE++) {
                    if (newFormAnswer.form_answer_fields[idxE].field_id === fField.id_field) {
                        foundAnswer = true;
                        newFormAnswer.form_answer_fields[idxE].value = calcValue;
                        newFormAnswer.form_answer_fields[idxE].valueString = maskedValue;
                    }

                    //Get the max FormAnswer
                    if (maxFormAnswer < newFormAnswer.form_answer_fields[idxE].id_form_answer_field) {
                        maxFormAnswer = newFormAnswer.form_answer_fields[idxE].id_form_answer_field;
                    }
                }

                //Update the form_answers_highlitgh
                if (newFormAnswer.form_answers_highlitgh !== undefined) {
                    for (let idxE = 0; idxE < newFormAnswer.form_answers_highlitgh.length; idxE++) {
                        if (newFormAnswer.form_answers_highlitgh[idxE].field_id === fField.id_field) {
                            newFormAnswer.form_answers_highlitgh[idxE].value = calcValue;
                            newFormAnswer.form_answers_highlitgh[idxE].valueString = maskedValue;
                        }
                    }
                }

                //If no found the answer 
                if (!foundAnswer && fField.id_field !== undefined) {
                    const newAnswer: FormAnswerField = {
                        id_form_answer_field: maxFormAnswer + 1,
                        form_answer_id: newFormAnswer.id_form_answer,
                        field_id: fField.id_field,
                        index: 0,
                        value: calcValue,
                        valueString: maskedValue,
                        field: fField
                    }

                    //Add a New form answer in highlight
                    if (String(fField.card_highlight) === "1") {

                        //check if the form_answer_highlitgh is undefined
                        if (newFormAnswer.form_answers_highlitgh === undefined) {
                            newFormAnswer.form_answers_highlitgh = [];
                        }

                        newFormAnswer.form_answers_highlitgh.push(newAnswer);
                    }

                    //Add a New form answer in form answer base
                    newFormAnswer.form_answer_fields.push(newAnswer);
                }

            }
        }
    }

    return newFormAnswer;

}