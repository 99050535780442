import Grid from '@material-ui/core/Grid';
import styled, { css } from "styled-components";
import Button from '../../components/Button';

interface BoxMenuItemProps {
    isSelect?: boolean;
}

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-height: calc(100vh - 80px);
    height: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const Content = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: 10px;
    height: 100%;
`;

export const ContainerLeft = styled.div`
    width: 260px !important;
    min-width: 260px !important;
    margin-bottom: 10px;
    height: 100%;
`;

export const ContainerCenter = styled.div`
    width: calc(100% - 260px) !important;
    margin-bottom: 10px;
    height: 100%;
`;

export const ContainerBox = styled(Grid)`
    background-color: white;
    border-radius: 10px;
    height: 100%;    
`;

export const BoxHeader = styled.div`
    font-size: 20px;
    color: black;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 10px;

`;

export const ButtonUserAdd = styled(Button)`
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 35px;
    margin-bottom: 10px;
    margin-right: 25px !important;
    font-size: 14px;
    font-weight: 400;
    color: #4681b8;
    background-color: white;
    border: 1px dashed #4681b8;
    overflow: hidden;
    width: 120px !important;

    div {
        margin-top: 2px !important;

        svg {
            width: 18px;
            height: 18px;
            color: #4681b8;
        }
    }

    :hover {

        border: 1px dashed #f23b5c;
        background-color: white;
        color: #f23b5c;

        svg {
            color: #f23b5c;
        }

    }

    @media(max-width: 800px){
        display: none !important;
    }
`;

export const NewButton = styled.button`
    border: none;
    background-color: #9e37ec;
    color: white;
    padding: 10px 10px;
    font-size: 14px;
    width: 100%;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 20px;
    min-height: 42px;

    svg {
        margin-right: 10px;
    }

    :hover {
        opacity: 0.8;
    }
`;

export const BoxMenuContainer = styled.div``;

export const BoxMenuItem = styled.div<BoxMenuItemProps>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    color: gray;
    padding: 12px 10px;
    border-radius: 10px;
    font-size: 15px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;

    svg {
        margin-right: 10px;
    }

    ${props => props.isSelect && css`
        color: #2e2e2e;
        background-color: #f4f5f7;
        font-weight: 500;
    `}

    :hover {
        color: #2e2e2e;
        background-color: #f4f5f7;
    }

    @media(max-width: 768px){
        display: flex;
        justify-content: center;
        align-items: center;

        .box-menu-item-icon {
            font-size: 30px;
        }

        .box-menu-item-text {
            display: none;
        }

        .box-menu-item-detail {
            display: none;
        }
    }

`;
export const BoxMenuItemText = styled.div``;
